import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const items = [
  { label: 'Mi', value: 'Mi' },
  { label: 'Gi', value: 'Gi' },
  { label: 'Ti', value: 'Ti' },
]

interface Props {}

export default function PvcSizePicklist(props: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown name="sizeUnit" label="Unit" items={items} compact={false} {...props} />
}
