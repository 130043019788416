import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { ISecretSelector } from '../model'
import Progress from 'core/components/progress/Progress'
import SummaryCard from 'k8s/components/common/entity/summary-card'
import InfoCard from 'k8s/components/common/entity/info-card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import { renderAge } from 'k8s/components/common/entity/helpers'
import Card from 'core/elements/card'
import Grid, { GridViewColumn } from 'core/elements/grid/Grid'
import { GridCellWithCopyIcon, OwnerReferences } from '../../config-maps/details/ConfigMapsOverview'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import SecretsDataInfoModal from './SecretsDataInfoModal'
import SecretImmutabilityConfirmationDialog from './SecretImmutabilityConfirmationDialog'
import DeleteSecretDataDialog from './DeleteSecretDataDialog'

const keyValuesColums: GridViewColumn<{ key: string; value: string }>[] = [
  { key: 'key', label: 'Key', width: 'medium' },
  { key: 'value', label: 'Value', render: GridCellWithCopyIcon },
]

const summaryRows: { key: keyof ISecretSelector; label: string }[] = [
  { key: 'clusterName', label: 'Cluster' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'age', label: 'Age' },
]

const SecretsOverview = ({
  secret,
  loading,
  reload,
}: {
  secret: ISecretSelector
  loading: boolean
  reload: (refetch: boolean) => void
}) => {
  const classes = useStyles()

  const [selectedSecretData, setSelectedSecretData] = useState<{ key: string; value: string }>({
    key: '',
    value: '',
  })
  const [showImmutabilityDialog, setShowImmutabilityDialog] = useState(false)
  const [showDeleteSecretDataDialog, setShowDeleteSecretDataDialog] = useState(false)
  const [showSecretDataModal, setShowSecretDataModal] = useState(false)

  const metadataFields = [
    {
      id: 'age',
      title: 'Created',
      required: true,
      render: renderAge,
    },
    {
      id: 'immutable',
      title: 'Immutable Enabled',
      helpMessage: 'If enabled, the secret cannot be modified.',
      required: true,
      render: (value) => (
        <ToggleSwitch
          active={value}
          onClick={() => setShowImmutabilityDialog(true)}
          disabled={secret?.immutable}
        />
      ),
    },
    {
      id: 'type',
      title: 'Type',
      required: true,
    },
    {
      id: 'ownerReferences',
      title: 'OwnerReferences',
      required: true,
      render: (value) => <OwnerReferences refs={value} />,
      condition: ({ ownerReferences }) => ownerReferences?.length > 0,
    },
  ]

  const rowMenuItems: Array<GridRowMenuItemSpec<{ key: string; value: string }>> = [
    {
      label: 'View',
      icon: 'eye',
      handleClick: (secretData) => {
        setSelectedSecretData(secretData)
        setShowSecretDataModal(true)
      },
      refreshAfterSuccess: true,
    },
    {
      cond: () => !secret?.immutable,
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (secretData) => {
        setSelectedSecretData(secretData)
        setShowDeleteSecretDataDialog(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, secret)
  }, [secret])

  const secretData = useMemo(() => {
    if (!secret?.data) return []
    return Object.entries(secret?.data).map(([key, value]) => {
      return { key, value }
    })
  }, [secret])

  return (
    <Progress loading={loading}>
      <div className={classes.container}>
        <DeleteSecretDataDialog
          secret={secret}
          secretData={selectedSecretData}
          open={showDeleteSecretDataDialog}
          onClose={() => setShowDeleteSecretDataDialog(false)}
          reload={reload}
        />
        <SecretImmutabilityConfirmationDialog
          secret={secret}
          open={showImmutabilityDialog}
          onClose={() => setShowImmutabilityDialog(false)}
          reload={reload}
        />
        <SecretsDataInfoModal
          secret={secret}
          secretData={selectedSecretData}
          open={showSecretDataModal}
          onClose={() => setShowSecretDataModal(false)}
        />
        <div className={classes.column}>
          <SummaryCard<ISecretSelector>
            title={secret?.name}
            subtitle="Secret"
            rows={summaryRows}
            data={secret}
          />
          <InfoCard
            items={metadata}
            title="Properties"
            footer={
              <div className={classes.footer}>
                <LabelsAndAnnotationsSection entity={secret} resourceType="secret" />
              </div>
            }
          />
        </div>
        <div className={classes.column}>
          <Card title="Secret Data" withCustomBody>
            <Grid
              uniqueIdentifier="key"
              data={secretData}
              columns={keyValuesColums}
              loading={loading}
              compact
              rowMenuItems={rowMenuItems}
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutosummaryRows: 'max-content',
  },
  footer: {
    margin: theme.spacing(1),
  },
  copyClipboard: {
    '& .copy': {
      color: theme.components.typography.passive,
    },
  },
}))

export default SecretsOverview
