import { IRbacAPIGroup, IRbacRoleRule, IRbacAPIGroupResource } from 'k8s/components/rbac/model'
import React, { FC, useMemo, useCallback } from 'react'
import useParams from 'core/hooks/useParams'
import { pluck, intersection } from 'ramda'
import { except } from 'utils/fp'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import Checkbox from 'core/elements/input/Checkbox'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'

const useStyles = makeStyles<Theme>((theme) => ({
  permissionsResources: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: 240,
    minWidth: 240,
    '& > p': {
      margin: 0,
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    '& > p > strong': {
      marginLeft: theme.spacing(1),
      display: 'flex',
      flexFlow: 'row wrap',
      whiteSpace: 'pre-wrap',
      width: 130,
    },
  },
  editPermissionForm: {
    minHeight: 200,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 2,
    borderColor: theme.palette.blue[500],
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
  },
  editFormContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  leftBlock: {
    minHeight: 200,
    minWidth: 300,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
  },
  policyActions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    '& > button': {
      margin: theme.spacing(0, 1),
      borderRadius: 4,
      minHeight: 30,
    },
  },
  verbsBoxes: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    '& > label': {
      width: '33%',
      margin: 0,
    },
  },
}))

interface Props {
  apiGroups: IRbacAPIGroup[]
  onAdd: (role: IRbacRoleRule) => void
  onCancel: () => void
}

export const AddNewApiRule: FC<Props> = ({ apiGroups, onAdd, onCancel }) => {
  const classes = useStyles()
  const {
    params: { selectedApiGroup, selectedResource, selectedVerbs },
    updateParams,
  } = useParams<{
    selectedApiGroup: IRbacAPIGroup
    selectedResource: IRbacAPIGroupResource
    selectedVerbs: string[]
  }>({
    selectedApiGroup: null,
    selectedResource: null,
    selectedVerbs: [],
  })
  const groupOptions = useMemo(
    () =>
      pluck('name', apiGroups).map((value) => ({
        value,
        label: value,
      })),
    [apiGroups],
  )
  const resourceOptions = useMemo(
    () =>
      pluck('name', selectedApiGroup?.resources || []).map((value) => ({
        value,
        label: value,
      })),
    [selectedApiGroup],
  )
  const availableVerbs = useMemo(() => selectedResource?.verbs || [], [selectedResource])

  const handleGroupChange = useCallback(
    (apiGroupName) => {
      const selectedApiGroup = apiGroups.find(({ name }) => name === apiGroupName)
      const [selectedResource]: IRbacAPIGroupResource[] = selectedApiGroup?.resources || []
      updateParams({ selectedApiGroup, selectedResource, selectedVerbs: [] })
    },
    [apiGroups],
  )

  const handleResourceChange = useCallback(
    (resourceName) => {
      const selectedResource = (selectedApiGroup?.resources || []).find(
        ({ name }) => name === resourceName,
      )
      updateParams({ selectedApiGroup, selectedResource, selectedVerbs: [] })
    },
    [apiGroups, selectedApiGroup],
  )

  const handleAdd = useCallback(
    (e) => {
      e.stopPropagation()
      onAdd({
        verbs: intersection(selectedVerbs, availableVerbs),
        apiGroups: [selectedApiGroup.name],
        resources: [selectedResource.name],
      })
    },
    [onAdd, selectedVerbs],
  )
  const handleCancel = useCallback((e) => {
    e.stopPropagation()
    updateParams({
      selectedApiGroup: null,
      selectedResource: null,
      selectedVerbs: [],
    })
    onCancel()
  }, [])

  const toggleVerb = useCallback(
    (verb) => {
      updateParams({
        selectedVerbs: selectedVerbs.includes(verb)
          ? except(verb, selectedVerbs)
          : [verb, ...selectedVerbs],
      })
    },
    [selectedVerbs],
  )

  return (
    <div className={classes.editPermissionForm}>
      <Text variant="subtitle2">+ ADD NEW API ACCESS/PERMISSION</Text>
      <hr />
      <div className={classes.editFormContent}>
        <div className={classes.leftBlock}>
          <div className={classes.permissionsResources}>
            <AsyncDropdown
              label="API Group"
              name="selectedGroup"
              value={selectedApiGroup?.name}
              onChange={handleGroupChange}
              items={groupOptions}
              showAll={false}
            />
            <AsyncDropdown
              label="Resources"
              name="selectedResource"
              value={selectedResource?.name}
              onChange={handleResourceChange}
              items={resourceOptions}
              showAll={false}
            />
          </div>
          <div className={classes.policyActions}>
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleAdd} disabled={!selectedVerbs.length}>
              Add
            </Button>
          </div>
        </div>
        <div>
          <Text variant="body1">Select Verbs to add to this resource:</Text>
          <div className={classes.verbsBoxes}>
            {selectedApiGroup && selectedResource ? (
              availableVerbs.map((verb) => (
                <Checkbox
                  key={verb}
                  label={verb}
                  checked={selectedVerbs.includes(verb)}
                  onChange={() => toggleVerb(verb)}
                />
              ))
            ) : (
              <Text variant="body2">API Group and a Resource must be selected to add verbs.</Text>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
