import React from 'react'
import Card from 'core/elements/card'
import PieUsageWidget from 'core/components/widgets/PieUsageWidget'
import { podStatusCardProps } from 'k8s/components/dashboard/card-templates'

const PodsStatusCard = ({ pods = [] }) => {
  const { pieData, piePrimary } = podStatusCardProps.quantityFn(pods)
  return (
    <Card title="Pods Status">
      <PieUsageWidget data={pieData} primary={piePrimary} />
    </Card>
  )
}

export default PodsStatusCard
