import React, { useEffect } from 'react'
import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown'
import TextField from 'core/components/validatedForm/TextField'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { ipamTypes } from './AddNetworkAttachmentDefinitionsModal'
import DhcpNote from './DhcpNote'
import WhereaboutsFields from './WhereaboutsFields'

export default function SriovParamFields({ params, updateParams, method = 'add' }) {
  useEffect(() => {
    if (method === 'edit') {
      return
    }

    updateParams({
      config: {
        ...params?.config,
        type: 'sriov',
        spoofchk: 'off',
      },
    })
  }, [])

  return (
    <>
      <FormFieldSection title="Interface" step={method === 'edit' ? 1 : 2}>
        <TextField
          id="networkName"
          label="Network Name"
          info="The name of the network"
          value={params?.config?.name}
          onChange={(value) => updateParams({ config: { ...params?.config, name: value } })}
        />
        <TextField
          id="vlan"
          label="VLAN"
          type="number"
          value={params?.config?.vlan}
          onChange={(value) => updateParams({ config: { ...params?.config, vlan: value } })}
        />
        <TextField
          id="resourceName"
          label="Resource Name"
          value={params?.resourceName}
          onChange={(value) => updateParams({ resourceName: value })}
        />
        <TextField
          id="logFile"
          label="Log File"
          info="File path for multus log (e.g. /var/log/multus.log)"
          value={params?.config?.logFile}
          onChange={(value) => updateParams({ config: { ...params?.config, logFile: value } })}
        />
        <TextField
          id="logLevel"
          label="Log Level"
          info="Logging level (e.g. 'debug', 'error', 'verbose' or 'panic')"
          value={params?.config?.logLevel}
          onChange={(value) => updateParams({ config: { ...params?.config, logLevel: value } })}
        />
        <ToggleSwitchField
          id="spoofchk"
          label="spoofchk"
          value={params?.config?.spoofchk === 'on'}
          onChange={(val) =>
            updateParams(
              val
                ? { config: { ...params?.config, spoofchk: 'on' } }
                : { config: { ...params?.config, spoofchk: 'off' } },
            )
          }
        />
      </FormFieldSection>
      <FormFieldSection title="IPAM (optional)" step={method === 'edit' ? 2 : 3}>
        <DropdownField
          DropdownComponent={Dropdown}
          id="ipamType"
          label="Type"
          value={params?.config?.ipam?.type}
          onChange={(value) =>
            updateParams({
              config: { ...params?.config, ipam: { ...params?.config?.ipam, type: value } },
            })
          }
          items={ipamTypes}
        />

        {params?.config?.ipam?.type === 'whereabouts' && (
          <WhereaboutsFields params={params} updateParams={updateParams} />
        )}

        {params?.config?.ipam?.type === 'dhcp' && <DhcpNote />}
      </FormFieldSection>
    </>
  )
}
