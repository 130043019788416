import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React from 'react'
import { medAppPlaceholderIcon } from '../../helpers'
import Text from 'core/elements/Text'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import generateTestId from 'utils/test-helpers'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'grid',
    gridTemplateColumns: '125px auto',
    margin: theme.spacing(8, 0, 0, 10),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}))

const defaultHeaderMessage = 'To use the App Catalog, please attach a repository.'
const defaultSecondaryMessage =
  "You can do so from the '+ Add Repository' button on the Repositories Page."

export default function NoRepositoriesMessage({
  headerMessage = defaultHeaderMessage,
  secondaryMessage = defaultSecondaryMessage,
  showAddRepositoryLink = true,
  button = null,
  className = null,
}) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.main, className)}>
      <img src={medAppPlaceholderIcon} />
      <div>
        <Text className={classes.header} variant="subtitle2">
          {headerMessage}
        </Text>
        <Text variant="body1">{secondaryMessage}</Text>
        {button && button}
        {showAddRepositoryLink && (
          <SimpleLink src={routes.repositories.add.path()}>
            <Text data-testid={generateTestId('add', 'repository')} variant="body1">
              Add Repository
            </Text>
          </SimpleLink>
        )}
      </div>
    </div>
  )
}
