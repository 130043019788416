// libs
import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import useReactRouter from 'use-react-router'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'

import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { isDecco } from 'core/utils/helpers'
import { routes } from 'core/utils/routes'
import { UserPreferences } from 'app/constants'
import useScopedPreferences from 'core/session/useScopedPreferences'
import DocumentMeta from 'core/components/DocumentMeta'
import CardMosaic from './card-mosaic'
import Text from 'core/elements/Text'
import IconButton from 'core/elements/button/IconButton'
import { baseCards } from './helpers'
import { emptyArr } from 'utils/fp'
import { HeaderPrimaryActionPortal, HeaderTitlePortal } from 'core/elements/header/portals'

const EditDashboardPage = () => {
  const { history } = useReactRouter()
  const { prefs, fetchUserDefaults, updateUserDefaults } = useScopedPreferences('defaults')
  const { cards = emptyArr, ...rest } = (prefs[UserPreferences.Dashboard] || {}) as {
    cards: unknown[]
  }

  useEffect(() => {
    // Load user defaults from the preference store
    fetchUserDefaults(UserPreferences.Dashboard)
  }, [])

  const cardsToRender = cards?.length > 0 ? cards : baseCards
  const loading = cardsToRender.length === 0

  const handleSave = (newCards) => {
    updateUserDefaults(UserPreferences.Dashboard, { ...rest, cards: newCards })
    handleRedirect()
  }
  const handleRedirect = () => {
    history.push(routes.dashboard.root.path())
  }

  const handleReset = () => {
    updateUserDefaults(UserPreferences.Dashboard, { ...rest, cards: baseCards })
  }

  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const {
    userDetails: { role },
    features,
  } = session
  const isDeccoEnv = isDecco(features)

  if (!isDeccoEnv) {
    return <Redirect to={routes.dashboard.root.path()} />
  }

  return (
    <>
      <DocumentMeta title="Edit Dashboard" />
      <HeaderTitlePortal>
        <Text variant="subtitle1" component="h1">
          Customize your dashboard
        </Text>
      </HeaderTitlePortal>
      <HeaderPrimaryActionPortal>
        <IconButton icon="sync" info="Reset To Default" onClick={handleReset} />
        <IconButton
          icon="times-circle"
          info="Stop editing your dashboard"
          onClick={handleRedirect}
        />
      </HeaderPrimaryActionPortal>
      {!loading && <CardMosaic cards={cardsToRender} role={role} onSave={handleSave} draggable />}
    </>
  )
}

export default EditDashboardPage
