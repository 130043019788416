import React, { useMemo, useEffect, useCallback } from 'react'
import { clientActions } from 'core/client/clientReducers'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import { listCronjobs, updateCronjob } from '../actions'
import { listPods } from 'k8s/components/pods/new-actions'
import useListAction from 'core/hooks/useListAction'
import { routes } from 'core/utils/routes'
import { ICronjobDetailsPageTabs } from '../model'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { cronjobSelector } from '../selectors'
import CronjobOverview from './CronjobOverview'
import EntityPodsPage from '../../common/entity/entity-pods-page'
import EntityEventsPage from '../../common/entity/entity-events-page'
import EntityLogsPage from '../../common/entity/entity-logs-page'
import EntityYamlPage from '../../common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import { pathStrOr } from 'utils/fp'
import jsYaml from 'js-yaml'
import useUpdateAction from 'core/hooks/useUpdateAction'
import InfoHeader from '../../common/entity/info-header'
import ActiveJobsPage from './ActiveJobsPage'
import { durationBetweenDates } from 'utils/misc'
import { ICronjobSelector } from 'k8s/components/cronjobs/cronjob-model'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'

const { qbert } = ApiClient.getInstance()

const defaultCronjob = {
  volumes: [],
  tolerations: [],
} as ICronjobSelector

const CronjobOverviewPage = () => {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { globalParams: params } = useGlobalParams(useParams, {})
  const { loading, reload } = useListAction(listCronjobs, {
    params,
  })
  const cronjobs = useSelectorWithParams(cronjobSelector, { clusterId, useGlobalParams: false })
  const cronjob = useMemo(() => cronjobs.find((cronjob) => cronjob.id === id) || defaultCronjob, [
    id,
    cronjobs,
  ])

  const { update, updating } = useUpdateAction(updateCronjob)

  const { loading: loadingPods, reload: reloadPods } = useListAction(listPods, { params })

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: cronjob?.clusterName || clusterId,
        id: cronjob?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [cronjob?.clusterName, cronjob?.name, id, clusterId])

  const getCronjobYaml = useCallback(async () => {
    if (!cronjob) return undefined
    return qbert.getCronjob(cronjob.clusterId, cronjob.namespace, cronjob.name)
  }, [cronjob])

  const handleCronjobUpdate = useCallback(
    async (yaml) => {
      const body = jsYaml.load(yaml)
      const namespace = pathStrOr('', 'metadata.namespace', body)
      const name = pathStrOr('', 'metadata.name', body)
      await update({ clusterId, namespace, name, id: cronjob?.id, body })
    },
    [clusterId, cronjob?.id],
  )

  const headerColumns = useMemo(() => {
    if (!cronjob) return []
    return [
      { label: 'Name', value: cronjob?.name },
      { label: 'Age', value: cronjob?.age },
      { label: 'Schedule', value: cronjob?.spec?.schedule },
      {
        label: 'Last Scheduled',
        value:
          cronjob?.status?.lastScheduleTime &&
          `${durationBetweenDates({ labels: ['d'] })(cronjob?.status?.lastScheduleTime)}`,
      },
    ]
  }, [cronjob])

  return (
    <>
      <DocumentMeta title="Cronjob Overview" breadcrumbs />
      <InfoHeader columns={headerColumns} />
      <Tabs route={routes.cronjobs.details}>
        <Tab label="Overview" value={ICronjobDetailsPageTabs.Overview}>
          <CronjobOverview cronjob={cronjob} loading={loading} />
        </Tab>
        <Tab label="Active Jobs" value={ICronjobDetailsPageTabs.ActiveJobs}>
          <ActiveJobsPage jobs={cronjob.jobs} loading={loading} reload={reload} />
        </Tab>
        <Tab label="Pods" value={ICronjobDetailsPageTabs.Pods}>
          <EntityPodsPage
            pods={cronjob.pods}
            loading={loading || loadingPods}
            reload={reloadPods}
          />
        </Tab>
        <Tab label="Events" value={ICronjobDetailsPageTabs.Events}>
          <EntityEventsPage entity={cronjob} loading={loading} />
        </Tab>
        <Tab label="Logs" value={ICronjobDetailsPageTabs.Logs}>
          <EntityLogsPage pods={cronjob.pods} loading={loading} />
        </Tab>
        <Tab label="YAML" value={ICronjobDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Cronjob"
            entityName={cronjob?.name}
            getYamlFn={getCronjobYaml}
            handleUpdate={handleCronjobUpdate}
            loading={updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default CronjobOverviewPage
