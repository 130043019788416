import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { findCluster } from 'k8s/util/helpers'
import { calcUsagePercentage, durationBetweenDates } from 'utils/misc'
import { IResourceQuotaSelector, ResourceQuotaStatus } from './model'
import { isNilOrEmpty } from 'utils/fp'

const getStatusObj = (status: ResourceQuotaStatus) => {
  if (isNilOrEmpty(status)) return []

  return Object.entries(status?.hard).map(([resource, max]) => {
    const used = status.used[resource]
    const usage = calcUsagePercentage(used, max)
    return {
      resource,
      used,
      max,
      usage,
    }
  })
}

export const resourceQuotasSelector = createSharedSelector(
  getDataSelector<DataKeys.ResourceQuotas>(
    DataKeys.ResourceQuotas,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  (rawResourceQuotas, allClusters): IResourceQuotaSelector[] => {
    return rawResourceQuotas.map((rawResourceQuota) => {
      const { clusterId, metadata, status } = rawResourceQuota
      const { name: clusterName, clusterType: clusterType } = findCluster(allClusters, clusterId)
      const statusObj = getStatusObj(status)
      return {
        ...rawResourceQuota,
        statusObj,
        clusterName,
        clusterId,
        clusterType,
        age: durationBetweenDates({ labels: ['d'] })(metadata?.creationTimestamp),
        ownerReferences: metadata?.ownerReferences,
        creationTimestamp: metadata?.creationTimestamp,
        labels: metadata?.labels,
        annotations: metadata?.annotations,
      }
    })
  },
)
