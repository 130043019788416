import React from 'react'
import ExternalLink from 'core/components/ExternalLink'
import { makeStyles } from '@material-ui/styles'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import { terraformGitubLink } from 'k8s/links'
import Text from 'core/elements/Text'
import BulletList from 'core/components/BulletList'
import Button from 'core/elements/button'
import CloudProviderCard from '../common/CloudProviderCard'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { noop } from 'utils/fp'
import DocumentMeta from 'core/components/DocumentMeta'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  blueIcon: {
    color: theme.palette.primary.main,
  },
  text: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  bulletListTitle: {
    marginLeft: theme.spacing(1),
  },
  bulletList: {
    marginLeft: theme.spacing(2),
  },
  link: {
    marginLeft: theme.spacing(1),
  },
  cloudContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cloudTypes: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  button: {
    margin: 0,
    borderRadius: 2,
    textTransform: 'none',
    height: 40,
    minWidth: 150,
    marginTop: theme.spacing(6),
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
  },
  card: {
    marginTop: 24,
  },
}))

const requirements = ['Go v1.13+ and', 'Terraform']

const TerraformListPage = () => {
  const classes = useStyles()
  return (
    <>
      <DocumentMeta title="Terraform" breadcrumbs />
      <FormFieldCard
        className={classes.card}
        title="Build Clusters Using Terraform"
        link={
          <div>
            <ExternalLink url={terraformGitubLink}>Terraform Help?</ExternalLink>
          </div>
        }
      >
        <Text variant="body1" className={classes.text}>
          Konform allows you to create and manage your PMK clusters using terraform.
        </Text>
        <Text variant="body1" className={classes.bulletListTitle}>
          Requirements:
        </Text>
        <BulletList className={classes.bulletList} items={requirements} />
        <Text variant="body1" className={classes.text}>
          Access Konform and the commands for building a cluster on the Github repository
        </Text>
        <ExternalLink className={classes.link} url={terraformGitubLink} underline="always">
          {terraformGitubLink}
        </ExternalLink>
        <div className={classes.cloudContainer}>
          <Text className={classes.text}>
            <b>Supported Cloud</b>
          </Text>
          <div className={classes.cloudTypes}>
            <CloudProviderCard type={CloudProviders.Aws} active={false} onClick={noop} />
            {/* <CloudProviderCard type={CloudProviders.Azure} active={false} onClick={noop} /> */}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <ExternalLink url={terraformGitubLink}>
            <Button variant="primary">+ Get Konform on GitHub</Button>
          </ExternalLink>
        </div>
      </FormFieldCard>
    </>
  )
}

export default TerraformListPage
