import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { cloudProviderTypes } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import { capitalizeString } from 'utils/misc'
import DryRunDialog from '../deploy/DryRunDialog'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import { TypesTableCell } from '../RbacProfilesListPage'
import { indexBy, path } from 'ramda'
import { renderActiveProfile, renderProfileAgent } from '../deploy/SelectClusterForm'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { makeParamsClustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { listRbacProfiles, getDriftAnalysis } from '../new-actions'
import { rbacProfilesSelector } from '../selectors'
import { useSelector } from 'react-redux'
import { makeParamsCapiClustersSelector } from 'app/plugins/infrastructure/components/clusters/capi/selectors'
import { listCapiClusters } from 'app/plugins/infrastructure/components/clusters/capi/actions'
import { listAllClusterAddons } from 'app/plugins/infrastructure/components/clusters/cluster-addons/new-actions'

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  largeTopMargin: {
    marginTop: theme.spacing(2),
  },
}))

interface Props {
  wizardContext: any
  setWizardContext: any
  onNext: any
  handleNext: any
  setSubmitting: any
}

const renderTypes = (_, profile) => <TypesTableCell item={profile} />

const profileColumns = [
  { id: 'name', label: 'Name' },
  { id: 'roles', label: 'Types', render: renderTypes },
]

const clusterColumns = [
  { id: 'name', label: 'Name' },
  { id: 'kubeRoleVersion', label: 'Version' },
  {
    id: 'cloudProviderType',
    label: 'Cloud Provider',
    render: (type) => cloudProviderTypes[type] || capitalizeString(type),
  },
  {
    id: 'hasBinding',
    label: 'Active Profile',
    render: renderActiveProfile,
  },
  {
    id: 'enableProfileAgent',
    label: 'Profile Agent',
    render: renderProfileAgent,
  },
]

const profileAgentInstalled = (cluster) => !!cluster.enableProfileAgent

const defaultParamsClusterSelector = makeParamsClustersSelector({ healthyClusters: true })
const defaultParamsCapiClusterSelector = makeParamsCapiClustersSelector({
  provisionedClusters: true,
})

const ClusterProfileSelection = ({
  wizardContext,
  setWizardContext,
  onNext,
  handleNext,
  setSubmitting,
}: Props) => {
  const classes = useStyles({})
  const [showDialog, setShowDialog] = useState(true)
  const [bindingName, setBindingName] = useState('')
  const validatorRef = useRef(null)

  const { loading: profilesLoading } = useListAction(listRbacProfiles)
  const profiles = useSelector(rbacProfilesSelector)

  const { loading: loadingClusters, reload: reloadClusters } = useListAction(listClusters)
  const { loading: loadingCapiClusters, reload: reloadCapiClusters } = useListAction(
    listCapiClusters,
  )
  //need to get addons seperately for capi clusters
  const { loading: loadingClusterAddons, reload: reloadAddons } = useListAction(
    listAllClusterAddons,
  )

  const qbertClusters = useSelector(defaultParamsClusterSelector)
  const capiClusters = useSelector(defaultParamsCapiClusterSelector)

  const clusters = useMemo(() => [...qbertClusters, ...capiClusters], [qbertClusters, capiClusters])

  const publishedProfiles = useMemo(
    () => profiles.filter((profile) => profile.status.phase === 'published'),
    [profiles],
  )
  const mappedClusters = useMemo(() => {
    if (clusters.length && profiles.length) {
      const allBindings = profiles
        .map((profile) => {
          return profile.bindings
        })
        .flat()
      const bindingsByClusterId = indexBy(
        path<string>(['spec', 'clusterRef']),
        allBindings,
      )
      return clusters.map((cluster) => {
        return {
          ...cluster,
          hasBinding: !!bindingsByClusterId[cluster.uuid],
        }
      })
    }
    return clusters
  }, [clusters, profiles])
  const setupValidator = (validate) => {
    validatorRef.current = { validate }
  }

  const submitStep = useCallback(async () => {
    const isValid = await validatorRef.current.validate()
    if (!isValid) {
      return false
    }
    setSubmitting(true)
    try {
      const response: any = await getDriftAnalysis({
        cluster: wizardContext.clusters[0],
        profileName: wizardContext.profiles[0].name,
      })
      setBindingName(response.metadata?.name)
      setSubmitting(false)
      setShowDialog(true)
    } catch (err) {
      setSubmitting(false)
      return false
    }
    return false
  }, [wizardContext])

  useEffect(() => {
    // submitStep will always return false and prevent the user from
    // proceeding to the next step because we only want to move on to
    // the next step when the user closes the dialog box
    onNext(submitStep)
  }, [submitStep])

  const proceed = () => {
    // Move on to the next step
    setShowDialog(false)
    onNext()
    handleNext()
  }

  const refresh = () => {
    reloadClusters(true)
    reloadCapiClusters(true)
    reloadAddons(true)
  }

  return (
    <>
      <ValidatedForm
        classes={{ root: classes.validatedFormContainer }}
        initialValues={wizardContext}
        onSubmit={setWizardContext}
        triggerSubmit={setupValidator}
        elevated={false}
      >
        <FormFieldCard title="Select Profile">
          <Text variant="body2">
            Select a profile to analyze the drift if it is applied to a selected cluster. Only
            available profiles that are <b>active and published</b> are shown below.
          </Text>
          <ListTableField
            id="profiles"
            data={publishedProfiles}
            loading={profilesLoading}
            columns={profileColumns}
            onChange={(value) => setWizardContext({ profiles: value })}
            value={wizardContext.profiles}
            uniqueIdentifier="id"
            required
          />
        </FormFieldCard>
        <FormFieldCard title="Select Cluster">
          <Text variant="body2">
            Select a cluster to analyze the drift if the profile selected above is applied to it. A
            profile agent will need to be deployed to analyze the drift.
          </Text>
          <ListTableField
            id="clusters"
            data={mappedClusters}
            loading={loadingClusters || loadingCapiClusters || loadingClusterAddons}
            columns={clusterColumns}
            onChange={(value) => setWizardContext({ clusters: value })}
            value={wizardContext.clusters}
            checkboxCond={profileAgentInstalled}
            uniqueIdentifier="uuid"
            onReload={refresh}
            required
          />
        </FormFieldCard>
        {showDialog && bindingName && (
          <DryRunDialog
            handleClose={() => setShowDialog(false)}
            onFinish={proceed}
            bindingName={bindingName}
            setWizardContext={setWizardContext}
            type="Drift"
          />
        )}
      </ValidatedForm>
    </>
  )
}

export default ClusterProfileSelection
