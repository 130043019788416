// Libs
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'

// Utils
import { capitalizeString } from 'utils/misc'
import generateTestId from 'utils/test-helpers'

// Hooks
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

// Components
import Text from 'core/elements/Text'
import IconButton from 'core/elements/button/IconButton'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import PieUsageWidget from 'core/components/widgets/PieUsageWidget'
import DonutWidget from 'core/components/widgets/DonutWidget'

// Models
import { StatusCardProps, IStatusCardQuantity } from './card-templates/model'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'

interface StyleProps {
  actionRow: boolean
  chartRow: boolean
  draggable: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  headerIcon: {
    color: theme.components.card.text,
    gridArea: 'status-card-icon',
    display: 'grid',
    alignItems: 'center',
    minWidth: 20,
    minHeight: 20,
  },
  spinner: {
    marginLeft: theme.spacing(1),
  },
  contentContainer: {
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateRows: ({ chartRow }) => `max-content${chartRow ? ' 1fr' : ''}`,
    minWidth: '300px',
    minHeight: '110px',
    border: `solid 1px ${theme.components.card.border}`,
    borderRadius: 6,
    padding: theme.spacing(3, 1.5),
    backgroundColor: theme.components.card.background,
    gap: 16,
    cursor: ({ draggable }) => (draggable ? 'move' : 'default'),
  },
  text: {
    color: theme.components.card.text,
    fontWeight: 100,
    backgroundColor: theme.components.card.background,
    padding: theme.spacing(0, 2),
    lineHeight: 0.85,
    gridArea: 'status-card-count',
  },
  cardTitle: {
    color: theme.components.card.text,
    gridArea: 'status-card-title',
  },
  cardTitleLink: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gridTemplateAreas: `
      "status-card-count status-card-title"
      "status-card-count status-card-icon"
    `,
    rowGap: 8,
  },
  header: {
    pointerEvents: ({ draggable }) => (draggable ? 'none' : 'auto'),
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    paddingRight: theme.spacing(1),
    gridGap: theme.spacing(),
  },
  links: {
    pointerEvents: ({ draggable }) => (draggable ? 'none' : 'auto'),
  },
  chart: {
    pointerEvents: ({ draggable }) => (draggable ? 'none' : 'auto'),
    display: 'grid',
    alignContent: 'center',
    justifyItems: 'center',
    color: theme.components.card.text,
  },
  addAction: {
    gridArea: 'add-action',
  },
  listAction: {
    gridArea: 'list-action',
  },
}))

const defaultParams = {
  clusterId: null,
  namespace: null,
}

const StatusCard: FC<StatusCardProps> = ({
  data: { loader, selector, params },
  quantityFn,
  ...rest
}) => {
  const { globalParams } = useGlobalParams(useParams, defaultParams)
  const { useGlobalParams: useGlobalParameters } = params

  const { loading } = useListAction(loader, {
    params: useGlobalParameters ? { ...params, ...globalParams } : params,
  })
  const data = useSelectorWithParams<Record<string, unknown>, unknown[]>(selector, params)
  const { quantity, pieData, piePrimary, graphType } = quantityFn(data)

  return (
    <StatusCardPreview
      loading={loading}
      quantity={quantity}
      pieData={pieData}
      piePrimary={piePrimary}
      graphType={graphType}
      {...rest}
    />
  )
}

export const StatusCardPreview: FC<Partial<StatusCardProps> &
  IStatusCardQuantity & { loading: boolean }> = ({
  entity,
  route,
  addRoute,
  title,
  icon,
  onClick,
  actionRow = true,
  style,
  draggable,
  pieData: _pieData,
  loading,
  piePrimary: _piePrimary,
  graphType: _graphType = 'usage',
  quantity: _quantity = 0,
  data = undefined,
  quantityFn = undefined,
  sampleDataFn,
  ...rest
}) => {
  const dataToPullFrom = draggable
    ? sampleDataFn()
    : { quantity: _quantity, pieData: _pieData, piePrimary: _piePrimary, graphType: _graphType }
  const { pieData, piePrimary, graphType, quantity } = dataToPullFrom

  const classes = useStyles({ chartRow: !!pieData, actionRow, draggable })

  const GraphComponent = graphType === 'donut' ? DonutWidget : PieUsageWidget
  const isDraggable = draggable && !!(rest as any).onDragStart
  return (
    <article
      data-testid={generateTestId(entity, 'card')}
      id={entity}
      className={classes.contentContainer}
      style={style}
      {...rest}
      onClick={onClick}
      draggable={isDraggable}
    >
      <header className={classes.header}>
        <Link to={!isDraggable ? route : null} className={classes.cardTitleLink}>
          <Text data-testid={generateTestId(entity, 'count')} variant="h1" className={classes.text}>
            {loading ? <CircularProgress size={38} color="inherit" /> : quantity}
          </Text>
          <Text variant="caption1" component="h2" className={classes.cardTitle}>
            {title}
          </Text>
          <FontAwesomeIcon solid className={classes.headerIcon}>
            {icon}
          </FontAwesomeIcon>
        </Link>
        {actionRow && addRoute && (
          <div data-testid={generateTestId(entity, 'card', 'div1')} className={classes.links}>
            <Link to={!isDraggable ? addRoute : null} className={classes.addAction}>
              <IconButton icon="plus" info={`Add ${capitalizeString(entity)}`} />
            </Link>
          </div>
        )}
      </header>
      {pieData && (
        <div className={classes.chart}>
          {!loading && <GraphComponent sideLength={216} primary={piePrimary} data={pieData} />}
        </div>
      )}
    </article>
  )
}

export default StatusCard
