import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import { someAsync } from 'utils/async'
import { ensureArray, pathStr } from 'utils/fp'
import { flatten } from 'ramda'
import CreateAction from 'core/actions/CreateAction'
import { trackEvent } from 'utils/tracking'
import DeleteAction from 'core/actions/DeleteAction'
import UpdateAction from 'core/actions/UpdateAction'

const { qbert } = ApiClient.getInstance()

export const ingressActions = ActionsSet.make<DataKeys.Ingresses>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.Ingresses],
  cacheKey: DataKeys.Ingresses,
})

export const listIngresses = ingressActions.add(
  new ListAction<DataKeys.Ingresses, { clusterId: string; namespace?: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Ingresses', params)
    const { namespace, clusterId } = params
    return someAsync(
      ensureArray(namespace).map((name) => qbert.getIngresses(clusterId, name)),
    ).then(flatten)
  }),
)

export const createIngress = ingressActions.add(
  new CreateAction<DataKeys.Ingresses, { clusterId: string; namespace: string; body: any }>(
    async ({ clusterId, namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new Ingress', {
        clusterId,
        namespace,
        body,
      })
      const created = await qbert.createIngress(clusterId, namespace, body)
      trackEvent('Create New Ingress', {
        clusterId,
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const updateIngress = ingressActions.add(
  new UpdateAction<
    DataKeys.Ingresses,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Ingress', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn = requestType === 'patch' ? qbert.patchIngress : qbert.updateIngress
    const updatedIngress = await updateFn({
      clusterId,
      namespace,
      name,
      body,
      contentType,
    })
    trackEvent('Update Ingress', { clusterId, namespace, name })
    return updatedIngress
  }),
)

export const deleteIngress = ingressActions.add(
  new DeleteAction<DataKeys.Ingresses, { clusterId: string; namespace: string; name: string }>(
    async ({ clusterId, namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Ingress', {
        clusterId,
        namespace,
        name,
      })
      await qbert.deleteIngress(clusterId, namespace, name)
    },
  ),
)
