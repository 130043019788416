import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ModalForm from 'core/elements/modal/ModalForm'
import { updateNetworkPlugin } from '../actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Note from 'core/components/validatedForm/Note'
import Text from 'core/elements/Text'
import ExternalLink from 'core/components/ExternalLink'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import useParams from 'core/hooks/useParams'
import TextField from 'core/components/validatedForm/TextField'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import UnitPicklist from 'app/plugins/kubevirt/components/virtual-machines/create/UnitPicklist'
import { middleLeft } from 'core/elements/menu/defaults'
import { hexValidator } from 'core/utils/fieldValidators'
import { enableOvsWithDpdkLink } from 'k8s/links'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: 'grid',
    gap: 32,
    padding: '16px 40px 40px 32px',
  },
  buttons: {
    display: 'grid',
    gap: 16,
    gridTemplateColumns: '1fr 1fr',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    background: theme.components.button.secondary.background,
    padding: 8,
  },
  noteContents: {
    display: 'grid',
    gap: 24,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
  text: {
    display: 'grid',
    gap: 8,
  },
  steps: {
    display: 'grid',
    gap: 16,
  },
  step: {
    display: 'grid',
    gap: 16,
    gridTemplateColumns: 'max-content auto',
    alignItems: 'center',
  },
  stepNumber: {
    background: theme.components.badge.unknown.background,
    width: 24,
    height: 24,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fields: {
    display: 'grid',
    gap: 24,
  },
  hugepageFields: {
    display: 'grid',
    gridTemplateColumns: 'auto max-content 120px',
    gap: 8,
  },
  dash: {
    alignSelf: 'end',
    position: 'relative',
    bottom: 12,
  },
}))

const Step = ({ number, label }) => {
  const classes = useStyles()
  return (
    <div className={classes.step}>
      <div className={classes.stepNumber}>
        <Text variant="caption1">{number}</Text>
      </div>
      <Text variant="caption1">{label}</Text>
    </div>
  )
}

const defaultParams = {
  enableDpdk: false,
  lcoreMask: '0x2',
  socketMem: '1024',
  pmdCpuMask: '0xF0',
  hugepageMemory: '1',
  hugepageMemoryUnit: 'Gi',
}

export default function EnableOvsModal({ networkPlugin, open = true, onClose }) {
  const classes = useStyles()

  const { params, updateParams, setParams } = useParams<{
    enableDpdk: boolean
    lcoreMask: string
    socketMem: string
    pmdCpuMask: string
    hugepageMemory: string
    hugepageMemoryUnit: string
  }>(defaultParams)

  const { update: updateFn, updating, error, reset } = useUpdateAction(updateNetworkPlugin)

  const handleClose = () => {
    reset()
    onClose()
  }

  const submitForm = useCallback(async () => {
    const body = {
      ...networkPlugin?.spec?.plugins,
      ovs: {
        dpdk: params?.enableDpdk
          ? {
              lcoreMask: params?.lcoreMask,
              socketMem: params?.socketMem,
              pmdCpuMask: params?.pmdCpuMask,
              hugepageMemory: `${params?.hugepageMemory}${params?.hugepageMemoryUnit}`,
            }
          : undefined,
      },
    }

    const ops = []
    ops.push({
      op: 'replace',
      path: '/spec/plugins',
      value: body,
    })

    const { success } = await updateFn({
      ...networkPlugin,
      body: ops,
      requestType: 'patch',
      contentType: 'application/json-patch+json',
    })
    if (success) {
      handleClose()
    }
  }, [networkPlugin, handleClose])

  return (
    <ModalForm
      open={open}
      title={`Enable: OVS`}
      onSubmit={submitForm}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Enable`}
      maxWidth={528}
    >
      <div className={classes.container}>
        <Note title="Pre-requisite Worker Nodes Configuration:">
          <div className={classes.noteContents}>
            <Text variant="body2">Follow the steps in the documentation:</Text>
            <div className={classes.buttons}>
              {/* TODO: Need the doc link for this one */}
              <ExternalLink url={enableOvsWithDpdkLink}>
                <div className={classes.button}>
                  <FontAwesomeIcon>arrow-up-right-from-square</FontAwesomeIcon>
                  <Text variant="caption1">OVS</Text>
                </div>
              </ExternalLink>
              <ExternalLink url={enableOvsWithDpdkLink}>
                <div className={classes.button}>
                  <FontAwesomeIcon>arrow-up-right-from-square</FontAwesomeIcon>
                  <Text variant="caption1">OVS + DPDK</Text>
                </div>
              </ExternalLink>
            </div>
          </div>
        </Note>
        <Text variant="body2">
          <b>Note:</b> Enable DPDK to configure its parameters.
        </Text>
        <div className={classes.fields}>
          <ToggleSwitch
            label="Enable DPDK"
            active={!!params?.enableDpdk}
            onClick={(value) => updateParams({ enableDpdk: value })}
          />
          {params?.enableDpdk && (
            <>
              <TextField
                id="lcoreMask"
                label="Lcore Mask"
                placeholder="Enter value..."
                onChange={(value) => updateParams({ lcoreMask: value })}
                value={params?.lcoreMask}
                info="Must be a hex value"
                align={middleLeft.align}
                offset={middleLeft.offset}
                origin="center left"
                validations={[hexValidator]}
                required
              />
              <TextField
                id="socketMem"
                label="Socket Memory"
                placeholder="Enter value..."
                onChange={(value) => updateParams({ socketMem: value })}
                value={params?.socketMem}
                info="Comma separated list of memory to pre-allocate from hugepages on specific sockets"
                align={middleLeft.align}
                offset={middleLeft.offset}
                origin="center left"
                required
              />
              <TextField
                id="pmdCpuMask"
                label="PMD CPU Mask"
                placeholder="Enter value..."
                onChange={(value) => updateParams({ pmdCpuMask: value })}
                value={params?.pmdCpuMask}
                info="Must be a hex value"
                align={middleLeft.align}
                offset={middleLeft.offset}
                origin="center left"
                validations={[hexValidator]}
                required
              />
              <div className={classes.hugepageFields}>
                <TextField
                  id="hugepageMemory"
                  label="Hugepage Memory"
                  placeholder="Enter value..."
                  type="number"
                  onChange={(value) => updateParams({ hugepageMemory: value })}
                  value={params?.hugepageMemory}
                  info="The amount of memory for hugepages that pod will consume (# of hugepages * hugepagesize)"
                  required
                />
                <Text variant="body2" className={classes.dash}>
                  -
                </Text>
                <UnitPicklist
                  name="hugepageMemoryUnit"
                  label="Size Type"
                  value={params?.hugepageMemoryUnit}
                  onChange={(value) => updateParams({ hugepageMemoryUnit: value })}
                />
              </div>
            </>
          )}
        </div>
        <hr className={classes.divider} />
        <div className={classes.text}>
          <Text variant="caption1">OVS Enable Post Steps</Text>
          <Text variant="body2">
            Create the following resources after enabling OVS on this cluster.
          </Text>
        </div>
        <div className={classes.steps}>
          <Step number={1} label="Host Network Template" />
          <Step number={2} label="Network Attachment Definition" />
        </div>
      </div>
    </ModalForm>
  )
}
