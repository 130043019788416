import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import NumberedStepLabel from 'core/components/validatedForm/NumberedStepLabel'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { remove, update } from 'ramda'
import { validIpv4Validator, validIpv6Validator } from 'core/utils/fieldValidators'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    display: 'grid',
    gap: 24,
  },
  addLabel: {
    width: 'fit-content',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 8,
  },
  icon: {
    color: theme.components.badge.primary.color,
  },
  minusIcon: {
    color: theme.components.badge.primary.color,
    cursor: 'pointer',
    position: 'relative',
    top: 14,
  },
  withRemove: {
    display: 'grid',
    gridTemplateColumns: 'auto max-content',
    alignItems: 'center',
    gap: 24,
  },
}))

export default function InterfaceConfigurationFields({ index, values, updateField, isActive }) {
  const classes = useStyles()

  // Todo: can make a generic function for below, and use the field property as distinction
  const addItem = useCallback(
    (fieldId) => {
      updateField(fieldId, index)([...values[fieldId], ''])
    },
    [values, updateField],
  )
  const removeItem = useCallback(
    (fieldId, itemIndex) => {
      updateField(fieldId, index)(remove(itemIndex, 1, values?.[fieldId]))
    },
    [values, updateField],
  )

  return (
    <div className={classes.fields}>
      <NumberedStepLabel step={index + 1} title="Interface Config" />
      <TextField
        id={`interfaceConfigs.${index}.name`}
        label="Name"
        placeholder="Enter value..."
        onChange={(value) => updateField('name', index)(value)}
        value={values?.name}
        required={isActive}
      />
      <TextField
        id={`interfaceConfigs.${index}.mtu`}
        label="MTU"
        placeholder="Enter value..."
        onChange={(value) => updateField('mtu', index)(value)}
        value={values?.mtu}
        type="number"
      />
      <Text variant="caption1">VLAN</Text>
      {values?.vlanIds.map((vlanId, vlanIndex) => (
        <div className={classes.withRemove} key={vlanIndex}>
          <TextField
            id={`interfaceConfigs.${index}.vlanIds.${vlanIndex}`}
            label="ID"
            placeholder="Enter value..."
            onChange={(value) => {
              const updatedVlanIds = update(vlanIndex, value, values?.vlanIds)
              updateField('vlanIds', index)(updatedVlanIds)
            }}
            value={vlanId}
            required={isActive}
          />
          <FontAwesomeIcon
            onClick={() => removeItem('vlanIds', vlanIndex)}
            className={classes.minusIcon}
            size="xl"
            solid
          >
            circle-minus
          </FontAwesomeIcon>
        </div>
      ))}
      <div className={classes.addLabel} onClick={() => addItem('vlanIds')}>
        <FontAwesomeIcon className={classes.icon} size="xl" solid>
          circle-plus
        </FontAwesomeIcon>
        <Text variant="body2">Add VLAN ID</Text>
      </div>
      <Text variant="caption1">IPV4 Address</Text>
      {values?.ipv4Addresses.map((ipAddress, ipIndex) => (
        <div className={classes.withRemove} key={ipIndex}>
          <TextField
            id={`interfaceConfigs.${index}.ipv4Addresses.${ipIndex}`}
            label="IPV4 Address"
            placeholder="Enter value..."
            onChange={(value) => {
              const updatedAddresses = update(ipIndex, value, values?.ipv4Addresses)
              updateField('ipv4Addresses', index)(updatedAddresses)
            }}
            value={ipAddress}
            required={isActive}
            validations={[validIpv4Validator]}
          />
          <FontAwesomeIcon
            onClick={() => removeItem('ipv4Addresses', ipIndex)}
            className={classes.minusIcon}
            size="xl"
            solid
          >
            circle-minus
          </FontAwesomeIcon>
        </div>
      ))}
      <div className={classes.addLabel} onClick={() => addItem('ipv4Addresses')}>
        <FontAwesomeIcon className={classes.icon} size="xl" solid>
          circle-plus
        </FontAwesomeIcon>
        <Text variant="body2">Add IPV4 Address</Text>
      </div>
      <Text variant="caption1">IPV6 Address</Text>
      {values?.ipv6Addresses.map((ipAddress, ipIndex) => (
        <div className={classes.withRemove} key={ipIndex}>
          <TextField
            id={`interfaceConfigs.${index}.ipv6Addresses.${ipIndex}`}
            label="IPV6 Address"
            placeholder="Enter value..."
            onChange={(value) => {
              const updatedAddresses = update(ipIndex, value, values?.ipv6Addresses)
              updateField('ipv6Addresses', index)(updatedAddresses)
            }}
            value={ipAddress}
            required={isActive}
            validations={[validIpv6Validator]}
          />
          <FontAwesomeIcon
            onClick={() => removeItem('ipv6Addresses', ipIndex)}
            className={classes.minusIcon}
            size="xl"
            solid
          >
            circle-minus
          </FontAwesomeIcon>
        </div>
      ))}
      <div className={classes.addLabel} onClick={() => addItem('ipv6Addresses')}>
        <FontAwesomeIcon className={classes.icon} size="xl" solid>
          circle-plus
        </FontAwesomeIcon>
        <Text variant="body2">Add IPV6 Address</Text>
      </div>
    </div>
  )
}
