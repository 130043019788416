import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { someAsync } from 'utils/async'
import { trackEvent } from 'utils/tracking'
import { ensureArray, pathStr } from 'utils/fp'
import DeleteAction from 'core/actions/DeleteAction'
import UpdateAction from 'core/actions/UpdateAction'
import { flatten } from 'ramda'

const { qbert } = ApiClient.getInstance()

export const networkActions = ActionsSet.make<DataKeys.Networks>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.Networks,
  cacheKey: DataKeys.Networks,
})

export const listNetworks = networkActions.add(
  new ListAction<DataKeys.Networks, { clusterId: string; namespace?: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to load networks', params)
    const { clusterId, namespace } = params

    return someAsync(ensureArray(namespace).map((name) => qbert.getNetworks(clusterId, name))).then(
      flatten,
    )
    // return qbert.getNetworks(clusterId, namespace)
  }),
)

export const createNetwork = networkActions.add(
  new CreateAction<DataKeys.Networks, { clusterId: string; namespace: string; body: any }>(
    async ({ clusterId, namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new network attachment definition', {
        clusterId,
        namespace,
        body,
      })
      const created = await qbert.createNetwork(clusterId, namespace, body)
      trackEvent('Create New NetworkAttachmentDefinition', {
        clusterId,
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const updateNetwork = networkActions.add(
  new UpdateAction<
    DataKeys.Networks,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update network attachment definition', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn = requestType === 'patch' ? qbert.patchNetwork : qbert.updateNetwork
    const updated = await updateFn({
      clusterId,
      namespace,
      name,
      body,
      contentType,
    })
    trackEvent('Update Network Attachment Definition', { clusterId, namespace, name })
    return updated
  }),
)

export const deleteNetwork = networkActions.add(
  new DeleteAction<
    DataKeys.Networks,
    { clusterId: string; namespace: string; name: string; id: string }
  >(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete network attachment definition', {
      id,
      clusterId,
      namespace,
      name,
    })
    await qbert.deleteNetwork(clusterId, namespace, name)
    trackEvent('Delete Network', { clusterId, namespace, name, id })
  }),
)
