import { GetClusterDeploymentsItem, IGenericResource } from 'api-client/qbert.model'
import { IPodSelector } from '../pods/model'
import { IServicesSelector } from '../services/model'

export enum IDeploymentDetailsPageTabs {
  Overview = 'overview',
  Pods = 'pods',
  Events = 'events',
  Yaml = 'yaml',
  Logs = 'logs',
}

export interface IDeploymentSelector extends IGenericResource<GetClusterDeploymentsItem> {
  id: string
  name: string
  kind: string
  clusterName: string
  namespace: string
  dashboardUrl: string
  created: string
  selectors: Record<string, string>
  pods: number
  runningPods: number
  creationTimestamp: string

  labels: GetClusterDeploymentsItem['metadata']['labels']
  annotations: GetClusterDeploymentsItem['metadata']['annotations']
  deploymentPods: IPodSelector[]

  deploymentServices: IServicesSelector[]

  strategyType: string
  age: string
}
