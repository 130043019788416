import React, { useCallback } from 'react'
import jsYaml from 'js-yaml'
import PicklistField from 'core/components/validatedForm/DropdownField'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import { codeMirrorOptions } from 'app/constants'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { switchCase } from 'utils/fp'
import moize from 'moize'
import { customValidator, requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import { uncamelizeString } from 'utils/misc'
import { routes } from 'core/utils/routes'
import generateTestId from 'app/utils/test-helpers'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createDaemonSet } from 'k8s/components/daemon-sets/actions'
import { createJob, createCronjob } from 'k8s/components/cronjobs/actions'
import { createStatefulSet } from 'k8s/components/stateful-sets/actions'
import { createReplicaSet } from 'k8s/components/replica-sets/actions'
import { createPod } from 'k8s/components/pods/new-actions'
import { createDeployment } from 'k8s/components/deployments/new-actions'
import { createService } from 'k8s/components/services/new-actions'
import ExternalLink from 'core/components/ExternalLink'
import { createDeploymentLink, createPodLink, createServiceLink } from 'k8s/links'
import SimpleLink from 'core/components/SimpleLink'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import usePluginRouter from 'core/hooks/usePluginRouter'

const moizedYamlLoad = moize(jsYaml.load, {
  maxSize: 10,
})

const codeMirrorValidations = [
  requiredValidator,
  yamlValidator,
  customValidator((yaml, formFields) => {
    try {
      const body = moizedYamlLoad(yaml)
      return body.kind.toLowerCase() === formFields.resourceType.toLowerCase()
    } catch (err) {
      return true
    }
  }, 'Resource type does not match with selected resource type above'),
]

const linksByResourceType = {
  pod: { icon: 'cubes', url: createPodLink, label: 'How do I create a pod?' },
  deployment: {
    icon: 'window',
    url: createDeploymentLink,
    label: 'How do I create a deployment?',
  },
  service: {
    icon: 'tasks-alt',
    url: createServiceLink,
    label: 'How do I create a service?',
  },
}

const templateNameLinksByResourceType = {
  pod: 'View Redis Example',
  deployment: 'View Nginx Example',
  service: 'View App Example',
}

const yamlTemplates = {
  pod: `apiVersion: v1
kind: Pod
metadata:
  name: redis-django
  labels:
    app: web
spec:
  containers:
    - name: key-value-store
      image: redis
      ports:
        - containerPort: 6379
    - name: frontend
      image: django
      ports:
        - containerPort: 8000
`,
  deployment: `apiVersion: apps/v1
kind: Deployment
metadata:
  name: nginx-deployment
spec:
  selector:
    matchLabels:
      app: nginx
  replicas: 3 # tells deployment to run 2 pods matching the template
  template:
    metadata:
      labels:
        app: nginx
    spec:
      containers:
      - name: nginx
        image: nginx:1.14.2
        ports:
        - containerPort: 80
`,
  service: `apiVersion: v1
kind: Service
metadata:
  name: myapp
spec:
  ports:
    - port: 8765
      targetPort: 9376
  selector:
    app: example
`,
}

interface Props {
  addRoute: Route
  resourceType:
    | 'pod'
    | 'deployment'
    | 'service'
    | 'statefulSet'
    | 'replicaSet'
    | 'daemonSet'
    | 'job'
    | 'cronjob'
}

export default function AddResourcePage({ resourceType, addRoute }: Props) {
  const { history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const defaultParams = {
    resourceType,
    clusterId: '',
    namespace: '',
    yaml: '',
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const listPageRoutes = {
    pod: currentPluginId === 'kubevirt' ? routes.kubevirtPods.list.path() : routes.pods.list.path(),
    deployment: routes.deployments.list.path(),
    service:
      currentPluginId === 'kubevirt'
        ? routes.kubevirtServices.list.path()
        : routes.services.list.path(),
    statefulSet: routes.statefulSets.list.path(),
    replicaSet: routes.replicaSets.list.path(),
    daemonSet: routes.daemonSets.list.path(),
    job: routes.cronjobs.list.path(),
    cronjob: routes.cronjobs.list.path(),
  }

  const createFn = switchCase({
    pod: createPod,
    deployment: createDeployment,
    service: createService,
    statefulSet: createStatefulSet,
    replicaSet: createReplicaSet,
    daemonSet: createDaemonSet,
    job: createJob,
    cronjob: createCronjob,
  })(params.resourceType)

  const { update, updating, error, reset } = useUpdateAction(createFn)

  const submitForm = async (params) => {
    const { success } = await update(params)
    if (success) handleClose()
  }
  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(listPageRoutes[resourceType])
  }

  const insertYamlTemplate = useCallback(
    () =>
      updateParams({
        yaml: yamlTemplates[params.resourceType],
      }),
    [params],
  )

  const { icon, url, label } = linksByResourceType[params.resourceType] || {}
  const formattedResourceName = uncamelizeString(params.resourceType)

  const renderLink = useCallback(() => {
    if (!url) return null
    return (
      <ExternalLink url={url} icon={icon}>
        {label}
      </ExternalLink>
    )
  }, [url])

  const renderViewTemplateLink = useCallback(() => {
    const template = templateNameLinksByResourceType[resourceType]
    if (!template) return null
    return (
      <SimpleLink
        data-testid={generateTestId('view', formattedResourceName, 'template', 'button')}
        src=""
        onClick={insertYamlTemplate}
      >
        {templateNameLinksByResourceType[params.resourceType]}
      </SimpleLink>
    )
  }, [resourceType])

  return (
    <ModalForm
      route={addRoute}
      title={`Add ${formattedResourceName}`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Add ${formattedResourceName}`}
    >
      <>
        <FormFieldSection title="Choose a Destination" link={renderLink()} step={1}>
          <PicklistField
            DropdownComponent={ClusterPicklist}
            id="clusterId"
            data-testid={generateTestId('clusterid', 'dropdown')}
            label="Cluster"
            onChange={getParamsUpdater('clusterId')}
            value={params.clusterId}
            showAll={false}
            setInitialCluster
            required
          />
          <PicklistField
            DropdownComponent={NamespacePicklist}
            disabled={!params.clusterId}
            id="namespace"
            data-testid={generateTestId('namespace', 'dropdown')}
            label="Namespace"
            onChange={getParamsUpdater('namespace')}
            clusterId={params.clusterId}
            value={params.namespace}
            showAll={false}
            setInitialNamespace
            required
          />
        </FormFieldSection>
        <FormFieldSection title="Resource YAML" link={renderViewTemplateLink()} step={2}>
          <CodeMirror
            variant="light"
            id="yaml"
            data-testid={generateTestId('yaml')}
            validations={codeMirrorValidations}
            onChange={getParamsUpdater('yaml')}
            value={params.yaml}
            options={codeMirrorOptions}
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
