import React, { useEffect, useMemo } from 'react'
import { switchCase } from 'utils/fp'
import { capitalizeString } from 'utils/misc'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import StatusPicklist from './StatusPicklist'
import SeverityPicklist from './SeverityPicklist'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, allKey, LoadingGifs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'

import StackedAreaChart from 'core/components/graphs/StackedAreaChart'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import ExternalLink from 'core/components/ExternalLink'

import AlarmsChartTooltip from './AlarmsChartTooltip'
import TimePicklistDefault from './TimePicklist'
import AlarmDetailsLink from './AlarmDetailsLink'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Progress from 'core/components/progress/Progress'
import SnoozeAlarmDialog from './SnoozeAlarmDialog'
import DocumentMeta from 'core/components/DocumentMeta'
import { ArrayElement } from 'core/actions/Action'
import { listAlerts, listTimeSeriesAlerts } from './new-actions'
import { makeAlertsSelector, makeTimeSeriesSelector } from './selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import { GridViewColumn } from 'core/elements/grid/Grid'
import ListContainer from 'core/containers/ListContainer'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import Badge from 'core/elements/badge'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import useGlobalParams from 'core/hooks/useGlobalParams'

// Potential todo for time series table: Distinguish alerts by cluster instead
// of aggregating them into one number

const TimePicklist: any = TimePicklistDefault

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridGap: 32,
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chartContainer: {
    border: `1px solid ${theme.components.table.border}`,
    padding: theme.spacing(2, 4, 0, 3),
    '& .progressContent.loading': {
      display: 'none',
    },
    background: theme.components.table.background,
    borderRadius: 4,
  },
  chartLabel: {
    flexGrow: 0,
    fontSize: '18px',
  },
  chartFilters: {
    display: 'flex',
    flexGrow: 0,
  },
  chartRefresh: {
    fontSize: '18px',
    display: 'flex',
    marginRight: '15px',
  },
  clusterPicker: {
    flexGrow: 0,
    marginRight: '20px',
  },
  filters: {
    display: 'flex',
    flexGrow: 0,
  },
}))

const paramsAlertsSelector = makeAlertsSelector()
const timeSeriesAlertsSelector = makeTimeSeriesSelector()

type ModelDataKey = DataKeys.Alerts
type SelectorModel = ArrayElement<ReturnType<typeof paramsAlertsSelector>>

const chartKeys = [
  {
    name: 'warning',
    color: 'yellow.200',
    badge: 'warning' as const,
    icon: 'exclamation-triangle',
  },
  {
    name: 'critical',
    color: 'yellow.300',
    badge: 'danger' as const,
    icon: 'engine-warning',
  },
  {
    name: 'fatal',
    color: 'red.300',
    badge: 'error' as const,
    icon: 'skull-crossbones',
  },
]

type Params = {
  severity?: string
  chartTime?: string
  status?: string
  showNeverActive?: boolean
}

const defaultParams: Params = {
  severity: allKey,
  chartTime: '24.h',
  status: 'Active',
  showNeverActive: false,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Alerts', listTablePrefs)

const searchTargets = ['name', 'clusterName', 'exportedNamespace']

export const NameCell = ({ value, item }) => <AlarmDetailsLink display={value} alarm={item} />

export const TimeCell = ({ value }) => (value ? <DateAndTime value={value} /> : <div>N/A</div>)

const GrafanaLink = ({ value }) => (
  <ExternalLink className="no-wrap-text" icon="chart-line" url={value}>
    Grafana
  </ExternalLink>
)

export const SeverityTableCell = ({ value }) => {
  const key = chartKeys.find((key) => key.name === value)
  return key ? <Badge variant={key.badge} text={capitalizeString(value)} /> : <div>{value}</div>
}

const SummaryCell = ({ value }) => (
  <Tooltip message={value}>
    <Text variant="body2" maxWidth={350} lineClamp={2}>
      {value ? value : 'N/A'}
    </Text>
  </Tooltip>
)

export const getAlarmSeverityStatus = (severity) => {
  const variant = switchCase(
    {
      warning: 'warning',
      critical: 'danger',
      fatal: 'error',
    },
    'unknown',
  )(severity)
  return { variant, label: capitalizeString(severity || 'unknown') }
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    display: false,
    key: 'fingerprint',
    label: 'Fingerprint',
  },
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: NameCell,
  },
  {
    key: 'severity',
    label: 'Severity',
    CellComponent: createGridStatusCell({
      dataFn: getAlarmSeverityStatus,
    }),
  },
  {
    key: 'updatedAt',
    label: 'Time',
    CellComponent: TimeCell,
  },
  {
    key: 'summary',
    label: 'Rule Summary',
    CellComponent: SummaryCell,
  },
  { key: 'status', label: 'Status', formatFn: (val: string) => (val ? val : 'N/A') },
  {
    key: 'grafanaLink',
    label: 'Open in Grafana',
    CellComponent: GrafanaLink,
  },
  {
    key: 'clusterName',
    label: 'Cluster',
    formatFn: (val: string) => (val ? val : 'N/A'),
  },
  {
    key: 'labels',
    label: 'Labels',
    disableSorting: true,
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
  {
    display: false,
    key: 'startsAt',
    label: 'Starts At',
    CellComponent: TimeCell,
  },
  {
    display: false,
    key: 'endsAt',
    label: 'Ends At',
    CellComponent: TimeCell,
  },
]

export default function AlarmsListPage({ clusterId = null, hideDocumentMeta = false }) {
  const classes = useStyles({})
  const { allParams: params, getParamsUpdater, params: filterParams } = useGlobalParams(
    usePrefParams,
    defaultParams,
  )

  // Had to add this memoized value bc defining in-line would cause time series
  // list action to keep firing
  const memoizedClusterIds = useMemo(() => {
    return clusterId ? [clusterId] : params?.clusterId
  }, [params, clusterId])

  const { message, loading, reload: reloadAlerts } = useListAction(listAlerts, {
    params: {
      ...params,
      clusterId: memoizedClusterIds,
    },
    requiredParams: ['clusterId'],
  })
  const alerts = clusterId
    ? useSelectorWithParams(paramsAlertsSelector, {
        ...params,
        clusterId,
        useGlobalParams: false,
      })
    : useSelectorWithParams(paramsAlertsSelector, filterParams)

  // Provide specific param properties to timeSeries data loader
  // so that it doesn't reload unless those props are changed
  // time series alerts are not indexed, must provide clusterId array
  const { loading: timeSeriesLoading } = useListAction(listTimeSeriesAlerts, {
    params: {
      chartTime: params.chartTime,
      clusterId: memoizedClusterIds,
    },
    preserveParams: true,
  })
  const timeSeriesData = useSelectorWithParams(timeSeriesAlertsSelector, params)

  const filteredChartKeys = chartKeys.filter((key) => {
    return [allKey, key.name].includes(params.severity)
  })

  const filteredAlerts = alerts.filter((alert) => {
    return (
      [allKey, alert.severity].includes(params.severity) &&
      [allKey, alert.status].includes(params.status)
    )
  })

  const batchActions = useMemo(
    () => [
      {
        label: 'Snooze',
        icon: 'snooze',
        BatchActionButton: getGridDialogButton(SnoozeAlarmDialog, {
          listTableParams: params,
        }),
      },
    ],
    [params],
  )

  useEffect(() => {
    // workaround to nullify cache on reload / cluster change to match time series graph
    reloadAlerts(true)
  }, [params.clusterId])

  return (
    <>
      {!hideDocumentMeta && <DocumentMeta title="Alarms" />}
      <div className={classes.root}>
        <div className={classes.chartContainer}>
          <div className={classes.header}>
            {/* All filters are controlled, just leave them up here to match wireframes */}
            <div className={classes.filters}>
              <div className={classes.clusterPicker}>
                <StatusPicklist
                  name="status"
                  label="Status"
                  selectFirst={false}
                  onChange={getParamsUpdater('status')}
                  value={params.status}
                />
              </div>
              <div>
                <SeverityPicklist
                  name="severity"
                  label="Severity"
                  selectFirst={false}
                  onChange={getParamsUpdater('severity')}
                  value={params.severity}
                />
              </div>
              <div>
                <TimePicklist onChange={getParamsUpdater('chartTime')} value={params.chartTime} />
              </div>
            </div>
          </div>
          <Progress
            loading={timeSeriesLoading}
            message={`Loading ${entityNamesByKey[DataKeys.AlertsTimeSeries]}...`}
            overlay={false}
            loadingImage={LoadingGifs.BluePinkTiles}
          >
            <StackedAreaChart
              values={timeSeriesData}
              keys={filteredChartKeys}
              xAxis="time"
              responsive
              CustomTooltip={<AlarmsChartTooltip keys={filteredChartKeys} />}
            />
          </Progress>
        </div>
        <ListContainer<ModelDataKey, SelectorModel>
          showBreadcrumbs={false}
          searchTargets={searchTargets}
          uniqueIdentifier="id"
          loading={loading}
          loadingMessage={message}
          onRefresh={reloadAlerts}
          data={filteredAlerts}
          columns={columns}
          getParamsUpdater={getParamsUpdater}
          batchActions={batchActions}
          multiSelection={false}
          label="Alarms"
          {...pick(listTablePrefs, params)}
        />
      </div>
    </>
  )
}
