import React, { useState } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import RuleDetailsDialog from './RuleDetailsDialog'
import { Alarm } from 'k8s/components/alarms/model'

interface Props {
  rule: Alarm
  display: string
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

const RuleDetailsLink = ({ display, rule }: Props) => {
  const [dialogOpened, setDialogOpened] = useState(false)
  const { link } = useStyles({})

  return (
    <>
      {dialogOpened && <RuleDetailsDialog rule={rule} onClose={() => setDialogOpened(false)} />}
      <span className={link} onClick={() => setDialogOpened(true)}>
        {display}
      </span>
    </>
  )
}

export default RuleDetailsLink
