import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { ISecretSelector } from '../model'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import CopyToClipboard from 'core/components/CopyToClipboard'
import IconButton from 'core/elements/button/IconButton'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import { encodeStr, decodeStr } from 'utils/misc'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateSecret } from '../actions'
import { assoc } from 'ramda'

type Props = {
  open: boolean
  onClose: () => void
  secretData: { key: string; value: string }
  secret: ISecretSelector
}

export default function SecretsDataInfoModal({ secret, secretData, open = false, onClose }: Props) {
  const classes = useStyles()

  const { update, updating: updatingSecret } = useUpdateAction(updateSecret)

  const [enableEdit, setEnableEdit] = useState(false)

  const [encoded, setEncoded] = useState(true)
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(secretData?.value)
    setEncoded(true)
    setEnableEdit(false)
  }, [open])

  const toggleEncode = () => {
    if (encoded) {
      setValue(decodeStr(value))
      setEncoded(false)
    } else {
      setValue(encodeStr(value))
      setEncoded(true)
    }
  }

  const handleSecretDataEdit = useCallback(async () => {
    const newValue = encoded ? value : encodeStr(value)
    const newData = assoc(secretData?.key, newValue, secret?.data)

    const ops = []
    ops.push({
      op: 'replace',
      path: '/data',
      value: newData,
    })

    const { success } = await update({
      ...secret,
      body: ops,
      requestType: 'patch',
      contentType: 'application/json-patch+json',
    })
    if (success) onClose()
  }, [enableEdit, encoded, value])

  return (
    <ModalForm
      open={open}
      title={`Key (${secretData?.key})`}
      onClose={onClose}
      onSubmit={enableEdit ? handleSecretDataEdit : null}
      submitTitle={`Save Changes`}
      submitting={updatingSecret}
    >
      <div className={classes.container}>
        <CopyToClipboard copyText={secretData.key} inline codeBlock={false}>
          <TextField id="keyInput" label="Key" value={secretData.key} />
        </CopyToClipboard>

        <CodeMirror
          variant="light"
          label="Value"
          className={classes.codeMirror}
          id="value"
          showSearchBar
          showCopyButton
          options={{
            mode: null,
            lineNumbers: false,
            lineWrapping: true,
            readOnly: enableEdit ? false : true,
            cursorHeight: enableEdit ? 1 : 0,
          }}
          value={value}
          onChange={(val) => setValue(val)}
          extraActions={
            <>
              <ToggleSwitch
                // className={classes.switch}
                label="Edit"
                active={enableEdit}
                onClick={(active) => setEnableEdit(active)}
                disabled={secret?.immutable}
              />
              <IconButton
                key="encodeDecode"
                icon={encoded ? 'eye-slash' : 'eye'}
                // color="inherit"
                onClick={toggleEncode}
              />
            </>
          }
        />
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  codeMirror: {
    '& .CodeMirror': {
      maxHeight: 'calc(100vh - 400px)',
      margin: theme.spacing(2),
      width: '712px',
    },
  },
}))
