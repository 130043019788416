import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  cards: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: theme.spacing(1),
    margin: '0 0 64px 0',
  },
}))

const CardsHeader = ({ children }) => {
  const classes = useStyles()
  return (
    <header>
      <div className={classes.cards}>{children}</div>
    </header>
  )
}

export default CardsHeader
