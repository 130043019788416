import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import AppInfoHeaderCard from './AppInfoHeaderCard'
import { loadDeployedAppDetails } from '../../new-actions'
import EditAppDeploymentFormModal from '../EditAppDeploymentFormModal'
import DeleteAppDeploymentDialog from '../DeleteAppDeploymentDialog'
import useToggler from 'core/hooks/useToggler'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { deployedAppDetailsSelector } from '../../selectors'

const defaultAppDetails = {
  clusterId: null,
  namespace: null,
  repository: '',
  chart: '',
  name: '',
  icon: null,
  appVersion: '',
  description: '',
  home: '',
}

export default function DeployedAppDetailsPage() {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const [showEditForm, toggleEditForm] = useToggler()
  const [showDeleteDialog, toggleDeleteDialog] = useToggler()
  const { clusterId, namespace, repository, chart, name } = match.params

  const { loading } = useListAction(loadDeployedAppDetails, {
    params: { clusterId, namespace, name },
  })

  const [deployedAppDetails = defaultAppDetails] = useSelectorWithParams(
    deployedAppDetailsSelector,
    {
      clusterId,
      namespace,
      name,
    },
  )

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        name,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [name])

  return (
    <>
      <DocumentMeta title="Deployed App Overview" breadcrumbs />
      {showEditForm && (
        <EditAppDeploymentFormModal
          deployedApp={deployedAppDetails}
          repository={repository}
          open={showEditForm}
          onClose={toggleEditForm}
        />
      )}
      {showDeleteDialog && (
        <DeleteAppDeploymentDialog
          clusterId={clusterId}
          namespace={namespace}
          chart={chart}
          name={name}
          onClose={toggleDeleteDialog}
        />
      )}
      <AppInfoHeaderCard
        appDetails={deployedAppDetails}
        loading={loading}
        handleEditClick={toggleEditForm}
        handleUninstallClick={toggleDeleteDialog}
      />
    </>
  )
}
