import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { trackEvent } from 'utils/tracking'
import { pathStr } from 'utils/fp'
import DeleteAction from 'core/actions/DeleteAction'
import UpdateAction from 'core/actions/UpdateAction'

const { qbert } = ApiClient.getInstance()

export const networkPluginActions = ActionsSet.make<DataKeys.NetworkPlugins>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.NetworkPlugins,
  cacheKey: DataKeys.NetworkPlugins,
})

export const listNetworkPlugins = networkPluginActions.add(
  new ListAction<DataKeys.NetworkPlugins, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get network plugins', params)
    return qbert.getNetworkPlugins(params.clusterId)
  }),
)

export const createNetworkPlugin = networkPluginActions.add(
  new CreateAction<DataKeys.NetworkPlugins, { clusterId: string; namespace: string; body: any }>(
    async ({ clusterId, namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new network plugin', {
        clusterId,
        namespace,
        body,
      })
      const created = await qbert.createNetworkPlugin(clusterId, namespace, body)
      trackEvent('Create New NetworkPlugin', {
        clusterId,
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const updateNetworkPlugin = networkPluginActions.add(
  new UpdateAction<
    DataKeys.NetworkPlugins,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update network plugin', {
      clusterId,
      namespace,
      name,
      body,
    })
    // To be confirmed if there is only a patch request or if put is available
    const updateFn = requestType === 'patch' ? qbert.patchNetworkPlugin : qbert.updateNetworkPlugin
    const updated = await updateFn({ clusterId, namespace, name, body, contentType })
    trackEvent('Update Network Plugin', { clusterId, namespace, name })
    return updated
  }),
)

export const deleteNetworkPlugin = networkPluginActions.add(
  new DeleteAction<
    DataKeys.NetworkPlugins,
    { clusterId: string; namespace: string; name: string; id: string }
  >(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete network plugin', {
      id,
      clusterId,
      namespace,
      name,
    })
    await qbert.deleteNetworkPlugin(clusterId, namespace, name)
    trackEvent('Delete Network Plugin', { clusterId, namespace, name, id })
  }),
)
