import Text from 'core/elements/Text'
import React, { FC } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { getProfileStatus } from '../RbacProfilesListPage'
import ProfilePublishDialog from 'k8s/components/rbac/profiles/ProfilePublishDialog'
import Badge from 'core/elements/badge/Badge'

const useStyles = makeStyles<Theme>((theme) => ({
  profileSummaryBox: {
    borderRadius: 4,
    background: theme.components.card.background,
    padding: theme.spacing(2, 3),
    display: 'flex',
    flexFlow: 'column nowrap',
    color: theme.components.card.text,
    margin: theme.spacing(0, 2),
    minWidth: 300,
    '& > h6': {
      marginBottom: theme.spacing(2),
    },
    '& > p': {
      margin: theme.spacing(1, 0),
    },
    '& > p > strong': {
      float: 'right',
    },
  },
  publishBtn: {
    '& > button': {
      width: '100%',
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.pink['500'],
      borderColor: theme.palette.pink['500'],
    },
    '& > button:hover': {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.pink['300'],
      borderColor: theme.palette.pink['300'],
    },
  },
}))

export interface ProfileSummaryProps {
  profile: {
    name: string
    status: {
      phase: string
    }
    baseClusterName: string
  }
  rolesCount: number
  clusterRolesCount: number
  roleBindingsCount: number
  clusterRoleBindingsCount: number
}

const ProfileSummaryBox: FC<ProfileSummaryProps> = ({
  profile,
  rolesCount,
  clusterRolesCount,
  roleBindingsCount,
  clusterRoleBindingsCount,
}) => {
  const classes = useStyles()
  const {
    name = '',
    status: { phase = 'unknown' },
    baseClusterName,
  } = profile

  const { variant, label = phase } = getProfileStatus(phase)
  return (
    <div className={classes.profileSummaryBox}>
      <Text variant="subtitle2">Profile Summary</Text>
      <p>
        Profile Name: <strong>{name}</strong>
      </p>
      <div>
        Status:{' '}
        <strong>
          <Badge variant={variant} text={label} />
        </strong>
      </div>
      <p>
        Base Cluster: <strong>{baseClusterName}</strong>
      </p>
      <br />
      <p>
        Total Roles: <strong>{rolesCount}</strong>
      </p>
      <p>
        Total Cluster Roles: <strong>{clusterRolesCount}</strong>
      </p>
      <p>
        Total Role Bindings: <strong>{roleBindingsCount}</strong>
      </p>
      <p>
        Total Cluster Role Bindings: <strong>{clusterRoleBindingsCount}</strong>
      </p>
      <br />
      {phase === 'draft' ? (
        <ProfilePublishDialog className={classes.publishBtn} profile={profile} />
      ) : null}
    </div>
  )
}

export default ProfileSummaryBox
