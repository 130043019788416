import { AppSelector } from 'app/store'
import { complement, filter, isNil, map, pipe } from 'ramda'
import { arrayIfEmpty } from 'utils/fp'
import { durationBetweenDates } from 'utils/misc'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { IDataKeys } from 'k8s/datakeys.model'
import { IDataVolumesSelector } from './model'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'

const getDataVolumeSource = (dataVolume) => {
  if (dataVolume?.spec?.source?.http) {
    return 'HTTP URL'
  } else if (dataVolume?.spec?.source?.registry) {
    return 'Registry URL'
  } else if (dataVolume?.spec?.source?.pvc) {
    return 'PVC'
  } else if (dataVolume?.spec?.source?.upload) {
    return 'Upload'
  } else if (dataVolume?.spec?.source?.blank) {
    return 'Blank'
  } else {
    return 'Other'
  }
}

const getDataVolumeSourceMetadata = (dataVolume) => {
  if (dataVolume?.spec?.source?.http) {
    return {
      url: dataVolume?.spec?.source?.http?.url,
    }
  } else if (dataVolume?.spec?.source?.registry) {
    return {
      url: dataVolume?.spec?.source?.registry?.url,
    }
  } else if (dataVolume?.spec?.source?.pvc) {
    return {
      name: dataVolume?.spec?.source?.pvc?.name,
    }
  } else if (dataVolume?.spec?.source?.upload) {
    return {}
  } else if (dataVolume?.spec?.source?.blank) {
    return {}
  } else {
    return {}
  }
}

export const dataVolumesSelector: AppSelector<IDataVolumesSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.DataVolumes>(
    DataKeys.DataVolumes,
    ['clusterId', 'namespace'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  (rawDataVolumes, allClusters): IDataVolumesSelector[] => {
    const clusters = {}
    allClusters.forEach((c) => {
      clusters[c.uuid] = c
    })
    return pipe<
      IDataKeys[DataKeys.DataVolumes],
      IDataVolumesSelector[],
      IDataVolumesSelector[],
      IDataVolumesSelector[]
    >(
      map((dataVolume) => {
        const { clusterId, id } = dataVolume
        const cluster = clusters[clusterId]
        if (!cluster) {
          // If no cluster is found, this item is invalid because the parent cluster has been deleted
          return null
        }
        const creationTimestamp = dataVolume?.metadata?.creationTimestamp
        return {
          ...dataVolume,
          labels: dataVolume?.metadata?.labels,
          annotations: dataVolume?.metadata?.annotations,
          clusterName: cluster?.name,
          creationTimestamp,
          age: durationBetweenDates({ labels: ['d'] })(creationTimestamp),
          source: getDataVolumeSource(dataVolume),
          sourceMetadata: getDataVolumeSourceMetadata(dataVolume),
        }
      }),
      filter(complement(isNil)),
      arrayIfEmpty,
    )(rawDataVolumes)
  },
)
