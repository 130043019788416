import React, { useMemo } from 'react'
import { switchCase } from 'utils/fp'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import Button from 'core/elements/button'
import ProfilePublishDialog from './ProfilePublishDialog'
import SimpleLink from 'core/components/SimpleLink'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import TooltipListBody from 'core/elements/tooltip/TooltipListBody'
import useReactRouter from 'use-react-router'
import { pick, prop, map } from 'ramda'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import DocumentMeta from 'core/components/DocumentMeta'
import { rbacProfilesSelector } from './selectors'
import { ArrayElement } from 'core/actions/Action'
import { listRbacProfiles, deleteRbacProfile } from './new-actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import ListContainer from 'core/containers/ListContainer'
import PollingData from 'core/components/PollingData'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import { capitalizeString } from 'utils/misc'
import { bottomRight } from 'core/elements/menu/defaults'

type ModelDataKey = DataKeys.RbacProfiles
type SelectorModel = ArrayElement<ReturnType<typeof rbacProfilesSelector>>

interface StyleParams {
  status?: string
}

const usePrefParams = createUsePrefParamsHook<TablePrefsParams>('RbacProfiles', listTablePrefs)

export const getProfileStatus = (phase) => {
  const variant = switchCase(
    {
      published: 'success',
      draft: 'primary',
      errored: 'error',
    },
    'unknown',
  )(phase)
  return { variant, label: capitalizeString(phase || 'unknown') }
}

const ProfileTypeItem = ({ items, title }) => {
  if (!items || !items?.length) return null
  return (
    <Tooltip
      align={bottomRight.align}
      offset={bottomRight.offset}
      customBody={<TooltipListBody items={items} />}
    >
      <SimpleLink src="">{title}</SimpleLink>
    </Tooltip>
  )
}

export const NameCell = ({ value, item }) => {
  return ['published'].includes(item?.status?.phase) ? (
    <SimpleLink src={routes.rbacProfiles.view.path({ id: item?.metadata?.uid })}>
      {value}
    </SimpleLink>
  ) : (
    <Text variant="body2" lineClamp={2}>
      {value}
    </Text>
  )
}

export const TypesTableCell = ({ item: profile }) => {
  return (
    <>
      <ProfileTypeItem title="Role" items={profile.roles} />
      <ProfileTypeItem title="Cluster Role" items={profile.clusterRoles} />
      <ProfileTypeItem title="Role Binding" items={profile.roleBindings} />
      <ProfileTypeItem title="Cluster Role Binding" items={profile.clusterRoleBindings} />
    </>
  )
}

const ClustersCell = ({ value }) => {
  return <ProfileTypeItem title={value?.length} items={map(prop('name'), value)} />
}

const ProfileActionButton = ({ item: profile }) => {
  if (profile.action === 'deploy') {
    return (
      <SimpleLink src={routes.rbacProfiles.deploy.path({ id: profile.metadata.uid })}>
        <Button>Deploy</Button>
      </SimpleLink>
    )
  } else if (profile.action === 'publish') {
    return <ProfilePublishDialog profile={profile} />
  }
  return <Button disabled>Deploy</Button>
}

const searchTargets = ['metadata.name']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'metadata.name', label: 'Name', CellComponent: NameCell },
  {
    key: 'status.phase',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: getProfileStatus,
    }),
  },
  { key: 'clusters', label: 'Active Clusters', CellComponent: ClustersCell },
  { key: 'roles', label: 'Types', CellComponent: TypesTableCell },
  { key: 'action', label: 'Action', CellComponent: ProfileActionButton },
]

export default function RbacProfilesListPage() {
  const { params, getParamsUpdater } = usePrefParams({})
  const { history } = useReactRouter()
  const { message, loading, reload } = useListAction(listRbacProfiles)
  const data = useSelector(rbacProfilesSelector)
  const pollingCondition = (data) => {
    return !!data.find((profile) => {
      return profile.status.phase === 'creating'
    })
  }

  const batchActions = useMemo(
    () => [
      {
        label: 'Manage Bindings',
        icon: 'tasks',
        handleAction: ([firstSelectedItem]) => {
          history.push(routes.rbacProfiles.deleteBindings.path({ id: firstSelectedItem.id }))
        },
      },
    ],
    [history],
  )

  return (
    <>
      <DocumentMeta title="RBAC Profiles" />
      <PollingData
        loading={loading}
        onReload={reload}
        refreshDuration={1000 * 30}
        pollingCondition={() => pollingCondition(data)}
        hidden
      />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.RbacProfiles}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={data}
        getParamsUpdater={getParamsUpdater}
        addUrl={routes.rbacProfiles.add.path()}
        addText="Add RBAC Profile"
        editUrl={(_, id) => routes.rbacProfiles.edit.path({ id })}
        deleteAction={deleteRbacProfile}
        editCond={([selectedRow]) => ['draft'].includes(selectedRow?.status?.phase)}
        // Todo: Batch actions need to be able to show this tooltip
        editDisabledInfo={`You may only edit a profile in draft phase`}
        batchActions={batchActions}
        multiSelection={false}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
