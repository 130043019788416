import React, { FC } from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import clsx from 'clsx'
import Progress from 'core/components/progress/Progress'
import generateTestId from 'utils/test-helpers'

const useStyles = makeStyles<Theme, Partial<EntityInfoCardProps & { wrap?: boolean }>>((theme) => ({
  card: {
    border: ({ addCardBorder = true }) =>
      addCardBorder ? `1px solid ${theme.palette.grey[300]}` : null,
    borderRadius: ({ addCardBorder = true }) => (addCardBorder ? 4 : null),
    padding: theme.spacing(2),
    width: 'auto',
    minWidth: 'max-content',
    maxWidth: 607,
  },
  cardTitle: {
    color: theme.palette.grey[700],
    padding: theme.spacing(1, 2, 2, 1),
    borderBottom: `solid 1px ${theme.palette.grey[200]}`,
  },
  cardContent: {
    marginTop: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridGap: theme.spacing(0.5),

    '& >.row': {
      display: ({ wrap }) => (wrap ? 'block' : 'grid'),
      gridTemplateColumns: 'max-content 1fr',
      alignItems: 'center',
      gridAutoRows: 'max-content',
    },
  },
  rowHeader: {
    color: theme.palette.grey[600],
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2, 1, 1),
    display: 'flex',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  rowValue: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.grey[700],
    wordBreak: 'break-all',
    display: 'flex',
    flexDirection: 'column',
  },
  rowHelp: {
    width: 24,
  },
  detailRow: {
    display: 'grid',
    gridTemplateColumns: ({ title }) => (title ? 'minmax(90px, max-content) 1fr' : '1fr'),
    alignItems: 'center',
    // gridGap: theme.spacing(1),
    gridColumnGap: theme.spacing(1),
  },
  rows: {},
}))

interface DetailRowProps {
  items: any[]
  classNames: any
  wrap?: boolean
}

export const DetailRow: FC<{
  label: string
  value: string | React.ReactNode
  extraContent?: React.ReactNode
  classNames: any
  wrap?: boolean
}> = ({ label, value, extraContent, classNames = {}, wrap = true }) => {
  const classes = useStyles({ title: label, wrap })

  const content = (
    <>
      {label && (
        <Text
          className={clsx(classes.rowHeader, classNames.rowHeader)}
          variant="caption1"
          component="span"
        >
          {label}
        </Text>
      )}
      <Text
        className={clsx(classes.rowValue, classNames.rowValue)}
        variant="body2"
        component={typeof value === 'string' ? 'span' : 'div'}
      >
        {value}
      </Text>
      {extraContent}
    </>
  )
  if (!wrap) return content
  return <div className={clsx(classes.detailRow, classNames.detailRow)}>{content}</div>
}

export const renderDetailRow = (items, classNames, wrap = true) => (
  <DetailRowDiv items={items} classNames={classNames} wrap={wrap} />
)

export const DetailRowDiv = ({ items, classNames, wrap }: DetailRowProps) => {
  return (
    <>
      {Object.entries(items).map(([name, { value, extraContent }]) => (
        <DetailRow
          key={name}
          label={name}
          wrap={wrap}
          value={value}
          extraContent={extraContent}
          classNames={classNames}
        />
      ))}
    </>
  )
}

const EntityInfoCard = ({
  title,
  leftRows,
  rightRows = undefined,
  className,
  addCardBorder,
  wrap,
  loading = false,
}: EntityInfoCardProps) => {
  const classes = useStyles({ addCardBorder, wrap })
  return (
    <div className={clsx(classes.card, className)}>
      {title && (
        <Text className={classes.cardTitle} variant="subtitle2" component="h3">
          {title}
        </Text>
      )}
      <div id="cardContent" className={clsx(classes.cardContent, className)}>
        {loading ? (
          <Progress loading={loading} />
        ) : (
          <>
            <div
              data-testid={generateTestId('overview', 'content', 'left', 'row')}
              className={'row'}
            >
              {leftRows}
            </div>
            {rightRows && (
              <div
                data-testid={generateTestId('overview', 'content', 'right', 'row')}
                className={'row'}
              >
                {rightRows}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

interface EntityInfoCardProps {
  title?: string
  leftRows: any
  rightRows?: any
  className?: any
  addCardBorder?: boolean
  wrap?: boolean
  loading?: boolean
}

export default EntityInfoCard
