import React, { useCallback, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { propEq } from 'ramda'
import FormWrapper from 'core/components/FormWrapper'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { deleteRbacProfileBinding, listRbacProfiles } from './new-actions'
import SubmitButton from 'core/components/SubmitButton'
import useParams from 'core/hooks/useParams'
import DocumentMeta from 'core/components/DocumentMeta'
import { makeStyles } from '@material-ui/styles'
import { routes } from 'core/utils/routes'
import Text from 'core/elements/Text'
import ListTableField from 'core/components/validatedForm/ListTableField'
import Theme from 'core/themes/model'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { rbacProfilesSelector } from './selectors'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { listCapiClusters } from 'app/plugins/infrastructure/components/clusters/capi/actions'
import { qbertAndCapiClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

const ClusterField = ({ clusterId }) => {
  useListAction(listClusters)
  useListAction(listCapiClusters)
  const clusters = useSelector(qbertAndCapiClustersSelector)
  const clusterName = useMemo(() => {
    return clusters.find(propEq('uuid', clusterId))?.name || clusterId
  }, [clusters, clusterId])
  return <span>{clusterName}</span>
}

const renderClusterField = (clusterId) => <ClusterField clusterId={clusterId} />

const renderProfileField = (profile) => {
  const profileName = profile.split('default/')[1]
  return <span>{profileName}</span>
}

const columns = [
  { id: 'spec.clusterRef', label: 'Cluster', render: renderClusterField },
  { id: 'spec.profileRef', label: 'Profile', render: renderProfileField },
]

const DeleteProfileBindingsPage = () => {
  const { match, history } = useReactRouter()
  const classes = useStyles()
  const profileId = match.params.id

  const { loading: loadingProfiles } = useListAction(listRbacProfiles)
  const profiles = useSelector(rbacProfilesSelector)

  const { update: handleRemove, updating: deleting } = useUpdateAction(deleteRbacProfileBinding)

  const profile = useMemo(() => profiles.find(propEq('id', profileId)) || { bindings: [] }, [
    profiles,
    profileId,
  ])
  const { params, getParamsUpdater } = useParams({
    selectedProfileBindings: [],
  })

  const handleSubmit = useCallback(
    async (params) => {
      await Promise.all(params.selectedProfileBindings.map(handleRemove))
      history.push(routes.rbacProfiles.list.path())
    },
    [handleRemove, history],
  )

  return (
    <>
      <DocumentMeta title="Manage Profile Bindings" bodyClasses={['form-view']} />
      <FormWrapper
        title={`Manage Profile Bindings`}
        loading={loadingProfiles || deleting}
        message={loadingProfiles ? 'Loading Profile Bindings...' : 'Deleting Profile Bindings...'}
        backUrl={routes.rbacProfiles.list.path()}
      >
        <ValidatedForm
          title="Delete Profile Bindings"
          classes={{ root: classes.validatedFormContainer }}
          formActions={<SubmitButton>Delete Profile Bindings</SubmitButton>}
          onSubmit={handleSubmit}
        >
          <Text variant="body2">
            Select profile bindings to delete below. A cluster's current RBAC configurations will
            not be affected by profile binding deletions.
          </Text>
          <ListTableField
            id="selectedProfileBindings"
            data={profile.bindings || []}
            loading={loadingProfiles}
            columns={columns}
            onChange={getParamsUpdater('selectedProfileBindings')}
            value={params.selectedProfileBindings}
            uniqueIdentifier="name"
            required
            multiSelection
          />
        </ValidatedForm>
      </FormWrapper>
    </>
  )
}

export default DeleteProfileBindingsPage
