import React, { useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { routes } from 'core/utils/routes'
import { IPersistentVolumeDetailsPageTabs } from './model'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import DataKeys from 'k8s/DataKeys'
import {
  renderResourceLabels,
  renderLabelsAsBadges,
} from 'k8s/components/common/entity/labels-and-annotations/helpers'
import DateCell, { DateAndTime } from 'core/components/listTable/cells/DateCell'
import { renderPvcStatus } from '../persistent-volume-claims/helpers'
import DocumentMeta from 'core/components/DocumentMeta'
import { persistentVolumeSelector } from './selectors'
import { ArrayElement } from 'core/actions/Action'
import { listPersistentVolumes, deletePersistentVolume } from './new-actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useListAction from 'core/hooks/useListAction'
import ListContainer from 'core/containers/ListContainer'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import usePluginRouter from 'core/hooks/usePluginRouter'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import DeletePersistentVolumeDialog from './DeletePersistentVolumeDialog'
import AddPersistentVolumeModal from './AddPersistentVolumeModal'

type ModelDataKey = DataKeys.PersistentVolumes
type SelectorModel = ArrayElement<ReturnType<typeof persistentVolumeSelector>>
type ActionParams = InferActionParams<typeof listPersistentVolumes>

type Params = ActionParams & {
  namespace?: string
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'PersistentVolumes',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']

const getColumns = (pluginId = 'kubernetes'): GridViewColumn<SelectorModel>[] => {
  const routePath = pluginId === 'kubevirt' ? 'kubevirtStorage' : 'storage'
  return [
    {
      key: 'name',
      label: 'Name',
      width: 'medium',
      CellComponent: createGridLinkCell({
        routeToFn: ({ clusterId, id }) =>
          routes[routePath].persistentVolumes.details.path({
            clusterId,
            id,
            tab: IPersistentVolumeDetailsPageTabs.Overview,
          }),
      }),
    } as GridViewColumn<SelectorModel, 'name'>,
    { key: 'namespace', label: 'Namespace', display: false },
    { key: 'clusterName', label: 'Cluster' },
    { key: 'capacity', label: 'Capacity', render: renderResourceLabels({ type: 'table' }) },
    {
      key: 'accessModes',
      label: 'Access Modes',
      render: renderLabelsAsBadges({ type: 'table', variant: 'default' }),
    },
    { key: 'reclaimPolicy', label: 'Reclaim Policy' },
    { key: 'status.phase', label: 'Status', render: renderPvcStatus },
    { key: 'spec.claimRef.name', label: 'Claim' },
    {
      key: 'metadata.annotations',
      label: 'Annotations',
      render: renderResourceLabels({ type: 'table', variant: 'default' }),
    },
    { key: 'storageClass', label: 'Storage Class' },
    {
      key: 'created',
      label: 'Created',
      render: (value: string) => <DateAndTime value={value} />,
    },
  ]
}

export default function PersistentVolumesListPage() {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtStorage' : 'storage'
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listPersistentVolumes, {
    params,
    requiredParams,
  })
  const data = useAppSelector(persistentVolumeSelector)

  const [selectedPersistentVolume, setSelectedPersistentVolume] = useState<SelectorModel>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      // cond: (item) => canDelete([item]),
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (persistentVolume) => {
        setSelectedPersistentVolume(persistentVolume)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
  ]

  const columns = getColumns(currentPluginId)
  return (
    <>
      <DocumentMeta title="Persistent Volumes" />
      <AddPersistentVolumeModal addRoute={routes[routePath].persistentVolumes.add} />
      {showDeleteDialog && (
        <DeletePersistentVolumeDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedPersistentVolume]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.PersistentVolumes}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={data}
        addUrl={routes[routePath].persistentVolumes.add.path()}
        addText="Add Persistent Volume"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deletePersistentVolume}
        rowMenuItems={rowMenuItems}
        showRowMenuForSingleRowActions
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

const toFilterColumns = {
  namespace: true,
  clusterName: true,
  labels: true,
  storageClass: true,
  created: true,
}
const formatOpts = {
  renameLabels: {},
  labels: ['d', 'h'],
  pluralize: false,
}
export const getPvDetailOptions = (pluginId = 'kubernetes') => {
  const columns = getColumns(pluginId)
  return {
    title: 'Persistent Volumes',
    columns: [
      ...columns.filter((column) => !toFilterColumns[column.key]),
      {
        key: 'created',
        label: 'Age',
        render: (value) => (
          <DateCell value={value} formatOpts={formatOpts} showToolTip difference />
        ),
      },
    ],
    columnsOrder: [
      'name',
      'created',
      'capacity',
      'accessModes',
      'reclaimPolicy',
      'spec.claimRef.name',
      'status.phase',
    ],
  }
}
