import React, { useMemo, useEffect } from 'react'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import { listDeployedApps } from '../new-actions'
import { deployedAppsSelector } from '../selectors'
import DocumentMeta from 'core/components/DocumentMeta'
import AppCardsGrid from '../../AppCardsGrid'
import { allKey } from 'app/constants'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'

type ActionParams = InferActionParams<typeof listDeployedApps>

const defaultParams = {
  clusterId: null,
  namespace: allKey,
}
const requiredParams: Array<keyof ActionParams> = ['clusterId']

export default function DeployedAppsPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(useParams, defaultParams)
  const { loading: loadingDeployedApps, message, reload } = useListAction(listDeployedApps, {
    params,
    requiredParams,
  })
  const deployedApps = useAppSelector(deployedAppsSelector)

  // Note: not sure if below useEffect will be honored with global params change
  useEffect(() => {
    // After deploying an app, it takes a while for the new app
    // to show up in the list deployed apps api payload. To ensure that the user
    // sees the new app right after deploying it successfully, call the list API again.
    // This was the behavior from the old deployed apps list page
    reload(true)
  }, [params.clusterId])

  return (
    <>
      <DocumentMeta title="Deployed Apps" breadcrumbs />
      <AppCardsGrid
        apps={deployedApps}
        appType="deployed"
        loadingApps={loadingDeployedApps}
        message={message}
        reload={reload}
      />
    </>
  )
}
