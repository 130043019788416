import React, { useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { listHorizontalPodAutoscalers } from './actions'
import { horizontalPodAutoscalersSelector } from './selectors'
import { useSelector } from 'react-redux'
import usePluginRouter from 'core/hooks/usePluginRouter'
import { IHorizontalPodAutoscalerDetailsPageTabs } from './model'
import ClusterNameCell from 'k8s/components/common/cells/ClusterNameCell'
import { durationBetweenDates } from 'utils/misc'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import AddHorizontalPodAutoscalerModal from './AddHorizontalPodAutoscalerModal'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import DeleteHorizontalPodAutoscalerDialog from './DeleteHorizontalPodAutoscalerDialog'

type ModelDataKey = DataKeys.HorizontalPodAutoscalers
type SelectorModel = ArrayElement<ReturnType<typeof horizontalPodAutoscalersSelector>>
type ActionParams = InferActionParams<typeof listHorizontalPodAutoscalers>
//@fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  masterNodeClusters: boolean
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId', 'namespace']
const defaultParams: Params & SortingState = {
  clusterId: null,
  namespace: null,
  masterNodeClusters: true,
  healthyClusters: true,
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'HorizontalPodAutoscalers',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']

export const getHorizontalPodAutoscalersColumns = (
  pluginId = 'kubernetes',
): GridViewColumn<SelectorModel>[] => {
  const routePath = pluginId === 'kubevirt' ? 'kubevirtConfiguration' : 'configuration'
  return [
    {
      key: 'name',
      label: 'Name',
      width: 'medium',
      CellComponent: createGridLinkCell({
        routeToFn: ({ clusterId, id }) =>
          routes[routePath].horizontalPodAutoscalers.details.path({
            clusterId,
            id,
            tab: IHorizontalPodAutoscalerDetailsPageTabs.Overview,
          }),
      }),
    },
    { key: 'clusterName', label: 'Cluster', width: 'medium', CellComponent: ClusterNameCell },
    { key: 'namespace', label: 'Namespace' },
    {
      key: 'created',
      label: 'Age',
      formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
    },
    {
      key: 'labels',
      label: 'Labels',
      disableSorting: true,
      CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
    },
    {
      key: 'annotations',
      label: 'Annotations',
      disableSorting: true,
      CellComponent: createResourceLabelsCell({ type: 'table', separator: ': ' }),
    },
  ]
}

export default function HorizontalPodAutoscalersListPage() {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtConfiguration' : 'configuration'
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listHorizontalPodAutoscalers, {
    params,
    requiredParams,
  })
  const horizontalPodAutoscalers = useSelector(horizontalPodAutoscalersSelector)

  const columns = getHorizontalPodAutoscalersColumns(currentPluginId)

  const [selectedHorizontalPodAutoscaler, setSelectedHorizontalPodAutoscaler] = useState<
    SelectorModel
  >(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (horizontalPodAutoscaler) => {
        setSelectedHorizontalPodAutoscaler(horizontalPodAutoscaler)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="Horizontal Pod Autoscalers" />
      <AddHorizontalPodAutoscalerModal addRoute={routes[routePath].horizontalPodAutoscalers.add} />
      {showDeleteDialog && (
        <DeleteHorizontalPodAutoscalerDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedHorizontalPodAutoscaler]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.HorizontalPodAutoscalers}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={horizontalPodAutoscalers}
        columns={columns}
        addUrl={routes[routePath].horizontalPodAutoscalers.add.path()}
        addText="Add Horizontal Pod Autoscalers"
        getParamsUpdater={getParamsUpdater}
        rowMenuItems={rowMenuItems}
        showItemsCountInLabel
        showRowMenuForSingleRowActions
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
