import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { pathOr, prop } from 'ramda'
import { makeStyles } from '@material-ui/styles'

import {
  pmkCliOverviewLink,
  gettingStartedHelpLink,
  pf9LearnBaseUrl,
  slackLink,
  forumHelpLink,
  requestFormLink,
} from 'k8s/links'
import { RootState } from 'app/store'
import { CustomerTiers, pf9IdeasLink } from 'app/constants'
import { showAndOpenZendeskWidget } from 'utils/zendesk-widget'

import { showZendeskChatOption } from 'core/utils/helpers'
import { routes } from 'core/utils/routes'

import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'

import Text from 'core/elements/Text'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import Card from 'core/elements/card'
import CardHeader from 'core/elements/card/CardHeader'

import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Button from 'core/elements/button'
import ExternalLink from 'core/components/ExternalLink'
import AccountUpgradeDialog from 'app/plugins/account/components/theme/AccountUpgradeDialog'
import DocumentMeta from 'core/components/DocumentMeta'

const useStyles = makeStyles((theme: Theme) => ({
  pageHeader: {
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.main,
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  text: {
    margin: theme.spacing(2),
  },
  card: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',

    '& > section': {
      display: 'grid',
      gridTemplateRows: '1fr max-content',
      paddingBottom: 16,
    },
  },
  cardRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 330px)',
    gridTemplateRows: 'repeat(2, 185px)',
    gridGap: theme.spacing(2),
  },
  cardHeader: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    alignItems: 'center',
    gap: 8,
  },
  icon: {
    fontSize: 20,
    height: 10,
    marginTop: 10,
  },
  svgIcon: {
    width: '30px',
    justifySelf: 'center',
    color: theme.palette.primary.main,
  },
}))

const brandIcons = ['slack']

interface SupportCardProps {
  title: string
  icon: string
  body?: string
  src: string
  action: string
  onClick?: any
}

const SupportCard = ({ title, icon, body = '', src, action, onClick }: SupportCardProps) => {
  const classes = useStyles()
  return (
    <Card
      className={classes.card}
      title={
        <CardHeader className={classes.cardHeader}>
          <FontAwesomeIcon
            solid={!brandIcons.includes(icon)}
            brand={brandIcons.includes(icon)}
            className={classes.icon}
          >
            {icon}
          </FontAwesomeIcon>
          <span>{title}</span>
        </CardHeader>
      }
    >
      <Text variant="body2" component="p">
        {body}
      </Text>
      {src && src.indexOf('mailto') >= 0 ? (
        <a href={src}>
          <Button variant="secondary">{action}</Button>
        </a>
      ) : (
        <ExternalLink url={src} onClick={onClick}>
          <Button variant="secondary">{action}</Button>
        </ExternalLink>
      )}
    </Card>
  )
}

const HelpPage = () => {
  const classes = useStyles()
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { features } = session
  const customerTier = pathOr<CustomerTiers>(CustomerTiers.Freedom, ['customer_tier'], features)
  const { prefs } = useScopedPreferences()
  const { lastStack } = prefs
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false)
  const [feature, setFeature] = useState('')

  const supportRequestEnabled =
    customerTier === CustomerTiers.Growth || customerTier === CustomerTiers.Enterprise
  const showSupportRequestOption = supportRequestEnabled || customerTier === CustomerTiers.Freedom
  const chatOptionEnabled = useMemo(() => showZendeskChatOption(lastStack, customerTier), [
    lastStack,
    customerTier,
  ])
  const showChatOption = chatOptionEnabled || customerTier === CustomerTiers.Freedom

  const showAccountUpgradeDialog = (feature) => () => {
    setShowUpgradeDialog(true)
    setFeature(feature)
  }

  return (
    <>
      <DocumentMeta title="Help" />
      {showUpgradeDialog && (
        <AccountUpgradeDialog onClose={() => setShowUpgradeDialog(false)} feature={feature} />
      )}
      <Tabs route={routes.help} previewInHeader>
        <Tab value="support" label="Support">
          <div className={classes.cardRow}>
            <SupportCard
              title="Getting Started"
              icon="play-circle"
              body="Need help with BareOS, AWS or Azure?"
              src={gettingStartedHelpLink}
              action="Getting Started"
            />
            <SupportCard
              title="CLI Guide"
              icon="file-code"
              body="Using the CLI to build a cluster?"
              src={pmkCliOverviewLink}
              action="View CLI Commands"
            />
            <SupportCard
              title="Tutorials"
              icon="drafting-compass"
              body="Need step-by-step tutorials for cloud-native technologies?"
              src={pf9LearnBaseUrl}
              action="View Tutorials"
            />
          </div>
        </Tab>
        <Tab value="contact" label="Contact Us">
          <div className={classes.cardRow}>
            <SupportCard
              title="Ideas"
              icon="lightbulb"
              body="Help decide what we build next. Create and vote on your product ideas."
              src={pf9IdeasLink}
              action="Open Ideas Portal"
            />
            {showSupportRequestOption && (
              <SupportCard
                title="Submit a Support Request"
                icon="file-alt"
                src={supportRequestEnabled ? requestFormLink : ''}
                action="Open Request Form"
                onClick={supportRequestEnabled ? null : showAccountUpgradeDialog('Support Request')}
              />
            )}
            <SupportCard
              title="Join our Forums"
              icon="comments"
              src={forumHelpLink}
              action="Go to forum"
            />
            <SupportCard
              title="Reach out on Slack"
              icon="slack"
              src={slackLink}
              action="Open Slack"
            />
            {showChatOption && (
              <SupportCard
                title="Chat With Us"
                icon="comments-alt"
                src={''}
                action="Open Chat"
                onClick={
                  chatOptionEnabled ? showAndOpenZendeskWidget : showAccountUpgradeDialog('Chat')
                }
              />
            )}
          </div>
        </Tab>
      </Tabs>
    </>
  )
}

export default HelpPage
