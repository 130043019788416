/**
 * Given a string with time in '__h' or '__m' formats returns seconds
 * @param {string} dur
 * @returns {number}
 *
 * @example
 * "10m" returns 600
 * "2h" returns 7200
 */
export const getSecondsForDuration = (dur: string) => {
  let sec = 0
  let match
  if ((match = dur.match(/(\d+)h/))) {
    sec += parseInt(match[1]) * 3600
  }
  if ((match = dur.match(/(\d+)m/))) {
    sec += parseInt(match[1]) * 60
  }
  return sec
}
