/* eslint-disable no-extra-boolean-cast */
import React, { useCallback, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import useDataLoader from 'core/hooks/useDataLoader'
import { loadServiceCatalog } from 'k8s/components/apiAccess/endpoints/actions'
import { pluck } from 'ramda'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { ApiServices, Method } from 'api-client/model'
import Filter, { FilterSpec } from 'core/components/FilterSpec'
import Keystone from 'api-client/Keystone'
import Qbert from 'api-client/Qbert'
import ResMgr from 'api-client/ResMgr'
import SelectableCard from 'core/components/SelectableCard'
import Text from 'core/elements/Text'
import ApiRequestHelper from './ApiRequestHelper'
import { Route } from 'core/plugins/route'
import { devEnabled } from 'core/utils/helpers'
import DocumentMeta from 'core/components/DocumentMeta'

const initialMethod = {
  name: '',
  url: '',
  version: '',
  params: [],
} as Method

const searchTarget = 'name'
const requestVerbs = ['GET', 'PATCH', 'PUT', 'POST', 'DELETE']

const filters: FilterSpec[] = [
  {
    name: 'Verb',
    label: 'Verb',
    options: requestVerbs,
    target: 'type',
  },
]

const apiServices = {
  keystone: Keystone,
  qbert: Qbert,
  resmgr: ResMgr,
}

const hideMethod = (method, isDevEnabled) => {
  return method.disable || (method.type !== 'GET' && !isDevEnabled)
}

export default function ApiServicesPage() {
  const classes = useStyles()
  const [activeApi, setActiveApi] = useState('')
  const [apiMethods, setApiMethods] = useState([])
  const [activeMethod, setActiveMethod] = useState(initialMethod)

  const { history, location } = useReactRouter()
  const route = Route.getCurrentRoute()
  const urlParams = new URLSearchParams(location.search)

  const [serviceCatalog] = useDataLoader(loadServiceCatalog)
  let whitelist = ['keystone', 'qbert']

  const isDevEnabled = devEnabled()
  // Only expose these services in the dev plugin
  const devOnlyServices = ['resmgr']
  if (isDevEnabled) {
    whitelist = whitelist.concat(devOnlyServices)
  }

  const services = serviceCatalog.filter((service) => whitelist.includes(service.name))

  // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
  const serviceNames: any = pluck('name' as any, services).sort()

  useEffect(() => {
    const apiName = urlParams.get('api')
    if (!!apiName) {
      setActiveApi(urlParams.get('api'))
    } else {
      if (serviceNames.length > 0) {
        setActiveApi(serviceNames[0])
      }
    }
  }, [])

  useEffect(() => {
    if (!!activeApi) {
      const methods = apiServices[activeApi].apiMethodsMetadata
      setApiMethods(methods)
      setActiveMethod(initialMethod)
      updateUrlWithParams()
    }
  }, [activeApi])

  const updateUrlWithParams = () => {
    const params = { api: activeApi }
    // Add in all other params in the URL
    urlParams.forEach((value, key, parent) => {
      if (key !== 'api') params[key] = value
    })
    const searchParams = new URLSearchParams(params)
    history.push({ pathname: location.pathname, search: searchParams.toString() })
  }

  const getServiceUrl = (name) => {
    return services.find((service) => service.name === name).url
  }

  const getAllApiMethods = useCallback(() => {
    if (!activeApi) {
      return []
    }
    return apiServices[activeApi].apiMethodsMetadata
  }, [activeApi])

  const handleMethodCardClick = (method) => {
    setActiveMethod(method)
  }

  return (
    <>
      <DocumentMeta title="API Services" breadcrumbs />
      <div className={classes.apiServicesPage}>
        <div className={classes.services}>
          {serviceNames.map((name) => (
            <SelectableCard key={name} id={name} active={activeApi === name} onClick={setActiveApi}>
              <Text variant="subtitle2">{ApiServices[name]}</Text>
              <Text variant="body2" className={classes.url} lineClamp={2}>
                {getServiceUrl(name)}
              </Text>
            </SelectableCard>
          ))}
        </div>
        <div className={classes.requestMethods}>
          <Text variant="subtitle2">Request Methods</Text>
          <Filter
            data={getAllApiMethods()}
            setFilteredData={setApiMethods}
            filters={isDevEnabled ? filters : []}
            searchTarget={searchTarget}
          />
          <div className={classes.methods}>
            {apiMethods.map((method) =>
              hideMethod(method, isDevEnabled) ? null : (
                <SelectableCard
                  key={method.name}
                  id={method}
                  active={activeMethod.name === method.name}
                  onClick={handleMethodCardClick}
                  className={classes.methodCard}
                >
                  <Text variant="body1">{method.name}</Text>
                </SelectableCard>
              ),
            )}
          </div>
        </div>
        <div className={classes.apiResponse}>
          <ApiRequestHelper api={activeApi} metadata={activeMethod} />
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  apiServicesPage: {
    display: 'grid',
    gridTemplateColumns:
      'minmax(300px, max-content) minmax(max-content, 450px) minmax(max-content, 950px)',
    gridGap: theme.spacing(2),
    gridTemplateAreas: `
      "services requests apiResponse"
    `,
  },
  services: {
    gridArea: 'services',
    display: 'grid',
    gridAutoFlow: 'row',
    alignContent: 'start',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 4, 2, 0),
    borderRight: `1px solid ${theme.components.tab.border}`,
  },
  requestMethods: {
    gridArea: 'requests',
    padding: theme.spacing(2, 4, 2, 2),
    borderRight: `1px solid ${theme.components.tab.border}`,
    display: 'grid',
    gridGap: theme.spacing(3),
    alignContent: 'start',
  },
  apiResponse: {
    gridArea: 'apiResponse',
    padding: theme.spacing(2),
  },
  serviceCards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(200px, 300px))',
    gridGap: theme.spacing(2),
  },
  formFieldCard: {
    paddingTop: 24,
    maxWidth: 1285,
    minHeight: 400,
    justifyContent: 'stretch',
    gap: 16,

    '& > header': {
      margin: 0,
    },
  },
  methods: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  url: {
    marginTop: theme.spacing(1),
  },
  apiDetails: {
    display: 'grid',
    marginTop: theme.spacing(3),
  },
  methodCard: {
    width: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: 30,
    right: 46,
  },
  modalBody: {
    position: 'relative',
  },
}))
