import React, { useMemo, useRef, useEffect, useCallback } from 'react'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import DocumentMeta from 'core/components/DocumentMeta'
import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import { routes } from 'core/utils/routes'
import useReactRouter from 'use-react-router'
import { RepositoryType, IRepositoriesSelector } from '../models'
import { propEq, pathOr, prop } from 'ramda'
import { isNilOrEmpty, noop } from 'utils/fp'
import { useSelector } from 'react-redux'
import { RootState } from 'app/store'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import ModalForm from 'core/elements/modal/ModalForm'
import Theme from 'core/themes/model'
import { CustomerTiers, upgradeLinks } from 'app/constants'
import useListAction from 'core/hooks/useListAction'
import {
  listRepositories,
  updateRepository,
  deleteClustersFromRepository,
  addClustersToRepository,
  updateConnectedClusters,
} from '../new-actions'
import { repositoriesSelector } from '../selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import useToggler from 'core/hooks/useToggler'
import PicklistField from 'core/components/validatedForm/DropdownField'
import generateTestId from 'utils/test-helpers'
import MultiClusterSelectionDropdown from 'k8s/components/common/MultiClusterSelectionDropdown'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import Alert from 'core/components/Alert'
import ExternalLink from 'core/components/ExternalLink'
import { trackUpgrade } from 'utils/tracking'
import Column from 'core/containers/Column'

const customerTierBlacklist = []

const defaultRepository = {
  type: null,
} as IRepositoriesSelector

export default function EditRepositoryFormModal() {
  const classes = useStyles()
  const { history, match } = useReactRouter()
  const repositoryName = match.params.id
  const formFieldSetter = useRef(null)
  const [isPrivateRepo, toggleIsPrivateRepo] = useToggler()
  const { loading } = useListAction(listRepositories)
  const repositories = useSelector(repositoriesSelector)
  const { update: updateRepo, updating: updatingRepo, error: updateError } = useUpdateAction(
    updateRepository,
  )
  const {
    update: deleteClustersFromRepo,
    updating: deletingClustersFromRepo,
    error: deleteClusterError,
  } = useUpdateAction(deleteClustersFromRepository)
  const {
    update: addClustersToRepo,
    updating: addingClustersToRepo,
    error: addClusterError,
  } = useUpdateAction(addClustersToRepository)

  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { username, features } = session
  const customerTier = pathOr(CustomerTiers.Freedom, ['customer_tier'], features)
  const disableClusterSelection = customerTierBlacklist.includes(customerTier)

  const setUpFormFieldSetter = (setField) => {
    formFieldSetter.current = { setField }
  }

  const repository = useMemo(
    () => repositories.find(propEq('name', repositoryName)) || defaultRepository,
    [repositoryName, repositories],
  )

  const handleSubmit = useCallback(
    async (values) => {
      const { success } = await updateRepo({ ...repository, ...values })
      if (success) {
        if (!values.autoAttach) {
          await updateConnectedClusters({
            repository,
            selectedClusterIds: values.clusterIds,
            deleteClustersFromRepo,
            addClustersToRepo,
          })
        }
        history.push(routes.repositories.list.path())
      }
    },
    [repository],
  )

  useEffect(() => {
    if (isNilOrEmpty(repository)) return
    formFieldSetter?.current?.setField('name')(repository.name)
    formFieldSetter?.current?.setField('url')(repository.url)
    formFieldSetter?.current?.setField('clusterIds')(repository.clusterIds)
    formFieldSetter?.current?.setField('autoAttach')(repository.autoAttach)
    if (repository.type === RepositoryType.Private) {
      toggleIsPrivateRepo()
    }
  }, [repository, formFieldSetter?.current])

  const submitting = updatingRepo || deletingClustersFromRepo || addingClustersToRepo

  return (
    <>
      <DocumentMeta title="Edit Repository" />
      <ModalForm
        route={routes.repositories.edit}
        title="Edit Helm Repository"
        onBackdropClick={noop}
        onClose={() => history.push(routes.repositories.list.path())}
        onSubmit={handleSubmit}
        submitting={submitting}
        submitTitle="Edit Repository"
        fieldSetter={setUpFormFieldSetter}
        loading={loading}
        error={updateError || deleteClusterError || addClusterError}
      >
        {({ setFieldValue, values }) => {
          return (
            <>
              <div className={classes.validatedFormContainer}>
                <FormFieldSection step={1} title="Set access level">
                  <ToggleSwitch
                    active={isPrivateRepo}
                    onClick={toggleIsPrivateRepo}
                    label="Private Repository"
                    disabled
                  />
                </FormFieldSection>
                <FormFieldSection step={2} title="Configure Settings">
                  <Column>
                    <TextField
                      id="name"
                      label="Repository Name"
                      className={classes.textField}
                      onChange={setFieldValue('repositoryName')}
                      disabled
                    />
                    <TextField
                      id="url"
                      label="Repository URL"
                      className={classes.textField}
                      onChange={setFieldValue('repositoryUrl')}
                      disabled
                    />
                    {isPrivateRepo && (
                      <Column>
                        <TextField
                          id="username"
                          label="Username"
                          className={classes.textField}
                          onChange={setFieldValue('username')}
                          required
                        />
                        <TextField
                          id="password"
                          label="Password"
                          className={classes.textField}
                          onChange={setFieldValue('password')}
                          type="password"
                          required
                        />
                      </Column>
                    )}
                    <div className={classes.clusters}>
                      <PicklistField
                        DropdownComponent={MultiClusterSelectionDropdown}
                        id="clusterIds"
                        data-testid={generateTestId('clusterIds', 'dropdown')}
                        label="Assign Clusters"
                        onChange={setFieldValue('clusterIds')}
                        values={values.clusterIds}
                        enableSearch
                        disabled={disableClusterSelection || values.autoAttach}
                      />
                      {disableClusterSelection && (
                        <Alert>
                          <Text variant="body2">
                            Assigning clusters is only available to the Enterprise customers. To
                            enable the option,{' '}
                            <ExternalLink
                              url={upgradeLinks[customerTier]}
                              onClick={() => trackUpgrade({ username, customerTier })}
                            >
                              Upgrade Now
                            </ExternalLink>
                          </Text>
                        </Alert>
                      )}
                      <CheckboxField
                        id="autoAttach"
                        value={values.autoAttach}
                        label="Auto-attach to all new clusters"
                        onChange={setFieldValue('autoAttach')}
                      />
                      {values.autoAttach && (
                        <Alert
                          variant="warning"
                          title="Warning"
                          message="Attaching all clusters can take a few minutes. You may not be able to see all the connected clusters immediately"
                        />
                      )}
                    </div>
                  </Column>
                </FormFieldSection>
              </div>
            </>
          )
        }}
      </ModalForm>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
  },
  title: {
    color: theme?.components?.typography?.default,
  },
  fields: {
    marginLeft: theme.spacing(2),
  },
  textField: {
    minWidth: '80%',
  },
  loginDetails: {
    marginTop: theme.spacing(2),
  },
  repoTypes: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    marginTop: theme.spacing(1),
  },
  repoTypeText: {
    marginTop: theme.spacing(2),
  },
  label: {
    ...theme.typography.body2,
  },
  repoInfo: {
    display: 'grid',
    gridTemplateColumns: '120px 1fr',
    gridTemplateRows: '40px',
    gridGap: theme.spacing(1),
  },
  clusters: {
    display: 'grid',
    gap: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}))
