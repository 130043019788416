import { makeStyles } from '@material-ui/styles'
import Progress from 'core/components/progress/Progress'
import Theme from 'core/themes/model'
import React from 'react'
import ContainerLogCard from './container-log-card'

const useStyles = makeStyles<Theme>((theme) => ({
  logsPage: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(2),
    marginTop: 16,
    width: 'minmax(100%, max-content)',
  },
}))

const PodLogsPage = ({ pod, loading }) => {
  const classes = useStyles()
  const containers = pod?.spec?.containers || []

  return (
    <div className={classes.logsPage}>
      <Progress loading={loading}>
        {containers.map((container) => (
          <ContainerLogCard
            key={`${pod?.name}-${container?.name}`}
            pod={pod}
            container={container}
          />
        ))}
      </Progress>
    </div>
  )
}

export default PodLogsPage
