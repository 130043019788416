import React, { useMemo, useEffect } from 'react'
import { projectAs } from 'utils/fp'
import { isEmpty, uniqBy, prop, pathOr } from 'ramda'
import { allKey as defaultAllKey } from 'app/constants'
import useListAction from 'core/hooks/useListAction'
import { listConfigMaps } from 'k8s/components/config-maps/new-actions'
import { configMapsSelector } from 'k8s/components/config-maps/selectors'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'

interface ConfigMapPicklistProps {
  clusterId: string
  namespace: string
  useGlobalParams?: boolean
  customFilter?: (configMaps) => any[]
}

export default function ConfigMapPicklist({
  name = 'configMap',
  label = 'ConfigMap',
  selectFirst = false,
  allKey = defaultAllKey,
  compact = true,
  showAll = false,
  useGlobalParams = false,
  clusterId,
  namespace,
  loading,
  onChange,
  value,
  initialValue,
  customFilter,
  ...rest
}: PropsWithAsyncDropdown<ConfigMapPicklistProps>) {
  const { loading: configMapsLoading } = useListAction(listConfigMaps, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const configMaps = useSelectorWithParams(configMapsSelector, {
    clusterId,
    namespace,
    useGlobalParams,
  })
  const filteredConfigMaps = useMemo(() => {
    if (namespace) {
      const namespaceConfigMaps = configMaps.filter(
        (configMap) => configMap?.namespace === namespace,
      )
      return customFilter ? customFilter(configMaps) : namespaceConfigMaps
    }
    return customFilter ? customFilter(configMaps) : configMaps
  }, [configMaps, namespace, customFilter])

  const options = useMemo(
    () => projectAs({ label: 'name', value: 'name' }, uniqBy(prop('name'), filteredConfigMaps)),
    [filteredConfigMaps],
  )

  useEffect(() => {
    if (!clusterId || !namespace) return
    // If initialValue was being set, it would just be overridden here
    // without this return statement
    if (initialValue) {
      const option = options.find((o) => o.value === initialValue)
      option ? onChange(option.value) : onChange('default')
      return
    }
    // Reset the namespace when the clusterId or namespace changes
    onChange('')
  }, [clusterId, namespace, initialValue])

  useEffect(() => {
    if (!clusterId || !namespace || value) return
    if (isEmpty(options)) return
  }, [options, clusterId, namespace, value])

  return (
    <AsyncDropdown
      name={name}
      compact={compact}
      showAll={showAll}
      allKey={allKey}
      onChange={onChange}
      label={label}
      loading={loading || configMapsLoading}
      items={options}
      value={value}
      selectFirst={selectFirst}
      initialValue={initialValue}
      {...rest}
    />
  )
}
