import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import { someAsync } from 'utils/async'
import { ensureArray, pathStr } from 'utils/fp'
import { flatten } from 'ramda'
import CreateAction from 'core/actions/CreateAction'
import { trackEvent } from 'utils/tracking'
import DeleteAction from 'core/actions/DeleteAction'
import UpdateAction from 'core/actions/UpdateAction'

const { qbert } = ApiClient.getInstance()

export const configMapActions = ActionsSet.make<DataKeys.ConfigMaps>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.ConfigMaps],
  cacheKey: DataKeys.ConfigMaps,
})

export const listConfigMaps = configMapActions.add(
  new ListAction<DataKeys.ConfigMaps, { clusterId: string; namespace?: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Config Maps', params)
    const { namespace, clusterId } = params
    return someAsync(
      ensureArray(namespace).map((name) => qbert.getConfigMaps(clusterId, name)),
    ).then(flatten)
  }),
)

export const createConfigMap = configMapActions.add(
  new CreateAction<DataKeys.ConfigMaps, { clusterId: string; namespace: string; body: any }>(
    async ({ clusterId, namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new Config Map', {
        clusterId,
        namespace,
        body,
      })
      const created = await qbert.createConfigMap(clusterId, namespace, body)
      trackEvent('Create New Config Map', {
        clusterId,
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const updateConfigMap = configMapActions.add(
  new UpdateAction<
    DataKeys.ConfigMaps,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Config Map', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn = requestType === 'patch' ? qbert.patchConfigMap : qbert.updateConfigMap
    const updatedConfigMap = await updateFn({
      clusterId,
      namespace,
      name,
      body,
      contentType,
    })
    trackEvent('Update Config Map', { clusterId, namespace, name })
    return updatedConfigMap
  }),
)

export const deleteConfigMap = configMapActions.add(
  new DeleteAction<DataKeys.ConfigMaps, { clusterId: string; namespace: string; name: string }>(
    async ({ clusterId, namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Config Map', {
        clusterId,
        namespace,
        name,
      })
      await qbert.deleteConfigMap(clusterId, namespace, name)
    },
  ),
)
