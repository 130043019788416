import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { findCluster } from 'k8s/util/helpers'
import { durationBetweenDates } from 'utils/misc'
import { IHorizontalPodAutoscalerSelector } from './model'

export const horizontalPodAutoscalersSelector = createSharedSelector(
  getDataSelector<DataKeys.HorizontalPodAutoscalers>(
    DataKeys.HorizontalPodAutoscalers,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  (rawHorizontalPodAutoscalers, allClusters): IHorizontalPodAutoscalerSelector[] => {
    return rawHorizontalPodAutoscalers.map((rawHorizontalPodAutoscaler) => {
      const { clusterId, metadata } = rawHorizontalPodAutoscaler
      const { name: clusterName, clusterType: clusterType } = findCluster(allClusters, clusterId)
      return {
        ...rawHorizontalPodAutoscaler,
        clusterName,
        clusterId,
        clusterType,
        age: durationBetweenDates({ labels: ['d'] })(metadata?.creationTimestamp),
        ownerReferences: metadata?.ownerReferences,
        creationTimestamp: metadata?.creationTimestamp,
        labels: metadata?.labels,
        annotations: metadata?.annotations,
      }
    })
  },
)
