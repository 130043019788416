import React, { useCallback, useState } from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import DisplayKeyValues from 'core/components/DisplayKeyValues'
import { formatDate } from 'utils/misc'
import { SeverityTableCell } from './AlarmsListPage'
import { IAlertSelector } from './model'
import SnoozeTimerPicklist from './SnoozeTimerPicklist'
import SubmitButton from 'core/components/buttons/SubmitButton'
import { keys, prop } from 'ramda'
import moment from 'moment'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { useSelector } from 'react-redux'
import { RootState } from 'app/store'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listAlerts, listTimeSeriesAlerts, createSilence } from './new-actions'
import Modal from 'core/elements/modal'
import Alert from 'core/components/Alert'
import CodeBlock from 'core/components/CodeBlock'
import Theme from 'core/themes/model'

interface Props {
  rows: IAlertSelector[]
  onClose: () => void
  listTableParams: any
}

const useStyles = makeStyles<Theme>((theme) => ({
  upperBody: {
    borderBottom: `1px solid ${theme.components.card.border}`,
    borderTop: `1px solid ${theme.components.card.border}`,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  snoozePicklist: {
    marginLeft: theme.spacing(2),
  },
  paddingRight: {
    paddingRight: theme.spacing(7.5),
  },
}))

const SnoozeAlarmDialog = ({ rows: [alarm], onClose, listTableParams }: Props) => {
  const classes = useStyles({})
  const [snoozeTime, setSnoozeTime] = useState('12.h')
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { username } = session

  const { reload: reloadAlarms } = useListAction(listAlerts, {
    params: listTableParams,
  })
  const { reload: reloadTimeSeries } = useListAction(listTimeSeriesAlerts, {
    params: {
      chartTime: listTableParams.chartTime,
      clusterId: listTableParams.clusterId,
    },
  })
  const { update: handleAdd, updating: addingSilence } = useUpdateAction(createSilence)

  const createSnooze = useCallback(async () => {
    const labelKeys = keys(alarm.labels)
    // Match the alarm labels completely (some alarms differ by only 1 property)
    const matchers = labelKeys.map((k) => ({ name: k, value: alarm.labels[k], isRegex: false }))
    const timeParts = snoozeTime.split('.')
    const currentTime = moment()
    // @ts-ignore
    const futureTime = moment(currentTime).add(parseInt(timeParts[0]), timeParts[1])
    const timeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
    const body = {
      matchers,
      startsAt: currentTime.utc().format(timeFormat),
      endsAt: futureTime.utc().format(timeFormat),
      comment: '',
      createdBy: username,
    }
    await handleAdd({ clusterId: alarm.clusterId, body })
    reloadAlarms(true)
    reloadTimeSeries(true)
    // @ts-ignore not sure why complaining about num arguments
    onClose(true)
  }, [snoozeTime])

  const upperValues = [
    { key: 'Event Time', value: formatDate(alarm.updatedAt) },
    {
      key: 'Alarm Severity',
      value: alarm.severity,
      render: (value) => <SeverityTableCell value={value} />,
    },
    { key: 'Alarm Summary', value: alarm.summary },
  ]
  const lowerValues = [
    { key: 'Conditions', value: alarm.query, render: (value) => <CodeBlock>{value}</CodeBlock> },
    { key: 'Duration', value: alarm.for || 'N/A' },
  ]

  return (
    <Modal
      open
      title={`Snooze ${alarm.name}`}
      panel="dialog"
      onClose={onClose}
      footer={
        <SubmitButton onClick={createSnooze} loading={addingSilence}>
          Snooze Alarm
        </SubmitButton>
      }
    >
      <Alert
        title="What will happen?"
        variant="warning"
        message={`By snoozing an alarm no more events or
              notifications will fire for the selected time period.`}
      />
      <div className={classes.flex}>
        <Text variant="body2">Snooze for</Text>
        <div className={classes.snoozePicklist}>
          <SnoozeTimerPicklist onChange={setSnoozeTime} value={snoozeTime} />
        </div>
      </div>
      <div className={classes.upperBody}>
        <DisplayKeyValues keyValuePairs={upperValues} />
      </div>
      <div>
        <DisplayKeyValues keyValuePairs={lowerValues} rowSpacing={24} />
      </div>
    </Modal>
  )
}

export default SnoozeAlarmDialog
