// Libs
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import useReactRouter from 'use-react-router'

// Components
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import DocumentMeta from 'core/components/DocumentMeta'
import { routes } from 'core/utils/routes'
import ApiClient from 'api-client/ApiClient'
import EntityYamlPage, { moizedYamlLoad } from 'k8s/components/common/entity/entity-yaml-page'
import StorageClassOverview from './StorageClassOverview'
import { IStorageDetailsPageTabs } from '../model'
import EntityEventsPage from 'k8s/components/common/entity/entity-events-page'
import { customValidator, requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import useListAction from 'core/hooks/useListAction'
import { listStorageClasses, updateStorageClass } from '../storage-classes/new-actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { storageClassSelector } from '../storage-classes/selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { IStorageClassSelector } from '../storage-classes/model'
import InfoHeader from 'k8s/components/common/entity/info-header'
import { isEmpty } from 'ramda'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'

const { qbert } = ApiClient.getInstance()

const yamlValidations = [
  requiredValidator,
  yamlValidator,
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return !!body?.metadata?.name
  }, 'metadata.name must be set'),
]

const defaultStorageClass = {
  persistentVolumes: [],
  persistentVolumeClaims: [],
} as IStorageClassSelector

export default function StorageDetailsPage() {
  const { match } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtStorage' : 'storage'
  const dispatch = useDispatch()
  const { clusterId, id } = match.params
  const { globalParams: params } = useGlobalParams(useParams, {})
  const { loading } = useListAction(listStorageClasses, {
    params,
  })
  const storageClasses = useSelectorWithParams(storageClassSelector, {
    clusterId,
    useGlobalParams: false,
  })
  const storageClass = useMemo(
    () => storageClasses.find((sc) => sc.id === id) || defaultStorageClass,
    [id, storageClasses],
  )
  const { update, updating } = useUpdateAction(updateStorageClass)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: storageClass?.clusterName || clusterId,
        id: storageClass?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [storageClass?.clusterName, storageClass?.name, id, clusterId])

  const getStorageClassYaml = useCallback(async () => {
    if (!storageClass) return ''
    return qbert.getClusterStorageClassByName(storageClass.clusterId, storageClass.name)
  }, [storageClass])

  const handleStorageClassUpdate = async (storageClassYaml) => {
    const body = jsYaml.load(storageClassYaml)
    const name = pathStrOr('', 'metadata.name', body)
    await update({ clusterId, name, body })
  }

  const headerColumns = useMemo(() => {
    if (isEmpty(storageClass)) return []
    return [
      { label: 'Name', value: storageClass?.name },
      { label: 'Cluster', value: storageClass?.clusterName },
      { label: 'Provisioner', value: storageClass?.provisioner },
      { label: 'Age', value: storageClass?.age },
    ]
  }, [storageClass])

  return (
    <>
      <DocumentMeta title="Storage Class Overview" breadcrumbs />
      <InfoHeader columns={headerColumns} />
      <Tabs route={routes[routePath].storageClasses.details}>
        <Tab value={IStorageDetailsPageTabs.Overview} label="Overview">
          <StorageClassOverview storageClass={storageClass} loading={loading} />
        </Tab>
        <Tab value={IStorageDetailsPageTabs.Events} label="Events">
          <EntityEventsPage entity={storageClass} loading={loading} />
        </Tab>
        <Tab value={IStorageDetailsPageTabs.Yaml} label="YAML">
          <EntityYamlPage
            entityType="Storage Class"
            getYamlFn={getStorageClassYaml}
            handleUpdate={handleStorageClassUpdate}
            loading={updating}
            yamlValidations={yamlValidations}
          />
        </Tab>
      </Tabs>
    </>
  )
}
