import { IGenericResource } from 'api-client/qbert.model'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'

export enum IEndpointDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface EndpointsResponse {
  kind: string
  apiVersion: string
  metadata: EndpointsResponseMetadata
  items: EndpointItem[]
}

export interface EndpointItem {
  metadata: ItemMetadata
  subsets: EndpointSubset[]
}

interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels?: Record<string, string>
  ownerReferences?: OwnerReference[]
  annotations?: Record<string, string>
}

export interface OwnerReference {
  apiVersion: string
  kind: string
  name: string
  uid: string
  controller: boolean
  blockOwnerDeletion: boolean
}

interface EndpointsResponseMetadata {
  resourceVersion: string
}

interface EndpointSubset {
  addresses: EndpointAddress[]
  ports: EndpointPort[]
}

export interface EndpointAddress {
  ip: string
  hostname?: string
  nodeName?: string
  targetRef?: TargetRef
}

interface TargetRef {
  kind: string
  namespace: string
  name: string
  uid: string
}

export interface EndpointPort {
  port: number
  name?: string
  protocol?: string
}

export interface IEndpointSelector extends IGenericResource<EndpointItem> {
  clusterName: string
  clusterId: string
  clusterType: ClusterTypes
  ownerReferences: EndpointItem['metadata']['ownerReferences']
  age?: string
  labels: EndpointItem['metadata']['labels']
  annotations: EndpointItem['metadata']['annotations']
  endpoints: string[]
}
