import { IGenericResource } from 'api-client/qbert.model'
import { Pod } from 'k8s/components/pods/model'
import { Replicas } from 'k8s/components/stateful-sets/model'

export enum IReplicaSetDetailsPageTabs {
  Overview = 'overview',
  Pods = 'pods',
  Events = 'events',
  Yaml = 'yaml',
  Logs = 'logs',
}
export interface IReplicaSetSelector extends IGenericResource<ReplicaSetsItem> {
  clusterName: string
  age: string
  creationTimestamp: string
  labels: Record<string, string>
  annotations: Record<string, string>
  selector: Record<string, string>
  replicas: Replicas
  podTemplate: Template
  volumes: Volume[]
  pods: Pod[]
}

export interface ReplicaSetsResponse {
  kind: string
  apiVersion: string
  metadata: ReplicaSetsResponseMetadata
  items: ReplicaSetsItem[]
}

export interface ReplicaSetsItem {
  kind: string
  apiVersion: string
  metadata: ItemMetadata
  spec: ItemSpec
  status: Status
}

interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  generation: number
  creationTimestamp: string
  labels: Record<string, string>
  annotations: Record<string, string>
  ownerReferences: OwnerReference[]
}
interface OwnerReference {
  apiVersion: string
  kind: string
  name: string
  uid: string
  controller: boolean
  blockOwnerDeletion: boolean
}

interface ItemSpec {
  replicas: number
  selector: Selector
  template: Template
}

interface Selector {
  matchLabels: Record<string, string>
}

interface Template {
  metadata: TemplateMetadata
  spec: TemplateSpec
}

interface TemplateMetadata {
  creationTimestamp: null
  labels: Record<string, string>
  name?: string
  annotations?: Record<string, string>
}

interface TemplateSpec {
  volumes?: Volume[]
  containers: Container[]
  initContainers: Container[]
  restartPolicy: string
  terminationGracePeriodSeconds: number
  dnsPolicy: string
  nodeSelector?: Record<string, string>
  serviceAccountName?: string
  serviceAccount?: string
  securityContext: SpecSecurityContext
  affinity?: any
  schedulerName: string
  tolerations?: Toleration[]
  priorityClassName?: string
  hostNetwork?: boolean
}

interface Container {
  name: string
  image: string
  args?: string[]
  ports?: PortElement[]
  resources: Resources
  volumeMounts?: VolumeMount[]
  livenessProbe?: LivenessProbeClass
  readinessProbe?: LivenessProbeClass
  terminationMessagePath: string
  terminationMessagePolicy: string
  imagePullPolicy: string
  securityContext?: ContainerSecurityContext
  command?: string[]
  env?: Env[]
}

interface Env {
  name: string
  valueFrom?: ValueFrom
  value?: string
}

interface ValueFrom {
  fieldRef: FieldRef
}

interface FieldRef {
  apiVersion: string
  fieldPath: string
}

interface LivenessProbeClass {
  httpGet: HTTPGet
  initialDelaySeconds?: number
  timeoutSeconds: number
  periodSeconds: number
  successThreshold: number
  failureThreshold: number
}

interface HTTPGet {
  path: string
  port: number | string
  scheme: Scheme
}

enum Scheme {
  HTTP = 'HTTP',
  HTTPS = 'HTTPS',
}

interface PortElement {
  name?: string
  containerPort: number
  protocol: Protocol
}

enum Protocol {
  TCP = 'TCP',
  UDP = 'UDP',
}

interface Resources {
  limits?: Limits
  requests?: Limits
}

interface Limits {
  cpu: string
  memory: string
}

interface ContainerSecurityContext {
  capabilities?: Capabilities
  readOnlyRootFilesystem?: boolean
  allowPrivilegeEscalation: boolean
  runAsUser?: number
  runAsGroup?: number
}

interface Capabilities {
  add: string[]
  drop: string[]
}

interface VolumeMount {
  name: string
  readOnly?: boolean
  mountPath: string
  subPath?: string
}

interface SpecSecurityContext {
  seccompProfile?: SeccompProfile
  supplementalGroups?: number[]
  fsGroup?: number
  runAsUser?: number
  runAsNonRoot?: boolean
}

interface SeccompProfile {
  type: string
}

interface Toleration {
  key: string
  operator?: string
  effect?: string
  value?: string
}

interface Volume {
  name: string
  configMap?: ConfigMap
  emptyDir?: {}
  secret?: Secret
  hostPath?: HostPath
}

interface ConfigMap {
  name: string
  items?: ConfigMapItem[]
  defaultMode: number
}

interface ConfigMapItem {
  key: string
  path: string
}

interface HostPath {
  path: string
  type: string
}

interface Secret {
  secretName: string
  defaultMode: number
}

interface Status {
  replicas: number
  fullyLabeledReplicas?: number
  readyReplicas?: number
  availableReplicas?: number
  observedGeneration: number
}

interface ReplicaSetsResponseMetadata {
  resourceVersion: string
}
