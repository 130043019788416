import { createSelector } from '@reduxjs/toolkit'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { complement, isNil, pipe, propEq, propSatisfies, whereEq } from 'ramda'
import { findClusterName } from 'k8s/util/helpers'
import { allKey } from 'app/constants'
import { arrayIfEmpty } from 'utils/fp'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'

// Can be either 'User' or 'Group'
const getSubjectsOfKind = (subjects, kind) =>
  subjects.filter((subject) => subject.kind === kind).map((user) => user.name)

export const apiGroupsSelector = createSharedSelector(
  getDataSelector<DataKeys.ApiGroups>(DataKeys.ApiGroups, ['clusterId']),
  getDataSelector<DataKeys.CoreApiResources>(DataKeys.CoreApiResources, ['clusterId']),
  getDataSelector<DataKeys.ApiResources>(DataKeys.ApiResources, ['clusterId']),
  (apiGroups, coreApiResources, apiResources) => {
    return arrayIfEmpty([
      ...(apiGroups.length && coreApiResources.length
        ? [
            {
              name: 'core',
              groupVersion: 'core',
              resources: coreApiResources,
            },
          ]
        : []),
      ...apiGroups.map((apiGroup) => {
        const {
          clusterId,
          name,
          preferredVersion: { groupVersion },
        } = apiGroup
        return {
          name,
          groupVersion,
          clusterId,
          resources: apiResources.filter(whereEq({ clusterId, apiGroup: groupVersion })),
        }
      }),
    ])
  },
)

export const makeApiGroupsSelector = (
  defaultParams = {
    orderBy: 'created_at',
    orderDirection: 'desc',
  } as SortConfig,
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(apiGroupsSelector, selectParams, (items, params) => {
    const { orderBy, orderDirection } = params
    return pipe(createSorter({ orderBy, orderDirection }))(items)
  })
}

export const rolesSelector = createSharedSelector(
  getDataSelector<DataKeys.KubeRoles>(
    DataKeys.KubeRoles,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  (items, clusters) => {
    return items
      .map((item) => ({
        ...item,
        id: item?.metadata?.uid,
        name: item?.metadata?.name,
        namespace: item?.metadata?.namespace,
        clusterName: findClusterName(clusters, item.clusterId),
        created: item?.metadata?.creationTimestamp,
        pickerLabel: `Role: ${item?.metadata?.name}`,
        pickerValue: `Role:${item?.metadata?.name}`,
      }))
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

export const makeRolesSelector = (
  defaultParams = {
    orderBy: 'created_at',
    orderDirection: 'desc',
  } as SortConfig & { namespace?: string; clusterId?: string; useGlobalParams?: boolean },
) => {
  // console.log(defaultParams, 'default params')
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(rolesSelector, selectParams, (items, params) => {
    const { namespace, orderBy, orderDirection } = params
    const filteredItems =
      namespace && namespace !== allKey ? items.filter(propEq('namespace', namespace)) : items
    return createSorter({ orderBy, orderDirection })(filteredItems)
  })
}

export const clusterRolesSelector = createSharedSelector(
  getDataSelector<DataKeys.ClusterRoles>(DataKeys.ClusterRoles, ['clusterId'], ['clusterId']),
  allClustersSelector,
  (items, clusters) => {
    return items
      .map((item) => ({
        ...item,
        id: item?.metadata?.uid,
        name: item?.metadata?.name,
        clusterName: findClusterName(clusters, item.clusterId),
        created: item?.metadata?.creationTimestamp,
        pickerLabel: `Cluster Role: ${item?.metadata?.name}`,
        pickerValue: `ClusterRole:${item?.metadata?.name}`,
      }))
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

export const makeClusterRolesSelector = (
  defaultParams = {
    orderBy: 'created_at',
    orderDirection: 'desc',
  } as SortConfig,
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(clusterRolesSelector, selectParams, (items, params) => {
    const { orderBy, orderDirection } = params
    return pipe(createSorter({ orderBy, orderDirection }))(items)
  })
}

export const roleBindingsSelector = createSharedSelector(
  // [makeParamsClustersSelector({ healthyClusters: true })], // do you mean to use `makeParamsClustersSelector({ healthyClusters: true })` here?
  getDataSelector<DataKeys.RoleBindings>(
    DataKeys.RoleBindings,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  (items, clusters) => {
    return items
      .map((item) => ({
        ...item,
        id: item?.metadata?.uid,
        name: item?.metadata?.name,
        namespace: item?.metadata?.namespace,
        clusterName: findClusterName(clusters, item.clusterId),
        created: item?.metadata?.creationTimestamp,
        users: item.subjects ? getSubjectsOfKind(item.subjects, 'User') : [],
        groups: item.subjects ? getSubjectsOfKind(item.subjects, 'Group') : [],
      }))
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

export const makeRoleBindingsSelector = (
  defaultParams = {
    orderBy: 'created_at',
    orderDirection: 'desc',
  } as SortConfig & { clusterId?: string; namespace?: string; useGlobalParams?: boolean },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(roleBindingsSelector, selectParams, (items, params) => {
    const { namespace, orderBy, orderDirection } = params
    const filteredItems =
      namespace && namespace !== allKey ? items.filter(propEq('namespace', namespace)) : items
    return createSorter({ orderBy, orderDirection })(filteredItems)
  })
}

export const clusterRoleBindingsSelector = createSharedSelector(
  // [makeParamsClustersSelector({ healthyClusters: true })], // do you mean to use `makeParamsClustersSelector({ healthyClusters: true })` here?
  getDataSelector<DataKeys.ClusterRoleBindings>(
    DataKeys.ClusterRoleBindings,
    ['clusterId'],
    ['clusterId'],
  ),
  allClustersSelector,
  (items, clusters) => {
    return items
      .map((item) => ({
        ...item,
        id: item?.metadata?.uid,
        name: item?.metadata?.name,
        clusterName: findClusterName(clusters, item.clusterId),
        created: item?.metadata?.creationTimestamp,
        users: item.subjects ? getSubjectsOfKind(item.subjects, 'User') : [],
        groups: item.subjects ? getSubjectsOfKind(item.subjects, 'Group') : [],
      }))
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

export const makeClusterRoleBindingsSelector = (
  defaultParams = {
    orderBy: 'created_at',
    orderDirection: 'desc',
  } as SortConfig,
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(clusterRoleBindingsSelector, selectParams, (items, params) => {
    const { orderBy, orderDirection } = params
    return pipe(createSorter({ orderBy, orderDirection }))(items)
  })
}
