import { isEmpty, toPairs as ToPairs } from 'ramda'
import { pathStr, isNilOrEmpty } from 'utils/fp'
import { add, memoize } from 'utils/misc'
import { labelsMatchSelector } from '../services/helpers'
import { BadgeVariant } from 'core/elements/badge/Badge'

const toPairs: any = ToPairs

export const calculateRestartCount = (_, pod) => {
  const containerStatuses = pod?.status?.containerStatuses
  if (!containerStatuses) return 'N/A'
  return containerStatuses.reduce(
    (acc, { restartCount }) =>
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      acc + parseInt(restartCount),
    0,
  )
}

const convertCpuUsageValue = (usageValue) => {
  if (!usageValue) return 0
  if (usageValue.includes('n')) {
    // Convert usage value from nanocores to millicores
    return parseInt(usageValue) * 0.000001
  }
  return parseInt(usageValue)
}

const convertMemoryUsageValue = (usageValue) => {
  if (!usageValue) return 0
  if (usageValue.includes('Ki')) {
    // Convert usage value from Ki to Mi
    return parseInt(usageValue) / 1024
  }
  return parseInt(usageValue)
}

// Get total resource stats for a list of pods
export const getPodsResourceStats = memoize((pods) => {
  if (!pods || isEmpty(pods)) return {}
  let totalCpuUsage
  let totalMemUsage
  let totalCpuLimits
  let totalMemLimits
  let totalCpuRequests
  let totalMemRequests

  pods.forEach((pod) => {
    const {
      usage: { cpu: cpuUsage, memory: memUsage },
      limits: { cpu: cpuLimits, memory: memLimits },
      requests: { cpu: cpuRequests, memory: memRequests },
    } = getPodResourceStats(pod)
    totalCpuUsage = add(cpuUsage, totalCpuUsage)
    totalMemUsage = add(memUsage, totalMemUsage)
    totalCpuLimits = add(cpuLimits, totalCpuLimits)
    totalMemLimits = add(memLimits, totalMemLimits)
    totalCpuRequests = add(cpuRequests, totalCpuRequests)
    totalMemRequests = add(memRequests, totalMemRequests)
  })

  return {
    usage: {
      cpu: totalCpuUsage ? `${totalCpuUsage}m` : totalCpuUsage,
      memory: totalMemUsage ? `${totalMemUsage}Mi` : totalMemUsage,
    },
    limits: {
      cpu: totalCpuLimits ? `${totalCpuLimits}m` : totalCpuLimits,
      memory: totalMemLimits ? `${totalMemLimits}Mi` : totalMemLimits,
    },
    requests: {
      cpu: totalCpuRequests ? `${totalCpuRequests}m` : totalCpuRequests,
      memory: totalMemRequests ? `${totalMemRequests}Mi` : totalMemRequests,
    },
  }
})

const getPodResourceUsage = (pod) => {
  if (!pod) return {} as any
  const containers = pod?.metrics?.containers || []
  let totalCpuUsage
  let totalMemUsage
  containers.forEach((container) => {
    const { cpu, memory } = container?.usage || {}
    totalCpuUsage = cpu ? convertCpuUsageValue(cpu) + parseFloat(totalCpuUsage || 0) : totalCpuUsage
    totalMemUsage = memory
      ? convertMemoryUsageValue(memory) + parseFloat(totalMemUsage || 0)
      : totalMemUsage
  })
  return {
    usage: {
      cpu: totalCpuUsage ? `${totalCpuUsage.toFixed(2)}m` : totalCpuUsage,
      memory: totalMemUsage ? `${totalMemUsage.toFixed(2)}Mi` : totalMemUsage,
    },
  }
}

// Get resource stats for a single pod
export const getPodResourceStats = (pod) => {
  const containers = pod?.spec?.containers || []
  if (!pod || isEmpty(containers)) return {} as any
  const resourceUsage = getPodResourceUsage(pod)

  let totalCpuLimits
  let totalMemLimits
  let totalCpuRequests
  let totalMemRequests

  for (const container of containers) {
    const cpuLimits = pathStr('resources.limits.cpu', container)
    const memLimits = pathStr('resources.limits.memory', container)
    const cpuRequests = pathStr('resources.requests.cpu', container)
    const memRequests = pathStr('resources.requests.memory', container)

    totalCpuLimits = add(cpuLimits, totalCpuLimits)
    totalMemLimits = add(memLimits, totalMemLimits)
    totalCpuRequests = add(cpuRequests, totalCpuRequests)
    totalMemRequests = add(memRequests, totalMemRequests)
  }
  return {
    ...resourceUsage,
    limits: {
      cpu: totalCpuLimits ? `${totalCpuLimits}m` : totalCpuLimits,
      memory: totalMemLimits ? `${totalMemLimits}Mi` : totalMemLimits,
    },
    requests: {
      cpu: totalCpuRequests ? `${totalCpuRequests}m` : totalCpuRequests,
      memory: totalMemRequests ? `${totalMemRequests}Mi` : totalMemRequests,
    },
  }
}

export const getResourcePods = (resourceSelectors = {} as any, pods = []) => {
  return pods.filter((pod) => labelsMatchSelector(pod?.metadata?.labels, resourceSelectors))
}

export const getContainerCpuUsage = (value) => {
  if (!value) return 'N/A'
  return `${convertCpuUsageValue(value).toFixed(2)}m`
}

export const getContainerMemoryUsage = (value) => {
  if (!value) return 'N/A'
  return `${convertMemoryUsageValue(value).toFixed(2)}m`
}

export const addContainerType = memoize((containers = [], type = 'Container') =>
  containers.map((container) => ({ ...container, type })),
)

export const getPodStatus = (phase) => {
  let status: BadgeVariant = 'warning'
  switch (phase) {
    case 'Running':
    case 'Succeeded':
      status = 'success'
      break

    case 'Failed':
      status = 'error'
      break
  }
  return { variant: status }
}

export const getContainerStatus = (state) => {
  if (isNilOrEmpty(state)) return { variant: 'unknown' as const, label: 'Unknown' }
  let status: BadgeVariant = 'success'

  switch (state) {
    case 'Terminated':
      status = 'error'
      break
    case 'Waiting':
      status = 'unknown'
      break
    case 'Pending':
      break
  }
  return { variant: status }
}
