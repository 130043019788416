import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const options = [
  { label: 'pfName', value: 'pfName' },
  { label: 'Vendor & Device ID', value: 'vendor' },
  { label: 'PCI Address', value: 'pciAddress' },
]

interface Props {}

export default function SriovDeviceSelectorPicklist({
  name = 'deviceSelector',
  label = 'Device Selector',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
