import React from 'react'
import { listTablePrefs } from 'app/constants'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { routes } from 'core/utils/routes'
import ListContainer from 'core/containers/ListContainer'
import { pick, isEmpty } from 'ramda'
import NoRepositoriesMessage from './NoRepositoriesMessage'
import UpdateRepositoryDialog from './UpdateRepositoryDialog'
import DataKeys from 'k8s/DataKeys'
import DocumentMeta from 'core/components/DocumentMeta'
import AddRepositoryFormModal from './AddRepositoryFormModal'
import EditRepositoryFormModal from './EditRepositoryFormModal'
import useListAction from 'core/hooks/useListAction'
import { listRepositories, deleteRepository } from '../new-actions'
import { useSelector } from 'react-redux'
import { repositoriesSelector } from '../selectors'
import Progress from 'core/components/progress/Progress'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import getGridRedirectButton from 'core/elements/grid/helpers/getGridRedirectButton'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import { HeaderPrimaryActionPortal } from 'core/elements/header/portals'
import CreateButton from 'core/components/buttons/CreateButton'
import useReactRouter from 'use-react-router'

const noRepositoriesHeaderMsg = 'Get started by attaching your first Helm Repository.'
const noRepositoriesSecondaryMsg = 'You can do so from the ‘+ Add Repository’ button above.'

type ModelDataKey = DataKeys.Repositories
type SelectorModel = ArrayElement<ReturnType<typeof repositoriesSelector>>

const usePrefParams = createUsePrefParamsHook('Repositories', listTablePrefs)

const searchTargets = ['name']

export const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'name', label: 'Name' },
  { key: 'numClusters', label: '# of Assigned Clusters' },
  { key: 'type', label: 'Type' },
]

const batchActions: GridBatchActionSpec<SelectorModel>[] = [
  {
    icon: 'edit',
    label: 'Edit',
    BatchActionButton: getGridRedirectButton<SelectorModel>(({ name }) =>
      routes.repositories.edit.path({ id: name }),
    ),
  },
  {
    icon: 'sync',
    label: 'Update',
    BatchActionButton: getGridDialogButton(UpdateRepositoryDialog),
  },
]

export default function RepositoriesListPage() {
  const { history } = useReactRouter()
  const { params, getParamsUpdater } = usePrefParams({})
  const { loading, message, reload } = useListAction(listRepositories)
  const repositories = useSelector(repositoriesSelector)

  return (
    <>
      <DocumentMeta title="Helm Repositories" />
      <AddRepositoryFormModal />
      <EditRepositoryFormModal />
      <Progress loading={loading} message={message} overlay>
        {isEmpty(repositories) && (
          <>
            <HeaderPrimaryActionPortal>
              <CreateButton onClick={() => history.push(routes.repositories.add.path())}>
                Add Repository
              </CreateButton>
            </HeaderPrimaryActionPortal>
            <NoRepositoriesMessage
              headerMessage={noRepositoriesHeaderMsg}
              secondaryMessage={noRepositoriesSecondaryMsg}
              showAddRepositoryLink={false}
            />
          </>
        )}
        {!isEmpty(repositories) && (
          <ListContainer<ModelDataKey, SelectorModel>
            dataKey={DataKeys.Repositories}
            searchTargets={searchTargets}
            uniqueIdentifier="name"
            loading={loading}
            loadingMessage={message}
            onRefresh={reload}
            data={repositories}
            columns={columns}
            addUrl={routes.repositories.add.path()}
            addText="Add Helm Repository"
            getParamsUpdater={getParamsUpdater}
            deleteAction={deleteRepository}
            batchActions={batchActions}
            {...pick(listTablePrefs, params)}
          />
        )}
      </Progress>
    </>
  )
}
