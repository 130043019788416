import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React from 'react'
import clsx from 'clsx'
import Text, { TextVariant } from 'core/elements/Text'

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(240px, 1fr)',
    height: '140px',
    margin: '7px 20px 64px 0px',
    backgroundColor: theme.components.card.background,
    gridGap: '32px',
    width: '100%',
  },
  info: {
    borderLeft: `1px solid ${theme.components.card.border}`,
    padding: '24px 32px 32px',
    margin: '0 32px 0 0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
  },
  label: {
    color: theme.components.card.text,
    marginBottom: '4px',
  },
}))

interface Column {
  label: string
  value: string | number
  render?: (value) => any
}

interface Props {
  columns: Column[]
  className?: string
  labelTextVariant?: TextVariant
  valueTextVariant?: TextVariant
}

const InfoHeader = ({
  columns = [],
  className,
  labelTextVariant = 'body2',
  valueTextVariant = 'subtitle1',
}: Props) => {
  const classes = useStyles()
  return (
    <header className={clsx(classes.header, className)}>
      {columns.map(({ label, value }) => (
        <article key={label} className={clsx(classes.info, 'info')}>
          <Text variant={labelTextVariant} className={clsx(classes.label, 'label')} component="h1">
            {label}
          </Text>
          <Text variant={valueTextVariant} component="p" className="value">
            {value}
          </Text>
        </article>
      ))}
    </header>
  )
}

export default InfoHeader
