import DocumentMeta from 'core/components/DocumentMeta'
import PicklistField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import Column from 'core/containers/Column'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { namespaceValidator } from 'core/utils/fieldValidators'
import { routes } from 'core/utils/routes'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import React from 'react'
import useReactRouter from 'use-react-router'
import { createNamespace } from './new-actions'

const defaultParams = {
  masterNodeClusters: true,
  clusterId: null,
}

export default function AddNamespaceForm() {
  const { history } = useReactRouter()
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(createNamespace)

  const submitForm = async (params) => {
    const { success } = await update(params)
    if (success) handleClose()
  }
  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.namespaces.list.path())
  }

  return (
    <>
      <DocumentMeta title="Add Namespace" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.namespaces.add}
        title="Add Namespace"
        submitTitle="Add Namespace"
        onSubmit={submitForm}
        submitting={updating}
        error={error}
        onClose={handleClose}
      >
        <Column>
          <TextField id="name" label="Name" required validations={[namespaceValidator]} />
          <PicklistField
            DropdownComponent={ClusterPicklist}
            id="clusterId"
            label="Cluster"
            onChange={getParamsUpdater('clusterId')}
            value={params.clusterId}
            setInitialCluster
            compact={false}
            showAll={false}
            required
          />
        </Column>
      </ModalForm>
    </>
  )
}
