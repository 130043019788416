import React, { useCallback, useState } from 'react'
import EditEntityLabelsModal from './EditEntityLabelsModal'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    display: 'inline-block',
    cursor: 'pointer',
  },
}))

export default function EditEntityLabelsButton({ resourceType, entity }) {
  const classes = useStyles()
  const [showModal, setShowModal] = useState(false)

  const handleClick = useCallback(() => {
    setShowModal(true)
  }, [setShowModal])

  return (
    <>
      <div className={classes.icon} onClick={handleClick}>
        <FontAwesomeIcon size="md">edit</FontAwesomeIcon>
      </div>
      {showModal && (
        <EditEntityLabelsModal
          resourceType={resourceType}
          entity={entity}
          open
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}
