import React from 'react'
import SelectableCard from 'core/components/SelectableCard'
import {
  CloudProviders,
  getCloudProviderIcon,
  getCloudProviderLabel,
} from 'app/plugins/infrastructure/components/cloudProviders/model'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import clsx from 'clsx'
import { hexToRgbaCss } from 'core/utils/colorHelpers'
import generateTestId from 'utils/test-helpers'

interface Props {
  type: CloudProviders
  active: boolean
  onClick?: (type: CloudProviders) => void
  className?: string
}

export default function CloudProviderSelectableCard({
  type,
  active,
  onClick,
  className = undefined,
  ...rest
}: Props) {
  const classes = useStyles({ active })
  return (
    <SelectableCard
      key={type}
      id={type}
      active={active}
      onClick={onClick}
      className={clsx(classes.card, className)}
      {...rest}
    >
      <div className={classes.container}>
        <img src={getCloudProviderIcon(type)} />
        <Text data-testid={generateTestId(getCloudProviderLabel(type))} variant="caption1">
          {getCloudProviderLabel(type)}
        </Text>
      </div>
    </SelectableCard>
  )
}

const useStyles = makeStyles<Theme, Partial<Props>>((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& img': {
      maxWidth: 50,
    },
  },
  card: {
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.components.selectableCard.activeBorder}`,
    },
    border: ({ active }) =>
      active
        ? `1px solid ${theme.components.selectableCard.activeBorder}`
        : `1px solid ${theme.components.selectableCard.border}`,
    background: ({ active }) =>
      active
        ? hexToRgbaCss(theme.components.selectableCard.activeBackground, 0.1)
        : theme.components.card.background,
    borderRadius: theme.spacing(0.5),
    minWidth: 300,
    '& .card-body': {
      padding: theme.spacing(2),
    },
  },
}))
