import { makeStyles } from '@material-ui/styles'
import CopyToClipboard from 'core/components/CopyToClipboard'
import Theme from 'core/themes/model'
import React from 'react'
import Text from 'core/elements/Text'
import IconButton from 'core/elements/button/IconButton'
import LogViewer from 'core/components/log-viewer'
import { Dialog } from '@material-ui/core'
import Button from 'core/elements/button'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  logsModal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
    zIndex: 100000000,
    boxSizing: 'border-box',
    backgroundColor: theme.components.card.background,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.components.card.border}`,
    marginBottom: '16px',
    padding: '16px 32px',
  },
  container: {
    alignContent: 'stretch',
    justifyContent: 'stretch',
  },
  actions: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    gap: '16px',
  },
  logViewer: {
    maxHeight: 'calc(100vh - 85px)',
    padding: '0 16px',
  },
}))

const LogsDialog = ({ containerName, log, onClose }) => {
  const classes = useStyles()

  return (
    <Dialog open>
      <div className={classes.logsModal}>
        <header className={classes.header}>
          <Text variant="subtitle2">{containerName}</Text>
          <div className={classes.actions}>
            <CopyToClipboard copyText={log} inline codeBlock={false} triggerWithChild>
              <Button icon="copy">Copy Logs</Button>
            </CopyToClipboard>
            <IconButton icon="times-circle" onClick={onClose} />
          </div>
        </header>
        <LogViewer
          logs={log}
          size={16}
          className={classes.logViewer}
          lineNumbers
          extraLines="both"
        />
      </div>
    </Dialog>
  )
}

export default LogsDialog
