import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useParams from 'core/hooks/useParams'
import ModalForm from 'core/elements/modal/ModalForm'
import SelectorParamFields from './SelectorParamFields'
import HostConfigurationsParamFields, {
  defaultInterfaceConfig,
  defaultOvsConfig,
  defaultSriovConfig,
} from './HostConfigurationsParamFields'
import { updateHostNetworkTemplate } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { InterfaceConfigs, OvsConfigs, SriovConfigs } from './AddHostConfigurationModal'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '16px 40px 40px 32px',
    display: 'grid',
    gap: 32,
  },
  radioText: {
    textTransform: 'uppercase',
    color: theme.components.typography.passive,
  },
  radioCards: {
    display: 'grid',
    gap: 16,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
  fields: {
    display: 'grid',
    gap: 24,
  },
}))

export default function EditHostConfigurationModal({ rows: [hostConfig], onClose }) {
  const [loaded, setLoaded] = useState(false)
  const classes = useStyles()

  const defaultParams = {
    selectors: {},
    interfaceConfigs: [defaultInterfaceConfig],
    ovsConfigs: [defaultOvsConfig],
    sriovConfigs: [defaultSriovConfig],
  }

  const { params, updateParams, setParams } = useParams<{
    selectors?: any
    interfaceConfigs?: InterfaceConfigs[]
    ovsConfigs?: OvsConfigs[]
    sriovConfigs?: SriovConfigs[]
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(updateHostNetworkTemplate)

  useEffect(() => {
    // Prevent resetting of form from background reloads
    if (loaded) {
      return
    }
    if (hostConfig) {
      updateParams({
        selectors: hostConfig?.spec?.nodeSelector,
        ovsConfigs: hostConfig?.spec?.ovsConfig?.map((config) => ({
          bridgeName: config?.bridgeName,
          nodeInterface: config?.nodeInterface,
          enableDpdk: !!config?.dpdk,
          enableParams: config?.params ? true : false,
          mtuRequest: config?.params?.mtu_request || defaultOvsConfig?.mtuRequest,
          bondMode: config?.params?.bond_mode || defaultOvsConfig?.bondMode,
          lacp: config?.params?.lacp || defaultOvsConfig?.lacp,
        })),
        interfaceConfigs: hostConfig?.spec?.interfaceConfig?.map((config) => ({
          name: config?.name,
          mtu: config?.mtu || defaultInterfaceConfig?.mtu,
          vlanIds: config?.params?.vlan?.map((vlan) => vlan?.id) || defaultInterfaceConfig?.vlanIds,
          ipv4Addresses: config?.params?.ipv4?.address || defaultInterfaceConfig?.ipv4Addresses,
          ipv6Addresses: config?.params?.ipv6?.address || defaultInterfaceConfig?.ipv6Addresses,
        })),
        sriovConfigs: hostConfig?.spec?.sriovConfig?.map((config) => ({
          deviceSelector: config?.pciAddr ? 'pciAddress' : config?.vendorId ? 'vendor' : 'pfName',
          pfName: config?.pfName || defaultSriovConfig?.pfName,
          vendorId: config?.vendorId || defaultSriovConfig?.vendorId,
          deviceId: config?.deviceId || defaultSriovConfig?.deviceId,
          pciAddress: config?.pciAddr || defaultSriovConfig?.pciAddress,
          numVfs: config?.numVfs || defaultSriovConfig?.numVfs,
          vfDriver: config?.vfDriver || defaultSriovConfig?.vfDriver,
        })),
      })
      setLoaded(true)
    }
  }, [hostConfig, loaded])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  const submitForm = useCallback(async () => {
    const interfaceConfig = params?.interfaceConfigs
      ?.filter((config) => !!config?.name)
      .map((config) => {
        return {
          name: config?.name,
          mtu: config?.mtu ? parseInt(config.mtu) : undefined,
          ipv4: config?.ipv4Addresses?.length ? { address: config.ipv4Addresses } : undefined,
          ipv6: config?.ipv6Addresses?.length ? { address: config.ipv6Addresses } : undefined,
          vlan: config?.vlanIds?.length
            ? config.vlanIds.map((id) => ({ id: parseInt(id) }))
            : undefined,
        }
      })
    const ovsConfig = params?.ovsConfigs
      ?.filter((config) => !!config?.bridgeName)
      .map((config) => {
        return {
          bridgeName: config?.bridgeName,
          nodeInterface: config?.nodeInterface,
          dpdk: !!config?.enableDpdk,
          params: {
            mtu_request: config?.mtuRequest ? config.mtuRequest : undefined,
            bond_mode: config?.bondMode ? config.bondMode : undefined,
            lacp: config?.lacp ? config.lacp : undefined,
          },
        }
      })
    const sriovConfig = params?.sriovConfigs
      ?.filter((config) => !!config?.numVfs)
      .map((config) => {
        return {
          pfName: config?.deviceSelector === 'pfName' ? config?.pfName : undefined,
          vendorId: config?.deviceSelector === 'vendor' ? config?.vendorId : undefined,
          deviceId: config?.deviceSelector === 'vendor' ? config?.deviceId : undefined,
          pciAddr: config?.deviceSelector === 'pciAddress' ? config?.pciAddress : undefined,
          numVfs: config?.numVfs,
          vfDriver: config?.vfDriver,
        }
      })

    const body = {
      nodeSelector: params?.selectors,
      interfaceConfig: interfaceConfig?.length ? interfaceConfig : undefined,
      ovsConfig: ovsConfig?.length ? ovsConfig : undefined,
      sriovConfig: sriovConfig?.length ? sriovConfig : undefined,
    }

    const ops = []
    ops.push({
      op: 'replace',
      path: '/spec',
      value: body,
    })

    const { success } = await update({
      ...hostConfig,
      body: ops,
      requestType: 'patch',
      contentType: 'application/json-patch+json',
    })
    if (success) {
      handleClose()
    }
  }, [params, handleClose])

  return (
    <ModalForm
      open
      title={`Edit Host Configuration`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Edit Host Configuration`}
      maxWidth={528}
      loading={!loaded}
    >
      <div className={classes.container}>
        <div className={classes.fields}>
          <SelectorParamFields method="edit" params={params} updateParams={updateParams} />
          <HostConfigurationsParamFields
            method="edit"
            params={params}
            updateParams={updateParams}
          />
        </div>
      </div>
    </ModalForm>
  )
}
