import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ListAction from 'core/actions/ListAction'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import Bugsnag from 'utils/bugsnag'
import { ensureArray, pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'

const { qbert } = ApiClient.getInstance()

const resourceQuotaActions = ActionsSet.make<DataKeys.ResourceQuotas>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.ResourceQuotas],
  cacheKey: DataKeys.ResourceQuotas,
})

export const listResourceQuotas = resourceQuotaActions.add(
  new ListAction<DataKeys.ResourceQuotas, { clusterId: string; namespace?: string }>(
    async (params) => {
      Bugsnag.leaveBreadcrumb('Attempting to get Resource Quotas', params)
      const { namespace, clusterId } = params
      return someAsync(
        ensureArray(namespace).map((name) => qbert.getResourceQuotas(clusterId, name)),
      ).then(flatten)
    },
  ),
)

export const createResourceQuota = resourceQuotaActions.add(
  new CreateAction<DataKeys.ResourceQuotas, { clusterId: string; namespace: string; body: any }>(
    async ({ clusterId, namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new Resource Quota', {
        clusterId,
        namespace,
        body,
      })
      const createdResourceQuota = await qbert.createResourceQuota(clusterId, namespace, body)
      trackEvent('Create New Resource Quota', {
        clusterId,
        namespace,
        name: pathStr('metadata.name', createdResourceQuota),
      })
      return createdResourceQuota
    },
  ),
)

export const updateResourceQuota = resourceQuotaActions.add(
  new UpdateAction<
    DataKeys.ResourceQuotas,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Resource Quota', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn = requestType === 'patch' ? qbert.patchResourceQuota : qbert.updateResourceQuota
    const updatedResourceQuota = await updateFn({
      clusterId,
      namespace,
      name,
      body,
      contentType,
    })
    trackEvent('Update Resource Quota', { clusterId, namespace, name })
    return updatedResourceQuota
  }),
)

export const deleteResourceQuota = resourceQuotaActions.add(
  new DeleteAction<DataKeys.ResourceQuotas, { clusterId: string; namespace: string; name: string }>(
    async ({ clusterId, namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Resource Quota', {
        clusterId,
        namespace,
        name,
      })
      await qbert.deleteResourceQuota(clusterId, namespace, name)
    },
  ),
)
