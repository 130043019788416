import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { flatten, uniq } from 'ramda'
import { someAsync } from 'utils/async'
import { trackEvent } from 'utils/tracking'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'
import { RoleRef } from 'api-client/qbert.model'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'

const { qbert } = ApiClient.getInstance()

const uniqueIdentifier = 'metadata.uid'

const rbacApiGroupsToRules = (apiGroups) => {
  const rules = []
  Object.keys(apiGroups).map((apiGroupName) => {
    Object.keys(apiGroups[apiGroupName]).map((resourceName) => {
      const rule = {
        apiGroups: apiGroupName === 'core' ? [''] : [apiGroupName],
        resources: [resourceName],
        verbs: Object.keys(apiGroups[apiGroupName][resourceName]),
      }
      rules.push(rule)
    })
  })
  return rules
}

export const coreApiResourceActions = ActionsSet.make<DataKeys.CoreApiResources>({
  uniqueIdentifier: ['name', 'clusterId'],
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.CoreApiResources],
  cacheKey: DataKeys.CoreApiResources,
})

export const listCoreApiResources = coreApiResourceActions.add(
  new ListAction<DataKeys.CoreApiResources, { clusterId: string }>(async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to load core API resources', { clusterId })
    const response = await qbert.getCoreApiResourcesList(clusterId)
    return response.resources
  }),
)

export const apiResourceActions = ActionsSet.make<DataKeys.ApiResources>({
  uniqueIdentifier: ['name', 'clusterId'],
  indexBy: ['clusterId', 'apiGroup'],
  entityName: entityNamesByKey[DataKeys.ApiResources],
  cacheKey: DataKeys.ApiResources,
})

export const listApiResources = apiResourceActions.add(
  new ListAction<DataKeys.ApiResources, { clusterId: string | string[]; apiGroup: string }>(
    async ({ clusterId, apiGroup }) => {
      Bugsnag.leaveBreadcrumb('Attempting to load API resources', { clusterId, apiGroup })
      try {
        const response = await qbert.getApiResourcesList({ clusterId, apiGroup })
        return response.resources
      } catch (err) {
        console.log(err, 'error')
        return []
      }
    },
  ),
)

export const apiGroupActions = ActionsSet.make<DataKeys.ApiGroups>({
  uniqueIdentifier: ['name', 'clusterId'],
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.ApiGroups],
  cacheKey: DataKeys.ApiGroups,
})

export const listApiGroups = apiGroupActions.add(
  new ListAction<DataKeys.ApiGroups, { clusterId: string }>(async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to load API groups', { clusterId })
    const response = await qbert.getApiGroupList(clusterId)
    const apiGroups = response.groups
    const groupVersions = uniq(apiGroups.map((apiGroup) => apiGroup.preferredVersion.groupVersion))
    await Promise.all(
      groupVersions.map((apiGroup) => listApiResources.call({ clusterId, apiGroup })),
    )
    return apiGroups
  }).addDependency(DataKeys.CoreApiResources),
)

export const roleActions = ActionsSet.make<DataKeys.KubeRoles>({
  uniqueIdentifier,
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.KubeRoles],
  cacheKey: DataKeys.KubeRoles,
})

export const listRoles = roleActions.add(
  new ListAction<DataKeys.KubeRoles>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get kube roles', params)
    return qbert.getClusterRoles(params.clusterId)
  }),
)

export const createRole = roleActions.add(
  new CreateAction<DataKeys.KubeRoles>(async (data) => {
    const rules = rbacApiGroupsToRules(data.rbac)
    const body = {
      apiVersion: 'rbac.authorization.k8s.io/v1',
      kind: 'Role',
      metadata: {
        name: data.name,
        namespace: data.namespace,
      },
      rules,
    }
    Bugsnag.leaveBreadcrumb('Attempting to create kube role', body)
    trackEvent('Create Kube Role', body)
    return qbert.createClusterRole(data.clusterId, data.namespace, body)
  }),
)

export const updateRole = roleActions.add(
  new UpdateAction<DataKeys.KubeRoles>(async (data) => {
    const rules = rbacApiGroupsToRules(data.rbac)
    const body = {
      apiVersion: 'rbac.authorization.k8s.io/v1',
      kind: 'Role',
      metadata: {
        name: data.name,
      },
      rules,
    }
    Bugsnag.leaveBreadcrumb('Attempting to update kube role', body)
    trackEvent('Update Kube Role', body)
    return qbert.updateClusterRole(data.clusterId, data.namespace, data.name, body)
  }),
)

export const deleteRole = roleActions.add(
  new DeleteAction<DataKeys.KubeRoles>(async ({ clusterId, namespace, name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete kube role', { clusterId, name, namespace })
    const { qbert } = ApiClient.getInstance()
    trackEvent('Delete Kube Role', { clusterId, name, namespace })
    await qbert.deleteClusterRole(clusterId, namespace, name)
  }),
)

export const clusterRoleActions = ActionsSet.make<DataKeys.ClusterRoles>({
  uniqueIdentifier,
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.ClusterRoles],
  cacheKey: DataKeys.ClusterRoles,
})

export const listClusterRoles = clusterRoleActions.add(
  new ListAction<DataKeys.ClusterRoles>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get cluster roles', params)
    return qbert.getClusterClusterRoles(params.clusterId)
  }),
)

export const createClusterRole = clusterRoleActions.add(
  new CreateAction<DataKeys.ClusterRoles>(async (data) => {
    Bugsnag.leaveBreadcrumb('Attempting to create cluster role', {
      clusterId: data.clusterId,
      name: data.name,
    })
    const rules = rbacApiGroupsToRules(data.rbac)
    const body = {
      apiVersion: 'rbac.authorization.k8s.io/v1',
      kind: 'ClusterRole',
      metadata: {
        name: data.name,
      },
      rules,
    }
    trackEvent('Create Cluster Role', { clusterId: data.clusterId, name: data.name })
    return qbert.createClusterClusterRole(data.clusterId, body)
  }),
)

export const updateClusterRole = clusterRoleActions.add(
  new UpdateAction<DataKeys.ClusterRoles>(async (data) => {
    Bugsnag.leaveBreadcrumb('Attempting to update cluster role', {
      clusterId: data.clusterId,
      name: data.name,
    })
    const rules = rbacApiGroupsToRules(data.rbac)
    const body = {
      apiVersion: 'rbac.authorization.k8s.io/v1',
      kind: 'ClusterRole',
      metadata: {
        name: data.name,
      },
      rules,
    }
    trackEvent('Update Cluster Role', { clusterId: data.clusterId, name: data.name })
    return qbert.updateClusterClusterRole(data.clusterId, data.name, body)
  }),
)

export const deleteClusterRole = clusterRoleActions.add(
  new DeleteAction<DataKeys.ClusterRoles>(async ({ clusterId, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete cluster role', { id, clusterId, name })
    trackEvent('Delete Cluster Role', { id, clusterId, name })
    await qbert.deleteClusterClusterRole(clusterId, name)
  }),
)

export const roleBindingActions = ActionsSet.make<DataKeys.RoleBindings>({
  uniqueIdentifier,
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.RoleBindings],
  cacheKey: DataKeys.RoleBindings,
})

export const listRoleBindings = roleBindingActions.add(
  new ListAction<DataKeys.RoleBindings, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get role bindings', params)
    return qbert.getClusterRoleBindings(params.clusterId)
  }),
)

export const createRoleBinding = roleBindingActions.add(
  new CreateAction<
    DataKeys.RoleBindings,
    {
      clusterId: string
      namespace: string
      name: string
      users: string[]
      groups: string[]
      role: string
    }
  >(async (data) => {
    const { clusterId, namespace, name } = data
    Bugsnag.leaveBreadcrumb('Attempting to create role binding', { clusterId, namespace, name })
    const users = data.users.map((user) => ({
      kind: 'User',
      name: user,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const groups = data.groups.map((group) => ({
      kind: 'Group',
      name: group,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const subjects = [...users, ...groups]

    const [roleType, ...rest] = data.role.split(':')
    const roleName = rest.join(':')
    const body = {
      kind: 'RoleBinding',
      metadata: {
        name: data.name,
        namespace: data.namespace,
      },
      subjects,
      roleRef: {
        kind: roleType,
        name: roleName,
        apiGroup: 'rbac.authorization.k8s.io',
      },
    }
    trackEvent('Create Role Binding', { clusterId, namespace, name })
    return qbert.createClusterRoleBinding(data.clusterId, data.namespace, body)
  }),
)

export const updateRoleBinding = roleBindingActions.add(
  new UpdateAction<
    DataKeys.RoleBindings,
    {
      clusterId: string
      namespace: string
      name: string
      users: string[]
      groups: string[]
      roleRef: RoleRef
    }
  >(async (data) => {
    const { clusterId, namespace, name } = data
    Bugsnag.leaveBreadcrumb('Attempting to update role binding', { clusterId, namespace, name })
    const users = data.users.map((user) => ({
      kind: 'User',
      name: user,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const groups = data.groups.map((group) => ({
      kind: 'Group',
      name: group,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const subjects = [...users, ...groups]

    const body = {
      kind: 'RoleBinding',
      metadata: {
        name: data.name,
      },
      subjects,
      roleRef: data.roleRef,
    }
    trackEvent('Update Role Binding', { clusterId, namespace, name })
    return qbert.updateClusterRoleBinding(data.clusterId, data.namespace, data.name, body)
  }),
)

export const deleteRoleBinding = roleBindingActions.add(
  new DeleteAction<DataKeys.RoleBindings>(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete role binding', { clusterId, namespace, name, id })
    await qbert.deleteClusterRoleBinding(clusterId, namespace, name)
    trackEvent('Delete Role Binding', { clusterId, namespace, name, id })
  }),
)

export const clusterRoleBindingActions = ActionsSet.make<DataKeys.ClusterRoleBindings>({
  uniqueIdentifier,
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.ClusterRoleBindings],
  cacheKey: DataKeys.ClusterRoleBindings,
})

export const listClusterRoleBindings = clusterRoleBindingActions.add(
  new ListAction<DataKeys.ClusterRoleBindings>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get cluster role bindings', params)
    return qbert.getClusterClusterRoleBindings(params.clusterId)
  }),
)

export const createClusterRoleBinding = clusterRoleBindingActions.add(
  new CreateAction<
    DataKeys.ClusterRoleBindings,
    { clusterId: string; name: string; users: string[]; groups: string[]; clusterRole: string }
  >(async (data) => {
    const { clusterId, name } = data
    Bugsnag.leaveBreadcrumb('Attempting to create cluster role binding', { clusterId, name })
    const users = data.users.map((user) => ({
      kind: 'User',
      name: user,
      apiGroup: 'rbac.authorization.k8s.io',
    }))
    const groups = data.groups.map((group) => ({
      kind: 'Group',
      name: group,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const subjects = [...users, ...groups]

    const [roleType, ...rest] = data.clusterRole.split(':')
    const roleName = rest.join(':')
    const body = {
      kind: 'ClusterRoleBinding',
      metadata: {
        name: data.name,
      },
      subjects,
      roleRef: {
        kind: roleType,
        name: roleName,
        apiGroup: 'rbac.authorization.k8s.io',
      },
    }
    trackEvent('Create Cluster Role Binding', { clusterId, name })
    return qbert.createClusterClusterRoleBinding(data.clusterId, body)
  }),
)

export const updateClusterRoleBinding = clusterRoleBindingActions.add(
  new UpdateAction<
    DataKeys.ClusterRoleBindings,
    { clusterId: string; name: string; users: string[]; groups: string[]; roleRef: RoleRef }
  >(async (data) => {
    const { clusterId, name } = data
    Bugsnag.leaveBreadcrumb('Attempting to update cluster role binding', { clusterId, name })
    const users = data.users.map((user) => ({
      kind: 'User',
      name: user,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const groups = data.groups.map((group) => ({
      kind: 'Group',
      name: group,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const subjects = [...users, ...groups]

    const body = {
      kind: 'ClusterRoleBinding',
      metadata: {
        name: data.name,
      },
      subjects,
      roleRef: data.roleRef,
    }
    trackEvent('Update Cluster Role Binding', { clusterId, name })
    return qbert.updateClusterClusterRoleBinding(data.clusterId, data.name, body)
  }),
)

export const deleteClusterRoleBinding = clusterRoleBindingActions.add(
  new DeleteAction<DataKeys.ClusterRoleBindings>(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete cluster role binding', { id })
    await qbert.deleteClusterClusterRoleBinding(clusterId, name)
    trackEvent('Delete Cluster Role Binding', { id })
  }),
)
