import { IGenericResource } from 'api-client/qbert.model'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'

export enum IResourceQuotaDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface ResourceQuotasResponse {
  kind: string
  apiVersion: string
  metadata: ResourceQuotasResponseMetadata
  items: ResourceQuotaItem[]
}

export interface ResourceQuotaItem {
  metadata: ItemMetadata
  spec: ResourceQuotaSpec
  status?: ResourceQuotaStatus
}

interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels?: Record<string, string>
  ownerReferences?: OwnerReference[]
  annotations?: Record<string, string>
}

export interface OwnerReference {
  apiVersion: string
  kind: string
  name: string
  uid: string
  controller: boolean
  blockOwnerDeletion: boolean
}

interface ResourceQuotasResponseMetadata {
  resourceVersion: string
}

interface ResourceQuotaSpec {
  hard?: Record<string, string>
}

export interface ResourceQuotaStatus {
  hard?: Record<string, string>
  used?: Record<string, string>
}

export interface StatusObj {
  resource?: string
  used?: string
  max?: string
  usage?: number
}

export interface IResourceQuotaSelector extends IGenericResource<ResourceQuotaItem> {
  clusterName: string
  clusterId: string
  clusterType: ClusterTypes
  ownerReferences: ResourceQuotaItem['metadata']['ownerReferences']
  age?: string
  labels: ResourceQuotaItem['metadata']['labels']
  annotations: ResourceQuotaItem['metadata']['annotations']
  statusObj: StatusObj[]
}
