import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import { routes } from 'core/utils/routes'
import { deleteDataVolume } from './new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'

export default function DeleteDataVolumeDialog({ rows: [dataVolume], onClose }) {
  const { history } = useReactRouter()
  const { update: deleteFn, updating: deletingDataVolume } = useUpdateAction(deleteDataVolume)
  const title = `Delete "${dataVolume?.name}"?`
  const handleDelete = useCallback(async () => {
    await deleteFn(dataVolume)
    onClose(true)
    history.push(routes.kubevirtStorage.dataVolumes.list.path())
  }, [dataVolume])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingDataVolume}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingDataVolume}>
            Yes, delete
          </Button>
        </>
      }
    >
      <Text variant="body2">
        Deleting a Data Volume is an irreversible action and might bring down the associated VM.
      </Text>
    </Modal>
  )
}
