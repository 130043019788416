import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import DeploymentOverview from './overview'
import EntityEventsPage from '../common/entity/entity-events-page'
import EntityYamlPage from '../common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import { pathStrOr } from 'utils/fp'
import jsYaml from 'js-yaml'
import EntityLogsPage from '../common/entity/entity-logs-page'
import { IDeploymentDetailsPageTabs, IDeploymentSelector } from './model'
import InfoHeader from '../common/entity/info-header'
import EntityPodsPage from '../common/entity/entity-pods-page'
import { isEmpty } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import { listDeployments, updateDeployment } from './new-actions'
import { listPods } from 'k8s/components/pods/new-actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { deploymentsSelector } from './selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import useParams from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'

const { qbert } = ApiClient.getInstance()

const defaultDeployment = {
  deploymentServices: [],
  deploymentPods: [],
} as IDeploymentSelector

const DeploymentOverviewPage = () => {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { clusterId, id } = match.params
  const { globalParams: params } = useGlobalParams(useParams, {})
  const { loading, reload } = useListAction(listDeployments, { params })
  const deployments = useSelectorWithParams(deploymentsSelector, {
    clusterId,
    useGlobalParams: false,
  })
  const deployment = useMemo(
    () => deployments.find((deployment) => deployment.id === id) || defaultDeployment,
    [id, deployments],
  )
  const { update, updating } = useUpdateAction(updateDeployment)

  const { loading: loadingPods, reload: reloadPods } = useListAction(listPods, { params })

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: deployment?.clusterName || clusterId,
        id: deployment?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [deployment?.clusterName, deployment?.name, id, clusterId])

  const getDeploymentYaml = useCallback(async () => {
    if (isEmpty(deployment)) return undefined
    return qbert.getClusterDeployment(deployment.clusterId, deployment.namespace, deployment.name)
  }, [deployment])

  const handleDeploymentUpdate = useCallback(
    async (yaml) => {
      const body = jsYaml.load(yaml)
      const namespace = pathStrOr('', 'metadata.namespace', body)
      const name = pathStrOr('', 'metadata.name', body)
      await update({
        id,
        clusterId,
        namespace,
        name,
        body,
      })
    },
    [deployment],
  )

  const headerColumns = useMemo(() => {
    if (isEmpty(deployment)) return []
    return [
      { label: 'Name', value: deployment?.name },
      { label: 'Cluster', value: deployment?.clusterName },
      { label: 'Strategy', value: deployment?.strategyType },
      { label: 'Age', value: deployment?.age },
    ]
  }, [deployment])

  return (
    <>
      <DocumentMeta title="Deployments Overview" breadcrumbs />
      <InfoHeader columns={headerColumns} />
      <Tabs route={routes.deployments.details}>
        <Tab value={IDeploymentDetailsPageTabs.Overview} label="Overview">
          <DeploymentOverview deployment={deployment} loading={loading} />
        </Tab>
        <Tab value={IDeploymentDetailsPageTabs.Pods} label="Pods">
          <EntityPodsPage
            pods={deployment?.deploymentPods}
            loading={loading || loadingPods}
            reload={reloadPods}
          />
        </Tab>
        <Tab value={IDeploymentDetailsPageTabs.Events} label="Events">
          <EntityEventsPage entity={deployment} loading={loading} />
        </Tab>
        <Tab value={IDeploymentDetailsPageTabs.Logs} label="Logs">
          <EntityLogsPage pods={deployment?.deploymentPods} loading={loading} />
        </Tab>
        <Tab value={IDeploymentDetailsPageTabs.Yaml} label="YAML">
          <EntityYamlPage
            entityType="Deployment"
            entityName={deployment?.name}
            getYamlFn={getDeploymentYaml}
            handleUpdate={handleDeploymentUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default DeploymentOverviewPage
