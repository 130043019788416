import React from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import useReactRouter from 'use-react-router'
import Button from 'core/elements/button'
import Card from 'core/elements/card'
import CardBody from 'core/elements/card/CardBody'
import Text from 'core/elements/Text'
import ImageWithFallback from 'core/components/image-fallback'
import { medAppPlaceholderIcon } from 'k8s/components/app-catalog/helpers'
import Tooltip from 'core/elements/tooltip'
import Progress from 'core/components/progress/Progress'

export default function AppInfoHeaderCard({
  appDetails,
  loading,
  handleEditClick,
  handleUninstallClick,
}) {
  const classes = useStyles()
  const { match } = useReactRouter()
  const { repository, name } = match.params
  const { icon, chartVersion, description, home, clusterName, namespace } = appDetails

  return (
    <Card
      className={classes.appCard}
      title={
        <div className={classes.cardHeader}>
          <ImageWithFallback
            src={icon}
            fallbackSrc={medAppPlaceholderIcon}
            alt="App Logo"
            className={classes.appIcon}
          />
          <Text variant="h3">{name}</Text>
        </div>
      }
      footer={
        <div className={classes.cardActions}>
          <Button
            icon="edit"
            variant="tertiary"
            className={classes.editButton}
            onClick={handleEditClick}
          >
            Edit Deployment
          </Button>
          <Button variant="secondary" onClick={handleUninstallClick}>
            Uninstall
          </Button>
        </div>
      }
      withCustomBody
    >
      <CardBody className={classes.cardBody}>
        <Progress loading={loading} overlay>
          <div className={classes.cardInfo}>
            <Tooltip message={description} className={classes.descriptionTooltip}>
              <Text variant="body2" lineClamp={5} maxWidth={300}>
                {description}
              </Text>
            </Tooltip>
            <div className={classes.row}>
              <Text variant="caption1">Cluster</Text>
              <Text variant="body2">{clusterName}</Text>
            </div>
            <div className={classes.row}>
              <Text variant="caption1">Namespace</Text>
              <Text variant="body2">{namespace}</Text>
            </div>
            <div className={classes.row}>
              <Text variant="caption1">Latest Version</Text>
              <Text variant="body2">{chartVersion}</Text>
            </div>
            <div className={classes.row}>
              <Text variant="caption1">Repository</Text>
              <Text variant="body2">{repository}</Text>
            </div>
            <div className={classes.row}>
              <Text variant="caption1">Info</Text>
              <Text variant="body2">{home}</Text>
            </div>
          </div>
        </Progress>
      </CardBody>
    </Card>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  appCard: {
    minHeight: '229px',
    maxWidth: '400px',
  },
  cardHeader: {
    display: 'flex',
    wordBreak: 'break-all',
    gap: theme.spacing(3),
    padding: '16px 32px 0px 32px',
  },
  appIcon: {
    alignSelf: 'center',
    marginBottom: theme.spacing(2),
    width: '68px',
    padding: theme.spacing(1),
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardInfo: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  descriptionTooltip: {
    wordBreak: 'break-word',
  },
  cardActions: {
    display: 'grid',
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  editButton: {
    '& > div': {
      justifyContent: 'center',
    },
  },
  row: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))
