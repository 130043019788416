import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { createGridArrayCell } from 'core/elements/grid/cells/GridArrayCell'
import { renderAge } from '../common/entity/helpers'
import { configMapColumns, ingressTableColumns } from '../common/entity/constants'
import { getResourceIngresses } from '../ingresses/helpers'
import Progress from 'core/components/progress/Progress'
import InfoCard from '../common/entity/info-card'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import { getConfigMapsForResource } from '../config-maps/helpers'
import Grid from 'core/elements/grid'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listIngresses } from '../ingresses/new-actions'
import { ingressesSelector } from '../ingresses/selectors'
import { listConfigMaps } from '../config-maps/new-actions'
import { configMapsSelector } from '../config-maps/selectors'
import { ExternalEndpoint, InternalEndpoint, IServicesSelector } from './model'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { renderResourceLabels } from '../common/entity/labels-and-annotations/helpers'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import { renderClusterName } from '../pods/overview'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  metadataTable: {
    borderSpacing: '20px',
    'last-child': {
      width: '100%',
    },
  },
  noneText: {
    margin: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}))

const metadataFields = [
  { id: 'name', title: 'Name', required: true },
  { id: 'namespace', title: 'Namespace', required: true },
  {
    id: 'clusterId',
    title: 'Cluster',
    render: renderClusterName,
    required: true,
  },
  {
    id: 'selectors',
    title: 'Selector',
    required: true,
    render: renderResourceLabels({ separator: '=' }),
  },
  { id: 'type', title: 'Type', required: true },
  { id: 'clusterIp', title: 'Cluster IP', required: true },
  {
    id: 'age',
    title: 'Age',
    required: true,
    render: renderAge,
  },
]

const connectionTableColumns: GridViewColumn<IServicesSelector>[] = [
  { key: 'type', label: 'Type' },
  { key: 'spec.clusterIP', label: 'IP' },
  {
    key: 'spec.ports',
    label: 'Ports',
    CellComponent: createGridArrayCell({
      nameFn: ({ protocol, port }) => `${port}/${protocol}`,
    }),
  },
]

const renderInternalEndpoints = (endpoints = []) => (
  <>
    {endpoints.map((endpoint, i) => (
      <Text key={endpoint} variant="body2">
        {endpoint}
      </Text>
    ))}
  </>
)

const internalEndpointsTableColumns: GridViewColumn<InternalEndpoint>[] = [
  { key: 'name', label: 'Name' },
  { key: 'endpoints', label: 'Endpoints', render: renderInternalEndpoints },
]

const externalEndpointsTableColumns: GridViewColumn<ExternalEndpoint>[] = [
  { key: 'hostname', label: 'Hostname' },
  { key: 'ip', label: 'IP' },
  {
    key: 'ports',
    label: 'Ports',
    CellComponent: createGridArrayCell({
      nameFn: ({ protocol, port }) => `${port}/${protocol}`,
    }),
  },
]

const ServiceOverview = ({ service, loading }) => {
  const classes = useStyles()
  const params = useMemo(
    () => ({
      clusterId: service?.clusterId,
    }),
    [service],
  )
  const { loading: loadingIngresses } = useListAction(listIngresses, {
    params,
    requiredParams: ['clusterId'],
  })
  const allIngresses = useSelectorWithParams(ingressesSelector, params)
  const ingresses = useMemo(() => getResourceIngresses([service], allIngresses), [
    allIngresses,
    service,
  ])
  const { loading: loadingConfigMaps } = useListAction(listConfigMaps, {
    params,
    requiredParams: ['clusterId'],
  })
  const allConfigMaps = useSelectorWithParams(configMapsSelector, params)
  const configMaps = useMemo(() => getConfigMapsForResource(service, allConfigMaps), [
    allConfigMaps,
    service,
  ])

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, service)
  }, [service])

  return (
    <Progress loading={loading}>
      <article className={classes.overview}>
        <div className={classes.column}>
          <InfoCard
            items={metadata}
            title="Metadata"
            footer={<LabelsAndAnnotationsSection entity={service} resourceType="service" />}
          />
          <Card title="Config Maps" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={configMaps}
              columns={configMapColumns}
              loading={loadingConfigMaps}
              compact
              disableToolbar
            />
          </Card>
        </div>
        <div className={classes.column}>
          <Card title="Connections" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={[service]}
              columns={connectionTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Ingresses" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={ingresses}
              columns={ingressTableColumns}
              loading={loadingIngresses}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Internal Endpoints" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={service?.internalEndpoints}
              columns={internalEndpointsTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
          <Card title="External Endpoints" withCustomBody>
            <Grid
              uniqueIdentifier="hostname"
              data={service?.externalEndpoints}
              columns={externalEndpointsTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </article>
    </Progress>
  )
}

export default ServiceOverview
