import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import NumberedStepLabel from 'core/components/validatedForm/NumberedStepLabel'
import TextField from 'core/components/validatedForm/TextField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import StorageClassesPicklist from '../StorageClassesPicklist'
import AccessModePicklist from './AccessModePicklist'
import VolumeModePicklist from './VolumeModePicklist'
import PvcSizePicklist from './PvcSizePicklist'
import Text from 'core/elements/Text'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import { keyValueArrToObj, objToKeyValueArr } from 'utils/fp'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    display: 'grid',
    gap: 24,
  },
  withUnits: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  unitsDropdown: {
    position: 'relative',
    bottom: 1,
  },
}))

export default function PvcParamFields({ params, updateParams }) {
  const classes = useStyles()

  return (
    <div className={classes.fields}>
      <TextField
        id="name"
        label="Name"
        placeholder="Enter name..."
        onChange={(value) => updateParams({ name: value })}
        required
      />
      <PicklistField
        DropdownComponent={StorageClassesPicklist}
        id="storageClass"
        clusterId={params?.clusterId}
        label="Storage Class"
        onChange={(value) => updateParams({ storageClass: value })}
        value={params?.storageClass}
        compact={false}
      />
      <PicklistField
        DropdownComponent={AccessModePicklist}
        id="accessMode"
        label="Access Mode"
        onChange={(value) => updateParams({ accessMode: value })}
        value={params?.accessMode}
        compact={false}
      />
      <PicklistField
        DropdownComponent={VolumeModePicklist}
        id="volumeMode"
        label="Volume Mode"
        onChange={(value) => updateParams({ volumeMode: value })}
        value={params?.volumeMode}
        compact={false}
      />
      <div className={classes.withUnits}>
        <TextField
          id="size"
          label="Size"
          onChange={(value) => updateParams({ size: value })}
          value={params.size}
          type="number"
          min={1}
        />
        <PvcSizePicklist
          name="Unit"
          value={params.sizeUnit}
          onChange={(value) => updateParams({ sizeUnit: value })}
          className={classes.unitsDropdown}
        />
      </div>
      <div>
        <Text variant="caption1">Use label selectors to request storage.</Text>
        <KeyValuesField
          id="labels"
          value={objToKeyValueArr(params?.labels)}
          onChange={(value) => updateParams({ labels: keyValueArrToObj(value) })}
          addLabel="Add Label Selector"
          allowMultipleValues
        />
      </div>
    </div>
  )
}
