import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import NumberedStepLabel from 'core/components/validatedForm/NumberedStepLabel'
import TextField from 'core/components/validatedForm/TextField'
import ToggleSwitch from 'core/elements/ToggleSwitch'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    display: 'grid',
    gap: 24,
  },
}))

export default function OvsConfigurationFields({
  index,
  values,
  updateField,
  updateFields,
  isActive,
}) {
  const classes = useStyles()

  return (
    <div className={classes.fields}>
      <NumberedStepLabel step={index + 1} title="OVS Config" />
      <TextField
        id={`ovsConfigs.${index}.bridgeName`}
        label="Bridge Name"
        placeholder="Enter value..."
        onChange={(value) => updateField('bridgeName', index)(value)}
        value={values?.bridgeName}
        required={isActive}
      />
      <TextField
        id={`ovsConfigs.${index}.nodeInterface`}
        label="Node Interface"
        placeholder="Enter value..."
        onChange={(value) => updateField('nodeInterface', index)(value)}
        value={values?.nodeInterface}
        required={isActive}
      />
      <ToggleSwitch
        label="Enable DPDK"
        active={!!values?.enableDpdk}
        onClick={(value) => updateField('enableDpdk', index)(value)}
      />
      <ToggleSwitch
        label="Enable Params"
        active={!!values?.enableParams}
        onClick={(value) => {
          value
            ? updateField('enableParams', index)(value)
            : // Want to reset these to match default params
              updateFields(index)({
                enableParams: value,
                mtuRequest: '',
                bondMode: '',
                lacp: '',
              })
        }}
      />
      {values?.enableParams && (
        <>
          <TextField
            id={`ovsConfigs.${index}.mtuRequest`}
            label="MTU Request"
            placeholder="Enter value..."
            onChange={(value) => updateField('mtuRequest', index)(value)}
            value={values?.mtuRequest}
          />
          <TextField
            id={`ovsConfigs.${index}.bondMode`}
            label="Bond Mode"
            placeholder="Enter value..."
            onChange={(value) => updateField('bondMode', index)(value)}
            value={values?.bondMode}
          />
          <TextField
            id={`ovsConfigs.${index}.lacp`}
            label="Lacp"
            placeholder="Enter value..."
            onChange={(value) => updateField('lacp', index)(value)}
            value={values?.lacp}
          />
        </>
      )}
    </div>
  )
}
