import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import { updateNetworkPlugin } from '../actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Note from 'core/components/validatedForm/Note'
import Text from 'core/elements/Text'
import ExternalLink from 'core/components/ExternalLink'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { enableSriovLink, enableSriovWithDpdkLink } from 'k8s/links'
import NumberedStepLabel from 'core/components/validatedForm/NumberedStepLabel'
import PicklistField from 'core/components/validatedForm/DropdownField'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import ConfigMapPicklist from 'k8s/components/common/ConfigMapPicklist'
import usePluginRouter from 'core/hooks/usePluginRouter'
import { routes } from 'core/utils/routes'
import SimpleLink from 'core/components/SimpleLink'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: 'grid',
    gap: 32,
    padding: '16px 40px 40px 32px',
  },
  buttons: {
    display: 'grid',
    gap: 16,
    gridTemplateColumns: '1fr 1fr',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    background: theme.components.button.secondary.background,
    padding: 8,
  },
  noteContents: {
    display: 'grid',
    gap: 24,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
  text: {
    display: 'grid',
    gap: 8,
  },
  steps: {
    display: 'grid',
    gap: 16,
  },
  step: {
    display: 'grid',
    gap: 16,
    gridTemplateColumns: 'max-content auto',
    alignItems: 'center',
  },
  stepNumber: {
    background: theme.components.badge.unknown.background,
    width: 24,
    height: 24,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fields: {
    display: 'grid',
    gap: 24,
  },
  hugepageFields: {
    display: 'grid',
    gridTemplateColumns: 'auto max-content 120px',
    gap: 8,
  },
  dash: {
    alignSelf: 'end',
    position: 'relative',
    bottom: 12,
  },
  body1: {
    lineHeight: '24px',
  },
}))

const Step = ({ number, label }) => {
  const classes = useStyles()
  return (
    <div className={classes.step}>
      <div className={classes.stepNumber}>
        <Text variant="caption1">{number}</Text>
      </div>
      <Text variant="caption1">{label}</Text>
    </div>
  )
}

const defaultParams = {
  namespace: '',
  configMap: '',
}

export default function EnableSriovModal({ networkPlugin, open = true, onClose }) {
  const classes = useStyles()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtConfiguration' : 'configuration'
  const { params, getParamsUpdater, updateParams, setParams } = useParams<any>(defaultParams)
  const { update: updateFn, updating, error, reset } = useUpdateAction(updateNetworkPlugin)

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  const submitForm = useCallback(async () => {
    const body = {
      ...networkPlugin?.spec?.plugins,
      sriov: {
        namespace: params?.namespace || 'luigi-system',
      },
    }

    const ops = []
    ops.push({
      op: 'replace',
      path: '/spec/plugins',
      value: body,
    })

    const { success } = await updateFn({
      ...networkPlugin,
      body: ops,
      requestType: 'patch',
      contentType: 'application/json-patch+json',
    })
    if (success) {
      handleClose()
    }
  }, [params, networkPlugin, handleClose])

  return (
    <ModalForm
      open={open}
      title={`Enable: SR-IOV`}
      onSubmit={submitForm}
      onClose={onClose}
      submitting={false}
      error={error}
      submitTitle={`Enable`}
      maxWidth={528}
    >
      <div className={classes.container}>
        <Note title="Pre-requisite Worker Nodes Configuration:">
          <div className={classes.noteContents}>
            <Text variant="body2">Follow the steps in the documentation:</Text>
            <div className={classes.buttons}>
              <ExternalLink url={enableSriovLink}>
                <div className={classes.button}>
                  <FontAwesomeIcon>arrow-up-right-from-square</FontAwesomeIcon>
                  <Text variant="caption1">SR-IOV</Text>
                </div>
              </ExternalLink>
              <ExternalLink url={enableSriovWithDpdkLink}>
                <div className={classes.button}>
                  <FontAwesomeIcon>arrow-up-right-from-square</FontAwesomeIcon>
                  <Text variant="caption1">SR-IOV + DPDK</Text>
                </div>
              </ExternalLink>
            </div>
          </div>
        </Note>
        <hr className={classes.divider} />
        <Text variant="body1" className={classes.body1}>
          Config Map <b>sriovdp-config</b> is required to be present on the cluster before enabling
          SR-IOV. Please verify the presence below:
        </Text>
        <NumberedStepLabel step={1} title="Select SR-IOV - Kernel Driver Config Map" />
        <PicklistField
          DropdownComponent={NamespacePicklist}
          id="namespace"
          label="Namespace"
          onChange={(value) => updateParams({ namespace: value })}
          clusterId={networkPlugin?.clusterId}
          value={params?.namespace}
          showAll={false}
          initialNamespace="luigi-system"
          required
        />
        <PicklistField
          DropdownComponent={ConfigMapPicklist}
          id="configMap"
          label="Config Map"
          onChange={(value) => updateParams({ configMap: value })}
          clusterId={networkPlugin?.clusterId}
          namespace="luigi-system"
          value={params?.configMap}
          customFilter={(configMaps) =>
            configMaps?.filter((configMap) => configMap.name === 'sriovdp-config')
          }
          required
        />
        <Text variant="body2">
          If the Config Map is not present, create it and re-initiate this SR-IOV enable flow.
        </Text>
        {/* Need a screen to create a config map first */}
        <SimpleLink
          src={routes[routePath].configMaps.add.path({
            for: 'sriov',
            clusterId: networkPlugin?.clusterId,
            networkPluginId: networkPlugin?.id,
          })}
        >
          Create Config Map
        </SimpleLink>
        <hr className={classes.divider} />
        <div className={classes.text}>
          <Text variant="caption1">SR-IOV Enable Post Steps</Text>
          <Text variant="body2">
            Create the following resources after enabling SR-IOV on this cluster.
          </Text>
        </div>
        <div className={classes.steps}>
          <Step number={1} label="Host Network Template" />
          <Step number={2} label="Network Attachment Definition" />
        </div>
      </div>
    </ModalForm>
  )
}
