import React, { useRef, useEffect } from 'react'
import DocumentMeta from 'core/components/DocumentMeta'
import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import { routes } from 'core/utils/routes'
import { useSelector } from 'react-redux'
import { RootState } from 'app/store'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { pathOr, prop } from 'ramda'
import { CustomerTiers, upgradeLinks } from 'app/constants'
import useReactRouter from 'use-react-router'
import ModalForm from 'core/elements/modal/ModalForm'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Column from 'core/containers/Column'
import Theme from 'core/themes/model'
import { noop } from 'utils/fp'
import {
  createRepository,
  addClustersToRepository,
  addRepositoryAndConnectClusters,
} from '../new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import useToggler from 'core/hooks/useToggler'
import PicklistField from 'core/components/validatedForm/DropdownField'
import generateTestId from 'utils/test-helpers'
import MultiClusterSelectionDropdown from 'k8s/components/common/MultiClusterSelectionDropdown'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import Alert from 'core/components/Alert'
import ExternalLink from 'core/components/ExternalLink'
import { trackUpgrade } from 'utils/tracking'

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
  },
  title: {
    color: theme?.components?.typography?.default,
  },
  textField: {
    minWidth: '40%',
  },
  repoTypes: {
    display: 'grid',
    gridTemplateColumns: '50px 50px',
    marginTop: theme.spacing(1),
  },
  repoTypeText: {
    marginTop: theme.spacing(2),
  },
  label: {
    ...theme.typography.body2,
  },
  clusters: {
    display: 'grid',
    gap: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}))

const customerTierBlacklist = []

export default function AddRepositoryFormModal() {
  const classes = useStyles()
  const { history } = useReactRouter()
  const formFieldSetter = useRef(null)
  const [isPrivateRepo, toggleIsPrivateRepo] = useToggler()
  const { update: addRepo, updating: addingRepo, error: errorAddingRepo } = useUpdateAction(
    createRepository,
  )
  const {
    update: connectClustersToRepo,
    updating: connectingClustersToRepo,
    error: errorConnectingClusters,
  } = useUpdateAction(addClustersToRepository)
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { username, features } = session
  const customerTier = pathOr(CustomerTiers.Freedom, ['customer_tier'], features)
  const disableClusterSelection = customerTierBlacklist.includes(customerTier)

  const setUpFormFieldSetter = (setField) => {
    formFieldSetter.current = { setField }
  }

  useEffect(() => {
    if (!disableClusterSelection) return
    formFieldSetter?.current?.setField('attachAllClusters')(true)
  }, [disableClusterSelection])

  const handleSubmit = async (values) => {
    const { name, url, username, password, clusterIds, autoAttach } = values
    const { success } = await addRepositoryAndConnectClusters({
      name,
      url,
      username,
      password,
      clusterIds,
      autoAttach,
      addRepoFn: addRepo,
      addClustersToRepoFn: connectClustersToRepo,
    })
    success && history.push(routes.repositories.list.path())
  }

  return (
    <>
      <DocumentMeta title="Add Repository" />
      <ModalForm
        route={routes.repositories.add}
        title="Add New Helm Repository"
        onBackdropClick={noop}
        onClose={() => history.push(routes.repositories.list.path())}
        onSubmit={handleSubmit}
        submitTitle="Add New Repository"
        submitting={addingRepo || connectingClustersToRepo}
        fieldSetter={setUpFormFieldSetter}
        error={errorAddingRepo || errorConnectingClusters}
        clearOnSubmit
      >
        {({ setFieldValue, values }) => {
          return (
            <>
              <div className={classes.validatedFormContainer}>
                <FormFieldSection step={1} title="Set access level">
                  <ToggleSwitch
                    active={isPrivateRepo}
                    onClick={toggleIsPrivateRepo}
                    label="Private Repository"
                  />
                </FormFieldSection>
                <FormFieldSection step={2} title="Configure Settings">
                  <Column>
                    <TextField
                      id="name"
                      label="Repository Name"
                      className={classes.textField}
                      onChange={setFieldValue('repositoryName')}
                      required
                    />
                    <TextField
                      id="url"
                      label="Repository URL"
                      className={classes.textField}
                      onChange={setFieldValue('repositoryUrl')}
                      required
                    />
                    {isPrivateRepo && (
                      <Column>
                        <TextField
                          id="username"
                          label="Username"
                          className={classes.textField}
                          onChange={setFieldValue('username')}
                          required
                        />
                        <TextField
                          id="password"
                          label="Password"
                          className={classes.textField}
                          onChange={setFieldValue('password')}
                          type="password"
                          required
                        />
                      </Column>
                    )}
                    <div className={classes.clusters}>
                      <PicklistField
                        DropdownComponent={MultiClusterSelectionDropdown}
                        id="clusterIds"
                        data-testid={generateTestId('clusterIds', 'dropdown')}
                        label="Assign Clusters"
                        onChange={setFieldValue('clusterIds')}
                        value={values.clusterIds}
                        enableSearch
                        disabled={disableClusterSelection || values.autoAttach}
                      />
                      {disableClusterSelection && (
                        <Alert>
                          <Text variant="body2">
                            Assigning clusters is only available to the Enterprise customers. To
                            enable the option,{' '}
                            <ExternalLink
                              url={upgradeLinks[customerTier]}
                              onClick={() => trackUpgrade({ username, customerTier })}
                            >
                              Upgrade Now
                            </ExternalLink>
                          </Text>
                        </Alert>
                      )}
                      <CheckboxField
                        id="autoAttach"
                        value={values.autoAttach}
                        label="Auto-attach to all new clusters"
                        onChange={setFieldValue('autoAttach')}
                      />
                      {values.autoAttach && (
                        <Alert
                          variant="warning"
                          title="Warning"
                          message="Attaching all clusters can take a few minutes. You may not be able to see all the connected clusters immediately"
                        />
                      )}
                    </div>
                  </Column>
                </FormFieldSection>
              </div>
            </>
          )
        }}
      </ModalForm>
    </>
  )
}
