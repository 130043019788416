import { createSelector } from '@reduxjs/toolkit'
import { pipe, assocPath } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { IAppsSelector, IAppsAvailableToClusterSelector } from './models'
import { durationBetweenDates } from 'utils/misc'
import { getRepositoryName } from '../helpers'
import { emptyObj } from 'utils/fp'

export const appsSelector = createSharedSelector(
  getDataSelector<DataKeys.Apps>(DataKeys.Apps),
  (rawApps): IAppsSelector[] => {
    return rawApps.map((app) => {
      return {
        ...app,
        id: app.Name,
        name: app.Chart?.name,
        repository: getRepositoryName(app.Name),
        version: app.Chart?.version,
        description: app.Chart?.description,
        apiVersion: app.Chart?.apiVersion,
        appVersion: app.Chart?.appVersion,
        icon: app.Chart?.icon,
        home: app.Chart?.home,
        age: durationBetweenDates({ labels: ['d'] })(app.Chart?.created),
      }
    })
  },
)

export const makeAppsSelector = (
  defaultParams = {
    orderBy: 'name',
    orderDirection: 'asc',
  } as SortConfig,
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(appsSelector, selectParams, (clusters, params) => {
    const { orderBy, orderDirection } = params
    return pipe(createSorter({ orderBy, orderDirection }))(clusters)
  })
}

export const appDetailsSelector = createSharedSelector(
  getDataSelector<DataKeys.AppDetails>(DataKeys.AppDetails, ['name', 'repository']),
  (appDetails) => {
    return appDetails.map((appDetail) => {
      return {
        ...appDetail,
        version: appDetail?.metadata?.version,
        info: appDetail?.metadata?.home,
      }
    })
  },
)

export const appsAvailableToClusterSelector = createSharedSelector(
  getDataSelector<DataKeys.AppsAvailableToCluster>(DataKeys.AppsAvailableToCluster, ['clusterId']),
  (rawApps): IAppsAvailableToClusterSelector[] => {
    return rawApps.map((app) => {
      return {
        ...app,
        repository: getRepositoryName(app.Name),
      }
    })
  },
)

type AvailableAppsByRepositorySelectorModel = {
  [clusterId: string]: IAppsAvailableToClusterSelector[]
}

export const availableAppsByRepositorySelector = createSharedSelector(
  appsAvailableToClusterSelector,
  (allApps): AvailableAppsByRepositorySelectorModel =>
    allApps.reduce((accum, app) => {
      const { repository } = app
      const existingApps = accum[repository] || []
      const apps = [...existingApps, app]
      return assocPath([repository], apps, accum)
    }, emptyObj),
)
