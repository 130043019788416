import React from 'react'
import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import ListTable from 'core/components/listTable/ListTable'
import { findClusterName } from 'k8s/util/helpers'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'

const useStyles = makeStyles<Theme>((theme) => ({
  outlinedButton: {
    background: theme.palette.grey['000'],
    color: theme.palette.blue[500],
  },
  dialogContainer: {
    padding: theme.spacing(1, 3),
  },
  dialogHeader: {
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogHeaderText: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: theme.spacing(1),
    alignItems: 'center',
  },
  dialogContent: {
    margin: theme.spacing(3, 2),
  },
  closeIcon: {
    color: theme.palette.blue[500],
    cursor: 'pointer',
  },
  role: {
    display: 'flex',
  },
  roleValue: {
    marginLeft: theme.spacing(7),
  },
  details: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: 16,
  },
  gridColumn: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr',
  },
  label: {
    marginRight: theme.spacing(7),
  },
}))

interface Props {
  clusterRoleBinding: any
  onClose: () => void
}

const columns = [
  { id: 'kind', label: 'Kind' },
  { id: 'name', label: 'Name' },
]

const ClusterRoleBindingAnalysisDialog = ({ clusterRoleBinding, onClose }: Props) => {
  const classes = useStyles({})

  useListAction(listClusters)
  const clusters = useSelector(clustersSelector)
  const clusterName = findClusterName(clusters, clusterRoleBinding.clusterId)

  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose} className={classes.dialog}>
      <div className={classes.dialogContainer}>
        <div className={classes.dialogHeader}>
          <div className={classes.dialogHeaderText}>
            <Text variant="body1">Policy Details:</Text>
            <Text variant="subtitle2">{clusterRoleBinding.name}</Text>
          </div>
          <FontAwesomeIcon className={classes.closeIcon} size="xl" onClick={onClose}>
            times
          </FontAwesomeIcon>
        </div>
        <div className={classes.details}>
          <div className={classes.gridColumn}>
            <Text variant="body1" className={classes.label}>
              Cluster Name:
            </Text>
            <Text variant="body1">{clusterName}</Text>
          </div>
        </div>
        <div>
          <div className={classes.dialogHeader}>
            <Text variant="body1">Subjects</Text>
          </div>
          <div className={classes.dialogContent}>
            <ListTable
              showCheckboxes={false}
              searchTargets={['name', 'kind']}
              columns={columns}
              data={clusterRoleBinding.newValue.subjects}
              rowsPerPage={10}
              uniqueIdentifier="name"
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ClusterRoleBindingAnalysisDialog
