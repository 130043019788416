import React from 'react'
import Picklist from 'core/elements/dropdown/AsyncDropdown'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { cloudProviderDetailsSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import { loadCloudProviderDetails } from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'

interface CloudProviderRegionPicklistProps extends Omit<DropdownProps<string>, 'items'> {
  cloudProviderId?: string
  type?: CloudProviders
  id?: string
}

export default function CloudProviderRegionPicklist({
  cloudProviderId,
  type,
  ...rest
}: CloudProviderRegionPicklistProps) {
  const { loading } = useListAction(loadCloudProviderDetails, {
    params: { cloudProviderId },
    requiredParams: ['cloudProviderId'],
  })
  const details = useSelectorWithParams(cloudProviderDetailsSelector, { cloudProviderId })

  const displayField = cloudProviderId && type === CloudProviders.Aws ? 'RegionName' : 'DisplayName'
  const options = details.map((detail) => ({
    label: detail[displayField],
    value: detail.RegionName,
  }))

  return <Picklist {...rest} loading={loading} items={options} />
}
