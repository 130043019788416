import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { ILimitRangeSelector, LimitObj } from '../model'
import Progress from 'core/components/progress/Progress'
import SummaryCard from 'k8s/components/common/entity/summary-card'
import InfoCard from 'k8s/components/common/entity/info-card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import { renderAge } from 'k8s/components/common/entity/helpers'
import Card from 'core/elements/card'
import { OwnerReferences } from '../../config-maps/details/ConfigMapsOverview'
import Grid, { GridViewColumn } from 'core/elements/grid/Grid'

const summaryRows: { key: keyof ILimitRangeSelector; label: string }[] = [
  { key: 'clusterName', label: 'Cluster' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'age', label: 'Age' },
]

const limitDetailsColumns: GridViewColumn<LimitObj>[] = [
  { key: 'type', label: 'Type', width: 'medium' },
  { key: 'resource', label: 'Resource' },
  { key: 'min', label: 'Min' },
  { key: 'max', label: 'Max' },
  { key: 'defaultRequest', label: 'Default Request' },
  { key: 'default', label: 'Default Limit' },
  { key: 'maxLimitRequestRatio', label: 'Max Limit / Request Ratio' },
]

const LimitRangesOverview = ({
  limitRange,
  loading,
  reload,
}: {
  limitRange: ILimitRangeSelector
  loading: boolean
  reload: (refetch: boolean) => void
}) => {
  const classes = useStyles()

  const metadataFields = [
    {
      id: 'age',
      title: 'Created',
      required: true,
      render: renderAge,
    },
    {
      id: 'ownerReferences',
      title: 'Owner References',
      render: (value) => <OwnerReferences refs={value} />,
      condition: ({ ownerReferences }) => ownerReferences?.length > 0,
    },
  ]

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, limitRange)
  }, [limitRange])

  return (
    <Progress loading={loading}>
      <div className={classes.container}>
        <div className={classes.column}>
          <SummaryCard<ILimitRangeSelector>
            title={limitRange?.name}
            subtitle="Limit Range"
            rows={summaryRows}
            data={limitRange}
          />
          <InfoCard items={metadata} title="Properties" />
        </div>
        <div className={classes.column}>
          <Card>
            <LabelsAndAnnotationsSection entity={limitRange} resourceType="limitRange" />
          </Card>
        </div>
        <div className={classes.columnBoth}>
          <Card title="Limit Details" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={limitRange?.limitsObj}
              columns={limitDetailsColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutosummaryRows: 'max-content',
  },
  columnBoth: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutosummaryRows: 'max-content',
    gridColumn: 'span 2',
  },
  footer: {
    margin: theme.spacing(1),
  },
  copyClipboard: {
    '& .copy': {
      color: theme.components.typography.passive,
    },
  },
}))

export default LimitRangesOverview
