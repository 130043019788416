import { clientActions } from 'core/client/clientReducers'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import { listStatefulSets, updateStatefulSet } from './actions'
import { listPods } from 'k8s/components/pods/new-actions'
import useListAction from 'core/hooks/useListAction'
import { routes } from 'core/utils/routes'
import { IStatefulSetDetailsPageTabs, IStatefulSetSelector } from './model'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { statefulSetSelector } from './selectors'
import StatefulSetOverview from './overview'
import CardsHeader from '../common/entity/cards-header'
import ReplicasCard from '../common/entity/replicas-card'
import PodsStatusCard from '../common/entity/pods-status-card'
import EntityPodsPage from '../common/entity/entity-pods-page'
import EntityEventsPage from '../common/entity/entity-events-page'
import EntityLogsPage from '../common/entity/entity-logs-page'
import EntityYamlPage from '../common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import { pathStrOr } from 'utils/fp'
import jsYaml from 'js-yaml'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { isEmpty } from 'ramda'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'

const { qbert } = ApiClient.getInstance()

const defaultStatefulSet = {
  volumes: [],
} as IStatefulSetSelector

const StatefulSetOverviewPage = () => {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { globalParams: params } = useGlobalParams(useParams, {})
  const { loading, reload } = useListAction(listStatefulSets, {
    params,
  })
  const statefulSets = useSelectorWithParams(statefulSetSelector, {
    clusterId,
    useGlobalParams: false,
  })
  const statefulSet = useMemo(
    () => statefulSets.find((statefulSet) => statefulSet.id === id) || defaultStatefulSet,
    [id, statefulSets],
  )

  const { update, updating } = useUpdateAction(updateStatefulSet)

  const { loading: loadingPods, reload: reloadPods } = useListAction(listPods, { params })

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: statefulSet?.clusterName || clusterId,
        id: statefulSet?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [statefulSet?.clusterName, statefulSet?.name, id, clusterId])

  const getStatefulSetYaml = useCallback(async () => {
    if (isEmpty(statefulSet)) return undefined
    return qbert.getStatefulSet(statefulSet.clusterId, statefulSet.namespace, statefulSet.name)
  }, [statefulSet])

  const handleStatefulSetUpdate = useCallback(
    async (yaml) => {
      const body = jsYaml.load(yaml)
      const namespace = pathStrOr('', 'metadata.namespace', body)
      const name = pathStrOr('', 'metadata.name', body)
      await update({ clusterId, namespace, name, id: statefulSet?.id, body })
    },
    [clusterId, statefulSet?.id],
  )

  return (
    <>
      <DocumentMeta title="Stateful Set Overview" breadcrumbs />
      <CardsHeader>
        <PodsStatusCard pods={statefulSet?.pods} />
        <ReplicasCard
          numeratorValue={statefulSet?.replicas?.desired}
          denomValue={statefulSet?.replicas?.total}
        />
      </CardsHeader>
      <Tabs route={routes.statefulSets.details}>
        <Tab label="Overview" value={IStatefulSetDetailsPageTabs.Overview}>
          <StatefulSetOverview statefulSet={statefulSet} loading={loading} />
        </Tab>
        <Tab label="Pods" value={IStatefulSetDetailsPageTabs.Pods}>
          <EntityPodsPage
            pods={statefulSet?.pods}
            loading={loading || loadingPods}
            reload={reloadPods}
          />
        </Tab>
        <Tab label="Events" value={IStatefulSetDetailsPageTabs.Events}>
          <EntityEventsPage entity={statefulSet} loading={loading} />
        </Tab>
        <Tab label="Logs" value={IStatefulSetDetailsPageTabs.Logs}>
          <EntityLogsPage pods={statefulSet?.pods} loading={loading} />
        </Tab>
        <Tab label="YAML" value={IStatefulSetDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Stateful Set"
            entityName={statefulSet?.name}
            getYamlFn={getStatefulSetYaml}
            handleUpdate={handleStatefulSetUpdate}
            loading={updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default StatefulSetOverviewPage
