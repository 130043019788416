export enum INetworkAttachmentDefinitionsDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface NetworkAttachmentDefinitionsResponse {
  apiVersion: string
  items: NetworksItem[]
  kind: string
  metadata: NetworkAttachmentDefinitionsResponseMetadata
}

export interface NetworksItem {
  apiVersion: string
  kind: string
  metadata: ItemMetadata
  spec: Spec
  clusterId: string // Added by convertResource
  name: string
  namespace: string
  created: string
}

interface ItemMetadata {
  annotations: Record<string, string>
  creationTimestamp: string
  generation: number
  name: string
  namespace: string
  resourceVersion: string
  uid: string
}

export interface Spec {
  config: string
}

interface NetworkAttachmentDefinitionsResponseMetadata {
  continue: string
  resourceVersion: string
}

export interface INetworkAttachmentDefinitionsSelector extends NetworksItem {
  id: string
  clusterName: string
  type: string
  ipRange: string
  ipamType: string
}
