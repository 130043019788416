import React from 'react'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'

interface StorageTypePicklistProps extends Omit<DropdownProps<string>, 'items'> {
  formField?: boolean
}

const defaultOptions = [
  { label: 'gp2', value: 'gp2' },
  { label: 'io1', value: 'io1' },
  { label: 'sc1', value: 'sc1' },
  { label: 'st1', value: 'st1' },
]

export default function StorageTypePicklist({
  name = 'storageType',
  label = 'Storage Type',
  formField = false,
  ...props
}: StorageTypePicklistProps) {
  return (
    <AsyncDropdown
      {...props}
      name={name}
      label={label}
      formField={formField}
      items={defaultOptions}
    />
  )
}
