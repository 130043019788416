import React, { useMemo, useState } from 'react'
import { OwnerReference } from 'k8s/components/config-maps/models'
import ModalForm from 'core/elements/modal/ModalForm'
import { makeStyles } from '@material-ui/styles'
import { getFieldsForCard } from 'core/components/InfoPanel'
import { renderDetailRow } from 'k8s/components/common/entity/info-card'

const fields = [
  {
    id: 'name',
    title: 'Name',
    required: true,
  },
  {
    id: 'kind',
    title: 'Kind',
  },
  {
    id: 'apiVersion',
    title: 'API Version',
  },
  {
    id: 'controller',
    title: 'Controller',
    render: (value) => (value ? 'True' : 'False'),
  },
  {
    id: 'uid',
    title: 'UID',
  },
]

export default function ConfigMapsOwnerRefInfoModal({ ownerRef, open = false, onClose }) {
  if (!open || !ownerRef) return null
  const classes = useStyles()

  const items = useMemo(() => {
    return getFieldsForCard(fields, ownerRef)
  }, [ownerRef, open])

  console.log(ownerRef, items)

  return (
    <ModalForm open={open} title={`Owner Ref (${ownerRef?.name})`} onClose={onClose}>
      <div className={classes.container}>{renderDetailRow(items)}</div>
    </ModalForm>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    borderSpacing: '20px',
    position: 'relative',
  },
}))
