import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { routes } from 'core/utils/routes'
import { deleteLimitRange } from './actions'
import usePluginRouter from 'core/hooks/usePluginRouter'

export default function DeleteLimitRangeDialog({ rows: [limitRange], onClose }) {
  const { history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtConfiguration' : 'configuration'
  const { update: deleteFn, updating: deletingLimitRange } = useUpdateAction(deleteLimitRange)

  const handleDelete = useCallback(async () => {
    await deleteFn(limitRange)
    onClose(true)
    history.push(routes[routePath].limitRanges.list.path())
  }, [limitRange, routePath])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={`Remove Limit Range "${limitRange?.name}" ?`}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingLimitRange}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingLimitRange}>
            Delete
          </Button>
        </>
      }
    >
      <Text variant="body2">Your data will be lost since this action cannot be undone.</Text>
    </Modal>
  )
}
