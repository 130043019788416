import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { findCluster } from 'k8s/util/helpers'
import { durationBetweenDates } from 'utils/misc'
import { INetworkPolicySelector } from './model'

export const networkPoliciesSelector = createSharedSelector(
  getDataSelector<DataKeys.NetworkPolicies>(
    DataKeys.NetworkPolicies,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  (rawNetworkPolicies, allClusters): INetworkPolicySelector[] => {
    return rawNetworkPolicies.map((rawNetworkPolicy) => {
      const { clusterId, metadata, spec } = rawNetworkPolicy
      const { name: clusterName, clusterType: clusterType } = findCluster(allClusters, clusterId)
      return {
        ...rawNetworkPolicy,
        numberOfEgressRules: spec?.egress ? spec?.egress.length : 0,
        numberOfIngressRules: spec?.ingress ? spec?.ingress.length : 0,
        clusterName,
        clusterId,
        clusterType,
        age: durationBetweenDates({ labels: ['d'] })(metadata?.creationTimestamp),
        ownerReferences: metadata?.ownerReferences,
        creationTimestamp: metadata?.creationTimestamp,
        labels: metadata?.labels,
        annotations: metadata?.annotations,
      }
    })
  },
)
