import React from 'react'
import TextField from 'core/components/validatedForm/TextField'
import { ipv4CidrValidator } from 'app/plugins/infrastructure/components/clusters/form-components/validators'
import { validIpv4Validator } from 'core/utils/fieldValidators'

export default function WhereaboutsFields({ params, updateParams }) {
  const datastore = params?.config?.type === 'ovs' || params?.config?.type === 'userspace'
  const gateway =
    params?.config?.type === 'ipvlan' ||
    params?.config?.type === 'ipvlan' ||
    params?.config?.type === 'sriov'

  return (
    <>
      <TextField
        id="range"
        label="Range (e.g. 192.168.60.0/24)"
        placeholder="192.168.60.0/24"
        value={params?.config?.ipam?.range}
        onChange={(value) =>
          updateParams({
            config: { ...params?.config, ipam: { ...params?.config?.ipam, range: value } },
          })
        }
        required
        validations={[ipv4CidrValidator]}
      />
      <TextField
        id="rangeStart"
        label="Range Start (e.g. 192.168.60.20)"
        placeholder="192.168.60.20"
        value={params?.config?.ipam?.range_start}
        onChange={(value) =>
          updateParams({
            config: {
              ...params?.config,
              ipam: { ...params?.config?.ipam, range_start: value },
            },
          })
        }
        validations={[validIpv4Validator]}
      />
      <TextField
        id="rangeEnd"
        label="Range End (e.g. 192.168.60.50)"
        placeholder="192.168.60.50"
        value={params?.config?.ipam?.range_end}
        onChange={(value) =>
          updateParams({
            config: {
              ...params?.config,
              ipam: { ...params?.config?.ipam, range_end: value },
            },
          })
        }
        validations={[validIpv4Validator]}
      />
      {datastore && (
        <TextField
          id="datastore"
          label="datastore"
          value={params?.config?.ipam?.datastore}
          onChange={(value) =>
            updateParams({
              config: {
                ...params?.config,
                ipam: { ...params?.config?.ipam, datastore: value },
              },
            })
          }
        />
      )}
      {gateway && (
        <TextField
          id="gateway"
          label="Gateway (e.g. 192.168.60.1)"
          placeholder="192.168.60.1"
          value={params?.config?.ipam?.gateway}
          onChange={(value) =>
            updateParams({
              config: { ...params?.config, ipam: { ...params?.config?.ipam, gateway: value } },
            })
          }
          validations={[validIpv4Validator]}
        />
      )}
      <TextField
        id="log_file"
        label="Log File"
        info="File path for IPAM log (e.g. /tmp/whereabouts.log)"
        value={params?.config?.ipam?.log_file}
        onChange={(value) =>
          updateParams({
            config: { ...params?.config, ipam: { ...params?.config?.ipam, log_file: value } },
          })
        }
      />
      <TextField
        id="log_level"
        label="Log Level"
        info="Logging level (e.g. 'debug', 'error', or 'panic')"
        value={params?.config?.ipam?.log_level}
        onChange={(value) =>
          updateParams({
            config: {
              ...params?.config,
              ipam: { ...params?.config?.ipam, log_level: value },
            },
          })
        }
      />
    </>
  )
}
