import React from 'react'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DateCell from 'core/components/listTable/cells/DateCell'
import DataKeys from 'k8s/DataKeys'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import { clusterRolesSelector } from './selectors'
import { ArrayElement } from 'core/actions/Action'
import { listClusterRoles, deleteClusterRole } from './new-actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useListAction from 'core/hooks/useListAction'
import ListContainer from 'core/containers/ListContainer'
import AddClusterRolePage from 'k8s/components/rbac/AddClusterRolePage'
import UpdateClusterRolePage from 'k8s/components/rbac/UpdateClusterRolePage'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'
import usePluginRouter from 'core/hooks/usePluginRouter'

type ModelDataKey = DataKeys.ClusterRoles
type SelectorModel = ArrayElement<ReturnType<typeof clusterRolesSelector>>
type ActionParams = InferActionParams<typeof listClusterRoles>

type Params = ActionParams & {
  clusterId?: string
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'ClusterRoles',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'name', label: 'Name' },
  { key: 'clusterName', label: 'Cluster' },
  { key: 'created', label: 'Created', render: (value) => <DateCell value={value} /> },
]

export default function ClusterRolesListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { currentPluginId } = usePluginRouter()
  const { message, loading, reload } = useListAction(listClusterRoles, {
    params,
    requiredParams,
  })
  const data = useAppSelector(clusterRolesSelector)

  return (
    <>
      <DocumentMeta title="Cluster Roles" />
      <AddClusterRolePage />
      <UpdateClusterRolePage />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.ClusterRoles}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={data}
        getParamsUpdater={getParamsUpdater}
        addUrl={routes.rbac.addClusterRoles.path({ plugin: currentPluginId })}
        addText="Add Cluster Role"
        editUrl={(item, id) =>
          routes.rbac.editClusterRoles.path({
            id,
            clusterId: item.clusterId,
            plugin: currentPluginId,
          })
        }
        deleteAction={deleteClusterRole}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
