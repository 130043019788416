import React from 'react'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'

interface ReplicationTypePicklistProps extends Omit<DropdownProps<string>, 'items'> {
  formField?: boolean
}

const defaultOptions = [
  { label: 'none', value: 'none' },
  { label: 'regional-pd', value: 'regional-pd' },
]

export default function ReplicationTypePicklist({
  name = 'storageType',
  label = 'Storage Type',
  formField = false,
  ...props
}: ReplicationTypePicklistProps) {
  return (
    <AsyncDropdown
      {...props}
      name={name}
      label={label}
      formField={formField}
      items={defaultOptions}
    />
  )
}
