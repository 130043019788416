import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import useReactRouter from 'use-react-router'
import useListAction from 'core/hooks/useListAction'
import { listNetworkPolicies, updateNetworkPolicy } from '../actions'
import { INetworkPolicyDetailsPageTabs, INetworkPolicySelector } from '../model'
import { networkPoliciesSelector } from '../selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import { routes } from 'core/utils/routes'
import NetworkPoliciesOverview from './NetworkPoliciesOverview'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import { isEmpty } from 'ramda'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import OverviewActions from 'core/elements/overview/OverviewActions'
import DeleteNetworkPolicyDialog from '../DeleteNetworkPolicyDialog'
import usePluginRouter from 'core/hooks/usePluginRouter'

const { qbert } = ApiClient.getInstance()

const actions = {
  rightActions: [
    {
      label: 'Delete',
      icon: 'trash-alt',
      DialogComponent: DeleteNetworkPolicyDialog,
    },
  ],
}

export default function NetworkPoliciesDetailsPage() {
  const dispatch = useDispatch()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { loading, reload } = useListAction(listNetworkPolicies, { params: { clusterId } })
  const networkPolicies: INetworkPolicySelector[] = useSelector(networkPoliciesSelector)
  const networkPolicy = useMemo(
    () => networkPolicies.find((networkPolicy) => networkPolicy.id === id),
    [id, networkPolicies],
  )

  const { update, updating } = useUpdateAction(updateNetworkPolicy)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: networkPolicy?.clusterName || clusterId,
        id: networkPolicy?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [networkPolicy?.clusterName, networkPolicy?.name, id, clusterId])

  const getNetworkPolicyYaml = useCallback(async () => {
    if (isEmpty(networkPolicy)) return undefined
    return qbert.getNetworkPolicy(
      networkPolicy.clusterId,
      networkPolicy.namespace,
      networkPolicy.name,
    )
  }, [networkPolicy])

  const handleNetworkPolicyUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Network Policies Overview" breadcrumbs />
      <div>
        <OverviewActions actions={actions} entity={networkPolicy} />
      </div>
      <Tabs route={routes[routePath].networkPolicies.details}>
        <Tab label="Overview" value={INetworkPolicyDetailsPageTabs.Overview}>
          <NetworkPoliciesOverview
            networkPolicy={networkPolicy}
            loading={loading}
            reload={reload}
          />
        </Tab>
        <Tab label="YAML" value={INetworkPolicyDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Network Policy"
            entityName={networkPolicy?.name}
            getYamlFn={getNetworkPolicyYaml}
            handleUpdate={handleNetworkPolicyUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}
