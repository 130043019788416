import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import useReactRouter from 'use-react-router'
import useListAction from 'core/hooks/useListAction'
import { listHorizontalPodAutoscalers, updateHorizontalPodAutoscaler } from '../actions'
import { IHorizontalPodAutoscalerDetailsPageTabs, IHorizontalPodAutoscalerSelector } from '../model'
import { horizontalPodAutoscalersSelector } from '../selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import { routes } from 'core/utils/routes'
import HorizontalPodAutoscalersOverview from './HorizontalPodAutoscalersOverview'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import { isEmpty } from 'ramda'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import OverviewActions from 'core/elements/overview/OverviewActions'
import DeleteHorizontalPodAutoscalerDialog from '../DeleteHorizontalPodAutoscalerDialog'
import usePluginRouter from 'core/hooks/usePluginRouter'

const { qbert } = ApiClient.getInstance()

const actions = {
  rightActions: [
    {
      label: 'Delete',
      icon: 'trash-alt',
      DialogComponent: DeleteHorizontalPodAutoscalerDialog,
    },
  ],
}

export default function HorizontalPodAutoscalersDetailsPage() {
  const dispatch = useDispatch()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtConfiguration' : 'configuration'
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { loading, reload } = useListAction(listHorizontalPodAutoscalers, { params: { clusterId } })
  const horizontalPodAutoscalers: IHorizontalPodAutoscalerSelector[] = useSelector(
    horizontalPodAutoscalersSelector,
  )
  const horizontalPodAutoscaler = useMemo(
    () =>
      horizontalPodAutoscalers.find((horizontalPodAutoscaler) => horizontalPodAutoscaler.id === id),
    [id, horizontalPodAutoscalers],
  )

  const { update, updating } = useUpdateAction(updateHorizontalPodAutoscaler)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: horizontalPodAutoscaler?.clusterName || clusterId,
        id: horizontalPodAutoscaler?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [horizontalPodAutoscaler?.clusterName, horizontalPodAutoscaler?.name, id, clusterId])

  const getHorizontalPodAutoscalerYaml = useCallback(async () => {
    if (isEmpty(horizontalPodAutoscaler)) return undefined
    return qbert.getHorizontalPodAutoscaler(
      horizontalPodAutoscaler.clusterId,
      horizontalPodAutoscaler.namespace,
      horizontalPodAutoscaler.name,
    )
  }, [horizontalPodAutoscaler])

  const handleHorizontalPodAutoscalerUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Horizontal Pod Autoscalers Overview" breadcrumbs />
      <div>
        <OverviewActions actions={actions} entity={horizontalPodAutoscaler} />
      </div>
      <Tabs route={routes[routePath].horizontalPodAutoscalers.details}>
        <Tab label="Overview" value={IHorizontalPodAutoscalerDetailsPageTabs.Overview}>
          <HorizontalPodAutoscalersOverview
            horizontalPodAutoscaler={horizontalPodAutoscaler}
            loading={loading}
            reload={reload}
          />
        </Tab>
        <Tab label="YAML" value={IHorizontalPodAutoscalerDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Horizontal Pod Autoscalers"
            entityName={horizontalPodAutoscaler?.name}
            getYamlFn={getHorizontalPodAutoscalerYaml}
            handleUpdate={handleHorizontalPodAutoscalerUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}
