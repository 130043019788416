import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ListTable from 'core/components/listTable/ListTable'
import { filterByOperation, numApiResources, parseRoleRules, renderOp } from './helpers'
import ClusterRoleAnalysisDialog from './ClusterRoleAnalysisDialog'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import DriftResourceSummary from './DriftResourceSummary'
import { allKey } from 'app/constants'
import OperationsPicklist from './OperationsPicklist'

const useStyles = makeStyles((theme: Theme) => ({
  inspectIcon: {
    color: theme.palette.blue[500],
    cursor: 'pointer',
  },
}))

const InspectClusterRoleButton = ({ rbac, clusterRole }) => {
  const classes = useStyles({})
  const [dialogOpened, setDialogOpened] = useState(false)

  return (
    <>
      <FontAwesomeIcon className={classes.inspectIcon} onClick={() => setDialogOpened(true)}>
        search-plus
      </FontAwesomeIcon>
      {dialogOpened && (
        <ClusterRoleAnalysisDialog
          rbac={rbac}
          clusterRole={clusterRole}
          onClose={() => setDialogOpened(false)}
        />
      )}
    </>
  )
}

const renderInspectButton = (rbac, clusterRole) => (
  <InspectClusterRoleButton rbac={rbac} clusterRole={clusterRole} />
)

const columns = [
  { id: 'op', label: '', render: renderOp },
  { id: 'name', label: 'Name' },
  { id: 'numApis', label: 'APIs' },
  { id: 'rbac', label: '', render: renderInspectButton },
]

const ClusterRoleAnalysis = ({ clusterRoles, isImpactAnalysis, clusterId }) => {
  const [operation, setOperation] = useState(allKey)
  const clusterRoleAnalysis = useMemo(
    () =>
      clusterRoles.map((clusterRole) => {
        const rules = parseRoleRules(clusterRole.newValue?.rules)
        return {
          ...clusterRole,
          rbac: rules,
          numApis: numApiResources(rules),
          clusterId,
        }
      }),
    [clusterRoles],
  )
  const filteredClusterRoles = filterByOperation(clusterRoleAnalysis, operation)

  return (
    <>
      <DriftResourceSummary
        resourceType="Cluster Roles"
        resources={clusterRoleAnalysis}
        isImpactAnalysis={isImpactAnalysis}
      />
      <div>
        <ListTable
          filters={
            <OperationsPicklist
              onChange={(value) => setOperation(value)}
              value={operation}
              isImpactAnalysis={isImpactAnalysis}
            />
          }
          showCheckboxes={false}
          searchTargets={['name']}
          columns={columns}
          data={filteredClusterRoles}
          rowsPerPage={10}
          uniqueIdentifier="path"
        />
      </div>
    </>
  )
}

export default ClusterRoleAnalysis
