import React, { ComponentProps } from 'react'
import { projectAs } from 'utils/fp'
import withFormContext from 'core/components/validatedForm/withFormContext'
import MultiSelect from 'core/components/MultiSelect'
import { makeFilteredUsersSelector } from 'account/components/userManagement/users/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listUsers } from 'account/components/userManagement/users/new-actions'

const usersSelector = makeFilteredUsersSelector()

export default withFormContext<
  string[],
  Omit<ComponentProps<typeof MultiSelect>, 'label' | 'options' | 'loading'>
>(function UserMultiSelect(props) {
  const { loading: loadingUsers } = useListAction(listUsers)
  const users = useSelectorWithParams(usersSelector, { orderBy: 'username' })
  const usersList = projectAs({ label: 'username', value: 'username' }, users)
  return <MultiSelect {...props} label="Users" options={usersList} loading={loadingUsers} />
})
