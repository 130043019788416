import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import EntityYamlPage from '../../../common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import { IPersistentVolumeDetailsPageTabs } from '../model'
import { routes } from 'core/utils/routes'
import { isEmpty } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listPersistentVolumes, updatePersistentVolume } from '../new-actions'
import { persistentVolumeSelector } from '../selectors'
import Overview from './Overview'
import DeletePersistentVolumeDialog from '../DeletePersistentVolumeDialog'
import OverviewActions from 'core/elements/overview/OverviewActions'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'

const { qbert } = ApiClient.getInstance()

const actions = {
  rightActions: [
    {
      label: 'Delete',
      icon: 'trash-alt',
      DialogComponent: DeletePersistentVolumeDialog,
    },
  ],
}

export default function PersistentVolumeDetailsPage() {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtStorage' : 'storage'
  const { id, clusterId } = match.params
  const { globalParams: params } = useGlobalParams(useParams, {})
  const { loading } = useListAction(listPersistentVolumes, { params })
  const persistentVolumes = useSelectorWithParams(persistentVolumeSelector, {
    clusterId,
    useGlobalParams: false,
  })
  const persistentVolume = useMemo(() => persistentVolumes.find((plugin) => plugin.id === id), [
    id,
    persistentVolumes,
  ])
  const { update, updating } = useUpdateAction(updatePersistentVolume)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: persistentVolume?.clusterName || clusterId,
        id: persistentVolume?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [persistentVolume?.clusterName, persistentVolume?.name, id, clusterId])

  const getPersistentVolumeYaml = useCallback(async () => {
    if (isEmpty(persistentVolume)) return undefined
    return qbert.getPersistentVolume(persistentVolume.clusterId, persistentVolume.name)
  }, [persistentVolume])

  const handlePersistentVolumeUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Data Volume" breadcrumbs />
      <div>
        <OverviewActions actions={actions} entity={persistentVolume} />
      </div>
      <Tabs route={routes[routePath].persistentVolumes.details}>
        <Tab label="Overview" value={IPersistentVolumeDetailsPageTabs.Overview}>
          <Overview persistentVolume={persistentVolume} loading={loading} />
        </Tab>
        <Tab label="YAML" value={IPersistentVolumeDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Persistent Volume"
            entityName={persistentVolume?.name}
            getYamlFn={getPersistentVolumeYaml}
            handleUpdate={handlePersistentVolumeUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}
