import { isEmpty } from 'ramda'
import { memoize } from 'utils/misc'

export const getPodTemplate = (workload) =>
  workload?.spec?.template || workload?.spec?.jobTemplate?.spec?.template || workload || {}

export const getConfigMapsForResource = memoize((resource, allConfigMaps = []) => {
  if (!resource || isEmpty(allConfigMaps)) return []
  const podTemplate = getPodTemplate(resource)

  const configMapNames = new Set()

  // Get config map names from volumes
  const volumes = podTemplate?.spec?.volumes || []
  volumes.forEach((v) => {
    if (v?.configMap?.name) {
      configMapNames.add(v?.configMap?.name)
    }
  })

  // Get config map names from containers
  const containers = podTemplate?.spec?.containers || []
  containers.forEach((c) => {
    // Search for config map names from env variables
    const envVars = c?.env || []
    envVars.forEach((env) => {
      if (env?.valueFrom?.configMapKeyRef?.name) {
        configMapNames.add(env?.valueFrom?.configMapKeyRef?.name)
      }
    })
    // Search for config map names from env sources
    const envFromSources = c?.envFrom || []
    envFromSources.map((envFrom) => {
      if (envFrom?.configMapRef?.name) {
        configMapNames.add(envFrom?.configMapRef?.name)
      }
    })
  })

  return allConfigMaps?.filter((cm) => configMapNames.has(cm?.metadata?.name))
})
