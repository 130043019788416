import React, { useState } from 'react'
import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { keys } from 'ramda'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { findClusterName } from 'k8s/util/helpers'
import { allKey } from 'app/constants'
import ApiGroupPicklist from './ApiGroupPicklist'
import { filterApiGroups } from './helpers'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'

const useStyles = makeStyles<Theme>((theme) => ({
  outlinedButton: {
    background: theme.palette.grey['000'],
    color: theme.palette.blue[500],
  },
  dialogContainer: {
    padding: theme.spacing(1, 3),
    background: theme.components.card.background,
  },
  dialogHeader: {
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogHeaderText: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: theme.spacing(1),
    alignItems: 'center',
  },
  dialogContent: {
    margin: theme.spacing(3, 2),
  },
  resourcesList: {
    display: 'grid',
    gridGap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  resourceBox: {
    padding: theme.spacing(2, 3),
    background: theme.components.card.activeBackground,
    display: 'flex',
  },
  closeIcon: {
    color: theme.palette.blue[500],
    cursor: 'pointer',
  },
  middleDiv: {
    marginLeft: theme.spacing(1),
    width: 200,
  },
  verb: {
    display: 'inline-block',
    background: theme.palette.blue[200],
    padding: theme.spacing(0.5, 2),
    borderRadius: 8,
    margin: theme.spacing(1),
    position: 'relative',
    top: theme.spacing(-0.5),
  },
  details: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: 16,
  },
  gridColumn: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr',
  },
  filters: {
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  rbac: any
  clusterRole: any
  onClose: () => void
}

const GroupResources = ({ group, rbac }) => {
  const classes = useStyles({})
  const resources = keys(rbac[group])
  return (
    <div className={classes.resourcesList}>
      {resources.map((resource: string) => (
        <div key={`${group}-${resource}`} className={classes.resourceBox}>
          <div>
            <Text variant="body2">API Group:</Text>
            <Text variant="body2">Resources:</Text>
          </div>
          <div className={classes.middleDiv}>
            <Text variant="caption1">{group}</Text>
            <Text variant="caption1">{resource}</Text>
          </div>
          <div>
            {keys(rbac[group][resource]).map((verb: string) => (
              <div key={verb} className={classes.verb}>
                {verb}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

const renderGroupResources = (group, rbac) => (
  <GroupResources key={group} group={group} rbac={rbac} />
)

const ClusterRoleAnalysisDialog = ({ rbac, clusterRole, onClose }: Props) => {
  const classes = useStyles({})
  const [apiGroupFilter, setApiGroupFilter] = useState(allKey)
  const apiGroups = keys(rbac)
  const filteredApiGroups = filterApiGroups(apiGroups, apiGroupFilter)

  useListAction(listClusters)
  const clusters = useSelector(clustersSelector)
  const clusterName = findClusterName(clusters, clusterRole.clusterId)

  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose} className={classes.dialog}>
      <div className={classes.dialogContainer}>
        <div className={classes.dialogHeader}>
          <div className={classes.dialogHeaderText}>
            <Text variant="body1">Policy Details:</Text>
            <Text variant="subtitle2">{clusterRole.name}</Text>
          </div>
          <FontAwesomeIcon className={classes.closeIcon} size="xl" onClick={onClose}>
            times
          </FontAwesomeIcon>
        </div>
        <div className={classes.details}>
          <div className={classes.gridColumn}>
            <Text variant="body1" className={classes.label}>
              Cluster Name:
            </Text>
            <Text variant="body1">{clusterName}</Text>
          </div>
        </div>
        <div className={classes.dialogHeader}>
          <Text variant="body1">API Access/Permissions</Text>
        </div>
        <div className={classes.dialogContent}>
          <div className={classes.filters}>
            <ApiGroupPicklist
              apiGroups={apiGroups}
              onChange={(value) => setApiGroupFilter(value)}
              value={apiGroupFilter}
            />
          </div>
          {filteredApiGroups.map((group) => renderGroupResources(group, rbac))}
        </div>
      </div>
    </Dialog>
  )
}

export default ClusterRoleAnalysisDialog
