import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import AppCard from './apps/components/AppCard'
import useListAction from 'core/hooks/useListAction'
import { listRepositories } from './repositories/new-actions'
import { useSelector } from 'react-redux'
import { repositoriesSelector } from './repositories/selectors'
import { isEmpty } from 'ramda'
import Button from 'core/elements/button'
import SearchBar from 'core/components/SearchBar'
import { filterBySearch } from 'utils/misc'
import Progress from 'core/components/progress/Progress'
import { IAppsSelector, IDeployedAppsSelector } from './apps/models'
import Dropdown from 'core/elements/dropdown'
import NoRepositoriesMessage from './repositories/components/NoRepositoriesMessage'
import DeployedAppCard from './deployed-apps/components/DeployedAppCard'

interface AppCardsGridProps {
  apps: IAppsSelector[] | IDeployedAppsSelector[]
  appType: 'undeployed' | 'deployed'
  loadingApps: boolean
  message: string
  reload: () => void
  searchTargets?: string[]
  filters?: any
}

const sortByOptions = [
  { label: 'A-Z', value: 'asc' },
  { label: 'Z-A', value: 'desc' },
]

export const sortAppsByName = (apps, sortBy = 'asc') => {
  if (isEmpty(apps)) return apps
  const sortedApps = apps.sort((app1, app2) => (app1.name > app2.name ? 1 : -1))
  return sortBy === 'asc' ? sortedApps : sortedApps.reverse()
}

export default function AppCardsGrid({
  apps,
  appType,
  loadingApps,
  message,
  reload,
  searchTargets = ['name', 'repository'],
  filters,
}: AppCardsGridProps) {
  const classes = useStyles()
  const [searchTerm, setSearchTerm] = useState('')
  const [sortBy, setSortBy] = useState('asc')
  const { loading: loadingRepositories } = useListAction(listRepositories)
  const repositories = useSelector(repositoriesSelector)

  const Card = useMemo(() => (appType === 'undeployed' ? AppCard : DeployedAppCard), [appType])

  const filteredApps = useMemo(() => {
    if (isEmpty(apps)) return apps
    let filteredApps = apps

    if (searchTerm) {
      filteredApps = filterBySearch(filteredApps, searchTargets, searchTerm)
    }
    return sortAppsByName(filteredApps, sortBy)
  }, [apps, searchTargets, sortBy])

  const loadingData = loadingApps || loadingRepositories

  const showNoRepositoriesMsg = !loadingData && isEmpty(repositories)

  return (
    <>
      <Progress loading={loadingData} message={message} overlay>
        {!showNoRepositoriesMsg && (
          <div className={classes.appCardsGrid}>
            <div className={classes.filters}>
              <div className={classes.filtersGroup}>
                <Button variant="secondary" icon="rotate" onClick={reload}>
                  Refresh
                </Button>
                <SearchBar
                  searchTerm={searchTerm}
                  onSearchChange={setSearchTerm}
                  className={classes.clusterSearch}
                />
              </div>
              <div className={classes.filtersGroup}>
                {filters && filters}
                <Dropdown
                  name="sortBy"
                  label="Sort"
                  items={sortByOptions}
                  value={sortBy}
                  onChange={setSortBy}
                  compact
                />
              </div>
            </div>
            <div className={classes.cards}>
              {filteredApps.map((app) => (
                <Card key={app.id} app={app} />
              ))}
            </div>
          </div>
        )}
        {showNoRepositoriesMsg && <NoRepositoriesMessage />}
      </Progress>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  appCardsGrid: {
    display: 'grid',
    gap: 32,
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filtersGroup: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: 16,
  },
  clusterSearch: {
    gap: 0,
  },
  cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fill, 368px )',
    gap: '16px',
  },
}))
