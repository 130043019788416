import React, { useCallback, useMemo, useEffect, useState } from 'react'
import CardTable from 'core/components/cardTable/card-table'
import { routes } from 'core/utils/routes'
import useReactRouter from 'use-react-router'
import AppCard from 'k8s/components/app-catalog/apps/components/AppCard'
import useParams from 'core/hooks/useParams'
import Button from 'core/elements/button'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import DocumentMeta from 'core/components/DocumentMeta'
import {
  addRepositoryAndConnectClusters,
  listRepositories,
  addClustersToRepository,
  createRepository,
} from 'k8s/components/app-catalog/repositories/new-actions'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { repositoriesSelector } from 'k8s/components/app-catalog/repositories/selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listApps } from 'k8s/components/app-catalog/apps/new-actions'
import { appsSelector } from 'k8s/components/app-catalog/apps/selectors'
import NoRepositoriesMessage from 'k8s/components/app-catalog/repositories/components/NoRepositoriesMessage'
import Alert from 'core/components/Alert'

const pf9DefaultRepository = {
  name: 'platform9-apps',
  url: 'https://platform9.github.io/helm-charts',
}

const defaultParams = {
  sortBy: 'asc',
}

const sortByOptions = [
  { label: 'A->Z', value: 'asc' },
  { label: 'Z->A', value: 'desc' },
]

const storageAppNames = new Set(['hostpath-csi-driver'])

const StorageProvidersListPage = () => {
  const classes = useStyles()
  const { history } = useReactRouter()
  const { params, getParamsUpdater } = useParams(defaultParams)
  const [storageApps, setStorageApps] = useState([])

  const { loading: loadingClusters } = useListAction(listClusters)
  const clusters = useSelector(clustersSelector)

  const { loading: loadingRepositories } = useListAction(listRepositories)
  const repositories = useSelector(repositoriesSelector)

  const { loading, message, reload } = useListAction(listApps)
  const apps: { name: string; repository: string }[] = useSelector(appsSelector)

  const { update: addRepo, updating: addingRepo, error: errorAddingRepo } = useUpdateAction(
    createRepository,
  )
  const {
    update: addClustersToRepo,
    updating: addingClustersToRepo,
    error: errorAddingClusterToRepo,
  } = useUpdateAction(addClustersToRepository)

  const addingPf9Repo = addingRepo || addingClustersToRepo

  const clusterIds = useMemo(() => {
    return clusters.map((c) => c.uuid)
  }, [clusters])

  const hasPf9Repository = useMemo(() => {
    return !!repositories.find((r) => r.url?.includes(pf9DefaultRepository.url))
  }, [repositories])

  useEffect(() => {
    if (!hasPf9Repository) return
    setStorageApps(
      apps.filter(
        ({ name, repository }) =>
          repository === pf9DefaultRepository.name && storageAppNames.has(name),
      ),
    )
  }, [apps])

  const addPf9Repository = useCallback(async () => {
    const { success } = await addRepositoryAndConnectClusters({
      name: pf9DefaultRepository.name,
      url: pf9DefaultRepository.url,
      clusterIds,
      addRepoFn: addRepo,
      addClustersToRepoFn: addClustersToRepo,
    })
    if (!success) return
  }, [clusterIds, addRepo, addClustersToRepo])

  const handleOnClick = (app) => {
    history.push(routes.apps.deploy.path({ name: app.name, repository: app.repository }))
  }

  const renderStorageProviderCards = useCallback((sp) => <AppCard key={sp.name} app={sp} />, [
    handleOnClick,
  ])

  const handleRefresh = useCallback(() => reload(true), [reload])

  const error = errorAddingRepo || errorAddingClusterToRepo
  return (
    <>
      <DocumentMeta title="Storage Providers" breadcrumbs />
      {!!error && <Alert variant="error" title={error?.title} message={error?.message} />}
      <CardTable
        data={storageApps}
        searchTarget="name"
        showSortOption
        sortOptions={sortByOptions}
        onSortChange={getParamsUpdater('sortBy')}
        sortBy={params.sortBy}
        sortTarget="name"
        loading={loading || addingPf9Repo || loadingClusters || loadingRepositories}
        loadingMessage={addingPf9Repo ? 'Attaching Platform9 repository to all clusters' : message}
        handleRefresh={handleRefresh}
        emptyItemsMessage={
          <NoRepositoriesMessage
            headerMessage={
              'To see the list of storage providers, ' +
              'please attach the Platform9 default repository.'
            }
            secondaryMessage={
              'Click the button below to attach the Platform9 repository to all clusters'
            }
            button={
              <Button className={classes.addPf9RepoButton} onClick={addPf9Repository}>
                Add Platform9 Repository
              </Button>
            }
            showAddRepositoryLink={false}
          />
        }
      >
        {renderStorageProviderCards}
      </CardTable>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  addPf9RepoButton: {
    marginTop: theme.spacing(2),
  },
}))

export default StorageProvidersListPage
