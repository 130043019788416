import React, { useMemo, useCallback } from 'react'
import { emptyObj } from 'utils/fp'
import useReactRouter from 'use-react-router'
import { propEq } from 'ramda'
import RbacChecklist from './RbacChecklist'
import PresetField from 'core/components/PresetField'
import { makeStyles } from '@material-ui/styles'
import DocumentMeta from 'core/components/DocumentMeta'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { routes } from 'core/utils/routes'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listClusterRoles, updateClusterRole } from './new-actions'
import Theme from 'core/themes/model'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { clusterRolesSelector } from './selectors'
import { IRbacClusterRoleSelector } from './model'
import ModalForm from 'core/elements/modal/ModalForm'
import usePluginRouter from 'core/hooks/usePluginRouter'

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: 16,
  },
}))

export default function UpdateClusterRolePage() {
  const classes = useStyles({})
  const { match, history } = useReactRouter()
  const clusterRoleId = match.params.id
  const clusterId = match.params.clusterId
  const { currentPluginId } = usePluginRouter()

  const { loading } = useListAction(listClusterRoles, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const clusterRoles = useSelectorWithParams(clusterRolesSelector, { clusterId })

  const clusterRole = useMemo(
    () => clusterRoles.find(propEq('id', clusterRoleId)) || (emptyObj as IRbacClusterRoleSelector),
    [clusterRoles, clusterRoleId],
  )

  const { update, updating, reset, error: errorUpdatingClusterRole } = useUpdateAction(
    updateClusterRole,
  )

  const submitForm = useCallback(
    async (params) => {
      const { success } = await update({ ...clusterRole, ...params })
      if (success) handleClose()
    },
    [clusterRole],
  )
  const handleClose = () => {
    reset()
    history.push(routes.rbac.clusterRoles.path({ plugin: currentPluginId }))
  }
  return (
    <>
      <DocumentMeta title="Update Cluster Role" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.rbac.editClusterRoles}
        title="Edit Cluster Role"
        onSubmit={submitForm}
        onClose={handleClose}
        loading={loading}
        submitting={updating}
        error={errorUpdatingClusterRole}
        loadingMessage={loading ? 'Loading cluster role...' : 'Submitting form...'}
        submitTitle="Update Cluster Role"
      >
        <>
          <FormFieldSection title="Basic Details" step={1}>
            <PresetField label="Name" value={clusterRole.name} />
            <PresetField label="Cluster" value={clusterRole.clusterName} />
          </FormFieldSection>
          {clusterRole.clusterId && (
            <FormFieldSection title="API Access / Permissions" step={2}>
              <RbacChecklist
                id="rbac"
                clusterId={clusterRole.clusterId}
                initialRules={clusterRole.rules}
              />
            </FormFieldSection>
          )}
        </>
      </ModalForm>
    </>
  )
}
