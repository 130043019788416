import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { trackEvent } from 'utils/tracking'
import {
  IRbacClusterRoleSelector,
  IRbacClusterRoleBindingSelector,
  IRbacProfileDetails,
  IRbacRoleSelector,
  IRbacRoleBindingSelector,
} from 'k8s/components/rbac/model'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import CustomAction from 'core/actions/CustomAction'
import { ClusterSelector } from 'app/plugins/infrastructure/components/clusters/model'

const { qbert } = ApiClient.getInstance()

const uniqueIdentifier = 'metadata.name'

export const loadProfileDetails = async (namespace, name): Promise<IRbacProfileDetails> => {
  return qbert.getRbacProfileDetails(namespace, name)
}

export const rbacProfileBindingsActions = ActionsSet.make<DataKeys.RbacProfileBindings>({
  uniqueIdentifier,
  entityName: entityNamesByKey[DataKeys.RbacProfileBindings],
  cacheKey: DataKeys.RbacProfileBindings,
})

export const listRbacProfileBindings = rbacProfileBindingsActions.add(
  new ListAction<DataKeys.RbacProfileBindings>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get rbac profile bindings')
    return qbert.getRbacProfileBindings()
  }),
)

export const createRbacProfileBinding = rbacProfileBindingsActions.add(
  new CreateAction<DataKeys.RbacProfileBindings, { cluster: ClusterSelector; profileName: string }>(
    async ({ cluster, profileName }) => {
      const clusterId = cluster.uuid
      const body = {
        apiVersion: 'sunpike.platform9.com/v1alpha2',
        kind: 'ClusterProfileBinding',
        metadata: {
          name: `${profileName}-binding-${Date.now()}`,
        },
        spec: {
          clusterRef: clusterId,
          profileRef: `default/${profileName}`,
          dryRun: false,
        },
      }
      Bugsnag.leaveBreadcrumb('Attempting to create rbac profile binding', {
        clusterId,
        profileName,
      })
      trackEvent('Create RBAC Profile Binding', {
        clusterId,
        profileName,
      })
      return await qbert.createRbacProfileBinding(body)
    },
  ),
)

export const deleteRbacProfileBinding = rbacProfileBindingsActions.add(
  new DeleteAction<DataKeys.RbacProfileBindings, { name: string }>(async ({ name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete rbac profile binding')
    trackEvent('Delete RBAC Profile Binding', {
      name,
    })
    return qbert.deleteRbacProfileBinding(name)
  }),
)

export const rbacProfileActions = ActionsSet.make<DataKeys.RbacProfiles>({
  uniqueIdentifier,
  entityName: entityNamesByKey[DataKeys.RbacProfiles],
  cacheKey: DataKeys.RbacProfiles,
})

export const listRbacProfiles = rbacProfileActions.add(
  new ListAction<DataKeys.RbacProfiles>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get rbac profiles')
    return qbert.getRbacProfiles()
  }).addDependency(DataKeys.RbacProfileBindings),
)

export const createRbacProfile = rbacProfileActions.add(
  new CreateAction<
    DataKeys.RbacProfiles,
    {
      profileName: string
      baseCluster: string
      roles: { [key: string]: IRbacRoleSelector }
      clusterRoles: { [key: string]: IRbacClusterRoleSelector }
      roleBindings: { [key: string]: IRbacRoleBindingSelector }
      clusterRoleBindings: { [key: string]: IRbacClusterRoleBindingSelector }
    }
  >(async (data) => {
    const roles = Object.values(data.roles).map(
      ({ name, namespace }) => `${namespace}/roles/${name}`,
    )
    const roleBindings = Object.values(data.roleBindings).map(
      ({ name, namespace }) => `${namespace}/rolebindings/${name}`,
    )
    const clusterRoles = Object.values(data.clusterRoles).map(({ name }) => `clusterroles/${name}`)
    const clusterRoleBindings = Object.values(data.clusterRoleBindings).map(
      ({ name }) => `clusterrolebindings/${name}`,
    )
    const body = {
      apiVersion: 'sunpike.platform9.com/v1alpha2',
      kind: 'ClusterProfile',
      metadata: { name: data.profileName },
      spec: {
        cloneFrom: data.baseCluster,
        namespaceScopedResources: [...roles, ...roleBindings],
        clusterScopedResources: [...clusterRoles, ...clusterRoleBindings],
        reapInterval: 30,
      },
    }
    Bugsnag.leaveBreadcrumb('Attempting to create a rbac profile', body)
    const profile = await qbert.createRbacProfile(body)
    trackEvent('Create Rbac Profile', body)
    return profile
  }),
)

export const deleteRbacProfile = rbacProfileActions.add(
  new DeleteAction<DataKeys.RbacProfiles, { name: string }>(async ({ name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete rbac profile', { name })
    trackEvent('Delete RBAC Profile', {
      name,
    })
    return qbert.deleteRbacProfile(name)
  }),
)

export const updateRbacProfileDetails = rbacProfileActions.add(
  new CustomAction<
    DataKeys.RbacProfiles,
    { name: string; namespace; string; details: IRbacProfileDetails }
  >('updateDetails', async ({ name, namespace, details }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update rbac profile details')
    trackEvent('Update RBAC Profile details', details)
    await qbert.putRbacProfileDetails(name, namespace, details)
    listRbacProfiles.call({})
  }),
)

export const patchRbacProfile = async (name, body) => {
  return qbert.patchRbacProfile(name, body)
}

export const getDriftAnalysis = async ({ cluster, profileName }) => {
  const clusterId = cluster.uuid
  const body = {
    apiVersion: 'sunpike.platform9.com/v1alpha2',
    kind: 'ClusterProfileBinding',
    metadata: {
      name: `${profileName}-binding-${Date.now()}`,
    },
    spec: {
      clusterRef: clusterId,
      profileRef: `default/${profileName}`,
      dryRun: true,
    },
  }
  Bugsnag.leaveBreadcrumb('Attempting to get drift analysis', { clusterId, profileName })
  trackEvent('Get drift analysis', {
    clusterId,
    profileName,
  })
  return qbert.createRbacProfileBinding(body)
}

export const getProfileBinding = async (profileName) => {
  return qbert.getRbacProfileBinding(profileName)
}

export const getProfileBindingDetails = async (profileName) => {
  return qbert.getRbacProfileBindingDetails(profileName)
}
