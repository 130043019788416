import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {}

// values match with moment's add/subtract API for use in actions.js
const items = [
  { label: '24 Hours', value: '24.h' },
  { label: '12 Hours', value: '12.h' },
  { label: '6 Hours', value: '6.h' },
  { label: '3 Hours', value: '3.h' },
  { label: '1 Hour', value: '1.h' },
]

export default function TimePicklist(props: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown selectFirst compact items={items} {...props} />
}
