import { clientActions } from 'core/client/clientReducers'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import EntityContainerDetailsPage from '../common/entity/entity-container-details-page'
import { listPods } from './new-actions'
import { podsSelector } from './selectors'

export default function PodContainerDetailsPage() {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId, containerName, namespace } = match.params
  const { loading } = useListAction(listPods, {
    params: { clusterId, namespace },
    requiredParams: ['clusterId'],
  })

  const pods = useSelectorWithParams(podsSelector, { clusterId })
  const pod = useMemo(() => pods.find((pod) => pod.id === id), [id, pods])
  const container = pod?.containers?.find((container) => container.name === containerName) || {}

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: pod?.clusterName || clusterId,
        id: pod?.name || id,
        containerName,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [pod?.clusterName, pod?.name, id, clusterId, containerName])

  return <EntityContainerDetailsPage entity={pod} container={container} loading={loading} />
}
