import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import NumberedStepLabel from 'core/components/validatedForm/NumberedStepLabel'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import { keyValueArrToObj, objToKeyValueArr } from 'utils/fp'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    display: 'grid',
    gap: 24,
  },
}))

export default function SelectorParamFields({ params, updateParams, method = 'add' }) {
  const classes = useStyles()
  return (
    <div className={classes.fields}>
      <NumberedStepLabel
        step={method === 'edit' ? 1 : 2}
        title={method === 'edit' ? 'Node Selectors' : 'Add Node Selectors'}
      />
      <div>
        <Text variant="caption1">
          Use node selectors to apply this configuration to matching nodes.
        </Text>
        <KeyValuesField
          id="selectors"
          value={objToKeyValueArr(params?.selectors)}
          onChange={(value) => updateParams({ selectors: keyValueArrToObj(value) })}
          addLabel="Add Node Selector"
          allowMultipleValues
        />
      </div>
    </div>
  )
}
