import CopyToClipboard from 'core/components/CopyToClipboard'
import React, { useMemo } from 'react'
import DisplayKeyValues from 'core/components/DisplayKeyValues'
import Modal from 'core/elements/modal'
import Button from 'core/elements/button'

const ConfigMapDataDetailsDialog = ({ configMap, onClose }) => {
  const values = useMemo(
    () =>
      Object.keys(configMap?.data).map((key) => {
        return { key, value: configMap?.data[key] }
      }),
    [configMap?.data],
  )
  const dataAsString = useMemo(() => JSON.stringify(configMap?.data), [configMap?.data])
  return (
    <Modal
      open
      panel="dialog"
      onClose={onClose}
      title={configMap?.name}
      maxWidth={700}
      info={
        <CopyToClipboard copyText={dataAsString} inline codeBlock={false} triggerWithChild>
          <Button type="button" icon="copy">
            Copy
          </Button>
        </CopyToClipboard>
      }
      footer={
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      }
    >
      <DisplayKeyValues keyValuePairs={values} rowSpacing={24} />
    </Modal>
  )
}

export default ConfigMapDataDetailsDialog
