import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { trackEvent } from 'utils/tracking'
import { pathStr } from 'utils/fp'
import DeleteAction from 'core/actions/DeleteAction'
import UpdateAction from 'core/actions/UpdateAction'

const { qbert } = ApiClient.getInstance()

export const hostNetworkTemplateActions = ActionsSet.make<DataKeys.HostNetworkTemplates>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.HostNetworkTemplates,
  cacheKey: DataKeys.HostNetworkTemplates,
})

export const listHostNetworkTemplates = hostNetworkTemplateActions.add(
  new ListAction<DataKeys.HostNetworkTemplates, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get host network templates', params)
    return qbert.getHostNetworkTemplates(params.clusterId)
  }),
)

export const createHostNetworkTemplate = hostNetworkTemplateActions.add(
  new CreateAction<
    DataKeys.HostNetworkTemplates,
    { clusterId: string; namespace: string; body: any }
  >(async ({ clusterId, namespace, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create new host network template', {
      clusterId,
      namespace,
      body,
    })
    const created = await qbert.createHostNetworkTemplate(clusterId, namespace, body)
    trackEvent('Create New Host Network Template', {
      clusterId,
      namespace,
      name: pathStr('metadata.name', created),
    })
    return created
  }),
)

export const updateHostNetworkTemplate = hostNetworkTemplateActions.add(
  new UpdateAction<
    DataKeys.HostNetworkTemplates,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update host network template', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn =
      requestType === 'patch' ? qbert.patchHostNetworkTemplate : qbert.updateHostNetworkTemplate
    const updatedHostNetworkTemplate = await updateFn({
      clusterId,
      namespace,
      name,
      body,
      contentType,
    })
    trackEvent('Update Host Network Template', { clusterId, namespace, name })
    return updatedHostNetworkTemplate
  }),
)

export const deleteHostNetworkTemplate = hostNetworkTemplateActions.add(
  new DeleteAction<
    DataKeys.HostNetworkTemplates,
    { clusterId: string; namespace: string; name: string }
  >(async ({ clusterId, namespace, name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete host network template', {
      clusterId,
      namespace,
      name,
    })
    await qbert.deleteHostNetworkTemplate(clusterId, namespace, name)
  }),
)
