import Card from 'core/elements/card'
import React, { FC } from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles((theme: Theme) => ({
  cardComponent: {
    '& .card-body': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .card-footer': {
      paddingBottom: 16,
    },
  },
  cardContent: {
    borderSpacing: '20px',
    position: 'relative',
    left: -20,
  },
  rowHelp: {
    width: 24,
  },
  rowHeader: {
    display: 'flex',
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  rowValue: {
    marginLeft: theme.spacing(0.5),
    wordBreak: 'break-all',
    display: 'flex',
    gap: '4px',
    maxWidth: '400px',
  },
  cardHeader: {
    borderBottom: `solid 1px ${theme.palette.grey[200]}`,
  },
}))

export const DetailRow: FC<{
  label: string
  value: string | React.ReactNode
  lineClamp?: number
}> = ({ label, value, lineClamp }) => {
  const classes = useStyles()
  return (
    <tr>
      <td>
        <Text className={classes.rowHeader} variant="body2" component="span">
          {label}
        </Text>
      </td>
      <td>
        <Text
          className={classes.rowValue}
          variant="caption1"
          component={typeof value === 'string' ? 'span' : 'div'}
          lineClamp={lineClamp}
        >
          {value}
        </Text>
      </td>
    </tr>
  )
}

interface DetailRowProps {
  classes?: any
  items: any[]
}

// @ts-ignore
const DetailRowDiv = ({ items }: DetailRowProps) => {
  return Object.entries(items).map(([name, { value }]) => (
    <DetailRow key={name} label={name} value={value} />
  ))
}

// @ts-ignore
export const renderDetailRow = (items) => <DetailRowDiv items={items} />

const InfoCard = ({ items, title, footer = undefined, ...rest }) => {
  const classes = useStyles()
  return (
    <Card {...rest} title={title} footer={footer} className={classes.cardComponent}>
      <table className={classes.cardContent}>
        <tbody>{Array.isArray(items) ? items.map(renderDetailRow) : renderDetailRow(items)}</tbody>
      </table>
    </Card>
  )
}

export default InfoCard
