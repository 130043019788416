import { createSelector } from '@reduxjs/toolkit'
import { pipe, propEq } from 'ramda'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import { allKey } from 'app/constants'
import { filterIf } from 'utils/fp'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { appsSelector } from '../apps/selectors'
import { importedClustersSelector } from 'app/plugins/infrastructure/components/importedClusters/selectors'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { IDeployedAppsSelector } from '../apps/models'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { findClusterName } from 'k8s/util/helpers'

export const deployedAppsSelector = createSharedSelector(
  getDataSelector<DataKeys.DeployedApps>(
    DataKeys.DeployedApps,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  appsSelector,
  allClustersSelector,
  (rawDeployedApps, apps, allClusters): IDeployedAppsSelector[] => {
    return rawDeployedApps.map((deployedApp) => {
      const clusterId = deployedApp?.clusterId
      const clusterName = findClusterName(allClusters, clusterId)
      const app = apps.find((app) => app.name === deployedApp.chart)
      return {
        ...deployedApp,
        clusterName,
        repository: app?.repository,
        icon: app?.icon,
        home: app?.home,
        chartVersion: deployedApp.chart_version,
        appVersion: app?.appVersion,
      }
    })
  },
)

export const makeDeployedAppsSelector = (
  defaultParams = {
    orderBy: 'name',
    orderDirection: 'asc',
  } as SortConfig & { clusterId?: string; namespace?: string },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(
    deployedAppsSelector,
    clustersSelector,
    importedClustersSelector,
    selectParams,
    (deployedApps, clusters, importedClusters, params) => {
      const { clusterId, namespace, orderBy, orderDirection } = params
      const allClusters = [...clusters, ...importedClusters]
      if (!clusterId || !allClusters.find(propEq('uuid', clusterId))) {
        // If no cluster if found, this item is invalid because the cluster has been deleted
        return []
      }
      return pipe<
        IDeployedAppsSelector[],
        IDeployedAppsSelector[],
        IDeployedAppsSelector[],
        IDeployedAppsSelector[]
      >(
        filterIf(clusterId && clusterId !== allKey, propEq('clusterId', clusterId)),
        filterIf(namespace && namespace !== allKey, propEq('namespace', namespace)),
        createSorter({ orderBy, orderDirection }),
      )(deployedApps)
    },
  )
}

export const deployedAppDetailsSelector = createSharedSelector(
  getDataSelector<DataKeys.DeployedAppDetails>(DataKeys.DeployedAppDetails, [
    'clusterId',
    'namespace',
    'name',
  ]),
  allClustersSelector,
  (rawDeployedAppDetails, allClusters): IDeployedAppsSelector[] => {
    return rawDeployedAppDetails.map((appDetails) => {
      const clusterId = appDetails?.clusterId
      const clusterName = findClusterName(allClusters, clusterId)
      return {
        ...appDetails,
        clusterName,
        icon: appDetails?.chart?.metadata?.icon,
        home: appDetails?.chart?.metadata?.home,
        description: appDetails?.chart?.metadata?.description,
        chartName: appDetails?.chart?.metadata?.name,
        chartVersion: appDetails?.chart?.metadata?.version,
        appVersion: appDetails?.chart?.metadata?.appVersion,
      }
    })
  },
)
