import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {}

const items = [
  { label: 'Active', value: 'Active' },
  { label: 'Suppressed', value: 'Suppressed' },
  { label: 'Closed', value: 'Closed' },
]

export default function StatusPicklist(props: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown name="status" label="Status" items={items} compact showAll {...props} />
}
