import React, { forwardRef, useEffect, useMemo } from 'react'
import PicklistDefault from 'core/elements/dropdown/AsyncDropdown'
import { head, sortWith, descend, prop } from 'ramda'
import { DropdownItemSpec } from 'core/elements/dropdown/DropdownCommonProps'
import useListAction from 'core/hooks/useListAction'
import { listEksVersions } from 'app/plugins/infrastructure/components/versions/eks/actions'
import { eksVersionsSelector } from 'app/plugins/infrastructure/components/versions/eks/selectors'

import { useSelector } from 'react-redux'
import { CapiSupportedK8sVersions } from 'app/plugins/infrastructure/components/clusters/capi/constants'
const Picklist: any = PicklistDefault // types on forward ref .js file dont work well.

interface Props {
  onChange: (key: string) => void
  value: string
  identityName: string
  roleArn: string
  selectFirst?: boolean
  customizeOptionsFn?: (options: DropdownItemSpec<string>[]) => DropdownItemSpec<string>[]
}

const filterDeprecatedVersions = (version) => !version?.deprecated

const filterUnsupportedVersions = (version) => CapiSupportedK8sVersions.includes(version?.name)

const EksKubernetesVersionPicklist = forwardRef(
  (
    { onChange, value, selectFirst, customizeOptionsFn, identityName, roleArn, ...rest }: Props,
    ref,
  ) => {
    const { loading } = useListAction(listEksVersions, { params: { identityName, roleArn } })
    const versions = useSelector(eksVersionsSelector).filter(filterUnsupportedVersions)

    const sortedVersions: any[] = useMemo(() => sortWith([descend(prop('name'))])(versions), [
      versions,
    ])

    const allOptions = useMemo(() => {
      return sortedVersions.map((version) => ({
        label: version?.name,
        value: version?.name,
      }))
    }, [sortedVersions])

    useEffect(() => {
      if (!value && selectFirst) {
        onChange(head(allOptions)?.value)
      }
    }, [selectFirst, value, allOptions])

    const options = useMemo(
      () => (customizeOptionsFn ? customizeOptionsFn(allOptions) : allOptions),
      [customizeOptionsFn, allOptions],
    )

    return (
      <Picklist
        {...rest}
        loading={loading}
        value={value}
        onChange={onChange}
        ref={ref}
        items={options}
      />
    )
  },
)

export default EksKubernetesVersionPicklist
