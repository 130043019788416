import { renderContainerCommands } from 'k8s/components/pods/view-helpers'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React, { useMemo } from 'react'
import { getFieldsForCard } from 'core/components/InfoPanel'
import DocumentMeta from 'core/components/DocumentMeta'
import InfoCard from './info-card'
import Progress from 'core/components/progress/Progress'
import Card from 'core/elements/card'
import Grid from 'core/elements/grid'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
    alignItems: 'start',
  },
}))

const metadataFields = [
  { id: 'name', title: 'Name', required: true },
  { id: 'type', title: 'Type', required: true, render: (type) => type || 'Container' },
  { id: 'image', title: 'Image', required: true },
  { id: 'command', title: 'Commands', required: true, render: renderContainerCommands },
  { id: 'workingDir', title: 'Mounts', required: true },
]

const containerVolumeColumns = [
  { key: 'name', label: 'Name' },
  { key: 'mountPath', label: 'Mount Path' },
  { key: 'mountPropagation', label: 'Mount Propogation' },
]

export default function EntityContainerDetailsPage({ entity, container, loading }) {
  const classes = useStyles()

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, container)
  }, [container])

  return (
    <>
      <DocumentMeta title="Container Overview" breadcrumbs />
      <Progress loading={loading}>
        <div className={classes.overview}>
          <InfoCard items={metadata} title="Metadata" />
          <Card title="Volumes" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={container?.volumeMounts || []}
              columns={containerVolumeColumns}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </Progress>
    </>
  )
}
