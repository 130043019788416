import React, { useMemo } from 'react'
import DocumentMeta from 'core/components/DocumentMeta'
import AppCardsGrid from '../../AppCardsGrid'
import DeployAppModalForm from './DeployAppModalForm'
import useListAction from 'core/hooks/useListAction'
import { listApps } from '../new-actions'
import { useSelector } from 'react-redux'
import { appsSelector } from '../selectors'
import useParams from 'core/hooks/useParams'
import RepositoryPicklist from '../../RepositoryPicklist'
import { repositoriesConnectedToClustersSelector } from '../../repositories/selectors'
import { allKey } from 'app/constants'
import { flatten, isEmpty, pluck, uniq } from 'ramda'
import { listRepositories } from '../../repositories/new-actions'
import { filterByRepositories } from '../../helpers'
import useGlobalParams from 'core/hooks/useGlobalParams'

const defaultParams = {
  clusterId: allKey,
  repository: null,
}

export default function AppCatalogPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(useParams, defaultParams)
  const { loading: loadingApps, message, reload } = useListAction(listApps)
  const apps = useSelector(appsSelector)
  const { loading: loadingRepositories } = useListAction(listRepositories)
  const reposConnectedToClusters = useSelector(repositoriesConnectedToClustersSelector)

  const filteredApps = useMemo(() => {
    if (isEmpty(apps)) return apps
    // We don't have pagination for apps yet so in order to minimize the number of
    // apps rendered at one time, only load the apps from a specific repository first
    if (params.repository === null) return []
    let filteredApps = apps

    if (!!params.repository && params.repository !== allKey) {
      filteredApps = filterByRepositories(filteredApps, [params.repository])
    }

    if (!!params.clusterId) {
      const repositoriesConnectedToCluster = flatten(
        params.clusterId.map((clusterId) => {
          return reposConnectedToClusters[clusterId] || []
        }),
      )
      const repositoryNames = uniq(pluck<any, string>('name', repositoriesConnectedToCluster))
      filteredApps = filterByRepositories(filteredApps, repositoryNames)
    }
    return filteredApps
  }, [apps, params, reposConnectedToClusters])

  return (
    <>
      <DocumentMeta title="App Catalog" breadcrumbs />
      <DeployAppModalForm />
      <AppCardsGrid
        apps={filteredApps}
        appType="undeployed"
        loadingApps={loadingApps || loadingRepositories}
        message={message}
        reload={reload}
        filters={
          <>
            <RepositoryPicklist
              onChange={getParamsUpdater('repository')}
              value={params.repository}
              selectFirst
            />
          </>
        }
      />
    </>
  )
}
