import { clientActions } from 'core/client/clientReducers'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import { listDaemonSets, updateDaemonSet } from './actions'
import { listPods } from 'k8s/components/pods/new-actions'
import useListAction from 'core/hooks/useListAction'
import { routes } from 'core/utils/routes'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import CardsHeader from '../common/entity/cards-header'
import PodsStatusCard from '../common/entity/pods-status-card'
import { daemonSetSelector } from './selectors'
import { IDaemonSetDetailsPageTabs, IDaemonSetSelector } from './models'
import DaemonSetOverview from './DaemonSetOverview'
import EntityPodsPage from '../common/entity/entity-pods-page'
import EntityEventsPage from '../common/entity/entity-events-page'
import EntityLogsPage from '../common/entity/entity-logs-page'
import EntityYamlPage from '../common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import { pathStrOr } from 'utils/fp'
import jsYaml from 'js-yaml'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { isEmpty } from 'ramda'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'

const { qbert } = ApiClient.getInstance()

const defaultDaemonSet = {
  volumes: [],
  tolerations: [],
} as IDaemonSetSelector

const DaemonSetOverviewPage = () => {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { globalParams: params } = useGlobalParams(useParams, {})
  const { loading, reload } = useListAction(listDaemonSets, {
    params,
  })
  const daemonSets = useSelectorWithParams(daemonSetSelector, { clusterId })
  const daemonSet = useMemo(
    () => daemonSets.find((daemonSet) => daemonSet.id === id) || defaultDaemonSet,
    [id, daemonSets],
  )
  const { update, updating } = useUpdateAction(updateDaemonSet)

  const { loading: loadingPods, reload: reloadPods } = useListAction(listPods, { params })

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: daemonSet?.clusterName || clusterId,
        id: daemonSet?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [daemonSet?.clusterName, daemonSet?.name, id, clusterId])

  const getDaemonSetYaml = useCallback(async () => {
    if (isEmpty(daemonSet)) return undefined
    return qbert.getDaemonSet(daemonSet?.clusterId, daemonSet?.namespace, daemonSet?.name)
  }, [daemonSet])

  const handleDaemonSetUpdate = useCallback(
    async (yaml) => {
      const body = jsYaml.load(yaml)
      const namespace = pathStrOr('', 'metadata.namespace', body)
      const name = pathStrOr('', 'metadata.name', body)
      await update({ clusterId, namespace, name, id: daemonSet?.id, body })
    },
    [clusterId, daemonSet?.id],
  )

  return (
    <>
      <DocumentMeta title="Daemon Set Overview" breadcrumbs />
      <CardsHeader>
        <PodsStatusCard pods={daemonSet?.pods} />
      </CardsHeader>
      <Tabs route={routes.daemonSets.details}>
        <Tab label="Overview" value={IDaemonSetDetailsPageTabs.Overview}>
          <DaemonSetOverview daemonSet={daemonSet} loading={loading} />
        </Tab>
        <Tab label="Pods" value={IDaemonSetDetailsPageTabs.Pods}>
          <EntityPodsPage
            pods={daemonSet?.pods}
            loading={loading || loadingPods}
            reload={reloadPods}
          />
        </Tab>
        <Tab label="Events" value={IDaemonSetDetailsPageTabs.Events}>
          <EntityEventsPage entity={daemonSet} loading={loading} />
        </Tab>
        <Tab label="Logs" value={IDaemonSetDetailsPageTabs.Logs}>
          <EntityLogsPage pods={daemonSet?.pods} loading={loading} />
        </Tab>
        <Tab label="YAML" value={IDaemonSetDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Daemon Set"
            entityName={daemonSet?.name}
            getYamlFn={getDaemonSetYaml}
            handleUpdate={handleDaemonSetUpdate}
            loading={updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default DaemonSetOverviewPage
