import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { findCluster } from 'k8s/util/helpers'
import { durationBetweenDates } from 'utils/misc'
import { ISecretSelector } from './model'

export const secretsSelector = createSharedSelector(
  getDataSelector<DataKeys.Secrets>(DataKeys.Secrets, ['clusterId'], ['clusterId', 'namespace']),
  allClustersSelector,
  (rawSecrets, allClusters): ISecretSelector[] => {
    return rawSecrets.map((rawSecret) => {
      const { clusterId, metadata, data } = rawSecret
      const { name: clusterName, clusterType: clusterType } = findCluster(allClusters, clusterId)
      const dataSize = !!data ? Object.keys(data).length : 0
      return {
        ...rawSecret,
        clusterName,
        clusterId,
        clusterType,
        dataSize,
        age: durationBetweenDates({ labels: ['d'] })(metadata?.creationTimestamp),
        ownerReferences: metadata?.ownerReferences,
        creationTimestamp: metadata?.creationTimestamp,
        labels: metadata?.labels,
        annotations: metadata?.annotations,
      }
    })
  },
)
