import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import Text from 'core/elements/Text'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import InfoBlocks from 'core/components/entity-page/InfoBlocks'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import useListAction from 'core/hooks/useListAction'
import { listPods } from 'k8s/components/pods/new-actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { podsSelector } from 'k8s/components/pods/selectors'
import Grid from 'core/elements/grid/Grid'
import { uncamelizeString } from 'utils/misc'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  customCardHeader: {
    display: 'flex',
    padding: '16px 32px 0px',
    gap: 8,
    alignItems: 'center',
  },
  pluginStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  enabled: {
    fontSize: 10,
    color: theme.palette.green.main,
  },
  disabled: {
    fontSize: 10,
    color: theme.palette.red.main,
  },
  config: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: '24px 32px',
    gap: '12px 24px',
  },
  configColumn: {
    display: 'grid',
    gap: 12,
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  configData: {
    display: 'grid',
    gridTemplateColumns: '140px 1fr',
    justifyItems: 'start',
  },
}))

const metadataFields = []

const attachedItemsColumns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'type',
    label: 'Type',
  },
]

interface DataProps {
  label: string
  value: string
}

const ConfigData = ({ label, value }: DataProps) => {
  const classes = useStyles()
  return (
    <div className={classes.configData}>
      <Text variant="body2">{label}</Text>
      <Text variant="caption1">{value}</Text>
    </div>
  )
}

interface ConfigProps {
  config: any
  networkType: string
}

const CniConfig = ({ config, networkType }: ConfigProps) => {
  const classes = useStyles()

  return (
    <div className={classes.config}>
      <div className={classes.configColumn}>
        <Text variant="caption1">Interface</Text>

        {config?.name && <ConfigData label="Network Name" value={config?.name} />}
        {config?.bridge && <ConfigData label="Bridge Name" value={config?.bridge} />}
        {config?.host?.bridge?.bridgeName && (
          <ConfigData label="Bridge Name" value={config?.host?.bridge?.bridgeName} />
        )}

        {config?.vlan && <ConfigData label="VLAN" value={config?.vlan} />}
        {config?.host?.bridge?.vlanId && (
          <ConfigData label="VLAN" value={config?.host?.bridge?.vlanId} />
        )}

        {config?.spoofchk && (
          <ConfigData label="spoofchk" value={config?.spoofchk === 'on' ? 'Enabled' : 'Disabled'} />
        )}
        {config?.vlanQoS && <ConfigData label="vlanQoS" value={config?.vlanQoS} />}
        {config?.max_tx_rate && <ConfigData label="max_tx_rate" value={config?.max_tx_rate} />}
        {config?.trust && (
          <ConfigData label="Trust" value={config?.trust === 'on' ? 'Enabled' : 'Disabled'} />
        )}
        {config?.master && <ConfigData label="Master" value={config?.master} />}
        {config?.mode && <ConfigData label="Mode" value={config?.mode} />}
        {config?.logFile && <ConfigData label="Log File" value={config?.logFile} />}
        {config?.logLevel && <ConfigData label="Log Level" value={config?.logLevel} />}
      </div>
      <div className={classes.configColumn}>
        <Text variant="caption1">IPAM</Text>

        {config?.ipam ? (
          <>
            {config?.ipam?.type && <ConfigData label="Type" value={config?.ipam?.type} />}
            {config?.ipam?.range && <ConfigData label="Range" value={config?.ipam?.range} />}
            {config?.ipam?.range_start && (
              <ConfigData label="Range Start" value={config?.ipam?.range_start} />
            )}
            {config?.ipam?.range_end && (
              <ConfigData label="Range End" value={config?.ipam?.range_end} />
            )}
            {config?.ipam?.datastore && (
              <ConfigData label="datastore" value={config?.ipam?.datastore} />
            )}
            {config?.ipam?.gateway && <ConfigData label="Gateway" value={config?.ipam?.gateway} />}
            {config?.ipam?.log_file && (
              <ConfigData label="Log File" value={config?.ipam?.log_file} />
            )}
            {config?.ipam?.log_level && (
              <ConfigData label="Log Level" value={config?.ipam?.log_level} />
            )}
          </>
        ) : (
          <Text variant="body2">
            {networkType === 'sriovDpdk' ? 'Not applicable' : 'Add IPAM using edit action or YAML'}
          </Text>
        )}
      </div>
    </div>
  )
}

export default function Overview({ network, loading }) {
  const classes = useStyles()

  // Getting Attached Items
  const { loading: loadingPods } = useListAction(listPods, {
    params: {
      clusterId: network?.clusterId,
      namespace: network?.namespace,
    },
  })
  const pods = useSelectorWithParams(podsSelector, {
    clusterId: network?.clusterId,
    useGlobalParams: false,
  })

  const networkPods = useMemo(() => {
    return pods.reduce((accum, pod) => {
      const annotations = pod.annotations || {}

      Object.entries(annotations).forEach(([key, value]) => {
        if (key === 'k8s.v1.cni.cncf.io/networks') {
          const networksObj = JSON.parse(value)
          if (network.name === networksObj[0].name) {
            accum.push({ name: pod.name, type: 'Pod' })
          }
        }
      })
      return accum
    }, [])
  }, [pods])

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, network)
  }, [network])

  const overviewData = useMemo(
    () => [
      {
        label: 'Cluster',
        value: network?.clusterName,
      },
      {
        label: 'Namespace',
        value: network?.namespace,
      },
      {
        label: 'Age',
        value: network?.age,
      },
    ],
    [network],
  )

  const configObj = network?.spec?.config ? JSON.parse(network?.spec?.config) : {}

  return (
    <>
      <Progress loading={loading}>
        <div className={classes.overview}>
          <div className={classes.column}>
            <Card
              title={
                <div className={classes.customCardHeader}>
                  <Text variant="subtitle2">{network?.name}</Text>
                </div>
              }
            >
              <Text variant="body2">Network Attachment Definition</Text>
              <InfoBlocks data={overviewData} />
            </Card>
            <InfoCard
              items={metadata}
              title="Properties"
              footer={<LabelsAndAnnotationsSection entity={network} resourceType="network" />}
            />
          </div>
          <div className={classes.column}>
            <Card title={`CNI Plugins (${uncamelizeString(network?.networkType)})`} withCustomBody>
              <CniConfig config={configObj} networkType={network?.networkType} />
            </Card>
            <Card title="Attached Items" withCustomBody>
              <Grid
                uniqueIdentifier="name"
                data={networkPods}
                columns={attachedItemsColumns}
                loading={loadingPods}
                compact
                disableToolbar
              />
            </Card>
          </div>
        </div>
      </Progress>
    </>
  )
}
