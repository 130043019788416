import React, { useState, useCallback } from 'react'
import ModalForm from 'core/elements/modal/ModalForm'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import Text from 'core/elements/Text'
import RadioSelectableCard from 'k8s/components/common/RadioSelectableCard'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createNetwork } from './actions'
import jsYaml from 'js-yaml'
import OvsParamFields from './OvsParamFields'
import OvsDpdkParamFields from './OvsDpdkParamFields'
import SriovParamFields from './SriovParamFields'
import SriovDpdkParamFields from './SriovDpdkParamFields'
import MacvlanParamFields from './MacvlanParamFields'
import IpvlanParamFields from './IpvlanParamFields'
import YamlParamFields from './YamlParamFields'
import BasicSettingsParamFields from './BasicSettingsParamFields'
import usePluginRouter from 'core/hooks/usePluginRouter'

export enum NetworkTypes {
  Ovs = 'ovs',
  OvsDpdk = 'ovsDpdk',
  Sriov = 'sriov',
  SriovDpdk = 'sriovDpdk',
  Macvlan = 'macvlan',
  Ipvlan = 'ipvlan',
}

const networkTypes = [
  { label: 'OVS', value: NetworkTypes.Ovs },
  { label: 'OVS DPDK', value: NetworkTypes.OvsDpdk },
  { label: 'SR-IOV', value: NetworkTypes.Sriov },
  { label: 'SR-IOV DPDK', value: NetworkTypes.SriovDpdk },
  { label: 'MACVLAN', value: NetworkTypes.Macvlan },
  { label: 'IPVLAN', value: NetworkTypes.Ipvlan },
]

export const ipamTypes = [
  { label: 'Whereabouts', value: 'whereabouts' },
  { label: 'DHCP', value: 'dhcp' },
]

export const modes = [
  { label: 'Bridge', value: 'bridge' },
  { label: 'l2', value: 'l2' },
]

export default function AddNetworkAttachmentDefinitionsModal({ addRoute }) {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const { history } = useReactRouter()

  const [form, setForm] = useState(true)

  const defaultParams = {
    clusterId: null,
    name: null,
    namespace: null,
    resourceName: null,
    config: {
      cniVersion: '0.3.1',
      type: null,
    },
    networkType: null,
    yaml: null,
  }

  const { params, updateParams, setParams } = useParams<{
    clusterId: string
    name: string
    namespace: string
    resourceName?: string
    config: any
    networkType: string
    yaml: string
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(createNetwork)

  const submitForm = useCallback(async () => {
    if (form) {
      const body = {
        apiVersion: 'k8s.cni.cncf.io/v1',
        kind: 'NetworkAttachmentDefinition',
        metadata: {
          name: params?.name,
          annotations: params?.resourceName
            ? {
                'k8s.v1.cni.cncf.io/resourceName': params?.resourceName,
              }
            : undefined,
        },
        spec: {
          config: JSON.stringify(params?.config),
        },
      }
      const { success } = await update({
        clusterId: params?.clusterId,
        namespace: params?.namespace,
        body,
      })
      if (success) handleClose()
    } else {
      const body = jsYaml.load(params?.yaml)

      const { success } = await update({
        clusterId: params?.clusterId,
        namespace: params?.namespace,
        body,
      })
      if (success) handleClose()
    }
  }, [form, params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes[routePath].network.path())
  }

  const classes = useStyles()

  return (
    <ModalForm
      route={addRoute}
      title="Add Network Attachment Definition"
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      maxWidth={528}
    >
      <div className={classes.container}>
        <Text variant="body2">Select one of the below: </Text>
        <RadioSelectableCard
          label="Fill in the form"
          active={form}
          onClick={() => setForm(true)}
        ></RadioSelectableCard>
        <RadioSelectableCard
          label="Upload or paste YAML"
          active={!form}
          onClick={() => setForm(false)}
        ></RadioSelectableCard>

        <hr className={classes.divider} />
        {form ? (
          <>
            <Text variant="body2">
              Select a <strong>CNI Plugin Type</strong> and proceed with preferred method of
              configuration.
            </Text>

            <DropdownField
              DropdownComponent={Dropdown}
              id="networkType"
              label="CNI Plugin Type"
              value={params?.networkType}
              onChange={(value) => updateParams({ networkType: value })}
              items={networkTypes}
            />

            <hr className={classes.divider} />

            {!!params?.networkType && (
              <BasicSettingsParamFields params={params} updateParams={updateParams} />
            )}

            {params?.networkType === NetworkTypes.Ovs && (
              <OvsParamFields method="add" params={params} updateParams={updateParams} />
            )}

            {params?.networkType === NetworkTypes.OvsDpdk && (
              <OvsDpdkParamFields method="add" params={params} updateParams={updateParams} />
            )}

            {params?.networkType === NetworkTypes.Sriov && (
              <SriovParamFields method="add" params={params} updateParams={updateParams} />
            )}

            {params?.networkType === NetworkTypes.SriovDpdk && (
              <SriovDpdkParamFields method="add" params={params} updateParams={updateParams} />
            )}

            {params?.networkType === NetworkTypes.Macvlan && (
              <MacvlanParamFields method="add" params={params} updateParams={updateParams} />
            )}

            {params?.networkType === NetworkTypes.Ipvlan && (
              <IpvlanParamFields method="add" params={params} updateParams={updateParams} />
            )}
          </>
        ) : (
          <>
            <BasicSettingsParamFields
              inYamlMethod={true}
              params={params}
              updateParams={updateParams}
            />
            <YamlParamFields params={params} updateParams={updateParams} />
          </>
        )}
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '24px 40px',
    display: 'grid',
    gap: 24,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
}))
