import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const items = [
  { label: 'Filesystem', value: 'Filesystem' },
  { label: 'Block', value: 'Block' },
]

interface Props {}

export default function VolumeModePicklist(props: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown name="volumeMode" label="Volume Mode" compact items={items} {...props} />
}
