import React, { useEffect } from 'react'
import TextField from 'core/components/validatedForm/TextField'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { minValueValidator } from 'core/utils/fieldValidators'

export default function SriovDpdkParamFields({ params, updateParams, method = 'add' }) {
  useEffect(() => {
    if (method === 'edit') {
      return
    }

    updateParams({
      config: {
        ...params?.config,
        type: 'sriov',
        spoofchk: 'off',
        trust: 'off',
      },
    })
  }, [])

  return (
    <>
      <FormFieldSection title="Interface" step={method === 'edit' ? 1 : 2}>
        <TextField
          id="networkName"
          label="Network Name"
          info="The name of the network"
          value={params?.config?.name}
          onChange={(value) => updateParams({ config: { ...params?.config, name: value } })}
        />
        <TextField
          id="vlan"
          label="VLAN"
          type="number"
          value={params?.config?.vlan}
          onChange={(value) => updateParams({ config: { ...params?.config, vlan: value } })}
        />
        <TextField
          id="vlanQoS"
          label="vlanQoS (e.g. 1)"
          type="number"
          min={0}
          validations={[minValueValidator(0)]}
          value={params?.config?.vlanQoS}
          onChange={(value) => updateParams({ config: { ...params?.config, vlanQoS: value } })}
        />
        <TextField
          id="max_tx_rate"
          label="max_tx_rate (e.g. 100)"
          type="number"
          min={1}
          validations={[minValueValidator(1)]}
          value={params?.config?.max_tx_rate}
          onChange={(value) => updateParams({ config: { ...params?.config, max_tx_rate: value } })}
        />
        <TextField
          id="logFile"
          label="Log File"
          info="File path for multus log (e.g. /var/log/multus.log)"
          value={params?.config?.logFile}
          onChange={(value) => updateParams({ config: { ...params?.config, logFile: value } })}
        />
        <TextField
          id="logLevel"
          label="Log Level"
          info="Logging level (e.g. 'debug', 'error', 'verbose' or 'panic')"
          value={params?.config?.logLevel}
          onChange={(value) => updateParams({ config: { ...params?.config, logLevel: value } })}
        />
        <ToggleSwitchField
          id="spoofchk"
          label="spoofchk"
          value={params?.config?.spoofchk === 'on'}
          onChange={(val) => {
            updateParams(
              val
                ? { config: { ...params?.config, spoofchk: 'on' } }
                : { config: { ...params?.config, spoofchk: 'off' } },
            )
          }}
        />
        <ToggleSwitchField
          id="trust"
          label="Trust"
          value={params?.config?.trust === 'on'}
          onChange={(val) =>
            updateParams(
              val
                ? { config: { ...params?.config, trust: 'on' } }
                : { config: { ...params?.config, trust: 'off' } },
            )
          }
        />
      </FormFieldSection>
    </>
  )
}
