// libs
import React, { useCallback, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { pathOr, prop } from 'ramda'
import { useSelector } from 'react-redux'

// Constants
import { UserPreferences } from 'app/constants'
// Components
import Text from 'core/elements/Text'
import { routes } from 'core/utils/routes'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { isDecco } from 'core/utils/helpers'
import { emptyArr, emptyObj } from 'utils/fp'
import IconButton from 'core/elements/button/IconButton'
import { baseCards } from './helpers'
import CardMosaic from './card-mosaic'
import { RootState } from 'app/store'
import DocumentMeta from 'core/components/DocumentMeta'
import { HeaderPrimaryActionPortal, HeaderTitlePortal } from 'core/elements/header/portals'

const DashboardPage = () => {
  const { history } = useReactRouter()
  const { prefs, fetchUserDefaults, updateUserDefaults } = useScopedPreferences('defaults')
  const { cards = emptyArr, isInitialized = false } = (prefs[UserPreferences.Dashboard] ||
    emptyObj) as { cards: Array<unknown>; isInitialized: boolean }

  useEffect(() => {
    // Load user defaults from the preference store
    fetchUserDefaults(UserPreferences.Dashboard)
    fetchUserDefaults(UserPreferences.Cluster)
  }, [])

  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const {
    userDetails: { displayName, role },
    features,
  } = session

  const isDeccoEnv = isDecco(features)
  useEffect(() => {
    if (isDeccoEnv && !isInitialized && !cards.length) {
      updateUserDefaults(UserPreferences.Dashboard, {
        isInitialized: true,
        cards: baseCards,
      })
    }
  }, [isDeccoEnv, isInitialized, cards])
  // To avoid missing API errors for ironic region UX-751
  const kubeRegion = pathOr(false, ['experimental', 'containervisor'], features)

  const handleEditDashboard = useCallback(() => {
    history.push(routes.dashboard.edit.path())
  }, [])

  return (
    <>
      <DocumentMeta title="Dashboard" />
      <HeaderTitlePortal>
        <Text id="dashboard-title" variant="subtitle1">
          Welcome{displayName ? ` ${displayName}` : ''}!
        </Text>
      </HeaderTitlePortal>
      {isDeccoEnv && (
        <HeaderPrimaryActionPortal>
          <IconButton icon="pencil" info="Customize your dashboard" onClick={handleEditDashboard} />
        </HeaderPrimaryActionPortal>
      )}
      {kubeRegion && <CardMosaic cards={!isDeccoEnv ? baseCards : cards} role={role} />}
    </>
  )
}

export default DashboardPage
