import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ModalForm from 'core/elements/modal/ModalForm'
import useReactRouter from 'use-react-router'
import useParams from 'core/hooks/useParams'
import { routes } from 'core/utils/routes'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createNetworkPolicy } from './actions'
import DropdownField from 'core/components/validatedForm/DropdownField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import TextFileDrop from 'core/elements/grid/TextFileDrop'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import { codeMirrorOptions } from 'app/constants'
import { customValidator, requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import moize from 'moize'
import jsYaml from 'js-yaml'

const moizedYamlLoad = moize(jsYaml.load, {
  maxSize: 10,
})

const codeMirrorValidations = [
  requiredValidator,
  yamlValidator,
  customValidator((yaml, formFields) => {
    try {
      const body = moizedYamlLoad(yaml)
      return body?.kind === 'NetworkPolicy'
    } catch (err) {
      return true
    }
  }, 'YAML kind must be NetworkPolicy'),
]

export default function AddNetworkPolicyModal({ addRoute }) {
  const { history } = useReactRouter()

  const classes = useStyles()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'

  const defaultParams = {
    clusterId: null,
    yaml: '',
  }

  const { params, updateParams, setParams } = useParams<{ clusterId: string; yaml: string }>(
    defaultParams,
  )

  const { update, updating, error, reset } = useUpdateAction(createNetworkPolicy)

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes[routePath].networkPolicies.list.path())
  }

  const submitForm = useCallback(async () => {
    const body = jsYaml.load(params?.yaml)

    const { success } = await update({
      clusterId: params?.clusterId,
      namespace: body?.metadata?.namespace || 'default',
      body,
    })
    if (success) handleClose()
  }, [params, handleClose])

  return (
    <ModalForm
      route={addRoute}
      title="Add Network Policy"
      onSubmit={submitForm}
      submitting={updating}
      error={error}
      onClose={handleClose}
      maxWidth={528}
    >
      <div className={classes.container}>
        <DropdownField
          DropdownComponent={ClusterPicklist}
          id="clusterId"
          label="Select Cluster"
          value={params?.clusterId}
          onChange={(value) => updateParams({ clusterId: value })}
          showAll={false}
          required
        />
        <TextFileDrop
          onChange={(value) => updateParams({ yaml: value })}
          fileTypes={['.yaml', '.yml']}
        />
        <CodeMirror
          className={classes.limitWidth}
          label="YAML"
          id="yaml"
          validations={codeMirrorValidations}
          onChange={(value) => updateParams({ yaml: value })}
          value={params?.yaml}
          options={codeMirrorOptions}
        />
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '16px 40px 40px 32px',
    display: 'grid',
    gap: 32,
  },
  fields: {
    display: 'grid',
    gap: 24,
  },
  limitWidth: {
    // Max width possible for this w/o causing scrollbar
    // Assumes dialog with max width of 528px
    maxWidth: 407,
  },
}))
