import { createSelector } from '@reduxjs/toolkit'
import { pipe, propEq, propSatisfies, pathEq } from 'ramda'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { determineProfileResources } from './helpers'
import { arrayIfEmpty, filterIf, isTruthy } from 'utils/fp'
import { uuidRegex } from 'app/constants'
import { ClusterProfileBindingsSelector } from './cluster-profile-binding-model'
import { findClusterName } from 'k8s/util/helpers'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { ClusterProfileSelector } from './cluster-profile-model'
import {
  allClustersSelector,
  qbertAndCapiClustersSelector,
} from 'app/plugins/infrastructure/components/combinedClusters/selectors'

export const rbacProfileBindingsSelector = createSharedSelector(
  getDataSelector(DataKeys.RbacProfileBindings),
  allClustersSelector,
  (profileBindings, clusters) => {
    return arrayIfEmpty(
      profileBindings.map((binding) => {
        return {
          ...binding,
          id: binding.metadata.uid,
          name: binding.metadata.name,
          dryRun: binding?.spec?.dryRun,
          clusterName: findClusterName(clusters, binding?.spec?.clusterRef),
          profileName: binding?.spec?.profileRef?.split('default/')[1],
          created: binding?.metadata?.creationTimestamp,
        }
      }),
    )
  },
)

export const makeRbacProfileBindingsSelector = (
  defaultParams: any = {
    orderBy: 'name',
    orderDirection: 'asc',
  },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(
    rbacProfileBindingsSelector,
    selectParams,
    (rbacProfileBindings, params) => {
      const { orderBy, orderDirection, dryRun } = params
      return pipe<
        ClusterProfileBindingsSelector[],
        ClusterProfileBindingsSelector[],
        ClusterProfileBindingsSelector[],
        ClusterProfileBindingsSelector[]
      >(
        filterIf(dryRun, propSatisfies(isTruthy, 'dryRun')),
        createSorter({ orderBy, orderDirection }),
        arrayIfEmpty,
      )(rbacProfileBindings)
    },
  )
}

const determineProfileAction = (profile) => {
  const status = profile.status.phase
  if (status === 'published') {
    return 'deploy'
  } else if (status === 'draft') {
    return 'publish'
  }
  return 'none'
}

export const rbacProfilesSelector = createSharedSelector(
  getDataSelector(DataKeys.RbacProfiles),
  rbacProfileBindingsSelector,
  qbertAndCapiClustersSelector,
  (rbacProfiles, profileBindings, clusters): ClusterProfileSelector[] => {
    return arrayIfEmpty(
      rbacProfiles.map((profile) => {
        const matchingBindings = profileBindings.filter((binding) => {
          const isDryRun = !!binding.spec.dryRun
          const bindingMatches =
            binding.spec.profileRef.split('default/')[1] === profile.metadata.name
          return !isDryRun && bindingMatches
        })
        const bindingClusters = matchingBindings.map((binding) => {
          return binding.spec.clusterRef
        })
        const matchingClusters = clusters.filter((cluster) => {
          return bindingClusters.includes(cluster.uuid)
        })
        const profileResources = determineProfileResources(profile)
        const baseCluster = profile?.spec?.cloneFrom

        // FIXME remove the following when backend consistently returns the cluster uui rather than the name
        const baseClusterId = baseCluster.match(uuidRegex)
          ? baseCluster
          : clusters.find(propEq('name', baseCluster))?.uuid || baseCluster
        const baseClusterName = clusters.find(propEq('uuid', baseClusterId))?.name || baseClusterId

        return {
          ...profile,
          action: determineProfileAction(profile),
          id: profile.metadata.uid,
          name: profile.metadata.name,
          clusters: matchingClusters,
          bindings: matchingBindings,
          baseClusterName,
          baseClusterId,
          ...profileResources,
        }
      }),
    )
  },
)

export const makeRbacProfilesSelector = (
  defaultParams = {
    orderBy: 'name',
    orderDirection: 'asc',
  } as SortConfig,
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(rbacProfilesSelector, selectParams, (rbacProfiles, params) => {
    const { orderBy, orderDirection } = params
    return pipe(createSorter({ orderBy, orderDirection }), arrayIfEmpty)(rbacProfiles)
  })
}
