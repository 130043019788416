import ListTable from 'core/components/listTable/ListTable'
import useToggler from 'core/hooks/useToggler'
import EditClusterRoleDialog from 'k8s/components/rbac/profiles/edit/EditClusterRoleDialog'
import ViewClusterRoleDialog from 'k8s/components/rbac/profiles/edit/ViewClusterRoleDialog'
import React, { useCallback, useMemo, useState, FC } from 'react'
import EditIcon from 'k8s/components/rbac/profiles/EditIcon'
import DeleteIcon from 'k8s/components/rbac/profiles/DeleteIcon'
import ViewIcon from 'k8s/components/rbac/profiles/ViewIcon'
import Button from 'core/elements/button'
import useStyles from 'k8s/components/rbac/profiles/edit/useStyles'
import { IRbacClusterRole } from 'k8s/components/rbac/model'
import { HeaderPrimaryActionPortal } from 'core/elements/header/portals'

const infoColumns = [{ id: 'metadata.name', label: 'Name' }]
const visibleColumns = ['metadata.name', 'apiAccess']
const columnsOrder = ['metadata.name', 'apiAccess']
const orderBy = 'metadata.name'
const orderDirection = 'asc'
const searchTargets = ['metadata.name']

interface Props {
  clusterId: string
  clusterRoles: IRbacClusterRole[]
  onAdd: (role: IRbacClusterRole) => void
  onUpdate: (role: IRbacClusterRole) => void
  onRemove: (role: IRbacClusterRole) => void
  readOnly?: boolean
}

const ClusterRolesList: FC<Props> = ({
  clusterId,
  clusterRoles,
  onAdd,
  onUpdate,
  onRemove,
  readOnly = false,
}) => {
  /*****************************
   *      EDIT DIALOG
   *****************************/
  const [currentRow, setCurrentRow] = useState<IRbacClusterRole>()
  const [showingEditDialog, toggleEditDialog] = useToggler()
  const showApiPermissions = useCallback((row: IRbacClusterRole) => {
    setCurrentRow(row)
    toggleEditDialog()
  }, [])
  const handleUpdate = useCallback(
    ({ rules }: IRbacClusterRole) => {
      onUpdate({
        ...currentRow,
        rules,
      })
      toggleEditDialog()
    },
    [currentRow, onUpdate],
  )

  /*****************************
   *        ADD DIALOG
   *****************************/
  const handleAdd = useCallback(() => {
    toggleAddDialog()
  }, [])
  const addToProfile = useCallback(
    (newRole: IRbacClusterRole) => {
      toggleAddDialog()
      onAdd(newRole)
    },
    [onAdd],
  )
  const [showingAddDialog, toggleAddDialog] = useToggler()

  /*****************************
   *        VIEW DIALOG
   *****************************/
  const [showingViewDialog, toggleViewDialog] = useToggler()
  const showViewRoleDialog = useCallback((row: IRbacClusterRole) => {
    setCurrentRow(row)
    toggleViewDialog()
  }, [])

  /*****************************
   *    CLUSTER ROLES LIST
   *****************************/
  const classes = useStyles()
  const columns = useMemo(
    () =>
      readOnly
        ? [
            ...infoColumns,
            {
              id: 'apiAccess',
              render: (value, row) => (
                <ViewIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    showViewRoleDialog(row)
                  }}
                />
              ),
            },
          ]
        : [
            ...infoColumns,
            // Actions column
            {
              id: 'apiAccess',
              render: (value, row) => (
                <>
                  <EditIcon
                    onClick={(e) => {
                      e.stopPropagation()
                      showApiPermissions(row)
                    }}
                  />
                  <DeleteIcon
                    onClick={(e) => {
                      e.stopPropagation()
                      onRemove(row)
                    }}
                  />
                </>
              ),
            },
          ],
    [onRemove, readOnly],
  )

  return (
    <>
      {!readOnly && (
        <HeaderPrimaryActionPortal>
          <Button className={classes.addBtn} onClick={handleAdd} icon="plus">
            Add New Cluster Role
          </Button>
        </HeaderPrimaryActionPortal>
      )}
      {showingViewDialog && (
        <ViewClusterRoleDialog
          clusterId={clusterId}
          onClose={toggleViewDialog}
          open={showingViewDialog}
          clusterRole={currentRow}
        />
      )}
      {showingAddDialog && (
        <EditClusterRoleDialog
          clusterId={clusterId}
          onClose={toggleAddDialog}
          onSubmit={addToProfile}
          open={showingAddDialog}
        />
      )}
      {currentRow && showingEditDialog && (
        <EditClusterRoleDialog
          clusterId={clusterId}
          onClose={toggleEditDialog}
          onSubmit={handleUpdate}
          open={showingEditDialog}
          clusterRole={currentRow}
        />
      )}
      <ListTable
        compact
        className={classes.listTable}
        showPagination
        uniqueIdentifier={'metadata.name'}
        canEditColumns={false}
        searchTargets={searchTargets}
        showCheckboxes={false}
        data={clusterRoles}
        columns={columns}
        visibleColumns={visibleColumns}
        columnsOrder={columnsOrder}
        orderBy={orderBy}
        orderDirection={orderDirection}
      />
    </>
  )
}

export default ClusterRolesList
