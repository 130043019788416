import { makeStyles } from '@material-ui/styles'
import useParams from 'core/hooks/useParams'
import Theme from 'core/themes/model'
import { createUrlWithQueryString } from 'core/plugins/route'
import { head, isEmpty } from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import useReactRouter from 'use-react-router'
import { projectAs } from 'utils/fp'
import PodLogsPage from '../../pods/logs-page'
import Dropdown from 'core/elements/dropdown'

const useStyles = makeStyles((theme: Theme) => ({
  logsPage: {
    marginTop: theme.spacing(2),
  },
  picklist: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: theme.spacing(2),
  },
}))

const EntityLogsPage = ({ pods, loading }) => {
  const classes = useStyles()
  const { match, history, location } = useReactRouter()
  // const route = Route.getCurrentRoute()
  const resourceId = match.params.id
  const queryParams = new URLSearchParams(location.search)
  const podId = queryParams.get('pod')
  const { params, getParamsUpdater } = useParams<{ pod?: unknown }>({})
  const [currPod, setCurrPod] = useState(null)

  const options = useMemo(() => {
    if (!pods || isEmpty(pods)) return []
    return [...projectAs({ label: 'name', value: 'id' }, pods)]
  }, [pods])

  const handlePodSelection = (podId) => {
    getParamsUpdater('pod')(podId)
    setCurrPod(pods.find((p) => p.id === podId))
  }

  const updateUrlWithQueryParams = (podId) => {
    const urlWithQueryParams = createUrlWithQueryString(
      new URL(location.pathname, window.location.origin),
      { id: resourceId, pod: podId },
    )
    history.push(urlWithQueryParams)
  }

  useEffect(() => {
    if (!pods || isEmpty(pods) || currPod) return
    const initialPodId = podId || ((head(pods) as any) || {}).id
    if (!initialPodId) return
    handlePodSelection(initialPodId)
  }, [pods])

  useEffect(() => {
    if (!currPod) return
    updateUrlWithQueryParams(currPod.id)
  }, [currPod])

  useEffect(() => {
    // Remove the query params from the url when the component unmounts
    return () => {
      const { pathname, hash } = history.location
      history.push(`${pathname}${hash}`)
    }
  }, [])

  return (
    <div className={classes.logsPage}>
      <div className={classes.picklist}>
        <Dropdown
          name="Pod"
          label="Pod"
          value={params.pod}
          onChange={handlePodSelection}
          items={options}
          showAll={false}
          compact
        />
      </div>
      <PodLogsPage pod={currPod} loading={loading} />
    </div>
  )
}

export default EntityLogsPage
