import React, { useMemo } from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {
  apiGroups: (string | number | symbol)[]
}

export default function ApiGroupPicklist({ apiGroups, ...rest }: PropsWithAsyncDropdown<Props>) {
  const items = useMemo(
    () =>
      apiGroups.map((apiGroup) => ({
        label: String(apiGroup),
        value: apiGroup,
      })),
    [apiGroups],
  )
  return <AsyncDropdown name="apigroup" label="API Group" items={items} compact showAll {...rest} />
}
