import React, { useCallback } from 'react'
import ListTable from 'core/components/listTable/ListTable'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import SimpleLink from 'core/components/SimpleLink'
import Input from 'core/elements/input/Input'
import useStyles from './useStyles'
import generateTestId from 'utils/test-helpers'
import { profilesHelpUrl } from 'k8s/links'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { makeParamsClustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import Text from 'core/elements/Text'
import { makeParamsCapiClustersSelector } from 'app/plugins/infrastructure/components/clusters/capi/selectors'
import { useMemo } from 'react'
import { listCapiClusters } from 'app/plugins/infrastructure/components/clusters/capi/actions'

const defaultParamsClusterSelector = makeParamsClustersSelector({ healthyClusters: true })
const defaultParamsCapiClusterSelector = makeParamsCapiClustersSelector({
  provisionedClusters: true,
})

const RbacProfileBox = ({ profileName, setWizardContext }) => {
  const classes = useStyles()
  return (
    <FormFieldCard
      title={'RBAC Profile'}
      link={<SimpleLink src={profilesHelpUrl}>Profiles Help</SimpleLink>}
    >
      <Text variant="body2">
        RBAC Profiles allow you to snapshot a cluster and create a 'profile' or template of the
        clusters RBAC policies and then use the profile engine to apply the profile to other
        clusters as a standard.
      </Text>
      <br />
      <Input
        data-testid={generateTestId('profile', 'name', 'search')}
        label={`Profile Name *`}
        value={profileName}
        onChange={(e) => {
          setWizardContext({ profileName: e.target.value })
        }}
      />
    </FormFieldCard>
  )
}

export const columns = [
  { id: 'uuid', display: false },
  { id: 'name', label: 'Name' },
  { id: 'version', label: 'Version' },
  { id: 'cloudProviderType', label: 'Cloud Provider' },
]
const visibleColumns = ['name', 'version', 'cloudProviderType']
const columnsOrder = ['name', 'version', 'cloudProviderType']
const orderBy = 'name'
const orderDirection = 'asc'
const searchTargets = ['name', 'version']

export const BaseClusterForm = ({ wizardContext, setWizardContext }) => {
  const classes = useStyles()

  const { loading: loadingCluster, reload: reloadCluster } = useListAction(listClusters)
  const { loading: loadingCapiClusters, reload: reloadCapiClusters } = useListAction(
    listCapiClusters,
  )
  const qberClusters = useSelector(defaultParamsClusterSelector)
  const capiClusters = useSelector(defaultParamsCapiClusterSelector)

  const data = useMemo(() => [...qberClusters, ...capiClusters], [qberClusters, capiClusters])

  const refetch = useCallback(() => {
    reloadCluster(true)
    reloadCapiClusters(true)
  }, [reloadCluster, reloadCapiClusters])
  const handleSelect = useCallback(
    (row) => {
      setWizardContext({
        baseCluster: row.uuid,
        baseClusterName: row.name,
        roles: {},
        clusterRoles: {},
        roleBindings: {},
        clusterRoleBindings: {},
      })
    },
    [setWizardContext],
  )

  return (
    <>
      <div className={classes.profileContents}>
        <RbacProfileBox
          profileName={wizardContext.profileName}
          setWizardContext={setWizardContext}
        />
        <br />
        <FormFieldCard className={classes.listBox} title={'Select a Base Cluster'}>
          <Text variant="body2">
            Choose the Base Cluster to use as a template of which you can individually select Roles,
            Cluster Roles, Role Bindings and Cluster Role Bindings. Each item can be edited after
            the Profile has been created.
          </Text>
          <br />
          <ListTable
            uniqueIdentifier={'uuid'}
            multiSelection={false}
            canEditColumns={false}
            searchTargets={searchTargets}
            data={data}
            onReload={refetch}
            loading={loadingCluster || loadingCapiClusters}
            columns={columns}
            visibleColumns={visibleColumns}
            columnsOrder={columnsOrder}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onSelect={handleSelect}
            selectColumns={false}
          />
        </FormFieldCard>
      </div>
    </>
  )
}
