import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ListAction from 'core/actions/ListAction'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import Bugsnag from 'utils/bugsnag'
import { ensureArray, pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'

const { qbert } = ApiClient.getInstance()

const k8sEndpointActions = ActionsSet.make<DataKeys.K8sEndpoints>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.K8sEndpoints],
  cacheKey: DataKeys.K8sEndpoints,
})

export const listK8sEndpoints = k8sEndpointActions.add(
  new ListAction<DataKeys.K8sEndpoints, { clusterId: string; namespace?: string }>(
    async (params) => {
      Bugsnag.leaveBreadcrumb('Attempting to get Endpoints', params)
      const { namespace, clusterId } = params
      return someAsync(
        ensureArray(namespace).map((name) => qbert.getK8sEndpoints(clusterId, name)),
      ).then(flatten)
    },
  ),
)

export const createK8sEndpoint = k8sEndpointActions.add(
  new CreateAction<DataKeys.K8sEndpoints, { clusterId: string; namespace: string; body: any }>(
    async ({ clusterId, namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new Endpoint', {
        clusterId,
        namespace,
        body,
      })
      const createdK8sEndpoint = await qbert.createK8sEndpoint(clusterId, namespace, body)
      trackEvent('Create New Endpoint', {
        clusterId,
        namespace,
        name: pathStr('metadata.name', createdK8sEndpoint),
      })
      return createdK8sEndpoint
    },
  ),
)

export const updateK8sEndpoint = k8sEndpointActions.add(
  new UpdateAction<
    DataKeys.K8sEndpoints,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Endpoint', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn = requestType === 'patch' ? qbert.patchK8sEndpoint : qbert.updateK8sEndpoint
    const updatedK8sEndpoint = await updateFn({
      clusterId,
      namespace,
      name,
      body,
      contentType,
    })
    trackEvent('Update Endpoint', { clusterId, namespace, name })
    return updatedK8sEndpoint
  }),
)

export const deleteK8sEndpoint = k8sEndpointActions.add(
  new DeleteAction<DataKeys.K8sEndpoints, { clusterId: string; namespace: string; name: string }>(
    async ({ clusterId, namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Endpoint', {
        clusterId,
        namespace,
        name,
      })
      await qbert.deleteK8sEndpoint(clusterId, namespace, name)
    },
  ),
)
