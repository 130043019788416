import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {}

// values match with moment's add/subtract API for use in actions.js
const items = [
  { label: 'High to Low', value: 'highToLow' },
  { label: 'Low to High', value: 'lowToHigh' },
]

export default function AlarmOverviewOrderPicklist(props: PropsWithAsyncDropdown<Props>) {
  return (
    <AsyncDropdown
      selectFirst
      name="order"
      label="Sort"
      compact
      showAll={false}
      items={items}
      {...props}
    />
  )
}
