export enum RepositoryType {
  Public = 'public',
  Private = 'private',
}

export const repositoryOptions = [
  { label: 'Public', value: RepositoryType.Public },
  { label: 'Private', value: RepositoryType.Private },
]

export interface IRepositoriesSelector extends Repository {
  id: Repository['repo']['ID']
  name: Repository['repo']['name']
  url: Repository['repo']['url']
  username: Repository['repo']['username']
  password: Repository['repo']['password']
  private: Repository['repo']['private']
  type: RepositoryType.Public | RepositoryType.Private
  clusterIds: string[]
  numClusters: number
  autoAttach: boolean
}

export interface Repository {
  repo: RepositoryMetadata
  clusters: ClusterId[]
}

export interface RepositoryMetadata {
  ID: number
  name: string
  url: string
  username: string
  password: string
  certFile: string
  keyFile: string
  caFile: string
  forceUpdate: boolean
  allowDeprecatedRepos: boolean
  insecureSkipTLSverify: boolean
  deprecatedNoUpdate: boolean
  private: boolean
}

export interface ClusterId {
  cluster_uuid: string
}
