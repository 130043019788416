export enum IHostConfigurationDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface HostNetworkTemplatesResponse {
  apiVersion: string
  items: HostNetworkTemplate[]
  kind: string
  metadata: HostNetworkTemplatesResponseMetadata
}

export interface HostNetworkTemplate {
  apiVersion: string
  kind: string
  metadata: ItemMetadata
  spec: Spec
  // Below are the properties added by us with convertResource
  created: string
  clusterId: string
  id: string
  name: string
  namespace: string
}

export interface ItemMetadata {
  creationTimestamp: string
  finalizers: string[]
  generation: number
  name: string
  namespace: string
  resourceVersion: string
  uid: string
}

export interface Spec {
  ovsConfig: OvsConfig[]
  interfaceConfig?: InterfaceConfig[]
  nodeSelector?: NodeSelector
}

export interface NodeSelector {
  [key: string]: string
}

export interface InterfaceConfig {
  mtu?: number
  name: string
  ipv4?: IPv4
  ipv6?: IPv6
  vlan?: VlanConfig
}

export interface VlanConfig {
  id: number
  name?: string
}

export interface IPv4 {
  address?: string[]
}

export interface IPv6 {
  address?: string[]
}

export interface OvsConfig {
  bridgeName: string
  nodeInterface: string
  dpdk?: string
  params?: OvsConfigParams
}

export interface OvsConfigParams {
  mtu_request?: string
  bond_mode?: string
  lacp?: string
}

export interface HostNetworkTemplatesResponseMetadata {
  continue: string
  resourceVersion: string
}

export interface IHostNetworkTemplateSelector extends HostNetworkTemplate {
  clusterName: string
  age: string
  configTypes: string[]
}
