import { makeStyles } from '@material-ui/styles'
import DocumentMeta from 'core/components/DocumentMeta'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import SimpleLink from 'core/components/SimpleLink'
import useReactRouter from 'use-react-router'
import useDataLoader from 'core/hooks/useDataLoader'
import PicklistField from 'core/components/validatedForm/DropdownField'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { isEmpty } from 'ramda'
import { allKey, codeMirrorOptions } from 'app/constants'
import { requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import ModalForm from 'core/elements/modal/ModalForm'
import Column from 'core/containers/Column'
import AddNewNamespaceDialog from '../../AddNewNamespaceDialog'
import NamespacePicklistField from 'app/plugins/infrastructure/components/clusters/form-components/NamespacePicklistField'
import AppVersionPicklistField from '../../AppVersionPicklistField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import { filterConnectedClusters, getAppVersionPicklistOptions } from '../../helpers'
import { appDetailsLoader } from '../actions'
import useListAction from 'core/hooks/useListAction'
import { listRepositories } from '../../repositories/new-actions'
import { useSelector } from 'react-redux'
import { repositoriesSelector } from '../../repositories/selectors'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deployApp } from '../../deployed-apps/new-actions'

const codeMirrorValidations = [requiredValidator, yamlValidator]

export default function DeployAppModalForm() {
  const classes = useStyles()
  const { match, history } = useReactRouter()
  const { name, repository: repositoryName } = match.params
  const fileInputRef = useRef(null)
  const formFieldSetter = useRef(null)
  const fieldSetter = (setField) => {
    formFieldSetter.current = { setField }
  }
  const [fileUploadError, setFileUploadError] = useState(null)
  const [showNamespaceDialog, setShowNamespaceDialog] = useState(false)
  const [[appDetail = {}], loadingAppDetail] = useDataLoader(appDetailsLoader, {
    name,
    repository: repositoryName,
  })
  const { loading: loadingRepositories } = useListAction(listRepositories)
  const repositories = useSelector(repositoriesSelector)
  const repository = useMemo(
    () => repositories.find((repository) => repository.name === repositoryName),
    [repositories, repositoryName],
  )
  const { update: deploy, updating: deploying, error } = useUpdateAction(deployApp)

  const initialContext = useMemo(
    () => ({
      ...appDetail,
      values: JSON.stringify(appDetail?.values, null, 1),
      useDefaultValues: true,
    }),
    [appDetail],
  )

  useEffect(() => {
    if (isEmpty(appDetail) || !formFieldSetter?.current) return
    formFieldSetter.current.setField('version')(appDetail?.version)
    formFieldSetter.current.setField('values')(JSON.stringify(appDetail?.values, null, 1))
  }, [appDetail])

  const appVersions = useMemo(() => getAppVersionPicklistOptions(appDetail.Versions), [appDetail])

  const handleSubmit = async (val) => {
    const { deploymentName, clusterId, namespace, version, values: values, useDefaultValues } = val

    const vals = useDefaultValues ? JSON.stringify(appDetail?.values) : values

    const { success } = await deploy({
      clusterId,
      namespace,
      deploymentName: deploymentName.toLowerCase(),
      repository: repositoryName,
      chartName: name,
      version,
      values: vals,
    })
    if (!success) return
    history.push(routes.apps.list.path())
  }

  const closeDialog = () => {
    setShowNamespaceDialog(false)
  }

  const openFileBrowser = () => {
    fileInputRef.current.click()
  }

  const handleFileUpload = (setFieldValue) => (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = function() {
      setFieldValue('values')(reader.result)
      setFieldValue('useDefaultValues')(false)
    }
    reader.onerror = function() {
      setFileUploadError({ title: 'File Upload Error', message: 'Cannot read file' })
    }

    reader.readAsText(file)
  }

  // Filter the clusters list and return only the clusters that are connected to this repository
  const filterClusters = useCallback(
    (clusters) => filterConnectedClusters(repository?.clusterIds, clusters),
    [repository?.clusterIds],
  )

  return (
    <>
      <DocumentMeta title="Deploy Application" />
      <ModalForm
        route={routes.apps.deploy}
        title={`Deploy ${name}`}
        initialValues={initialContext}
        onClose={() => history.push(routes.apps.list.path())}
        submitting={deploying}
        onSubmit={handleSubmit}
        submitTitle="Deploy"
        loading={loadingAppDetail || loadingRepositories}
        fieldSetter={fieldSetter}
        error={error || fileUploadError}
        clearOnSubmit
      >
        {({ setFieldValue, values }) => {
          const noClusterSelected = !values?.clusterId || values.clusterId === allKey
          return (
            <>
              {showNamespaceDialog && (
                <AddNewNamespaceDialog clusterId={values.clusterId} onClose={closeDialog} />
              )}
              <FormFieldSection title="Deployment Info" step={1}>
                <Column>
                  <TextField id="deploymentName" label="Deployment Name" required />
                  <PicklistField
                    DropdownComponent={ClusterPicklist}
                    compact={false}
                    id="clusterId"
                    label="Cluster"
                    onChange={setFieldValue('clusterId')}
                    filterFn={filterClusters}
                    showAll={false}
                    required
                  />
                  <div className={classes.row}>
                    <NamespacePicklistField
                      compact={false}
                      clusterId={values.clusterId}
                      showAll={false}
                      required
                    />
                    <Button
                      type="button"
                      disabled={noClusterSelected}
                      onClick={() => setShowNamespaceDialog(true)}
                    >
                      Add New Namespace
                    </Button>
                  </div>

                  <AppVersionPicklistField options={appVersions} />
                </Column>
              </FormFieldSection>
              <FormFieldSection
                step={2}
                className={classes.form}
                title="Values"
                link={
                  <>
                    <SimpleLink src="" onClick={openFileBrowser}>
                      Upload a .yaml file
                    </SimpleLink>
                    <input
                      type="file"
                      id="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      accept=".yaml"
                      onChange={handleFileUpload(setFieldValue)}
                    />
                  </>
                }
              >
                <CodeMirror
                  id="values"
                  value={values.values}
                  onChange={(values) => {
                    setFieldValue('values')(values)
                    setFieldValue('useDefaultValues')(false)
                  }}
                  variant="light"
                  options={codeMirrorOptions}
                  validations={codeMirrorValidations}
                />
                <CheckboxField
                  id="useDefaultValues"
                  value={values.useDefaultValues}
                  label="Use default structured values"
                  onChange={(checked) => {
                    setFieldValue('values')(checked ? initialContext.values : '')
                    setFieldValue('useDefaultValues')(checked)
                  }}
                />
              </FormFieldSection>
            </>
          )
        }}
      </ModalForm>
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  deployAppForm: {
    marginTop: theme.spacing(3),
    gridGap: theme.spacing(4),
  },
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  fields: {
    display: 'grid',
    gridColumnGap: theme.spacing(3),
    marginBottom: theme.spacing(4),
    gridTemplateColumns: '1fr 1fr',
    '& .MuiFormControl-root.validatedFormInput': {
      width: '100%',
    },
  },
  clusterField: {
    gridColumnStart: 1,
    gridColumnEnd: 1,
  },
  namespaceField: {
    gridColumnStart: 2,
    gridColumnEnd: 2,
  },
  logoContainer: {
    borderRadius: '2px',
    border: `solid 0.5px ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey['000'],
    width: 240,
    height: 140,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1.5, 0, 0.5, 0),
    '& img': {
      maxWidth: 160,
      maxHeight: 100,
    },
  },
  form: {
    '& .form-field-card-requirementsTitle': {
      marginBottom: 8,
    },
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '220px 164px',
    alignItems: 'end',
    gridGap: theme.spacing(2),
    minWidth: 'auto',
    '& .dropdown-container': {
      width: 'auto',
    },
  },
}))
