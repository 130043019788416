import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { cloudProviderTypes } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import { capitalizeString } from 'utils/misc'
import DryRunDialog from './DryRunDialog'
import { customValidator, requiredValidator } from 'core/utils/fieldValidators'
import { indexBy, path } from 'ramda'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { makeParamsClustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { listRbacProfiles, getDriftAnalysis } from '../new-actions'
import { rbacProfilesSelector } from '../selectors'
import { useSelector } from 'react-redux'
import { listCapiClusters } from 'app/plugins/infrastructure/components/clusters/capi/actions'
import { makeParamsCapiClustersSelector } from 'app/plugins/infrastructure/components/clusters/capi/selectors'
import { listAllClusterAddons } from 'app/plugins/infrastructure/components/clusters/cluster-addons/new-actions'
import { allKey } from 'app/constants'

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  largeTopMargin: {
    marginTop: theme.spacing(2),
  },
  checkIcon: {
    color: theme.palette.green[500],
  },
}))

interface Props {
  wizardContext: any
  setWizardContext: any
  onNext: any
  handleNext: any
  setSubmitting: any
}

export const renderActiveProfile = (hasBinding) => <span>{hasBinding ? 'Yes' : 'No'}</span>

const CheckIcon = () => {
  const classes = useStyles({})
  return (
    <FontAwesomeIcon className={classes.checkIcon} size="md" solid>
      check-circle
    </FontAwesomeIcon>
  )
}

export const renderProfileAgent = (enabled, cluster) => {
  // Add link to install back in after profile agent is in addon manager
  // const canInstall = compareVersions(cluster.kubeRoleVersion, '1.20') >= 0
  return (
    <>
      {enabled ? (
        <>
          <CheckIcon />
          <span>Installed</span>
        </>
      ) : (
        <span>Not Installed</span>
      )}
    </>
  )
}

const columns = [
  { id: 'name', label: 'Name' },
  { id: 'kubeRoleVersion', label: 'Version' },
  {
    id: 'cloudProviderType',
    label: 'Cloud Provider',
    render: (type) => cloudProviderTypes[type] || capitalizeString(type),
  },
  {
    id: 'hasBinding',
    label: 'Active Profile',
    render: renderActiveProfile,
  },
  {
    id: 'enableProfileAgent',
    label: 'Profile Agent',
    render: renderProfileAgent,
  },
]

const profileAgentInstalled = (cluster) => !!cluster.enableProfileAgent

const clusterValidations = [
  requiredValidator,
  customValidator((clusters) => {
    if (!clusters.length) {
      return false
    }
    // Even though only one selection allowed, ListTableField always returns as array
    const cluster = clusters[0]
    return !cluster.hasBinding
  }, 'Selected cluster may not have an active profile binding. You can delete the profile binding by managing bindings on the RBAC profiles view.'),
]

const defaultParamsClusterSelector = makeParamsClustersSelector()
const defaultParamsCapiClusterSelector = makeParamsCapiClustersSelector()

const SelectClusterForm = ({
  wizardContext,
  setWizardContext,
  onNext,
  handleNext,
  setSubmitting,
}: Props) => {
  const classes = useStyles({})
  const [showDialog, setShowDialog] = useState(true)
  const [bindingName, setBindingName] = useState('')
  const validatorRef = useRef(null)

  const { loading: profilesLoading } = useListAction(listRbacProfiles)

  const { loading: loadingClusters, reload: reloadClusters } = useListAction(listClusters)
  const { loading: loadingCapiClusters, reload: reloadCapiClusters } = useListAction(
    listCapiClusters,
  )
  //need to get addons seperately for capi clusters
  const { loading: loadingClusterAddons, reload: reloadAddons } = useListAction(
    listAllClusterAddons,
  )
  const profiles = useSelector(rbacProfilesSelector)
  const qbertClusters = useSelectorWithParams(defaultParamsClusterSelector, {
    healthyClusters: true,
  })
  const capiClusters = useSelectorWithParams(defaultParamsCapiClusterSelector, {
    provisionedClusters: true,
  })

  const clusters = [...qbertClusters, ...capiClusters]

  const mappedClusters = useMemo(() => {
    if (clusters.length && profiles.length) {
      const allBindings = profiles
        .map((profile) => {
          return profile.bindings
        })
        .flat()
      const bindingsByClusterId = indexBy(
        path<string>(['spec', 'clusterRef']),
        allBindings,
      )
      return clusters.map((cluster) => {
        return {
          ...cluster,
          hasBinding: !!bindingsByClusterId[cluster.uuid],
        }
      })
    }
    return clusters
  }, [clusters, profiles])

  const setupValidator = (validate) => {
    validatorRef.current = { validate }
  }

  const submitStep = useCallback(async () => {
    const isValid = await validatorRef.current.validate()
    if (!isValid) {
      return false
    }
    setSubmitting(true)
    try {
      const response: any = await getDriftAnalysis({
        cluster: wizardContext.clusters[0],
        profileName: wizardContext.profileName,
      })
      setBindingName(response.metadata?.name)
      setSubmitting(false)
      setShowDialog(true)
    } catch (err) {
      setSubmitting(false)
      return false
    }
    return false
  }, [wizardContext])

  useEffect(() => {
    // submitStep will always return false and prevent the user from
    // proceeding to the next step because we only want to move on to
    // the next step when the user closes the dialog
    onNext(submitStep)
  }, [submitStep])

  const proceed = () => {
    // Move on to the next step
    setShowDialog(false)
    onNext()
    handleNext()
  }

  const refresh = () => {
    reloadClusters(true)
    reloadCapiClusters(true)
    reloadAddons(true)
  }

  return (
    <>
      <ValidatedForm
        classes={{ root: classes.validatedFormContainer }}
        initialValues={wizardContext}
        onSubmit={setWizardContext}
        triggerSubmit={setupValidator}
        title="Select a Cluster to Deploy to"
      >
        <Text variant="body2">
          Choose a cluster to deploy this RBAC profile to. The cluster will use the RBAC permissions
          that currently belong to the RBAC profile. If you make changes to a profile, you will need
          to redeploy the profile to any clusters you wish to use the updated permissions.
        </Text>
        <Text variant="body2" className={classes.largeTopMargin}>
          RBAC profiles may only be deployed onto clusters with Kubernetes version 1.2+ that have
          Profile Agent installed.
        </Text>
        <ListTableField
          id="clusters"
          data={mappedClusters}
          loading={
            loadingClusters || profilesLoading || loadingCapiClusters || loadingClusterAddons
          }
          columns={columns}
          onChange={(value) => setWizardContext({ clusters: value })}
          value={wizardContext.clusters}
          checkboxCond={profileAgentInstalled}
          uniqueIdentifier="uuid"
          validations={clusterValidations}
          onReload={refresh}
        />
        {showDialog && bindingName && (
          <DryRunDialog
            handleClose={() => setShowDialog(false)}
            onFinish={proceed}
            bindingName={bindingName}
            setWizardContext={setWizardContext}
          />
        )}
      </ValidatedForm>
    </>
  )
}

export default SelectClusterForm
