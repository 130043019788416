import React, { useEffect, useMemo, useState } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import { allKey, LoadingGifs } from 'app/constants'
import AlarmOverviewOrderPicklist from './AlarmOverviewOrderPicklist'
import SeverityPicklist from '../alarms/SeverityPicklist'
import Progress from 'core/components/progress/Progress'
import DocumentMeta from 'core/components/DocumentMeta'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listAlerts } from 'k8s/components/alarms/new-actions'
import { alertsSelector } from 'k8s/components/alarms/selectors'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { useSelector } from 'react-redux'
import ClusterAlarmCards from './ClusterAlarmCards'
import Button from 'core/elements/button'
import SearchBar from 'core/components/SearchBar'
import { filterBySearch } from 'utils/misc'
import { makeParamsAllClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import useParams from 'core/hooks/useParams'
import InferActionParams from 'core/actions/InferActionParams'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  clusterSearch: {
    gap: 0,
  },
  overview: {
    display: 'grid',
    gridGap: 32,
    minHeight: 200,
    gridTemplateRows: 'max-content 1fr',
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filtersGroup: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: 16,
  },
}))

type ActionParams = InferActionParams<typeof listAlerts>

type Params = ActionParams & {
  severity?: string
  order?: string
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  severity: allKey,
  clusterId: '',
  order: 'highToLow',
}

const allClustersParams = {
  prometheusClusters: true,
  orderBy: 'name',
}

const defaultParamsAllClusterSelector = makeParamsAllClustersSelector(allClustersParams)

const AlarmOverviewPage = () => {
  const classes = useStyles({})
  const { params, updateParams, getParamsUpdater } = useParams(defaultParams)
  const [search, setSearch] = useState('')

  const { message, loading: loadingAlarms, reload } = useListAction(listAlerts, {
    params,
    requiredParams,
  })
  const alarms = useSelectorWithParams(alertsSelector, {
    ...params,
    useGlobalParams: false,
  })

  const { loading: clustersLoading } = useListAction(listClusters)
  const { loading: importedClustersLoading } = useListAction(listImportedClusters)

  const allClusters = useSelector(defaultParamsAllClusterSelector)

  useEffect(() => {
    // Clusters need to be loaded up before listAlerts action is made, otherwise,
    // won't be able to get the cluster IDs
    if (!clustersLoading && !importedClustersLoading) {
      updateParams({ clusterId: allKey })
    }
  }, [clustersLoading, importedClustersLoading])

  const loading = loadingAlarms || clustersLoading || importedClustersLoading

  const clustersWithAlarms = useMemo(() => {
    if (!loading) {
      const result = allClusters.map((cluster) => {
        const clusterAlarms = alarms.filter((alarm) => {
          return (
            alarm.clusterId === cluster.uuid &&
            ['fatal', 'critical', 'warning'].includes(alarm.severity)
          )
        })
        return {
          ...cluster,
          numAlarms: clusterAlarms.length,
          allAlarms: clusterAlarms,
          fatalAlarms: clusterAlarms.filter((alarm) => alarm.severity === 'fatal'),
          criticalAlarms: clusterAlarms.filter((alarm) => alarm.severity === 'critical'),
          warningAlarms: clusterAlarms.filter((alarm) => alarm.severity === 'warning'),
        }
      })
      return result
    }
    return []
  }, [alarms, allClusters, loading])

  const filterBySeverity = (alarms, params) => {
    return alarms.filter((alarm) => {
      if (params.severity === allKey) {
        return true
      } else if (params.severity === 'fatal') {
        return alarm.fatalAlarms.length
      } else if (params.severity === 'critical') {
        return alarm.criticalAlarms.length
      } else if (params.severity === 'warning') {
        return alarm.warningAlarms.length
      }
      return true
    })
  }

  const sortAlarms = (alarms, params) => {
    if (params.order === 'highToLow') {
      return alarms.sort((a, b) => b.numAlarms - a.numAlarms)
    } else if (params.order === 'lowToHigh') {
      return alarms.sort((a, b) => a.numAlarms - b.numAlarms)
    }
    return alarms
  }

  const filteredClustersWithAlarms = useMemo(() => {
    if (clustersWithAlarms) {
      // Make these a pipe of filter functions if possible?
      const searchClusters = search
        ? filterBySearch(clustersWithAlarms, ['name'], search)
        : clustersWithAlarms
      return sortAlarms(filterBySeverity(searchClusters, params), params)
    }
    return []
  }, [clustersWithAlarms, search, params.order, params.severity])

  return (
    <Progress
      loading={loading}
      message={message}
      overlay
      renderContentOnMount
      loadingImage={LoadingGifs.BluePinkTiles}
    >
      <DocumentMeta title="Alarms Overview" breadcrumbs />
      <div className={classes.overview}>
        <div className={classes.filters}>
          <div className={classes.filtersGroup}>
            <Button variant="secondary" icon="rotate" onClick={reload}>
              Refresh
            </Button>
            <SearchBar
              searchTerm={search}
              onSearchChange={setSearch}
              className={classes.clusterSearch}
            />
          </div>
          <div className={classes.filtersGroup}>
            <SeverityPicklist
              selectFirst={false}
              onChange={getParamsUpdater('severity')}
              value={params.severity}
            />
            <AlarmOverviewOrderPicklist onChange={getParamsUpdater('order')} value={params.order} />
          </div>
        </div>
        <ClusterAlarmCards clusters={filteredClustersWithAlarms} />
      </div>
    </Progress>
  )
}

export default AlarmOverviewPage
