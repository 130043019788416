import React from 'react'
import ProductInfoPage from 'core/containers/ProductInfoPage'
import Text from 'core/elements/Text'
import CardButton from 'core/elements/button/CardButton'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import useToggler from 'core/hooks/useToggler'
// import InstallKubevirtForm from './InstallKubevirtForm'
import { trackEvent } from 'utils/tracking'

import { KubeVirtLogo } from 'app/plugins/kubevirt/logo'

export default function NetworkingInfoLandingPage() {
  const { history } = useReactRouter()
  const [modalOpen, toggleModalOpen] = useToggler(false)
  return (
    <>
      {/* <InstallKubevirtForm open={modalOpen} onClose={toggleModalOpen} /> */}
      <ProductInfoPage
        title="Luigi Plugin Disabled"
        footerTitle="Choose where to enable Luigi"
        icon={<KubeVirtLogo width="240" height="240" />}
        actions={[
          <CardButton
            key="luigi-choose-new"
            icon="plus"
            title="Create a New Cluster"
            message="Configure Add-ons to enable Luigi"
            onClick={() => {
              console.log('clicked create new')
              // trackEvent('Clicked Create New Kubevirt Cluster')
              // history.push(routes.cluster.add.root.path())
            }}
          />,
          <CardButton
            key="luigi-choose-existing"
            icon="check-double"
            title="Select an Existing Cluster"
            message="Add Luigi to a selected cluster"
            onClick={() => {
              console.log('clicked select existing')
              // trackEvent('Clicked Add Kubevirt Configuration to Selected Cluster')
              // toggleModalOpen()
            }}
          />,
        ]}
      >
        <Text variant="body1">
          Luigi is an operator that helps with simplifying the configuration of advanced technology
          stacks and provides feature detection through "NFD".
        </Text>
        <Text variant="body1">Learn more on advanced network setup</Text>
      </ProductInfoPage>
    </>
  )
}
