import React, { useEffect, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import { createNetworkPlugin } from './actions'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import useListAction from 'core/hooks/useListAction'
import { listNetworkPlugins } from './actions'
import { networkPluginsSelector } from './selectors'
import { makeParamsAllClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import NetworkPluginParamFields from './NetworkPluginParamFields'
import usePluginRouter from 'core/hooks/usePluginRouter'

const paramsAllClusterSelector = makeParamsAllClustersSelector()

type NetworkPluginParams = {
  clusterId: string | string[]
}

const defaultNetworkPluginParams: NetworkPluginParams = {
  clusterId: '',
}

const clusterParams = {
  healthyClusters: true,
  advancedNetworkingClusters: true,
}

export default function EnablePluginsModal({ addRoute }) {
  const { history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'

  // Params for setting clusterIds for getting network plugins
  // across all clusters
  const { params: pluginParams, updateParams: updatePluginParams } = useParams(
    defaultNetworkPluginParams,
  )
  const [loadedNetworkingClusters, setLoaded] = useState(false)

  const { loading: clustersLoading } = useListAction(listClusters)
  const clusters = useSelectorWithParams(paramsAllClusterSelector, clusterParams)

  useEffect(() => {
    // Clusters need to be loaded up before other list actions are made, otherwise
    // won't be able to get the cluster IDs for networking clusters
    if (loadedNetworkingClusters) {
      return
    }
    if (!clustersLoading) {
      const clusterIds = clusters.map((cluster) => cluster.uuid)
      updatePluginParams({ clusterId: clusterIds })
      setLoaded(true)
    }
  }, [clustersLoading, clusters, loadedNetworkingClusters])

  const { loading: networkPluginsLoading } = useListAction(listNetworkPlugins, {
    params: pluginParams,
    requiredParams: ['clusterId'],
    // Do not want filterFn in clusterpicklist to filter until loaded
    initialLoadingState: true,
  })
  const networkPlugins = useSelectorWithParams(networkPluginsSelector, {
    ...pluginParams,
    useGlobalParams: false,
  })

  const defaultParams = {
    clusterId: null,
    hostPlumber: true,
    multusCni: true,
    nodeFeatureDiscovery: false,
    sriov: false,
    ovs: false,
    dpdk: false,
    platform9Dhcp: false,
    whereabouts: true,
  }

  const { params, updateParams, setParams } = useParams<{
    clusterId: string
    hostPlumber: boolean
    multusCni: boolean
    nodeFeatureDiscovery: boolean
    sriov: boolean
    ovs: boolean
    dpdk: boolean
    platform9Dhcp: boolean
    whereabouts: boolean
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(createNetworkPlugin)

  const submitForm = async () => {
    const body = {
      apiVersion: 'plumber.k8s.pf9.io/v1',
      kind: 'NetworkPlugins',
      metadata: {
        name: 'network-plugins',
      },
      spec: {
        plugins: {
          // TODO: Still waiting on backend for where to add DPDK
          hostPlumber: params?.hostPlumber ? {} : undefined,
          nodeFeatureDiscovery: params?.nodeFeatureDiscovery ? {} : undefined,
          multus: params?.multusCni ? {} : undefined,
          whereabouts: params?.whereabouts ? {} : undefined,
          sriov: params?.sriov ? {} : undefined,
          ovs: params?.ovs ? {} : undefined,
          dhcpController: params?.platform9Dhcp ? {} : undefined,
        },
      },
    }
    const { success } = await update({
      clusterId: params?.clusterId,
      namespace: 'luigi-system',
      body,
    })
    if (success) handleClose()
  }

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes[routePath].plugins.path())
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Create Plugin Configuration`}
      onSubmit={submitForm}
      onClose={handleClose}
      loading={networkPluginsLoading}
      submitting={updating}
      error={error}
      submitTitle={`Create Plugin Configuration`}
      maxWidth={528}
    >
      <NetworkPluginParamFields
        method="create"
        params={params}
        updateParams={updateParams}
        clustersLoading={clustersLoading}
        networkPlugins={networkPlugins}
        networkPluginsLoading={networkPluginsLoading}
      />
    </ModalForm>
  )
}
