import React from 'react'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Tooltip from 'core/elements/tooltip'
import { switchCase } from 'utils/fp'

interface Props {
  type: string
  count: number
}

interface StyleProps {
  type: string
}

const getAlarmSeverityColor = (theme, key) => ({ type }) => {
  const colorSwatch = switchCase(
    {
      fatal: theme.components.badge.error,
      critical: theme.components.badge.danger,
      warning: theme.components.badge.warning,
    },
    theme.components.badge.unknown,
  )(type)
  return colorSwatch[key]
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  button: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: 4,
    transition: 'background .2s ease',
    background: getAlarmSeverityColor(theme, 'background'),
    borderRadius: 4,
    padding: '6px 10px',
    '& .alarm-button-content': {
      fontWeight: '600',
      color: getAlarmSeverityColor(theme, 'color'),
    },
  },
  // The engine-warning icon is wider, set a width and center align them
  // to have them match
  alarmIcon: {
    width: 20,
    textAlign: 'center',
  },
}))

const alarmIcons = {
  fatal: 'skull-crossbones',
  critical: 'engine-warning',
  warning: 'exclamation-triangle',
}

export default function AlarmCountButton({ count, type }: Props) {
  const classes = useStyles({ type })
  return (
    <Tooltip message={`${count} ${type}`} align={{ horizontal: 'middle', vertical: 'bottom' }}>
      <div className={classes.button}>
        <FontAwesomeIcon
          solid
          className={clsx(classes.alarmIcon, 'alarm-button-content')}
          size="md"
        >
          {alarmIcons[type]}
        </FontAwesomeIcon>
        <span className="alarm-button-content">{count}</span>
      </div>
    </Tooltip>
  )
}
