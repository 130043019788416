import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import moment from 'moment'
import { flatten, pluck } from 'ramda'
import { someAsync } from 'utils/async'
import Bugsnag from 'utils/bugsnag'
import { trackEvent } from 'utils/tracking'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import { makeParamsAllClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'

const { qbert } = ApiClient.getInstance()

export const alertActions = ActionsSet.make<DataKeys.Alerts>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.Alerts,
  cacheKey: DataKeys.Alerts,
})

export const listAlerts = alertActions.add(
  new ListAction<DataKeys.Alerts, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to load alerts', params)
    return qbert.getAlertManagerAlerts(params.clusterId)
  }).addDependency(DataKeys.AlertRules),
)

// Used to calculate the timestamps on the chart
// Each period (represented by key name) is split into
// 6 equal intervals (represented by the value)
// h is hours, m is minutes
const timestampSteps = {
  // for use in moment.add
  '24.h': [4, 'h'],
  '12.h': [2, 'h'],
  '6.h': [1, 'h'],
  '3.h': [30, 'm'],
  '1.h': [10, 'm'],
}
const defaultAllClusterSelector = makeParamsAllClustersSelector()

export const timeSeriesAlertActions = ActionsSet.make<DataKeys.AlertsTimeSeries>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.AlertsTimeSeries,
  cacheKey: DataKeys.AlertsTimeSeries,
  cache: false,
})

// no cache & data selector indexing not supported for this -- pass all
// cluster IDs into the action
export const listTimeSeriesAlerts = timeSeriesAlertActions.add(
  new ListAction<DataKeys.AlertsTimeSeries, { chartTime: string; clusterId: string[] }>(
    async ({ chartTime, ...params }) => {
      Bugsnag.leaveBreadcrumb('Attempting to load time series alerts', { chartTime, ...params })
      const timeNow = moment().unix()
      const [number, period] = chartTime.split('.')
      // @ts-ignore not sure how to fix TS complaining about moment
      const timePast = moment
        .unix(timeNow)
        .subtract(number, period)
        .unix()
      const step = timestampSteps[chartTime].join('')
      const clusterId = params.clusterId

      return someAsync(
        clusterId.map((clusterUuid) =>
          qbert.getPrometheusAlertsOverTime(clusterUuid, timePast, timeNow, step),
        ),
      ).then(flatten)
    },
  ),
)

export const silenceActions = ActionsSet.make<DataKeys.Silences>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.Silences,
  cacheKey: DataKeys.Silences,
})

export const listSilences = silenceActions.add(
  new ListAction<DataKeys.Silences, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get alert mananger silences', params)
    return qbert.getAlertManagerSilences(params.clusterId)
  }),
)

export const createSilence = silenceActions.add(
  new CreateAction<DataKeys.Silences, { clusterId: string; body: any }>(
    async ({ clusterId, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create alert manager silence', { clusterId, ...body })
      const result = await qbert.createAlertManagerSilence(clusterId, body)
      trackEvent(' Create Alert Manager Silence', { clusterId })
      return result
    },
  ),
)
