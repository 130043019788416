import { RootState } from 'app/store'
import { Selector } from '@reduxjs/toolkit'
import { complement, flatten, isNil, propSatisfies } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { findClusterName } from 'k8s/util/helpers'
import { IStorageClassSelector } from './model'
import { isDefaultStorageClass } from './helpers'
import { pvByStorageClassSelector } from '../persistent-volume/selectors'
import { pvcByPVSelector } from '../persistent-volume-claims/selectors'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { durationBetweenDates } from 'utils/misc'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'

export const storageClassSelector: Selector<
  RootState,
  IStorageClassSelector[]
> = createSharedSelector(
  getDataSelector<DataKeys.StorageClasses>(DataKeys.StorageClasses, ['clusterId'], ['clusterId']),
  pvByStorageClassSelector,
  pvcByPVSelector,
  allClustersSelector,
  (rawStorageClasses, pvByStorageClass, pvcByPv, allClusters) => {
    return rawStorageClasses
      .map((storageClass) => {
        const pvs = pvByStorageClass.get(storageClass?.name) || []
        const pvcs = flatten(pvs.map((pv) => pvcByPv.get(pv?.name) || []))
        return {
          ...storageClass,
          clusterName: findClusterName(allClusters, storageClass.clusterId),
          isDefault: isDefaultStorageClass(storageClass),
          kind: 'StorageClass',
          allowVolumeExpansion: !!storageClass?.allowVolumeExpansion, // Force boolean here
          persistentVolumes: pvs,
          persistentVolumeClaims: pvcs,
          labels: storageClass?.metadata?.labels,
          annotations: storageClass?.metadata?.annotations,
          age: durationBetweenDates({ labels: ['d'] })(storageClass?.metadata?.creationTimestamp),
        }
      })
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)
