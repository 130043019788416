import React, { ComponentProps } from 'react'
import { projectAs } from 'utils/fp'
import withFormContext from 'core/components/validatedForm/withFormContext'
import MultiSelect from 'core/components/MultiSelect'
import { listMngmGroups } from 'app/plugins/account/components/ssoManagement/groups/new-actions'
import useListAction from 'core/hooks/useListAction'
import { makeGroupsSelector } from 'app/plugins/account/components/ssoManagement/groups/selectors'
import { useSelector } from 'react-redux'

export default withFormContext<
  string[],
  Omit<ComponentProps<typeof MultiSelect>, 'label' | 'options' | 'loading'>
>(function GroupMultiSelect(props) {
  const { loading: loadingGroups } = useListAction(listMngmGroups)
  const groups = useSelector(
    makeGroupsSelector({
      orderBy: 'name',
      orderDirection: 'asc',
    }),
  )

  const groupsList = projectAs({ label: 'name', value: 'name' }, groups)
  return <MultiSelect {...props} label="Groups" options={groupsList} loading={loadingGroups} />
})
