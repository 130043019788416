import { DashBoardStatusCardTypes } from './card-templates'

import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { omit } from 'ramda'

export const useDashboardPageStyles = makeStyles<Theme>((theme) => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',

    minWidth: 'max-content',
    maxWidth: 'max-content',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: theme.palette.grey['000'],
    padding: theme.spacing(2, 4),
    border: `solid 1px ${theme.palette.grey[300]}`,
    minHeight: 75,
  },
}))

export const filterReportsWithUserRole = (reports, role) => {
  const mappedReports = reports.map((report) => {
    // No permissions property means no restrictions
    if (!report?.permissions) {
      return report
    }
    // remove the add action when not permitted to
    return report.permissions.includes(role) ? report : { ...report, addRoute: '' }
  })
  const filteredReports = mappedReports.filter((report) => {
    if (!report.overallPermissions) {
      return report
    }
    return report.overallPermissions.includes(role)
  })
  return filteredReports.map(omit(['permissions', 'overallPermissions']))
}

export const baseCards = [
  DashBoardStatusCardTypes.Pod,
  DashBoardStatusCardTypes.Deployment,
  DashBoardStatusCardTypes.Service,
]
export const allReportCardsDefaultOrder = [
  DashBoardStatusCardTypes.Pod,
  DashBoardStatusCardTypes.Deployment,
  DashBoardStatusCardTypes.Service,
  DashBoardStatusCardTypes.User,
  DashBoardStatusCardTypes.Tenant,
]
