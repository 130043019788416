import React, { useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { routes } from 'core/utils/routes'
import { IPersistentVolumeClaimDetailsPageTabs } from './model'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import DataKeys from 'k8s/DataKeys'
import {
  renderResourceLabels,
  renderLabelsAsBadges,
} from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { renderPvcStatus } from './helpers'
import DateCell, { DateAndTime } from 'core/components/listTable/cells/DateCell'
import DocumentMeta from 'core/components/DocumentMeta'
import { persistentVolumeClaimsSelector } from './selectors'
import { ArrayElement } from 'core/actions/Action'
import { listPersistentVolumeClaims, deletePersistentVolumeClaim } from './new-actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useListAction from 'core/hooks/useListAction'
import ListContainer from 'core/containers/ListContainer'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import usePluginRouter from 'core/hooks/usePluginRouter'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import DeletePersistentVolumeClaimDialog from './DeletePersistentVolumeClaimDialog'
import AddPersistentVolumeClaimModal from './AddPersistentVolumeClaimModal'

type ModelDataKey = DataKeys.PersistentVolumeClaims
type SelectorModel = ArrayElement<ReturnType<typeof persistentVolumeClaimsSelector>>
type ActionParams = InferActionParams<typeof listPersistentVolumeClaims>

type Params = ActionParams & {
  namespace?: string
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'PersistentVolumeClaims',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']

const getColumns = (pluginId = 'kubernetes'): GridViewColumn<SelectorModel>[] => {
  const routePath = pluginId === 'kubevirt' ? 'kubevirtStorage' : 'storage'
  return [
    {
      key: 'name',
      label: 'Name',
      width: 'medium',
      CellComponent: createGridLinkCell({
        routeToFn: ({ clusterId, id }) =>
          routes[routePath].persistentVolumeClaims.details.path({
            clusterId,
            id,
            tab: IPersistentVolumeClaimDetailsPageTabs.Overview,
          }),
      }),
    } as GridViewColumn<SelectorModel, 'name'>,
    { key: 'namespace', label: 'Namespace' },
    { key: 'clusterName', label: 'Cluster', width: 'medium' },
    { key: 'status.phase', label: 'Status', render: renderPvcStatus },
    {
      key: 'labels',
      label: 'Label',
      render: renderResourceLabels({ type: 'table', variant: 'default' }),
    },
    { key: 'volume', label: 'Volume' },
    { key: 'capacity', label: 'Capacity', render: renderResourceLabels({ type: 'table' }) },
    {
      key: 'accessModes',
      label: 'Access Modes',
      render: renderLabelsAsBadges({ variant: 'default' }),
    },
    { key: 'storageClassName', label: 'Storage Class' },
    { key: 'created', label: 'Created', render: (value: string) => <DateAndTime value={value} /> },
  ]
}

export default function PersistentVolumeClaimsListPage() {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtStorage' : 'storage'
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listPersistentVolumeClaims, {
    params,
    requiredParams,
  })
  const data = useAppSelector(persistentVolumeClaimsSelector)

  const [selectedPersistentVolumeClaim, setSelectedPersistentVolumeClaim] = useState<SelectorModel>(
    null,
  )
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      // cond: (item) => canDelete([item]),
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (persistentVolumeClaim) => {
        setSelectedPersistentVolumeClaim(persistentVolumeClaim)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
  ]

  const columns = getColumns(currentPluginId)
  return (
    <>
      <DocumentMeta title="Persistent Volume Claims" />
      <AddPersistentVolumeClaimModal addRoute={routes[routePath].persistentVolumeClaims.add} />
      {showDeleteDialog && (
        <DeletePersistentVolumeClaimDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedPersistentVolumeClaim]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.PersistentVolumeClaims}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={data}
        addUrl={routes[routePath].persistentVolumeClaims.add.path()}
        addText="Add Persistent Volume Claim"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deletePersistentVolumeClaim}
        rowMenuItems={rowMenuItems}
        showRowMenuForSingleRowActions
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

const toFilterColumns = {
  clusterName: true,
  labels: true,
  volume: true,
  storageClass: true,
  created: true,
}

const formatOpts = {
  renameLabels: {},
  labels: ['d', 'h'],
  pluralize: false,
}

export const getPvcDetailOptions = (pluginId = 'kubevirt') => {
  const columns = getColumns(pluginId)
  return {
    title: 'Persistent Volume Claims',
    columns: [
      ...columns.filter((column) => !toFilterColumns[column.key]),
      {
        key: 'created',
        label: 'Age',
        render: (value) => (
          <DateCell value={value} formatOpts={formatOpts} showToolTip difference />
        ),
      },
    ],
    columnsOrder: ['name', 'created', 'capacity', 'accessModes', 'status.phase'],
  }
}
