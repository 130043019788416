import FormControl from '@material-ui/core/FormControl'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import TextField from 'core/components/validatedForm/TextField'

const useStyles = makeStyles<Theme>((theme) => ({
  itemsBox: {
    width: '50%',
    margin: theme.spacing(1.5, 0),
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    '& > .MuiFormControl-root': {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: 4,
      padding: theme.spacing(1.5),
    },
  },
  itemsBoxLabel: {
    position: 'absolute',
    top: -12,
    backgroundColor: theme.components.card.background,
    padding: 4,
  },
  itemsBoxInput: {
    marginBottom: theme.spacing(1, 0),
    width: '100% !important',
  },
  itemsBoxItems: {
    overflowX: 'auto',
    maxHeight: 280,
    paddingRight: theme.spacing(1),
  },
  removeItemIcon: {
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  notchedOutline: {
    borderRadius: 0,
    '& > legend': {
      height: 18,
      visibility: 'visib',
    },
  },
  formControl: {
    '& > .MuiFormControl-root.validatedFormInput': {
      width: '100%',
    },
  },
  addBtn: {
    margin: theme.spacing(2, 0),
    width: 80,
  },
}))

const CustomItemsBox = ({
  id,
  items,
  onAddItem,
  onRemoveItem,
  label,
  addNewLabel = 'Add New Item',
}) => {
  const classes = useStyles()
  const [term, changeTerm] = useState('')

  const handleItemAdd = useCallback(() => {
    onAddItem(term)
    changeTerm('')
  }, [term])

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleItemAdd()
      } else if (event.key === 'Escape') {
        changeTerm('')
      }
    },
    [term],
  )

  return (
    <div className={classes.itemsBox}>
      <FormControl id="externalGroups" className={classes.formControl}>
        <Text className={classes.itemsBoxLabel} variant="caption1">
          {label}
        </Text>
        <TextField
          id={id}
          label={addNewLabel}
          value={term}
          onChange={changeTerm}
          onKeyDown={handleKeyDown}
        />
        <Button disabled={term.length < 3} onClick={handleItemAdd} className={classes.addBtn}>
          Add
        </Button>
        <div className={classes.itemsBoxItems}>
          {items.map((name, idx) => (
            <div key={idx}>
              <FontAwesomeIcon
                className={classes.removeItemIcon}
                onClick={(e) => {
                  e.stopPropagation()
                  onRemoveItem(name, idx)
                }}
                size="md"
                solid
              >
                minus-circle
              </FontAwesomeIcon>
              {name}
            </div>
          ))}
        </div>
      </FormControl>
    </div>
  )
}

export default CustomItemsBox
