import React, { useState, useCallback } from 'react'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import { except, stopBubbling } from 'utils/fp'
import { RolesListTable } from 'k8s/components/rbac/profiles/create/RolesForm'
import { profilesHelpUrl } from 'k8s/links'
import { RoleBindingsListTable } from 'k8s/components/rbac/profiles/create/RoleBindingsForm'
import { ClusterRolesListTable } from 'k8s/components/rbac/profiles/create/ClusterRolesForm'
import { ClusterRoleBindingsListTable } from 'k8s/components/rbac/profiles/create/ClusterRoleBindingsForm'
import useStyles from './useStyles'
import SimpleLink from 'core/components/SimpleLink'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from 'k8s/components/rbac/profiles/ProfilesAccordion'
import generateTestId from 'utils/test-helpers'
import Text from 'core/elements/Text'

enum Panels {
  Roles,
  ClusterRoles,
  RoleBindings,
  ClusterRoleBindings,
}
const defaultActivePanels = []

const ReviewForm = ({ wizardContext }) => {
  const classes = useStyles()
  const {
    profileName,
    baseClusterName,
    roles: selectedRoles,
    clusterRoles: selectedClusterRoles,
    roleBindings: selectedRoleBindings,
    clusterRoleBindings: selectedClusterRoleBindings,
  } = wizardContext
  const [activePanels, setActivePanels] = useState<Panels[]>(defaultActivePanels)
  const togglePanel = useCallback(
    (panel) => () => {
      setActivePanels(
        activePanels.includes(panel) ? except(panel, activePanels) : [panel, ...activePanels],
      )
    },
    [activePanels],
  )
  const filteredRoles = Object.values(selectedRoles)
  const filteredClusterRoles = Object.values(selectedClusterRoles)
  const filteredRoleBindings = Object.values(selectedRoleBindings)
  const filteredClusterRoleBindings = Object.values(selectedClusterRoleBindings)

  return (
    <FormFieldCard
      className={classes.listBox}
      title={'RBAC Profile - Review'}
      link={<SimpleLink src={profilesHelpUrl}>Profiles Help</SimpleLink>}
    >
      <Text variant="body2">
        The selected RBAC Policies will be added to your list of <strong>Draft Profiles</strong>.
        You can review and customize the profile after the draft is created. Once the profile is
        customized to your requirements you can publish the profile for use.
      </Text>
      <br />
      <Text variant="body2">
        Profile Name: <strong>{profileName}</strong>
        <br />
        Base Cluster: <strong>{baseClusterName}</strong>
      </Text>
      <br />
      {filteredRoles.length ? (
        <Accordion
          expanded={activePanels.includes(Panels.Roles)}
          onClick={togglePanel(Panels.Roles)}
        >
          <AccordionSummary data-testid={generateTestId('roles')} expandIcon={<ExpandMoreIcon />}>
            <div className={classes.accordionTitle}>
              {`Roles`} <span>{`(${filteredRoles.length} total)`}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails onClick={stopBubbling}>
            <RolesListTable data={filteredRoles} compactTable />
          </AccordionDetails>
        </Accordion>
      ) : null}
      {filteredClusterRoles.length ? (
        <Accordion
          expanded={activePanels.includes(Panels.ClusterRoles)}
          onClick={togglePanel(Panels.ClusterRoles)}
        >
          <AccordionSummary
            data-testid={generateTestId('cluster', 'roles')}
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionTitle}>
              {`Cluster Roles`} <span>{`(${filteredClusterRoles.length} total)`})</span>
            </div>
          </AccordionSummary>
          <AccordionDetails onClick={stopBubbling}>
            <ClusterRolesListTable data={filteredClusterRoles} compactTable />
          </AccordionDetails>
        </Accordion>
      ) : null}
      {filteredRoleBindings.length ? (
        <Accordion
          expanded={activePanels.includes(Panels.RoleBindings)}
          onClick={togglePanel(Panels.RoleBindings)}
        >
          <AccordionSummary
            data-testid={generateTestId('role', 'bindings')}
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionTitle}>
              {`Role Bindings`} <span>{`(${filteredRoleBindings.length} total)`})</span>
            </div>
          </AccordionSummary>
          <AccordionDetails onClick={stopBubbling}>
            <RoleBindingsListTable data={filteredRoleBindings} compactTable />
          </AccordionDetails>
        </Accordion>
      ) : null}
      {filteredClusterRoleBindings.length ? (
        <Accordion
          expanded={activePanels.includes(Panels.ClusterRoleBindings)}
          onClick={togglePanel(Panels.ClusterRoleBindings)}
        >
          <AccordionSummary
            data-testid={generateTestId('cluster', 'role', 'bindings')}
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionTitle}>
              {`Cluster Role Bindings`}{' '}
              <span>{`(${filteredClusterRoleBindings.length} total)`})</span>
            </div>
          </AccordionSummary>
          <AccordionDetails onClick={stopBubbling}>
            <ClusterRoleBindingsListTable data={filteredClusterRoleBindings} compactTable />
          </AccordionDetails>
        </Accordion>
      ) : null}
    </FormFieldCard>
  )
}

export default ReviewForm
