import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const items = [
  { label: 'ReadWriteOnce', value: 'ReadWriteOnce' },
  { label: 'ReadOnlyMany', value: 'ReadOnlyMany' },
  { label: 'ReadWriteMany', value: 'ReadWriteMany' },
  { label: 'ReadWriteOncePod', value: 'ReadWriteOncePod' },
]

interface Props {}

export default function AccessModePicklist(props: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown name="accessMode" label="Access Mode" items={items} {...props} />
}
