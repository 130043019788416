import React, { useCallback, useState, useMemo } from 'react'
import ListTable from 'core/components/listTable/ListTable'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import useParams from 'core/hooks/useParams'
import { allKey } from 'app/constants'
import { profilesHelpUrl } from 'k8s/links'
import DateCell from 'core/components/listTable/cells/DateCell'
import SimpleLink from 'core/components/SimpleLink'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import { emptyArr } from 'utils/fp'
import useStyles from './useStyles'
import { listRoleBindings } from 'k8s/components/rbac/new-actions'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { makeRoleBindingsSelector } from 'k8s/components/rbac/selectors'
import { propEq } from 'ramda'

const defaultParams = {
  clusterId: null,
  masterNodeClusters: true,
  namespace: allKey,
}
const visibleColumns = ['name', 'namespace', 'created']
const columnsOrder = ['name', 'namespace', 'created']
const orderBy = 'name'
const orderDirection = 'asc'
const searchTargets = ['name', 'namespace']

export const RoleBindingsListTable = ({
  data,
  onReload = null,
  loading = null,
  onSelectedRowsChange = null,
  selectedRows = emptyArr,
  filters = null,
  ...rest
}) => {
  const columns = useMemo(
    () => [
      { id: 'id', display: false },
      { id: 'name', label: 'Name' },
      { id: 'namespace', label: 'Namespace' },
      { id: 'created', label: 'Created', render: (value) => <DateCell value={value} /> },
    ],
    [],
  )

  return (
    <ListTable
      filters={filters}
      uniqueIdentifier={'id'}
      canEditColumns={false}
      searchTargets={searchTargets}
      data={data}
      onReload={onReload}
      loading={loading}
      columns={columns}
      visibleColumns={visibleColumns}
      columnsOrder={columnsOrder}
      orderBy={orderBy}
      orderDirection={orderDirection}
      onSelectedRowsChange={onSelectedRowsChange}
      showCheckboxes={!!onSelectedRowsChange}
      selectedRows={selectedRows}
      {...rest}
    />
  )
}

const paramsRoleBindingsSelector = makeRoleBindingsSelector()

const RoleBindingsForm = ({ wizardContext, setWizardContext }) => {
  const classes = useStyles()
  const [selectedRows, setSelectedRows] = useState(emptyArr)
  const { params, getParamsUpdater } = useParams(defaultParams)

  const { loading, reload } = useListAction(listRoleBindings, {
    params: { ...params, clusterId: wizardContext.baseCluster },
    requiredParams: ['clusterId'],
  })
  const data = useSelectorWithParams(paramsRoleBindingsSelector, {
    clusterId: wizardContext.baseCluster,
    namespace: params.namespace,
    useGlobalParams: false,
  })

  const refetch = useCallback(() => reload(true), [reload])
  const handleSelect = useCallback((rows) => {
    setSelectedRows(rows)
    setWizardContext({
      roleBindings: rows.reduce((acc, row) => {
        acc[row.id] = row
        return acc
      }, {}),
    })
  }, [])

  return (
    <>
      <FormFieldCard
        className={classes.listBox}
        title={'RBAC Profile - Role Bindings'}
        link={<SimpleLink src={profilesHelpUrl}>Profiles Help</SimpleLink>}
      >
        <RoleBindingsListTable
          filters={
            <NamespacePicklist
              /*
              // @ts-ignore */
              value={params.namespace}
              clusterId={wizardContext.baseCluster}
              disabled={!wizardContext.baseCluster}
              onChange={getParamsUpdater('namespace')}
            />
          }
          data={data}
          onReload={refetch}
          loading={loading}
          columnsOrder={columnsOrder}
          onSelectedRowsChange={handleSelect}
          selectedRows={selectedRows}
          selectColumns={false}
          wrapToolbar
        />
      </FormFieldCard>
    </>
  )
}

export default RoleBindingsForm
