import React, { useState, useEffect } from 'react'
import DropdownField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'

export default function BasicSettingsParamFields({ params, updateParams, inYamlMethod = false }) {
  return (
    <>
      <FormFieldSection title="Basic Settings" step={1}>
        {!inYamlMethod && (
          <TextField
            id="name"
            label="Name"
            value={params?.name}
            onChange={(value) => updateParams({ name: value })}
            required
          />
        )}
        <DropdownField
          DropdownComponent={ClusterPicklist}
          id="clusterId"
          label="Cluster"
          value={params?.clusterId}
          onChange={(value) => updateParams({ clusterId: value })}
          showAll={false}
          required
        />
        <DropdownField
          DropdownComponent={NamespacePicklist}
          id="namespace"
          label="Namespace"
          clusterId={params?.clusterId}
          value={params?.namespace}
          onChange={(value) => updateParams({ namespace: value })}
          initialNamespace="luigi-system"
          selectFirst={false}
          showAll={false}
          required
        />
      </FormFieldSection>
    </>
  )
}
