import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import useReactRouter from 'use-react-router'
import useListAction from 'core/hooks/useListAction'
import { listK8sEndpoints, updateK8sEndpoint } from '../actions'
import { IEndpointDetailsPageTabs, IEndpointSelector } from '../model'
import { k8sEndpointsSelector } from '../selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import { routes } from 'core/utils/routes'
import EndpointsOverview from './EndpointsOverview'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import { isEmpty } from 'ramda'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import OverviewActions from 'core/elements/overview/OverviewActions'
import DeleteEndpointDialog from '../DeleteEndpointDialog'
import usePluginRouter from 'core/hooks/usePluginRouter'

const { qbert } = ApiClient.getInstance()

const actions = {
  rightActions: [
    {
      label: 'Delete',
      icon: 'trash-alt',
      DialogComponent: DeleteEndpointDialog,
    },
  ],
}

export default function EndpointsDetailsPage() {
  const dispatch = useDispatch()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { loading, reload } = useListAction(listK8sEndpoints, { params: { clusterId } })
  const k8sEndpoints: IEndpointSelector[] = useSelector(k8sEndpointsSelector)
  const k8sEndpoint = useMemo(() => k8sEndpoints.find((k8sEndpoint) => k8sEndpoint.id === id), [
    id,
    k8sEndpoints,
  ])

  const { update, updating } = useUpdateAction(updateK8sEndpoint)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: k8sEndpoint?.clusterName || clusterId,
        id: k8sEndpoint?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [k8sEndpoint?.clusterName, k8sEndpoint?.name, id, clusterId])

  const getK8sEndpointYaml = useCallback(async () => {
    if (isEmpty(k8sEndpoint)) return undefined
    return qbert.getK8sEndpoint(k8sEndpoint.clusterId, k8sEndpoint.namespace, k8sEndpoint.name)
  }, [k8sEndpoint])

  const handleK8sEndpointUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Endpoints Overview" breadcrumbs />
      <div>
        <OverviewActions actions={actions} entity={k8sEndpoint} />
      </div>
      <Tabs route={routes[routePath].endpoints.details}>
        <Tab label="Overview" value={IEndpointDetailsPageTabs.Overview}>
          <EndpointsOverview k8sEndpoint={k8sEndpoint} loading={loading} reload={reload} />
        </Tab>
        <Tab label="YAML" value={IEndpointDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Endpoint"
            entityName={k8sEndpoint?.name}
            getYamlFn={getK8sEndpointYaml}
            handleUpdate={handleK8sEndpointUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}
