import React, { useCallback, useState } from 'react'
import jsYaml from 'js-yaml'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import Button from 'core/elements/button'
import Text from 'core/elements/Text'
import SubmitButton from 'core/components/buttons/SubmitButton'
import { IRbacClusterRoleBinding } from 'k8s/components/rbac/model'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import useEditBindingStyles from 'k8s/components/rbac/profiles/edit/useEditBindingsStyles'
import useToggler from 'core/hooks/useToggler'
import DisplayKeyValues from 'core/components/DisplayKeyValues'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'

interface Props {
  onClose: () => void
  open: boolean
  clusterRoleBinding: IRbacClusterRoleBinding
}

const renderSubjectType = (type) => (subjects) => {
  const filteredSubjects = subjects.filter((subject) => subject?.kind === type)
  return (
    <>
      {filteredSubjects.map((subject) => (
        <Text variant="body2">{subject?.name}</Text>
      ))}
    </>
  )
}

const EditClusterRoleBindingDialog = ({ open, onClose, clusterRoleBinding }: Props) => {
  const classes = useEditBindingStyles()

  const keyValuePairs = [
    { key: 'Name', value: clusterRoleBinding?.metadata?.name },
    {
      key: clusterRoleBinding?.roleRef?.kind,
      value: clusterRoleBinding?.roleRef?.name,
    },
    {
      key: 'Service Accounts',
      value: clusterRoleBinding?.subjects,
      render: renderSubjectType('ServiceAccount'),
    },
    {
      key: 'Users',
      value: clusterRoleBinding?.subjects,
      render: renderSubjectType('User'),
    },
    {
      key: 'Groups',
      value: clusterRoleBinding?.subjects,
      render: renderSubjectType('Group'),
    },
    {
      key: 'External Users',
      value: clusterRoleBinding?.subjects,
      render: renderSubjectType('ExternalUser'),
    },
    {
      key: 'External Groups',
      value: clusterRoleBinding?.subjects,
      render: renderSubjectType('ExternalGroup'),
    },
  ]

  const [rawYaml, setRawYaml] = useState()
  const [showingYaml, , setShowingYaml] = useToggler()
  const showYaml = useCallback(() => {
    // Get document, or throw exception on error
    try {
      const rawYaml = jsYaml.dump(clusterRoleBinding)
      setRawYaml(rawYaml)
      setShowingYaml(true)
    } catch (e) {
      console.error(e)
    }
  }, [clusterRoleBinding])

  const exitYaml = useCallback(() => {
    setShowingYaml(false)
  }, [rawYaml])

  const defaultView = (
    <div className={classes.policyDetails}>
      <header className={classes.header}>
        <Text variant="body1">Cluster Role Binding Details</Text>
        <Button onClick={showYaml} className={classes.yamlBtn} variant="secondary">
          YAML View
        </Button>{' '}
      </header>
      <DisplayKeyValues keyValuePairs={keyValuePairs} />
    </div>
  )

  const yamlView = (
    <ValidatedForm onSubmit={exitYaml}>
      <div className={classes.filters}>
        <span>Cluster Role Binding YAML</span>
        <SubmitButton className={classes.yamlBtn} variant="secondary">
          Exit YAML View
        </SubmitButton>
      </div>
      <CodeMirror
        id="yamlView"
        label="YAML RESOURCE"
        value={rawYaml}
        className={classes.codeMirror}
      />
    </ValidatedForm>
  )

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>{showingYaml ? yamlView : defaultView}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditClusterRoleBindingDialog
