import React from 'react'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DateCell from 'core/components/listTable/cells/DateCell'
import DataKeys from 'k8s/DataKeys'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import { rolesSelector } from './selectors'
import { ArrayElement } from 'core/actions/Action'
import { listRoles, deleteRole } from './new-actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useListAction from 'core/hooks/useListAction'
import ListContainer from 'core/containers/ListContainer'
import AddRolePage from './AddRolePage'
import UpdateRolePage from './UpdateRolePage'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'
import usePluginRouter from 'core/hooks/usePluginRouter'

type ModelDataKey = DataKeys.KubeRoles
type SelectorModel = ArrayElement<ReturnType<typeof rolesSelector>>
type ActionParams = InferActionParams<typeof listRoles>

type Params = ActionParams & {
  namespace?: string
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Roles', listTablePrefs)

const searchTargets = ['name', 'clusterName', 'namespace']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'name', label: 'Name' },
  { key: 'clusterName', label: 'Cluster' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'created', label: 'Created', render: (value) => <DateCell value={value} /> },
]

export default function RolesListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { currentPluginId } = usePluginRouter()
  const { message, loading, reload } = useListAction(listRoles, {
    params,
    requiredParams,
  })
  const data = useAppSelector(rolesSelector)

  return (
    <>
      <DocumentMeta title="Roles" />
      <AddRolePage />
      <UpdateRolePage />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.KubeRoles}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={data}
        getParamsUpdater={getParamsUpdater}
        addUrl={routes.rbac.addRoles.path({ plugin: currentPluginId })}
        addText="Add Role"
        editUrl={(item, id) =>
          routes.rbac.editRoles.path({ id, clusterId: item.clusterId, plugin: currentPluginId })
        }
        deleteAction={deleteRole}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
