import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import { routes } from 'core/utils/routes'
import { deleteNetworkPlugin } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'
import usePluginRouter from 'core/hooks/usePluginRouter'

export default function DeleteNetworkPluginDialog({ rows: [networkPlugin], onClose }) {
  const { currentPluginId } = usePluginRouter()
  const { history } = useReactRouter()
  const { update: deleteFn, updating: deletingNetworkPlugin } = useUpdateAction(deleteNetworkPlugin)
  const title = `Permanently delete "${networkPlugin?.name}"?`
  const handleDelete = useCallback(async () => {
    const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
    await deleteFn(networkPlugin)
    onClose(true)
    history.push(routes[routePath].plugins.path())
  }, [networkPlugin])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingNetworkPlugin}>
            Don't Delete
          </Button>
          <Button onClick={handleDelete} loading={deletingNetworkPlugin}>
            Delete Network Plugin
          </Button>
        </>
      }
    >
      <Text variant="body2">This action cannot be undone, are you sure you want to do this?</Text>
    </Modal>
  )
}
