import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import useReactRouter from 'use-react-router'
import useListAction from 'core/hooks/useListAction'
import { listIngresses, updateIngress } from 'k8s/components/ingresses/new-actions'
import { IIngressDetailsPageTabs, IIngressSelector } from 'k8s/components/ingresses/model'
import { ingressesSelector } from 'k8s/components/ingresses/selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import { routes } from 'core/utils/routes'
import IngressesOverview from './IngressesOverview'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import { isEmpty } from 'ramda'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import OverviewActions from 'core/elements/overview/OverviewActions'
import DeleteIngressDialog from '../DeleteIngressDialog'
import usePluginRouter from 'core/hooks/usePluginRouter'

const { qbert } = ApiClient.getInstance()

const actions = {
  rightActions: [
    {
      label: 'Delete',
      icon: 'trash-alt',
      DialogComponent: DeleteIngressDialog,
    },
  ],
}

export default function IngressesDetailsPage() {
  const dispatch = useDispatch()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { loading, reload } = useListAction(listIngresses, { params: { clusterId } })
  const ingresses: IIngressSelector[] = useSelector(ingressesSelector)
  const ingress = useMemo(() => ingresses.find((ingress) => ingress.id === id), [id, ingresses])

  const { update, updating } = useUpdateAction(updateIngress)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: ingress?.clusterName || clusterId,
        id: ingress?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [ingress?.clusterName, ingress?.name, id, clusterId])

  const getIngressYaml = useCallback(async () => {
    if (isEmpty(ingress)) return undefined
    return qbert.getIngress(ingress.clusterId, ingress.namespace, ingress.name)
  }, [ingress])

  const handleIngressUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Ingresses Overview" breadcrumbs />
      <div>
        <OverviewActions actions={actions} entity={ingress} />
      </div>
      <Tabs route={routes[routePath].ingresses.details}>
        <Tab label="Overview" value={IIngressDetailsPageTabs.Overview}>
          <IngressesOverview ingress={ingress} loading={loading} reload={reload} />
        </Tab>
        <Tab label="YAML" value={IIngressDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Ingress"
            entityName={ingress?.name}
            getYamlFn={getIngressYaml}
            handleUpdate={handleIngressUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}
