import React, { useMemo } from 'react'
import DocumentMeta from 'core/components/DocumentMeta'
import ListContainer from 'core/containers/ListContainer'
import { useState } from 'react'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useListAction from 'core/hooks/useListAction'
import { routes } from 'core/utils/routes'
import { listNetworks } from './actions'
import { networksSelector } from './selectors'
import { ArrayElement } from 'core/actions/Action'
import DataKeys from 'k8s/DataKeys'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import InferActionParams from 'core/actions/InferActionParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'
import { pick } from 'ramda'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import AddNetworkAttachmentDefinitionsModal from './AddNetworkAttachmentDefinitionsModal'
import Text from 'core/elements/Text'
import DeleteNetworkAttachmentDefinitionsDialog from './DeleteNetworkAttachmentDefinitionsDialog'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { INetworkAttachmentDefinitionsDetailsPageTabs } from './model'
import usePluginRouter from 'core/hooks/usePluginRouter'

type ModelDataKey = DataKeys.Networks
type SelectorModel = ArrayElement<ReturnType<typeof networksSelector>>
type ActionParams = InferActionParams<typeof listNetworks>
type Params = ActionParams

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Networks', listTablePrefs)

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  namespace: null,
}

const searchTargets = ['name', 'clusterName']

export const networkTypes = {
  ovs: 'OVS',
  ovsDpdk: 'OVS DPDK',
  sriov: 'SR-IOV',
  sriovDpdk: 'SR-IOV DPDK',
  macvlan: 'MACVLAN',
  ipvlan: 'IPVLAN',
}

const TypeCell = ({ value }) => (
  <Text variant="body2" component="p">
    {networkTypes[value]}
  </Text>
)

const ModeCell = () => (
  <Text variant="body2" component="p">
    Bridge
  </Text>
)

export default function NetworkAttachmentDefinitionsListPage() {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)

  const { message, loading, reload } = useListAction(listNetworks, {
    params,
    requiredParams,
  })
  const data = useAppSelector(networksSelector)

  const [selectedNetwork, setSelectedNetwork] = useState<SelectorModel>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const columns: GridViewColumn<SelectorModel>[] = useMemo(
    () => [
      {
        key: 'name',
        label: 'Name',
        width: 'medium',
        CellComponent: createGridLinkCell({
          routeToFn: ({ clusterId, id }) =>
            routes[routePath].networkDetails.path({
              clusterId,
              id,
              tab: INetworkAttachmentDefinitionsDetailsPageTabs.Overview,
            }),
        }),
      },
      { key: 'clusterName', label: 'Cluster', width: 'medium' },
      { key: 'namespace', label: 'Namespace' },
      { key: 'networkType', label: 'CNI Plugin Type', CellComponent: TypeCell },
      { key: 'mode', label: 'Mode', CellComponent: ModeCell },
      { key: 'ipRange', label: 'IP-Range' },
      { key: 'ipamType', label: 'IPAM' },
    ],
    [routePath],
  )

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      label: 'Remove',
      icon: 'trash-alt',
      handleClick: (network) => {
        setSelectedNetwork(network)
        setShowDeleteDialog(true)
      },
    },
  ]

  return (
    <>
      <DocumentMeta title="Network Attachment Definitions" />
      <AddNetworkAttachmentDefinitionsModal addRoute={routes[routePath].addNetwork} />
      {showDeleteDialog && (
        <DeleteNetworkAttachmentDefinitionsDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedNetwork]}
          onSuccess={() => setShowDeleteDialog(false)}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.Networks}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        label="Network Attachment Definitions"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes[routePath].addNetwork.path()}
        addText="Create Network"
        getParamsUpdater={getParamsUpdater}
        rowMenuItems={rowMenuItems}
        showRowMenuForSingleRowActions
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
