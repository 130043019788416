import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import EntityYamlPage from '../../../common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import { IHostConfigurationDetailsPageTabs, IHostNetworkTemplateSelector } from '../model'
import { routes } from 'core/utils/routes'
import { isEmpty } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listHostNetworkTemplates, updateHostNetworkTemplate } from '../actions'
import { hostNetworkTemplatesSelector } from '../selectors'
import Overview from './Overview'
import EditHostConfigurationModal from '../EditHostConfigurationModal'
import DeleteHostConfigurationDialog from '../DeleteHostConfigurationDialog'
import ActionsDropdown from 'core/elements/overview/ActionsDropdown'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import usePluginRouter from 'core/hooks/usePluginRouter'

const { qbert } = ApiClient.getInstance()

const useStyles = makeStyles<Theme>((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const dropdownActions = [
  {
    label: 'Edit',
    icon: 'pen-to-square',
    DialogComponent: EditHostConfigurationModal,
  },
  {
    label: 'Delete',
    icon: 'trash-alt',
    DialogComponent: DeleteHostConfigurationDialog,
  },
]

export default function HostConfigurationDetailsPage() {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { loading } = useListAction(listHostNetworkTemplates, { params: { clusterId } })
  const hostNetworkTemplates = useSelectorWithParams(hostNetworkTemplatesSelector, {
    clusterId,
    useGlobalParams: false,
  })
  const hostNetworkTemplate = useMemo(
    () => hostNetworkTemplates.find((plugin) => plugin.id === id),
    [id, hostNetworkTemplates],
  )
  const { update, updating } = useUpdateAction(updateHostNetworkTemplate)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: hostNetworkTemplate?.clusterName || clusterId,
        id: hostNetworkTemplate?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [hostNetworkTemplate?.clusterName, hostNetworkTemplate?.name, id, clusterId])

  const getHostNetworkTemplateYaml = useCallback(async () => {
    if (isEmpty(hostNetworkTemplate)) return undefined
    return qbert.getHostNetworkTemplate(
      hostNetworkTemplate.clusterId,
      hostNetworkTemplate.namespace,
      hostNetworkTemplate.name,
    )
  }, [hostNetworkTemplate])

  const handleHostNetworkTemplateUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Host Configuration" breadcrumbs />
      <div className={classes.actionsBar}>
        <ActionsDropdown<IHostNetworkTemplateSelector>
          actions={dropdownActions}
          entity={hostNetworkTemplate}
        />
      </div>
      <Tabs route={routes[routePath].hostConfigurationDetails}>
        <Tab label="Overview" value={IHostConfigurationDetailsPageTabs.Overview}>
          <Overview hostNetworkTemplate={hostNetworkTemplate} loading={loading} />
        </Tab>
        <Tab label="YAML" value={IHostConfigurationDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Host Network Template"
            entityName={hostNetworkTemplate?.name}
            getYamlFn={getHostNetworkTemplateYaml}
            handleUpdate={handleHostNetworkTemplateUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}
