import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'
import createCRUDActions from 'core/helpers/createCRUDActions'
import listFnWithDependencies from 'core/helpers/list-with-dependencies'
import jsYaml from 'js-yaml'

import { makePodsSelector } from 'k8s/components/pods/selectors'
import { ActionDataKeys } from 'k8s/DataKeys'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import { loadPodMetricsForCluster } from './metrics/actions'

const { qbert } = ApiClient.getInstance()

export const podActions = createCRUDActions(ActionDataKeys.Pods, {
  listFn: listFnWithDependencies(
    async (params) => {
      Bugsnag.leaveBreadcrumb('Attempting to get pods', params)
      const clusterIds = parseClusterIdsFromParams(params)
      return someAsync(clusterIds.map(qbert.getClusterPods)).then(flatten)
    },
    [loadPodMetricsForCluster],
  ),
  createFn: async ({ clusterId, namespace, yaml }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create new pod', { clusterId, namespace, yaml })
    const body = jsYaml.load(yaml)
    const pod = await qbert.createPod(clusterId, namespace, body)
    trackEvent('Create New Pod', {
      clusterId,
      namespace,
      name: pathStr('metadata.name', pod),
    })
    return pod
  },
  updateFn: async ({ clusterId, namespace, name, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update cluster pod', {
      clusterId,
      namespace,
      name,
      body,
    })
    await qbert.updatePod(clusterId, namespace, name, body)
    trackEvent('Update Pod', { clusterId, namespace, name })
  },
  deleteFn: async ({ id }, currentItems) => {
    const { clusterId, namespace, name } = currentItems.find((x) => x.id === id)
    Bugsnag.leaveBreadcrumb('Attempting to delete pod', { id, clusterId, namespace, name })
    const result = await qbert.deletePod(clusterId, namespace, name)
    trackEvent('Delete Pod', { clusterId, namespace, name, id })
    return result
  },
  uniqueIdentifier: 'metadata.uid',
  indexBy: 'clusterId',
  selectorCreator: makePodsSelector,
})
