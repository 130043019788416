import { RootState } from 'app/store'
import { Selector } from '@reduxjs/toolkit'
import { complement, isNil, propSatisfies } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { findClusterName } from 'k8s/util/helpers'
import { emptyArr } from 'utils/fp'
import { durationBetweenDates } from 'utils/misc'
import { IReplicaSetSelector } from './model'
import { podsByClusterIdAndNamespaceSelector } from '../pods/selectors'
import { addContainerType } from '../pods/helpers'
import { getVolumeType, volumeTypeDescriptions } from '../storage/helpers'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'

export const replicaSetSelector: Selector<RootState, IReplicaSetSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.ReplicaSets>(
    DataKeys.ReplicaSets,
    ['clusterId', 'namespace'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  podsByClusterIdAndNamespaceSelector,
  (rawReplicaSets, allClusters, podsByClusterIdAndNamespace) => {
    return rawReplicaSets
      .map((replicaSet) => {
        const clusterId = replicaSet?.clusterId
        const clusterName = findClusterName(allClusters, clusterId)
        const selector = replicaSet?.spec?.selector?.matchLabels
        const creationTimestamp = replicaSet?.metadata?.creationTimestamp
        const associatedPods =
          podsByClusterIdAndNamespace?.[clusterId]?.[replicaSet?.namespace] || emptyArr
        const pods = associatedPods.filter((pod) =>
          pod?.metadata?.ownerReferences?.some((owner) => owner.uid === replicaSet?.id),
        )
        const initContainers = addContainerType(
          replicaSet?.spec?.template?.spec?.initContainers,
          'Init Container',
        )
        const containers = addContainerType(replicaSet?.spec?.template?.spec?.containers)
        const volumes = (replicaSet?.spec?.template?.spec?.volumes || []).map((volume) => {
          const type = getVolumeType(volume)
          return {
            ...volume,
            type,
            description: volumeTypeDescriptions[type],
          }
        })

        return {
          ...replicaSet,
          clusterName,
          age: durationBetweenDates({ labels: ['d'] })(creationTimestamp),
          creationTimestamp,
          labels: replicaSet?.metadata?.labels,
          annotations: replicaSet?.metadata?.annotations,
          selector,
          replicas: {
            desired: replicaSet?.spec?.replicas,
            total: replicaSet?.status?.replicas || 0,
            available:
              replicaSet?.status?.availableReplicas ||
              replicaSet?.status?.fullyLabeledReplicas ||
              0,
            ready: replicaSet?.status?.readyReplicas || 0,
          },
          ownerReferences: replicaSet?.metadata?.ownerReferences,
          podTemplate: replicaSet?.spec?.template,
          volumes,
          pods,
          initContainers,
          containers,
        }
      })
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)
