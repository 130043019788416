import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import Text from 'core/elements/Text'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    height: '240px',
    width: '378px',
    margin: '0 0 0 24px',
  },
  mainDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  dataDiv: {
    display: 'grid',
    gridTemplateRows: '62px 20px',
  },
  text: {
    margin: '0 42px 4px 11px',
    fontSize: '50px',
  },
  label: {
    margin: '4px 31px 0 0',
    textAlign: 'center',
  },
}))

const ReplicasCard = ({
  numeratorValue = 0,
  denomValue = 0,
  numeratorTitle = 'Desired',
  denomTitle = 'Total',
}) => {
  const classes = useStyles()
  return (
    <Card title="Replicas" className={classes.card}>
      <div className={classes.mainDiv}>
        <div className={classes.dataDiv}>
          <Text variant="body2" component="p" className={classes.text}>
            {numeratorValue}
          </Text>
          <Text variant="body2" component="p" className={classes.label}>
            {numeratorTitle}
          </Text>
        </div>
        <Text variant="body2" component="p" className={classes.text}>
          /
        </Text>
        <div className={classes.dataDiv}>
          <Text variant="body2" component="p" className={classes.text}>
            {denomValue}
          </Text>
          <Text variant="body2" component="p" className={classes.label}>
            {denomTitle}
          </Text>
        </div>
      </div>
    </Card>
  )
}

export default ReplicasCard
