import React, { useCallback } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import useReactRouter from 'use-react-router'
import Button from 'core/elements/button'
import Card from 'core/elements/card'
import CardBody from 'core/elements/card/CardBody'
import Text from 'core/elements/Text'
import generateTestId from 'utils/test-helpers'
import { medAppPlaceholderIcon } from '../../helpers'
import { routes } from 'core/utils/routes'
import ImageWithFallback from 'core/components/image-fallback'

export default function AppCard({ app }) {
  const classes = useStyles()
  const { history } = useReactRouter()
  const { name, repository, icon, description, version, clusterName, namespace } = app

  const handleOnClick = useCallback(() => {
    history.push(routes.apps.deploy.path({ name: app.name, repository: app.repository }))
  }, [app])

  return (
    <Card data-testid={generateTestId('app', 'catalog')} className={classes.appCard} withCustomBody>
      <CardBody className={classes.cardBody}>
        <div className={classes.cardHeader}>
          <ImageWithFallback
            src={icon}
            fallbackSrc={medAppPlaceholderIcon}
            alt="App Logo"
            className={classes.appIcon}
          />
          <Button onClick={handleOnClick}>Deploy</Button>
        </div>
        <div className={classes.cardInfo}>
          <Text variant="caption1">{clusterName}</Text>
          <Text variant="caption1">{namespace}</Text>
          <Text variant="subtitle2">{name}</Text>
          <Text
            variant="body2"
            className={classes.passiveText}
          >{`${repository} / v${version}`}</Text>
          <Text variant="body2" component="p" lineClamp={1} className={classes.description}>
            {description}
          </Text>
        </div>
      </CardBody>
    </Card>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  appCard: {
    display: 'grid',
    minHeight: '197px',
    height: '100%',
  },
  cardHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
  },
  appIcon: {
    alignSelf: 'center',
    maxHeight: '50px',
    maxWidth: '96px',
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 16,
    gap: 16,
  },
  cardInfo: {
    display: 'grid',
    gap: theme.spacing(0.5),
    padding: 8,
  },
  description: {
    marginTop: 8,
  },
  passiveText: {
    color: theme.components.typography.passive,
  },
}))
