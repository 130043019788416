import React, { useMemo, useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick, uniq } from 'ramda'
import { routes } from 'core/utils/routes'
import { IDataVolumeDetailsPageTabs } from './model'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { listDataVolumes, deleteDataVolume } from './new-actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { dataVolumesSelector } from './selectors'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import { BadgeVariant } from 'core/elements/badge/Badge'
import StringMultiDropdownFilter from 'core/elements/grid/filters/StringMultiDropdownFilter'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import DeleteDataVolumeDialog from './DeleteDataVolumeDialog'
import AddDataVolumeModal from './AddDataVolumeModal'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'

type ModelDataKey = DataKeys.DataVolumes
type SelectorModel = ArrayElement<ReturnType<typeof dataVolumesSelector>>
type ActionParams = InferActionParams<typeof listDataVolumes>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  masterNodeClusters: boolean
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']
const defaultParams: Params & SortingState = {
  clusterId: null,
  namespace: null,
  masterNodeClusters: true,
  healthyClusters: true,
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'DataVolumes',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']

// All other phases not listed here to be Grey
const phaseHealthMap = {
  Succeeded: 'success',
  Failed: 'error',
  Unknown: 'error',
  Blank: 'error',
  Paused: 'warning',
  Pending: 'warning',
  PVCBound: 'warning',
}

const getDataVolumeStatus = (phase) => {
  const status: BadgeVariant = phaseHealthMap[phase] || 'unknown'
  return { variant: status }
}

export const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId, id }) =>
        routes.kubevirtStorage.dataVolumes.details.path({
          clusterId,
          id,
          tab: IDataVolumeDetailsPageTabs.Overview,
        }),
    }),
  },
  { key: 'clusterName', label: 'Cluster', width: 'medium' },
  { key: 'namespace', label: 'Namespace' },
  {
    key: 'status.phase',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: getDataVolumeStatus,
    }),
  },
  {
    key: 'spec.pvc.resources.requests.storage',
    label: 'Capacity',
  },
  {
    key: 'status.claimName',
    label: 'Persistent Volume Claim',
  },
  {
    key: 'spec.pvc.storageClassName',
    label: 'Storage Class',
  },
]

const dropdownFilters = [
  {
    key: 'phase',
    label: 'Phase',
    FilterComponent: StringMultiDropdownFilter,
    filterComponentProps: {
      label: 'Phase',
    },
    filterComponentOptionsPropName: 'dropdownOptions',
    getOptionsFn: (items) => {
      const options = items?.map((item) => ({
        label: item?.status?.phase,
        value: item?.status?.phase,
      }))
      return uniq(options)
    },
    equalityComparerFn: (item, value) => {
      const hasSome = value?.some((phase) => {
        return item?.status?.phase === phase
      })
      return hasSome
    },
  },
]

export default function DataVolumesListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listDataVolumes, {
    params,
    requiredParams,
  })
  const data = useAppSelector(dataVolumesSelector)

  const [selectedDataVolume, setSelectedDataVolume] = useState<SelectorModel>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      // cond: (item) => canDelete([item]),
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (dataVolume) => {
        setSelectedDataVolume(dataVolume)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="Data Volumes" />
      <AddDataVolumeModal addRoute={routes.kubevirtStorage.dataVolumes.add} />
      {showDeleteDialog && (
        <DeleteDataVolumeDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedDataVolume]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.DataVolumes}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes.kubevirtStorage.dataVolumes.add.path()}
        addText="Add Data Volume"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteDataVolume}
        DeleteDialogComponent={DeleteDataVolumeDialog}
        rowMenuItems={rowMenuItems}
        dropdownFilters={dropdownFilters}
        showRowMenuForSingleRowActions
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
