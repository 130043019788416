import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import { appDetailsSelector } from './selectors'
import createContextLoader from 'core/helpers/createContextLoader'

const { helm } = ApiClient.getInstance()

// TODO: Use GetAction for this
export const appDetailsLoader = createContextLoader(
  DataKeys.AppDetails,
  async ({ repository, name, infoType = 'all', versions = true }) => {
    Bugsnag.leaveBreadcrumb('Attempting to load app details', { name, repository })
    const chart = await helm.getChartInfo(repository, name, {
      info_type: infoType,
      versions,
    })
    return {
      ...chart,
      name,
      repository,
    }
  },
  {
    entityName: 'App Detail',
    uniqueIdentifier: ['name', 'repository'],
    indexBy: ['repository', 'name'],
    selector: appDetailsSelector,
  },
)

export const clusterAppsAction = ActionsSet.make<DataKeys.AppsAvailableToCluster>({
  uniqueIdentifier: 'Name',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.AppsAvailableToCluster],
  cacheKey: DataKeys.AppsAvailableToCluster,
})

export const listAppsAvailableToCluster = clusterAppsAction.add(
  new ListAction<DataKeys.AppsAvailableToCluster, { clusterId: string }>(async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Helm apps available to cluster', { clusterId })
    return helm.getChartsForCluster(clusterId)
  }),
)

export const appActions = ActionsSet.make<DataKeys.Apps>({
  uniqueIdentifier: 'Name',
  entityName: entityNamesByKey[DataKeys.Apps],
  cacheKey: DataKeys.Apps,
})

export const listApps = appActions.add(
  new ListAction<DataKeys.Apps>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get all Helm apps')
    return helm.getCharts()
  }),
)
