import { makeStyles } from '@material-ui/styles'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import Theme from 'core/themes/model'
import React, { useMemo, useRef, useState } from 'react'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import SimpleLink from 'core/components/SimpleLink'
import AppVersionPicklistField from '../../AppVersionPicklistField'
import useDataLoader from 'core/hooks/useDataLoader'
import { appDetailsLoader } from '../../apps/actions'
import PicklistField from 'core/components/validatedForm/DropdownField'
import NamespacePicklist from '../../../common/NamespacePicklist'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { getAppVersionPicklistOptions } from '../../helpers'
import ClusterPicklist from '../../../common/ClusterPicklist'
import { compareVersions } from 'k8s/util/helpers'
import { codeMirrorOptions } from 'app/constants'
import { requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import ModalForm from 'core/elements/modal/ModalForm'
import Column from 'core/containers/Column'
import { noop } from 'utils/fp'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateDeployedApp } from '../new-actions'

const codeMirrorValidations = [requiredValidator, yamlValidator]

export default function EditAppDeploymentFormModal({ deployedApp, repository, open, onClose }) {
  const classes = useStyles()
  const fileInputRef = useRef(null)
  const formFieldSetter = useRef(null)
  const fieldSetter = (setField) => {
    formFieldSetter.current = { setField }
  }
  const [fileUploadError, setFileUploadError] = useState(null)
  const { clusterId, namespace, chartName: chart, name, config, chartVersion } = deployedApp

  // We also need to load app/chart detail for other additional info such as app/chart versions
  const [[appDetail = {}], loadingAppDetail] = useDataLoader(appDetailsLoader, {
    name: chart,
    repository,
  })

  const { update, updating, error } = useUpdateAction(updateDeployedApp)

  const defaultStructuredValues = useMemo(() => JSON.stringify(appDetail?.values || {}, null, 1), [
    appDetail?.values,
  ])

  const initialContext = useMemo(
    () => ({
      deploymentName: name,
      version: chartVersion,
      repository,
      clusterId: clusterId,
      namespace: namespace,
      useDefaultValues: false,
      values: JSON.stringify(config, null, 1),
      info: appDetail?.metadata?.home,
    }),
    [deployedApp, appDetail, name, clusterId, namespace],
  )

  // Only version upgrades are available to the user so we must filter out the list of
  // app versions to only include versions >= to the deployed app's current version
  const availableVersions = useMemo(() => {
    if (!appDetail?.Versions || !chartVersion) {
      return []
    }
    return appDetail.Versions.filter((version) => compareVersions(version, chartVersion) >= 0)
  }, [appDetail, deployedApp])
  const appVersions = useMemo(() => getAppVersionPicklistOptions(availableVersions), [appDetail])

  const handleSubmit = async (val) => {
    const { deploymentName, clusterId, namespace, version, values, useDefaultValues } = val

    const vals = useDefaultValues ? defaultStructuredValues : values

    const { success } = await update({
      clusterId,
      namespace,
      deploymentName,
      chart,
      repository,
      action: 'upgrade',
      version,
      values: vals,
    })
    if (success) {
      onClose()
    }
  }

  const openFileBrowser = () => {
    fileInputRef.current.click()
  }

  const handleFileUpload = (setFieldValue) => (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = function() {
      setFieldValue('values')(reader.result)
      setFieldValue('useDefaultValues')(false)
    }
    reader.onerror = function() {
      setFileUploadError({ title: 'File Upload Error', message: 'Cannot read file' })
    }

    reader.readAsText(file)
  }

  return (
    <ModalForm
      open={open}
      title="Edit Deployment"
      initialValues={initialContext}
      onBackdropClick={noop}
      onClose={onClose}
      onSubmit={handleSubmit}
      submitting={updating}
      submitTitle="Update"
      fieldSetter={fieldSetter}
      loading={loadingAppDetail}
      error={error || fileUploadError}
    >
      {({ setFieldValue, values }) => {
        return (
          <>
            <FormFieldSection title="Deployment Info" step={1}>
              <Column>
                <TextField
                  className={classes.nameField}
                  id="deploymentName"
                  label="Deployment Name"
                  disabled
                />
                <PicklistField
                  DropdownComponent={ClusterPicklist}
                  value={clusterId}
                  compact={false}
                  id="clusterId"
                  label="Cluster"
                  onChange={null}
                  disabled
                />
                <PicklistField
                  DropdownComponent={NamespacePicklist}
                  id="namespace"
                  label="Namespace"
                  compact={false}
                  clusterId={values.clusterId}
                  onChange={null}
                  disabled
                />
                <AppVersionPicklistField options={appVersions} />
              </Column>
            </FormFieldSection>
            <FormFieldSection
              step={2}
              className={classes.form}
              title="Values"
              link={
                <>
                  <SimpleLink src="" onClick={openFileBrowser}>
                    Upload a values.yaml file
                  </SimpleLink>
                  <input
                    type="file"
                    id="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept=".yaml"
                    onChange={handleFileUpload(setFieldValue)}
                  />
                </>
              }
            >
              <CodeMirror
                id="values"
                value={values.values}
                onChange={(values) => {
                  setFieldValue('values')(values)
                  setFieldValue('useDefaultValues')(false)
                }}
                variant="light"
                options={codeMirrorOptions}
                validations={codeMirrorValidations}
              />
              <CheckboxField
                id="useDefaultValues"
                value={values.useDefaultValues}
                label="Use default structured values"
                onChange={(checked) => {
                  setFieldValue('values')(checked ? defaultStructuredValues : initialContext.values)
                  setFieldValue('useDefaultValues')(checked)
                }}
              />
            </FormFieldSection>
          </>
        )
      }}
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  deployAppForm: {
    marginTop: theme.spacing(3),
    gridGap: theme.spacing(4),
  },
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  fields: {
    display: 'grid',
    gridColumnGap: theme.spacing(3),
    marginBottom: theme.spacing(4),
    gridTemplateColumns: '1fr 1fr',
    '& .MuiFormControl-root.validatedFormInput': {
      width: '100%',
    },
  },
  clusterField: {
    gridColumnStart: 1,
    gridColumnEnd: 1,
  },
  namespaceField: {
    gridColumnStart: 2,
    gridColumnEnd: 2,
  },
  infoText: {
    color: theme.palette.grey[700],
  },
  logoContainer: {
    borderRadius: '2px',
    border: `solid 0.5px ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey['000'],
    width: 240,
    height: 140,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1.5, 0, 0.5, 0),
    '& img': {
      maxWidth: 160,
      maxHeight: 100,
    },
  },
  form: {
    '& .form-field-card-requirementsTitle': {
      marginBottom: 8,
    },
  },
  codeMirror: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    '& .validatedFormInput': {
      margin: '0 !important',
      width: '100% !important',
    },
    '& .CodeMirror-linenumber': {
      padding: '0 8px 0 0',
    },
    '& .CodeMirror pre': {
      padding: '0 0 0 8px',
    },
  },
}))
