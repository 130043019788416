import { IGenericResource } from 'api-client/qbert.model'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'

export enum IPodDisruptionBudgetDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface PodDisruptionBudgetsResponse {
  kind: string
  apiVersion: string
  metadata: PodDisruptionBudgetsResponseMetadata
  items: PodDisruptionBudgetItem[]
}

export interface PodDisruptionBudgetItem {
  metadata: ItemMetadata
  spec: PodDisruptionBudgetSpec
  status: PodDisruptionBudgetStatus
}

interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels?: Record<string, string>
  ownerReferences?: OwnerReference[]
  annotations?: Record<string, string>
}

export interface OwnerReference {
  apiVersion: string
  kind: string
  name: string
  uid: string
  controller: boolean
  blockOwnerDeletion: boolean
}

interface PodDisruptionBudgetsResponseMetadata {
  resourceVersion: string
}

interface PodDisruptionBudgetSpec {
  maxUnavailable?: number | string
  minAvailable?: number | string
  selector?: Selector
}

interface Selector {
  matchLabels: Record<string, string>
}

interface PodDisruptionBudgetStatus {
  disruptionsAllowed: number
  currentHealthy: number
  desiredHealthy: number
  expectedPods: number
  observedGeneration?: number
  conditions?: Condition[]
}

export interface Condition {
  type: string
  status: string
  lastTransitionTime: Date
  reason?: string
  message?: string
}

export interface IPodDisruptionBudgetSelector extends IGenericResource<PodDisruptionBudgetItem> {
  clusterName: string
  clusterId: string
  clusterType: ClusterTypes
  ownerReferences: PodDisruptionBudgetItem['metadata']['ownerReferences']
  age?: string
  labels: PodDisruptionBudgetItem['metadata']['labels']
  annotations: PodDisruptionBudgetItem['metadata']['annotations']
}
