import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React, { useState } from 'react'
import Text from 'core/elements/Text'
import { isNilOrEmpty } from 'utils/fp'
import Tab from 'core/elements/tabs/Tab'
import Tabs from 'core/elements/tabs'
import Grid from 'core/elements/grid'

const useStyles = makeStyles((theme: Theme) => ({
  noneText: {
    margin: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}))

const defaultColumns = [
  { key: 'name', label: 'Name' },
  { key: 'image', label: 'image' },
]

const Containers = ({
  initContainers = [],
  containers = [],
  initContainerColumns = defaultColumns,
  containerColumns = defaultColumns,
  showInitContainers = true,
  showContainers = true,
}) => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(showInitContainers ? 'initContainers' : 'containers')
  return (
    <Tabs activeTab={activeTab} setActiveTab={(tab) => setActiveTab(tab)}>
      {showInitContainers && (
        <Tab label="Init Containers" value="initContainers">
          {!isNilOrEmpty(initContainers) ? (
            <Grid
              uniqueIdentifier="name"
              data={initContainers}
              columns={initContainerColumns}
              compact
              disableToolbar
            />
          ) : (
            <Text className={classes.noneText} variant="caption1">
              No Init Containers
            </Text>
          )}
        </Tab>
      )}
      {showContainers && (
        <Tab label="Containers" value="containers">
          {!isNilOrEmpty(containers) ? (
            <Grid
              uniqueIdentifier="name"
              data={containers}
              columns={containerColumns}
              compact
              disableToolbar
            />
          ) : (
            <Text className={classes.noneText} variant="caption1">
              No Init Containers
            </Text>
          )}
        </Tab>
      )}
    </Tabs>
  )
}

export default Containers
