import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import React, { useMemo } from 'react'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { pick } from 'ramda'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import { createResourceLabelsCell } from '../common/entity/labels-and-annotations/helpers'
import { routes } from 'core/utils/routes'
import { ArrayElement } from 'core/actions/Action'
import { listDaemonSets, deleteDaemonSet } from './actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridFilterSpec } from 'core/elements/grid/hooks/useGridFiltering'
import ListContainer from 'core/containers/ListContainer'
import { daemonSetSelector } from './selectors'
import AddWorkloadResourcePage from 'k8s/components/common/entity/AddResourcePage'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'
import { durationBetweenDates } from 'utils/misc'

type ModelDataKey = DataKeys.DaemonSets
type SelectorModel = ArrayElement<ReturnType<typeof daemonSetSelector>>
type ActionParams = InferActionParams<typeof listDaemonSets>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  namespace?: string
  masterNodeClusters: boolean
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']
const defaultParams: Params = {
  clusterId: null,
  masterNodeClusters: true,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'DaemonSets',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId, id }) => routes.daemonSets.details.path({ clusterId, id }),
    }),
  },
  { key: 'namespace', label: 'Namespace' },
  { key: 'clusterName', label: 'Cluster', width: 'medium' },
  {
    key: 'creationTimestamp',
    label: 'Age',
    formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
  { key: 'desiredNumberScheduled', label: 'Desired Number of Nodes Scheduled' },
  {
    key: 'currentNumberScheduled',
    label: 'Current Number of Nodes Scheduled',
  },
  {
    key: 'updatedNumberScheduled',
    label: 'Nodes Scheduled with Up-to-date Pods',
  },
  {
    key: 'numberAvailable',
    label: 'Nodes Scheduled with Available Pods',
  },
  {
    key: 'numberMisscheduled',
    label: 'Number of Nodes Misscheduled',
  },
  {
    key: 'selector',
    label: 'Selectors',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
  {
    key: 'nodeSelector',
    label: 'Node Selector',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
  {
    key: 'labels',
    label: 'Labels',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
  {
    key: 'annotations',
    label: 'Annotations',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: ': ' }),
  },
]

export default function DaemonSetsListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listDaemonSets, {
    params,
    requiredParams,
  })
  const data = useAppSelector(daemonSetSelector)

  return (
    <>
      <DocumentMeta title="Daemon Sets" />
      <AddWorkloadResourcePage resourceType="daemonSet" addRoute={routes.daemonSets.add} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.DaemonSets}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes.daemonSets.add.path()}
        addText="Add Daemon Set"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteDaemonSet}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
