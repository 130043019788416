import React, { useState, useMemo } from 'react'
import { routes } from 'core/utils/routes'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import AddHostConfigurationModal from './AddHostConfigurationModal'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import useGlobalParams from 'core/hooks/useGlobalParams'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { useAppSelector } from 'app/store'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { listHostNetworkTemplates } from './actions'
import { hostNetworkTemplatesSelector } from './selectors'
import { IHostConfigurationDetailsPageTabs } from './model'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import DeleteHostConfigurationDialog from './DeleteHostConfigurationDialog'
import usePluginRouter from 'core/hooks/usePluginRouter'

type ModelDataKey = DataKeys.HostNetworkTemplates
type SelectorModel = ArrayElement<ReturnType<typeof hostNetworkTemplatesSelector>>
type ActionParams = InferActionParams<typeof listHostNetworkTemplates>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  masterNodeClusters: boolean
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']
const defaultParams: Params & SortingState = {
  clusterId: null,
  masterNodeClusters: true,
  healthyClusters: true,
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'HostNetworkTemplates',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']

export default function HostConfigurationsListPage() {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listHostNetworkTemplates, {
    params,
    requiredParams,
  })
  const data = useAppSelector(hostNetworkTemplatesSelector)

  const [selectedHostConfig, setSelectedHostConfig] = useState<SelectorModel>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const columns: GridViewColumn<SelectorModel>[] = useMemo(
    () => [
      {
        key: 'name',
        label: 'Name',
        width: 'medium',
        CellComponent: createGridLinkCell({
          routeToFn: ({ clusterId, id }) =>
            routes[routePath].hostConfigurationDetails.path({
              clusterId,
              id,
              tab: IHostConfigurationDetailsPageTabs.Overview,
            }),
        }),
      },
      { key: 'clusterName', label: 'Cluster', width: 'medium' },
      { key: 'namespace', label: 'Namespace', width: 'medium' },
      {
        key: 'configTypes',
        label: 'Configuration',
        render: (value: string[]) => value?.join(', '),
      },
    ],
    [routePath],
  )

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (hostConfig) => {
        setSelectedHostConfig(hostConfig)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="Host Configurations" />
      <AddHostConfigurationModal addRoute={routes[routePath].addHostConfiguration} />
      {showDeleteDialog && (
        <DeleteHostConfigurationDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedHostConfig]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.HostNetworkTemplates}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes[routePath].addHostConfiguration.path()}
        addText="Create Host Configuration"
        getParamsUpdater={getParamsUpdater}
        rowMenuItems={rowMenuItems}
        showRowMenuForSingleRowActions
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
