// Libs
import React from 'react'
import { makeStyles } from '@material-ui/styles'

// Hooks
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'

// Components
import ListPageHeaderCard from './ListPageHeaderCard'
import UsageWidget from 'core/components/widgets/UsageWidget'

// Types
import { IStatusCardWithFilterProps } from '../dashboard/card-templates/model'
import Theme from 'core/themes/model'
import PollingData from 'core/components/PollingData'

interface IUsageTotal {
  current: number
  max: number
  percent: number
}
interface IUsageTotals {
  compute: IUsageTotal
  memory: IUsageTotal
  disk: IUsageTotal
}

interface IListPageHeaderProps<T> {
  report: IStatusCardWithFilterProps
  totalUsageFn?: (items: unknown[]) => IUsageTotals
  documentMeta?: JSX.Element
  hideUsage?: boolean
  percentagePrecision?: number
}
function ListPageHeader<T>({
  report,
  totalUsageFn,
  documentMeta,
  hideUsage,
  percentagePrecision,
}: IListPageHeaderProps<T>) {
  const {
    data: { loader, selector, params },
  } = report
  const classes = useStyles({})
  const { loading, reload } = useListAction(loader)
  const data = useSelectorWithParams<Record<string, unknown>, unknown[]>(selector, params)
  const totals = totalUsageFn && totalUsageFn(data)
  return (
    <>
      {documentMeta && documentMeta}
      <PollingData hidden loading={loading} onReload={reload} refreshDuration={1000 * 30} />
      <div className={classes.container}>
        <ListPageHeaderCard percentagePrecision={percentagePrecision} {...report} />
        {!hideUsage && (
          <>
            <UsageWidget title="CPU" stats={totals.compute} units="GHz" />
            <UsageWidget title="Memory" stats={totals.memory} units="GiB" />
            <UsageWidget title="Storage" stats={totals.disk} units="GiB" />
          </>
        )}
      </div>
    </>
  )
}

export default ListPageHeader

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'minmax(max-content, 394px) repeat(3, minmax(min-content, 215px))',
    gridGap: theme.spacing(2),
    alignItems: 'start',
  },
}))
