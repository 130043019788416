import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'
import { ensureArray } from 'utils/fp'

const { qbert } = ApiClient.getInstance()
const uniqueIdentifier = 'metadata.uid'

/* Service Accounts */

export const serviceAccountActions = ActionsSet.make<DataKeys.ServiceAccounts>({
  uniqueIdentifier,
  indexBy: ['clusterId', 'namespace'],
  entityName: entityNamesByKey.ServiceAccounts,
  cacheKey: DataKeys.ServiceAccounts,
})

export const listServiceAccounts = serviceAccountActions.add(
  new ListAction<
    DataKeys.ServiceAccounts,
    { clusterId: string | string[]; namespace: string | string[] }
  >(async (params) => {
    const { namespace } = params
    const namespaces = ensureArray(namespace)
    const clusterIds = parseClusterIdsFromParams(params)
    Bugsnag.leaveBreadcrumb('Attempting to get service accounts', {
      clusterIds,
      namespaces,
    })
    return someAsync(
      flatten(
        namespaces.map((namespace) =>
          clusterIds.map((clusterId) => qbert.getServiceAccounts(clusterId, namespace)),
        ),
      ),
    ).then(flatten)
  }),
)

/* Rules */

export const prometheusRuleActions = ActionsSet.make<DataKeys.PrometheusRules>({
  uniqueIdentifier,
  entityName: entityNamesByKey.PrometheusRules,
  cacheKey: DataKeys.PrometheusRules,
})

export const listPrometheusRules = prometheusRuleActions.add(
  new ListAction<DataKeys.PrometheusRules>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Prometheus rule actions')
    const clusterIds = parseClusterIdsFromParams({ ...params, prometheusClusters: true })
    return someAsync(clusterIds.map(qbert.getPrometheusRules)).then(flatten)
  }),
)
