export enum IDataVolumeDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface DataVolumesResponse {
  apiVersion: string
  items: DataVolumesItem[]
  kind: string
  metadata: DataVolumesResponseMetadata
}

export interface DataVolumesItem {
  apiVersion: string
  kind: string
  metadata: ItemMetadata
  spec: Spec
  status: StatusClass
  // Below are from qbert's convertResource function
  name: string
  namespace: string
  clusterId: string
  id: string
  created: string
}

interface ItemMetadata {
  annotations?: Record<string, string>
  creationTimestamp: string
  generation: number
  name: string
  namespace: string
  resourceVersion: string
  uid: string
  labels?: Record<string, string>
  ownerReferences?: OwnerReference[]
}

interface OwnerReference {
  apiVersion: string
  blockOwnerDeletion: boolean
  controller: boolean
  kind: string
  name: string
  uid: string
}

interface Spec {
  pvc: Pvc
  source: Source
}

interface Pvc {
  accessModes: string[]
  resources: Resources
}

interface Resources {
  requests: Requests
}

interface Requests {
  storage: string
}

interface Source {
  upload?: any
  registry?: HTTP
  http?: HTTP
}

interface HTTP {
  url: string
}

interface StatusClass {
  conditions: Condition[]
  phase: string
  progress: string
  restartCount?: number
}

interface Condition {
  lastHeartbeatTime: string
  lastTransitionTime: string
  message?: string
  reason?: string
  status: string
  type: string
}

interface DataVolumesResponseMetadata {
  continue: string
  resourceVersion: string
}

export interface IDataVolumesSelector extends DataVolumesItem {
  labels: {
    [key: string]: string
  }
  annotations: {
    [key: string]: string
  }
  clusterName: string
  creationTimestamp: string
  age: string
  source: string
  sourceMetadata: {
    [key: string]: string
  }
}
