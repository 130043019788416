import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { isNilOrEmpty } from 'utils/fp'
import { ClusterId, IRepositoriesSelector, RepositoryType } from './models'
import { pluck } from 'ramda'

export const repositoriesSelector = createSharedSelector(
  getDataSelector<DataKeys.Repositories>(DataKeys.Repositories, ['clusterId']),
  (rawRepositories): IRepositoriesSelector[] => {
    return rawRepositories.map((repository) => {
      const clusterIds = isNilOrEmpty(repository.clusters)
        ? []
        : pluck<'cluster_uuid', ClusterId>('cluster_uuid', repository.clusters)
      return {
        ...repository,
        id: repository.repo?.ID,
        name: repository.repo?.name,
        url: repository.repo?.url,
        username: repository.repo?.username,
        password: repository.repo?.password,
        private: repository.repo?.private,
        type: repository.repo?.private ? RepositoryType.Private : RepositoryType.Public,
        clusterIds: clusterIds,
        numClusters: clusterIds.length,
        autoAttach: repository.repo?.['auto_attach'],
      }
    })
  },
)

export const repositoriesConnectedToClustersSelector = createSharedSelector(
  repositoriesSelector,
  (repositories) => {
    const clusterRepos = {}
    repositories.forEach((repo) => {
      repo.clusterIds.forEach((clusterId) => {
        const existingReposForCluster = clusterRepos[clusterId] || []
        const updatedListOfRepos = [...existingReposForCluster, repo]
        clusterRepos[clusterId] = updatedListOfRepos
      })
    })
    return clusterRepos
  },
)
