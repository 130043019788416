import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ListAction from 'core/actions/ListAction'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import Bugsnag from 'utils/bugsnag'
import { ensureArray, pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'

const { qbert } = ApiClient.getInstance()

const limitRangeActions = ActionsSet.make<DataKeys.LimitRanges>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.LimitRanges],
  cacheKey: DataKeys.LimitRanges,
})

export const listLimitRanges = limitRangeActions.add(
  new ListAction<DataKeys.LimitRanges, { clusterId: string; namespace?: string }>(
    async (params) => {
      Bugsnag.leaveBreadcrumb('Attempting to get Limit Ranges', params)
      const { namespace, clusterId } = params
      return someAsync(
        ensureArray(namespace).map((name) => qbert.getLimitRanges(clusterId, name)),
      ).then(flatten)
    },
  ),
)

export const createLimitRange = limitRangeActions.add(
  new CreateAction<DataKeys.LimitRanges, { clusterId: string; namespace: string; body: any }>(
    async ({ clusterId, namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new Limit Range', {
        clusterId,
        namespace,
        body,
      })
      const createdLimitRange = await qbert.createLimitRange(clusterId, namespace, body)
      trackEvent('Create New LimitRange', {
        clusterId,
        namespace,
        name: pathStr('metadata.name', createdLimitRange),
      })
      return createdLimitRange
    },
  ),
)

export const updateLimitRange = limitRangeActions.add(
  new UpdateAction<
    DataKeys.LimitRanges,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Limit Range', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn = requestType === 'patch' ? qbert.patchLimitRange : qbert.updateLimitRange
    const updatedLimitRange = await updateFn({
      clusterId,
      namespace,
      name,
      body,
      contentType,
    })
    trackEvent('Update Limit Range', { clusterId, namespace, name })
    return updatedLimitRange
  }),
)

export const deleteLimitRange = limitRangeActions.add(
  new DeleteAction<DataKeys.LimitRanges, { clusterId: string; namespace: string; name: string }>(
    async ({ clusterId, namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Limit Range', {
        clusterId,
        namespace,
        name,
      })
      await qbert.deleteLimitRange(clusterId, namespace, name)
    },
  ),
)
