import React from 'react'
import SelectableCard from 'core/components/SelectableCard'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import clsx from 'clsx'
import { hexToRgbaCss } from 'core/utils/colorHelpers'
import Radio from 'core/elements/input/Radio'

interface Props {
  label: string
  subtitle?: string
  tagText?: string
  onClick?: (val) => void
  className?: string
  active: boolean
}

export default function RadioSelectableCard({
  active,
  label,
  subtitle,
  tagText,
  onClick,
  className = undefined,
  ...rest
}: Props) {
  const classes = useStyles({ active })
  return (
    <SelectableCard
      key={`${label}-${active}`}
      id={`${label}-${active}`}
      active={active}
      onClick={onClick}
      className={clsx(classes.card, className)}
      {...rest}
    >
      <div className={classes.content}>
        <Radio className={classes.radio} textWeight="heavy" label={label} checked={active} />
        <Text className={classes.radioSubtitle} variant="body2">
          {subtitle}
        </Text>
        {!!tagText && (
          <div className={classes.tag}>
            <svg
              width="8px"
              height="24.67px"
              viewBox="0 0 8 24"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <polygon className={classes.polygon} points="0,0 7,12 0,24 8,24 8,0" />
            </svg>
            <Text className={classes.tagText} variant="caption1">
              {tagText}
            </Text>
          </div>
        )}
      </div>
    </SelectableCard>
  )
}

const useStyles = makeStyles<Theme, Partial<Props>>((theme) => ({
  card: {
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.components.selectableCard.activeBorder}`,
    },
    border: ({ active }) =>
      active
        ? `1px solid ${theme.components.selectableCard.activeBorder}`
        : `1px solid ${theme.components.selectableCard.border}`,
    background: ({ active }) =>
      active
        ? hexToRgbaCss(theme.components.selectableCard.activeBackground, 0.1)
        : theme.components.card.background,
    borderRadius: theme.spacing(0.5),
    minWidth: 300,
    '& .card-body': {
      padding: theme.spacing(2),
    },
  },
  content: {
    display: 'grid',
    gridTemplateAreas: `"card-radio card-tag" "card-subtitle card-tag"`,
  },
  radio: {
    gridArea: 'card-radio',
  },
  radioSubtitle: {
    marginLeft: theme.spacing(4),
    fontSize: 13,
    gridArea: 'card-subtitle',
  },
  tag: {
    gridArea: 'card-tag',
    justifySelf: 'end',
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
  },
  tagText: {
    color: theme.components.selectableCard.tagText,
    backgroundColor: theme.components.selectableCard.tagBackground,
    fontSize: 12,
    padding: '4px 8px',
    width: 'auto',
    marginRight: '-20px',
  },
  polygon: {
    stroke: theme.components.selectableCard.tagBackground,
    fill: theme.components.selectableCard.tagBackground,
  },
}))
