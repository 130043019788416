import React, { useCallback, useState, useMemo } from 'react'
import ListTable from 'core/components/listTable/ListTable'
import useParams from 'core/hooks/useParams'
import { allKey } from 'app/constants'
import { profilesHelpUrl } from 'k8s/links'
import DateCell from 'core/components/listTable/cells/DateCell'
import SimpleLink from 'core/components/SimpleLink'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import { emptyArr } from 'utils/fp'
import useStyles from './useStyles'
import { listClusterRoleBindings } from 'k8s/components/rbac/new-actions'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { clusterRoleBindingsSelector } from 'k8s/components/rbac/selectors'

const defaultParams = {
  masterNodeClusters: true,
  clusterId: allKey,
}
const visibleColumns = ['name', 'created']
const columnsOrder = ['name', 'created']
const orderBy = 'name'
const orderDirection = 'asc'
const searchTargets = ['name']

export const ClusterRoleBindingsListTable = ({
  data,
  onReload = null,
  loading = null,
  onSelectedRowsChange = null,
  selectedRows = emptyArr,
  ...rest
}) => {
  const columns = useMemo(
    () => [
      { id: 'id', display: false },
      { id: 'name', label: 'Name' },
      { id: 'created', label: 'Created', render: (value) => <DateCell value={value} /> },
    ],
    [],
  )

  return (
    <ListTable
      uniqueIdentifier={'id'}
      canEditColumns={false}
      searchTargets={searchTargets}
      data={data}
      onReload={onReload}
      loading={loading}
      columns={columns}
      visibleColumns={visibleColumns}
      columnsOrder={columnsOrder}
      orderBy={orderBy}
      orderDirection={orderDirection}
      onSelectedRowsChange={onSelectedRowsChange}
      showCheckboxes={!!onSelectedRowsChange}
      selectedRows={selectedRows}
      {...rest}
    />
  )
}

const ClusterRoleBindingsForm = ({ wizardContext, setWizardContext }) => {
  const classes = useStyles()
  const [selectedRows, setSelectedRows] = useState(emptyArr)
  const { params } = useParams(defaultParams)

  const { loading, reload } = useListAction(listClusterRoleBindings, {
    params: { ...params, clusterId: wizardContext.baseCluster },
    requiredParams: ['clusterId'],
  })
  const data = useSelectorWithParams(clusterRoleBindingsSelector, {
    clusterId: wizardContext.baseCluster,
    useGlobalParams: false,
  })

  const refetch = useCallback(() => reload(true), [reload])
  const handleSelect = useCallback((rows) => {
    setSelectedRows(rows)
    setWizardContext({
      clusterRoleBindings: rows.reduce((acc, row) => {
        acc[row.id] = row
        return acc
      }, {}),
    })
  }, [])

  return (
    <>
      <FormFieldCard
        className={classes.listBox}
        title={'RBAC Profile - Cluster Role Bindings'}
        link={<SimpleLink src={profilesHelpUrl}>Profiles Help</SimpleLink>}
      >
        <ClusterRoleBindingsListTable
          data={data}
          onReload={refetch}
          loading={loading}
          onSelectedRowsChange={handleSelect}
          selectedRows={selectedRows}
          selectColumns={false}
          wrapToolbar
        />
      </FormFieldCard>
    </>
  )
}

export default ClusterRoleBindingsForm
