import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import Text from 'core/elements/Text'
import InfoBlocks from 'core/components/entity-page/InfoBlocks'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import NumberedStepLabel from 'core/components/validatedForm/NumberedStepLabel'
import { renderResourceLabels } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { keys } from 'ramda'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  customCardHeader: {
    display: 'flex',
    padding: '16px 32px 0px',
    gap: 8,
    alignItems: 'center',
  },
  pluginStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  enabled: {
    fontSize: 10,
    color: theme.palette.green.main,
  },
  disabled: {
    fontSize: 10,
    color: theme.palette.red.main,
  },
  config: {
    display: 'grid',
    gap: 12,
  },
  configurations: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: '24px 32px',
    gap: 24,
  },
  configIndent: {
    display: 'grid',
    gap: 12,
    marginLeft: 42,
  },
  configData: {
    display: 'grid',
    gridTemplateColumns: '140px 1fr',
    justifyItems: 'start',
  },
  indentedParams: {
    display: 'grid',
    gap: 12,
    marginLeft: 8,
  },
  noConfigs: {
    padding: 32,
    display: 'grid',
    gap: 8,
    justifyContent: 'center',
    textAlign: 'center',
  },
}))

const metadataFields = [
  {
    id: 'spec.nodeSelector',
    title: 'Node Selectors',
    render: renderResourceLabels({ separator: '=' }),
    condition: (item) => {
      const selectorKeys = keys(item?.spec?.nodeSelector)
      return !!selectorKeys?.length
    },
  },
]

export const pluginColumns = [
  {
    key: 'name',
    label: 'Name',
  },
  { key: 'type', label: 'Type' },
]

interface DataProps {
  label: string
  value: string
}

const ConfigData = ({ label, value }: DataProps) => {
  const classes = useStyles()
  return (
    <div className={classes.configData}>
      <Text variant="body2">{label}</Text>
      <Text variant="caption1">{value}</Text>
    </div>
  )
}

interface ConfigProps {
  config: any
  idx: number
}

const OvsConfig = ({ config, idx }: ConfigProps) => {
  const classes = useStyles()

  return (
    <div className={classes.config}>
      <NumberedStepLabel step={idx + 1} title="OVS Config" />
      <div className={classes.configIndent}>
        <ConfigData label="Bridge Name" value={config?.bridgeName} />
        <ConfigData label="Node Interface" value={config?.nodeInterface} />
        <ConfigData label="DPDK" value={config?.dpdk ? 'Enabled' : 'Disabled'} />
        <ConfigData label="Params" value={config?.params ? '' : 'None'} />
        {config?.params && (
          <div className={classes.indentedParams}>
            {config?.mtuRequest && <ConfigData label="MTU Request" value={config?.mtuRequest} />}
            {config?.bondMode && <ConfigData label="Bond Mode" value={config?.bondMode} />}
            {config?.lacp && <ConfigData label="Lacp" value={config?.lacp} />}
          </div>
        )}
      </div>
    </div>
  )
}

const InterfaceConfig = ({ config, idx }: ConfigProps) => {
  const classes = useStyles()
  const vlanIds = config?.vlan?.map((vlan) => vlan?.id).join(', ')

  return (
    <div className={classes.config}>
      <NumberedStepLabel step={idx + 1} title="Interface Config" />
      <div className={classes.configIndent}>
        <ConfigData label="Name" value={config?.name} />
        <ConfigData label="MTU" value={config?.mtu} />
        <ConfigData label="VLAN IDs" value={vlanIds} />
        <ConfigData label="IPV4 Address" value={config?.ipv4?.address ? '' : 'None'} />
        {config?.ipv4?.address && (
          <div className={classes.indentedParams}>
            {config?.ipv4?.address?.map((address) => (
              <Text key={address} variant="caption1">
                {address}
              </Text>
            ))}
          </div>
        )}
        <ConfigData label="IPV6 Address" value={config?.ipv6?.address ? '' : 'None'} />
        {config?.ipv6?.address && (
          <div className={classes.indentedParams}>
            {config?.ipv6?.address?.map((address) => (
              <Text key={address} variant="caption1">
                {address}
              </Text>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const SriovConfig = ({ config, idx }: ConfigProps) => {
  const classes = useStyles()
  const deviceSelector = config?.pciAddr
    ? 'PCI Address'
    : config?.vendorId
    ? 'Vendor & Device ID'
    : 'pfName'

  return (
    <div className={classes.config}>
      <NumberedStepLabel step={idx + 1} title="SR-IOV Config" />
      <div className={classes.configIndent}>
        <ConfigData label="Device Selector" value={deviceSelector} />
        {deviceSelector === 'pfName' && <ConfigData label="pfName" value={config?.pfName} />}
        {deviceSelector === 'Vendor & Device ID' && (
          <>
            <ConfigData label="vendorId" value={config?.vendorId} />
            <ConfigData label="deviceId" value={config?.deviceId} />
          </>
        )}
        {deviceSelector === 'PCI Address' && (
          <ConfigData label="pciAddress" value={config?.pciAddr} />
        )}
        <ConfigData label="numVfs" value={config?.numVfs} />
        <ConfigData label="vfDriver" value={config?.vfDriver} />
      </div>
    </div>
  )
}

const NoConfigs = ({ type }) => {
  const classes = useStyles()
  return (
    <div className={classes.noConfigs}>
      <Text variant="subtitle2">{type} Not Present</Text>
      <Text variant="body1">Add {type} using edit action or YAML.</Text>
    </div>
  )
}

export default function Overview({ hostNetworkTemplate, loading }) {
  const classes = useStyles()

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, hostNetworkTemplate)
  }, [hostNetworkTemplate])

  const overviewData = useMemo(
    () => [
      {
        label: 'Cluster',
        value: hostNetworkTemplate?.clusterName,
      },
      {
        label: 'Namespace',
        value: hostNetworkTemplate?.namespace,
      },
      {
        label: 'Age',
        value: hostNetworkTemplate?.age,
      },
    ],
    [hostNetworkTemplate],
  )

  return (
    <>
      <Progress loading={loading}>
        <div className={classes.overview}>
          <div className={classes.column}>
            <Card
              title={
                <div className={classes.customCardHeader}>
                  <Text variant="subtitle2">{hostNetworkTemplate?.name}</Text>
                </div>
              }
            >
              <Text variant="body2">Network Plugin</Text>
              <InfoBlocks data={overviewData} />
            </Card>
            <InfoCard
              items={metadata}
              title="Properties"
              footer={
                <LabelsAndAnnotationsSection
                  entity={hostNetworkTemplate}
                  resourceType="networkPlugin"
                />
              }
            />
          </div>
          <div className={classes.column}>
            <Card title="Interface Configurations" withCustomBody>
              {hostNetworkTemplate?.spec?.interfaceConfig?.length ? (
                <div className={classes.configurations}>
                  {hostNetworkTemplate?.spec?.interfaceConfig?.map((config, idx) => (
                    <InterfaceConfig key={idx} config={config} idx={idx} />
                  ))}
                </div>
              ) : (
                <NoConfigs type="Interface Configurations" />
              )}
            </Card>
            <Card title="OVS Configurations" withCustomBody>
              {hostNetworkTemplate?.spec?.ovsConfig?.length ? (
                <div className={classes.configurations}>
                  {hostNetworkTemplate?.spec?.ovsConfig?.map((config, idx) => (
                    <OvsConfig key={idx} config={config} idx={idx} />
                  ))}
                </div>
              ) : (
                <NoConfigs type="OVS Configurations" />
              )}
            </Card>
            <Card title="SR-IOV Configurations" withCustomBody>
              {hostNetworkTemplate?.spec?.sriovConfig?.length ? (
                <div className={classes.configurations}>
                  {hostNetworkTemplate?.spec?.sriovConfig?.map((config, idx) => (
                    <SriovConfig key={idx} config={config} idx={idx} />
                  ))}
                </div>
              ) : (
                <NoConfigs type="SR-IOV Configurations" />
              )}
            </Card>
          </div>
        </div>
      </Progress>
    </>
  )
}
