import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'
import { trackEvent } from 'utils/tracking'
import jsYaml from 'js-yaml'
import { pathStr } from 'utils/fp'

const { qbert } = ApiClient.getInstance()

export const persistentVolumeClaimActions = ActionsSet.make<DataKeys.PersistentVolumeClaims>({
  uniqueIdentifier: 'id',
  indexBy: ['clusterId', 'namespace'],
  entityName: entityNamesByKey[DataKeys.PersistentVolumeClaims],
  cacheKey: DataKeys.PersistentVolumeClaims,
})

export const listPersistentVolumeClaims = persistentVolumeClaimActions.add(
  new ListAction<DataKeys.PersistentVolumeClaims, { clusterId: string; namespace?: string }>(
    async (params) => {
      const { clusterId, namespace } = params
      Bugsnag.leaveBreadcrumb('Attempting to get Persistent Volume Claims', params)
      return qbert.getPersistentVolumeClaims(clusterId, namespace)
    },
  )
    .addDependency(DataKeys.StorageClasses)
    .addDependency(DataKeys.PersistentVolumes),
)

export const createPersistentVolumeClaim = persistentVolumeClaimActions.add(
  new CreateAction<
    DataKeys.PersistentVolumeClaims,
    { clusterId: string; namespace: string; body: any }
  >(async ({ clusterId, namespace, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create new persistent volume claim', {
      clusterId,
      namespace,
    })
    const created = await qbert.createPersistentVolumeClaim(clusterId, namespace, body)
    trackEvent('Create New Persistent Volume Claim', {
      clusterId,
      namespace,
      name: pathStr('metadata.name', created),
    })
    return created
  }),
)

export const updatePersistentVolumeClaim = persistentVolumeClaimActions.add(
  new UpdateAction<
    DataKeys.PersistentVolumeClaims,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update persistent volume claim', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn =
      requestType === 'patch' ? qbert.patchPersistentVolumeClaim : qbert.updatePersistentVolumeClaim
    const updatedPersistentVolumeClaim = await updateFn({
      clusterId,
      namespace,
      name,
      body,
      contentType,
    })
    trackEvent('Update Persistent Volume Claim', { clusterId, namespace, name })
    return updatedPersistentVolumeClaim
  }),
)

export const deletePersistentVolumeClaim = persistentVolumeClaimActions.add(
  new DeleteAction<
    DataKeys.PersistentVolumeClaims,
    { clusterId: string; namespace: string; name: string; id: string }
  >(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete persistent volume claim', {
      id,
      clusterId,
      namespace,
      name,
    })
    await qbert.deletePersistentVolumeClaim(clusterId, namespace, name)
    trackEvent('Delete Persistent Volume Claim', { clusterId, namespace, name, id })
  }),
)
