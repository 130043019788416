import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ListAction from 'core/actions/ListAction'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import Bugsnag from 'utils/bugsnag'
import { ensureArray, pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'

const { qbert } = ApiClient.getInstance()

const podDisruptionBudgetActions = ActionsSet.make<DataKeys.PodDisruptionBudgets>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.PodDisruptionBudgets],
  cacheKey: DataKeys.PodDisruptionBudgets,
})

export const listPodDisruptionBudgets = podDisruptionBudgetActions.add(
  new ListAction<DataKeys.PodDisruptionBudgets, { clusterId: string; namespace?: string }>(
    async (params) => {
      Bugsnag.leaveBreadcrumb('Attempting to get Pod Disruption Budgets', params)
      const { namespace, clusterId } = params
      return someAsync(
        ensureArray(namespace).map((name) => qbert.getPodDisruptionBudgets(clusterId, name)),
      ).then(flatten)
    },
  ),
)

export const createPodDisruptionBudget = podDisruptionBudgetActions.add(
  new CreateAction<
    DataKeys.PodDisruptionBudgets,
    { clusterId: string; namespace: string; body: any }
  >(async ({ clusterId, namespace, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create new Pod Disruption Budget', {
      clusterId,
      namespace,
      body,
    })
    const createdPodDisruptionBudget = await qbert.createPodDisruptionBudget(
      clusterId,
      namespace,
      body,
    )
    trackEvent('Create New Pod Disruption Budget', {
      clusterId,
      namespace,
      name: pathStr('metadata.name', createdPodDisruptionBudget),
    })
    return createdPodDisruptionBudget
  }),
)

export const updatePodDisruptionBudget = podDisruptionBudgetActions.add(
  new UpdateAction<
    DataKeys.PodDisruptionBudgets,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Pod Disruption Budget', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn =
      requestType === 'patch' ? qbert.patchPodDisruptionBudget : qbert.updatePodDisruptionBudget
    const updatedPodDisruptionBudget = await updateFn({
      clusterId,
      namespace,
      name,
      body,
      contentType,
    })
    trackEvent('Update Pod Disruption Budget', { clusterId, namespace, name })
    return updatedPodDisruptionBudget
  }),
)

export const deletePodDisruptionBudget = podDisruptionBudgetActions.add(
  new DeleteAction<
    DataKeys.PodDisruptionBudgets,
    { clusterId: string; namespace: string; name: string }
  >(async ({ clusterId, namespace, name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete Pod Disruption Budget', {
      clusterId,
      namespace,
      name,
    })
    await qbert.deletePodDisruptionBudget(clusterId, namespace, name)
  }),
)
