import getDataSelector from 'core/utils/getDataSelector'
import DataKeys from 'k8s/DataKeys'
import { getRules } from './helpers'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { findCluster } from 'k8s/util/helpers'
import { durationBetweenDates } from 'utils/misc'
import { IIngressSelector } from './model'

export const ingressesSelector = createSharedSelector(
  getDataSelector<DataKeys.Ingresses>(
    DataKeys.Ingresses,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  (rawIngresses, allClusters): IIngressSelector[] => {
    return rawIngresses.map((rawIngress) => {
      const { name: clusterName, clusterType: clusterType } = findCluster(
        allClusters,
        rawIngress?.clusterId,
      )
      const defaultBackend = rawIngress.spec?.defaultBackend?.service
        ? {
            name: rawIngress.spec?.defaultBackend?.service?.name,
            portName: rawIngress.spec?.defaultBackend?.service?.port?.name,
            port: rawIngress.spec?.defaultBackend?.service?.port?.number,
          }
        : rawIngress.spec?.defaultBackend?.resource
      return {
        ...rawIngress,
        id: rawIngress.metadata?.uid,
        name: rawIngress.metadata?.name,
        namespace: rawIngress.metadata?.namespace,
        creationTimestamp: rawIngress.metadata?.creationTimestamp,
        defaultBackend,
        rules: getRules(rawIngress),
        clusterName,
        clusterType,
        age: durationBetweenDates({ labels: ['d'] })(rawIngress.metadata?.creationTimestamp),
        ownerReferences: rawIngress.metadata?.ownerReferences,
        labels: rawIngress.metadata?.labels,
        annotations: rawIngress.metadata?.annotations,
      }
    })
  },
)
