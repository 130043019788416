import React, { useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { durationBetweenDates } from 'utils/misc'
import { configMapsSelector, IConfigMapsTabs } from 'k8s/components/config-maps/selectors'
import { listConfigMaps } from 'k8s/components/config-maps/new-actions'
import { useSelector } from 'react-redux'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import ClusterNameCell from 'k8s/components/common/cells/ClusterNameCell'
import AddConfigMapModal from './AddConfigMapModal'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import DeleteConfigMapDialog from './DeleteConfigMapDialog'
import usePluginRouter from 'core/hooks/usePluginRouter'

type ModelDataKey = DataKeys.ConfigMaps
type SelectorModel = ArrayElement<ReturnType<typeof configMapsSelector>>
type ActionParams = InferActionParams<typeof listConfigMaps>
//@fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  masterNodeClusters: boolean
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId', 'namespace']
const defaultParams: Params & SortingState = {
  clusterId: null,
  namespace: null,
  masterNodeClusters: true,
  healthyClusters: true,
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'ConfigMaps',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']

export const getConfigMapColumns = (pluginId = 'kubernetes'): GridViewColumn<SelectorModel>[] => {
  const routePath = pluginId === 'kubevirt' ? 'kubevirtConfiguration' : 'configuration'
  return [
    {
      key: 'name',
      label: 'Name',
      width: 'medium',
      CellComponent: createGridLinkCell({
        routeToFn: ({ clusterId, id }) =>
          routes[routePath].configMaps.details.path({
            clusterId,
            id,
            tab: IConfigMapsTabs.Overview,
          }),
      }),
    },
    { key: 'clusterName', label: 'Cluster', width: 'medium', CellComponent: ClusterNameCell },
    { key: 'namespace', label: 'Namespace' },
    {
      key: 'created',
      label: 'Age',
      formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
    },
    { key: 'dataSize', label: 'Size' },
    {
      key: 'labels',
      label: 'Labels',
      disableSorting: true,
      CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
    },
    {
      key: 'annotations',
      label: 'Annotations',
      disableSorting: true,
      CellComponent: createResourceLabelsCell({ type: 'table', separator: ': ' }),
    },
  ]
}

export default function ConfigMapsListPage() {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtConfiguration' : 'configuration'
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listConfigMaps, {
    params,
    requiredParams,
  })
  const configMaps = useSelector(configMapsSelector)

  const columns = getConfigMapColumns(currentPluginId)

  const [selectedConfigMap, setSelectedConfigMap] = useState<SelectorModel>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (configMap) => {
        setSelectedConfigMap(configMap)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="Config Maps" />
      <AddConfigMapModal addRoute={routes[routePath].configMaps.add} />
      {showDeleteDialog && (
        <DeleteConfigMapDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedConfigMap]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.ConfigMaps}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={configMaps}
        columns={columns}
        addUrl={routes[routePath].configMaps.add.path()}
        addText="Add Config Map"
        getParamsUpdater={getParamsUpdater}
        rowMenuItems={rowMenuItems}
        showItemsCountInLabel
        showRowMenuForSingleRowActions
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
