import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import Text from 'core/elements/Text'
import InfoBlocks from 'core/components/entity-page/InfoBlocks'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import Grid from 'core/elements/grid'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import CardHeaderWithLink from 'core/elements/card/CardHeaderWithLink'
import EditPluginsButton from './EditPluginsButton'
import { NetworkPluginArrayItem } from '../model'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import EnablePluginDialog from './EnablePluginDialog'
import DisablePluginDialog from './DisablePluginDialog'
import EnableSriovModal from './EnableSriovModal'
import EnableOvsModal from './EnableOvsModal'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  customCardHeader: {
    display: 'flex',
    padding: '16px 32px 0px',
    gap: 8,
    alignItems: 'center',
  },
  pluginStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  enabled: {
    fontSize: 10,
    color: theme.palette.green.main,
  },
  disabled: {
    fontSize: 10,
    color: theme.palette.red.main,
  },
}))

const renderOvsPlugin = (values) => (
  <>
    {!!values?.dpdk ? (
      <div>
        <div>DPDK Enabled</div>
        <div>Lcore Mask: {values?.dpdk?.lcoreMask || 'N/A'}</div>
        <div>Socket Memory: {values?.dpdk?.socketMem || 'N/A'}</div>
        <div>PMD CPU Mask: {values?.dpdk?.pmdCpuMask || 'N/A'}</div>
      </div>
    ) : (
      <div>DPDK Disabled</div>
    )}
  </>
)

const renderSriovPlugin = (values) => <div>{values?.namespace || 'luigi-system'}</div>

const metadataFields = [
  {
    id: 'spec.plugins.ovs',
    title: 'OVS Plugin Configuration',
    render: renderOvsPlugin,
    condition: (item) => {
      return !!item?.spec?.plugins?.ovs
    },
  },
]

const EnabledCell = ({ value }) => {
  const classes = useStyles()
  return (
    <div className={classes.pluginStatus}>
      <FontAwesomeIcon solid className={value ? classes.enabled : classes.disabled}>
        circle
      </FontAwesomeIcon>
      {value ? 'Enabled' : 'Disabled'}
    </div>
  )
}

export const pluginColumns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'enabled',
    label: 'Status',
    CellComponent: EnabledCell,
  },
  { key: 'type', label: 'Type' },
  {
    key: 'namespace',
    label: 'Namespace',
  },
]

const pluginIsEnabled = (pluginType, networkPlugin) => {
  const plugins = networkPlugin?.spec?.plugins || {}
  return {
    'Host Plumber': !!plugins?.hostPlumber,
    'Multus CNI': !!plugins?.multus,
    'Node Feature Discovery': !!plugins?.nodeFeatureDiscovery,
    'SR-IOV': !!plugins?.sriov,
    OVS: !!plugins?.ovs,
    'Platform9 DHCP': !!plugins?.dhcpController,
    Whereabouts: !!plugins?.whereabouts,
  }[pluginType?.name]
}

export default function Overview({ networkPlugin, loading }) {
  const classes = useStyles()

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, networkPlugin)
  }, [networkPlugin])

  const overviewData = useMemo(
    () => [
      {
        label: 'Cluster',
        value: networkPlugin?.clusterName,
      },
      {
        label: 'Namespace',
        value: networkPlugin?.namespace,
      },
      {
        label: 'Age',
        value: networkPlugin?.age,
      },
    ],
    [networkPlugin],
  )

  const [selectedPlugin, setSelectedPlugin] = useState<NetworkPluginArrayItem>(null)
  const [showEnablePluginModal, setShowEnablePluginModal] = useState<boolean>(false)
  const [showDisablePluginModal, setShowDisablePluginModal] = useState<boolean>(false)
  const [showCustomEnablePluginModal, setShowCustomEnablePluginModal] = useState<boolean>(false)
  const CustomPluginDialog = useMemo(() => {
    if (selectedPlugin?.name === 'SR-IOV') {
      return EnableSriovModal
    } else if (selectedPlugin?.name === 'OVS') {
      return EnableOvsModal
    } else {
      return null
    }
  }, [selectedPlugin])

  const rowMenuItems: Array<GridRowMenuItemSpec<NetworkPluginArrayItem>> = useMemo(
    () => [
      {
        cond: (plugin) => {
          return !pluginIsEnabled(plugin, networkPlugin)
        },
        label: 'Enable',
        handleClick: (plugin) => {
          setSelectedPlugin(plugin)
          if (['SR-IOV', 'OVS'].includes(plugin?.name)) {
            setShowCustomEnablePluginModal(true)
          } else {
            setShowEnablePluginModal(true)
          }
        },
        refreshAfterSuccess: true,
        hideIfDisabled: true,
      },
      {
        cond: (plugin) => {
          // Do not allow users to disable multus
          if (plugin?.name === 'Multus CNI') {
            return false
          }
          return pluginIsEnabled(plugin, networkPlugin)
        },
        label: 'Disable',
        handleClick: (plugin) => {
          setSelectedPlugin(plugin)
          setShowDisablePluginModal(true)
        },
        refreshAfterSuccess: true,
        hideIfDisabled: true,
      },
    ],
    [networkPlugin],
  )

  return (
    <>
      {showEnablePluginModal && (
        <EnablePluginDialog
          networkPlugin={networkPlugin}
          selectedPlugin={selectedPlugin}
          onClose={() => setShowEnablePluginModal(false)}
        />
      )}
      {showDisablePluginModal && (
        <DisablePluginDialog
          networkPlugin={networkPlugin}
          selectedPlugin={selectedPlugin}
          onClose={() => setShowDisablePluginModal(false)}
        />
      )}
      {showCustomEnablePluginModal && (
        <CustomPluginDialog
          networkPlugin={networkPlugin}
          onClose={() => setShowCustomEnablePluginModal(false)}
        />
      )}
      <Progress loading={loading}>
        <div className={classes.overview}>
          <div className={classes.column}>
            <Card
              title={
                <div className={classes.customCardHeader}>
                  <Text variant="subtitle2">{networkPlugin?.name}</Text>
                </div>
              }
            >
              <Text variant="body2">Network Plugin</Text>
              <InfoBlocks data={overviewData} />
            </Card>
            <InfoCard
              items={metadata}
              title="Properties"
              footer={
                <LabelsAndAnnotationsSection entity={networkPlugin} resourceType="networkPlugin" />
              }
            />
          </div>
          <div className={classes.column}>
            <Card
              title={
                <CardHeaderWithLink
                  linkComponent={<EditPluginsButton networkPlugin={networkPlugin} />}
                >
                  Plugins
                </CardHeaderWithLink>
              }
              withCustomBody
            >
              <Grid
                uniqueIdentifier="name"
                data={networkPlugin?.arrayVersion || []}
                columns={pluginColumns}
                loading={!networkPlugin}
                rowMenuItems={rowMenuItems}
                rowMenuOffset={{ vertical: 30 }}
                showRowMenuForSingleRowActions
                compact
                disableToolbar
              />
            </Card>
          </div>
        </div>
      </Progress>
    </>
  )
}
