import React, { useMemo } from 'react'
import { projectAs } from 'utils/fp'
import useListAction from 'core/hooks/useListAction'
import { listStorageClasses } from './storage-classes/new-actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { storageClassSelector } from './storage-classes/selectors'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {
  clusterId: string
}

export default function StorageClassesPicklist({
  clusterId,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading } = useListAction(listStorageClasses, { params: { clusterId } })
  const storageClasses = useSelectorWithParams(storageClassSelector, {
    clusterId,
    useGlobalParams: false,
  })
  const items = useMemo(() => {
    return [...projectAs({ label: 'name', value: 'name' }, storageClasses)]
  }, [storageClasses])

  return (
    <AsyncDropdown
      name="storageClass"
      label="Storage Class"
      items={items}
      loading={loading}
      showAll={false}
      formField
      {...rest}
    />
  )
}
