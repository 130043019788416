import React, { useCallback } from 'react'
import DriftAnalysis from './DriftAnalysis'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { Tooltip } from '@material-ui/core'
import Text from 'core/elements/Text'
import clsx from 'clsx'
import Button from 'core/elements/button'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  summaryContainer: {
    background: theme.palette.grey['000'],
    display: 'grid',
    gridGap: theme.spacing(0.5),
    padding: theme.spacing(3),
    minWidth: 324,
    marginRight: theme.spacing(3),
  },
  summaryField: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  largeTopMargin: {
    marginTop: theme.spacing(2),
  },
  roleCounts: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  button: {
    width: '100%',
  },
  analysis: {
    flexGrow: 1,
  },
}))

// Deprecated in favor of wizard summary
const DriftSummary = ({ wizardContext, createFn }) => {
  const classes = useStyles({})

  const deployCluster = useCallback(() => {
    return createFn({
      cluster: wizardContext.clusters[0],
      profileName: wizardContext.profiles[0]?.name,
    })
  }, [wizardContext])

  return (
    <div className={classes.summaryContainer}>
      <Text variant="caption1">DRIFT SUMMARY</Text>
      <div className={clsx(classes.summaryField, classes.largeTopMargin)}>
        <Text variant="body2">Profile Name:</Text>
        <Text variant="caption1">{wizardContext.profiles[0]?.name}</Text>
      </div>
      <div className={classes.summaryField}>
        <Text variant="body2">Cluster:</Text>
        <Text variant="caption1">{wizardContext.clusters[0]?.name}</Text>
      </div>
      <div className={clsx(classes.roleCounts, classes.largeTopMargin)}>
        <div className={classes.summaryField}>
          <Text variant="body2">Total Roles:</Text>
          <Text variant="caption1">{wizardContext.profiles[0]?.roles.length}</Text>
        </div>
        <div className={classes.summaryField}>
          <Text variant="body2">Total Cluster Roles:</Text>
          <Text variant="caption1">{wizardContext.profiles[0]?.clusterRoles.length}</Text>
        </div>
        <div className={classes.summaryField}>
          <Text variant="body2">Total Role Bindings:</Text>
          <Text variant="caption1">{wizardContext.profiles[0]?.roleBindings.length}</Text>
        </div>
        <div className={classes.summaryField}>
          <Text variant="body2">Total Cluster Role Bindings:</Text>
          <Text variant="caption1">{wizardContext.profiles[0]?.clusterRoleBindings.length}</Text>
        </div>
      </div>
      <Tooltip
        title={
          wizardContext.clusters[0]?.hasBinding ? (
            <>
              A profile must be deployed onto a cluster without an active profile. Delete the
              cluster's associated profile binding to deploy a new profile onto this cluster.
            </>
          ) : (
            ''
          )
        }
      >
        <div>
          <Button
            disabled={wizardContext.clusters[0]?.hasBinding}
            onClick={deployCluster}
            className={clsx(classes.button, classes.largeTopMargin)}
          >
            Deploy Profile
          </Button>
        </div>
      </Tooltip>
    </div>
  )
}

const DriftAnalysisResult = ({ wizardContext }) => {
  const classes = useStyles({})

  return (
    <div className={classes.container}>
      <div>
        <DriftAnalysis
          className={classes.analysis}
          analysisString={wizardContext.analysis}
          clusterId={wizardContext.clusters?.[0]?.uuid}
        />
      </div>
    </div>
  )
}

export default DriftAnalysisResult
