import { clientActions } from 'core/client/clientReducers'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import { listReplicaSets, updateReplicaSet } from './actions'
import { listPods } from 'k8s/components/pods/new-actions'
import useListAction from 'core/hooks/useListAction'
import { routes } from 'core/utils/routes'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import StatefulSetOverview from './overview'
import CardsHeader from '../common/entity/cards-header'
import ReplicasCard from '../common/entity/replicas-card'
import PodsStatusCard from '../common/entity/pods-status-card'
import EntityPodsPage from '../common/entity/entity-pods-page'
import EntityEventsPage from '../common/entity/entity-events-page'
import EntityLogsPage from '../common/entity/entity-logs-page'
import EntityYamlPage from '../common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import { pathStrOr, isNilOrEmpty } from 'utils/fp'
import jsYaml from 'js-yaml'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { replicaSetSelector } from './selectors'
import { IReplicaSetDetailsPageTabs, IReplicaSetSelector } from './model'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'

const { qbert } = ApiClient.getInstance()

const defaultReplicaSet = {
  volumes: [],
} as IReplicaSetSelector

const ReplicaSetOverviewPage = () => {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { globalParams: params } = useGlobalParams(useParams, {})
  const { loading, reload } = useListAction(listReplicaSets, {
    params,
  })
  const replicaSets = useSelectorWithParams(replicaSetSelector, {
    clusterId,
    useGlobalParams: false,
  })
  const replicaSet = useMemo(
    () => replicaSets.find((replicaSet) => replicaSet.id === id) || defaultReplicaSet,
    [id, replicaSets],
  )

  const { update, updating } = useUpdateAction(updateReplicaSet)

  const { loading: loadingPods, reload: reloadPods } = useListAction(listPods, { params })

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: replicaSet?.clusterName || clusterId,
        id: replicaSet?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [replicaSet?.clusterName, replicaSet?.name, id, clusterId])

  const getReplicaSetYaml = useCallback(async () => {
    if (isNilOrEmpty(replicaSet)) return undefined
    return qbert.getReplicaSet(replicaSet.clusterId, replicaSet.namespace, replicaSet.name)
  }, [replicaSet])

  const handleReplicaSetUpdate = useCallback(
    async (yaml) => {
      const body = jsYaml.load(yaml)
      const namespace = pathStrOr('', 'metadata.namespace', body)
      const name = pathStrOr('', 'metadata.name', body)
      await update({ clusterId, namespace, name, id: replicaSet?.id, body })
    },
    [clusterId, replicaSet?.id],
  )

  return (
    <>
      <DocumentMeta title="Replica Set Overview" breadcrumbs />
      <CardsHeader>
        <PodsStatusCard pods={replicaSet?.pods} />
        <ReplicasCard
          numeratorValue={replicaSet?.replicas?.available}
          denomValue={replicaSet?.replicas?.desired}
          numeratorTitle="Current"
          denomTitle="Desired"
        />
      </CardsHeader>
      <Tabs route={routes.replicaSets.details}>
        <Tab label="Overview" value={IReplicaSetDetailsPageTabs.Overview}>
          <StatefulSetOverview replicaSet={replicaSet} loading={loading} />
        </Tab>
        <Tab label="Pods" value={IReplicaSetDetailsPageTabs.Pods}>
          <EntityPodsPage
            pods={replicaSet?.pods}
            loading={loading || loadingPods}
            reload={reloadPods}
          />
        </Tab>
        <Tab label="Events" value={IReplicaSetDetailsPageTabs.Events}>
          <EntityEventsPage entity={replicaSet} loading={loading} />
        </Tab>
        <Tab label="Logs" value={IReplicaSetDetailsPageTabs.Logs}>
          <EntityLogsPage pods={replicaSet?.pods} loading={loading} />
        </Tab>
        <Tab label="YAML" value={IReplicaSetDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Replica Set"
            entityName={replicaSet?.name}
            getYamlFn={getReplicaSetYaml}
            handleUpdate={handleReplicaSetUpdate}
            loading={updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default ReplicaSetOverviewPage
