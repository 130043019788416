import { AppSelector } from 'app/store'
import { createSelector } from '@reduxjs/toolkit'
import { complement, isNil, pipe, propEq, propSatisfies } from 'ramda'
import { podsByClusterIdAndNamespaceSelector } from 'k8s/components/pods/selectors'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { findClusterName } from 'k8s/util/helpers'
import { arrayIfEmpty, emptyArr, filterIf } from 'utils/fp'
import { allKey } from 'app/constants'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import { getPodsMounted } from './helpers'
import { IPersistentVolumeClaimsSelector } from './model'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { podsSelector } from 'k8s/components/pods/selectors'
import { durationBetweenDates } from 'utils/misc'
import { persistentVolumeSelector } from 'k8s/components/storage/persistent-volume/selectors'

export const persistentVolumeClaimsSelector: AppSelector<IPersistentVolumeClaimsSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.PersistentVolumeClaims>(
    DataKeys.PersistentVolumeClaims,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  persistentVolumeSelector,
  allClustersSelector,
  podsByClusterIdAndNamespaceSelector,
  // Should this data selector just be the storageClassSelector instead?
  // When this selector didn't have global params set while other one did,
  // the global params didn't work on the other one
  getDataSelector<DataKeys.StorageClasses>(DataKeys.StorageClasses, ['clusterId'], ['clusterId']),
  (rawPvcs, persistentVolumes, allClusters, podsByClusterIdAndNamespace, storageClasses) => {
    const mappedPersistentVolumes = persistentVolumes.reduce((accum, pv) => {
      const claimRef = pv?.spec?.claimRef
      return {
        ...accum,
        [claimRef?.uid]: pv,
      }
    }, {})
    return rawPvcs
      .map((pvc) => {
        const { clusterId, namespace } = pvc
        const associatedPods = podsByClusterIdAndNamespace?.[clusterId]?.[namespace] || emptyArr
        const storageClassName = pvc?.spec?.storageClassName
        const storageClass = storageClasses.find((sc) => sc.name === storageClassName)
        const creationTimestamp = pvc?.metadata?.creationTimestamp
        return {
          ...pvc,
          podsMounted: getPodsMounted(pvc, associatedPods),
          labels: pvc?.metadata?.labels,
          accessModes: pvc?.spec?.accessModes,
          storageClassName,
          storageClass,
          volume: pvc?.spec?.volumeName,
          capacity: pvc?.status?.capacity,
          clusterName: findClusterName(allClusters, pvc.clusterId),
          type: pvc?.parameters?.type,
          creationTimestamp,
          age: durationBetweenDates({ labels: ['d'] })(creationTimestamp),
          persistentVolume: mappedPersistentVolumes[pvc?.id],
        }
      })
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

export const makePersistentVolumeClaimSelector = (
  defaultParams = {} as SortConfig & { clusterId?: string; namespace?: string },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(persistentVolumeClaimsSelector, selectParams, (pvcs, params) => {
    const { clusterId, namespace, orderBy, orderDirection } = params
    return pipe<
      IPersistentVolumeClaimsSelector[],
      IPersistentVolumeClaimsSelector[],
      IPersistentVolumeClaimsSelector[],
      IPersistentVolumeClaimsSelector[],
      IPersistentVolumeClaimsSelector[]
    >(
      filterIf(clusterId && clusterId !== allKey, propEq('clusterId', clusterId)),
      filterIf(namespace && namespace !== allKey, propEq('namespace', namespace)),
      createSorter({ orderBy, orderDirection }),
      arrayIfEmpty,
    )(pvcs)
  })
}

export const pvcByPVSelector: AppSelector<Map<
  string,
  IPersistentVolumeClaimsSelector[]
>> = createSharedSelector(persistentVolumeClaimsSelector, (pvcs) => {
  const pvcByPv = new Map<string, IPersistentVolumeClaimsSelector[]>()
  for (const pvc of pvcs) {
    const volumeName = pvc?.spec?.volumeName
    if (volumeName) {
      const current = [...(pvcByPv.get(volumeName) || []), pvc]
      pvcByPv.set(volumeName, current)
    }
  }
  return pvcByPv
})

export const availablePvcsSelector: AppSelector<IPersistentVolumeClaimsSelector[]> = createSharedSelector(
  persistentVolumeClaimsSelector,
  podsSelector,
  (pvcs, pods) => {
    const podPvcs = pods.reduce((accum, pod) => {
      const podVolumes = pod?.spec?.volumes || []
      const volumePvcs = podVolumes
        .filter((vol) => vol?.persistentVolumeClaim?.claimName)
        .map((vol) => vol.persistentVolumeClaim.claimName)
      return [...accum, ...volumePvcs]
    }, [])
    return pvcs.filter((pvc) => !podPvcs.includes(pvc?.name))
  },
)
