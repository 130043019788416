import { IGenericResource } from 'api-client/qbert.model'

export enum IPersistentVolumeDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface IPersistentVolumeSelector extends IGenericResource<PersistentVolumesItem> {
  namespace: string
  accessModes: PersistentVolumesItem['spec']['accessModes']
  storageClass: PersistentVolumesItem['spec']['storageClassName']
  reclaimPolicy: PersistentVolumesItem['spec']['persistentVolumeReclaimPolicy']
  capacity: PersistentVolumesItem['spec']['capacity']
  clusterName: string
  type: string
  creationTimestamp: string
  age: string
}

export interface PersistentVolumesResponse {
  kind: string
  apiVersion: string
  metadata: PersistentVolumesResponseMetadata
  items: PersistentVolumesItem[]
}

export interface PersistentVolumesItem {
  metadata: ItemMetadata
  parameters?: { [key: string]: any }
  spec: Spec
  status: Status
}

interface ItemMetadata {
  [key: string]: any
  name: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  annotations: Record<string, string>
  labels?: Record<string, string>
  finalizers: string[]
}

interface Spec {
  [key: string]: any
  capacity: Capacity
  csi: CSI
  accessModes: string[]
  claimRef: ClaimRef
  persistentVolumeReclaimPolicy: string
  storageClassName: string
  volumeMode: string
  nodeAffinity: Record<string, unknown>
}

interface Capacity {
  storage: string
}

interface ClaimRef {
  kind: string
  namespace: string
  name: string
  uid: string
  apiVersion: string
  resourceVersion: string
}

interface CSI {
  driver: string
  volumeHandle: string
  volumeAttributes: Record<string, string>
}

interface Status {
  [key: string]: any
  phase: string
}

interface PersistentVolumesResponseMetadata {
  resourceVersion: string
}
