import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import React, { useCallback, useMemo } from 'react'
import { listCronjobs, deleteCronjob, listJobs } from './actions'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { allKey, listTablePrefs, TablePrefsParams } from 'app/constants'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { pick } from 'ramda'
import { makeCronjobsAndJobsSelector } from './selectors'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import { renderResourceLabels } from '../common/entity/labels-and-annotations/helpers'
import { routes } from 'core/utils/routes'
import JobTypePicklist from './JobTypePicklist'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridFilterSpec } from 'core/elements/grid/hooks/useGridFiltering'
import ListContainer from 'core/containers/ListContainer'
import DropdownButton from 'core/components/DropdownButton'
import { ICronjobSelector } from 'k8s/components/cronjobs/cronjob-model'
import AddWorkloadResourcePage from 'k8s/components/common/entity/AddResourcePage'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import InferActionParams from 'core/actions/InferActionParams'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import DeleteJobDialog from './DeleteJobDialog'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'
import { durationBetweenDates } from 'utils/misc'

type ModelDataKey = DataKeys.Cronjobs
type SelectorModel = ICronjobSelector
type ActionParams = InferActionParams<typeof listCronjobs>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  namespace?: string
  masterNodeClusters: boolean
  healthyClusters: boolean
  type: string
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']
const defaultParams: Params = {
  clusterId: null,
  masterNodeClusters: true,
  healthyClusters: true,
  type: allKey,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Cronjobs', listTablePrefs)
const cronjobsAndJobsSelector = makeCronjobsAndJobsSelector()

const searchTargets = ['name', 'clusterName']

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ type, clusterId, id }) => {
        const routeKey = type === 'job' ? 'jobs' : 'cronjobs'
        return routes[routeKey].details.path({ clusterId, id })
      },
    }),
  },
  { key: 'type', label: 'Type' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'clusterName', label: 'Cluster', width: 'medium' },
  {
    key: 'creationTimestamp',
    label: 'Age',
    formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
  {
    key: 'labels',
    label: 'Labels',
    render: renderResourceLabels({ type: 'table', separator: '=' }),
  },
]

const addButtonConfigs = [
  {
    label: 'Create Cronjob',
    link: routes.cronjobs.add.path(),
  },
  {
    label: 'Create Job',
    link: routes.jobs.add.path(),
  },
]

const renderCreateButton = () => <DropdownButton links={addButtonConfigs} addText={'Add Job'} />

const batchActions = [
  {
    label: 'Delete',
    icon: 'trash-alt',
    BatchActionButton: getGridDialogButton(DeleteJobDialog),
  },
]

export default function CronjobsListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { loading: loadingCronJobs, reload: reloadCronJobs } = useListAction(listCronjobs, {
    params,
    requiredParams,
  })

  const { message, loading: loadingJobs, reload: reloadJobs } = useListAction(listJobs, {
    params,
    requiredParams,
  })

  // Need to strip cluster & namespace out of params for the selector bc using global params
  const data = useSelectorWithParams(cronjobsAndJobsSelector, { type: params.type })

  const filters = useMemo(
    () => [
      {
        columnKey: 'type',
        FilterComponent: JobTypePicklist,
        onChange: getParamsUpdater('type'),
      } as GridFilterSpec<SelectorModel, Params, 'type'>,
    ],
    [],
  )

  const reload = useCallback(async (refetch = false, updateLoadingState = true) => {
    reloadJobs(refetch, updateLoadingState)
    reloadCronJobs(refetch, updateLoadingState)
  }, [])

  return (
    <>
      <DocumentMeta title="Cronjobs and Jobs" />
      <AddWorkloadResourcePage resourceType="cronjob" addRoute={routes.cronjobs.add} />
      <AddWorkloadResourcePage resourceType="job" addRoute={routes.jobs.add} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.Cronjobs}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loadingJobs || loadingCronJobs}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        filters={filters}
        addUrl={routes.cronjobs.add.path()}
        AddButtonComponent={renderCreateButton}
        getParamsUpdater={getParamsUpdater}
        batchActions={batchActions}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
