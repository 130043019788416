import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import CreateAction from 'core/actions/CreateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { trackEvent } from 'utils/tracking'
import jsYaml from 'js-yaml'
import { ensureArray, pathStr } from 'utils/fp'
import store from 'app/store'
import { compareVersions } from 'k8s/util/helpers'
import DeleteAction from 'core/actions/DeleteAction'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { someAsync } from 'utils/async'
import { flatten } from 'ramda'

const { qbert } = ApiClient.getInstance()
const minKubernetesVersion = '1.21'

const getApiVersion = (params): string => {
  const state = store.getState()
  const allClusters: CombinedClusterSelector[] = allClustersSelector(state, params)
  const targetCluster = allClusters.find((cluster) => cluster.uuid === params.clusterId)

  if ('kubeRoleVersion' in targetCluster) {
    const beta = compareVersions(targetCluster.kubeRoleVersion, minKubernetesVersion) < 0
    return beta ? 'v1beta1' : 'v1'
  }
  return 'v1'
}

export const jobActions = ActionsSet.make<DataKeys.Jobs>({
  uniqueIdentifier: 'id',
  indexBy: ['clusterId', 'namespace'],
  entityName: entityNamesByKey.Jobs,
  cacheKey: DataKeys.Jobs,
})

export const listJobs = jobActions.add(
  new ListAction<DataKeys.Jobs, { clusterId: string; namespace?: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Jobs', params)
    const apiVersion = getApiVersion(params)
    const { clusterId, namespace } = params
    return qbert.getJobs(clusterId, apiVersion, namespace)
  }).addDependency(DataKeys.Pods),
)

export const updateJob = jobActions.add(
  new UpdateAction<
    DataKeys.Jobs,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async (params) => {
    const { clusterId, namespace, name, body, requestType = 'put', contentType } = params
    Bugsnag.leaveBreadcrumb('Attempting to update Job', params)
    const apiVersion = getApiVersion(params)
    const updateFn = requestType === 'patch' ? qbert.patchJob : qbert.updateJob
    const updatedJob = await updateFn(clusterId, namespace, name, body, apiVersion, contentType)
    trackEvent('Update Job', params)
    return updatedJob
  }),
)

export const createJob = jobActions.add(
  new CreateAction<DataKeys.Jobs, { clusterId: string; namespace: string; yaml: string }>(
    async ({ clusterId, namespace, yaml }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create Job', { clusterId, namespace, yaml })
      const body = jsYaml.load(yaml)
      const apiVersion = getApiVersion({ clusterId })

      const created = await qbert.createJob(clusterId, namespace, body, apiVersion)
      trackEvent('Create Job', {
        id: pathStr('metadata.uid', created),
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const deleteJob = jobActions.add(
  new DeleteAction<
    DataKeys.Jobs,
    { clusterId: string; namespace: string; name: string; id: string }
  >(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete a Job', {
      clusterId,
      namespace,
      name,
      id,
    })
    await qbert.deleteJob(clusterId, namespace, name)
    trackEvent('Delete Job', { clusterId, namespace, name, id })
  }),
)

export const cronjobActions = ActionsSet.make<DataKeys.Cronjobs>({
  uniqueIdentifier: 'id',
  indexBy: ['clusterId', 'namespace'],
  entityName: entityNamesByKey.Cronjobs,
  cacheKey: DataKeys.Cronjobs,
})

export const listCronjobs = cronjobActions.add(
  new ListAction<DataKeys.Cronjobs, { clusterId: string; namespace?: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Cronjobs', params)
    const apiVersion = getApiVersion(params)
    const { clusterId, namespace } = params
    return qbert.getCronjobs(clusterId, apiVersion, namespace)
  }).addDependency(DataKeys.Jobs), // check if any way to add more than one dependency just in case later
)

export const updateCronjob = cronjobActions.add(
  new UpdateAction<
    DataKeys.Cronjobs,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
    }
  >(async (params) => {
    const { clusterId, namespace, name, body } = params
    const apiVersion = getApiVersion(params)

    const result = await qbert.updateCronjob(clusterId, namespace, name, body, apiVersion)
    trackEvent('Update Cronjob', params)
    return result
  }),
)

export const createCronjob = cronjobActions.add(
  new CreateAction<DataKeys.Cronjobs, { clusterId: string; namespace: string; yaml: string }>(
    async ({ clusterId, namespace, yaml }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create Cronjob', { clusterId, namespace, yaml })
      const body = jsYaml.load(yaml)
      const apiVersion = getApiVersion({ clusterId })

      const created = await qbert.createCronjob(clusterId, namespace, body, apiVersion)
      trackEvent('Create Cronjob', {
        id: pathStr('metadata.uid', created),
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const deleteCronjob = cronjobActions.add(
  new DeleteAction<
    DataKeys.Cronjobs,
    { clusterId: string; namespace: string; name: string; id: string }
  >(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete a Cronjob', {
      clusterId,
      namespace,
      name,
      id,
    })
    await qbert.deleteCronjob(clusterId, namespace, name)
    trackEvent('Delete Cronjob', { clusterId, namespace, name, id })
  }),
)
