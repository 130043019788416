import { BadgeVariant } from 'core/elements/badge/Badge'

export const getDeployedAppHealthStatusBadgeVariant = (status): BadgeVariant => {
  switch (status) {
    case 'deployed':
      return 'success'
    case 'uninstalled':
    case 'failed':
      return 'error'
    case 'superseded':
    case 'pending-install':
    case 'pending-upgrade':
      return 'warning'
    case 'uninstalling':
    case 'pending-rollback':
      return 'danger'
    default:
      return 'unknown'
  }
}
