import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {}

// values match with moment's add/subtract API for use in actions.js
const items = [
  { label: '15 Minutes', value: '15.m' },
  { label: '30 Minutes', value: '30.m' },
  { label: '1 Hour', value: '1.h' },
  { label: '4 Hours', value: '4.h' },
  { label: '12 Hours', value: '12.h' },
  { label: '24 Hours', value: '24.h' },
]

export default function SnoozeTimerPicklist(props: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown name="snoozeTime" label="Time" items={items} compact {...props} />
}
