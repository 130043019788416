import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import createCRUDActions from 'core/helpers/createCRUDActions'
import { ActionDataKeys } from 'k8s/DataKeys'
import {
  makeRbacProfileBindingsSelector,
  makeRbacProfilesSelector,
  rbacProfileBindingsSelector,
  rbacProfilesSelector,
} from './selectors'
import { trackEvent } from 'utils/tracking'
import { IRbacProfileDetails } from 'k8s/components/rbac/model'
import listFnWithDependencies from 'core/helpers/list-with-dependencies'

const { qbert } = ApiClient.getInstance()

const uniqueIdentifier = 'metadata.name'

export const loadProfileDetails = async (namespace, name): Promise<IRbacProfileDetails> => {
  return qbert.getRbacProfileDetails(namespace, name)
}

export const rbacProfileBindingsActions = createCRUDActions(ActionDataKeys.RbacProfileBindings, {
  listFn: async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get rbac profile bindings')
    return qbert.getRbacProfileBindings()
  },
  createFn: async ({ cluster, profileName }) => {
    const clusterId = cluster.uuid
    const body = {
      apiVersion: 'sunpike.platform9.com/v1alpha2',
      kind: 'ClusterProfileBinding',
      metadata: {
        name: `${profileName}-binding-${Date.now()}`,
      },
      spec: {
        clusterRef: clusterId,
        profileRef: `default/${profileName}`,
        dryRun: false,
      },
    }
    Bugsnag.leaveBreadcrumb('Attempting to create rbac profile binding', { clusterId, profileName })
    trackEvent('Create RBAC Profile Binding', {
      clusterId,
      profileName,
    })
    const profileBinding = await qbert.createRbacProfileBinding(body)
    return profileBinding
  },
  updateFn: async (data) => {
    Bugsnag.leaveBreadcrumb('Attempting to update rbac profile binding')
  },
  deleteFn: async ({ name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete rbac profile binding')
    trackEvent('Delete RBAC Profile Binding', {
      name,
    })
    return qbert.deleteRbacProfileBinding(name)
  },
  uniqueIdentifier,
  entityName: 'RbacProfileBinding',
  selector: rbacProfileBindingsSelector,
  selectorCreator: makeRbacProfileBindingsSelector,
})

export const rbacProfileActions = createCRUDActions(ActionDataKeys.RbacProfiles, {
  listFn: listFnWithDependencies(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get rbac profiles')
    return qbert.getRbacProfiles()
  }, [rbacProfileBindingsActions.list]),
  createFn: async (data) => {
    const roles = Object.values(data.roles).map(
      ({ name, namespace }) => `${namespace}/roles/${name}`,
    )
    const roleBindings = Object.values(data.roleBindings).map(
      ({ name, namespace }) => `${namespace}/rolebindings/${name}`,
    )
    const clusterRoles = Object.values(data.clusterRoles).map(({ name }) => `clusterroles/${name}`)
    const clusterRoleBindings = Object.values(data.clusterRoleBindings).map(
      ({ name }) => `clusterrolebindings/${name}`,
    )
    const body = {
      apiVersion: 'sunpike.platform9.com/v1alpha2',
      kind: 'ClusterProfile',
      metadata: { name: data.profileName },
      spec: {
        cloneFrom: data.baseCluster,
        namespaceScopedResources: [...roles, ...roleBindings],
        clusterScopedResources: [...clusterRoles, ...clusterRoleBindings],
        reapInterval: 30,
      },
    }
    Bugsnag.leaveBreadcrumb('Attempting to create a rbac profile', body)
    const profile = await qbert.createRbacProfile(body)
    trackEvent('Create Rbac Profile', body)
    return profile
  },
  deleteFn: async ({ name }, currentItems) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete rbac profile', { name })
    trackEvent('Delete RBAC Profile', {
      name,
    })
    return qbert.deleteRbacProfile(name)
  },
  customOperations: {
    updateDetails: async (
      {
        name,
        namespace,
        details,
      }: {
        name: string
        namespace: string
        details: IRbacProfileDetails
      },
      prevItems,
    ) => {
      Bugsnag.leaveBreadcrumb('Attempting to update rbac profile details')
      trackEvent('Update RBAC Profile details', details)
      await qbert.putRbacProfileDetails(name, namespace, details)
      return prevItems
    },
  },
  uniqueIdentifier,
  entityName: 'RbacProfile',
  selector: rbacProfilesSelector,
  selectorCreator: makeRbacProfilesSelector,
})

export const patchRbacProfile = async (name, body) => {
  return qbert.patchRbacProfile(name, body)
}

export const getDriftAnalysis = async ({ cluster, profileName }) => {
  const clusterId = cluster.uuid
  const body = {
    apiVersion: 'sunpike.platform9.com/v1alpha2',
    kind: 'ClusterProfileBinding',
    metadata: {
      name: `${profileName}-binding-${Date.now()}`,
    },
    spec: {
      clusterRef: clusterId,
      profileRef: `default/${profileName}`,
      dryRun: true,
    },
  }
  Bugsnag.leaveBreadcrumb('Attempting to get drift analysis', { clusterId, profileName })
  trackEvent('Get drift analysis', {
    clusterId,
    profileName,
  })
  return qbert.createRbacProfileBinding(body)
}

export const getProfileBinding = async (profileName) => {
  return qbert.getRbacProfileBinding(profileName)
}

export const getProfileBindingDetails = async (profileName) => {
  return qbert.getRbacProfileBindingDetails(profileName)
}
