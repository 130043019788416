import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import { routes } from 'core/utils/routes'
import { deleteHostNetworkTemplate } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'
import usePluginRouter from 'core/hooks/usePluginRouter'

export default function DeleteHostConfigurationDialog({ rows: [hostConfig], onClose }) {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const { history } = useReactRouter()
  const { update: deleteFn, updating: deletingHostNetworkTemplate } = useUpdateAction(
    deleteHostNetworkTemplate,
  )
  const title = `Permanently delete "${hostConfig?.name}"?`
  const handleDelete = useCallback(async () => {
    await deleteFn(hostConfig)
    onClose(true)
    history.push(routes[routePath].hostConfigurations.path())
  }, [hostConfig, routePath])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingHostNetworkTemplate}>
            Don't Delete
          </Button>
          <Button onClick={handleDelete} loading={deletingHostNetworkTemplate}>
            Delete Host Configuration
          </Button>
        </>
      }
    >
      <Text variant="body2">This action cannot be undone, are you sure you want to do this?</Text>
    </Modal>
  )
}
