import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Note from 'core/components/validatedForm/Note'
import Text from 'core/elements/Text'
import ExternalLink from 'core/components/ExternalLink'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { enableDhcpLink } from 'k8s/links'

export default function DhcpNote() {
  const classes = useStyles()

  return (
    <Note title="DHCP Server Configuration">
      <div className={classes.noteContents}>
        <Text variant="body2">
          DHCP Server should be created using the following documentation on this cluster, if not
          present.
        </Text>
        <ExternalLink url={enableDhcpLink}>
          <div className={classes.button}>
            <FontAwesomeIcon>arrow-up-right-from-square</FontAwesomeIcon>
            <Text variant="caption1"> DHCP Server Configuration</Text>
          </div>
        </ExternalLink>
      </div>
    </Note>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  noteContents: {
    display: 'grid',
    gap: 24,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    background: theme.components.button.secondary.background,
    padding: 8,
  },
}))
