import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import NumberedStepLabel from 'core/components/validatedForm/NumberedStepLabel'
import TextField from 'core/components/validatedForm/TextField'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import SriovDeviceSelectorPicklist from './SriovDeviceSelectorPicklist'
import PicklistField from 'core/components/validatedForm/DropdownField'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    display: 'grid',
    gap: 24,
  },
}))

export default function SriovConfigurationFields({
  index,
  values,
  updateField,
  updateFields,
  isActive,
}) {
  const classes = useStyles()

  return (
    <div className={classes.fields}>
      <NumberedStepLabel step={index + 1} title="SR-IOV Config" />
      <PicklistField
        DropdownComponent={SriovDeviceSelectorPicklist}
        id={`sriovConfigs.${index}.deviceSelector`}
        label="Device Selector"
        onChange={(value) => {
          // Reset hidden fields back to default
          if (value === 'pfName') {
            updateFields(index)({
              deviceSelector: value,
              vendorId: '',
              deviceId: '',
              pciAddress: '',
            })
          } else if (value === 'vendor') {
            updateFields(index)({
              deviceSelector: value,
              pfName: '',
              pciAddress: '',
            })
          } else if (value === 'pciAddress') {
            updateFields(index)({
              deviceSelector: value,
              pfName: '',
              vendorId: '',
              deviceId: '',
            })
          }
        }}
        value={values?.deviceSelector}
        compact={false}
        showAll={false}
        required={isActive}
      />
      {values?.deviceSelector === 'pfName' && (
        <TextField
          id={`sriovConfigs.${index}.pfName`}
          label="pfName"
          placeholder="Enter value..."
          onChange={(value) => updateField('pfName', index)(value)}
          value={values?.pfName}
          required={isActive}
        />
      )}
      {values?.deviceSelector === 'vendor' && (
        <>
          <TextField
            id={`sriovConfigs.${index}.vendorId`}
            label="vendorID (i.e. 8086)"
            placeholder="Enter value..."
            onChange={(value) => updateField('vendorId', index)(value)}
            value={values?.vendorId}
            required={isActive}
          />
          <TextField
            id={`sriovConfigs.${index}.deviceId`}
            label="deviceID (i.e. 1528)"
            placeholder="Enter value..."
            onChange={(value) => updateField('deviceId', index)(value)}
            value={values?.deviceId}
            required={isActive}
          />
        </>
      )}
      {values?.deviceSelector === 'pciAddress' && (
        <TextField
          id={`sriovConfigs.${index}.pciAddress`}
          label="pciAddr (i.e. 0000:03:00.0)"
          placeholder="Enter value..."
          onChange={(value) => updateField('pciAddress', index)(value)}
          value={values?.pciAddress}
          required={isActive}
        />
      )}
      <TextField
        id={`sriovConfigs.${index}.numVfs`}
        label="numVfs (i.e. 4)"
        placeholder="Enter value..."
        onChange={(value) => updateField('numVfs', index)(value)}
        value={values?.numVfs}
        type="number"
        required={isActive}
      />
      <TextField
        id={`sriovConfigs.${index}.vfDriver`}
        label="vfDriver"
        placeholder="Enter value..."
        onChange={(value) => updateField('vfDriver', index)(value)}
        value={values?.vfDriver}
        required={isActive}
      />
    </div>
  )
}
