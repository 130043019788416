import React, { useState, useMemo, useEffect, useCallback } from 'react'
import jsYaml from 'js-yaml'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core'
import Button from 'core/elements/button'
import { allKey } from 'app/constants'
import Text from 'core/elements/Text'
import { emptyArr, isNilOrEmpty, stopPropagation } from 'utils/fp'
import { uniq, complement, adjust, assoc, remove, assocPath, sortBy, prop } from 'ramda'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import useToggler from 'core/hooks/useToggler'
import SubmitButton from 'core/components/buttons/SubmitButton'
import { generateObjMemoizer, capitalizeString } from 'utils/misc'
import { IRbacRoleRule, IRbacAPIGroup, IRbacClusterRole } from 'k8s/components/rbac/model'
import useParams from 'core/hooks/useParams'
import Progress from 'core/components/progress/Progress'
import Picklist from 'core/elements/dropdown/AsyncDropdown'
import { AddNewApiRule } from 'k8s/components/rbac/profiles/edit/AddNewApiRule'
import {
  updateApiGroupsWithCore,
  EditApiRuleVerbs,
} from 'k8s/components/rbac/profiles/edit/EditApiRuleVerbs'
import Input from 'core/elements/input/Input'
import useEditPermissionStyles from 'k8s/components/rbac/profiles/edit/useEditPermissionsStyles'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import moize from 'moize'
import { requiredValidator, customValidator, yamlValidator } from 'core/utils/fieldValidators'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { listApiGroups } from 'k8s/components/rbac/new-actions'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { apiGroupsSelector } from 'k8s/components/rbac/selectors'

interface Props {
  onClose: () => void
  onSubmit: (role: IRbacClusterRole) => void
  open: boolean
  clusterId: string
  clusterRole?: IRbacClusterRole
}

const getRuleUniqueKey = (rule: IRbacRoleRule) => {
  return rule.apiGroups.join('-') + '_' + rule.resources.join('-') + '_' + rule.verbs.join('-')
}

const defaultClusterRole: IRbacClusterRole = {
  metadata: {
    name: '',
  },
  rules: [],
}

const customCodeMirrorOptions = {
  mode: 'json',
}
const moizedYamlLoad = moize(jsYaml.load, {
  maxSize: 10,
})
const codeMirrorValidations = [
  requiredValidator,
  yamlValidator,
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return !!body?.metadata?.name
  }, 'metadata.name must be set'),
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return (
      !isNilOrEmpty(body?.rules) &&
      body.rules.every(
        (rule) =>
          !isNilOrEmpty(rule) &&
          !isNilOrEmpty(rule?.verbs) &&
          !isNilOrEmpty(rule?.apiGroups) &&
          !isNilOrEmpty(rule?.resources),
      )
    )
  }, 'rules must be set and be valid'),
]
const getMemoizedClusterRole = generateObjMemoizer<IRbacClusterRole>()
const EditClusterRoleDialog = ({ open, onClose, onSubmit, clusterId, clusterRole }: Props) => {
  const classes = useEditPermissionStyles()

  const originalClusterRole = clusterRole ? getMemoizedClusterRole(clusterRole) : null
  const { params, getParamsUpdater } = useParams({ clusterId })
  const [newRole, updateRole] = useState<IRbacClusterRole>(
    originalClusterRole || defaultClusterRole,
  )
  const [rawYaml, setRawYaml] = useState()
  const [showingYaml, , setShowingYaml] = useToggler()
  const showYaml = useCallback(() => {
    // Get document, or throw exception on error
    try {
      const rawYaml = jsYaml.dump(newRole)
      setRawYaml(rawYaml)
      setShowingYaml(true)
    } catch (e) {
      console.error(e)
    }
  }, [newRole])

  const exitYaml = useCallback(() => {
    const parsedRole = moizedYamlLoad(rawYaml)
    updateRole(parsedRole)
    setShowingYaml(false)
  }, [rawYaml])

  const handleYamlUpdate = useCallback(
    (value) => {
      setRawYaml(value)
    },
    [rawYaml],
  )

  const {
    metadata: { name },
    rules,
  } = newRole

  const { loading: loadingApiGroups } = useListAction(listApiGroups, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const apiGroups = useSelectorWithParams(apiGroupsSelector, { clusterId })
  const sortedApiGroups = useMemo(() => {
    const sortByName = sortBy(prop('name'))
    return sortByName(apiGroups) as IRbacAPIGroup[]
  }, [apiGroups])

  const submitEnabled = params.clusterId && name.length > 2 && rules.length > 0 && !showingYaml

  useEffect(() => {
    if (originalClusterRole) {
      updateRole(originalClusterRole)
    }
  }, [originalClusterRole])

  const [currentApi, changeCurrentApi] = useState(allKey)
  const [currentVerb, changeCurrentVerb] = useState(allKey)
  const apis = useMemo(
    () =>
      rules
        .reduce(
          (acc, apiRuleGroup) => {
            return uniq([...acc, ...(apiRuleGroup.apiGroups || emptyArr)])
          },
          ['core'],
        )
        .filter(complement(isNilOrEmpty))
        .map((rule) => ({ value: rule, label: capitalizeString(rule) })),
    [rules],
  )
  const verbs = useMemo(
    () =>
      rules
        .reduce((acc, apiRuleGroup) => {
          return uniq([...acc, ...(apiRuleGroup.verbs || emptyArr)])
        }, emptyArr)
        .filter(complement(isNilOrEmpty))
        .map((rule) => ({ value: rule, label: capitalizeString(rule) })),
    [rules],
  )
  const filteredRules = useMemo(() => {
    return rules.filter(
      (rule) =>
        (currentApi === allKey ||
          (rule.apiGroups && updateApiGroupsWithCore(rule.apiGroups).includes(currentApi))) &&
        (currentVerb === allKey || (rule.verbs && rule.verbs.includes(currentVerb))),
    )
  }, [rules, currentApi, currentVerb])

  useEffect(() => {
    if (!open) {
      // Reset filters state when closing the dialog
      changeCurrentApi(allKey)
      changeCurrentVerb(allKey)
    }
  }, [open])

  const handleNameUpdate = useCallback(
    (e) => {
      updateRole(assocPath(['metadata', 'name'], e.target.value, newRole))
    },
    [newRole],
  )

  const handleRuleVerbsUpdate = useCallback(
    (ruleIdx, verbs) => {
      updateRole({
        ...newRole,
        rules: adjust(ruleIdx, assoc('verbs', verbs), rules),
      })
    },
    [newRole],
  )
  const handleRuleDelete = useCallback(
    (ruleIdx) => {
      updateRole({
        ...newRole,
        rules: remove(ruleIdx, 1, rules),
      })
    },
    [newRole],
  )

  const handleRuleAdd = useCallback(
    (rule) => {
      updateRole({
        ...newRole,
        rules: [rule, ...rules],
      })
      toggleAddingRule()
    },
    [newRole],
  )

  const [addingRule, toggleAddingRule] = useToggler()

  const handleSubmit = useCallback(() => {
    onSubmit(newRole)
  }, [newRole])

  const defaultView = (
    <div className={classes.apiAccess}>
      <header className={classes.header}>
        <Text variant="body1">API Access/Permissions</Text>
      </header>
      <div className={classes.filters}>
        <div>
          <Picklist
            compact={false}
            name={'apis'}
            allLabel={'All APIs'}
            value={currentApi}
            onChange={changeCurrentApi}
            items={apis}
          />
          &nbsp;
          <Picklist
            compact={false}
            name={'verbs'}
            allLabel={'All verbs'}
            value={currentVerb}
            onChange={changeCurrentVerb}
            items={verbs}
          />
        </div>
        <Button onClick={showYaml} className={classes.yamlBtn} variant="secondary">
          YAML View
        </Button>
      </div>
      {addingRule ? (
        <AddNewApiRule
          apiGroups={sortedApiGroups}
          onAdd={handleRuleAdd}
          onCancel={toggleAddingRule}
        />
      ) : (
        <div className={classes.addNewPermission} onClick={toggleAddingRule}>
          + ADD NEW API ACCESS/PERMISSION
        </div>
      )}
      {filteredRules.map((rule, idx) => (
        <EditApiRuleVerbs
          key={getRuleUniqueKey(rule)}
          idx={idx}
          apiGroups={sortedApiGroups}
          onSave={handleRuleVerbsUpdate}
          onDelete={handleRuleDelete}
          rule={rule}
        />
      ))}
    </div>
  )

  const yamlView = (
    <ValidatedForm onSubmit={exitYaml}>
      <div className={classes.filters}>
        <Text variant="body1">Enter value details below or upload a YAML file</Text>
        <SubmitButton className={classes.yamlBtn} variant="secondary">
          Exit YAML View
        </SubmitButton>
      </div>
      <CodeMirror
        id="yamlView"
        label="YAML RESOURCE"
        options={customCodeMirrorOptions}
        onChange={handleYamlUpdate}
        value={rawYaml}
        className={classes.codeMirror}
        validations={codeMirrorValidations}
        required
      />
    </ValidatedForm>
  )

  return (
    <Dialog
      onClick={stopPropagation}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div className={classes.policyDetails}>
          <header className={classes.header}>
            <Text variant="body1">
              {clusterRole ? (
                <>
                  Policy details: <strong>{name}</strong>
                </>
              ) : (
                'Add New Cluster Role'
              )}
            </Text>
          </header>
          <Text variant="body2">
            Cluster roles edited within the rbac profile will not affect the cluster roles selected
            during profile creation.
          </Text>
          <div className={classes.mainSelectors}>
            {!clusterRole ? (
              <Input
                className={classes.textbox}
                id="name"
                required
                label="Name *"
                value={name || ''}
                onChange={handleNameUpdate}
              />
            ) : null}
            <ClusterPicklist /*
            // @ts-ignore */
              onChange={getParamsUpdater('clusterId')}
              value={params.clusterId}
              showAll={false}
              compact={false}
            />
          </div>
        </div>
        <Progress loading={loadingApiGroups} renderContentOnMount>
          {showingYaml ? yamlView : defaultView}
        </Progress>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <SubmitButton disabled={!submitEnabled} onClick={handleSubmit}>
          Save
        </SubmitButton>
      </DialogActions>
    </Dialog>
  )
}

export default EditClusterRoleDialog
