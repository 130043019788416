export enum INetworkPluginDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface NetworkPluginsResponse {
  apiVersion: string
  items: NetworkPluginItem[]
  kind: string
  metadata: NetworkPluginsResponseMetadata
}

export interface NetworkPluginItem {
  apiVersion: string
  kind: string
  metadata: ItemMetadata
  spec: Spec
  // Below are the properties added by us with convertResource
  created: string
  clusterId: string
  id: string
  name: string
  namespace: string
}

export interface ItemMetadata {
  creationTimestamp: string
  finalizers: string[]
  generation: number
  name: string
  namespace: string
  resourceVersion: string
  uid: string
}

export interface Spec {
  plugins: Plugins
}

export interface Plugins {
  dhcpController?: any
  hostPlumber?: any
  multus?: any
  nodeFeatureDiscovery?: any
  ovs?: any
  whereabouts?: any
  sriov?: any
}

export interface NetworkPluginsResponseMetadata {
  continue: string
  resourceVersion: string
}

export interface INetworkPluginSelector extends NetworkPluginItem {
  clusterName: string
  age: string
  arrayVersion: NetworkPluginArrayItem[]
}

export interface NetworkPluginArrayItem {
  name: string
  enabled: boolean
  type: string
}
