import React, { useMemo, useState } from 'react'
import { IConfigMapSelector } from 'k8s/components/config-maps/selectors'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateConfigMap } from 'k8s/components/config-maps/new-actions'
import { dissoc } from 'ramda'

type Props = {
  open: boolean
  onClose: () => void
  configMapData: { key: string; value: string; type?: string }
  configMap: IConfigMapSelector
  reload: (refetch: boolean) => void
}

export default function DeleteConfigMapDataDialog({
  configMap,
  configMapData,
  open = false,
  onClose,
  reload,
}: Props) {
  const { update, updating: updatingConfigMap } = useUpdateAction(updateConfigMap)

  const handleConfigMapDataDelete = async () => {
    const ops = []

    if (configMapData?.type === 'data') {
      const newData = dissoc(configMapData?.key, configMap?.data)

      ops.push({
        op: 'replace',
        path: '/data',
        value: newData,
      })
    } else if (configMapData?.type === 'binaryData') {
      const newBinaryData = dissoc(configMapData?.key, configMap?.binaryData)

      ops.push({
        op: 'replace',
        path: '/binaryData',
        value: newBinaryData,
      })
    }

    const { success } = await update({
      ...configMap,
      body: ops,
      requestType: 'patch',
      contentType: 'application/json-patch+json',
    })
    if (success) {
      reload(true)
      onClose()
    }
  }

  return (
    <ConfirmationDialog
      title={`Delete Key "${configMapData?.key}" ?`}
      text={`This will permanently delete this Key-Value pair`}
      // confirmText="Enable"
      onConfirm={handleConfigMapDataDelete}
      onCancel={onClose}
      loading={updatingConfigMap}
      open={open}
    />
  )
}
