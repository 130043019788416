import { IGenericResource } from 'api-client/qbert.model'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'

export enum INetworkPolicyDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface NetworkPoliciesResponse {
  kind: string
  apiVersion: string
  metadata: NetworkPoliciesResponseMetadata
  items: NetworkPolicyItem[]
}

export interface NetworkPolicyItem {
  metadata: ItemMetadata
  spec: NetworkPolicySpec
}

interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels?: Record<string, string>
  ownerReferences?: OwnerReference[]
  annotations?: Record<string, string>
}

export interface OwnerReference {
  apiVersion: string
  kind: string
  name: string
  uid: string
  controller: boolean
  blockOwnerDeletion: boolean
}

interface NetworkPoliciesResponseMetadata {
  resourceVersion: string
}

interface NetworkPolicySpec {
  podSelector: PodSelector
  egress?: Egress[]
  ingress?: Ingress[]
}

interface PodSelector {
  matchLabels: Record<string, string>
}

interface Egress {
  to: To[]
}

interface To {
  podSelector?: PodSelector
  ipBlock?: IPBlock
}

interface IPBlock {
  cidr: string
}

interface Ingress {
  from: From[]
}

interface From {
  podSelector?: PodSelector
  ipBlock?: IPBlock
}

export interface INetworkPolicySelector extends IGenericResource<NetworkPolicyItem> {
  clusterName: string
  clusterId: string
  clusterType: ClusterTypes
  ownerReferences: NetworkPolicyItem['metadata']['ownerReferences']
  age?: string
  labels: NetworkPolicyItem['metadata']['labels']
  annotations: NetworkPolicyItem['metadata']['annotations']
  numberOfEgressRules: number
  numberOfIngressRules: number
}
