import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import EntityYamlPage from '../../../common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import { INetworkPluginDetailsPageTabs } from '../model'
import { routes } from 'core/utils/routes'
import { isEmpty } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listNetworkPlugins, updateNetworkPlugin } from '../actions'
import { networkPluginsSelector } from '../selectors'
import Overview from './Overview'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'

const { qbert } = ApiClient.getInstance()

export default function NetworkPluginsDetailsPage() {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { globalParams: params } = useGlobalParams(useParams, {})
  const { loading } = useListAction(listNetworkPlugins, { params })
  const networkPlugins = useSelectorWithParams(networkPluginsSelector, {
    clusterId,
    useGlobalParams: false,
  })
  const networkPlugin = useMemo(() => networkPlugins.find((plugin) => plugin.id === id), [
    id,
    networkPlugins,
  ])
  const { update, updating } = useUpdateAction(updateNetworkPlugin)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: networkPlugin?.clusterName || clusterId,
        id: networkPlugin?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [networkPlugin?.clusterName, networkPlugin?.name, id, clusterId])

  const getNetworkPluginYaml = useCallback(async () => {
    if (isEmpty(networkPlugin)) return undefined
    return qbert.getNetworkPlugin(
      networkPlugin.clusterId,
      networkPlugin.namespace,
      networkPlugin.name,
    )
  }, [networkPlugin])

  const handleNetworkPluginUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Network Plugin Configuration" breadcrumbs />
      <Tabs route={routes[routePath].pluginDetails}>
        <Tab label="Overview" value={INetworkPluginDetailsPageTabs.Overview}>
          <Overview networkPlugin={networkPlugin} loading={loading} />
        </Tab>
        <Tab label="YAML" value={INetworkPluginDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Network Plugin"
            entityName={networkPlugin?.name}
            getYamlFn={getNetworkPluginYaml}
            handleUpdate={handleNetworkPluginUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}
