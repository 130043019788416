import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useEditBindingStyles = makeStyles<Theme>((theme) => ({
  root: {
    borderRadius: 4,
    background: theme.palette.grey['000'],
    padding: theme.spacing(2, 3),
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    color: theme.palette.grey[700],
    '& .formActions': {
      margin: 0,
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1, 0),
      },
    },
  },
  policyDetails: {
    marginBottom: theme.spacing(2),
    '& > p': {
      margin: theme.spacing(1, 0),
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    '& > p > span': {
      minWidth: '70%',
    },
  },
  header: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[900],
  },
  mainSelectors: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  accordionTitle: {
    color: theme.palette.blue['700'],
    fontWeight: 'bold',
    '& >  span': {
      color: theme.palette.grey['700'],
      fontWeight: 'normal',
    },
  },
  textbox: {
    width: 250,
    margin: theme.spacing(1, 2, 0, 0),
  },
  formActions: {
    margin: 0,
  },
  serviceAccount: {
    position: 'relative',
    paddingRight: theme.spacing(3),
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    width: '50%',
    '& .MuiFormControl-root.validatedFormInput': {
      width: '100%',
    },
    '& > div': {
      flexGrow: 1,
    },
  },
  removeServiceAccountIcon: {
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  addServiceBtn: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
  },
  plusIcon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
    fontWeight: 400,
  },
  splitContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'stretch',
    alignItems: 'flex-start',
    '& > div': {
      padding: theme.spacing(2),
    },
  },
  filters: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(-2),
    '& > div': {
      display: 'flex',
      flexFlow: 'row nowrap',
    },
    '& .MuiSelect-root.MuiSelect-select': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  yamlBtn: {
    marginTop: 8,
  },
  codeMirror: {
    width: '100% !important',
  },
}))

export default useEditBindingStyles
