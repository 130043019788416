import React, { useMemo } from 'react'
import { projectAs } from 'utils/fp'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { makeParamsAllClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import MultiDropdown, { MultiDropdownProps } from 'core/elements/dropdown/MultiDropdown'

const defaultParamsAllClusterSelector = makeParamsAllClustersSelector()

interface MultiClusterSelectionDropdownProps extends Omit<MultiDropdownProps<string[]>, 'items'> {
  onlyMasterNodeClusters?: boolean
  onlyPrometheusEnabled?: boolean
  onlyKubevirtClusters?: boolean
  onlyHealthyClusters?: boolean
  onlyEcoEnabledClusters?: boolean
  filterFn?: (clusters: CombinedClusterSelector[]) => CombinedClusterSelector[]
}

export default function MultiClusterSelectionDropdown({
  name = 'clusterId',
  label = 'Cluster:',
  onlyMasterNodeClusters = false,
  onlyPrometheusEnabled = false,
  onlyHealthyClusters = true,
  onlyEcoEnabledClusters = true,
  onlyKubevirtClusters = false,
  loading = false,
  onChange,
  filterFn,
  ...rest
}: MultiClusterSelectionDropdownProps) {
  const defaultParams = useMemo(
    () => ({
      masterNodeClusters: onlyMasterNodeClusters,
      prometheusClusters: onlyPrometheusEnabled,
      healthyClusters: onlyHealthyClusters,
      ecoEnabledClusters: onlyEcoEnabledClusters,
      kubevirtClusters: onlyKubevirtClusters,
    }),
    [onlyMasterNodeClusters, onlyPrometheusEnabled, onlyHealthyClusters, onlyKubevirtClusters],
  )
  const { loading: clustersLoading } = useListAction(listClusters)
  const { loading: importedClustersLoading } = useListAction(listImportedClusters)

  const allClusters: CombinedClusterSelector[] = useSelectorWithParams(
    defaultParamsAllClusterSelector,
    defaultParams,
  )

  const allClustersLoading = clustersLoading || importedClustersLoading

  const filteredClusters = useMemo(() => (filterFn ? filterFn(allClusters) : allClusters), [
    allClusters,
  ])

  const options = useMemo(() => {
    return [...projectAs({ label: 'name', value: 'uuid' }, filteredClusters)]
  }, [filteredClusters])

  return (
    <MultiDropdown
      name={name}
      items={options}
      onChange={onChange}
      label={label}
      loading={loading || allClustersLoading}
      {...rest}
    />
  )
}
