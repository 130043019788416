import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { routes } from 'core/utils/routes'
import { deleteSecret } from './actions'
import usePluginRouter from 'core/hooks/usePluginRouter'

export default function DeleteSecretDialog({ rows: [secret], onClose }) {
  const { history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtConfiguration' : 'configuration'
  const { update: deleteFn, updating: deletingSecret } = useUpdateAction(deleteSecret)

  const handleDelete = useCallback(async () => {
    await deleteFn(secret)
    onClose(true)
    history.push(routes[routePath].secrets.list.path())
  }, [secret, routePath])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={`Remove Secret "${secret?.name}" ?`}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingSecret}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingSecret}>
            Delete
          </Button>
        </>
      }
    >
      <Text variant="body2">Your data will be lost since this action cannot be undone.</Text>
    </Modal>
  )
}
