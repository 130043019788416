import React from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { syncRepositories } from '../new-actions'
import Modal from 'core/elements/modal'

export default function UpdateRepositoryDialog({ rows: [repository], onClose }) {
  const { update, updating } = useUpdateAction(syncRepositories)

  const updateRepository = async () => {
    await update({ repositories: [repository] })
    onClose && onClose()
  }

  return (
    <Modal
      open
      panel="dialog"
      onClose={onClose}
      title="Update Repository"
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={updating}>
            Cancel
          </Button>
          <Button onClick={updateRepository} loading={updating}>
            Update
          </Button>
        </>
      }
    >
      <Text variant="body2" component="p">
        This will update repository <b>{repository.name}</b>. Are you sure?
      </Text>
    </Modal>
  )
}
