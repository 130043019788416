import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import DropdownField from 'core/components/validatedForm/DropdownField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import ExternalLink from 'core/components/ExternalLink'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import Tooltip from 'core/elements/tooltip'
import { topMiddle } from 'core/elements/menu/defaults'
import Note from 'core/components/validatedForm/Note'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '24px 40px',
    display: 'grid',
    gap: 24,
  },
  section: {
    display: 'grid',
    gap: 32,
  },
  switches: {
    display: 'grid',
    gap: 32,
    marginLeft: 8,
  },
  titleWithTooltip: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gap: 12,
    alignItems: 'center',
  },
  suboptions: {
    marginLeft: 32,
    display: 'grid',
    gap: 16,
  },
  suboptionsHeader: {
    textTransform: 'uppercase',
    color: theme.components.typography.passive,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
}))

const PluginTooltip = ({ message }) => {
  return (
    <Tooltip align={topMiddle.align} offset={topMiddle.offset} message={message}>
      <FontAwesomeIcon size="md">circle-question</FontAwesomeIcon>
    </Tooltip>
  )
}

export default function NetworkPluginParamFields({
  method = 'create',
  params,
  updateParams,
  // Not sure if I'll keep initialValues like this --
  // revisit this when creating the edit modal
  initialValues = { multusCni: false },
  clustersLoading = false,
  networkPlugins = [],
  networkPluginsLoading = false,
}) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {/* TODO: Maybe if there are no selectable clusters, should hide
    the entire wizard and say that there are networking clusters without
    an existing network plugin configuration? */}
      {method === 'create' && (
        <>
          <div>
            <DropdownField
              DropdownComponent={ClusterPicklist}
              id="clusterId"
              label="Select Cluster"
              onChange={(value) => updateParams({ clusterId: value })}
              value={params?.clusterId}
              setInitialCluster
              onlyHealthyClusters
              onlyAdvancedNetworkingClusters
              compact={false}
              showAll={false}
              loading={clustersLoading}
              filterFn={(clusters) =>
                clusters.filter((cluster) => {
                  const clustersWithNetworkPlugins =
                    networkPlugins?.map((plugin) => plugin.clusterId) || []
                  return !clustersWithNetworkPlugins.includes(cluster.uuid)
                })
              }
              filterFnDependenciesLoading={networkPluginsLoading}
              required
            />
          </div>
          <div>
            {params?.clusterId ? (
              <Text variant="body2">
                <b>Please note:</b> This will create a NetworkPlugins type Custom Resource named{' '}
                <b>network-plugins</b>, in the namespace <b>luigi-system</b> on the selected
                cluster.
              </Text>
            ) : (
              <Text variant="body2">
                Only advanced networking clusters without an existing network plugin configuration
                are selectable.
              </Text>
            )}
          </div>
          <hr className={classes.divider} />
        </>
      )}
      <div>
        {/* Todo: Add correct link */}
        <ExternalLink url="http://google.com">Learn more on advanced network setup</ExternalLink>
      </div>
      <div className={classes.section}>
        <div className={classes.titleWithTooltip}>
          <Text variant="caption1">General Plugins</Text>
          <PluginTooltip message="Host Plumber and Multus are required before enabling the Network Type and IPAM plugins" />
        </div>
        <div className={classes.switches}>
          <ToggleSwitchField
            id="hostPlumber"
            label="Host Plumber"
            onChange={(value) => {
              updateParams({ hostPlumber: value })
            }}
            value={params?.hostPlumber}
          />
          {/* Can not disable this during edit if it has already been enabled */}
          <ToggleSwitchField
            id="multusCni"
            label="Multus CNI"
            onChange={(value) => {
              updateParams({ multusCni: value })
            }}
            value={params?.multusCni}
            info={'Provides additional NIC. Required for additional plugin support'}
            infoPlacement={{ align: topMiddle.align }}
            disabled={method === 'edit' && initialValues?.multusCni}
          />
          <ToggleSwitchField
            id="nodeFeatureDiscovery"
            label="Node Feature Discovery"
            onChange={(value) => {
              updateParams({ nodeFeatureDiscovery: value })
            }}
            value={params?.nodeFeatureDiscovery}
            info={'Performs feature detection'}
            infoPlacement={{ align: topMiddle.align }}
          />
        </div>
      </div>
      <div className={classes.section}>
        <Text variant="caption1">IPAM Plugins</Text>
        <div className={classes.switches}>
          <ToggleSwitchField
            id="platform9Dhcp"
            label="Platform9 DHCP"
            onChange={(value) => {
              updateParams({ platform9Dhcp: value })
            }}
            value={params?.platform9Dhcp}
            info={'Recommended IPAM plugin for KubeVirt VMs. Ensure that pods support DHCP'}
            infoPlacement={{ align: topMiddle.align }}
          />
          <ToggleSwitchField
            id="whereabouts"
            label="Whereabouts"
            onChange={(value) => {
              updateParams({ whereabouts: value })
            }}
            value={params?.whereabouts}
            info={
              <div>
                Open source IPAM CNI. {/* Todo: Add correct link */}
                <ExternalLink url="http://www.google.com">Learn more</ExternalLink>
              </div>
            }
            infoPlacement={{ align: topMiddle.align }}
          />
        </div>
      </div>
      <hr className={classes.divider} />
      <div className={classes.section}>
        <Note title="Additional Configuration Required">
          <Text variant="body2">
            SR-IOV and OVS require Host Plumber and other additional configurations and cannot be
            enabled here. Please configure these after creating the initial Network Plugin
            configuration.
          </Text>
        </Note>
        <div className={classes.titleWithTooltip}>
          <Text variant="caption1">Network Type Plugins</Text>
          <PluginTooltip
            message={
              <div>
                Config map and/or Host network template may be required for full setup.{' '}
                {/* Todo: Add correct link */}
                <ExternalLink url="http://www.google.com">Learn more</ExternalLink>
              </div>
            }
          />
        </div>
        <div className={classes.switches}>
          <ToggleSwitchField
            id="sriov"
            label="SR-IOV"
            onChange={(value) => {
              updateParams({ sriov: value })
            }}
            value={params?.sriov}
            info={'Hypervisor pass-through to increase the packet processing performance'}
            infoPlacement={{ align: topMiddle.align }}
            disabled
          />
          <ToggleSwitchField
            id="ovs"
            label="OVS"
            onChange={(value) => {
              updateParams({ ovs: value, dpdk: value ? params?.dpdk : false })
            }}
            value={params?.ovs}
            info={'Open Source virtual switch'}
            infoPlacement={{ align: topMiddle.align }}
            disabled
          />
          {/* <div className={classes.suboptions}>
            <Text variant="caption2" className={classes.suboptionsHeader}>
              OVS Advanced Options
            </Text>
            <ToggleSwitchField
              id="dpdk"
              label="DPDK"
              onChange={(value) => {
                updateParams({ dpdk: value })
              }}
              value={params?.dpdk}
              info={'Bypasses the kernel to increase the packet processing performance'}
              infoPlacement={{ align: topMiddle.align }}
              disabled={!params?.ovs}
            />
          </div> */}
        </div>
      </div>
    </div>
  )
}
