import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import ServiceOverview from './overview'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import ApiClient from 'api-client/ApiClient'
import EntityYamlPage from '../common/entity/entity-yaml-page'
import { pathStrOr } from 'utils/fp'
import EntityEventsPage from '../common/entity/entity-events-page'
import jsYaml from 'js-yaml'
import EntityLogsPage from '../common/entity/entity-logs-page'
import { IServiceDetailsPageTabs, IServicesSelector } from './model'
import InfoHeader from '../common/entity/info-header'
import EntityPodsPage from '../common/entity/entity-pods-page'
import { isEmpty } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import { listServices, updateService } from './new-actions'
import { listPods } from 'k8s/components/pods/new-actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { serviceSelectors } from './selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'

const { qbert } = ApiClient.getInstance()

const defaultService = {
  internalEndpoints: [],
  externalEndpoints: [],
} as IServicesSelector

const ServiceOverviewPage = () => {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtServices' : 'services'
  const { clusterId, id } = match.params
  const { globalParams: params } = useGlobalParams(useParams, {})
  const { loading, reload } = useListAction(listServices, { params })
  const services = useSelectorWithParams(serviceSelectors, { clusterId, useGlobalParams: false })
  const service = useMemo(() => services.find((service) => service.id === id) || defaultService, [
    id,
    services,
  ])
  const { update, updating } = useUpdateAction(updateService)

  const { loading: loadingPods, reload: reloadPods } = useListAction(listPods, { params })

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: service?.clusterName || clusterId,
        id: service?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [service?.clusterName, service?.name, id, clusterId])

  const getServiceYaml = useCallback(async () => {
    if (isEmpty(service)) return undefined
    return qbert.getClusterKubeService(service.clusterId, service.namespace, service.name)
  }, [service])

  const handleServiceUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({ id, clusterId, namespace, name, body })
  }

  const headerColumns = useMemo(() => {
    if (isEmpty(service)) return []
    return [
      { label: 'Name', value: service?.name },
      { label: 'Cluster', value: service?.clusterName },
      { label: 'Cluster IP', value: service?.clusterIp },
      { label: 'Age', value: service?.age },
    ]
  }, [service])

  return (
    <>
      <DocumentMeta title="Service Overview" breadcrumbs />
      <InfoHeader columns={headerColumns} />
      <Tabs route={routes[routePath].details}>
        <Tab value={IServiceDetailsPageTabs.Overview} label="Overview">
          <ServiceOverview service={service} loading={loading} />
        </Tab>
        <Tab value={IServiceDetailsPageTabs.Pods} label="Pods">
          <EntityPodsPage
            pods={service?.servicePods}
            loading={loading || loadingPods}
            reload={reloadPods}
          />
        </Tab>
        <Tab value={IServiceDetailsPageTabs.Events} label="Events">
          <EntityEventsPage entity={service} loading={loading} />
        </Tab>
        <Tab value={IServiceDetailsPageTabs.Logs} label="Logs">
          <EntityLogsPage pods={service?.servicePods} loading={loading} />
        </Tab>
        <Tab value={IServiceDetailsPageTabs.Yaml} label="YAML">
          <EntityYamlPage
            entityType="Service"
            entityName={service?.name}
            getYamlFn={getServiceYaml}
            handleUpdate={handleServiceUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default ServiceOverviewPage
