import SimpleLink from 'core/components/SimpleLink'
import React, { useState } from 'react'
import ConfigMapDataDetailsDialog from './data-details-dialog'

const ConfigMapDataDetailsLink = ({ value: name, item: configMap }) => {
  const [dialogOpened, setDialogOpened] = useState(false)

  return (
    <>
      {dialogOpened && (
        <ConfigMapDataDetailsDialog configMap={configMap} onClose={() => setDialogOpened(false)} />
      )}
      <SimpleLink src={null} onClick={() => setDialogOpened(true)}>
        {name}
      </SimpleLink>
    </>
  )
}

export default ConfigMapDataDetailsLink
