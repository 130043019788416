import { IGenericResource } from 'api-client/qbert.model'
import { StorageClassesItem } from '../storage-classes/model'
import { IPersistentVolumeSelector } from 'k8s/components/storage/persistent-volume/model'

export enum IPersistentVolumeClaimDetailsPageTabs {
  Overview = 'overview',
  Events = 'events',
  Yaml = 'yaml',
}

export interface PodsMounted {
  pod: string
  container: string
  path: string
}

export interface IPersistentVolumeClaimsSelector
  extends IGenericResource<PersistentVolumeClaimsItem> {
  labels: PersistentVolumeClaimsItem['metadata']['labels']
  accessModes: PersistentVolumeClaimsItem['spec']['accessModes']
  storageClassName: PersistentVolumeClaimsItem['spec']['storageClassName']
  storageClass: StorageClassesItem
  volume: string
  capacity: PersistentVolumeClaimsItem['status']['capacity']
  clusterName: string
  type: string
  podsMounted: PodsMounted[]
  persistentVolume: IPersistentVolumeSelector
}

export interface PersistentVolumeClaimsResponse {
  kind: string
  apiVersion: string
  metadata: PersistentVolumeClaimsResponseMetadata
  items: PersistentVolumeClaimsItem[]
}

export interface PersistentVolumeClaimsItem {
  metadata: ItemMetadata
  parameters?: { [key: string]: any }
  spec: Spec
  status: Status
}

interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels: Record<string, string>
  annotations: Record<string, string>
  finalizers: string[]
}

interface Spec {
  accessModes: string[]
  resources: Resources
  volumeName: string
  storageClassName: string
  volumeMode: string
}

interface Resources {
  requests: Capacity
}

interface Capacity {
  storage: string
}

interface Status {
  phase: string
  accessModes: string[]
  capacity: Capacity
}

interface PersistentVolumeClaimsResponseMetadata {
  resourceVersion: string
}
