import { complement, isNil, propEq } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { importedClustersSelector } from 'app/plugins/infrastructure/components/importedClusters/selectors'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { INamespaceSelector } from './model'
import { Selector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { capiClustersSelector } from 'app/plugins/infrastructure/components/clusters/capi/selectors'

export const namespacesSelector: Selector<RootState, INamespaceSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.Clusters>(DataKeys.Clusters),
  importedClustersSelector,
  capiClustersSelector,
  getDataSelector<DataKeys.Namespaces>(DataKeys.Namespaces, ['clusterId'], ['clusterId']),
  (rawClusters, importedClusters, capiClusters, rawNamespaces) => {
    return rawNamespaces
      .map((ns) => {
        const cluster = [...rawClusters, ...importedClusters, ...capiClusters].find(
          propEq('uuid', ns.clusterId),
        )
        if (!cluster) {
          // If no cluster if found, this item is invalid because
          // the parent cluster has been deleted
          return null
        }
        return {
          ...ns,
          id: ns.metadata.uid,
          clusterName: cluster.name,
        }
      })
      .filter(complement(isNil))
  },
)
