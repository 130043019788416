import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'

const { qbert } = ApiClient.getInstance()

export const alertRuleActions = ActionsSet.make<DataKeys.AlertRules>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.AlertRules,
  cacheKey: DataKeys.AlertRules,
})

export const listAlertRules = alertRuleActions.add(
  new ListAction<DataKeys.AlertRules, { clusterId: string }>(async (params) => {
    return qbert.getPrometheusAlertRules(params.clusterId)
  }).addDependency(DataKeys.PrometheusRules),
)
