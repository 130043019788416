// Libs
import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

// Hooks
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'

// Components
import Text from 'core/elements/Text'
import PieUsageWidget from 'core/components/widgets/PieUsageWidget'
import DonutWidget from 'core/components/widgets/DonutWidget'
import { CircularProgress } from '@material-ui/core'
import generateTestId from 'utils/test-helpers'

// Models
import { StatusCardProps } from '../dashboard/card-templates/model'

interface ListPageHeaderProps {
  title: StatusCardProps['title']
  data: StatusCardProps['data']
  quantityFn: StatusCardProps['quantityFn']
  percentagePrecision?: number
}

const ListPageHeaderCard: FunctionComponent<ListPageHeaderProps> = ({
  title,
  data: { loader, selector, params },
  quantityFn,
  percentagePrecision,
}) => {
  const classes = useStyles({})
  const { loading } = useListAction(loader)
  const data = useSelectorWithParams<Record<string, unknown>, unknown[]>(selector, params)
  const { quantity, pieData, piePrimary, graphType = 'usage' } = quantityFn(data)

  const GraphComponent = graphType === 'donut' ? DonutWidget : PieUsageWidget

  return (
    <div className={classes.card}>
      <div className={classes.count}>
        <Text data-testid={generateTestId('count')} variant="h1">
          {quantity}
        </Text>
      </div>
      <div className={classes.title}>
        <Text variant="caption1" component="h2">
          {title}
        </Text>
      </div>
      <div className={classes.graph}>
        {loading ? (
          <CircularProgress className={classes.spinner} size={64} />
        ) : (
          <GraphComponent
            sideLength={216}
            primary={piePrimary}
            data={pieData}
            percentagePrecision={percentagePrecision}
          />
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    display: 'grid',
    gridTemplateAreas: '"phc-count phc-title" "phc-graph phc-graph"',
    gridTemplateColumns: 'minmax(62px, max-content) 1fr',
    gridTemplateRows: 'max-content 1fr',
    borderRadius: 4,
    border: `solid 1px ${theme.components.card.border}`,
    boxSizing: 'border-box',
    backgroundColor: theme.components.card.background,
    padding: '24px 16px',
    minHeight: 110,
    gap: 12,
  },
  count: {
    gridArea: 'phc-count',
    '& h1': {
      textAlign: 'center',
      fontWeight: 'normal',
      color: theme.components.card.text,
      lineHeight: '35px',
      position: 'relative',
      top: 8,
    },
  },
  title: {
    gridArea: 'phc-title',
    // borderBottom: `1px solid ${theme.components.card.border}`,
    paddingBottom: 6,
    paddingLeft: 6,
    display: 'flex',
    alignItems: 'flex-end',
    '& h2': {
      color: theme.components.card.text,
    },
  },
  graph: {
    gridArea: 'phc-graph',
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'end',
    minWidth: 'max-content',
  },
  spinner: {
    marginLeft: theme.spacing(1),
  },
}))

export default ListPageHeaderCard
