import { isEmpty } from 'ramda'
import { getPodTemplate } from '../config-maps/helpers'
import { memoize } from 'utils/misc'
import { StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'

export const getServicesForResource = memoize((resource = {}, services = []) => {
  const podTemplate = getPodTemplate(resource)
  return services.filter((service) =>
    labelsMatchSelector(podTemplate?.metadata?.labels, service?.spec?.selector),
  )
})

export const labelsMatchSelector = (labels = {}, selector = {}) => {
  if (isEmpty(labels) || isEmpty(selector)) {
    return false
  }
  for (const [selectorKey, selectorValue] of Object.entries(selector)) {
    if (!labels.hasOwnProperty(selectorKey) || labels[selectorKey] !== selectorValue) {
      return false
    }
  }
  return true
}

export const getServiceStatus = (status): StatusCellModel => ({
  variant: status === 'OK' ? 'success' : 'warning',
  label: status === 'OK' ? 'Connected' : 'Connecting',
})
