import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {}

export enum MetalLbModes {
  Layer2Mode = 'layer2',
  BGPMode = 'bgp',
}

const items = [
  { label: 'Layer 2 Mode', value: MetalLbModes.Layer2Mode },
  { label: 'BGP Mode', value: MetalLbModes.BGPMode },
]

export default function MetalLbModePicklist(props: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown items={items} {...props} />
}
