import React, { useMemo, useState } from 'react'
import { IConfigMapSelector } from 'k8s/components/config-maps/selectors'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateConfigMap } from 'k8s/components/config-maps/new-actions'

type Props = {
  open: boolean
  onClose: () => void
  configMap: IConfigMapSelector
  reload: (refetch: boolean) => void
}

export default function ConfigMapImmutabilityConfirmationDialog({
  configMap,
  open = false,
  onClose,
  reload,
}: Props) {
  const { update, updating: updatingConfigMap } = useUpdateAction(updateConfigMap)

  const updateImmutable = async () => {
    const body = { ...configMap, ...{ immutable: true } }
    const params = {
      body,
      clusterId: configMap.clusterId,
      name: configMap.name,
      namespace: configMap.namespace,
    }

    const { error } = await update(params)
    if (error) console.error(error)
    if (!error) reload(true)
    onClose()
  }

  return (
    <ConfirmationDialog
      title="Enable Immutability?"
      text="This change is not revertable."
      confirmText="Enable"
      onConfirm={updateImmutable}
      onCancel={onClose}
      loading={updatingConfigMap}
      open={open}
    />
  )
}
