import { IRbacAPIGroup, IRbacRoleRule } from 'k8s/components/rbac/model'
import useToggler from 'core/hooks/useToggler'
import React, { useState, useMemo, useCallback } from 'react'
import { uniq, intersection, when, includes, update, indexOf } from 'ramda'
import { except } from 'utils/fp'
import EditIcon from 'k8s/components/rbac/profiles/EditIcon'
import DeleteIcon from 'k8s/components/rbac/profiles/DeleteIcon'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import Checkbox from 'core/elements/input/Checkbox'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  permissionGroup: {
    display: 'flex',
    flexFlow: 'row nowrap',
    backgroundColor: theme.palette.blue['100'],
    borderRadius: 2,
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    justifyContent: 'space-between',
  },
  permissionsResources: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: 240,
    minWidth: 240,
    '& > p': {
      margin: 0,
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    '& > p > strong': {
      marginLeft: theme.spacing(1),
      display: 'flex',
      flexFlow: 'row wrap',
      whiteSpace: 'pre-wrap',
      width: 130,
    },
  },
  permissionsVerbs: {
    paddingLeft: theme.spacing(3),
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  verb: {
    borderRadius: 4,
    padding: theme.spacing(0.5, 2),
    margin: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.blue['200'],
  },
  editPermissionForm: {
    minHeight: 200,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 2,
    borderColor: theme.palette.blue[500],
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
  },
  editFormContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  leftBlock: {
    minHeight: 200,
    minWidth: 300,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
  },
  policyActions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    '& > button': {
      margin: theme.spacing(0, 1),
      borderRadius: 4,
      minHeight: 30,
    },
  },
  verbsBoxes: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    '& > label': {
      width: '33%',
      margin: 0,
    },
  },
}))

interface Props {
  idx?: number
  apiGroups: IRbacAPIGroup[]
  onSave: (idx: number, verbs: string[]) => void
  onDelete: (idx: number) => void
  rule?: IRbacRoleRule
  readOnly?: boolean
}

export const updateApiGroupsWithCore = (apiGroups) =>
  when<string[], string[]>(includes(''), update(indexOf('', apiGroups), 'core'), apiGroups)

export const EditApiRuleVerbs = ({
  idx,
  apiGroups,
  rule,
  onSave,
  onDelete,
  readOnly = false,
}: Props) => {
  const classes = useStyles()
  const [editing, toggleEditing] = useToggler()
  const [selectedVerbs, updateSelectedVerbs] = useState(rule.verbs)

  const availableVerbs = useMemo(() => {
    const [firstApiGroup] = rule?.apiGroups || []
    const [firstResource] = rule?.resources || []
    const currentGroup = apiGroups.find(({ name }) => name === (firstApiGroup || 'core'))
    const currentResource = currentGroup?.resources?.find(({ name }) => name === firstResource)
    return uniq([...rule.verbs, ...(currentResource?.verbs || [])])
  }, [apiGroups, rule])

  const handleEdit = useCallback((e) => {
    e.stopPropagation()
    toggleEditing()
  }, [])

  const handleSave = useCallback(
    (e) => {
      e.stopPropagation()
      toggleEditing()
      onSave(idx, intersection(selectedVerbs, availableVerbs))
    },
    [onSave, idx, selectedVerbs, availableVerbs],
  )

  const handleCancel = useCallback(
    (e) => {
      e.stopPropagation()
      updateSelectedVerbs(rule.verbs)
      toggleEditing()
    },
    [rule],
  )

  const handleDelete = useCallback(
    (e) => {
      e.stopPropagation()
      onDelete(idx)
    },
    [onDelete, idx],
  )

  const toggleVerb = useCallback(
    (verb) => {
      updateSelectedVerbs(
        selectedVerbs.includes(verb) ? except(verb, selectedVerbs) : [verb, ...selectedVerbs],
      )
    },
    [selectedVerbs],
  )

  const staticView = (
    <div className={classes.permissionGroup}>
      <div className={classes.permissionsResources}>
        <p>
          API Group: <strong>{updateApiGroupsWithCore(rule.apiGroups).join(', ')}</strong>
        </p>
        <p>
          Resources: <strong>{rule.resources.join(', ')}</strong>
        </p>
      </div>
      <div className={classes.permissionsVerbs}>
        {selectedVerbs.map((verb) => (
          <div key={verb} className={classes.verb}>
            {verb}
          </div>
        ))}
      </div>
      {!readOnly && (
        <div className={classes.permissionsActions}>
          <EditIcon onClick={handleEdit} />
          <DeleteIcon onClick={handleDelete} />
        </div>
      )}
    </div>
  )

  const editView = (
    <div className={classes.editPermissionForm}>
      <Text variant="subtitle2">EDIT API ACCESS/PERMISSION</Text>
      <hr />
      <div className={classes.editFormContent}>
        <div className={classes.leftBlock}>
          <div className={classes.permissionsResources}>
            <p>
              API Group: <strong>{updateApiGroupsWithCore(rule.apiGroups).join(', ')}</strong>
            </p>
            <p>
              Resources: <strong>{rule.resources.join(', ')}</strong>
            </p>
          </div>
          <div className={classes.policyActions}>
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleSave}>Save</Button>
          </div>
        </div>
        <div>
          <Text>Select Verbs to add to this resource:</Text>
          <div className={classes.verbsBoxes}>
            {availableVerbs.map((verb) => (
              <Checkbox
                key={verb}
                label={verb}
                checked={selectedVerbs.includes(verb)}
                onChange={() => toggleVerb(verb)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )

  return editing ? editView : staticView
}
