import React, { useEffect, useMemo, useCallback } from 'react'
import DocumentMeta from 'core/components/DocumentMeta'
import Wizard from 'core/components/wizard/Wizard'
import { BaseClusterForm } from 'k8s/components/rbac/profiles/create/BaseClusterForm'
import RolesForm from 'k8s/components/rbac/profiles/create/RolesForm'
import ClusterRolesForm from 'k8s/components/rbac/profiles/create/ClusterRolesForm'
import RoleBindingsForm from 'k8s/components/rbac/profiles/create/RoleBindingsForm'
import ClusterRoleBindingsForm from 'k8s/components/rbac/profiles/create/ClusterRoleBindingsForm'
import ReviewForm from 'k8s/components/rbac/profiles/create/ReviewForm'
import { createRbacProfile } from '../new-actions'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormWrapper from 'core/components/FormWrapper'
import WizardStep from 'core/components/wizard/WizardStep'
import useStyles from 'k8s/components/rbac/profiles/create/useStyles'
import { isEmpty } from 'ramda'
import { rbacProfileCreationTracking } from '../tracking'
import useUpdateAction from 'core/hooks/useUpdateAction'

const wizardMetaFormattedNames = {
  profileName: 'Profile Name',
  baseClusterName: 'Cluster Name',
  roles: 'Roles',
  clusterRoles: 'Cluster Roles',
  roleBindings: 'Role Bindings',
  clusterRoleBindings: 'Cluster Role Bindings',
}

const wizardMetaCalloutFields = Object.keys(wizardMetaFormattedNames)

const CreateRbacProfile = () => {
  const classes = useStyles()
  const initialContext = useMemo(
    () => ({
      profileName: '',
      baseCluster: '',
      baseClusterName: '',
      roles: {},
      clusterRoles: {},
      roleBindings: {},
      clusterRoleBindings: {},
    }),
    [],
  )
  const { history } = useReactRouter()
  const {
    update,
    updating: creatingRbacProfile,
    error: errorCreatingRbacProfile,
  } = useUpdateAction(createRbacProfile)

  const handleSubmit = useCallback(async (params) => {
    const { success } = await update(params)
    if (!success) return
    rbacProfileCreationTracking.finished()
    history.push(routes.rbacProfiles.list.path())
  }, [])
  const validateBaseFields = useCallback(
    ({ profileName, baseCluster }) => !isEmpty(profileName) && !isEmpty(baseCluster),
    [],
  )
  const validateAnySelectedRoles = useCallback(
    ({ roles, clusterRoles, roleBindings, clusterRoleBindings }) =>
      !isEmpty(roles) ||
      !isEmpty(clusterRoles) ||
      !isEmpty(roleBindings) ||
      !isEmpty(clusterRoleBindings),
    [],
  )

  useEffect(() => {
    rbacProfileCreationTracking.started()
  }, [])

  return (
    <FormWrapper
      title={'Create a new RBAC Profile'}
      backUrl={routes.rbacProfiles.list.path()}
      message={creatingRbacProfile ? 'Saving' : ''}
      loading={creatingRbacProfile}
    >
      <DocumentMeta title="Create a New RBAC Profile" bodyClasses={['form-view']} />
      <Wizard
        context={initialContext}
        submitLabel="Done"
        onComplete={handleSubmit}
        error={errorCreatingRbacProfile}
      >
        {({ wizardContext, setWizardContext }) => (
          <>
            <WizardStep
              className={classes.splitWizardStep}
              label="Base Cluster"
              stepId="baseCluster"
              validateFields={validateBaseFields}
              onNext={rbacProfileCreationTracking.step1}
              summaryTitle="New Profile"
              summaryKeyOverrides={wizardMetaFormattedNames}
              summaryCalloutFields={wizardMetaCalloutFields}
            >
              <BaseClusterForm {...{ wizardContext, setWizardContext }} />
            </WizardStep>

            <WizardStep
              className={classes.splitWizardStep}
              label="Roles"
              stepId="roles"
              onNext={rbacProfileCreationTracking.step2}
            >
              <RolesForm {...{ wizardContext, setWizardContext }} />
            </WizardStep>

            <WizardStep
              className={classes.splitWizardStep}
              label="Cluster Roles"
              stepId="clusterRoles"
              onNext={rbacProfileCreationTracking.step3}
            >
              <ClusterRolesForm {...{ wizardContext, setWizardContext }} />
            </WizardStep>

            <WizardStep
              className={classes.splitWizardStep}
              label="Role Bindings"
              stepId="roleBindings"
              onNext={rbacProfileCreationTracking.step4}
            >
              <RoleBindingsForm {...{ wizardContext, setWizardContext }} />
            </WizardStep>

            <WizardStep
              className={classes.splitWizardStep}
              label="Cluster Role Bindings"
              stepId="clusterRoleBindings"
              validateFields={validateAnySelectedRoles}
              onNext={rbacProfileCreationTracking.step5}
            >
              <ClusterRoleBindingsForm {...{ wizardContext, setWizardContext }} />
            </WizardStep>

            <WizardStep
              className={classes.splitWizardStep}
              label="Review"
              stepId="review"
              onNext={rbacProfileCreationTracking.step6}
            >
              <ReviewForm {...{ wizardContext }} />
            </WizardStep>
          </>
        )}
      </Wizard>
    </FormWrapper>
  )
}

export default CreateRbacProfile
