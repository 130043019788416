import React, { useEffect } from 'react'
import { Dialog, DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Button from 'core/elements/button'
import Text from 'core/elements/Text'
import { getProfileBindingDetails } from '../new-actions'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { useDispatch } from 'react-redux'
import { notificationActions, NotificationType } from 'core/notifications/notificationReducers'
import downloadFile from 'core/utils/downloadFile'
import { trackEvent } from 'utils/tracking'

const useStyles = makeStyles<Theme>((theme) => ({
  dialogContainer: {
    padding: theme.spacing(1, 3),
  },
  dialogHeader: {
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  dialogContent: {
    margin: theme.spacing(3, 2),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
}))

const ExportDriftAnalysisDialog = ({ rows: [profileBinding], onClose }) => {
  const classes = useStyles({})
  const dispatch = useDispatch()

  useEffect(() => {
    const getAnalysis = async () => {
      try {
        trackEvent('Exporting Drift Analysis')
        const details = await getProfileBindingDetails(profileBinding.name)
        const analysis = JSON.stringify(
          {
            clusterProfile: profileBinding.spec?.profileRef,
            cluster: profileBinding.spec?.clusterRef,
            analysis: details.analysis,
          },
          null,
          2,
        )
        downloadFile({
          filename: `${profileBinding.name}.json`,
          contents: analysis,
        })
        onClose()
      } catch (err) {
        dispatch(
          notificationActions.registerNotification({
            title: 'Drift Analysis Export Error',
            message: `Error encountered when exporting drift analysis for binding ${profileBinding.name}`,
            data: err,
            type: NotificationType.error,
          }),
        )
        onClose()
      }
    }
    getAnalysis()
  }, [])

  return (
    <>
      <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
        <div className={classes.dialogContainer}>
          <Text variant="body1" className={classes.dialogHeader}>
            Export Analysis
          </Text>
          <div className={classes.dialogContent}>
            <Text variant="body2">
              <FontAwesomeIcon className={classes.marginRight} spin>
                sync
              </FontAwesomeIcon>
              Exporting analysis...
            </Text>
          </div>
          <DialogActions>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  )
}

export default ExportDriftAnalysisDialog
