import React, { useMemo } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import { pick } from 'ramda'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import DataKeys from 'k8s/DataKeys'
import { renderLabelsAsBadges } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { csiDriversSelector } from './selectors'
import { ArrayElement } from 'core/actions/Action'
import DocumentMeta from 'core/components/DocumentMeta'
import { listCSIDrivers } from './new-actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { GridFilterSpec } from 'core/elements/grid/hooks/useGridFiltering'
import ListContainer from 'core/containers/ListContainer'
import GridDefaultCell from 'core/elements/grid/cells/GridDefaultCell'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'

type ModelDataKey = DataKeys.CSIDrivers
type SelectorModel = ArrayElement<ReturnType<typeof csiDriversSelector>>
type ActionParams = InferActionParams<typeof listCSIDrivers>

type Params = ActionParams & {
  healthyClusters: boolean
}

function QuantityGridCell<T>({ value = [] }: GridCellProps<T, unknown[]>) {
  return <GridDefaultCell>{value?.length || 0}</GridDefaultCell>
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'CSIDrivers',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'clusterName', label: 'Cluster' },
  { key: 'name', label: 'Driver Name' },
  {
    key: 'spec.volumeLifecycleModes',
    label: 'Capabilities',
    render: renderLabelsAsBadges({ type: 'table', variant: 'default' }),
  },
  {
    key: 'persistentVolumes',
    label: 'Persistent Volumes',
    CellComponent: QuantityGridCell,
  },
  {
    key: 'persistentVolumeClaims',
    label: 'Persistent Volume Claim',
    CellComponent: QuantityGridCell,
  },
]

export default function CsiDriversListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listCSIDrivers, {
    params,
    requiredParams,
  })
  const data = useAppSelector(csiDriversSelector)

  return (
    <>
      <DocumentMeta title="CSI Drivers" />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.CSIDrivers}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={data}
        getParamsUpdater={getParamsUpdater}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
