import React, { useCallback, useEffect, useState } from 'react'
import Theme from 'core/themes/model'
import Button from 'core/elements/button'
import { makeStyles } from '@material-ui/styles'
import DisplayKeyValues from 'core/components/DisplayKeyValues'
import { formatDate } from 'utils/misc'
import { SeverityTableCell } from './AlarmsListPage'
import { IAlertSelector } from './model'
import renderLabels from '../pods/renderLabels'
import Modal from 'core/elements/modal'
import ApiClient from 'api-client/ApiClient'
import { isEmpty, isNil } from 'ramda'
import { routes } from 'core/utils/routes'
import usePluginRouter from 'core/hooks/usePluginRouter'
import { IPodDetailsPageTabs } from '../pods/model'
import { IVirtualMachineDetailsPageTabs } from 'app/plugins/kubevirt/components/virtual-machines/model'
import { IPersistentVolumeClaimDetailsPageTabs } from '../storage/persistent-volume-claims/model'

const { qbert } = ApiClient.getInstance()

interface Props {
  alarm: IAlertSelector
  onClose: (e) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  upperBody: {
    paddingBottom: 16,
    borderBottom: `1px solid ${theme.components.tab.border}`,
  },
  lowerBody: {
    wordBreak: 'break-word',
  },
}))

const AlarmDetailsDialog = ({ alarm, onClose }: Props) => {
  const classes = useStyles({})
  const { currentPluginId } = usePluginRouter()

  const [link, setLink] = useState('')
  const [showLinkLabelNameArray, setShowLinkLabelNameArray] = useState([])
  const [entity, setEntity] = useState(null)

  const getPod = useCallback(async () => {
    if (isEmpty(alarm)) return undefined
    const podClusterId = alarm?.clusterId
    const podNamespace = alarm?.labels?.exported_namespace || alarm?.labels?.event_namespace || null
    const podName = alarm?.labels?.exported_pod || alarm?.labels?.event_objname || null

    if (isNil(podNamespace) || isNil(podName)) return undefined
    return qbert.getClusterPod(podClusterId, podNamespace, podName)
  }, [alarm?.clusterId, alarm?.labels])

  const getVm = useCallback(async () => {
    if (isEmpty(alarm)) return undefined

    const vmClusterId = alarm?.clusterId
    const vmNamespace = alarm?.labels?.namespace || null
    const vmName = alarm?.labels?.name || null

    if (isNil(vmNamespace) || isNil(vmName)) return undefined
    return qbert.getVirtualMachineByName(vmClusterId, vmNamespace, vmName)
  }, [alarm?.clusterId, alarm?.labels])

  const getPvc = useCallback(async () => {
    if (isEmpty(alarm)) return undefined

    const pvcClusterId = alarm?.clusterId
    const pvcNamespace = alarm?.labels?.exported_namespace || null
    const pvcName = alarm?.labels?.persistentvolumeclaim || null

    if (isNil(pvcNamespace) || isNil(pvcName)) return undefined
    return qbert.getPersistentVolumeClaim(pvcClusterId, pvcNamespace, pvcName)
  }, [alarm?.clusterId, alarm?.labels])

  useEffect(() => {
    const loadPod = async () => {
      const response = await getPod()
      setEntity(response)
    }

    const loadVm = async () => {
      const response = await getVm()
      setEntity(response)
    }

    const loadPvc = async () => {
      const response = await getPvc()
      setEntity(response)
    }

    // TODO: Ask Anmol what to do for the other Alarms
    if (['KubePodNotReady', 'FailedScheduling', 'BackOff'].includes(alarm?.name)) {
      loadPod()
    }

    if (['KubeVirtVMStuckInStartingState', 'KubeVirtVMStuckInErrorState'].includes(alarm?.name)) {
      loadVm()
    }

    if (['PVCPending'].includes(alarm?.name)) {
      loadPvc()
    }
  }, [alarm?.name, getPod, getVm, getPvc])

  useEffect(() => {
    if (
      ['KubePodNotReady', 'FailedScheduling', 'BackOff'].includes(alarm?.name) &&
      !isNil(entity)
    ) {
      const routePathPods = currentPluginId === 'kubevirt' ? 'kubevirtPods' : 'pods'
      setLink(
        routes[routePathPods].details.path({
          clusterId: alarm?.clusterId,
          id: entity?.id,
          tab: IPodDetailsPageTabs.Overview,
        }),
      )
      setShowLinkLabelNameArray(['exported_pod', 'event_objname'])
    }

    if (
      ['KubeVirtVMStuckInStartingState', 'KubeVirtVMStuckInErrorState'].includes(alarm?.name) &&
      !isNil(entity)
    ) {
      setLink(
        routes.virtualMachines.details.path({
          clusterId: alarm?.clusterId,
          id: entity?.metadata?.uid,
          tab: IVirtualMachineDetailsPageTabs.Overview,
        }),
      )
      setShowLinkLabelNameArray(['name'])
    }

    if (['PVCPending'].includes(alarm?.name) && !isNil(entity)) {
      const routePath = currentPluginId === 'kubevirt' ? 'kubevirtStorage' : 'storage'
      setLink(
        routes[routePath].persistentVolumeClaims.details.path({
          clusterId: alarm?.clusterId,
          id: entity?.metadata?.uid,
          tab: IPersistentVolumeClaimDetailsPageTabs.Overview,
        }),
      )
      setShowLinkLabelNameArray(['persistentvolumeclaim'])
    }
  }, [entity])

  const upperValues = [
    { key: 'Event Time', value: formatDate(alarm.updatedAt) },
    {
      key: 'Alarm Severity',
      value: alarm.severity,
      render: (value) => <SeverityTableCell value={value} />,
    },
    { key: 'Alarm Summary', value: alarm.summary },
    { key: 'Runbook URL', value: alarm.runbookUrl },
  ]
  const lowerValues = [
    {
      key: 'Labels',
      value: (
        <div>{renderLabels('labels', 'body2', link, showLinkLabelNameArray)(alarm?.labels)}</div>
      ),
    },
    { key: 'Conditions', value: alarm.query },
    { key: 'Duration', value: alarm.for || 'N/A' },
  ]

  return (
    <Modal
      open
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </>
      }
      onClose={onClose}
      title={alarm.name}
    >
      <div className={classes.upperBody}>
        <DisplayKeyValues keyValuePairs={upperValues} />
      </div>
      <div className={classes.lowerBody}>
        <DisplayKeyValues keyValuePairs={lowerValues} rowSpacing={24} />
      </div>
    </Modal>
  )
}

export default AlarmDetailsDialog
