import ActionsSet from 'core/actions/ActionsSet'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import { ensureArray, pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import jsYaml from 'js-yaml'
import ListAction from 'core/actions/ListAction'
import { listPods } from '../pods/new-actions'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'
import { someAsync } from 'utils/async'
import { flatten } from 'ramda'

const { qbert } = ApiClient.getInstance()

const deploymentActions = ActionsSet.make<DataKeys.Deployments>({
  uniqueIdentifier: 'id',
  indexBy: ['clusterId', 'namespace'],
  entityName: entityNamesByKey[DataKeys.Deployments],
  cacheKey: DataKeys.Deployments,
})

export default deploymentActions

export const listDeployments = deploymentActions.add(
  new ListAction<DataKeys.Deployments, { clusterId: string; namespace?: string }>(
    async (params) => {
      const { clusterId, namespace } = params
      Bugsnag.leaveBreadcrumb('Attempting to get cluster deployments', params)
      return qbert.getClusterDeployments(clusterId, namespace)
    },
  ).addDependency(DataKeys.Pods),
)

export const createDeployment = deploymentActions.add(
  new CreateAction<DataKeys.Deployments, { clusterId: string; namespace: string; yaml: string }>(
    async ({ clusterId, namespace, yaml }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new cluster deployment', {
        clusterId,
        namespace,
        yaml,
      })
      const body = jsYaml.load(yaml)
      // Also need to refresh the list of pods
      listPods.call({ clusterId, namespace })
      const created = await qbert.createDeployment(clusterId, namespace, body)
      trackEvent('Create New Deployment', {
        clusterId,
        namespace,
        name: pathStr('metadata.name', created),
        id: pathStr('metadata.uid', created),
      })
      return created
    },
  ),
)

export const updateDeployment = deploymentActions.add(
  new UpdateAction<
    DataKeys.Deployments,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update cluster deployment', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn = requestType === 'patch' ? qbert.patchDeployment : qbert.updateDeployment
    const updatedDeployment = await updateFn(clusterId, namespace, name, body, contentType)
    trackEvent('Update Deployment', { clusterId, namespace, name })
    return updatedDeployment
  }),
)

export const deleteDeployment = deploymentActions.add(
  new DeleteAction<
    DataKeys.Deployments,
    { clusterId: string; namespace: string; name: string; id: string }
  >(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete cluster deployment', {
      clusterId,
      namespace,
      name,
      id,
    })
    await qbert.deleteDeployment(clusterId, namespace, name)
    trackEvent('Delete Deployment', { clusterId, namespace, name, id })
  }),
)
