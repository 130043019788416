import { clientActions } from 'core/client/clientReducers'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listCronjobs } from '../actions'
import { cronjobSelector } from '../selectors'
import EntityContainerDetailsPage from 'k8s/components/common/entity/entity-container-details-page'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'

export default function CronjobContainerDetailsPage() {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId, containerName } = match.params
  const { globalParams: params } = useGlobalParams(useParams, {})
  const { loading } = useListAction(listCronjobs, {
    params,
  })
  const cronjobs = useSelectorWithParams(cronjobSelector, { clusterId, useGlobalParams: false })
  const cronjob = useMemo(() => cronjobs.find((cronJob) => cronJob.id === id), [id, cronjobs])
  const container = useMemo(
    () => cronjob?.containers?.find((c) => c.name === containerName) || {},
    [cronjob],
  )

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: cronjob?.clusterName || clusterId,
        id: cronjob?.name || id,
        containerName,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [cronjob?.name, cronjob?.clusterName, id, clusterId, containerName])

  return <EntityContainerDetailsPage entity={cronjob} container={container} loading={loading} />
}
