import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { INetworkPolicySelector } from '../model'
import Progress from 'core/components/progress/Progress'
import SummaryCard from 'k8s/components/common/entity/summary-card'
import InfoCard from 'k8s/components/common/entity/info-card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import { renderAge } from 'k8s/components/common/entity/helpers'
import Card from 'core/elements/card'
import { OwnerReferences } from 'k8s/components/configuration/config-maps/details/ConfigMapsOverview'

const summaryRows: { key: keyof INetworkPolicySelector; label: string }[] = [
  { key: 'clusterName', label: 'Cluster' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'age', label: 'Age' },
]

const NetworkPoliciesOverview = ({
  networkPolicy,
  loading,
  reload,
}: {
  networkPolicy: INetworkPolicySelector
  loading: boolean
  reload: (refetch: boolean) => void
}) => {
  const classes = useStyles()

  const metadataFields = [
    {
      id: 'age',
      title: 'Created',
      required: true,
      render: renderAge,
    },
    {
      id: 'ownerReferences',
      title: 'Owner References',
      render: (value) => <OwnerReferences refs={value} />,
      condition: ({ ownerReferences }) => ownerReferences?.length > 0,
    },
    {
      id: 'numberOfIngressRules',
      title: 'Ingress Rules',
      required: true,
      render: (value) => (value === 0 ? 'No traffic allowed' : value),
    },
    {
      id: 'numberOfEgressRules',
      title: 'Egress Rules',
      required: true,
      render: (value) => (value === 0 ? '-' : value),
    },
  ]

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, networkPolicy)
  }, [networkPolicy])

  return (
    <Progress loading={loading}>
      <div className={classes.container}>
        <div className={classes.column}>
          <SummaryCard<INetworkPolicySelector>
            title={networkPolicy?.name}
            subtitle="Network Policy"
            rows={summaryRows}
            data={networkPolicy}
          />
          <InfoCard
            items={metadata}
            title="Properties"
            footer={
              <div className={classes.footer}>
                <LabelsAndAnnotationsSection entity={networkPolicy} resourceType="networkPolicy" />
              </div>
            }
          />
        </div>
      </div>
    </Progress>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutosummaryRows: 'max-content',
  },
  footer: {
    margin: theme.spacing(1),
  },
  copyClipboard: {
    '& .copy': {
      color: theme.components.typography.passive,
    },
  },
}))

export default NetworkPoliciesOverview
