import React from 'react'
import DocumentMeta from 'core/components/DocumentMeta'

import AlarmsListPage from 'k8s/components/alarms/AlarmsListPage'

export default function AlarmChartListPage() {
  return (
    <>
      <DocumentMeta title="Alarms" breadcrumbs />
      <AlarmsListPage />
    </>
  )
}
