import React from 'react'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DateCell from 'core/components/listTable/cells/DateCell'
import DataKeys from 'k8s/DataKeys'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import { roleBindingsSelector } from './selectors'
import { ArrayElement } from 'core/actions/Action'
import { listRoleBindings, deleteRoleBinding } from './new-actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useListAction from 'core/hooks/useListAction'
import ListContainer from 'core/containers/ListContainer'
import AddRoleBindingPage from 'k8s/components/rbac/AddRoleBindingPage'
import UpdateRoleBindingPage from 'k8s/components/rbac/UpdateRoleBindingPage'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'
import usePluginRouter from 'core/hooks/usePluginRouter'

type ModelDataKey = DataKeys.RoleBindings
type SelectorModel = ArrayElement<ReturnType<typeof roleBindingsSelector>>
type ActionParams = InferActionParams<typeof listRoleBindings>

type Params = ActionParams & {
  namespace?: string
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'RoleBindings',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName', 'namespace']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'name', label: 'Name' },
  { key: 'clusterName', label: 'Cluster' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'created', label: 'Created', render: (value) => <DateCell value={value} /> },
]

export default function RoleBindingsListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { currentPluginId } = usePluginRouter()
  const { message, loading, reload } = useListAction(listRoleBindings, {
    params,
    requiredParams,
  })
  const data = useAppSelector(roleBindingsSelector)

  return (
    <>
      <DocumentMeta title="Role Bindings" />
      <AddRoleBindingPage />
      <UpdateRoleBindingPage />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.RoleBindings}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={data}
        getParamsUpdater={getParamsUpdater}
        addUrl={routes.rbac.addRoleBindings.path({ plugin: currentPluginId })}
        addText="Add Role Binding"
        editUrl={(item, id) =>
          routes.rbac.editRoleBindings.path({
            id,
            clusterId: item.clusterId,
            plugin: currentPluginId,
          })
        }
        deleteAction={deleteRoleBinding}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
