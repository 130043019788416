import React, { useEffect, useMemo } from 'react'
import { head, isEmpty, prop, propOr } from 'ramda'
import { emptyObj } from 'utils/fp'
import { allKey, UserPreferences } from 'app/constants'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { sessionStoreKey } from 'core/session/sessionReducers'
import Text from 'core/elements/Text'
import { isDecco } from 'core/utils/helpers'
import { useAppSelector } from 'app/store'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { makeParamsAllClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { ecoCluster } from 'app/plugins/infrastructure/components/clusters/helpers'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'
import { controlPlaneIsReady } from 'app/plugins/infrastructure/components/clusters/capi/helpers'
import { getClusterApiServerHealthStatus } from 'app/plugins/infrastructure/components/clusters/ClusterStatusUtils'

const defaultParamsAllClusterSelector = makeParamsAllClustersSelector()

const clusterTypeLabel = {
  [ClusterTypes.Normal]: 'PF9',
  [ClusterTypes.Capi]: 'CAPI',
  [ClusterTypes.Imported]: 'Ext',
}

const renderClusterLabel = (
  name,
  clusterType,
  apiServerOffline,
  ecoNotInstalled,
  controlPlaneNotReady,
) => {
  return (
    <>
      <Text variant="body1">
        {`(${clusterTypeLabel[clusterType]}) `}
        {name}
      </Text>
      {clusterType === ClusterTypes.Normal && (
        <Text variant="caption2" component="p">
          {`API Server ${apiServerOffline ? 'Offline' : 'Online'}`}
        </Text>
      )}
      {clusterType === ClusterTypes.Imported && (
        <Text variant="caption2" component="p">{`ECO ${
          ecoNotInstalled ? 'Missing' : 'Running'
        }`}</Text>
      )}
      {clusterType === ClusterTypes.Capi && (
        <Text variant="caption2" component="p">{`Control Plane ${
          controlPlaneNotReady ? 'Not Ready' : 'Ready'
        }`}</Text>
      )}
    </>
  )
}

const apiServerIsOffline = (cluster) => {
  if (cluster?.clusterType !== ClusterTypes.Normal) return false
  const { clusterStatus } = getClusterApiServerHealthStatus(cluster)
  return clusterStatus === 'degraded' || clusterStatus === 'fail'
}

export interface Props {
  onlyMasterNodeClusters?: boolean
  onlyPrometheusEnabled?: boolean
  onlyKubevirtClusters?: boolean
  onlyHealthyClusters?: boolean
  onlyEcoEnabledClusters?: boolean
  onlyAdvancedNetworkingClusters?: boolean
  filterFn?: (clusters: CombinedClusterSelector[]) => CombinedClusterSelector[]
  filterFnDependenciesLoading?: boolean
  setInitialCluster?: boolean
}

export default function ClusterPicklist({
  name = 'clusterId',
  label = 'Cluster:',
  selectFirst = true,
  onlyMasterNodeClusters = false,
  onlyPrometheusEnabled = false,
  onlyHealthyClusters = false,
  onlyEcoEnabledClusters = false,
  onlyKubevirtClusters = false,
  onlyAdvancedNetworkingClusters = false,
  setInitialCluster = true,
  loading = false,
  compact = true,
  showAll = true,
  onChange,
  value,
  filterFn,
  filterFnDependenciesLoading = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const defaultParams = useMemo(
    () => ({
      masterNodeClusters: onlyMasterNodeClusters,
      prometheusClusters: onlyPrometheusEnabled,
      healthyClusters: onlyHealthyClusters,
      ecoEnabledClusters: onlyEcoEnabledClusters,
      kubevirtClusters: onlyKubevirtClusters,
      advancedNetworkingClusters: onlyAdvancedNetworkingClusters,
    }),
    [
      onlyMasterNodeClusters,
      onlyPrometheusEnabled,
      onlyHealthyClusters,
      onlyKubevirtClusters,
      onlyAdvancedNetworkingClusters,
    ],
  )
  const { loading: clustersLoading } = useListAction(listClusters)
  const { loading: importedClustersLoading } = useListAction(listImportedClusters)

  const allClusters: CombinedClusterSelector[] = useSelectorWithParams(
    defaultParamsAllClusterSelector,
    defaultParams,
  )

  const { prefs, fetchUserDefaults } = useScopedPreferences('defaults')
  const { uuid: defaultClusterId } = (prefs[UserPreferences.Cluster] || emptyObj) as {
    uuid: string
  }

  const allClustersLoading = clustersLoading || importedClustersLoading
  const session = useAppSelector(prop(sessionStoreKey))
  const { features } = session
  const isDeccoEnv = isDecco(features)

  useEffect(() => {
    if (setInitialCluster && isDeccoEnv) {
      fetchUserDefaults(UserPreferences.Cluster)
    }
  }, [setInitialCluster, isDeccoEnv])

  const filteredClusters = useMemo(() => (filterFn ? filterFn(allClusters) : allClusters), [
    allClusters,
    filterFnDependenciesLoading,
  ])

  const options = useMemo(
    () =>
      filteredClusters.map((cluster) => {
        const apiServerOffline =
          cluster?.clusterType === ClusterTypes.Normal && apiServerIsOffline(cluster)
        const ecoNotInstalled =
          cluster?.clusterType === ClusterTypes.Imported && !ecoCluster(cluster)
        const controlPlaneNotReady =
          cluster?.clusterType === ClusterTypes.Capi && !controlPlaneIsReady(cluster)

        return {
          label: renderClusterLabel(
            cluster?.name,
            cluster?.clusterType,
            apiServerOffline,
            ecoNotInstalled,
            controlPlaneNotReady,
          ),
          value: cluster?.uuid,
          disabled: apiServerOffline || ecoNotInstalled || controlPlaneNotReady,
        }
      }),
    [filteredClusters],
  )

  useEffect(() => {
    // Do not set any value until dependencies are done loading
    if (filterFnDependenciesLoading) return
    if (value) return
    if (isEmpty(options) || !setInitialCluster) return

    if (isDeccoEnv && defaultClusterId) {
      const option = options.find((o) => o.value === defaultClusterId)
      option ? onChange(option.value) : onChange(propOr(allKey, 'value', head(options)))
    } else if (selectFirst) {
      // Always select the first loaded item
      const nonDisabledOption = options.find((option) => !option.disabled)
      onChange(propOr(allKey, 'value', nonDisabledOption))
    }
  }, [options, defaultClusterId, isDeccoEnv, allClustersLoading])

  return (
    <AsyncDropdown
      name={name}
      compact={compact}
      showAll={showAll}
      //@ts-ignore
      items={options}
      onChange={onChange}
      label={label}
      loading={loading || allClustersLoading}
      value={value}
      {...rest}
    />
  )
}
