import React from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useReactRouter from 'use-react-router'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteDeployedApp } from '../new-actions'
import { routes } from 'core/utils/routes'
import Alert from 'core/components/Alert'
import Modal from 'core/elements/modal'

export default function DeleteAppDeploymentDialog({ name, chart, clusterId, namespace, onClose }) {
  const { history } = useReactRouter()
  const { update: deleteAppDeployment, updating: deleting, error } = useUpdateAction(
    deleteDeployedApp,
  )

  const handleAppDeploymentDeletion = async () => {
    const { success } = await deleteAppDeployment({ clusterId, namespace, name })
    if (!success) onClose()
    history.push(routes.apps.deployed.list.path({}))
  }

  return (
    <Modal
      open
      panel="dialog"
      onClose={onClose}
      title="Delete App Deployment"
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deleting}>
            Cancel
          </Button>
          <Button onClick={handleAppDeploymentDeletion} loading={deleting}>
            Delete
          </Button>
        </>
      }
    >
      <Text variant="subtitle2">{name}</Text>
      <Text variant="body2">{chart}</Text>
      <Text variant="body2">
        Are you sure you would like to proceed? Once deleted it will be removed from all clusters it
        is currently deployed.
      </Text>
      {!!error && <Alert variant="error" title={error?.title} message={error?.message} />}
    </Modal>
  )
}
