import React, { forwardRef, useMemo } from 'react'
import { projectAs } from 'utils/fp'
import { listServiceAccounts } from 'k8s/components/prometheus/new-actions'
import { serviceAccountSelector } from 'k8s/components/prometheus/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import Dropdown from 'core/elements/dropdown'

interface Props {
  value: string
  onChange: (value: string) => void
  name?: string
  label?: string
  clusterId?: string
  namespace?: string
  loading?: boolean
}

// We need to use `forwardRef` as a workaround of an issue with material-ui Tooltip https://github.com/gregnb/mui-datatables/issues/595
const ServiceAccountPicklist = forwardRef(
  (
    {
      loading,
      name = 'serviceId',
      label = 'Current Service',
      clusterId,
      namespace,
      ...rest
    }: Props,
    ref,
  ) => {
    const params = useMemo(
      () => ({
        clusterId,
        namespace,
      }),
      [clusterId, namespace],
    )
    const { loading: servicesLoading } = useListAction(listServiceAccounts, {
      params,
      requiredParams: ['clusterId', 'namespace'],
    })
    const services = useSelectorWithParams(serviceAccountSelector, params)

    const options = useMemo(() => projectAs({ label: 'name', value: 'name' }, services), [services])

    return (
      <Dropdown
        {...rest}
        loading={loading || servicesLoading}
        items={options}
        name={name}
        label={label}
      />
    )
  },
)

export default ServiceAccountPicklist
