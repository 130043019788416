import { allKey } from 'app/constants'
import ListTable from 'core/components/listTable/ListTable'
import useParams from 'core/hooks/useParams'
import useToggler from 'core/hooks/useToggler'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import EditRoleDialog from 'k8s/components/rbac/profiles/edit/EditRoleDialog'
import React, { useCallback, useMemo, useState, FC } from 'react'
import EditIcon from 'k8s/components/rbac/profiles/EditIcon'
import DeleteIcon from 'k8s/components/rbac/profiles/DeleteIcon'
import ViewIcon from 'k8s/components/rbac/profiles/ViewIcon'
import Button from 'core/elements/button'
import useStyles from 'k8s/components/rbac/profiles/edit/useStyles'
import { propEq } from 'ramda'
import { IRbacNamespaceScopedRoles, IRbacRole } from 'k8s/components/rbac/model'
import { HeaderPrimaryActionPortal } from 'core/elements/header/portals'
import ViewRoleDialog from './ViewRoleDialog'

const infoColumns = [
  { id: 'metadata.name', label: 'Name' },
  { id: 'metadata.namespace', label: 'Namespace' },
]
const visibleColumns = ['metadata.name', 'metadata.namespace', 'apiAccess']
const columnsOrder = ['metadata.name', 'metadata.namespace', 'apiAccess']
const orderBy = 'metadata.name'
const orderDirection = 'asc'
const searchTargets = ['metadata.name', 'metadata.namespace']

interface Props {
  clusterName: string
  clusterId: string
  namespaceScopedRoles: IRbacNamespaceScopedRoles[]
  onAdd: (role: IRbacRole) => void
  onUpdate: (role: IRbacRole) => void
  onRemove: (role: IRbacRole) => void
  readOnly?: boolean
}

const RolesList: FC<Props> = ({
  clusterName,
  clusterId,
  namespaceScopedRoles,
  onAdd,
  onUpdate,
  onRemove,
  readOnly = false,
}) => {
  /*****************************
   *      EDIT DIALOG
   *****************************/
  const [currentRow, setCurrentRow] = useState<IRbacRole>()
  const [showingEditDialog, toggleEditDialog] = useToggler()
  const showEditRoleDialog = useCallback((row: IRbacRole) => {
    setCurrentRow(row)
    toggleEditDialog()
  }, [])
  const handleUpdate = useCallback(
    ({ rules }: IRbacRole) => {
      onUpdate({
        ...currentRow,
        rules,
      })
      toggleEditDialog()
    },
    [currentRow, onUpdate],
  )

  /*****************************
   *        ADD DIALOG
   *****************************/
  const handleAdd = useCallback(() => {
    toggleAddDialog()
  }, [])
  const addToProfile = useCallback(
    (newRole: IRbacRole) => {
      toggleAddDialog()
      onAdd(newRole)
    },
    [onAdd],
  )
  const [showingAddDialog, toggleAddDialog] = useToggler()

  /*****************************
   *        VIEW DIALOG
   *****************************/
  const [showingViewDialog, toggleViewDialog] = useToggler()
  const showViewRoleDialog = useCallback((row: IRbacRole) => {
    setCurrentRow(row)
    toggleViewDialog()
  }, [])

  /*****************************
   *        ROLES LIST
   *****************************/
  const classes = useStyles()
  const { params, getParamsUpdater } = useParams({
    // masterNodeClusters: true,
    clusterId: allKey,
    namespace: allKey,
    clusterName,
  })
  const visibleRoles: IRbacRole[] = useMemo(() => {
    return params.namespace && params.namespace !== allKey
      ? namespaceScopedRoles.find(propEq('name', params.namespace))?.roles || []
      : namespaceScopedRoles.reduce(
          (allRoles, currentNamespace) => [...allRoles, ...(currentNamespace?.roles || [])],
          [],
        )
  }, [params.namespace, namespaceScopedRoles])

  const columns = useMemo(
    () =>
      readOnly
        ? [
            ...infoColumns,
            {
              id: 'apiAccess',
              render: (value, row) => (
                <ViewIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    showViewRoleDialog(row)
                  }}
                />
              ),
            },
          ]
        : [
            ...infoColumns,
            // Actions column
            {
              id: 'apiAccess',
              render: (value, row) => (
                <>
                  <EditIcon
                    onClick={(e) => {
                      e.stopPropagation()
                      showEditRoleDialog(row)
                    }}
                  />
                  <DeleteIcon
                    onClick={(e) => {
                      e.stopPropagation()
                      onRemove(row)
                    }}
                  />
                </>
              ),
            },
          ],
    [onRemove, readOnly],
  )

  return (
    <>
      {!readOnly && (
        <HeaderPrimaryActionPortal>
          <Button className={classes.addBtn} onClick={handleAdd} icon="plus">
            Add New Role
          </Button>
        </HeaderPrimaryActionPortal>
      )}
      {showingViewDialog && (
        <ViewRoleDialog
          clusterId={clusterId}
          onClose={toggleViewDialog}
          open={showingViewDialog}
          role={currentRow}
        />
      )}
      {showingAddDialog && (
        <EditRoleDialog
          clusterId={clusterId}
          onClose={toggleAddDialog}
          onSubmit={addToProfile}
          open={showingAddDialog}
        />
      )}
      {currentRow && showingEditDialog && (
        <EditRoleDialog
          onClose={toggleEditDialog}
          onSubmit={handleUpdate}
          open={showingEditDialog}
          clusterId={clusterId}
          role={currentRow}
        />
      )}
      <ListTable
        compact
        className={classes.listTable}
        filters={
          <NamespacePicklist
            /*
            // @ts-ignore */
            value={params.namespace}
            clusterId={clusterId}
            disabled={!clusterId}
            onChange={getParamsUpdater('namespace')}
          />
        }
        showPagination
        uniqueIdentifier={({ metadata }) => `${metadata.name}-${metadata.namespace}`}
        canEditColumns={false}
        searchTargets={searchTargets}
        showCheckboxes={false}
        data={visibleRoles}
        columns={columns}
        visibleColumns={visibleColumns}
        columnsOrder={columnsOrder}
        orderBy={orderBy}
        orderDirection={orderDirection}
      />
    </>
  )
}

export default RolesList
