import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { trackEvent } from 'utils/tracking'
import Bugsnag from 'utils/bugsnag'
import jsYaml from 'js-yaml'
import { pathStr } from 'utils/fp'

const { qbert } = ApiClient.getInstance()

export const dataVolumeActions = ActionsSet.make<DataKeys.DataVolumes>({
  uniqueIdentifier: 'id',
  indexBy: ['clusterId', 'namespace'],
  entityName: entityNamesByKey.DataVolumes,
  cacheKey: DataKeys.DataVolumes,
})

export const listDataVolumes = dataVolumeActions.add(
  new ListAction<DataKeys.DataVolumes, { clusterId: string; namespace?: string }>(
    async (params) => {
      Bugsnag.leaveBreadcrumb('Attempting to get data volumes', params)
      const { clusterId, namespace } = params
      return qbert.getDataVolumes(clusterId, namespace)
    },
  ),
)

export const createDataVolume = dataVolumeActions.add(
  new CreateAction<DataKeys.DataVolumes, { clusterId: string; namespace: string; yaml: string }>(
    async ({ clusterId, namespace, yaml }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new data volume', {
        clusterId,
        namespace,
        yaml,
      })
      const body = jsYaml.load(yaml)
      const created = await qbert.createDataVolume(clusterId, namespace, body)
      trackEvent('Create New Data Volume', {
        clusterId,
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const updateDataVolume = dataVolumeActions.add(
  new UpdateAction<
    DataKeys.DataVolumes,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update data volume', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn = requestType === 'patch' ? qbert.patchDataVolume : qbert.updateDataVolume
    const updatedDataVolume = await updateFn({
      clusterId,
      namespace,
      name,
      body,
      contentType,
    })
    trackEvent('Update Data Volume', { clusterId, namespace, name })
    return updatedDataVolume
  }),
)

export const deleteDataVolume = dataVolumeActions.add(
  new DeleteAction<
    DataKeys.DataVolumes,
    { clusterId: string; namespace: string; name: string; id: string }
  >(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete data volume', { id, clusterId, namespace, name })
    await qbert.deleteDataVolume(clusterId, namespace, name)
    trackEvent('Delete Data Volume', { clusterId, namespace, name, id })
  }),
)
