import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ListAction from 'core/actions/ListAction'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import Bugsnag from 'utils/bugsnag'
import { ensureArray, pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'

const { qbert } = ApiClient.getInstance()

const networkPolicyActions = ActionsSet.make<DataKeys.NetworkPolicies>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.NetworkPolicies],
  cacheKey: DataKeys.NetworkPolicies,
})

export const listNetworkPolicies = networkPolicyActions.add(
  new ListAction<DataKeys.NetworkPolicies, { clusterId: string; namespace?: string }>(
    async (params) => {
      Bugsnag.leaveBreadcrumb('Attempting to get Network Policies', params)
      const { namespace, clusterId } = params
      return someAsync(
        ensureArray(namespace).map((name) => qbert.getNetworkPolicies(clusterId, name)),
      ).then(flatten)
    },
  ),
)

export const createNetworkPolicy = networkPolicyActions.add(
  new CreateAction<DataKeys.NetworkPolicies, { clusterId: string; namespace: string; body: any }>(
    async ({ clusterId, namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new Network Policy', {
        clusterId,
        namespace,
        body,
      })
      const createdNetworkPolicy = await qbert.createNetworkPolicy(clusterId, namespace, body)
      trackEvent('Create New Network Policy', {
        clusterId,
        namespace,
        name: pathStr('metadata.name', createdNetworkPolicy),
      })
      return createdNetworkPolicy
    },
  ),
)

export const updateNetworkPolicy = networkPolicyActions.add(
  new UpdateAction<
    DataKeys.NetworkPolicies,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Network Policy', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn = requestType === 'patch' ? qbert.patchNetworkPolicy : qbert.updateNetworkPolicy
    const updatedNetworkPolicy = await updateFn({
      clusterId,
      namespace,
      name,
      body,
      contentType,
    })
    trackEvent('Update Network Policy', { clusterId, namespace, name })
    return updatedNetworkPolicy
  }),
)

export const deleteNetworkPolicy = networkPolicyActions.add(
  new DeleteAction<
    DataKeys.NetworkPolicies,
    { clusterId: string; namespace: string; name: string }
  >(async ({ clusterId, namespace, name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete Network Policy', {
      clusterId,
      namespace,
      name,
    })
    await qbert.deleteNetworkPolicy(clusterId, namespace, name)
  }),
)
