import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React, { useState } from 'react'
import { Labels, Annotations } from './LabelsOrAnnotations'
import Text from 'core/elements/Text'
import { isNilOrEmpty } from 'utils/fp'
import Tab from 'core/elements/tabs/Tab'
import Tabs from 'core/elements/tabs'

const useStyles = makeStyles((theme: Theme) => ({
  noneText: {
    margin: theme.spacing(2),
    color: theme.components.card.passiveText,
  },
  tabContainer: {
    paddingLeft: 8,
  },
}))

export default function LabelsAndAnnotationsTabs({ labels = {}, annotations = {} }) {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState('labels')
  return (
    <Tabs activeTab={activeTab} setActiveTab={(tab) => setActiveTab(tab)}>
      <Tab label="Labels" value="labels">
        <div className={classes.tabContainer}>
          {!isNilOrEmpty(labels) ? (
            <Labels labels={labels} variant="default" showMoreButton ellipsisAt={75} />
          ) : (
            <Text className={classes.noneText} variant="caption1">
              No Labels
            </Text>
          )}
        </div>
      </Tab>
      <Tab label="Annotations" value="annotations">
        <div className={classes.tabContainer}>
          {!isNilOrEmpty(annotations) ? (
            <Annotations
              annotations={annotations}
              variant="default"
              showMoreButton
              ellipsisAt={75}
            />
          ) : (
            <Text className={classes.noneText} variant="caption1">
              No Annotations
            </Text>
          )}
        </div>
      </Tab>
    </Tabs>
  )
}
