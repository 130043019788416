import React from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'

export const CloudProviderRequirementTD = ({ text }) => {
  const useStyles = makeStyles<Theme>((theme) => ({
    icon: {
      fontSize: 12,
      color: theme.components.graph.primary,
      marginRight: '8px',
    },
    tdContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  }))

  const classes = useStyles()
  return (
    <td>
      <div className={classes.tdContent}>
        <FontAwesomeIcon solid className={classes.icon}>
          {'check'}
        </FontAwesomeIcon>
        <Text variant="body2">{text}</Text>
      </div>
    </td>
  )
}
