import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import { renderAgeFromTimestamp } from 'k8s/components/common/entity/helpers'
import Text from 'core/elements/Text'
import InfoBlocks from 'core/components/entity-page/InfoBlocks'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import Grid from 'core/elements/grid'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { sort } from 'ramda'

interface StyleProps {
  status?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  status: {
    display: 'inline-flex',
    gap: 8,
    alignItems: 'center',
  },
  statusCircle: {
    color: ({ status }) => theme.components.badge[status]?.color,
  },
}))

export const sortByTransitionTime = (a, b) => {
  const x = a?.lastTransitionTime
  const y = b?.lastTransitionTime

  if (x > y) {
    return -1
  }
  if (y > x) {
    return 1
  }
  return 0
}

const getStatusColor = (status) => {
  if (['True', 'Succeeded'].includes(status)) {
    return 'success'
  } else if (['Paused', 'Pending', 'PVCBound'].includes(status)) {
    return 'warning'
  } else if (['False', 'Failed', 'Unknown', 'Blank'].includes(status)) {
    return 'error'
  } else {
    return 'unknown'
  }
}

const StatusCircleValue = ({ value, status }) => {
  const classes = useStyles({ status })
  return (
    <div className={classes.status}>
      <FontAwesomeIcon size="xs" className={classes.statusCircle} solid>
        circle
      </FontAwesomeIcon>
      <span>{value}</span>
    </div>
  )
}

export const conditionsColumns = [
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'status',
    label: 'Status',
    // render: (value) => <StatusCircleValue value={value} status={getStatusColor(value)} />,
  },
  { key: 'message', label: 'Message' },
  { key: 'lastTransitionTime', label: 'Last Transition Time', CellComponent: DateAndTime },
  { key: 'lastHeartbeatTime', label: 'Last Heartbeat Time', CellComponent: DateAndTime },
  { key: 'reason', label: 'Reason' },
]

const metadataFields = [
  {
    id: 'status.phase',
    title: 'Phase',
    render: (value) => <StatusCircleValue value={value} status={getStatusColor(value)} />,
  },
  {
    id: 'spec.pvc.resources.requests.storage',
    title: 'Capacity',
  },
  {
    id: 'status.claimName',
    title: 'Persistent Volume Claim',
  },
  {
    id: 'spec.pvc.storageClassName',
    title: 'Storage Class',
  },
  {
    id: 'spec.pvc.accessModes',
    title: 'Access Mode',
    render: (value) => value?.join(', '),
  },
  {
    id: 'source',
    title: 'Source',
  },
  {
    id: 'sourceMetadata.url',
    title: 'URL',
  },
  {
    id: 'sourceMetadata.name',
    title: 'PVC Name',
  },
  {
    id: 'creationTimestamp',
    title: 'Created',
    required: true,
    render: renderAgeFromTimestamp,
  },
]

export default function Overview({ dataVolume, loading }) {
  const classes = useStyles({})

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, dataVolume)
  }, [dataVolume])

  const overviewData = useMemo(
    () => [
      {
        label: 'Cluster',
        value: dataVolume?.clusterName,
      },
      {
        label: 'Namespace',
        value: dataVolume?.namespace,
      },
      {
        label: 'Age',
        value: dataVolume?.age,
      },
    ],
    [dataVolume],
  )

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <Card title={dataVolume?.name}>
            <Text variant="body2">Data Volume</Text>
            <InfoBlocks data={overviewData} />
          </Card>
          <InfoCard
            items={metadata}
            title="Properties"
            footer={<LabelsAndAnnotationsSection entity={dataVolume} resourceType="dataVolume" />}
          />
        </div>
        <div className={classes.column}>
          <Card title="Status Conditions" withCustomBody>
            <Grid
              uniqueIdentifier="type"
              data={sort(sortByTransitionTime, dataVolume?.status?.conditions || [])}
              columns={conditionsColumns}
              loading={loading}
              disableSorting
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}
