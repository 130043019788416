import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import React from 'react'
import CodeBlock from 'core/components/CodeBlock'
import { isNilOrEmpty } from 'utils/fp'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles((theme: Theme) => ({
  commands: {
    border: `solid 1px ${theme.palette.grey['200']}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    color: theme.palette.grey['700'],
    fontWeight: 'normal',
    fontFamily: 'SpaceMono',
  },
}))

export const renderContainerName = (name, container) => {
  const { clusterId, podId } = container || {}
  if (!clusterId || !podId) return name
  return (
    <SimpleLink
      src={routes.pods.container.details.path({
        clusterId,
        id: podId,
        containerName: name,
      })}
    >
      {name}
    </SimpleLink>
  )
}

const Commands = ({ commands }) => {
  const classes = useStyles()
  if (isNilOrEmpty(commands)) return null
  return (
    <CodeBlock className={classes.commands}>
      {commands.map((command) => (
        <p key={command}>{command}</p>
      ))}
    </CodeBlock>
  )
}

export const renderContainerCommands = (commands) => <Commands commands={commands} />

export const getTaintsAndTolerationsMessage = (_, toleration) => {
  if (isNilOrEmpty(toleration)) return null
  const { key = '', effect = '', tolerationSeconds } = toleration
  const taintMsg = `Schedule on nodes with ${key}${key && effect ? ':' : ''}${effect} taint.`
  const evictionMsg = tolerationSeconds ? `Evict after ${tolerationSeconds} seconds.` : null
  return evictionMsg ? taintMsg + evictionMsg : taintMsg
}
