import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import ListAction from 'core/actions/ListAction'
import yaml from 'js-yaml'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'

const { qbert } = ApiClient.getInstance()

const storageClassActions = ActionsSet.make<DataKeys.StorageClasses>({
  uniqueIdentifier: 'metadata.uid',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.StorageClasses],
  cacheKey: DataKeys.StorageClasses,
})

export const listStorageClasses = storageClassActions.add(
  new ListAction<DataKeys.StorageClasses, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Storage Classes', params)
    return qbert.getClusterStorageClasses(params.clusterId)
  }),
)

export const createStorageClass = storageClassActions.add(
  new CreateAction<DataKeys.StorageClasses, { clusterId: string; storageClassYaml: string }>(
    async ({ clusterId, storageClassYaml }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create Storage Class', { clusterId, storageClassYaml })
      const body = yaml.load(storageClassYaml)
      const created = await qbert.createStorageClass(clusterId, body)
      trackEvent('Create Storage Class', {
        id: pathStr('metadata.uid', created),
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const updateStorageClass = storageClassActions.add(
  new UpdateAction<
    DataKeys.StorageClasses,
    {
      clusterId: string
      name: string
      body: unknown
    }
  >(async ({ clusterId, name, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Storage Class', {
      clusterId,
      name,
      body,
    })
    const data = await qbert.updateClusterStorageClass(clusterId, name, body)
    trackEvent('Update Storage Class', { clusterId, name })
    return data
  }),
)

export const deleteStorageClass = storageClassActions.add(
  new DeleteAction<DataKeys.StorageClasses, { clusterId: string; name: string }>(
    async ({ clusterId, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Storage Class', { clusterId, name })
      await qbert.deleteStorageClass(clusterId, name)
      trackEvent('Delete Storage Class', { clusterId, name })
    },
  ),
)
