import React, { useCallback, useEffect, useState } from 'react'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import { updateNetworkPlugin } from '../actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import NetworkPluginParamFields from '../NetworkPluginParamFields'

const defaultParams = {}

export default function EditPluginsModal({ networkPlugin, open, onClose }) {
  const [loaded, setLoaded] = useState(false)

  const { params, updateParams, setParams } = useParams<{
    clusterId?: string
    hostPlumber?: boolean
    multusCni?: boolean
    nodeFeatureDiscovery?: boolean
    sriov?: boolean
    ovs?: boolean
    dpdk?: boolean
    platform9Dhcp?: boolean
    whereabouts?: boolean
  }>(defaultParams)
  const { update: updateFn, updating, error, reset } = useUpdateAction(updateNetworkPlugin)

  useEffect(() => {
    // Prevent resetting of form from background reloads
    if (loaded) {
      return
    }
    if (networkPlugin) {
      const plugins = networkPlugin?.spec?.plugins || {}
      updateParams({
        hostPlumber: !!plugins?.hostPlumber,
        multusCni: !!plugins?.multus,
        nodeFeatureDiscovery: !!plugins?.nodeFeatureDiscovery,
        sriov: !!plugins?.sriov,
        ovs: !!plugins?.ovs,
        dpdk: false, // TODO: waiting on dpdk details
        platform9Dhcp: !!plugins?.dhcpController,
        whereabouts: !!plugins?.whereabouts,
      })
      setLoaded(true)
    }
  }, [networkPlugin, loaded])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  const submitForm = useCallback(async () => {
    const body = {
      // TODO: Still waiting on backend for where to add DPDK
      hostPlumber: params?.hostPlumber ? {} : undefined,
      nodeFeatureDiscovery: params?.nodeFeatureDiscovery ? {} : undefined,
      multus: params?.multusCni ? {} : undefined,
      whereabouts: params?.whereabouts ? {} : undefined,
      sriov: params?.sriov ? {} : undefined,
      ovs: params?.ovs ? {} : undefined,
      dhcpController: params?.platform9Dhcp ? {} : undefined,
    }

    const ops = []
    ops.push({
      op: 'replace',
      path: '/spec/plugins',
      value: body,
    })

    const { success } = await updateFn({
      ...networkPlugin,
      body: ops,
      requestType: 'patch',
      contentType: 'application/json-patch+json',
    })
    if (success) {
      handleClose()
    }
  }, [params, networkPlugin, handleClose])

  return (
    <ModalForm
      open={open}
      title={`Edit Plugins`}
      onSubmit={submitForm}
      onClose={onClose}
      submitting={false}
      error={error}
      submitTitle={`Save Changes`}
      maxWidth={528}
    >
      <NetworkPluginParamFields method="edit" params={params} updateParams={updateParams} />
    </ModalForm>
  )
}
