import React, { useMemo, useCallback } from 'react'
import { emptyObj } from 'utils/fp'
import useReactRouter from 'use-react-router'
import { propEq } from 'ramda'
import RbacChecklist from './RbacChecklist'
import PresetField from 'core/components/PresetField'
import { makeStyles } from '@material-ui/styles'
import DocumentMeta from 'core/components/DocumentMeta'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { routes } from 'core/utils/routes'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listRoles, updateRole } from './new-actions'
import Theme from 'core/themes/model'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { rolesSelector } from './selectors'
import { IRbacRoleSelector } from './model'
import ModalForm from 'core/elements/modal/ModalForm'
import usePluginRouter from 'core/hooks/usePluginRouter'

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

export default function UpdateRolePage() {
  const classes = useStyles({})
  const { match, history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()

  const roleId = match.params.id
  const clusterId = match.params.clusterId
  const { loading } = useListAction(listRoles, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const roles = useSelectorWithParams(rolesSelector, { clusterId })

  const role = useMemo(() => roles.find(propEq('id', roleId)) || (emptyObj as IRbacRoleSelector), [
    roles,
    roleId,
  ])
  const { update, updating, error: errorUpdatingRole, reset } = useUpdateAction(updateRole)

  const submitForm = useCallback(
    async (params) => {
      const { success } = await update({ ...role, ...params })
      if (success) handleClose()
    },
    [role],
  )
  const handleClose = () => {
    reset()
    console.log(currentPluginId)
    history.push(routes.rbac.roles.path({ plugin: currentPluginId }))
  }

  return (
    <>
      <DocumentMeta title="Update Role" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.rbac.editRoles}
        title="Edit Role"
        onSubmit={submitForm}
        onClose={handleClose}
        loading={loading}
        submitting={updating}
        error={errorUpdatingRole}
        loadingMessage={loading ? 'Loading role...' : 'Submitting form...'}
        submitTitle="Update Role"
      >
        <>
          <FormFieldSection title="Basic Details" step={1}>
            <PresetField label="Name" value={role.name} />
            <PresetField label="Cluster" value={role.clusterName} />
            <PresetField label="Namespace" value={role.namespace} />
          </FormFieldSection>
          {role.clusterId && (
            <FormFieldSection title="API Access / Permissions" step={2}>
              <RbacChecklist id="rbac" clusterId={role.clusterId} initialRules={role.rules} />
            </FormFieldSection>
          )}
        </>
      </ModalForm>
    </>
  )
}
