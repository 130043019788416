import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import RoleAnalysis from './RoleAnalysis'
import ClusterRoleAnalysis from './ClusterRoleAnalysis'
import RoleBindingAnalysis from './RoleBindingAnalysis'
import { groupBy, prop } from 'ramda'
import ClusterRoleBindingAnalysis from './ClusterRoleBindingAnalysis'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    background: theme.palette.blue[100],
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  overviewHeader: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  headerText: {
    color: 'black',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(0.5),
  },
  overviewBody: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
  },
  headerOverviewCounter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 266,
  },
  overviewCounter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 258,
    margin: theme.spacing(0, 1),
  },
  verticallyCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const validJsonOrEmpty = (value) => {
  try {
    return JSON.parse(value)
  } catch {
    return ''
  }
}

const getResourceType = (path) => {
  const type = ['/role/', '/clusterRole/', '/roleBinding/', '/clusterRoleBinding'].find((str) =>
    path.includes(str),
  )
  return type.replace(/\//g, '')
}

interface Props {
  analysisString: string
  clusterId: string
  className?: string
  isImpactAnalysis?: boolean
}

const DriftAnalysis = ({
  analysisString,
  clusterId,
  className = '',
  isImpactAnalysis = false,
}: Props) => {
  const classes = useStyles({})
  const parsedList = useMemo(() => (analysisString ? JSON.parse(analysisString) : []), [
    analysisString,
  ])
  const mappedList = useMemo(
    () =>
      parsedList.map((item) => {
        // Currently the extra op only has the resource data in oldValue
        // Can get rid of line below after pushkar's change is in
        const value = item.op === 'extra' ? item.oldValue : item.newValue
        const parsedValue = validJsonOrEmpty(value)
        return {
          ...item,
          newValue: parsedValue,
          oldValue: validJsonOrEmpty(item.oldValue),
          resourceType: getResourceType(item.path),
          name: parsedValue?.metadata?.name,
        }
      }),
    [parsedList],
  )

  const resourcesByType = useMemo(() => groupBy(prop('resourceType'), mappedList), [mappedList])
  const [activeTab, setActiveTab] = useState('roles')
  return (
    <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
      <Tab value="roles" label="Roles">
        <RoleAnalysis
          roles={resourcesByType.role || []}
          isImpactAnalysis={isImpactAnalysis}
          clusterId={clusterId}
        />
      </Tab>
      <Tab value="cluster-roles" label="Cluster Roles">
        <ClusterRoleAnalysis
          clusterRoles={resourcesByType.clusterRole || []}
          isImpactAnalysis={isImpactAnalysis}
          clusterId={clusterId}
        />
      </Tab>
      <Tab value="role-bindings" label="Role Bindings">
        <RoleBindingAnalysis
          roleBindings={resourcesByType.roleBinding || []}
          isImpactAnalysis={isImpactAnalysis}
          clusterId={clusterId}
        />
      </Tab>
      <Tab value="cluster-role-bindings" label="Cluster Role Bindings">
        <ClusterRoleBindingAnalysis
          clusterRoleBindings={resourcesByType.clusterRoleBinding || []}
          isImpactAnalysis={isImpactAnalysis}
          clusterId={clusterId}
        />
      </Tab>
    </Tabs>
  )
}

export default DriftAnalysis
