import React, { useMemo } from 'react'
import Text from 'core/elements/Text'
import CodeBlock from 'core/components/CodeBlock'
import CopyToClipboard from 'core/components/CopyToClipboard'
import Info from 'core/components/validatedForm/Info'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import BulletList from 'core/components/BulletList'
import useScopedPreferences from '../../../../core/session/useScopedPreferences'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import useListAction from 'core/hooks/useListAction'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import Progress from 'core/components/progress/Progress'
import {
  pf9CliInstallationSteps,
  UserInfo,
} from 'app/plugins/infrastructure/components/nodes/DownloadCliWalkthrough'

const useStyles = makeStyles((theme: Theme) => ({
  linkText: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  spaceAbove: {
    marginTop: theme.spacing(2),
  },
  downloadIcon: {
    marginLeft: theme.spacing(1),
  },
  downloadButton: {
    marginTop: theme.spacing(1),
  },
  userInfo: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}))

const installationSteps = pf9CliInstallationSteps.map(({ text, command }) => (
  <>
    <Text>{text}</Text>
    <CopyToClipboard copyText={command}>
      <CodeBlock>{<Text variant="body2">{command}</Text>}</CodeBlock>
    </CopyToClipboard>
  </>
))

const DownloadCliWalkthrough = () => {
  const classes = useStyles()
  const selectSessionState = prop<string, SessionState>(sessionStoreKey)
  const session = useSelector(selectSessionState)
  const { getUserPrefs } = useScopedPreferences()
  const { currentTenant, currentRegion } = getUserPrefs(session.username)

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  const tenant = useMemo(() => tenants.find((tenant) => tenant.id === currentTenant), [tenants])

  return (
    <Progress loading={loadingTenants} renderContentOnMount={!loadingTenants}>
      <Info title="Use the PF9 CLI to connect nodes to the Platform9 Management Plane">
        <BulletList type="decimal" items={installationSteps} />
        <div className={classes.userInfo}>
          <UserInfo
            classes={classes}
            username={session?.username}
            tenantName={tenant?.name}
            currentRegion={currentRegion}
          />
        </div>
      </Info>
    </Progress>
  )
}

export default DownloadCliWalkthrough
