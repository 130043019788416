import { clientActions } from 'core/client/clientReducers'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import EntityContainerDetailsPage from '../common/entity/entity-container-details-page'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { daemonSetSelector } from './selectors'
import { listDaemonSets } from './actions'
import { IDaemonSetSelector } from 'k8s/components/daemon-sets/models'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'

export default function DaemonSetContainerDetailsPage() {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId, containerName } = match.params
  const { globalParams: params } = useGlobalParams(useParams, {})
  const { loading } = useListAction(listDaemonSets, {
    params,
  })
  const daemonSets = useSelectorWithParams(daemonSetSelector, { clusterId, useGlobalParams: false })
  const daemonSet = useMemo(
    () => daemonSets.find((daemonSet) => daemonSet.id === id) || ({} as IDaemonSetSelector),
    [id, daemonSets],
  )
  const { initContainers = [], containers = [] } = daemonSet
  const container = useMemo(
    () =>
      initContainers.find((c) => c.name === containerName) ||
      containers.find((c) => c.name === containerName) ||
      {},
    [daemonSet],
  )

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: daemonSet?.clusterName || clusterId,
        id: daemonSet?.name || id,
        containerName,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [daemonSet?.clusterName, daemonSet?.name, id, clusterId, containerName])

  return <EntityContainerDetailsPage entity={daemonSet} container={container} loading={loading} />
}
