import { isEmpty } from 'ramda'
import { isNilOrEmpty } from 'utils/fp'
import { memoize } from 'utils/misc'

export const smallAppPlaceholderIcon = '/ui/images/app-catalog/app-cat-placeholder-logo.png'
export const medAppPlaceholderIcon = '/ui/images/app-catalog/app-cat-placeholder-logo@2x.png'
export const largeAppPlaceholderIcon = '/ui/images/app-catalog/app-cat-placeholder-logo@3x.png'

export const getIcon = (icon) =>
  icon && icon.match(/.(jpg|jpeg|png|gif)/) ? icon : medAppPlaceholderIcon

export const getAppVersionPicklistOptions = (versions, numOptionsToShow = 15) => {
  if (!versions) {
    return []
  }
  return versions.slice(0, numOptionsToShow).map((version) => ({ value: version, label: version }))
}

/**
 * Returns a list of clusters that are connected to the repository
 * @param {array} connectedClustersIds Array of all clusterIds that are connected to the repository
 * @param {array} clusters Array of all clusters
 * @returns
 */
export const filterConnectedClusters = (connectedClustersIds, clusters) => {
  if (isNilOrEmpty(connectedClustersIds) || isNilOrEmpty(clusters)) return []
  const connectedClusterIds = new Set(connectedClustersIds)
  return clusters.filter((cluster) => connectedClusterIds.has(cluster.uuid))
}

export const filterByRepositories = memoize((apps = [], targetRepositoryNames = []) => {
  if (isEmpty(apps)) return apps
  if (isEmpty(targetRepositoryNames)) return []
  const repositories = new Set(targetRepositoryNames)
  return apps.filter((app) => repositories.has(app.repository))
})

/**
 *
 * @param {string} name (Ex. "fluentd/fluent-bit")
 * @returns {string} (Ex. "fluentd")
 */
export const getRepositoryName = (str) => str.match(/^(\w||-)+/)[0]
