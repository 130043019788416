import React, { useEffect, useState } from 'react'
import { propEq } from 'ramda'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { ClusterSelector } from '../../../infrastructure/components/clusters/model'
import { convertBareOSNodeTypeToCloudProviderType } from '../../../infrastructure/components/clusters/helpers'
import useWizardSummaryUpdater from 'core/components/wizard/useWizardSummaryUpdater'
import { memoize } from 'utils/misc'
import { WizardSummaryFieldsProps } from 'core/components/wizard/model'

interface Props<T> extends WizardSummaryFieldsProps<T> {
  cluster: ClusterSelector
}

export const getSelectedCluster = memoize((clusters, id) => clusters.find(propEq('uuid', id)))

const getClusterImage = memoize((cluster) => {
  const clusterNodeCPUType =
    cluster?.nodes?.[0]?.combined?.resmgr?.info?.cpu_info['virtual/physical']

  return cluster?.cloudProviderType === CloudProviders.BareOS
    ? convertBareOSNodeTypeToCloudProviderType(clusterNodeCPUType)
    : cluster?.cloudProviderType
})

export default function ClusterResourceWizardMeta<T>({ cluster, ...props }: Props<T>) {
  const [cloudProviderIconType, setCloudProviderIconType] = useState(getClusterImage(cluster))
  useEffect(() => {
    setCloudProviderIconType(getClusterImage(cluster))
  }, [cluster])
  useWizardSummaryUpdater({
    ...props,
    icon: cloudProviderIconType,
  })
  return null
}
