export enum IStoragePageTabs {
  CSI = 'csi-drivers',
  PV = 'persistent-volumes',
  PVC = 'persistent-volume-claims',
  StorageClasses = 'storage-classes',
  StorageProviders = 'storage-providers',
}
export enum IStorageDetailsPageTabs {
  Overview = 'overview',
  Events = 'events',
  Yaml = 'yaml',
}
