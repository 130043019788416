import Bugsnag from 'utils/bugsnag'
import Progress from 'core/components/progress/Progress'
import { makeStyles } from '@material-ui/styles'
import ApiClient from 'api-client/ApiClient'
import LogViewer from 'core/components/log-viewer'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import { isEmpty } from 'ramda'
import React, { useEffect, useState } from 'react'
import LogsDialog from './logs-dialog'
import Button from 'core/elements/button'
import CopyToClipboard from 'core/components/CopyToClipboard'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import Card from 'core/elements/card'
import CardFooter from 'core/elements/card/CardFooter'
import CardHeader from 'core/elements/card/CardHeader'
import { hexToRgbaCss } from 'core/utils/colorHelpers'
import usePluginRouter from 'core/hooks/usePluginRouter'

interface Props {
  rawLog: string
}

interface StyleProps extends Props {
  loading: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  logsPage: {
    margin: theme.spacing(1),
    maxWidth: 'minmax(100%, max-content)',
  },
  logs: {
    margin: '0 8px 8px 24px',
    position: 'relative',
    display: 'grid',
  },
  gradient: {
    height: '30px',
  },
  card: {
    position: 'relative',
    height: '244px',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr max-content',
    gap: theme.spacing(2),
  },
  log: {
    position: 'relative',
    overflow: 'hidden',

    '& .progress-status': {
      padding: 0,
      maxHeight: '135px',
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      background: ({ rawLog, loading }) => {
        if (loading || !rawLog) return 'none'
        return `linear-gradient(
          ${hexToRgbaCss(theme.components.card.background, 0.1)} 0%, 
          ${hexToRgbaCss(theme.components.card.background, 0.5)} 35%, 
          ${hexToRgbaCss(theme.components.card.background, 0.8)} 85%, 
          ${hexToRgbaCss(theme.components.card.background, 0.9)} 100%
        )`
      },
      height: '60px',
    },
  },
  header: {
    display: 'grid',
    justifyContent: 'space-between',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gap: '16px',
    padding: '10px 16px 10px 24px',
  },
  logText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  linkText: {
    color: theme.palette.blue[500],
    cursor: 'pointer',
  },
  headerActions: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridGap: '8px',
  },
  footer: {
    padding: '8px 16px 16px 16px',
  },
}))

const { qbert } = ApiClient.getInstance()

const ContainerLogCard = ({ pod, container }) => {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtPods' : 'pods'
  const [logLines, setLogLines] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rawLog, setRawLog] = useState(null)
  const classes = useStyles({ rawLog, loading })

  const downloadTextFile = () => {
    const element = document.createElement('a')
    const file = new Blob([rawLog], {
      type: 'text/plain',
    })
    element.href = URL.createObjectURL(file)
    element.download = `${container?.name || ''}-logs.txt`
    document.body.appendChild(element)
    element.click()
  }

  useEffect(() => {
    const fetchLogs = async () => {
      Bugsnag.leaveBreadcrumb(
        `Attempting to get logs for pod ${pod} container ${container?.name}`,
        {
          clusterId: pod?.clusterId,
          namespace: pod?.namespace,
          pod: pod?.name,
          container: container?.name,
        },
      )
      const params = {
        container: container?.name,
        timestamps: true,
        tailLines: 500,
      }
      try {
        setLoading(true)
        const { data: log } = await qbert.getPodLogs(
          pod?.clusterId,
          pod?.namespace,
          pod?.name,
          params,
        )
        setRawLog(log)
      } catch (err) {
        setRawLog('')
      } finally {
        setLoading(false)
      }
    }

    if (!pod || !container?.name) return
    fetchLogs()
  }, [pod, container])

  useEffect(() => {
    if (!rawLog || typeof rawLog !== 'string') return
    const lines = rawLog.split(/\n/) || []
    const linesLength = lines.length
    // Show only the last 7 lines of the log
    setLogLines(lines.slice(linesLength > 7 ? linesLength - 7 : 0, linesLength))
  }, [rawLog])

  return (
    <>
      {openDialog && (
        <LogsDialog
          containerName={container?.name}
          log={rawLog}
          onClose={() => setOpenDialog(false)}
        />
      )}
      <Card className={classes.card} withCustomBody>
        <CardHeader className={classes.header}>
          <SimpleLink
            src={routes[routePath].container.details.path({
              clusterId: pod?.clusterId,
              id: pod?.id,
              containerName: container?.name,
            })}
            textVariant="subtitle2"
          >
            {container?.name}
          </SimpleLink>
          <div className={classes.headerActions}>
            <CopyToClipboard copyText={rawLog} inline codeBlock={false} triggerWithChild>
              <Button variant="secondary" icon="copy">
                Copy Logs
              </Button>
            </CopyToClipboard>
            <Button onClick={downloadTextFile} icon="download">
              Download
            </Button>
          </div>
        </CardHeader>
        <div className={classes.log}>
          <Progress loading={loading} overlay className={classes.progress}>
            <div className={classes.logs}>
              {loading || isEmpty(logLines) ? (
                <Text variant="body2">No logs available</Text>
              ) : (
                <LogViewer logs={logLines} />
              )}
            </div>
          </Progress>
        </div>
        <CardFooter className={classes.footer}>
          <Button onClick={() => setOpenDialog(true)}>Show full logs</Button>
        </CardFooter>
      </Card>
    </>
  )
}

export default ContainerLogCard
