import React from 'react'
import useDataUpdater from 'core/hooks/useDataUpdater'
import { repositoryActions } from '../actions'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import Modal from 'core/elements/modal'

interface DisconnectRepoDialogProps {
  name: string
  clusterId: string
  onClose: () => void
  onSubmit?: () => void
}

const DisconnectRepositoryDialog = ({
  name,
  clusterId,
  onClose,
  onSubmit,
}: DisconnectRepoDialogProps) => {
  const anyRepositoryActions = repositoryActions as any
  const [disconnectRepository, disconnectingRepository] = useDataUpdater(
    anyRepositoryActions.deleteClustersFromRepository,
    onSubmit || onClose,
  )

  const handleRepositoryDeletion = () =>
    disconnectRepository({ repoName: name, clusterIds: [clusterId] })

  return (
    <Modal
      open
      panel="dialog"
      onClose={onClose}
      title={`Disconnect Repository - ${name}`}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={disconnectingRepository}>
            Cancel
          </Button>
          <Button onClick={handleRepositoryDeletion} loading={disconnectingRepository}>
            Disconnect
          </Button>
        </>
      }
    >
      <Text variant="body2" component="p">
        Are you sure you would like to proceed?
      </Text>
      <Text variant="body2">
        Once disconnected, all apps that reside in this repository will no longer be available to
        this cluster.
      </Text>
    </Modal>
  )
}

export default DisconnectRepositoryDialog
