import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  labels: {
    display: 'flex',
    columnGap: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}))

export const renderLabelsInOneRow = (obj) => <LabelsInOneRow obj={obj} />

const LabelsInOneRow = ({ obj }) => {
  const classes = useStyles()
  return (
    <div className={classes.labels}>
      {Object.entries(obj).map(([key, value]) => (
        <div>
          <Text component="span" variant="caption1">
            {`${key}: `}
          </Text>
          <Text component="span" variant="body2">
            {value}
          </Text>
        </div>
      ))}
    </div>
  )
}

export default LabelsInOneRow
