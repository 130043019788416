import React, { useMemo } from 'react'
import Picklist from 'core/elements/dropdown/AsyncDropdown'
import { projectAs } from 'utils/fp'
import { propEq, always } from 'ramda'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'
import useListAction from 'core/hooks/useListAction'

import { useSelector } from 'react-redux'
import { listCloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
import { cloudProvidersSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
interface CloudProviderPicklistProps extends Omit<DropdownProps<string>, 'items'> {
  type?: string
  id?: string
}

export default function CloudProviderPicklist({
  type,
  showAll = false,
  showNone = false,
  id,
  name = id || 'cloudProviderId',
  label = 'Cloud Provider',
  ...rest
}: CloudProviderPicklistProps) {
  const { loading } = useListAction(listCloudProviders)
  const cloudProviders = useSelector(cloudProvidersSelector)
  const items = useMemo<{ label: string; value: string }[]>(
    () =>
      projectAs(
        { value: 'uuid', label: 'name' },
        cloudProviders.filter(type ? propEq('type', type) : always(true)),
      ),
    [cloudProviders],
  )

  return (
    <Picklist
      {...rest}
      name={name}
      label={label}
      showAll={showAll}
      showNone={showNone}
      loading={loading}
      items={items}
    />
  )
}
