import { allKey } from 'app/constants'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import getDataSelector from 'core/utils/getDataSelector'
import DataKeys from 'k8s/DataKeys'
import { pipe, propEq } from 'ramda'
import { createSelector } from '@reduxjs/toolkit'
import { arrayIfEmpty, filterIf } from 'utils/fp'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { findCluster } from 'k8s/util/helpers'
import { IGenericResource } from 'api-client/qbert.model'
import { ConfigMapItem } from './models'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'
import { durationBetweenDates } from 'utils/misc'

export enum IConfigMapsTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface IConfigMapSelector extends IGenericResource<ConfigMapItem> {
  clusterName: string
  clusterId: string
  clusterType: ClusterTypes
  dataSize: number
  ownerReferences: ConfigMapItem['metadata']['ownerReferences']
  age?: string
  labels: ConfigMapItem['metadata']['labels']
  annotations: ConfigMapItem['metadata']['annotations']
}

export const configMapsSelector = createSharedSelector(
  getDataSelector<DataKeys.ConfigMaps>(
    DataKeys.ConfigMaps,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  (rawConfigMaps, allClusters): IConfigMapSelector[] => {
    return rawConfigMaps.map((rawConfigMap) => {
      const { clusterId, metadata, data, binaryData } = rawConfigMap
      const { name: clusterName, clusterType: clusterType } = findCluster(allClusters, clusterId)
      const dataLength = !!data ? Object.keys(data).length : 0
      const binaryDataLength = !!binaryData ? Object.keys(binaryData).length : 0
      const dataSize = dataLength + binaryDataLength
      return {
        ...rawConfigMap,
        clusterName,
        clusterId,
        clusterType,
        dataSize,
        age: durationBetweenDates({ labels: ['d'] })(metadata?.creationTimestamp),
        ownerReferences: metadata?.ownerReferences,
        creationTimestamp: metadata?.creationTimestamp,
        labels: metadata?.labels,
        annotations: metadata?.annotations,
      }
    })
  },
)

export const makeConfigMapsSelector = (
  defaultParams = {} as SortConfig & { namespace?: string; clusterId?: string },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(
    getDataSelector<DataKeys.ConfigMaps>(DataKeys.ConfigMaps),
    selectParams,
    (configMaps, params) => {
      const { clusterId, namespace, orderBy, orderDirection } = params
      return pipe<any, any, any, any, any>(
        filterIf(clusterId && clusterId !== allKey, propEq('clusterId', clusterId)),
        filterIf(namespace && namespace !== allKey, propEq('namespace', namespace)),
        createSorter({ orderBy, orderDirection }),
        arrayIfEmpty,
      )(configMaps)
    },
  )
}
