import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import store from 'app/store'
import { cacheActions } from 'core/caching/cacheReducers'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { trackEvent } from 'utils/tracking'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'
import { isEmpty } from 'ramda'

const { helm } = ApiClient.getInstance()
const { dispatch } = store

export const clusterRepositoryActions = ActionsSet.make<DataKeys.RepositoriesForCluster>({
  uniqueIdentifier: 'name',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.RepositoriesForCluster],
  cacheKey: DataKeys.RepositoriesForCluster,
})

export const listRepositoriesForCluster = clusterRepositoryActions.add(
  new ListAction<DataKeys.RepositoriesForCluster, { clusterId: string }>(async (params) => {
    const { clusterId } = params
    Bugsnag.leaveBreadcrumb('Attempting to get repositories for cluster', params)
    const repos = await helm.getRepositoriesForCluster(clusterId)
    return repos.map((repo) => ({ ...repo, clusterId }))
  }),
)

export const repositoryActions = ActionsSet.make<DataKeys.Repositories>({
  uniqueIdentifier: ['repo.name', 'repo.url'],
  entityName: entityNamesByKey[DataKeys.Repositories],
  cacheKey: DataKeys.Repositories,
})

export const listRepositories = repositoryActions.add(
  new ListAction<DataKeys.Repositories>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get all repositories')
    return helm.getRepositories()
  }).addDependency(DataKeys.Apps),
)

export const createRepository = repositoryActions.add(
  new CreateAction<
    DataKeys.Repositories,
    { name: string; url: string; username?: string; password?: string; autoAttach?: boolean }
  >(async ({ name, url, username, password, autoAttach = false }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create repository', { name, url })
    const body = {
      name,
      url,
      username: username || undefined,
      password: password || undefined,
      auto_attach: autoAttach,
    }
    const result = await helm.createRepository(body)

    dispatch(cacheActions.clearCache({ cacheKey: DataKeys.Apps }))
    dispatch(cacheActions.clearCache({ cacheKey: DataKeys.RepositoriesForCluster }))

    trackEvent('Add Repository', {
      name,
      url,
    })
    return result
  }),
)

export const updateRepository = repositoryActions.add(
  new UpdateAction<
    DataKeys.Repositories,
    { name: string; url: string; username?: string; password?: string; autoAttach?: boolean }
  >(async ({ name, url, username, password, autoAttach = false }) => {
    const body = {
      name,
      url,
      username: username || undefined,
      password: password || undefined,
      auto_attach: autoAttach,
    }
    Bugsnag.leaveBreadcrumb('Attempting to update repository', { name, url })
    const result = await helm.updateRepository(body)
    trackEvent('Update Repository', { name, url })
    return result
  }),
)

export const deleteRepository = repositoryActions.add(
  new DeleteAction<DataKeys.Repositories, { name: string }>(async ({ name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete repository', { name })

    await helm.deleteRepository(name)

    dispatch(cacheActions.clearCache({ cacheKey: DataKeys.Apps }))
    dispatch(cacheActions.clearCache({ cacheKey: DataKeys.RepositoriesForCluster }))

    trackEvent('Remove Repository', {
      name,
    })
  }),
)

interface RepositoryNames {
  name: string
}

export const syncRepositories = repositoryActions.add(
  new CustomAction<DataKeys.Repositories, { repositories: RepositoryNames[] }, any>(
    'syncRepositories',
    async ({ repositories }) => {
      Bugsnag.leaveBreadcrumb('Attempting to sync repositories', { repositories })
      const body = repositories.map((repository) => ({ name: repository.name }))
      await helm.syncRepositories(body)
      trackEvent('Sync Repositories', { repositories })
      // Update the cache to get the synced repositories
      await listRepositories.call({})
    },
  ),
)

export const addClustersToRepository = repositoryActions.add(
  new CustomAction<DataKeys.Repositories, { repoName: string; clusterIds: string[] }, any>(
    'addClustersToRepository',
    async ({ repoName, clusterIds }) => {
      Bugsnag.leaveBreadcrumb('Attempting to attach clusters to repository', {
        repoName,
        clusterIds,
      })
      const body = clusterIds.map((id) => ({ cluster_uuid: id }))
      await helm.addClustersToRepository(repoName, body)

      dispatch(cacheActions.clearCache({ cacheKey: DataKeys.RepositoriesForCluster }))
      dispatch(cacheActions.clearCache({ cacheKey: DataKeys.AppsAvailableToCluster }))

      trackEvent('Attach Clusters to Repository', { repoName, clusterIds })

      await listRepositories.call({})
    },
  ),
)

export const deleteClustersFromRepository = repositoryActions.add(
  new CustomAction<DataKeys.Repositories, { repoName: string; clusterIds: string[] }, any>(
    'deleteClustersFromRepository',
    async ({ repoName, clusterIds }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete clusters from repository', {
        repoName,
        clusterIds,
      })
      const body = clusterIds.map((id) => ({ cluster_uuid: id }))
      await helm.deleteClustersFromRepository(repoName, body)

      dispatch(cacheActions.clearCache({ cacheKey: DataKeys.RepositoriesForCluster }))
      dispatch(cacheActions.clearCache({ cacheKey: DataKeys.AppsAvailableToCluster }))

      trackEvent('Detach Clusters From Repository', { repoName, clusterIds })

      await listRepositories.call({})
    },
  ),
)

interface AddRepositoryProps {
  name: string
  url: string
  username?: string
  password?: string
  clusterIds?: string[]
  autoAttach?: boolean
  addRepoFn: any
  addClustersToRepoFn: any
}

export const addRepositoryAndConnectClusters = async ({
  name,
  url,
  username = null,
  password = null,
  clusterIds = [],
  autoAttach,
  addRepoFn,
  addClustersToRepoFn,
}: AddRepositoryProps) => {
  let success: false

  const { success: successAddingRepo, response } = await addRepoFn({
    name: name.replace(/\s+/g, '_'), // Replace all whitespaces with underscores
    url: url.trim(),
    username,
    password,
    autoAttach,
  })
  success = successAddingRepo

  if (successAddingRepo && !isEmpty(clusterIds) && !autoAttach) {
    const { success: successAddingRepoToCluster } = await addClustersToRepoFn({
      repoName: response.repo?.name,
      clusterIds: clusterIds,
    })
    success = successAddingRepoToCluster
  }

  return { success }
}

export const updateConnectedClusters = async ({
  repository,
  selectedClusterIds,
  deleteClustersFromRepo,
  addClustersToRepo,
}) => {
  // Find clusters that were removed
  const clustersToRemove = repository.clusterIds.filter(
    (clusterId) => !selectedClusterIds.includes(clusterId),
  )
  if (clustersToRemove.length > 0) {
    await deleteClustersFromRepo({ repoName: repository.name, clusterIds: clustersToRemove })
  }

  // Find clusters that were added
  const clustersToAdd = selectedClusterIds.filter(
    (clusterId) => !repository.clusterIds.includes(clusterId),
  )
  if (clustersToAdd.length > 0) {
    await addClustersToRepo({
      repoName: repository.name,
      clusterIds: clustersToAdd,
    })
  }
}
