import React from 'react'
import { flatten, keys, mergeAll, mergeDeepLeft } from 'ramda'
import { allKey } from 'app/constants'
import { memoize } from 'utils/misc'

const apiGroupName = (name) => {
  if (name === '') {
    return 'core'
  }
  return name
}

const processRule = (rule) => {
  const ruleApiGroups = rule.apiGroups.map(apiGroupName)

  const withResources = ruleApiGroups.map((apiGroup) => {
    return { apiGroup, resources: rule.resources, verbs: rule.verbs }
  })

  const withVerbs = withResources.map((item) => {
    // use verb: true format so I can use mergeDeepLeft easily
    const verbsObject = item.verbs.reduce((accum, verb) => ({ ...accum, [verb]: true }), {})
    const verbsByResource = item.resources.reduce((accum, current) => {
      const resourceVerbs = { [current]: verbsObject }
      return { ...accum, ...resourceVerbs }
    }, {})
    return { [item.apiGroup]: verbsByResource }
  })

  const translatedRule = mergeAll(withVerbs)
  return translatedRule
}

export const parseRoleRules = (rules = []) => {
  const rbac = rules?.reduce((accum, current) => {
    const newRules = processRule(current)
    return mergeDeepLeft(accum, newRules)
  }, {})
  return rbac
}

export const numApiResources = (rbac) => {
  const apiGroups = keys(rbac)
  const resources = flatten(apiGroups.map((group) => keys(rbac[group])))
  return resources.length
}

const opIcons = {
  add: <img src="/ui/images/icon-plus-square-solid@2x.png" />,
  replace: <img src="/ui/images/icon-exchange-square-solid@2x.png" />,
  extra: <img src="/ui/images/icon-square-solid@2x.png" />,
}

export const renderOp = (op) => {
  return opIcons[op]
}

// for roles, cluster roles, role bindings, cluster role bindings in drift analysis
export const filterByOperation = memoize((resources, operation) =>
  resources.filter((resource) => {
    if (operation === allKey) {
      return true
    }
    return resource.op === operation
  }),
)

export const filterApiGroups = memoize((apiGroups, apiGroupFilter) =>
  apiGroups.filter((apiGroup) => {
    if (apiGroupFilter === allKey) {
      return true
    }
    return apiGroup === apiGroupFilter
  }),
)
