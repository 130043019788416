import React, { useMemo } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import useReactRouter from 'use-react-router'
import CardBody from 'core/elements/card/CardBody'
import Text from 'core/elements/Text'
import { medAppPlaceholderIcon } from '../../helpers'
import { routes } from 'core/utils/routes'
import ImageWithFallback from 'core/components/image-fallback'
import SelectableCard from 'core/components/SelectableCard'
import Badge, { BadgeVariant } from 'core/elements/badge/Badge'
import { capitalizeString } from 'utils/misc'
import generateTestId from 'utils/test-helpers'

export default function DeployedAppCard({ app }) {
  const classes = useStyles()
  const { history } = useReactRouter()
  const {
    clusterId,
    namespace,
    name,
    repository,
    chart,
    icon,
    description,
    version,
    status,
    clusterName,
  } = app

  const handleOnClick = () =>
    history.push(
      routes.apps.deployed.details.path({ clusterId, namespace, repository, chart, name }),
    )

  const badgeVariant = useMemo((): BadgeVariant => {
    switch (status) {
      case 'deployed':
        return 'success'
      case 'uninstalled':
      case 'failed':
        return 'error'
      case 'superseded':
      case 'pending-install':
      case 'pending-upgrade':
        return 'warning'
      case 'uninstalling':
      case 'pending-rollback':
        return 'danger'
      default:
        return 'unknown'
    }
  }, [status])

  return (
    <SelectableCard id={name} onClick={handleOnClick} className={classes.appCard} withCustomBody>
      <CardBody className={classes.cardBody}>
        <div className={classes.cardHeader}>
          <ImageWithFallback
            src={icon}
            fallbackSrc={medAppPlaceholderIcon}
            alt="App Logo"
            className={classes.appIcon}
          />
          {status && <Badge variant={badgeVariant} text={capitalizeString(status)} />}
        </div>
        <div className={classes.cardInfo}>
          <Text variant="caption1">{clusterName}</Text>
          <Text variant="caption1">{namespace}</Text>
          <Text data-testid={generateTestId(name)} variant="subtitle2">
            {name}
          </Text>
          <Text
            data-testid={generateTestId(repository)}
            variant="body2"
            className={classes.passiveText}
          >{`${repository} / v${version}`}</Text>
          <Text variant="body2" component="p" lineClamp={1}>
            {description}
          </Text>
        </div>
      </CardBody>
    </SelectableCard>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  appCard: {
    display: 'grid',
    minHeight: '197px',
    height: '100%',
    '&:hover': {
      backgroundColor: theme.components.card.activeBackground,
    },
  },
  cardHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
  },
  appIcon: {
    alignSelf: 'center',
    maxHeight: '50px',
    maxWidth: '96px',
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 16,
    gap: 16,
  },
  cardInfo: {
    display: 'grid',
    gap: theme.spacing(0.5),
    padding: 8,
  },
  description: {
    marginTop: 8,
  },
  passiveText: {
    color: theme.components.typography.passive,
  },
}))
