import { RootState } from 'app/store'
import { Selector } from '@reduxjs/toolkit'
import { complement, isNil, propSatisfies } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { findClusterName } from 'k8s/util/helpers'
import { emptyArr } from 'utils/fp'
import { durationBetweenDates } from 'utils/misc'
import { IStatefulSetSelector } from './model'
import { podsByClusterIdAndNamespaceSelector } from '../pods/selectors'
import { addContainerType } from '../pods/helpers'
import { getVolumeType, volumeTypeDescriptions } from '../storage/helpers'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'

export const statefulSetSelector: Selector<
  RootState,
  IStatefulSetSelector[]
> = createSharedSelector(
  getDataSelector<DataKeys.StatefulSets>(
    DataKeys.StatefulSets,
    ['clusterId', 'namespace'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  podsByClusterIdAndNamespaceSelector,
  (rawStatefulSets, allClusters, podsByClusterIdAndNamespace) => {
    return rawStatefulSets
      .map((statefulSet) => {
        const clusterId = statefulSet?.clusterId
        const clusterName = findClusterName(allClusters, clusterId)
        const selector = statefulSet?.spec?.selector?.matchLabels
        const creationTimestamp = statefulSet?.metadata?.creationTimestamp
        const associatedPods =
          podsByClusterIdAndNamespace?.[clusterId]?.[statefulSet?.namespace] || emptyArr
        const pods = associatedPods.filter((pod) =>
          pod?.metadata?.ownerReferences?.some((owner) => owner.uid === statefulSet?.id),
        )
        const initContainers = addContainerType(
          statefulSet?.spec?.template?.spec?.initContainers,
          'Init Container',
        )
        const containers = addContainerType(statefulSet?.spec?.template?.spec?.containers)
        const volumes = (statefulSet?.spec?.template?.spec?.volumes || []).map((volume) => {
          const type = getVolumeType(volume)
          return {
            ...volume,
            type,
            description: volumeTypeDescriptions[type],
          }
        })

        return {
          ...statefulSet,
          clusterName,
          age: durationBetweenDates({ labels: ['d'] })(creationTimestamp),
          creationTimestamp,
          labels: statefulSet?.metadata?.labels,
          annotations: statefulSet?.metadata?.annotations,
          selector,
          replicas: {
            desired: statefulSet?.spec?.replicas,
            total: statefulSet?.status?.replicas || 0,
            current: statefulSet?.status?.currentReplicas || 0,
            ready: statefulSet?.status?.readyReplicas || 0,
          },
          updateStrategy: statefulSet?.spec?.updateStrategy?.type,
          podTemplate: statefulSet?.spec?.template,
          volumes,
          pods,
          initContainers,
          containers,
        }
      })
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)
