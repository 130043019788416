import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    background: theme.components.card.background,
    padding: theme.spacing(3),
    marginBottom: 16,
  },
  overviewHeader: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  headerText: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(0.5),
  },
  overviewRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
  },
  headerOverviewCounter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 266,
  },
  overviewCounter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 258,
    margin: theme.spacing(0, 1),
  },
  verticallyCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const DriftResourceSummary = ({ resourceType, resources, isImpactAnalysis }) => {
  const newResources = resources.filter((resource) => resource.op === 'add')
  const replacedResources = resources.filter((resource) => resource.op === 'replace')
  const unchangedResources = resources.filter((resource) => resource.op === 'extra')

  const classes = useStyles({})
  return (
    <div className={classes.overview}>
      <div className={classes.overviewHeader}>
        <div className={classes.headerOverviewCounter}>
          <Text variant="caption1" className={classes.headerText}>
            Total {resourceType} Affected:
          </Text>
          <Text variant="body2">
            <b>{newResources.length + replacedResources.length}</b>/{resources.length}
          </Text>
        </div>
      </div>
      <div className={classes.overviewRow}>
        <div className={classes.overviewCounter}>
          <Tooltip
            title={
              isImpactAnalysis
                ? 'These resources will be added to the cluster when the profile is deployed.'
                : 'These resources are missing on the cluster. Deploy the profile to bring them into compliance.'
            }
          >
            <Text variant="body2" className={classes.verticallyCenter}>
              <img src="/ui/images/icon-plus-square-solid@2x.png" className={classes.icon} />
              {isImpactAnalysis ? 'Will be Added' : 'Missing on Cluster'}
            </Text>
          </Tooltip>
          <Text variant="body2">
            <b>{newResources.length}</b>/{resources.length}
          </Text>
        </div>
        <div className={classes.overviewCounter}>
          <Tooltip
            title={
              isImpactAnalysis
                ? 'These resources exist on the cluster, but not the profile. These resources will remain unchanged.'
                : 'These resources are present on the cluster, but not in the profile.'
            }
          >
            <Text variant="body2" className={classes.verticallyCenter}>
              <img src="/ui/images/icon-square-solid@2x.png" className={classes.icon} />
              {isImpactAnalysis ? 'Unchanged' : 'Present on Cluster'}
            </Text>
          </Tooltip>
          <Text variant="body2">
            <b>{unchangedResources.length}</b>
          </Text>
        </div>
      </div>
      <div className={classes.overviewRow}>
        <div className={classes.overviewCounter}>
          <Tooltip
            title={
              isImpactAnalysis
                ? 'These resources on the cluster are missing or have additional APIs or Verb Access. These resources will be modifed to match the selected profile.'
                : 'These resources on the cluster are missing or have additional APIs or Verb Access.'
            }
          >
            <Text variant="body2" className={classes.verticallyCenter}>
              <img src="/ui/images/icon-exchange-square-solid@2x.png" className={classes.icon} />
              {isImpactAnalysis ? 'Will be Modified' : 'Not Compliant'}
            </Text>
          </Tooltip>
          <Text variant="body2">
            <b>{replacedResources.length}</b>/{resources.length}
          </Text>
        </div>
      </div>
    </div>
  )
}

export default DriftResourceSummary
