import React, { useCallback, useMemo, useState } from 'react'
import FormWrapper from 'core/components/FormWrapper'
import { routes } from 'core/utils/routes'
import useReactRouter from 'use-react-router'
import { propEq } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import Wizard from 'core/components/wizard/Wizard'
import WizardStep from 'core/components/wizard/WizardStep'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import clsx from 'clsx'
import SelectClusterForm from './SelectClusterForm'
import DriftAnalysis from '../drift/DriftAnalysis'
import SubmitButton from 'core/components/buttons/SubmitButton'
import SkipDryRunDialog from './SkipDryRunDialog'
import Button from 'core/elements/button'
import { trackEvent } from 'utils/tracking'
import { memoize } from 'utils/misc'
import { rbacProfilesSelector } from '../selectors'
import { createRbacProfileBinding, listRbacProfiles } from '../new-actions'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import useUpdateAction from 'core/hooks/useUpdateAction'

const useStyles = makeStyles((theme: Theme) => ({
  wizardMeta: {
    gridTemplateColumns: '372px minmax(800px, max-content) minmax(200px, max-content)',
    '& aside': {
      display: 'block',
    },
  },
  wizardMetaContainer: {
    background: theme.palette.grey['000'],
    display: 'grid',
    gridGap: theme.spacing(0.5),
    padding: theme.spacing(3),
  },
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  calloutField: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  largeTopMargin: {
    marginTop: theme.spacing(2),
  },
  roleCounts: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  error: {
    width: 300,
    color: theme.palette.red[500],
  },
}))

const wizardMetaFormattedNames = {
  clusters: 'Clusters',
}

const wizardMetaCalloutFields = Object.keys(wizardMetaFormattedNames)

const renderProfileCalloutFields = memoize((clusters) => (fields) => {
  const classes = useStyles({})
  return (
    <div className={classes.wizardMetaContainer}>
      <Text variant="caption1">PROFILE SUMMARY</Text>
      <div className={clsx(classes.calloutField, classes.largeTopMargin)}>
        <Text variant="body2">Profile Name:</Text>
        <Text variant="caption1">{fields.name}</Text>
      </div>
      {!!clusters[0] && (
        <div className={classes.calloutField}>
          <Text variant="body2">Cluster Name:</Text>
          <Text variant="caption1">{clusters[0].name}</Text>
        </div>
      )}
      <div className={clsx(classes.roleCounts, classes.largeTopMargin)}>
        <div className={classes.calloutField}>
          <Text variant="body2">Total Roles:</Text>
          <Text variant="caption1">{fields.roles.length}</Text>
        </div>
        <div className={classes.calloutField}>
          <Text variant="body2">Total Cluster Roles:</Text>
          <Text variant="caption1">{fields.clusterRoles.length}</Text>
        </div>
        <div className={classes.calloutField}>
          <Text variant="body2">Total Role Bindings:</Text>
          <Text variant="caption1">{fields.roleBindings.length}</Text>
        </div>
        <div className={classes.calloutField}>
          <Text variant="body2">Total Cluster Role Bindings:</Text>
          <Text variant="caption1">{fields.clusterRoleBindings.length}</Text>
        </div>
      </div>
    </div>
  )
})

const DeployNowButton = ({ wizardContext, disabled }) => {
  const classes = useStyles({})
  const [showDialog, setShowDialog] = useState(false)
  const [showError, setShowError] = useState(false)
  const clickedButton = useCallback(() => {
    if (!!wizardContext.clusters[0] && !wizardContext.clusters[0].hasBinding) {
      setShowDialog(true)
      setShowError(false)
    } else {
      setShowError(true)
    }
  }, [wizardContext, setShowDialog, setShowError])
  return (
    <>
      {showDialog && (
        <SkipDryRunDialog wizardContext={wizardContext} handleClose={() => setShowDialog(false)} />
      )}
      <SubmitButton disabled={disabled} onClick={clickedButton}>
        Deploy
      </SubmitButton>
      {(showError || disabled) && (
        <Text className={classes.error} variant="body2">
          Selected cluster may not have an active profile and must have profile agent installed.
        </Text>
      )}
    </>
  )
}

export const CustomNext = ({ handleNext, type = 'Impact' }) => {
  const classes = useStyles({})
  return (
    <Button variant="cta" onClick={handleNext}>
      Show {type} Analysis
    </Button>
  )
}

const renderCustomNext = (handleNext, wizardContext, activeStep) => (
  <CustomNext handleNext={handleNext} />
)

const validateCluster = ({ clusters }) => {
  if (!clusters || !clusters.length) {
    return false
  }
  // Even though only one selection allowed, ListTableField always returns as array
  const cluster = clusters[0]
  return !cluster.hasBinding
}

const renderAdditionalActions = (wizardContext, activeStep) => {
  if (activeStep !== 0) {
    return []
  }
  const valid = validateCluster(wizardContext)
  return [<DeployNowButton key="deployButton" disabled={!valid} wizardContext={wizardContext} />]
}

const DeployRbacProfilePage = () => {
  const classes = useStyles({})
  const { history, match } = useReactRouter()
  const profileId = match?.params?.id

  const { loading: profilesLoading } = useListAction(listRbacProfiles)
  const profiles = useSelector(rbacProfilesSelector)

  const profile = useMemo(() => profiles.find(propEq('id', profileId)), [profiles, profileId])
  const [submittingStep, setSubmittingStep] = useState(false)

  const initialContext = useMemo(
    () => ({
      profileName: profile?.name,
      clusters: [],
      analysis: '',
    }),
    [profile],
  )

  const { update: updateProfileBindingAction, updating: creating, error } = useUpdateAction(
    createRbacProfileBinding,
  )

  const deployProfile = async (params) => {
    const { success } = await updateProfileBindingAction({
      cluster: params.clusters[0],
      profileName: params.profileName,
    })
    if (!success) return
    trackEvent('Deploy RBAC Profile - Finished')
    history.push(routes.rbacProfiles.list.path())
  }

  return (
    <FormWrapper
      title="Deploy a Profile to a Cluster"
      backUrl={routes.rbacProfiles.list.path()}
      loading={profilesLoading || creating || submittingStep}
    >
      <DocumentMeta title="Deploy Profile" bodyClasses={['form-view']} />
      {profile && (
        <Wizard
          error={error}
          onComplete={deployProfile}
          context={initialContext}
          additionalActions={renderAdditionalActions}
          submitLabel="Deploy"
          customNext={renderCustomNext}
          hideBack
        >
          {({ wizardContext, setWizardContext, onNext, handleNext }) => (
            <>
              <WizardStep
                stepId="step1"
                label="Select a Cluster"
                onNext={() => trackEvent('Deploy RBAC Profile - Finished Impact Analysis')}
                summaryFields={profile}
                summaryTitle="Deploy Profile"
                summaryKeyOverrides={wizardMetaFormattedNames}
                summaryCalloutFields={wizardMetaCalloutFields}
              >
                <SelectClusterForm
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                  handleNext={handleNext}
                  setSubmitting={setSubmittingStep}
                />
              </WizardStep>
              <WizardStep stepId="step2" label="Impact Analysis">
                <DriftAnalysis
                  analysisString={wizardContext.analysis}
                  clusterId={wizardContext.clusters?.[0]?.uuid}
                  isImpactAnalysis
                />
              </WizardStep>
            </>
          )}
        </Wizard>
      )}
    </FormWrapper>
  )
}

export default DeployRbacProfilePage
