import { IGenericResource } from 'api-client/qbert.model'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'

export enum IHorizontalPodAutoscalerDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface HorizontalPodAutoscalersResponse {
  kind: string
  apiVersion: string
  metadata: HorizontalPodAutoscalersResponseMetadata
  items: HorizontalPodAutoscalerItem[]
}

export interface HorizontalPodAutoscalerItem {
  metadata: ItemMetadata
  spec: HorizontalPodAutoscalerSpec
  status?: HorizontalPodAutoscalerStatus
}

interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels?: Record<string, string>
  ownerReferences?: OwnerReference[]
  annotations?: Record<string, string>
}

export interface OwnerReference {
  apiVersion: string
  kind: string
  name: string
  uid: string
  controller: boolean
  blockOwnerDeletion: boolean
}

interface HorizontalPodAutoscalersResponseMetadata {
  resourceVersion: string
}

interface HorizontalPodAutoscalerSpec {
  minReplicas: number
  maxReplicas: number
  metrics: Metric[]
  scaleTargetRef: ScaleTargetRef
}

interface Metric {
  type: string
  resource: MetricResource
}

interface MetricResource {
  name: string
  target: Target
}

interface Target {
  type: string
  averageUtilization: number
}

interface ScaleTargetRef {
  apiVersion: string
  kind: string
  name: string
}

interface HorizontalPodAutoscalerStatus {
  lastScaleTime?: Date
  currentReplicas?: number
  desiredReplicas?: number
  currentMetrics?: CurrentMetric[]
  conditions?: Condition[]
}

interface CurrentMetric {
  type: string
  resource: CurrentMetricResource
}

interface CurrentMetricResource {
  name: string
  current: Current
}

interface Current {
  averageUtilization: number
  averageValue: string
}

export interface Condition {
  type: string
  status: string
  lastTransitionTime: Date
  reason?: string
  message?: string
}

export interface IHorizontalPodAutoscalerSelector
  extends IGenericResource<HorizontalPodAutoscalerItem> {
  clusterName: string
  clusterId: string
  clusterType: ClusterTypes
  ownerReferences: HorizontalPodAutoscalerItem['metadata']['ownerReferences']
  age?: string
  labels: HorizontalPodAutoscalerItem['metadata']['labels']
  annotations: HorizontalPodAutoscalerItem['metadata']['annotations']
}
