import React, { useMemo } from 'react'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import ExportDriftAnalysisDialog from './drift/ExportDriftAnalysisDialog'
import Button from 'core/elements/button'
import SimpleLink from 'core/components/SimpleLink'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import { makeRbacProfileBindingsSelector } from './selectors'
import { ArrayElement } from 'core/actions/Action'
import { listRbacProfileBindings, deleteRbacProfileBinding } from './new-actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import ListContainer from 'core/containers/ListContainer'
import InferActionParams from 'core/actions/InferActionParams'

const driftAnalysisSelector = makeRbacProfileBindingsSelector()

type ModelDataKey = DataKeys.RbacProfileBindings
type SelectorModel = ArrayElement<ReturnType<typeof driftAnalysisSelector>>
type ActionParams = InferActionParams<typeof listRbacProfileBindings>

type Params = ActionParams & {
  dryRun?: boolean
  orderBy?: string
}

const defaultParams: Params = {
  dryRun: true,
  orderBy: 'created',
  orderDirection: 'desc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'DriftAnalytics',
  listTablePrefs,
)

const searchTargets = ['metadata.name', 'profileName', 'clusterName']

const ImportDriftAnalysisBtn = () => {
  return (
    <SimpleLink src={routes.drift.import.path()}>
      <Button variant="secondary">Import</Button>
    </SimpleLink>
  )
}

export const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'metadata.name', label: 'Analysis Name' },
  { key: 'profileName', label: 'Profile' },
  { key: 'clusterName', label: 'Cluster' },
  {
    key: 'created',
    label: 'Created Date',
    CellComponent: DateAndTime,
  },
]

export default function DriftAnalysisListPage() {
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { history } = useReactRouter()
  const { message, loading, reload } = useListAction(listRbacProfileBindings)
  const data = useSelectorWithParams(driftAnalysisSelector, params)

  const batchActions = useMemo(
    () => [
      {
        label: 'View',
        icon: 'search-plus',
        handleAction: (selectedItems) => {
          history.push(routes.drift.view.path({ id: selectedItems[0].id }))
        },
      },
      {
        label: 'Export',
        icon: 'file-export',
        BatchActionButton: getGridDialogButton(ExportDriftAnalysisDialog),
      },
    ],
    [history],
  )

  return (
    <>
      <DocumentMeta title="Drift Analytics" />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.RbacProfileBindings}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={data}
        getParamsUpdater={getParamsUpdater}
        addUrl={routes.drift.add.path()}
        addText="New Drift Analysis"
        deleteAction={deleteRbacProfileBinding}
        batchActions={batchActions}
        multiSelection={false}
        extraHeaderContent={<ImportDriftAnalysisBtn />}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
