import React from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import StorageClassKindPicklist from './StorageClassKindPicklist'
import PDTypePicklist from './PDTypePicklist'
import FsTypePicklist from './FsTypePicklist'
import ReplicationTypePicklist from './ReplicationTypePicklist'
import { ClusterCloudPlatforms } from 'app/constants'
import StorageTypePicklist from './StorageTypePicklist'
import AzureDiskTypeListPicklist from './AzureDiskTypeListPicklist'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  warningText: {
    color: theme.palette.red.main,
    marginBottom: theme.spacing(2),
  },
}))

const UseInTreeProviderFields = ({ wizardContext, setWizardContext }) => {
  const classes = useStyles()
  return (
    <>
      <Text className={classes.warningText} variant="body2">
        Warning: As of Kubernetes 1.22 all In-Tree Storage Classes will be deprecated, Platform9
        recommends using CSI Drivers for all Storage Classes.
      </Text>
      <TextField
        id="provisioner"
        label="Provisioner"
        value={wizardContext.provisioner}
        mask={wizardContext.cloudProviderType === 'local' ? 'kubernetes.io/' : ''}
        info=""
        disabled
        required
      />
      {(wizardContext.cloudProviderType === CloudProviders.Aws ||
        wizardContext.cloudProviderType === ClusterCloudPlatforms.EKS) && (
        <>
          <PicklistField
            DropdownComponent={StorageTypePicklist}
            id="storageType"
            label="Storage Type"
            onChange={(storageType) => setWizardContext({ storageType })}
            value={wizardContext.storageType}
            initialValue={'gp2'}
            required
          />
          <TextField
            id="fsType"
            label="FS Type"
            onChange={(fsType) => setWizardContext({ fsType })}
            required
          />
        </>
      )}
      {(wizardContext.cloudProviderType === CloudProviders.Azure ||
        wizardContext.cloudProviderType === ClusterCloudPlatforms.AKS) && (
        <>
          <PicklistField
            DropdownComponent={AzureDiskTypeListPicklist}
            id="azureDiskType"
            label="Azure Disk Type"
            onChange={(azureDiskType) =>
              setWizardContext({
                azureDiskType,
                skuName: '',
                location: '',
                storageAccount: '',
                resourceGroup: '',
              })
            }
            value={wizardContext.azureDiskType}
            required
          />

          {wizardContext.azureDiskType === 'azure-disk managed standard' && (
            <>
              <PicklistField
                DropdownComponent={StorageClassKindPicklist}
                id="kind"
                label="Kind"
                onChange={(kind) => setWizardContext({ kind })}
                value={wizardContext.kind}
                required
              />
              <TextField
                id="resourceGroup"
                label="Resource Group"
                onChange={(resourceGroup) => setWizardContext({ resourceGroup })}
              />
              <TextField
                id="storageAccount"
                label="Storage Account Type"
                onChange={(storageAccount) => setWizardContext({ storageAccount })}
              />
            </>
          )}
          {wizardContext.azureDiskType === 'azure-disk unmanaged standard' && (
            <>
              <TextField
                id="skuName"
                label="SKU Name"
                required
                onChange={(skuName) => setWizardContext({ skuName })}
              />
              <TextField
                id="location"
                label="Location"
                required
                onChange={(location) => setWizardContext({ location })}
              />
              <TextField
                id="storageAccount"
                label="Storage Account Type"
                onChange={(storageAccount) => setWizardContext({ storageAccount })}
              />
            </>
          )}
        </>
      )}
      {wizardContext.cloudProviderType === ClusterCloudPlatforms.GKE && (
        <>
          <PicklistField
            DropdownComponent={PDTypePicklist}
            id="pdType"
            label="PD Type"
            onChange={(pdType) => setWizardContext({ pdType })}
            value={wizardContext.pdType}
            required
          />
          <PicklistField
            DropdownComponent={FsTypePicklist}
            id="fsType"
            label="FS Type"
            onChange={(fsType) => setWizardContext({ fsType })}
            value={wizardContext.fsType}
            initialValue={'ext4'}
            required
          />
          <PicklistField
            DropdownComponent={ReplicationTypePicklist}
            id="replicationType"
            label="Replication Type"
            onChange={(replicationType) => setWizardContext({ replicationType })}
            value={wizardContext.replicationType}
            required
          />
        </>
      )}
    </>
  )
}

export default UseInTreeProviderFields
