import { GetClusterPodsItem, IGenericResource } from 'api-client/qbert.model'

export enum IPodDetailsPageTabs {
  Overview = 'overview',
  Events = 'events',
  Yaml = 'yaml',
  Logs = 'logs',
}

export interface IPodSelector extends IGenericResource<GetClusterPodsItem> {
  dashboardUrl: string
  id: string
  name: string
  namespace: string
  labels: GetClusterPodsItem['metadata']['labels']
  annotations: GetClusterPodsItem['metadata']['annotations']
  clusterName: string
  logs: Log[]
  resourceStats: ResourceStats
  kind: string
  podStatus: PodStatus
  containers: IContainer[]
  metrics: any
  qosClass: GetClusterPodsItem['status']['qosClass']
  creationTimestamp: GetClusterPodsItem['metadata']['creationTimestamp']
  age: string
}

export interface IContainer extends Container, IContainerStatus {
  usage: any
  clusterId: string
  podId: string
}

export interface PodStatus {
  state: string
  reason?: string
  message?: string
  isError: boolean
}

export interface IContainerStatus extends ContainerStatus {
  reason?: string
  message?: string
  isError?: boolean
}

interface Log {
  containerName: string
  url: string
}

export interface Pod {
  kind: string
  apiVersion: string
  metadata: PodMetadata
  spec: Spec
  status: Status
}

interface PodMetadata {
  name: string
  generateName: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels: Labels
  ownerReferences: OwnerReference[]
}

interface Labels {
  'app.kubernetes.io/instance': string
  'app.kubernetes.io/managed-by': string
  'app.kubernetes.io/name': string
  'helm.sh/chart': string
  kim: string
  'pod-template-hash': string
}

interface OwnerReference {
  apiVersion: string
  kind: string
  name: string
  uid: string
  controller: boolean
  blockOwnerDeletion: boolean
}

interface Spec {
  volumes: Volume[]
  containers: Container[]
  restartPolicy: string
  terminationGracePeriodSeconds: number
  dnsPolicy: string
  serviceAccountName: string
  serviceAccount: string
  nodeName: string
  securityContext: SpecSecurityContext
  affinity: Affinity
  schedulerName: string
  tolerations: Toleration[]
  hostAliases: HostAlias[]
  priority: number
  enableServiceLinks: boolean
  preemptionPolicy: string
}

interface Affinity {
  podAntiAffinity: PodAntiAffinity
}

interface PodAntiAffinity {
  preferredDuringSchedulingIgnoredDuringExecution: PreferredDuringSchedulingIgnoredDuringExecution[]
}

interface PreferredDuringSchedulingIgnoredDuringExecution {
  weight: number
  podAffinityTerm: PodAffinityTerm
}

interface PodAffinityTerm {
  labelSelector: LabelSelector
  namespaces: string[]
  topologyKey: string
}

interface LabelSelector {
  matchLabels: MatchLabels
}

interface MatchLabels {
  'app.kubernetes.io/instance': string
  'app.kubernetes.io/name': string
}

interface Container {
  name: string
  image: string
  ports: Port[]
  env: Env[]
  resources: Resources
  volumeMounts: VolumeMount[]
  livenessProbe: LivenessProbeClass
  readinessProbe: LivenessProbeClass
  terminationMessagePath: string
  terminationMessagePolicy: string
  imagePullPolicy: string
  securityContext: ContainerSecurityContext
}

interface Env {
  name: string
  value?: string
  valueFrom?: ValueFrom
}

interface ValueFrom {
  secretKeyRef: SecretKeyRef
}

interface SecretKeyRef {
  name: string
  key: string
}

interface LivenessProbeClass {
  httpGet: HTTPGet
  initialDelaySeconds: number
  timeoutSeconds: number
  periodSeconds: number
  successThreshold: number
  failureThreshold: number
}

interface HTTPGet {
  path: string
  port: string
  scheme: string
}

interface Port {
  name: string
  containerPort: number
  protocol: string
}

interface Resources {
  requests: Requests
}

interface Requests {
  cpu: string
  memory: string
}

interface ContainerSecurityContext {
  runAsUser: number
  runAsNonRoot: boolean
}

interface VolumeMount {
  name: string
  mountPath: string
  subPath?: string
  readOnly?: boolean
}

interface HostAlias {
  ip: string
  hostnames: string[]
}

interface SpecSecurityContext {
  fsGroup: number
}

interface Toleration {
  key: string
  operator: string
  effect: string
  tolerationSeconds: number
}

interface Volume {
  name: string
  persistentVolumeClaim?: PersistentVolumeClaim
  secret?: Secret
}

interface PersistentVolumeClaim {
  claimName: string
}

interface Secret {
  secretName: string
  defaultMode: number
}

interface Status {
  phase: string
  conditions: Condition[]
  hostIP: string
  startTime: string
  containerStatuses: ContainerStatus[]
  qosClass: string
}

interface Condition {
  type: string
  status: string
  lastProbeTime: null
  lastTransitionTime: string
  reason?: string
  message?: string
}

interface ContainerStatus {
  name: string
  state: State | string
  lastState: {}
  ready: boolean
  restartCount: number
  image: string
  imageID: string
  started: boolean
}

interface State {
  waiting?: Waiting
  terminated?: Terminated
}

interface Waiting {
  reason: string
}

interface Terminated {
  reason: string
}

interface ResourceStats {
  usage: Usage
  limits: Limits
  requests: ResourceRequests
}

interface Usage {
  cpu: string
  memory: string
}

interface Limits {
  cpu: string
  memory: string
}

interface ResourceRequests {
  cpu: string
  memory: string
}

export interface IPod {
  clusterId: string
  clusterName: string
  created: string
  dashboardUrl: string
  id: string
  name: string
  namespace: string
}
