import React, { useMemo } from 'react'
import { projectAs } from 'utils/fp'
import useListAction from 'core/hooks/useListAction'
import { listRepositories } from './repositories/new-actions'
import { repositoriesSelector } from './repositories/selectors'
import { useSelector } from 'react-redux'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {}

export default function RepositoryPicklist(props: PropsWithAsyncDropdown<Props>) {
  const { loading } = useListAction(listRepositories)
  const repositories = useSelector(repositoriesSelector)
  const items = useMemo(() => projectAs({ label: 'name', value: 'name' }, repositories), [
    repositories,
  ])

  return (
    <AsyncDropdown
      name="repositoryName"
      label="Repository"
      items={items}
      loading={loading}
      showAll
      compact
      {...props}
    />
  )
}
