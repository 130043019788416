import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useEditPermissionStyles = makeStyles<Theme>((theme) => ({
  root: {
    borderRadius: 4,
    background: theme.palette.grey['000'],
    padding: theme.spacing(2, 3),
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    color: theme.palette.grey[700],
  },
  policyDetails: {
    marginBottom: theme.spacing(2),
    '& > p': {
      margin: theme.spacing(1, 0),
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    '& > p > span': {
      minWidth: '70%',
    },
  },
  apiAccess: {
    maxHeight: 600,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  header: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[900],
  },
  mainSelectors: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  titleLink: {
    textAlign: 'right',
    fontSize: 12,
    minWidth: 100,
  },
  filters: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(-2),
    '& > div': {
      display: 'flex',
      flexFlow: 'row nowrap',
    },
    '& .inputFrame': {
      minWidth: 150,
    },
    '& .MuiSelect-root.MuiSelect-select': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  addNewPermission: {
    color: theme.palette.blue[500],
    border: `solid 0.5px ${theme.palette.blue[500]}`,
    borderRadius: 4,
    padding: theme.spacing(3, 2),
    backgroundColor: '#e5f7fd',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    cursor: 'pointer',
  },
  textbox: {
    width: 250,
    margin: theme.spacing(1, 2, 0, 0),
  },
  yamlBtn: {
    marginTop: 8,
  },
  codeMirror: {
    width: '100% !important',
  },
}))

export default useEditPermissionStyles
