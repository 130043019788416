import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'

import { difference } from 'ramda'

import Theme from 'core/themes/model'
import Button from 'core/elements/button'

import { throttle } from 'app/utils/async'

import StatusCard, { StatusCardPreview } from './StatusCard'
import { allReportCardsDefaultOrder, filterReportsWithUserRole } from './helpers'
import { dashboardCardsByType } from './card-templates'
import { emptyArr } from 'utils/fp'

const useStyles = makeStyles<Theme>((theme) => ({
  dragPreview: {
    border: `2px dashed ${theme.palette.blue[500]}`,
    minHeight: 155,
  },
  dashboardMosaic: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(395px, max-content))',
    gridGap: theme.spacing(2),
    marginTop: theme.spacing(2),
    alignItems: 'start',
    justifyContent: 'center',
  },
  container: {
    display: 'grid',
    gridGap: 32,
    alignItems: 'stretch',
    gridAutoRows: 'max-content',
  },
  submitButton: {
    alignSelf: 'start',
    justifySelf: 'start',
  },
}))

export default function CardMosaic({
  cards = emptyArr,
  role,
  onSave = undefined,
  draggable = false,
}) {
  const classes = useStyles()
  const [reportCards, setReportCards] = useState(cards)
  const remainingCards = useMemo(() => difference(allReportCardsDefaultOrder, reportCards), [
    reportCards,
  ])
  const theme: Theme = useTheme()

  const reports = useMemo(() => {
    if (!draggable) {
      return reportCards.map((cardType) => dashboardCardsByType[cardType])
    }
    return [...reportCards, ...remainingCards].map((cardType) => dashboardCardsByType[cardType])
  }, [reportCards, remainingCards])

  const clonedDraggedCard = useRef(null)
  const currentDragEntity = useRef<string>(null)
  const lastDraggedEntity = useRef<string>(null)

  const lastDragX = useRef<number>(0)
  const lastDragY = useRef<number>(0)

  const dragMouseOffsetX = useRef<number>(0)
  const dragMouseOffsetY = useRef<number>(0)

  useEffect(() => {
    if (cards) {
      setReportCards(cards)
    }
  }, [cards])

  const handleDragOver = throttle((event) => {
    const dragOverNode = event.currentTarget as HTMLElement
    if (currentDragEntity.current === dragOverNode.id) {
      // only set the last dragged entity if the drag is not over the current drag target
      return
    }
    lastDraggedEntity.current = dragOverNode.id
    const currIdx = reportCards.indexOf(currentDragEntity.current)
    const lastIdx = reportCards.indexOf(lastDraggedEntity.current)
    let direction = 'left'
    if (currIdx < lastIdx) {
      direction = 'right'
    }
    const topLeftCorner = {
      x: event.clientX - dragMouseOffsetX.current,
      y: event.clientY - dragMouseOffsetY.current,
    }
    const bottomRightCorner = {
      x: topLeftCorner.x + dragOverNode.offsetWidth,
      y: topLeftCorner.y + dragOverNode.offsetHeight,
    }

    const { x, y, width, height } = dragOverNode.getBoundingClientRect()
    const anchorX = x + width / 2 // half the card width
    const anchorY = y + height // anywhere on the card vertically
    const isOverCardVertically = topLeftCorner.y < anchorY && bottomRightCorner.y > y
    if (
      (direction === 'left' && topLeftCorner.x < anchorX && isOverCardVertically) ||
      (direction === 'right' && bottomRightCorner.x > anchorX && isOverCardVertically)
    ) {
      reorderCards()
    }
  }, 100)
  const handleDragStart = (event) => {
    const node = event.currentTarget
    const { x: nodeX, y: nodeY } = node.getBoundingClientRect()
    clonedDraggedCard.current = cloneDraggedCard(node)
    currentDragEntity.current = event.currentTarget.id

    dragMouseOffsetX.current = event.clientX - nodeX
    dragMouseOffsetY.current = event.clientY - nodeY
    node.style.opacity = 0
  }

  const handleDrag = throttle((event) => {
    let currentX = event.clientX
    let currentY = event.clientY

    if (currentX === 0 && currentY === 0) {
      currentX = lastDragX.current
      currentY = lastDragY.current
    }

    if (currentX === lastDragX.current && currentY === lastDragY.current) {
      return
    }
    translateDraggedCard(
      clonedDraggedCard.current,
      currentX - dragMouseOffsetX.current,
      currentY - dragMouseOffsetY.current,
    )

    lastDragX.current = currentX
    lastDragY.current = currentY
  }, 10)

  const handleDragEnd = (event) => {
    const node = event.currentTarget
    node.style.opacity = 1

    clonedDraggedCard.current.parentNode.removeChild(clonedDraggedCard.current)
    clonedDraggedCard.current = null
  }

  const reorderCards = () => {
    const reorderedCards = [...reportCards]
    const currIdx = reportCards.indexOf(currentDragEntity.current)
    const lastIdx = reportCards.indexOf(lastDraggedEntity.current)
    // remove the item that was moved
    reorderedCards.splice(currIdx, 1)

    // insert the item that was moved
    reorderedCards.splice(lastIdx, 0, currentDragEntity.current)

    setReportCards(reorderedCards)
  }

  const handleSave = useCallback(() => {
    onSave(reportCards)
  }, [reportCards, onSave])

  const handleSelectCard = useCallback(
    (cardId) => {
      if (reportCards.includes(cardId)) {
        setReportCards(reportCards.filter((card) => card !== cardId))
      } else {
        setReportCards([...reportCards, cardId])
      }
    },
    [reportCards],
  )
  const getCardStyle = (cardId) => {
    if (!draggable) return {}

    if (reportCards.includes(cardId)) {
      return {
        opacity: 1,
        border: `1px solid ${theme.components.graph.fadedPrimary}`,
      }
    } else {
      return {
        opacity: 0.5,
      }
    }
  }

  const StatusCardComponent = !draggable ? StatusCard : StatusCardPreview

  // TODO if a user doesn't have access to all reports is the grid going to have holes?
  const dashboardCards = useMemo(
    () =>
      filterReportsWithUserRole(reports, role).map((report) => {
        const componentStyle = {
          // ...(reportGrid?.[report.entity] || {}),
          ...getCardStyle(report.entity),
        }
        const isDraggable = reportCards.includes(report.entity)
        return (
          <StatusCardComponent
            key={report.route}
            {...report}
            style={componentStyle}
            onClick={draggable ? () => handleSelectCard(report.entity) : null}
            draggable={draggable}
            onDragStart={isDraggable ? handleDragStart : null}
            onDragEnd={isDraggable ? handleDragEnd : null}
            onDragOver={isDraggable ? handleDragOver : null}
            onDrag={isDraggable ? handleDrag : null}
          />
        )
      }),
    [reports, role],
  )

  return (
    <article className={classes.container}>
      <div className={classes.dashboardMosaic}>{dashboardCards}</div>
      {draggable && (
        <Button className={classes.submitButton} onClick={handleSave}>
          Save
        </Button>
      )}
    </article>
  )
}

// TODO convert this to a package / HOC
const translateDraggedCard = (node, x, y) => {
  node.style.left = `${x}px`
  node.style.top = `${y}px`
}

const styleClonedNode = (node, width, height) => {
  node.id = `${node.id}-clone`
  node.style.gridArea = ''
  node.style.position = 'fixed'
  node.style.zIndex = 9999
  node.style.width = `${width}px`
  node.style.height = `${height}px`
  node.style.left = '-9999px'
  node.style.opacity = 1
  node.style.userSelect = 'none'
  node.style.pointerEvents = 'none'

  node.style.margin = 0
}

const cloneDraggedCard = (node) => {
  const clone = node.cloneNode(true)

  styleClonedNode(clone, node.offsetWidth, node.offsetHeight)
  node.parentNode.insertBefore(clone, node)
  return clone
}
