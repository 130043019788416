export enum ICronjobDetailsPageTabs {
  Overview = 'overview',
  ActiveJobs = 'jobs',
  Pods = 'pods',
  Events = 'events',
  Yaml = 'yaml',
  Logs = 'logs',
}

export enum IJobDetailsPageTabs {
  Overview = 'overview',
  Pods = 'pods',
  Events = 'events',
  Yaml = 'yaml',
  Logs = 'logs',
}
