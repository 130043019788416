import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import ApiClient from 'api-client/ApiClient'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import { routes } from 'core/utils/routes'
import { isEmpty } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listConfigMaps, updateConfigMap } from 'k8s/components/config-maps/new-actions'
import {
  configMapsSelector,
  IConfigMapSelector,
  IConfigMapsTabs,
} from 'k8s/components/config-maps/selectors'
import ConfigMapsOverview from './ConfigMapsOverview'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import OverviewActions from 'core/elements/overview/OverviewActions'
import DeleteConfigMapDialog from '../DeleteConfigMapDialog'
import usePluginRouter from 'core/hooks/usePluginRouter'

const { qbert } = ApiClient.getInstance()

const actions = {
  rightActions: [
    {
      label: 'Delete',
      icon: 'trash-alt',
      DialogComponent: DeleteConfigMapDialog,
    },
  ],
}

const ConfigMapsDetailsPage = () => {
  const dispatch = useDispatch()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtConfiguration' : 'configuration'
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { loading, reload } = useListAction(listConfigMaps, { params: { clusterId } })
  const configMaps: IConfigMapSelector[] = useSelector(configMapsSelector)
  const configMap = useMemo(() => configMaps.find((configMap) => configMap.id === id), [
    id,
    configMaps,
  ])
  const { update, updating } = useUpdateAction(updateConfigMap)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: configMap?.clusterName || clusterId,
        id: configMap?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [configMap?.clusterName, configMap?.name, id, clusterId])

  const getConfigMapYaml = useCallback(async () => {
    if (isEmpty(configMap)) return undefined
    return qbert.getConfigMap(configMap.clusterId, configMap.namespace, configMap.name)
  }, [configMap])

  const handleConfigMapTemplateUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Config Maps Overview" breadcrumbs />
      <div>
        <OverviewActions actions={actions} entity={configMap} />
      </div>
      <Tabs route={routes[routePath].configMaps.details}>
        <Tab label="Overview" value={IConfigMapsTabs.Overview}>
          <ConfigMapsOverview configMap={configMap} loading={loading} reload={reload} />
        </Tab>
        <Tab label="YAML" value={IConfigMapsTabs.Yaml}>
          <EntityYamlPage
            entityType="Config Map"
            entityName={configMap?.name}
            getYamlFn={getConfigMapYaml}
            handleUpdate={handleConfigMapTemplateUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default ConfigMapsDetailsPage
