import { isNilOrEmpty } from 'utils/fp'

const volumeFormattedNames = {
  awsElasticBlockStore: 'AwsElasticBlockStore',
  cephfs: 'Cephfs',
  cinder: 'Cinder',
  configMap: 'ConfigMap',
  downwardAPI: 'DownwardAPI',
  emptyDir: 'EmptyDir',
  fc: 'FC',
  gcePersistentDisk: 'GcePersistentDisk',
  glusterfs: 'Glusterfs',
  hostPath: 'HostPath',
  iscsi: 'Iscsi',
  local: 'Local',
  nfs: 'Nfs',
  persistentVolumeClaim: 'PersistentVolumeClaim',
  portworxVolume: 'PortworxVolume',
  projected: 'Projected',
  secret: 'Secret',
  vsphereVolume: 'VsphereVolume',
}

export const getVolumeType = (volume) => {
  if (isNilOrEmpty(volume)) return null
  const lookupKey = Object.keys(volumeFormattedNames).find((key) => volume.hasOwnProperty(key))
  return lookupKey === undefined ? null : volumeFormattedNames[lookupKey] || null
}

export const volumeTypeDescriptions = {
  EmptyDir: "a temporary directory that shares a pod's lifetime",
  HostPath: 'bare host directory volume',
  Secret: 'a volume populated by a Secret',
}

export const getVolumeTypeAndDescription = (_, volume) => {
  if (isNilOrEmpty(volume)) return null
  const { type = '', description = '' } = volume
  return description ? `${type} (${description})` : type
}
