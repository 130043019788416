import React, { useCallback, useState } from 'react'
import { Dialog, DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Button from 'core/elements/button'
import Text from 'core/elements/Text'
import {
  getProfileBinding,
  getProfileBindingDetails,
  listRbacProfileBindings,
} from '../new-actions'
import useInterval from 'core/hooks/useInterval'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { useDispatch } from 'react-redux'
import { notificationActions, NotificationType } from 'core/notifications/notificationReducers'
import useListAction from 'core/hooks/useListAction'

const useStyles = makeStyles<Theme>((theme) => ({
  dialogContainer: {
    padding: theme.spacing(1, 3),
  },
  dialogHeader: {
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  dialogContent: {
    margin: theme.spacing(3, 2),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
}))

interface Props {
  handleClose: any
  onFinish: any
  bindingName: string
  setWizardContext: any
  type?: string
}

const DryRunDialog = ({
  bindingName,
  handleClose,
  onFinish,
  setWizardContext,
  type = 'Impact',
}: Props) => {
  const classes = useStyles({})
  const dispatch = useDispatch()
  const { reload: reloadProfileBindings } = useListAction(listRbacProfileBindings)

  // @ts-ignore not using lastIntervalTs but this is what triggers the poll
  const [lastIntervalTs, setLastIntervalTs] = useState(new Date().valueOf())
  const [lastFetchTs, setLastFetchTs] = useState(new Date().valueOf())

  const getUpdate = useCallback(async () => {
    const ts = new Date().valueOf()
    setLastFetchTs(ts)
    setLastIntervalTs(ts)
    try {
      const profile = await getProfileBinding(bindingName)
      if (['success', 'deleting'].includes(profile.status.phase)) {
        const details = await getProfileBindingDetails(bindingName)
        const analysis = details.analysis
        setWizardContext({ analysis })
        onFinish()
      }
    } catch (err) {
      dispatch(
        notificationActions.registerNotification({
          title: 'Drift Analysis Error',
          message: `Error encountered when calculating drift analysis for binding ${bindingName}`,
          data: err,
          type: NotificationType.error,
        }),
      )
      handleClose()
    } finally {
      // Dry run true is not linked to profile binding actions,
      // reload it here
      reloadProfileBindings(true)
    }
  }, [setLastFetchTs, setLastIntervalTs, onFinish, setWizardContext])

  useInterval(() => {
    setLastIntervalTs(new Date().valueOf())
  }, 5000)
  const currentTs = new Date().valueOf()
  if (currentTs - lastFetchTs > 30000) {
    getUpdate()
  }

  return (
    <>
      <Dialog open fullWidth maxWidth="sm">
        <div className={classes.dialogContainer}>
          <Text variant="body1" className={classes.dialogHeader}>
            {type} Analysis
          </Text>
          <div className={classes.dialogContent}>
            <Text variant="body2">
              <FontAwesomeIcon className={classes.marginRight} spin>
                sync
              </FontAwesomeIcon>
              Running {type} Analysis for cluster and profile... Do not close this dialog until the
              analysis is finished. This may take a few minutes to finish.
            </Text>
          </div>
          <DialogActions>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  )
}

export default DryRunDialog
