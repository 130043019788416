import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { IResourceQuotaSelector, StatusObj } from '../model'
import Progress from 'core/components/progress/Progress'
import SummaryCard from 'k8s/components/common/entity/summary-card'
import InfoCard from 'k8s/components/common/entity/info-card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import { renderAge } from 'k8s/components/common/entity/helpers'
import Card from 'core/elements/card'
import { OwnerReferences } from '../../config-maps/details/ConfigMapsOverview'
import ResourceInfo from 'app/plugins/kubevirt/components/virtual-machines/vm-details/ResourceInfo'
import Grid, { GridViewColumn } from 'core/elements/grid/Grid'
import { isNilOrEmpty } from 'utils/fp'

const summaryRows: { key: keyof IResourceQuotaSelector; label: string }[] = [
  { key: 'clusterName', label: 'Cluster' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'age', label: 'Age' },
]

const resourceDetailsColumns: GridViewColumn<StatusObj>[] = [
  { key: 'resource', label: 'Type', width: 'medium' },
  { key: 'used', label: 'Used' },
  { key: 'max', label: 'Max' },
  { key: 'usage', label: 'Usage', formatFn: (usage: number) => `${usage.toFixed(0)}%` },
]

const resourceNameMap = {
  'limits.cpu': 'Limits CPU',
  'limits.memory': 'Limits Memory',
  'requests.cpu': 'Requests CPU',
  'requests.memory': 'Requests Memory',
  cpu: 'CPU',
  memory: 'Memory',
}

const getResourceInfoFields = (resourceQuota: IResourceQuotaSelector, resourceName: string) => {
  const resourceObj = resourceQuota?.statusObj.find((obj) => obj.resource === resourceName)
  return {
    Used: resourceObj.used,
    Max: resourceObj.max,
  }
}

const getUsagePercentage = (resourceQuota: IResourceQuotaSelector, resourceName: string) => {
  const resourceObj = resourceQuota?.statusObj.find((obj) => obj.resource === resourceName)
  return resourceObj.usage
}

const resources = [
  'limits.cpu',
  'limits.memory',
  'requests.cpu',
  'requests.memory',
  'cpu',
  'memory',
]

const ResourceQuotasOverview = ({
  resourceQuota,
  loading,
  reload,
}: {
  resourceQuota: IResourceQuotaSelector
  loading: boolean
  reload: (refetch: boolean) => void
}) => {
  const classes = useStyles()

  const metadataFields = [
    {
      id: 'age',
      title: 'Created',
      required: true,
      render: renderAge,
    },
    {
      id: 'ownerReferences',
      title: 'Owner References',
      render: (value) => <OwnerReferences refs={value} />,
      condition: ({ ownerReferences }) => ownerReferences?.length > 0,
    },
  ]

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, resourceQuota)
  }, [resourceQuota])

  const resourceDetailsData = useMemo(() => {
    return resourceQuota?.statusObj.filter((obj) => !resources.includes(obj.resource))
  }, [resourceQuota])

  return (
    <Progress loading={loading}>
      <div className={classes.container}>
        <div className={classes.column}>
          <SummaryCard<IResourceQuotaSelector>
            title={resourceQuota?.name}
            subtitle="Resource Quota"
            rows={summaryRows}
            data={resourceQuota}
          />
          <InfoCard
            items={metadata}
            title="Properties"
            footer={
              <div className={classes.footer}>
                <LabelsAndAnnotationsSection entity={resourceQuota} resourceType="resourceQuota" />
              </div>
            }
          />
        </div>
        <div className={classes.column}>
          <Card title="Resources" withCustomBody>
            {!isNilOrEmpty(resourceQuota.status) &&
              resources
                .filter((resource) => resource in resourceQuota.status.hard ?? {})
                .map((resource) => (
                  <ResourceInfo
                    key={resource}
                    type={resourceNameMap[resource] || resource}
                    fields={getResourceInfoFields(resourceQuota, resource)}
                    percent={getUsagePercentage(resourceQuota, resource)}
                    progressBar
                  />
                ))}
          </Card>
          <Card title="Resource Details" withCustomBody>
            <Grid
              uniqueIdentifier="resource"
              data={resourceDetailsData}
              columns={resourceDetailsColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutosummaryRows: 'max-content',
  },
  footer: {
    margin: theme.spacing(1),
  },
  copyClipboard: {
    '& .copy': {
      color: theme.components.typography.passive,
    },
  },
}))

export default ResourceQuotasOverview
