import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react'
import UserPasswordField from 'account/components/userManagement/users/UserPasswordField'
import TextField from 'core/components/validatedForm/TextField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { emailValidator } from 'core/utils/fieldValidators'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { useSelector } from 'react-redux'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { prop } from 'ramda'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import { onboardClusterTracking } from './tracking'
import RadioFields from 'core/components/validatedForm/radio-fields'
import { accountActivationRadioOptions } from 'app/plugins/account/components/userManagement/users/AddUserPage'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createUser } from 'account/components/userManagement/users/new-actions'
import { managementRolesSelector } from 'account/components/userManagement/roles/selectors'
import useListAction from 'core/hooks/useListAction'
import { listMngmRoles } from 'account/components/userManagement/roles/new-actions'

const initialValues = {
  username: '',
  displayname: '',
  password: '',
}

const defaultRoleName = 'admin'

const AddCoworkerStep = ({ wizardContext, setWizardContext, onNext, setSubmitting }) => {
  const { getUserPrefs } = useScopedPreferences()
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { username } = session
  const { currentTenant } = getUserPrefs(username) as { currentTenant: string }
  const { loading: loadingRoles } = useListAction(listMngmRoles)
  const roles = useSelector(managementRolesSelector)
  const defaultRoleId = useMemo(() => roles.find((role) => role.name === defaultRoleName)?.id, [
    roles,
  ])
  const [activationType, setActivationType] = useState('createPassword')
  const { update: addUser } = useUpdateAction(createUser)
  const validatorRef = useRef(null)

  const setupValidator = (validate) => {
    validatorRef.current = { validate }
  }

  const handleSubmit = useCallback(async () => {
    const isValid = await validatorRef.current.validate()
    if (!isValid) {
      return false
    }
    onboardClusterTracking.wzAddCoworker()
    setSubmitting(true)

    const data = {
      ...wizardContext,
      roleAssignments: { [currentTenant]: defaultRoleId },
    }
    await addUser(data)

    setSubmitting(false)
    return true
  }, [wizardContext])

  useEffect(() => {
    onNext(handleSubmit)
  }, [handleSubmit])

  return (
    <ValidatedForm initialValues={initialValues} elevated={false} triggerSubmit={setupValidator}>
      {({ values }) => (
        <FormFieldCard title="Add a User">
          <TextField
            id="username"
            label="Email"
            value={wizardContext.username}
            onChange={(value) => setWizardContext({ username: value })}
            validations={[emailValidator]}
            required
          />
          <TextField
            id="displayname"
            label="Display Name"
            value={wizardContext.displayname}
            onChange={(value) => setWizardContext({ displayname: value })}
          />
          <RadioFields
            id="activateAcountOptions"
            title="Activate User Account"
            options={accountActivationRadioOptions}
            value={activationType}
            onChange={(type) => setActivationType(type as string)}
          />
          {activationType === 'createPassword' && (
            <UserPasswordField
              value={values.password}
              onChange={(value) => setWizardContext({ password: value })}
            />
          )}
        </FormFieldCard>
      )}
    </ValidatedForm>
  )
}

export default AddCoworkerStep
