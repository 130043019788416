import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useParams from 'core/hooks/useParams'
import ModalForm from 'core/elements/modal/ModalForm'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import Text from 'core/elements/Text'
import { createPersistentVolumeClaim } from './new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import jsYaml from 'js-yaml'
import TextFileDrop from 'core/elements/grid/TextFileDrop'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import { codeMirrorOptions } from 'app/constants'
import { customValidator, requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import moize from 'moize'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import RadioSelectableCard from 'k8s/components/common/RadioSelectableCard'
import PvcParamFields from './PvcParamFields'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '16px 40px 40px 32px',
    display: 'grid',
    gap: 24,
  },
  radioText: {
    textTransform: 'uppercase',
    color: theme.components.typography.passive,
  },
  radioCards: {
    display: 'grid',
    gap: 16,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
  fields: {
    display: 'grid',
    gap: 24,
  },
  limitWidth: {
    // Max width possible for this w/o causing scrollbar
    // Assumes dialog with max width of 528px
    maxWidth: 407,
  },
}))

const moizedYamlLoad = moize(jsYaml.load, {
  maxSize: 10,
})

const codeMirrorValidations = [
  requiredValidator,
  yamlValidator,
  customValidator((yaml, formFields) => {
    try {
      const body = moizedYamlLoad(yaml)
      return body?.kind === 'PersistentVolumeClaim'
    } catch (err) {
      return true
    }
  }, 'YAML kind must be PersistentVolumeClaim'),
]

export default function AddPersistentVolumeClaimModal({ addRoute }) {
  const { history } = useReactRouter()
  const classes = useStyles()

  const defaultParams = {
    clusterId: '',
    namespace: '',
    method: 'form',
    name: '',
    storageClass: null,
    accessMode: 'ReadWriteOnce',
    volumeMode: 'Filesystem',
    size: null,
    sizeUnit: 'Gi',
    labels: {},
    yaml: '',
  }

  const { params, updateParams, setParams } = useParams<{
    clusterId: string
    namespace: string
    method: string
    name: string
    storageClass?: any
    accessMode: string
    volumeMode: string
    size: number
    sizeUnit: string
    labels: any
    yaml: string
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(createPersistentVolumeClaim)

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.kubevirtStorage.persistentVolumeClaims.list.path())
  }

  const submitForm = useCallback(async () => {
    if (params?.method === 'yaml') {
      const body = jsYaml.load(params?.yaml)
      const { success } = await update({
        clusterId: params?.clusterId,
        namespace: params?.namespace,
        body,
      })
      if (success) handleClose()
      return
    }

    const body = {
      apiVersion: 'v1',
      kind: 'PersistentVolumeClaim',
      metadata: {
        name: params?.name,
      },
      spec: {
        storageClassName: params?.storageClass,
        accessModes: [params?.accessMode],
        volumeMode: params?.volumeMode,
        resources: {
          requests: {
            storage: `${params?.size}${params?.sizeUnit}`,
          },
        },
        selector: {
          matchLabels: params?.labels,
        },
      },
    }
    const { success } = await update({
      clusterId: params?.clusterId,
      namespace: params?.namespace,
      body,
    })
    if (success) handleClose()
  }, [params, handleClose])

  return (
    <ModalForm
      route={addRoute}
      title={`Add Persistent Volume Claim`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Add Persistent Volume Claim`}
      maxWidth={528}
    >
      <div className={classes.container}>
        <PicklistField
          DropdownComponent={ClusterPicklist}
          id="clusterId"
          label="Cluster"
          onChange={(value) => updateParams({ clusterId: value })}
          value={params.clusterId}
          showAll={false}
          setInitialCluster
          required
        />
        <PicklistField
          DropdownComponent={NamespacePicklist}
          disabled={!params.clusterId}
          id="namespace"
          label="Namespace"
          onChange={(value) => updateParams({ namespace: value })}
          clusterId={params.clusterId}
          value={params.namespace}
          showAll={false}
          setInitialNamespace
          required
        />
        <hr className={classes.divider} />
        <div className={classes.radioCards}>
          <Text variant="caption2" className={classes.radioText}>
            Select one of the below methods
          </Text>
          <RadioSelectableCard
            onClick={() => updateParams({ method: 'form' })}
            label="Fill in the form"
            active={params?.method === 'form'}
            subtitle=""
          />
          <RadioSelectableCard
            onClick={() => updateParams({ method: 'yaml' })}
            label="Upload or paste YAML"
            active={params?.method === 'yaml'}
            subtitle=""
          />
        </div>
        <hr className={classes.divider} />
        {params?.method === 'yaml' && (
          <div className={classes.fields}>
            <Text variant="caption1">Upload Persistent Volume Claim YAML</Text>
            <TextFileDrop
              onChange={(value) => updateParams({ yaml: value })}
              fileTypes={['.yaml', '.yml']}
            />
            <CodeMirror
              className={classes.limitWidth}
              label="YAML"
              id="yaml"
              validations={codeMirrorValidations}
              onChange={(value) => updateParams({ yaml: value })}
              value={params?.yaml}
              options={codeMirrorOptions}
            />
          </div>
        )}
        {params?.method === 'form' && (
          <PvcParamFields params={params} updateParams={updateParams} />
        )}
      </div>
    </ModalForm>
  )
}
