import React, { useCallback } from 'react'
import { INetworkPluginSelector, NetworkPluginArrayItem } from '../model'
import Text from 'core/elements/Text'
import ModalForm from 'core/elements/modal/ModalForm'
import { updateNetworkPlugin } from '../actions'
import useUpdateAction from 'core/hooks/useUpdateAction'

interface Props {
  networkPlugin: INetworkPluginSelector
  selectedPlugin: NetworkPluginArrayItem
  onClose: (success?: boolean) => void
}

const pluginProperties = {
  'Host Plumber': { hostPlumber: {} },
  'Multus CNI': { multus: {} },
  'Node Feature Discovery': { nodeFeatureDiscovery: {} },
  'Platform9 DHCP': { dhcpController: {} },
  Whereabouts: { whereabouts: {} },
  // OVS & SRIOV have their own modal
}

export default function EnablePluginDialog({ networkPlugin, selectedPlugin, onClose }: Props) {
  const { update: updateFn, updating, error, reset } = useUpdateAction(updateNetworkPlugin)

  const handleClose = () => {
    reset()
    onClose()
  }

  const submitForm = useCallback(async () => {
    const pluginProperty = pluginProperties[selectedPlugin?.name]
    const body = {
      ...networkPlugin?.spec?.plugins,
      ...pluginProperty,
    }

    const ops = []
    ops.push({
      op: 'replace',
      path: '/spec/plugins',
      value: body,
    })

    const { success } = await updateFn({
      ...networkPlugin,
      body: ops,
      requestType: 'patch',
      contentType: 'application/json-patch+json',
    })
    if (success) {
      handleClose()
    }
  }, [networkPlugin, handleClose])

  return (
    <ModalForm
      open
      onClose={onClose}
      panel="dialog"
      title={`Enable ${selectedPlugin?.name}`}
      submitTitle="Enable"
      onSubmit={submitForm}
      submitting={updating}
      error={error}
    >
      <Text variant="body2">This plugin will be enabled.</Text>
    </ModalForm>
  )
}
