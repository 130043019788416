import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import NumberedStepLabel from 'core/components/validatedForm/NumberedStepLabel'
import RepeatedFieldAccordion from 'core/components/validatedForm/RepeatedFieldAccordion'
import InterfaceConfigurationFields from './InterfaceConfigurationFields'
import OvsConfigurationFields from './OvsConfigurationFields'
import SriovConfigurationFields from './SriovConfigurationFields'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    display: 'grid',
    gap: 24,
  },
  configurations: {
    display: 'grid',
    gap: 16,
  },
}))

export const defaultInterfaceConfig = {
  name: '',
  mtu: '',
  vlanIds: [],
  ipv4Addresses: [],
  ipv6Addresses: [],
}

export const defaultOvsConfig = {
  bridgeName: '',
  nodeInterface: '',
  enableDpdk: false,
  enableParams: false,
  mtuRequest: '',
  bondMode: '',
  lacp: '',
}

export const defaultSriovConfig = {
  deviceSelector: 'pfName',
  pfName: '',
  vendorId: '',
  deviceId: '',
  pciAddress: '',
  numVfs: 0,
  vfDriver: '',
}

export default function HostConfigurationsParamFields({ params, updateParams, method = 'add' }) {
  const classes = useStyles()
  return (
    <div className={classes.fields}>
      <NumberedStepLabel
        step={method === 'edit' ? 2 : 3}
        title={method === 'add' ? 'Host Configurations' : 'Add Host Configurations'}
      />
      <div className={classes.configurations}>
        <RepeatedFieldAccordion
          title="Interface Configurations"
          id="interface"
          valueArray={params?.interfaceConfigs}
          FieldsComponent={InterfaceConfigurationFields}
          defaultValues={defaultInterfaceConfig}
          onChange={(value) => updateParams({ interfaceConfigs: value })}
          addText="Add Interface Config"
          removeText="Delete Interface Config"
        />
        <RepeatedFieldAccordion
          title="OVS Configurations"
          id="ovs"
          valueArray={params?.ovsConfigs}
          FieldsComponent={OvsConfigurationFields}
          defaultValues={defaultOvsConfig}
          onChange={(value) => updateParams({ ovsConfigs: value })}
          addText="Add OVS Config"
          removeText="Delete Interface Config"
        />
        <RepeatedFieldAccordion
          title="SR-IOV Configurations"
          id="sriov"
          valueArray={params?.sriovConfigs}
          FieldsComponent={SriovConfigurationFields}
          defaultValues={defaultSriovConfig}
          onChange={(value) => updateParams({ sriovConfigs: value })}
          addText="Add SR-IOV Config"
          removeText="Delete SR-IOV Config"
        />
      </div>
    </div>
  )
}
