import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { routes } from 'core/utils/routes'
import { deleteK8sEndpoint } from './actions'
import usePluginRouter from 'core/hooks/usePluginRouter'

export default function DeleteEndpointDialog({ rows: [endpoint], onClose }) {
  const { history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const { update: deleteFn, updating: deletingEndpoint } = useUpdateAction(deleteK8sEndpoint)

  const handleDelete = useCallback(async () => {
    await deleteFn(endpoint)
    onClose(true)
    history.push(routes[routePath].endpoints.list.path())
  }, [endpoint, routePath])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={`Remove Endpoint "${endpoint?.name}" ?`}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingEndpoint}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingEndpoint}>
            Delete
          </Button>
        </>
      }
    >
      <Text variant="body2">Your data will be lost since this action cannot be undone.</Text>
    </Modal>
  )
}
