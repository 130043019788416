import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import TextFileDrop from 'core/elements/grid/TextFileDrop'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import { codeMirrorOptions } from 'app/constants'
import { customValidator, requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import moize from 'moize'
import jsYaml from 'js-yaml'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    display: 'grid',
    gap: 24,
  },
  limitWidth: {
    // Max width possible for this w/o causing scrollbar
    // Assumes dialog with max width of 528px
    maxWidth: 407,
  },
}))

const moizedYamlLoad = moize(jsYaml.load, {
  maxSize: 10,
})

const codeMirrorValidations = [
  requiredValidator,
  yamlValidator,
  customValidator((yaml, formFields) => {
    try {
      const body = moizedYamlLoad(yaml)
      return body?.kind === 'NetworkAttachmentDefinition'
    } catch (err) {
      return true
    }
  }, 'YAML kind must be NetworkAttachmentDefinition'),
]

export default function YamlParamFields({ params, updateParams }) {
  const classes = useStyles()
  return (
    <div className={classes.fields}>
      <FormFieldSection title="Upload a YAML file" step={2}>
        <TextFileDrop
          onChange={(value) => updateParams({ yaml: value })}
          fileTypes={['.yaml', '.yml']}
        />
        <CodeMirror
          className={classes.limitWidth}
          label="YAML"
          id="yaml"
          validations={codeMirrorValidations}
          onChange={(value) => updateParams({ yaml: value })}
          value={params?.yaml}
          options={codeMirrorOptions}
        />
      </FormFieldSection>
    </div>
  )
}
