import { trackEvent } from 'utils/tracking'

export const rbacProfileCreationTracking = {
  started: (context = {}) => {
    trackEvent('WZ Create RBAC Profile Started 0', {
      wizard_name: 'Create RBAC Profile',
      wizard_progress: '0 of 6',
      wizard_state: 'Started',
      wizard_step: 'Start',
    })
  },
  step1: (context = {}) => {
    trackEvent('WZ Create RBAC Profile Base Cluster 1', {
      wizard_name: 'Create RBAC Profile',
      wizard_progress: '1 of 6',
      wizard_state: 'In-Progress',
      wizard_step: 'Base Cluster',
    })
  },
  step2: (context = {}) => {
    trackEvent('WZ Create RBAC Profile Roles 2', {
      wizard_name: 'Create RBAC Profile',
      wizard_progress: '2 of 6',
      wizard_state: 'In-Progress',
      wizard_step: 'Roles',
    })
  },
  step3: (context = {}) => {
    trackEvent('WZ Create RBAC Profile Cluster Roles 3', {
      wizard_name: 'Create RBAC Profile',
      wizard_progress: '3 of 6',
      wizard_state: 'In-Progress',
      wizard_step: 'Cluster Roles',
    })
  },
  step4: (context = {}) => {
    trackEvent('WZ Create RBAC Profile Role Bindings 4', {
      wizard_name: 'Create RBAC Profile',
      wizard_progress: '4 of 6',
      wizard_state: 'In-Progress',
      wizard_step: 'Role Bindings',
    })
  },
  step5: (context = {}) => {
    trackEvent('WZ Create RBAC Profile Cluster Role Bindings 5', {
      wizard_name: 'Create RBAC Profile',
      wizard_progress: '5 of 6',
      wizard_state: 'In-Progress',
      wizard_step: 'Role Bindings',
    })
  },
  step6: (context = {}) => {
    trackEvent('WZ Create RBAC Profile Review 6', {
      wizard_name: 'Create RBAC Profile',
      wizard_progress: '6 of 6',
      wizard_state: 'Finished',
      wizard_step: 'Review',
    })
  },
  finished: (context = {}) => {
    trackEvent('WZ Create RBAC Profile Finished 6', {
      wizard_name: 'Create RBAC Profile',
      wizard_progress: '6 of 6',
    })
  },
}
