import React, { useState } from 'react'
import Modal from 'core/elements/modal'
import Button from 'core/elements/button'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import { NameCell, TimeCell } from 'k8s/components/alarms/AlarmsListPage'
import Grid from 'core/elements/grid'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { IAlertSelector } from 'k8s/components/alarms/model'

export const columns: GridViewColumn<IAlertSelector>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: NameCell,
  },
  {
    key: 'updatedAt',
    label: 'Time',
    CellComponent: TimeCell,
  },
  {
    key: 'summary',
    label: 'Rule Summary',
    formatFn: (val: string) => (val ? val : 'N/A'),
  },
  { key: 'status', label: 'Status', formatFn: (val: string) => (val ? val : 'N/A') },
]

export default function OverviewAlarmsModal({ cluster, open, onClose }) {
  const [activeTab, setActiveTab] = useState('all')

  return (
    <Modal
      open={open}
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </>
      }
      onClose={onClose}
      title={cluster.name}
    >
      <div>
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
          <Tab value="all" label="All">
            <Grid
              uniqueIdentifier="id"
              columns={columns}
              data={cluster.allAlarms}
              compact
              disableToolbar
            />
          </Tab>
          <Tab value="fatal" label="Fatal">
            <Grid
              uniqueIdentifier="id"
              columns={columns}
              data={cluster.fatalAlarms}
              compact
              disableToolbar
            />
          </Tab>
          <Tab value="critical" label="Critical">
            <Grid
              uniqueIdentifier="id"
              columns={columns}
              data={cluster.criticalAlarms}
              compact
              disableToolbar
            />
          </Tab>
          <Tab value="warning" label="Warning">
            <Grid
              uniqueIdentifier="id"
              columns={columns}
              data={cluster.warningAlarms}
              compact
              disableToolbar
            />
          </Tab>
        </Tabs>
      </div>
    </Modal>
  )
}
