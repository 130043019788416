import { flatten, head, isEmpty } from 'ramda'
import { ensureArray } from 'utils/fp'
import { memoize } from 'utils/misc'

export const getResourceIngresses = memoize((services = [], ingresses = []) => {
  if (isEmpty(services) || isEmpty(ingresses)) return []
  const servicesList = ensureArray(services)
  const serviceNames = new Set()
  servicesList.forEach((s) => {
    serviceNames.add(s?.metadata?.name)
  })

  return ingresses.filter((ingress) => {
    // Check the default backend
    if (serviceNames.has(ingress?.spec?.defaultBackend?.service?.name)) {
      return true
    }
    // Check the rules backend
    const rules = ingress?.spec?.rules || []
    return !!rules.find((rule) => {
      const httpPaths = rule?.http?.paths || []
      return !!httpPaths.find((path) => serviceNames.has(path?.backend?.service?.name))
    })
  })
})

export const getRules = memoize((ingress) => {
  if (!ingress) return []
  const loadBalancerIngressPoint: any = head(ingress.status?.loadBalancer?.ingress || [])
  const defaultHost = loadBalancerIngressPoint?.hostname || loadBalancerIngressPoint?.ip

  return flatten(
    (ingress.spec?.rules || []).map((rule) => {
      const paths = rule.http?.paths || []
      return paths.map((path) => ({
        path: path.path,
        host: rule.host || defaultHost,
        pathType: path.pathType,
        serviceName: path?.backend ? path?.backend?.service?.name : null,
        servicePort: path?.backend ? path?.backend?.service?.port?.number : null,
        backend: path?.backend
          ? `${path?.backend?.service?.name}: ${path?.backend?.service?.port?.number}`
          : null,
      }))
    }),
  )
})
