import React, { useMemo, useEffect } from 'react'
import { emptyObj, projectAs } from 'utils/fp'
import { isEmpty, uniqBy, prop, pathOr } from 'ramda'
import { UserPreferences, allKey as defaultAllKey } from 'app/constants'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { sessionStoreKey } from 'core/session/sessionReducers'
import { isDecco } from 'core/utils/helpers'
import { useAppSelector } from 'app/store'
import useListAction from 'core/hooks/useListAction'
import { listNamespaces } from 'k8s/components/namespaces/new-actions'
import { namespacesSelector } from 'k8s/components/namespaces/selectors'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'

interface NamespacePicklistProps {
  clusterId: string
  setInitialNamespace?: boolean
  useGlobalParams?: boolean
  initialNamespace?: string
}

export default function NamespacePicklist({
  name = 'namespaceId',
  label = 'Namespace:',
  selectFirst = true,
  allKey = defaultAllKey,
  setInitialNamespace = false,
  compact = true,
  showAll = true,
  useGlobalParams = false,
  clusterId,
  loading,
  onChange,
  value,
  initialNamespace, // Changed from initialValue bc of conflict with ValidatedFormInput
  ...rest
}: PropsWithAsyncDropdown<NamespacePicklistProps>) {
  const { loading: namespacesLoading } = useListAction(listNamespaces, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const namespaces = useSelectorWithParams(namespacesSelector, {
    clusterId,
    useGlobalParams,
  })

  const { prefs, fetchUserDefaults } = useScopedPreferences('defaults')

  const namespaceDefaults = prefs[UserPreferences.Namespace] || emptyObj
  const defaultNamespaceName = useMemo(
    () => pathOr(null, [clusterId, 'namespace'], namespaceDefaults),
    [clusterId, namespaceDefaults],
  )
  const options = useMemo(
    () => projectAs({ label: 'name', value: 'name' }, uniqBy(prop('name'), namespaces)),
    [namespaces],
  )

  const session = useAppSelector(prop(sessionStoreKey))
  const { features } = session
  const isDeccoEnv = isDecco(features)

  useEffect(() => {
    if (setInitialNamespace && isDeccoEnv) {
      fetchUserDefaults(UserPreferences.Namespace)
    }
  }, [setInitialNamespace, isDeccoEnv])

  useEffect(() => {
    if (!clusterId) return
    // If initialNamespace was being set, it would just be overridden here
    // without this return statement
    if (initialNamespace) {
      const option = options.find((o) => o.value === initialNamespace)
      option ? onChange(option.value) : onChange('default')
      return
    }
    // Reset the namespace when the clusterId changes
    onChange('')
  }, [clusterId, initialNamespace])

  useEffect(() => {
    if (!clusterId || value) return
    if (isEmpty(options) || !setInitialNamespace) return
    // Select the users' default cluster
    if (isDeccoEnv && defaultNamespaceName) {
      const option = options.find((o) => o.value === defaultNamespaceName)
      option ? onChange(option.value) : onChange('default')
    }
  }, [options, defaultNamespaceName, isDeccoEnv, clusterId, value])

  return (
    <AsyncDropdown
      name={name}
      compact={compact}
      showAll={showAll}
      allKey={allKey}
      onChange={onChange}
      label={label}
      loading={loading || namespacesLoading}
      items={options}
      value={value}
      selectFirst={selectFirst}
      initialValue={initialNamespace}
      {...rest}
    />
  )
}
