import React, { useState, useMemo, useEffect, useCallback } from 'react'
import jsYaml from 'js-yaml'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core'
import Button from 'core/elements/button'
import { allKey } from 'app/constants'
import Text from 'core/elements/Text'
import { emptyArr, isNilOrEmpty, noop } from 'utils/fp'
import { uniq, complement, sortBy, prop } from 'ramda'
import useToggler from 'core/hooks/useToggler'
import { generateObjMemoizer } from 'utils/misc'
import { IRbacRoleRule, IRbacAPIGroup, IRbacClusterRole } from 'k8s/components/rbac/model'
import Progress from 'core/components/progress/Progress'
import Picklist from 'core/elements/dropdown/AsyncDropdown'
import {
  updateApiGroupsWithCore,
  EditApiRuleVerbs,
} from 'k8s/components/rbac/profiles/edit/EditApiRuleVerbs'
import useEditPermissionStyles from 'k8s/components/rbac/profiles/edit/useEditPermissionsStyles'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { listApiGroups } from 'k8s/components/rbac/new-actions'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { apiGroupsSelector } from 'k8s/components/rbac/selectors'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'

const stopPropagation = (e) => e.stopPropagation()

interface Props {
  onClose: () => void
  open: boolean
  clusterId: string
  clusterRole?: IRbacClusterRole
}

const getRuleUniqueKey = (rule: IRbacRoleRule) => {
  return rule.apiGroups.join('-') + '_' + rule.resources.join('-') + '_' + rule.verbs.join('-')
}

const defaultClusterRole: IRbacClusterRole = {
  metadata: {
    name: '',
  },
  rules: [],
}

const getMemoizedClusterRole = generateObjMemoizer<IRbacClusterRole>()
const ViewClusterRoleDialog = ({ open, onClose, clusterId, clusterRole }: Props) => {
  const classes = useEditPermissionStyles()

  const originalClusterRole = clusterRole ? getMemoizedClusterRole(clusterRole) : null
  const [newRole, updateRole] = useState<IRbacClusterRole>(
    originalClusterRole || defaultClusterRole,
  )
  const [rawYaml, setRawYaml] = useState()
  const [showingYaml, , setShowingYaml] = useToggler()
  const showYaml = useCallback(() => {
    // Get document, or throw exception on error
    try {
      const rawYaml = jsYaml.dump(newRole)
      setRawYaml(rawYaml)
      setShowingYaml(true)
    } catch (e) {
      console.error(e)
    }
  }, [newRole])

  const exitYaml = useCallback(() => {
    setShowingYaml(false)
  }, [rawYaml])

  const {
    metadata: { name },
    rules,
  } = newRole

  const { loading: loadingApiGroups } = useListAction(listApiGroups, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const apiGroups = useSelectorWithParams(apiGroupsSelector, { clusterId })
  const sortedApiGroups = useMemo(() => {
    const sortByName = sortBy(prop('name'))
    return sortByName(apiGroups) as IRbacAPIGroup[]
  }, [apiGroups])

  useEffect(() => {
    if (originalClusterRole) {
      updateRole(originalClusterRole)
    }
  }, [originalClusterRole])

  const [currentApi, changeCurrentApi] = useState(allKey)
  const [currentVerb, changeCurrentVerb] = useState(allKey)
  const apis = useMemo(
    () =>
      rules
        .reduce(
          (acc, apiRuleGroup) => {
            return uniq([...acc, ...(apiRuleGroup.apiGroups || emptyArr)])
          },
          ['core'],
        )
        .filter(complement(isNilOrEmpty)),
    [rules],
  )
  const verbs = useMemo(
    () =>
      rules
        .reduce((acc, apiRuleGroup) => {
          return uniq([...acc, ...(apiRuleGroup.verbs || emptyArr)])
        }, emptyArr)
        .filter(complement(isNilOrEmpty)),
    [rules],
  )
  const filteredRules = useMemo(() => {
    return rules.filter(
      (rule) =>
        (currentApi === allKey ||
          (rule.apiGroups && updateApiGroupsWithCore(rule.apiGroups).includes(currentApi))) &&
        (currentVerb === allKey || (rule.verbs && rule.verbs.includes(currentVerb))),
    )
  }, [rules, currentApi, currentVerb])

  useEffect(() => {
    if (!open) {
      // Reset filters state when closing the dialog
      changeCurrentApi(allKey)
      changeCurrentVerb(allKey)
    }
  }, [open])

  const defaultView = (
    <div className={classes.apiAccess}>
      <header className={classes.header}>
        <Text variant="body1">API Access/Permissions</Text>
        <Button onClick={showYaml} className={classes.yamlBtn} variant="secondary">
          YAML View
        </Button>
      </header>
      <div className={classes.filters}>
        <div>
          <Picklist
            name={'apis'}
            allLabel={'All APIs'}
            value={currentApi}
            onChange={changeCurrentApi}
            items={apis}
          />
          &nbsp;
          <Picklist
            name={'verbs'}
            allLabel={'All verbs'}
            value={currentVerb}
            onChange={changeCurrentVerb}
            items={verbs}
          />
        </div>
      </div>
      {filteredRules.map((rule, idx) => (
        <EditApiRuleVerbs
          key={getRuleUniqueKey(rule)}
          idx={idx}
          apiGroups={sortedApiGroups}
          onSave={noop}
          onDelete={noop}
          rule={rule}
          readOnly
        />
      ))}
    </div>
  )

  const yamlView = (
    <ValidatedForm onSubmit={exitYaml}>
      <div className={classes.filters}>
        <span>Cluster Role YAML</span>
        <Button className={classes.yamlBtn} variant="secondary">
          Exit YAML View
        </Button>
      </div>
      <CodeMirror
        id="yamlView"
        label="YAML RESOURCE"
        value={rawYaml}
        className={classes.codeMirror}
      />
    </ValidatedForm>
  )

  return (
    <Dialog
      onClick={stopPropagation}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Progress loading={loadingApiGroups} renderContentOnMount>
          {showingYaml ? yamlView : defaultView}
        </Progress>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewClusterRoleDialog
