import { getFieldsForCard } from 'core/components/InfoPanel'
import React from 'react'
import { renderDetailRow } from './entity-info-card'
import Text from 'core/elements/Text'
import { isNilOrEmpty } from 'utils/fp'
import { formatDate, durationBetweenDates } from 'utils/misc'

export const getRow = (fields, item, classNames = {}, wrap = false) => {
  const selectedFields = getFieldsForCard(fields, item)
  return renderDetailRow(selectedFields, classNames, wrap)
}

export const getRows = (fields, item, classNames = {}) => ({
  left: getRow(fields.left, item, classNames, fields.wrap),
  right: getRow(fields.right, item, classNames, fields.wrap),
})

export const renderAge = (age, entity) => {
  if (!age || isNilOrEmpty(entity)) return ''
  return (
    <>
      <Text variant="caption1">{age}</Text>
      <Text variant="body2">{`(${formatDate(entity?.creationTimestamp)})`}</Text>
    </>
  )
}

export const renderAgeFromTimestamp = (timestamp, entity) => {
  if (!timestamp || isNilOrEmpty(entity)) return ''
  const age = durationBetweenDates({ labels: ['d'] })(timestamp)
  return (
    <>
      <Text variant="caption1">{age}</Text>
      <Text variant="body2">{`(${formatDate(timestamp)})`}</Text>
    </>
  )
}
