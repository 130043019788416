import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ListAction from 'core/actions/ListAction'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import Bugsnag from 'utils/bugsnag'
import { ensureArray, pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'

const { qbert } = ApiClient.getInstance()

const horizontalPodAutoscalerActions = ActionsSet.make<DataKeys.HorizontalPodAutoscalers>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.HorizontalPodAutoscalers],
  cacheKey: DataKeys.HorizontalPodAutoscalers,
})

export const listHorizontalPodAutoscalers = horizontalPodAutoscalerActions.add(
  new ListAction<DataKeys.HorizontalPodAutoscalers, { clusterId: string; namespace?: string }>(
    async (params) => {
      Bugsnag.leaveBreadcrumb('Attempting to get Horizontal Pod Autoscalers', params)
      const { namespace, clusterId } = params
      return someAsync(
        ensureArray(namespace).map((name) => qbert.getHorizontalPodAutoscalers(clusterId, name)),
      ).then(flatten)
    },
  ),
)

export const createHorizontalPodAutoscaler = horizontalPodAutoscalerActions.add(
  new CreateAction<
    DataKeys.HorizontalPodAutoscalers,
    { clusterId: string; namespace: string; body: any }
  >(async ({ clusterId, namespace, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create new Horizontal Pod Autoscaler', {
      clusterId,
      namespace,
      body,
    })
    const createdHorizontalPodAutoscaler = await qbert.createHorizontalPodAutoscaler(
      clusterId,
      namespace,
      body,
    )
    trackEvent('Create New Horizontal Pod Autoscaler', {
      clusterId,
      namespace,
      name: pathStr('metadata.name', createdHorizontalPodAutoscaler),
    })
    return createdHorizontalPodAutoscaler
  }),
)

export const updateHorizontalPodAutoscaler = horizontalPodAutoscalerActions.add(
  new UpdateAction<
    DataKeys.HorizontalPodAutoscalers,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Horizontal Pod Autoscaler', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn =
      requestType === 'patch'
        ? qbert.patchHorizontalPodAutoscaler
        : qbert.updateHorizontalPodAutoscaler
    const updatedHorizontalPodAutoscaler = await updateFn({
      clusterId,
      namespace,
      name,
      body,
      contentType,
    })
    trackEvent('Update Horizontal Pod Autoscaler', { clusterId, namespace, name })
    return updatedHorizontalPodAutoscaler
  }),
)

export const deleteHorizontalPodAutoscaler = horizontalPodAutoscalerActions.add(
  new DeleteAction<
    DataKeys.HorizontalPodAutoscalers,
    { clusterId: string; namespace: string; name: string }
  >(async ({ clusterId, namespace, name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete Horizontal Pod Autoscaler', {
      clusterId,
      namespace,
      name,
    })
    await qbert.deleteHorizontalPodAutoscaler(clusterId, namespace, name)
  }),
)
