import { GetClusterKubeServicesItem, IGenericResource } from 'api-client/qbert.model'
import { IPodSelector } from '../pods/model'

export enum IServiceDetailsPageTabs {
  Overview = 'overview',
  Pods = 'pods',
  Events = 'events',
  Yaml = 'yaml',
  Logs = 'logs',
}

export interface InternalEndpoint {
  name: string
  endpoints: string[]
}

export interface ExternalEndpoint {
  hostname: string
  ip: string
  port: Port
}

interface Port {
  error: string
  port: number
  protocol: string
}

export interface IServicesSelector
  extends Omit<IGenericResource<GetClusterKubeServicesItem>, 'status'> {
  dashboardUrl: string
  labels: GetClusterKubeServicesItem['metadata']['labels']
  annotations: GetClusterKubeServicesItem['metadata']['annotations']
  selectors: GetClusterKubeServicesItem['spec']['selector']
  type: string
  status: string
  clusterIp: any
  internalEndpoints: InternalEndpoint[]
  externalEndpoints: ExternalEndpoint[]
  namespace: string
  clusterName: string
  servicePods: IPodSelector[]
  ports: GetClusterKubeServicesItem['spec']['ports']
  externalName: string
  kind: string
  creationTimestamp: string
  age: string
}
