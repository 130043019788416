import { IGenericResource } from 'api-client/qbert.model'
import { Pod } from '../pods/model'

export enum IStatefulSetDetailsPageTabs {
  Overview = 'overview',
  Pods = 'pods',
  Events = 'events',
  Yaml = 'yaml',
  Logs = 'logs',
}
export interface IStatefulSetSelector extends IGenericResource<StatefulSetItem> {
  clusterName: string
  age: string
  creationTimestamp: string
  labels: Record<string, string>
  annotations: Record<string, string>
  selector: Record<string, string>
  replicas: Replicas
  updateStrategy: string
  podTemplate: Template
  volumes: Volume[]
  pods: Pod[]
}

export interface Replicas {
  available?: number
  current?: number
  desired: number
  total: number
  ready: number
}

export interface StatefulSet {
  apiVersion: string
  kind: string
  metadata: ItemMetadata
  spec: ItemSpec
  status: ItemStatus
}

export interface StatefulSetResponse {
  kind: string
  apiVersion: string
  metadata: StatefulSetsResponseMetadata
  items: StatefulSetItem[]
}

export interface StatefulSetItem {
  metadata: ItemMetadata
  spec: ItemSpec
  status: ItemStatus
}

interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  generation: number
  creationTimestamp: string
  labels?: Record<string, string>
  annotations?: Record<string, string>
  ownerReferences?: OwnerReference[]
}

interface OwnerReference {
  apiVersion: string
  kind: string
  name: string
  uid: string
  controller: boolean
  blockOwnerDeletion: boolean
}

interface ItemSpec {
  replicas: number
  selector: Selector
  template: Template
  volumeClaimTemplates?: VolumeClaimTemplate[]
  serviceName: string
  podManagementPolicy: string
  updateStrategy: UpdateStrategy
  revisionHistoryLimit: number
}

interface Selector {
  matchLabels: Record<string, string>
}

interface Template {
  metadata: TemplateMetadata
  spec: TemplateSpec
}

interface TemplateMetadata {
  name?: string
  creationTimestamp: null
  labels: Record<string, string>
  annotations?: Record<string, string>
}

interface TemplateSpec {
  volumes?: Volume[]
  containers: Container[]
  initContainers: Container[]
  restartPolicy: string
  terminationGracePeriodSeconds: number
  dnsPolicy: string
  automountServiceAccountToken?: boolean
  securityContext: SpecSecurityContext
  affinity?: any
  schedulerName: string
  serviceAccountName?: string
  serviceAccount?: string
}

interface Container {
  name: string
  image: string
  ports: Port[]
  env?: Env[]
  resources: ContainerResources
  volumeMounts?: VolumeMount[]
  livenessProbe?: LivenessProbeClass
  readinessProbe?: LivenessProbeClass
  terminationMessagePath: string
  terminationMessagePolicy: string
  imagePullPolicy: string
  securityContext?: ContainerSecurityContext
  command?: string[]
  args?: string[]
}

interface Env {
  name: string
  value?: string
  valueFrom?: ValueFrom
}

interface ValueFrom {
  secretKeyRef?: SecretKeyRef
  fieldRef?: FieldRef
}

interface FieldRef {
  apiVersion: string
  fieldPath: string
}

interface SecretKeyRef {
  name: string
  key: string
}

interface LivenessProbeClass {
  exec?: Exec
  initialDelaySeconds?: number
  timeoutSeconds: number
  periodSeconds: number
  successThreshold: number
  failureThreshold: number
  tcpSocket?: TCPSocket
  httpGet?: HTTPGet
}

interface Exec {
  command: string[]
}

interface HTTPGet {
  path: string
  port: string
  scheme: string
}

interface TCPSocket {
  port: string
}

interface Port {
  name: string
  containerPort: number
  protocol: string
}

interface ContainerResources {
  requests?: Limits
  limits?: Limits
}

interface Limits {
  cpu: string
  memory: string
}

interface ContainerSecurityContext {
  runAsUser: number
}

interface VolumeMount {
  name: string
  mountPath: string
  readOnly?: boolean
}

interface SpecSecurityContext {
  fsGroup?: number
}

interface Volume {
  name: string
  emptyDir?: EmptyDir
  configMap?: ConfigMap
  secret?: Secret
}

interface ConfigMap {
  name: string
  defaultMode: number
}

interface EmptyDir {
  medium?: string
}

interface Secret {
  secretName: string
  defaultMode: number
}

interface UpdateStrategy {
  type: string
  rollingUpdate?: RollingUpdate
}

interface RollingUpdate {
  partition: number
}

interface VolumeClaimTemplate {
  kind: string
  apiVersion: string
  metadata: VolumeClaimTemplateMetadata
  spec: VolumeClaimTemplateSpec
  status: VolumeClaimTemplateStatus
}

interface VolumeClaimTemplateMetadata {
  name: string
  creationTimestamp: null
  labels?: Record<string, string>
}

interface VolumeClaimTemplateSpec {
  accessModes: string[]
  resources: SpecResources
  volumeMode: string
}

interface SpecResources {
  requests: Requests
}

interface Requests {
  storage: string
}

interface VolumeClaimTemplateStatus {
  phase: string
}

interface ItemStatus {
  observedGeneration: number
  replicas: number
  currentReplicas?: number
  updatedReplicas?: number
  currentRevision: string
  updateRevision: string
  collisionCount: number
  readyReplicas?: number
}

interface StatefulSetsResponseMetadata {
  resourceVersion: string
}
