import ApiClient from 'api-client/ApiClient'
import { allKey } from 'app/constants'
import DataKeys from 'k8s/DataKeys'

import { flatten, pluck } from 'ramda'
import { someAsync } from 'utils/async'
import namespaceActions from 'k8s/components/namespaces/actions'
import createContextLoader from 'core/helpers/createContextLoader'
import { INamespaceSelector } from 'k8s/components/namespaces/model'
import { podMetricsByClusterIdAndNamespaceSelector } from './selectors'

const { qbert } = ApiClient.getInstance()

export const loadPodMetricsForCluster = createContextLoader(
  DataKeys.PodMetrics,
  async ({ clusterId }) => {
    if (clusterId === allKey) {
      // We shouldn't load metrics for all clusters. This is excessively heavy and our
      // current use cases for loading pods for all clusters is for counts of pods.
      // Metrics aren't needed on these. If a future use case comes up we can readdress
      return Promise.resolve([])
    }
    const namespaces: INamespaceSelector[] = await namespaceActions.list({ clusterId })
    return someAsync(
      pluck('name', namespaces).map((name) => qbert.getPodsMetrics(clusterId, name)),
    ).then(flatten)
  },
  {
    uniqueIdentifier: ['name'],
    indexBy: 'clusterId',
    selector: podMetricsByClusterIdAndNamespaceSelector,
  },
)
