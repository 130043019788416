import React from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import RbacChecklist from './RbacChecklist'
import TextField from 'core/components/validatedForm/TextField'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import NoContentMessage from 'core/components/NoContentMessage'
import { makeStyles } from '@material-ui/styles'
import DocumentMeta from 'core/components/DocumentMeta'
import { routes } from 'core/utils/routes'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createRole } from './new-actions'
import Theme from 'core/themes/model'
import useReactRouter from 'use-react-router'
import ModalForm from 'core/elements/modal/ModalForm'
import usePluginRouter from 'core/hooks/usePluginRouter'

const useStyles = makeStyles<Theme>((theme) => ({}))

const defaultParams = {
  clusterId: '', // Initialize here to prevent desync with formContext
  namespace: '',
  rbac: {},
}

export default function AddRolePage() {
  const classes = useStyles({})
  const { history } = useReactRouter()
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)
  const { currentPluginId } = usePluginRouter()

  const {
    update: create,
    updating: creatingRole,
    reset,
    error: errorCreatingRole,
  } = useUpdateAction(createRole)

  const submitForm = async (params) => {
    const { success } = await create(params)
    if (success) handleClose()
  }

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.rbac.roles.path({ plugin: currentPluginId }))
  }

  return (
    <>
      <DocumentMeta title="Add Role" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.rbac.addRoles}
        title="Add Role"
        onSubmit={submitForm}
        onClose={handleClose}
        submitting={creatingRole}
        error={errorCreatingRole}
        submitTitle="Add Role"
      >
        <>
          <FormFieldSection title="Basic Details" step={1}>
            <TextField id="name" label="Name" required />
            <PicklistField
              DropdownComponent={ClusterPicklist}
              id="clusterId"
              label="Cluster"
              onChange={getParamsUpdater('clusterId')}
              value={params.clusterId}
              setInitialCluster
              compact={false}
              showAll={false}
              required
            />
            <PicklistField
              DropdownComponent={NamespacePicklist}
              disabled={!params.clusterId}
              id="namespace"
              label="Namespace"
              clusterId={params.clusterId}
              onChange={getParamsUpdater('namespace')}
              value={params.namespace}
              setInitialNamespace
              compact={false}
              showAll={false}
              required
            />
          </FormFieldSection>
          <FormFieldSection title="API Access / Permissions" step={2}>
            {params.clusterId && (
              <RbacChecklist
                id="rbac"
                clusterId={params.clusterId}
                onChange={getParamsUpdater('rbac')}
                value={params.rbac}
              />
            )}
            {!params.clusterId && (
              <NoContentMessage message="Please choose a cluster to define it's API access permissions." />
            )}
          </FormFieldSection>
        </>
      </ModalForm>
    </>
  )
}
