import { makeStyles } from '@material-ui/styles'
import { ClusterCloudPlatforms } from 'app/constants'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import Theme from 'core/themes/model'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { compareVersions } from 'k8s/util/helpers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { switchCase } from 'utils/fp'
import generateTestId from 'utils/test-helpers'
import CsiDriverFields from './CsiDriverFields'
import UseInTreeProviderFields from './UseInTreeProviderFields'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  fields: {
    margin: theme.spacing(2, 0, 1, 3),
  },
  toggler: {
    display: 'flex',
    flexDirection: 'row',
  },
  spaceRight: {
    marginRight: theme.spacing(2),
  },
}))

const cloudStorageProvisionerValues = switchCase(
  {
    [CloudProviders.Aws]: 'aws-ebs',
    [ClusterCloudPlatforms.EKS]: 'aws-ebs',
    [CloudProviders.Azure]: 'azure-disk',
    [ClusterCloudPlatforms.AKS]: 'azure-disk',
    [CloudProviders.Gcp]: 'gce-pd',
    [ClusterCloudPlatforms.GKE]: 'gce-pd',
  },
  '',
)

const StorageClassConfigFields = ({
  clusterVersion,
  defaultExists,
  loading,
  wizardContext,
  setWizardContext,
}) => {
  const classes = useStyles()
  const { cloudProviderType } = wizardContext
  const [useInTreeProvider, setUseInTreeProvider] = useState(false)
  const [detectProvisionersAutomatically, setDetectProvisionersAutomatically] = useState(true)
  const showInTreeProviderToggle = useMemo(
    () =>
      cloudProviderType !== CloudProviders.BareOS && compareVersions(clusterVersion, '1.22') < 0,
    [clusterVersion, cloudProviderType],
  )

  const handleDetectProvisionersToggleState = useCallback(() => {
    setWizardContext({ provisioner: '', csiDriver: '' })
    setDetectProvisionersAutomatically(!detectProvisionersAutomatically)
  }, [detectProvisionersAutomatically])

  useEffect(() => {
    if (useInTreeProvider) {
      const cloudProvisioner = cloudStorageProvisionerValues(cloudProviderType)
      setWizardContext({ provisioner: cloudProvisioner, csiDriver: '' })
    } else {
      setWizardContext({ provisioner: '', csiDriver: '' })
    }
    if (cloudProviderType === CloudProviders.BareOS) {
      setUseInTreeProvider(false)
    }
  }, [cloudProviderType])

  useEffect(() => {
    if (useInTreeProvider) {
      const cloudProvisioner = cloudStorageProvisionerValues(cloudProviderType)
      setWizardContext({ provisioner: cloudProvisioner, csiDriver: '' })
    } else {
      setWizardContext({ provisioner: '', csiDriver: '' })
    }
  }, [useInTreeProvider])

  return (
    <>
      <div className={classes.toggler}>
        {showInTreeProviderToggle && (
          <div className={classes.spaceRight}>
            <ToggleSwitch
              active={useInTreeProvider}
              onClick={() => setUseInTreeProvider(!useInTreeProvider)}
              label="Use In-Tree Provider"
            />
          </div>
        )}
        {!useInTreeProvider && (
          <ToggleSwitch
            data-testid={generateTestId('storage-provisioner', 'detector')}
            active={detectProvisionersAutomatically}
            onClick={handleDetectProvisionersToggleState}
            label="Detect Storage Provisioners"
          />
        )}
      </div>
      <div className={classes.fields}>
        {useInTreeProvider ? (
          <UseInTreeProviderFields
            wizardContext={wizardContext}
            setWizardContext={setWizardContext}
          />
        ) : (
          <CsiDriverFields
            detectAutomatically={detectProvisionersAutomatically}
            values={wizardContext}
            setValues={setWizardContext}
          />
        )}
        <CheckboxField
          id="isDefault"
          label="Use as Default Storage Class"
          info={defaultExists && 'A default storage class already exists on this cluster.'}
          disabled={defaultExists || loading}
        />
      </div>
    </>
  )
}

export default StorageClassConfigFields
