import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import {
  configMapColumns,
  ingressTableColumns,
  getContainerColumns,
  getServiceTableColumns,
  getPersistentVolumeClaimsListTableColumns,
} from '../common/entity/constants'
import { getConfigMapsForResource } from '../config-maps/helpers'
import { getResourceIngresses } from '../ingresses/helpers'
import { getServicesForResource } from '../services/helpers'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from '../common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import { renderAge } from '../common/entity/helpers'
import Grid from 'core/elements/grid'
import { listPersistentVolumeClaims } from '../storage/persistent-volume-claims/new-actions'
import { makePersistentVolumeClaimSelector } from '../storage/persistent-volume-claims/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listIngresses } from '../ingresses/new-actions'
import { ingressesSelector } from '../ingresses/selectors'
import { listConfigMaps } from '../config-maps/new-actions'
import { configMapsSelector } from '../config-maps/selectors'
import { listServices } from '../services/new-actions'
import { serviceSelectors } from '../services/selectors'
import usePluginRouter from 'core/hooks/usePluginRouter'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { listCapiClusters } from 'app/plugins/infrastructure/components/clusters/capi/actions'
import { useSelector } from 'react-redux'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { getClusterDetailsPath } from 'app/plugins/infrastructure/components/clusters/helpers'
import SimpleLink from 'core/components/SimpleLink'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  metadataTable: {
    borderSpacing: '20px',
    'last-child': {
      width: '100%',
    },
  },
}))

const ClusterName = ({ clusterId }) => {
  const { currentPluginId } = usePluginRouter()

  // To get clusterType, we need to get the cluster
  const { loading: loadingClusters } = useListAction(listClusters)
  const { loading: loadingImportedClusters } = useListAction(listImportedClusters)
  const { loading: loadingCapiClusters } = useListAction(listCapiClusters)
  const allClusters: CombinedClusterSelector[] = useSelector(allClustersSelector)

  const cluster = useMemo(() => allClusters.find((x) => x.uuid === clusterId), [
    allClusters,
    clusterId,
  ])

  const clusterDetailsUrl = useMemo(() => {
    return getClusterDetailsPath({
      clusterId: cluster?.uuid,
      clusterType: cluster?.clusterType,
      currentPluginId,
    })
  }, [cluster?.clusterType, cluster?.uuid])

  const value = cluster?.name
  return <SimpleLink src={clusterDetailsUrl}>{value}</SimpleLink>
}

export const renderClusterName = (clusterId) => {
  return <ClusterName clusterId={clusterId} />
}

const metadataFields = [
  { id: 'name', title: 'Name', required: true },
  { id: 'namespace', title: 'Namespace', required: true },
  {
    id: 'clusterId',
    title: 'Cluster',
    render: renderClusterName,
    required: true,
  },
  { id: 'qosClass', title: 'QoS', required: true },
  { id: 'status.hostIP', title: 'Node IP', required: true },
  {
    id: 'age',
    title: 'Age',
    required: true,
    render: renderAge,
  },
]

const pvcSelector = makePersistentVolumeClaimSelector()

const PodOverview = ({ pod, loading }) => {
  const { currentPluginId } = usePluginRouter()
  const classes = useStyles()
  const params = useMemo(
    () => ({
      clusterId: pod?.clusterId,
      namespace: pod?.namespace,
    }),
    [pod],
  )

  const { loading: loadingServices } = useListAction(listServices, {
    params,
    requiredParams: ['clusterId', 'namespace'],
  })
  const allServices = useSelectorWithParams(serviceSelectors, params)
  const services = useMemo(() => getServicesForResource(pod, allServices), [pod, allServices])

  const { loading: loadingIngresses } = useListAction(listIngresses, {
    params,
    requiredParams: ['clusterId'],
  })
  const allIngresses = useSelectorWithParams(ingressesSelector, params)
  const ingresses = useMemo(() => getResourceIngresses(services, allIngresses), [
    allIngresses,
    services,
  ])

  const { loading: loadingConfigMaps } = useListAction(listConfigMaps, {
    params,
    requiredParams: ['clusterId'],
  })
  const allConfigMaps = useSelectorWithParams(configMapsSelector, params)
  const configMaps = useMemo(() => getConfigMapsForResource(pod, allConfigMaps), [
    allConfigMaps,
    pod,
  ])

  const { loading: loadingPvcs } = useListAction(listPersistentVolumeClaims, {
    params,
    requiredParams: ['clusterId'],
  })
  const allPvcs = useSelectorWithParams(pvcSelector, params)
  const pvcs = useMemo(() => {
    return allPvcs.filter((pvc) => {
      const mounts = pvc.podsMounted || []
      return !!mounts.find((m) => m?.pod === pod?.name)
    })
  }, [allPvcs, pod])

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, pod)
  }, [pod])

  const containerColumns = getContainerColumns(currentPluginId)
  const serviceTableColumns = getServiceTableColumns(currentPluginId)
  const persistentVolumeClaimsListTableColumns = getPersistentVolumeClaimsListTableColumns(
    currentPluginId,
  )

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <InfoCard
            items={metadata}
            title="Metadata"
            footer={<LabelsAndAnnotationsSection entity={pod} resourceType="pod" />}
          />
          <Card title="Config Maps" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={configMaps}
              columns={configMapColumns}
              loading={loadingConfigMaps}
              compact
              disableToolbar
            />
          </Card>
        </div>
        <div className={classes.column}>
          <Card title="Containers" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={pod?.containers}
              columns={containerColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Services" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={services}
              columns={serviceTableColumns}
              loading={loadingServices}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Ingresses" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={ingresses}
              columns={ingressTableColumns}
              loading={loadingIngresses || loadingServices}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Persistent Volume Claims" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={pvcs}
              columns={persistentVolumeClaimsListTableColumns}
              loading={loadingPvcs}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}
export default PodOverview
