import React, { useEffect, useMemo } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { routes } from 'core/utils/routes'
import { createResourceLabelsCell } from '../common/entity/labels-and-annotations/helpers'
import { IPodDetailsPageTabs } from './model'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { listPods, deletePod } from './new-actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { podsSelector } from './selectors'
import AddWorkloadResourcePage from 'k8s/components/common/entity/AddResourcePage'
import { getPodStatus } from 'k8s/components/pods/helpers'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import GridStatusMessageCell from 'core/elements/grid/cells/GridStatusMessageCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import { durationBetweenDates } from 'utils/misc'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useReactRouter from 'use-react-router'
import { listNamespaces } from 'k8s/components/namespaces/new-actions'
import { namespacesSelector } from 'k8s/components/namespaces/selectors'

type ModelDataKey = DataKeys.Pods
type SelectorModel = ArrayElement<ReturnType<typeof podsSelector>>
type ActionParams = InferActionParams<typeof listPods>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  masterNodeClusters: boolean
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']
const defaultParams: Params & SortingState = {
  clusterId: null,
  namespace: null,
  masterNodeClusters: true,
  healthyClusters: true,
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Pods', listTablePrefs)

const handleSortByStatus = (statusA, statusB) => {
  if (!statusA?.state || statusA?.state === 'Running') return -1
  if (!statusB?.state || statusB?.state === 'Running') return 1

  return statusA?.reason > statusB?.reason ? 1 : statusA?.reason === statusB?.reason ? 0 : -1
}

const searchTargets = ['name', 'clusterName']

export const getPodColumns = (pluginId = 'kubernetes'): GridViewColumn<SelectorModel>[] => {
  const routePath = pluginId === 'kubevirt' ? 'kubevirtPods' : 'pods'
  return [
    {
      key: 'name',
      label: 'Name',
      width: 'medium',
      CellComponent: createGridLinkCell({
        routeToFn: ({ clusterId, id }) =>
          routes[routePath].details.path({ clusterId, id, tab: IPodDetailsPageTabs.Overview }),
      }),
    },
    { key: 'namespace', label: 'Namespace' },
    { key: 'clusterName', label: 'Cluster', width: 'medium' },
    {
      key: 'creationTimestamp',
      label: 'Age',
      formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
    },
    {
      key: 'status.phase',
      label: 'Status',
      CellComponent: createGridStatusCell({
        dataFn: getPodStatus,
      }),
    },
    {
      key: 'podStatus',
      label: 'Status Message',
      sortFn: handleSortByStatus,
      CellComponent: GridStatusMessageCell,
    },
    {
      key: 'labels',
      label: 'Labels',
      disableSorting: true,
      CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
    },
    {
      key: 'annotations',
      label: 'Annotations',
      disableSorting: true,
      CellComponent: createResourceLabelsCell({ type: 'table', separator: ': ' }),
    },
  ]
}

export default function PodsListPage() {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtPods' : 'pods'
  const { allParams: params, updateGlobalParams, getParamsUpdater } = useGlobalParams(
    usePrefParams,
    defaultParams,
  )
  const { location } = useReactRouter()
  const clusterId = new URLSearchParams(location.search).get('clusterId') || undefined

  const { message, loading, reload } = useListAction(listPods, {
    params,
    requiredParams,
  })
  const data = useAppSelector(podsSelector)

  const { loading: loadingNamespaces } = useListAction(listNamespaces, {
    params: { clusterId: clusterId },
    requiredParams: ['clusterId'],
  })
  const namespaces = useAppSelector(namespacesSelector)
  const namespacesNames = useMemo(() => namespaces.map((ns) => ns.name), [namespaces])

  useEffect(() => {
    if (clusterId) {
      updateGlobalParams({
        clusterId: [clusterId],
        namespace: namespacesNames,
      })
    }
  }, [])

  const podColumns = getPodColumns(currentPluginId)
  return (
    <>
      <DocumentMeta title="Pods" />
      <AddWorkloadResourcePage resourceType="pod" addRoute={routes[routePath].add} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.Pods}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={podColumns}
        addUrl={routes[routePath].add.path()}
        addText="Add Pod"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deletePod}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
