import React, { useCallback, useState, useMemo } from 'react'
import ListTable from 'core/components/listTable/ListTable'
import { allKey } from 'app/constants'
import { profilesHelpUrl } from 'k8s/links'
import useParams from 'core/hooks/useParams'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import DateCell from 'core/components/listTable/cells/DateCell'
import SimpleLink from 'core/components/SimpleLink'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import { emptyArr } from 'utils/fp'
import useStyles from './useStyles'
import ExpandIcon from 'k8s/components/rbac/profiles/ExpandIcon'
import ApiPermissionsDialog, {
  ApiPermissionsParams,
} from 'k8s/components/rbac/profiles/create/ApiPermissionsDialog'
import useToggler from 'core/hooks/useToggler'
import { listRoles } from 'k8s/components/rbac/new-actions'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { makeRolesSelector } from 'k8s/components/rbac/selectors'

const defaultParams = {
  masterNodeClusters: true,
  namespace: allKey,
}
const visibleColumns = ['name', 'namespace', 'created', 'apiAccess']
const columnsOrder = ['name', 'namespace', 'created', 'apiAccess']
const orderBy = 'name'
const orderDirection = 'asc'
const searchTargets = ['name', 'namespace']

export const RolesListTable = ({
  data,
  onReload = null,
  loading = null,
  onSelectedRowsChange = null,
  selectedRows = emptyArr,
  filters = null,
  ...rest
}) => {
  const [currentApiPermissions, setCurrentApiPermissions] = useState<ApiPermissionsParams>()
  const [showingPermissionsDialog, togglePermissionsDialog] = useToggler()
  const showApiPermissions = useCallback((row) => {
    setCurrentApiPermissions({
      name: row.name,
      cluster: row.clusterName,
      namespace: row.namespace,
      rules: row.rules,
    })
    togglePermissionsDialog()
  }, [])
  const columns = useMemo(
    () => [
      { id: 'id', display: false },
      { id: 'name', label: 'Name' },
      { id: 'namespace', label: 'Namespace' },
      { id: 'created', label: 'Created', render: (value) => <DateCell value={value} /> },
      {
        id: 'apiAccess',
        render: (value, row) => (
          <ExpandIcon
            onClick={(e) => {
              e.stopPropagation()
              showApiPermissions(row)
            }}
          />
        ),
      },
    ],
    [],
  )

  return (
    <>
      <ApiPermissionsDialog
        onClose={togglePermissionsDialog}
        open={showingPermissionsDialog}
        name={currentApiPermissions?.name}
        cluster={currentApiPermissions?.cluster}
        namespace={currentApiPermissions?.namespace}
        rules={currentApiPermissions?.rules}
      />
      <ListTable
        filters={filters}
        uniqueIdentifier={'id'}
        canEditColumns={false}
        searchTargets={searchTargets}
        data={data}
        onReload={onReload}
        loading={loading}
        columns={columns}
        visibleColumns={visibleColumns}
        columnsOrder={columnsOrder}
        orderBy={orderBy}
        orderDirection={orderDirection}
        onSelectedRowsChange={onSelectedRowsChange}
        showCheckboxes={!!onSelectedRowsChange}
        selectedRows={selectedRows}
        selectColumns={false}
        wrapToolbar
        {...rest}
      />
    </>
  )
}

const paramsRolesSelector = makeRolesSelector({ useGlobalParams: false })

const RolesForm = ({ wizardContext, setWizardContext }) => {
  const classes = useStyles()
  const [selectedRows, setSelectedRows] = useState(emptyArr)
  const { params, getParamsUpdater } = useParams(defaultParams)

  const { loading, reload } = useListAction(listRoles, {
    params: { ...params, clusterId: wizardContext.baseCluster },
    requiredParams: ['clusterId'],
  })
  const data = useSelectorWithParams(paramsRolesSelector, {
    clusterId: wizardContext.baseCluster,
    namespace: params.namespace,
    useGlobalParams: false,
  })

  const refetch = useCallback(() => reload(true), [reload])
  const handleSelect = useCallback((rows) => {
    setSelectedRows(rows)
    setWizardContext({
      roles: rows.reduce((acc, row) => {
        acc[row.id] = row
        return acc
      }, {}),
    })
  }, [])

  return (
    <>
      <FormFieldCard
        className={classes.listBox}
        title={'RBAC Profile - Roles'}
        link={<SimpleLink src={profilesHelpUrl}>Profiles Help</SimpleLink>}
      >
        <RolesListTable
          filters={
            <NamespacePicklist
              /*
              // @ts-ignore */
              value={params.namespace}
              clusterId={wizardContext.baseCluster}
              disabled={!wizardContext.baseCluster}
              onChange={getParamsUpdater('namespace')}
            />
          }
          data={data}
          onReload={refetch}
          loading={loading}
          onSelectedRowsChange={handleSelect}
          selectedRows={selectedRows}
        />
      </FormFieldCard>
    </>
  )
}

export default RolesForm
