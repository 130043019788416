import ExternalLink from 'core/components/ExternalLink'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import React, { useEffect, useRef, useMemo, useCallback } from 'react'
import Text from 'core/elements/Text'
import { gettingStartedHelpLink } from 'k8s/links'
import {
  CloudProviders,
  CloudProvidersFriendlyName,
} from 'app/plugins/infrastructure/components/cloudProviders/model'
import CloudProviderRegionField from '../../../infrastructure/components/clusters/form-components/CloudProviderRegionField'
import SshKeyField from '../../../infrastructure/components/clusters/form-components/SshKeyPicklist'
import AwsClusterSshKeyPicklist from '../../../infrastructure/components/clusters/aws/AwsClusterSshKeyPicklist'
import SshKeyTextField from '../../../infrastructure/components/clusters/form-components/SshKeyTextfield'
import useDataUpdater from 'core/hooks/useDataUpdater'
import { clusterActions } from '../../../infrastructure/components/clusters/actions'
import {
  columns as awsColumns,
  initialContext as awsInitialContext,
} from '../../../infrastructure/components/clusters/aws/create-templates/OneClickAwsCluster'
import {
  columns as azureColumns,
  initialContext as azureInitialContext,
} from '../../../infrastructure/components/clusters/azure/create-templates/OneClickAzureCluster'
import {
  awsClusterTracking,
  azureClusterTracking,
} from '../../../infrastructure/components/clusters/tracking'
import { ClusterCreateTypes } from '../../../infrastructure/components/clusters/model'
import AwsAvailabilityZoneField from '../../../infrastructure/components/clusters/aws/AwsAvailabilityZone'
import { sort } from 'ramda'
import { compareVersions } from 'k8s/util/helpers'
import { sessionActions } from 'core/session/sessionReducers'
import { useDispatch, useSelector } from 'react-redux'
import { routes } from 'core/utils/routes'
import { onboardClusterTracking } from './tracking'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import FormReviewTable from 'core/components/validatedForm/review-table'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { listSupportedRoleVersions } from '../../../infrastructure/components/clusters/newActions'
import useListAction from 'core/hooks/useListAction'
import { supportedRoleVersionsSelector } from '../../../infrastructure/components/clusters/selectors'

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

const CreateCloudClusterPage = ({
  wizardContext,
  setWizardContext,
  onNext,
  setSubmitting,
  setClusterId,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const onComplete = (success, cluster) => {
    if (!success) return
    const clusterNodeUrl = routes.cluster.managed.qbert.detail.path({
      tab: 'node-health',
      id: cluster?.uuid,
    })
    dispatch(sessionActions.updateSession({ onboardingRedirectToUrl: clusterNodeUrl }))
    setClusterId(cluster?.uuid)
  }
  const [createCluster] = useDataUpdater(clusterActions.create, onComplete)
  const validatorRef = useRef(null)
  const defaultValues = useMemo(
    () => (wizardContext.provider === CloudProviders.Aws ? awsInitialContext : azureInitialContext),
    [wizardContext.provider],
  )
  const { loading } = useListAction(listSupportedRoleVersions)
  const kubernetesVersions = useSelector(supportedRoleVersionsSelector)

  const defaultKubernetesVersion = useMemo(() => {
    const versionsList = kubernetesVersions?.map((obj) => obj.roleVersion) || []
    const sortedVersions = sort(compareVersions, versionsList) // this sorts the versions from low-high
    return sortedVersions[sortedVersions.length - 1]
  }, [kubernetesVersions])

  const setupValidator = (validate) => {
    validatorRef.current = { validate }
  }

  const segmentTrackingFields = useMemo(
    () => ({
      platform: wizardContext.provider,
      target: ClusterCreateTypes.OneClick,
    }),
    [wizardContext.provider],
  )

  const handleSubmit = useCallback(async () => {
    const isValid = await validatorRef.current.validate()
    if (!isValid) {
      return false
    }
    onboardClusterTracking.wzCreateClusterOnCloud(wizardContext.name, wizardContext.provider)
    setSubmitting(true)
    const data = {
      ...defaultValues,
      ...wizardContext,
      segmentTrackingFields,
      clusterType: wizardContext.provider,
      name: wizardContext.clusterName,
      kubeRoleVersion: defaultKubernetesVersion,
      location: wizardContext.region, // We need to add this in for Azure. Azure takes in a location field
    }

    await createCluster(data)
    setSubmitting(false)
    return true
  }, [
    validatorRef.current,
    setSubmitting,
    defaultValues,
    segmentTrackingFields,
    wizardContext,
    defaultKubernetesVersion,
  ])

  useEffect(() => {
    onNext(handleSubmit)
  }, [handleSubmit])

  useEffect(() => {
    if (wizardContext.provider === CloudProviders.Aws) {
      awsClusterTracking.createStarted(segmentTrackingFields)()
      awsClusterTracking.oneClick(segmentTrackingFields)()
    } else if (wizardContext.provider === CloudProviders.Azure) {
      azureClusterTracking.createStarted(segmentTrackingFields)()
      azureClusterTracking.oneClick(segmentTrackingFields)()
    }
  }, [])

  useEffect(() => {
    if (wizardContext.provider === CloudProviders.Aws) {
      awsClusterTracking.createStarted(segmentTrackingFields)()
      awsClusterTracking.oneClick(segmentTrackingFields)()
    } else if (wizardContext.provider === CloudProviders.Azure) {
      azureClusterTracking.createStarted(segmentTrackingFields)()
      azureClusterTracking.oneClick(segmentTrackingFields)()
    }
  }, [wizardContext.provider])

  const columns = useMemo(
    () => (wizardContext.provider === CloudProviders.Aws ? awsColumns : azureColumns),
    [wizardContext.provider],
  )

  return (
    <ValidatedForm
      classes={{ root: classes.validatedFormContainer }}
      link={
        <ExternalLink url={gettingStartedHelpLink}>
          <Text variant="caption2">
            {CloudProvidersFriendlyName[wizardContext.provider]} Cluster Help
          </Text>
        </ExternalLink>
      }
      triggerSubmit={setupValidator}
      elevated={false}
    >
      <FormFieldCard title="Cluster Configuration">
        <CloudProviderRegionField
          cloudProviderType={wizardContext.provider}
          values={wizardContext}
          onChange={(value) => setWizardContext({ region: value, azs: [] })}
          wizardContext={wizardContext}
        />

        {wizardContext.provider === CloudProviders.Aws && (
          <>
            {wizardContext.region && (
              <AwsAvailabilityZoneField
                values={wizardContext}
                wizardContext={wizardContext}
                setWizardContext={setWizardContext}
                allowMultiSelect={false}
              />
            )}
            <SshKeyField
              dropdownComponent={AwsClusterSshKeyPicklist}
              values={wizardContext}
              wizardContext={wizardContext}
              setWizardContext={setWizardContext}
            />
          </>
        )}
        {wizardContext.provider === CloudProviders.Azure && (
          <SshKeyTextField wizardContext={wizardContext} setWizardContext={setWizardContext} />
        )}
      </FormFieldCard>
      <FormFieldCard title="Default Settings for New Cluster">
        <FormReviewTable data={defaultValues} columns={columns} />
      </FormFieldCard>
    </ValidatedForm>
  )
}

export default CreateCloudClusterPage
