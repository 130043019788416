import React from 'react'
import Picklist from 'core/elements/dropdown/AsyncDropdown'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'

interface StorageClassKindListProps extends Omit<DropdownProps<string>, 'items'> {
  formField?: boolean
}

const defaultOptions = [
  { label: 'shared', value: 'shared' },
  { label: 'dedicated', value: 'dedicated' },
  { label: 'managed', value: 'managed' },
]

export default function StorageClassKindPicklist({
  name = 'storageClassKindType',
  label = 'Storage Class Kind',
  formField = false,
  ...props
}: StorageClassKindListProps) {
  return (
    <Picklist {...props} name={name} label={label} formField={formField} items={defaultOptions} />
  )
}
