import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { AppSelector } from 'app/store'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { complement, isNil, propSatisfies } from 'ramda'
import { findClusterName } from 'k8s/util/helpers'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { durationBetweenDates } from 'utils/misc'
import { IHostNetworkTemplateSelector } from './model'

const getConfigTypes = (template) => {
  const types = []
  if (template?.spec?.hasOwnProperty('ovsConfig')) {
    types.push('OVS')
  }
  if (template?.spec?.hasOwnProperty('interfaceConfig')) {
    types.push('Interface')
  }
  return types
}

export const hostNetworkTemplatesSelector: AppSelector<IHostNetworkTemplateSelector[]> = createSharedSelector(
  // We do not want to filter by namespace for this resource
  getDataSelector<DataKeys.HostNetworkTemplates>(
    DataKeys.HostNetworkTemplates,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  clustersSelector, // Currently only bareos clusters may have luigi addons
  (hostNetworkTemplates, clusters) =>
    hostNetworkTemplates
      .map((template) => {
        return {
          ...template,
          clusterName: findClusterName(clusters, template?.clusterId),
          age: durationBetweenDates({ labels: ['d'] })(template?.created),
          configTypes: getConfigTypes(template),
        }
      })
      .filter(propSatisfies(complement(isNil), 'clusterName')),
)
