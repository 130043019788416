import ApiClient from 'api-client/ApiClient'
import { allKey } from 'app/constants'
import DataKeys from 'k8s/DataKeys'
import { flatten, pluck } from 'ramda'
import { someAsync } from 'utils/async'
import { INamespaceSelector } from 'k8s/components/namespaces/model'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import { namespacesSelector } from 'k8s/components/namespaces/selectors'
import store from 'app/store'

const { qbert } = ApiClient.getInstance()

const podMetricsActionSet = ActionsSet.make<DataKeys.PodMetrics>({
  uniqueIdentifier: 'metadata.name',
  indexBy: 'clusterId',
  entityName: 'Pod Metrics',
  cacheKey: DataKeys.PodMetrics,
})

export const listPodMetrics = podMetricsActionSet.add(
  new ListAction<DataKeys.PodMetrics, { clusterId: string }>(async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get pod metrics', { clusterId })
    if (clusterId === allKey) {
      // We shouldn't load metrics for all clusters. This is excessively heavy and our
      // current use cases for loading pods for all clusters is for counts of pods.
      // Metrics aren't needed on these. If a future use case comes up we can readdress
      return Promise.resolve([])
    }
    const state = store.getState()
    const namespaces: INamespaceSelector[] = namespacesSelector(state, { clusterId })
    return someAsync(
      pluck('name', namespaces).map((name) => qbert.getPodsMetrics(clusterId, name)),
    ).then(flatten)
  }).addDependency(DataKeys.Namespaces),
)
