import React from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import RbacChecklist from './RbacChecklist'
import TextField from 'core/components/validatedForm/TextField'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import NoContentMessage from 'core/components/NoContentMessage'
import { makeStyles } from '@material-ui/styles'
import DocumentMeta from 'core/components/DocumentMeta'
import { routes } from 'core/utils/routes'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createClusterRole } from './new-actions'
import Theme from 'core/themes/model'
import useReactRouter from 'use-react-router'
import ModalForm from 'core/elements/modal/ModalForm'
import usePluginRouter from 'core/hooks/usePluginRouter'

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: 16,
  },
}))

const defaultParams = {
  clusterId: '', // Initialize here to prevent desync with formContext
  rbac: {},
}

export default function AddClusterRoleForm() {
  const classes = useStyles({})
  const { history } = useReactRouter()
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)
  const { currentPluginId } = usePluginRouter()

  const { update, updating, reset, error: errorCreatingClusterRole } = useUpdateAction(
    createClusterRole,
  )

  const submitForm = async (params) => {
    const { success } = await update(params)
    if (success) handleClose()
  }
  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.rbac.clusterRoles.path({ plugin: currentPluginId }))
  }

  return (
    <>
      <DocumentMeta title="Add Cluster Role" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.rbac.addClusterRoles}
        title="Add Cluster Role"
        onSubmit={submitForm}
        onClose={handleClose}
        submitting={updating}
        error={errorCreatingClusterRole}
        submitTitle="Add Cluster Role"
      >
        <>
          <FormFieldSection title="Basic Details" step={1}>
            <TextField id="name" label="Name" required />
            <PicklistField
              DropdownComponent={ClusterPicklist}
              id="clusterId"
              label="Cluster"
              onChange={getParamsUpdater('clusterId')}
              value={params.clusterId}
              setInitialCluster
              compact={false}
              showAll={false}
              required
            />
          </FormFieldSection>
          <FormFieldSection title="API Access / Permissions" step={2}>
            {params.clusterId && (
              <RbacChecklist
                id="rbac"
                clusterId={params.clusterId}
                onChange={getParamsUpdater('rbac')}
                value={params.rbac}
              />
            )}
            {!params.clusterId && (
              <NoContentMessage message="Please choose a cluster to define it's API access permissions." />
            )}
          </FormFieldSection>
        </>
      </ModalForm>
    </>
  )
}
