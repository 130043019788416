import React from 'react'
import {
  CloudProviders,
  getCloudProviderIcon,
  getCloudProviderLabel,
} from 'app/plugins/infrastructure/components/cloudProviders/model'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import clsx from 'clsx'
import ExternalLink from 'core/components/ExternalLink'

interface Props {
  provider: CloudProviders
  description?: string | JSX.Element
  link?: string
  className?: string
}

export default function ClusterRequirementsHeader({
  provider,
  description,
  link,
  className = undefined,
}: Props) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.container, className)}>
      <Text className={classes.text} variant="h3">
        {getCloudProviderLabel(provider)}
      </Text>
      <Text className={classes.text} variant="body1">
        {description}
      </Text>
      <ExternalLink url={link} icon="external-link">
        {getCloudProviderLabel(provider)} Help
      </ExternalLink>
      <img src={getCloudProviderIcon(provider, 'medium')} />
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    gap: theme.spacing(2),

    '& img': {
      maxWidth: 200,
      gridColumnStart: 2,
      gridRowStart: 1,
      gridRowEnd: 4,
    },
  },
}))
