import React, { forwardRef, useMemo, useEffect } from 'react'
import PicklistDefault from 'core/elements/dropdown/AsyncDropdown'
import useListAction from 'core/hooks/useListAction'
import { projectAs } from 'utils/fp'
import { pluck, sortBy, prop, head, sortWith, descend } from 'ramda'
import { useAppSelector } from 'app/store'
import { listSupportedRoleVersions } from 'app/plugins/infrastructure/components/clusters/newActions'
import { supportedRoleVersionsSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { DropdownItemSpec } from 'core/elements/dropdown/DropdownCommonProps'
import { listClusterVersions } from 'app/plugins/infrastructure/components/clusters/cluster-addons/new-actions'
import { clusterVersionsSelector } from 'app/plugins/infrastructure/components/clusters/cluster-addons/selectors'
import ClusterVersionIcon from 'app/plugins/infrastructure/components/clusters/cluster-cells/ClusterVersionIcon'
import Text from 'core/elements/Text'
import { styled } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { CapiSupportedK8sVersions } from 'app/plugins/infrastructure/components/clusters/capi/constants'

const Picklist: any = PicklistDefault // types on forward ref .js file dont work well.

interface Props {
  onChange: (key: string) => void
  value: string
  selectFirst?: boolean
  isCapiCluster?: boolean
  inClusterCreation?: boolean
  inClusterUpgrade?: boolean
  customizeOptionsFn?: any
}

const ListItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const renderLabel = (version, clusterVersions) => {
  return (
    <ListItem>
      <ClusterVersionIcon version={version} clusterVersions={clusterVersions} />
      <Text variant="body1">&nbsp;{version}</Text>
    </ListItem>
  )
}

const filterClusterCreationAllowed = (clusterVersion) => clusterVersion?.clusterCreationAllowed
const filterClusterUpgradePossible = (clusterVersion) => clusterVersion?.clusterUpgradePossible
const filterUnsupportedVersions = (version) =>
  CapiSupportedK8sVersions.includes(version.kubeVersion)
const filterOutEarlyAccessVersions = (clusterVersion) => clusterVersion?.phase !== 'EarlyAccess'

const KubernetesVersionPicklist = forwardRef(
  (
    {
      onChange,
      value,
      selectFirst,
      customizeOptionsFn,
      inClusterCreation = false,
      inClusterUpgrade = false,
      isCapiCluster = false,
      ...rest
    }: Props,
    ref,
  ) => {
    const { loading } = useListAction(listClusterVersions)
    const unfilteredClusterVersions = useAppSelector(clusterVersionsSelector)

    const clusterCreationVersions = inClusterCreation
      ? unfilteredClusterVersions.filter(filterClusterCreationAllowed)
      : unfilteredClusterVersions

    const clusterVersions = inClusterUpgrade
      ? clusterCreationVersions.filter(filterClusterUpgradePossible)
      : clusterCreationVersions

    const sortedClusterVersions: any[] = useMemo(() => {
      const supportedVersions = isCapiCluster
        ? clusterVersions.filter(filterUnsupportedVersions)
        : clusterVersions
      return sortWith([descend(prop('version'))])(supportedVersions)
    }, [clusterVersions])

    const allOptions = useMemo(() => {
      return sortedClusterVersions.map((clusterVersion) => ({
        label: renderLabel(clusterVersion?.version, unfilteredClusterVersions),
        value: clusterVersion?.version,
      }))
    }, [sortedClusterVersions])

    const versions: string[] = useMemo(() => pluck('version')(sortedClusterVersions), [
      sortedClusterVersions,
    ])

    useEffect(() => {
      if (selectFirst && sortedClusterVersions?.length) {
        if (!value || !versions.includes(value)) {
          const latestVersion = head(sortedClusterVersions.filter(filterOutEarlyAccessVersions))
            ?.version // get the newest (largest value) version
          onChange(latestVersion)
        }
      }
    }, [sortedClusterVersions, selectFirst, value])

    const options = useMemo(
      () => (customizeOptionsFn ? customizeOptionsFn(allOptions) : allOptions),
      [allOptions, customizeOptionsFn],
    )

    return (
      <Picklist
        {...rest}
        value={value}
        onChange={onChange}
        loading={loading}
        ref={ref}
        items={options}
      />
    )
  },
)

export default KubernetesVersionPicklist
