import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import useReactRouter from 'use-react-router'
import { getProfileBindingDetails, listRbacProfiles } from '../new-actions'
import DocumentMeta from 'core/components/DocumentMeta'
import DriftAnalysis from './DriftAnalysis'
import useListAction from 'core/hooks/useListAction'
import { makeRbacProfileBindingsSelector } from '../selectors'
import { ClusterProfileBindingsSelector } from '../cluster-profile-binding-model'

const rbacProfileBindingsSelector = makeRbacProfileBindingsSelector({ dryRun: true })

const ViewDriftAnalysisPage = () => {
  const { match } = useReactRouter()
  const dispatch = useDispatch()
  const { id } = match.params

  const { loading } = useListAction(listRbacProfiles)
  const clusterProfileBindings: ClusterProfileBindingsSelector[] = useSelector(
    rbacProfileBindingsSelector,
  )

  const [analysis, setAnalysis] = useState('')
  const profileBinding = useMemo(() => clusterProfileBindings.find((x) => x.id === id), [
    clusterProfileBindings,
    id,
  ])

  useEffect(() => {
    const getDetails = async () => {
      const details = await getProfileBindingDetails(profileBinding.name)
      const analysis = details?.analysis
      setAnalysis(analysis)
    }
    if (profileBinding) {
      getDetails()
    }
  }, [profileBinding])

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: profileBinding?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [profileBinding?.name, id])
  if (!profileBinding) {
    return null
  }
  return (
    <>
      <DocumentMeta title="View Drift Analysis" breadcrumbs />
      <DriftAnalysis analysisString={analysis} clusterId={profileBinding.spec.clusterRef} />
    </>
  )
}

export default ViewDriftAnalysisPage
