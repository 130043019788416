import React, { useCallback, useEffect, useState } from 'react'
import FormWrapper from 'core/components/FormWrapper'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import DriftAnalysis from './DriftAnalysis'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { useDropzone } from 'react-dropzone'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Button from 'core/elements/button'
import { findClusterName } from 'k8s/util/helpers'
import { trackEvent } from 'utils/tracking'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'

const useStyles = makeStyles((theme: Theme) => ({
  wellContainer: {
    padding: '8px 24px',
    border: `1px solid ${theme.components.card.border}`,
    borderRadius: 4,
    maxWidth: 560,
    background: theme.components.card.background,
  },
  wellHeader: {
    padding: '8px 0px',
    borderBottom: `1px solid ${theme.components.card.border}`,
  },
  wellContent: {
    margin: theme.spacing(2, 0),
    background: theme.components.card.activeBackground,
    display: 'flex',
    flexDirection: 'column',
    height: 208,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  uploadIcon: {
    fontSize: 40,
    color: theme.components.card.text,
  },
  dropzoneText: {
    margin: '16px 0px 8px',
  },
  errorText: {
    color: theme.components.input.error,
  },
  importButton: {
    position: 'absolute',
    top: 58,
    right: 24,
  },
  analysisContainer: {
    position: 'relative',
  },
  analysisTargets: {
    display: 'flex',
    marginBottom: 16,
    padding: 16,
    background: theme.components.card.background,
  },
  flexGrow: {
    flexGrow: 1,
  },
}))

const isValidAnalysis = (analysis) => {
  try {
    const parsedAnalysis = JSON.parse(analysis)
    if (!Array.isArray(parsedAnalysis)) {
      return false
    }
    if (parsedAnalysis[0]) {
      const keys = Object.keys(parsedAnalysis[0])
      if (!keys.includes('op') || !keys.includes('oldValue')) {
        return false
      }
    }
  } catch (e) {
    return false
  }
  return true
}

const ImportDriftAnalysisPage = () => {
  const [fileText, setFileText] = useState('')
  const [analysis, setAnalysis] = useState('')
  const [clusterId, setClusterId] = useState('')
  const [profileName, setProfileName] = useState('')
  const classes = useStyles({})

  useListAction(listClusters)
  const clusters = useSelector(clustersSelector)
  const clusterName = findClusterName(clusters, clusterId)

  const onDrop = useCallback((acceptedFiles) => {
    trackEvent('Importing Drift Analaysis')
    const file = acceptedFiles[0]
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      const text = String(reader.result)
      setFileText(text)
    }
    reader.readAsText(file)
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.json', maxFiles: 1 })

  const resetAnalysis = useCallback(() => {
    setFileText('')
    setAnalysis('')
    setClusterId('')
    setProfileName('')
  }, [setFileText, setAnalysis, setClusterId])

  useEffect(() => {
    if (fileText) {
      try {
        const parsedText = JSON.parse(fileText)
        setAnalysis(parsedText?.analysis || 'error')
        setClusterId(parsedText?.cluster)
        setProfileName(parsedText?.clusterProfile?.split('/')[1])
      } catch (e) {
        setAnalysis('error')
      }
    }
  }, [fileText])

  return (
    <FormWrapper title="Import Drift Analysis" backUrl={routes.drift.list.path()} loading={false}>
      <DocumentMeta title="Import Drift Analysis" bodyClasses={['form-view']} />
      {(!analysis || !isValidAnalysis(analysis)) && (
        <div className={classes.wellContainer}>
          <Text variant="body1" className={classes.wellHeader}>
            Select a Drift Analysis File
          </Text>
          <div className={classes.wellContent} {...getRootProps()}>
            <input {...getInputProps()} />
            <div>
              <FontAwesomeIcon className={classes.uploadIcon}>upload</FontAwesomeIcon>
              <div className={classes.dropzoneText}>
                {analysis && (
                  <Text variant="body2" className={classes.errorText}>
                    Invalid Analysis. Please select another file.
                  </Text>
                )}
                <Text variant="body2">Drag and drop file (.json) here</Text>
                <Text variant="body2">or</Text>
              </div>
              <Button variant="secondary">Browse</Button>
            </div>
          </div>
        </div>
      )}
      {analysis && isValidAnalysis(analysis) && (
        <>
          <div className={classes.analysisTargets}>
            <Text variant="body2" className={classes.flexGrow}>
              <b>Cluster Profile</b>: {profileName}
            </Text>
            <Text variant="body2" className={classes.flexGrow}>
              <b>Cluster</b>: {clusterName || clusterId}
            </Text>
          </div>
          <div className={classes.analysisContainer}>
            <DriftAnalysis analysisString={analysis} clusterId={clusterId} />
            <Button
              variant="primary"
              className={classes.importButton}
              onClick={() => resetAnalysis()}
            >
              Import New Analysis
            </Button>
          </div>
        </>
      )}
    </FormWrapper>
  )
}

export default ImportDriftAnalysisPage
