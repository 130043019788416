import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const jobTypeFilterOptions = [
  {
    label: 'Job',
    value: 'job',
  },
  {
    label: 'Cronjob',
    value: 'cronjob',
  },
]

interface Props {}

export default function JobTypePicklist(props: PropsWithAsyncDropdown<Props, string>) {
  return (
    <AsyncDropdown
      showAll
      compact
      label="Type"
      name="Type"
      items={jobTypeFilterOptions}
      {...props}
    />
  )
}
