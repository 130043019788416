import { IGenericResource } from 'api-client/qbert.model'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'

export enum ILimitRangeDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface LimitRangesResponse {
  kind: string
  apiVersion: string
  metadata: LimitRangesResponseMetadata
  items: LimitRangeItem[]
}

export interface LimitRangeItem {
  metadata: ItemMetadata
  spec: LimitRangeSpec
}

interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels?: Record<string, string>
  ownerReferences?: OwnerReference[]
  annotations?: Record<string, string>
}

export interface OwnerReference {
  apiVersion: string
  kind: string
  name: string
  uid: string
  controller: boolean
  blockOwnerDeletion: boolean
}

interface LimitRangesResponseMetadata {
  resourceVersion: string
}

interface LimitRangeSpec {
  limits: Limit[]
}

export interface Limit {
  default?: Record<string, string>
  defaultRequest?: Record<string, string>
  type: string
  max?: Record<string, string>
  min?: Record<string, string>
}

export interface LimitObj {
  id: string
  type: string
  resource: string
  default?: string
  min?: string
  max?: string
  defaultRequest?: string
  maxLimitRequestRatio?: number
}

export interface ILimitRangeSelector extends IGenericResource<LimitRangeItem> {
  clusterName: string
  clusterId: string
  clusterType: ClusterTypes
  ownerReferences: LimitRangeItem['metadata']['ownerReferences']
  age?: string
  labels: LimitRangeItem['metadata']['labels']
  annotations: LimitRangeItem['metadata']['annotations']
  limitsObj: LimitObj[]
}
