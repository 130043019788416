import React, { useMemo } from 'react'
import { allKey } from 'app/constants'
import { styled } from '@material-ui/styles'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { DropdownItemSpec } from 'core/elements/dropdown/DropdownCommonProps'

const ListItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: 160,
})

// values match with moment's add/subtract API for use in actions.js
const getOptions = (isImpactAnalysis): DropdownItemSpec<string>[] => [
  {
    content: (
      <ListItem>
        <div>
          <img src="/ui/images/icon-plus-square-solid@2x.png" />{' '}
          <img src="/ui/images/icon-exchange-square-solid@2x.png" />{' '}
          <img src="/ui/images/icon-square-solid@2x.png" />
        </div>
        All
      </ListItem>
    ),
    value: allKey,
    label: 'All',
  },
  {
    content: (
      <ListItem>
        <img src="/ui/images/icon-plus-square-solid@2x.png" />
        {isImpactAnalysis ? 'Added' : 'Missing'}
      </ListItem>
    ),
    value: 'add',
    label: 'Add',
  },
  {
    content: (
      <ListItem>
        <img src="/ui/images/icon-exchange-square-solid@2x.png" />
        {isImpactAnalysis ? 'Modified' : 'Not Compliant'}
      </ListItem>
    ),
    value: 'replace',
    label: 'Replace',
  },
  {
    content: (
      <ListItem>
        <img src="/ui/images/icon-square-solid@2x.png" />
        {isImpactAnalysis ? 'Unchanged' : 'Present'}
      </ListItem>
    ),
    value: 'extra',
    label: 'Extra',
  },
]

interface Props {
  isImpactAnalysis: boolean
}

export default function OperationsPicklist({
  isImpactAnalysis = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const items = useMemo(() => getOptions(isImpactAnalysis), [isImpactAnalysis])

  return (
    <AsyncDropdown
      name="operation"
      label="Operation"
      items={items}
      compact
      showAll={false} /* Added all option manually to items for custom label */
      {...rest}
    />
  )
}
