import React from 'react'
import DocumentMeta from 'core/components/DocumentMeta'
import Progress from 'core/components/progress/Progress'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { useSelector } from 'react-redux'
import { makeParamsClustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import NetworkingInfoLandingPage from './NetworkingInfoLandingPage'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ExternalLink from 'core/components/ExternalLink'
import Button from 'core/elements/button'
import Card from 'core/elements/card'
import Grid from 'core/elements/grid'

const useStyles = makeStyles<Theme>((theme) => ({
  docLinks: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    gap: 8,
  },
  topCards: {
    marginTop: 16,
    display: 'grid',
    gap: 16,
    gridTemplateColumns: '1fr 2fr',
  },
  bottomCards: {
    marginTop: 16,
  },
}))

const advancedNetworkingClustersSelector = makeParamsClustersSelector({
  advancedNetworkingClusters: true,
})

const DocLinks = () => {
  const classes = useStyles()
  return (
    <div className={classes.docLinks}>
      {/* Todo: Get links for these */}
      <ExternalLink url="">
        <Button rightIcon="arrow-up-right-from-square" variant="secondary">
          Read Documentation
        </Button>
      </ExternalLink>
      <ExternalLink url="">
        <Button rightIcon="arrow-up-right-from-square" variant="secondary">
          Watch Tutorials
        </Button>
      </ExternalLink>
    </div>
  )
}

export default function NetworkingOverviewPage() {
  const classes = useStyles()
  const { loading: clustersLoading } = useListAction(listClusters)
  const networkingClusters = useSelector(advancedNetworkingClustersSelector)

  if (clustersLoading) {
    return <Progress loading message="Loading Clusters with Advanced Networking..." />
  }

  return (
    <>
      <DocumentMeta title="Networking" />
      {networkingClusters?.length ? (
        <div>
          <DocLinks />
          <div className={classes.topCards}>
            <Card title="Required Action" withCustomBody>
              <div>Required actions go here, need more details</div>
            </Card>
            <Card title="Networks" withCustomBody>
              <div>Networks Table goes here, waiting on NADs</div>
            </Card>
          </div>
          <div className={classes.bottomCards}>
            <Card title="IPs Remaining (DHCP)" withCustomBody>
              <div>IPs remaining stuff goes here, need more details</div>
            </Card>
          </div>
        </div>
      ) : (
        <NetworkingInfoLandingPage />
      )}
    </>
  )
}
