import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {}

const items = [
  { label: 'azure-disk unmanaged standard', value: 'azure-disk unmanaged standard' },
  { label: 'azure-disk managed standard', value: 'azure-disk managed standard' },
]

export default function AzureDiskTypeListPicklist({
  name = 'azureDiskType',
  label = 'Azure Disk Type',
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown name={name} label={label} items={items} {...rest} />
}
