import React, { useMemo, useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { routes } from 'core/utils/routes'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import useGlobalParams from 'core/hooks/useGlobalParams'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { useAppSelector } from 'app/store'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import EnablePluginsModal from './EnablePluginsModal'
import { listNetworkPlugins } from './actions'
import { networkPluginsSelector } from './selectors'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { INetworkPluginDetailsPageTabs } from './model'
import usePluginRouter from 'core/hooks/usePluginRouter'
import DeleteNetworkPluginDialog from './DeleteNetworkPluginDialog'

type ModelDataKey = DataKeys.NetworkPlugins
type SelectorModel = ArrayElement<ReturnType<typeof networkPluginsSelector>>
type ActionParams = InferActionParams<typeof listNetworkPlugins>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  masterNodeClusters: boolean
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']
const defaultParams: Params & SortingState = {
  clusterId: null,
  masterNodeClusters: true,
  healthyClusters: true,
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'NetworkPlugins',
  listTablePrefs,
)

const PluginStatus = ({ installed = false, children }) => {
  const classes = useStyles({ installed })
  return (
    <div className={classes.pluginStatus}>
      <FontAwesomeIcon solid className={classes.iconColor}>
        circle
      </FontAwesomeIcon>
      {children}
    </div>
  )
}

const CorePluginsCellComponent = ({ value, item }) => {
  return (
    <div>
      <PluginStatus installed={item?.spec?.plugins?.hostPlumber}>Host Plumber</PluginStatus>
      <PluginStatus installed={item?.spec?.plugins?.multus}>Multus CNI</PluginStatus>
      <PluginStatus installed={item?.spec?.plugins?.nodeFeatureDiscovery}>
        Node Feature Discovery
      </PluginStatus>
    </div>
  )
}

const NetworkTypePluginsCellComponent = ({ value, item }) => {
  return (
    <div>
      <PluginStatus installed={item?.spec?.plugins?.sriov}>SR-IOV</PluginStatus>
      {item?.spec?.plugins?.ovs?.dpdk ? (
        <PluginStatus installed={item?.spec?.plugins?.ovs}>OVS (DPDK Enabled)</PluginStatus>
      ) : (
        <PluginStatus installed={item?.spec?.plugins?.ovs}>OVS</PluginStatus>
      )}
    </div>
  )
}

const IpamPluginsCellComponent = ({ value, item }) => {
  return (
    <div>
      <PluginStatus installed={item?.spec?.plugins?.dhcpController}>Platform9 DHCP</PluginStatus>
      <PluginStatus installed={item?.spec?.plugins?.whereabouts}>Whereabouts</PluginStatus>
    </div>
  )
}

const searchTargets = ['name', 'clusterName']

export default function NetworkPluginsListPage() {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listNetworkPlugins, {
    params,
    requiredParams,
  })
  const data = useAppSelector(networkPluginsSelector)

  const [selectedNetworkPlugin, setSelectedNetworkPlugin] = useState<SelectorModel>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const columns: GridViewColumn<SelectorModel>[] = useMemo(
    () => [
      {
        key: 'name',
        label: 'Name',
        width: 'medium',
        CellComponent: createGridLinkCell({
          routeToFn: ({ clusterId, id }) =>
            routes[routePath].pluginDetails.path({
              clusterId,
              id,
              tab: INetworkPluginDetailsPageTabs.Overview,
            }),
        }),
      },
      { key: 'clusterName', label: 'Cluster', width: 'medium' },
      { key: 'spec.hostPlumber', label: 'Core Plugins', CellComponent: CorePluginsCellComponent },
      {
        key: 'spec.sriov',
        label: 'Network Type Plugins',
        CellComponent: NetworkTypePluginsCellComponent,
      },
      {
        key: 'spec.dhcpController',
        label: 'IPAM Plugins',
        CellComponent: IpamPluginsCellComponent,
      },
    ],
    [routePath],
  )

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      // if multus is installed then set to false
      cond: (item) => {
        return !item?.spec?.plugins?.multus
      },
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (networkPlugin) => {
        setSelectedNetworkPlugin(networkPlugin)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="Network Plugins" />
      <EnablePluginsModal addRoute={routes[routePath].addPlugin} />
      {showDeleteDialog && (
        <DeleteNetworkPluginDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedNetworkPlugin]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.NetworkPlugins}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes[routePath].addPlugin.path()}
        addText="Create Plugin Configuration"
        getParamsUpdater={getParamsUpdater}
        rowMenuItems={rowMenuItems}
        showRowMenuForSingleRowActions
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

const useStyles = makeStyles<Theme, { installed: boolean }>((theme: Theme) => ({
  pluginStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  iconColor: {
    fontSize: 10,
    color: ({ installed }) => (installed ? theme.palette.green.main : theme.palette.red.main),
  },
}))
