import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import NumberedStepLabel from 'core/components/validatedForm/NumberedStepLabel'
import TextField from 'core/components/validatedForm/TextField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    display: 'grid',
    gap: 24,
  },
}))

export default function BasicSettingsParamFields({ params, updateParams }) {
  const classes = useStyles()

  return (
    <div className={classes.fields}>
      <NumberedStepLabel step={1} title="Basic Settings" />
      <TextField
        id="name"
        label="Name"
        placeholder="Enter name..."
        onChange={(value) => updateParams({ name: value })}
        required
      />
      <PicklistField
        DropdownComponent={ClusterPicklist}
        id="clusterId"
        label="Cluster"
        onChange={(value) => updateParams({ clusterId: value })}
        value={params?.clusterId}
        compact={false}
        showAll={false}
        onlyAdvancedNetworkingClusters
        required
      />
      <PicklistField
        DropdownComponent={NamespacePicklist}
        disabled={!params?.clusterId}
        id="namespace"
        label="Namespace"
        onChange={(value) => updateParams({ namespace: value })}
        clusterId={params?.clusterId}
        value={params?.namespace}
        showAll={false}
        required
      />
    </div>
  )
}
