import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React, { useState, useEffect, useMemo } from 'react'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import clsx from 'clsx'
import { loadServiceCatalog } from 'k8s/components/apiAccess/endpoints/actions'
import { arrToObjByKey } from 'utils/fp'
import ApiClient from 'api-client/ApiClient'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import CopyToClipboard from 'core/components/CopyToClipboard'
import { pathJoin } from 'utils/misc'
import DropdownField from 'core/components/validatedForm/DropdownField'
import ClusterPicklist from '../common/ClusterPicklist'
import useParams from 'core/hooks/useParams'
import CloudProviderPicklist from '../common/CloudProviderPicklist'
import { createUrlWithQueryString } from 'core/plugins/route'
import { omit } from 'ramda'
import NamespacePicklist from '../common/NamespacePicklist'
import { devEnabled } from 'core/utils/helpers'
import Button from 'core/elements/button'
import Alert from 'core/components/Alert'

const methodsWithBody = ['POST', 'PUT', 'PATCH']

const renderParamField = ({ classes, param, params, getParamsUpdater }) => {
  if (param === 'clusterId') {
    return (
      <DropdownField
        key={param}
        DropdownComponent={ClusterPicklist}
        className={classes.textField}
        id="clusterId"
        label="clusterId"
        onChange={getParamsUpdater('clusterId')}
        value={params.clusterId}
        setInitialCluster
        required
      />
    )
  } else if (param === 'namespace') {
    return (
      <DropdownField
        key={param}
        DropdownComponent={NamespacePicklist}
        className={classes.textField}
        id="namespace"
        label="namespace"
        onChange={getParamsUpdater('namespace')}
        value={params.namespace}
        clusterId={params.clusterId}
        setInitialNamespace
        required
      />
    )
  } else if (param === 'cloudProviderId') {
    return (
      <DropdownField
        key={param}
        DropdownComponent={CloudProviderPicklist}
        className={classes.textField}
        id="cloudProviderId"
        label="cloudProviderId"
        onChange={getParamsUpdater('cloudProviderId')}
        value={params.cloudProviderId}
        required
      />
    )
  } else {
    return (
      <TextField
        key={param}
        className={classes.textField}
        id={param}
        label={param}
        onChange={getParamsUpdater(param)}
        required
      />
    )
  }
}

export default function ApiRequestHelper({ api, metadata, className = undefined }) {
  const classes = useStyles()
  const { params, getParamsUpdater } = useParams({})
  const [apiResponse, setApiResponse] = useState('')
  const [requestUrl, setRequestUrl] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const apiClient = ApiClient.getInstance()

  const isGetRequest = !methodsWithBody.includes(metadata.type) && metadata.type !== 'DELETE'
  const isDevEnabled = devEnabled()
  const enableRequest = isDevEnabled || (!isDevEnabled && isGetRequest && metadata?.url)

  useEffect(() => {
    setApiResponse('')
    setErrorMessage('')

    const setUrl = async () => {
      let serviceEndpoint = ''
      if (api === 'qbert') {
        serviceEndpoint = await apiClient.qbert.getApiEndpoint()
        if (metadata.version !== undefined) {
          serviceEndpoint = serviceEndpoint
            .replace(/\/v3$/, `/${metadata.version}`)
            .replace(/\/v3\//, `/${metadata.version}/`)
        }
      } else if (api === 'keystone') {
        serviceEndpoint = await apiClient.keystone.getApiEndpoint()
      } else {
        const serviceCatalog = await loadServiceCatalog()
        const catalogMap = arrToObjByKey('name', serviceCatalog)
        serviceEndpoint = catalogMap[api].url
      }
      if (apiClient[api]?.scopedEnpointPath) {
        serviceEndpoint = `${serviceEndpoint}/${apiClient[api].scopedEnpointPath()}`
      }
      const endpoint = createUrlWithQueryString(metadata.url, params)
      const url = pathJoin(serviceEndpoint, endpoint)
      setRequestUrl(url)
    }

    if (metadata?.url) setUrl()
  }, [metadata, params])

  const makeApiRequest = async (url, body = '') => {
    const params = {
      url,
      version: metadata.version,
      options: {
        clsName: api,
        mthdName: metadata.name,
      },
    }
    if (methodsWithBody.includes(metadata.type)) {
      params[body] = body
    }
    try {
      const response = await {
        GET: async () => apiClient.basicGet(params),
        POST: async () => apiClient.basicPost(params),
        PATCH: async () => apiClient.basicPatch(params),
        PUT: async () => apiClient.basicPut(params),
        DELETE: async () => apiClient.basicDelete(params),
      }[metadata.type]()

      return response
    } catch (error) {
      if (typeof error === 'object' && typeof error?.err === 'string') {
        return setErrorMessage(error.err)
      } else if (typeof error === 'object' && typeof error?.err?.message === 'string') {
        return setErrorMessage(error.err.message)
      }
      return setErrorMessage('Request Failed')
    }
  }

  const handleSubmit = async ({ body, ...inputValues }) => {
    let url = metadata.url
    const values = omit(['jsonResponse'], inputValues)
    if (metadata.params.length > 0) {
      url = createUrlWithQueryString(url, values)
    }
    const response = await makeApiRequest(url, body)
    if (response) {
      setApiResponse(JSON.stringify(response, null, 2))
    }
  }

  const hasParams = useMemo(() => metadata.params?.length > 0, [metadata.params])

  return (
    <div className={clsx(classes.apiRequestHelper, className)}>
      <Text variant="subtitle2">Request API</Text>
      <ValidatedForm classes={{ root: classes.form }} elevated={false} onSubmit={handleSubmit}>
        <Text component="span" variant="body2" lineClamp={2}>
          {requestUrl}
        </Text>
        {hasParams && (
          <div className={classes.parametersSection}>
            <Text variant="subtitle2">Request Parameters</Text>
            <div className={classes.parameters}>
              {metadata.params.map((param) =>
                renderParamField({ classes, param, params, getParamsUpdater }),
              )}
            </div>

            {methodsWithBody.includes(metadata.type) && isDevEnabled && (
              <CodeMirror
                className={classes.requestBody}
                id="body"
                label={
                  <CopyToClipboard copyText={apiResponse} inline codeBlock={false}>
                    Request Body
                  </CopyToClipboard>
                }
                options={{ mode: 'javascript' }}
              />
            )}
          </div>
        )}
        <div className={classes.actionContainer}>
          <Button disabled={!enableRequest}>Make API Request</Button>
          {!!errorMessage && <Alert message={errorMessage} variant="error" />}
        </div>
        <CodeMirror
          className={classes.codeMirror}
          id="jsonResponse"
          showCopyButton
          label="JSON Response"
          value={apiResponse}
          options={{ mode: 'javascript' }}
        />
      </ValidatedForm>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  apiRequestHelper: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: theme.spacing(3),
  },
  container: {
    margin: theme.spacing(2, 6, 3, 6),
    display: 'grid',
    alignContent: 'stretch',
  },
  request: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  form: {
    display: 'grid',
    gridGap: 24,
  },
  parametersSection: {
    display: 'grid',
    gridGap: 16,
  },
  parameters: {
    justifySelf: 'flex-start',
  },
  requestBody: {
    '& .CodeMirror': {
      maxHeight: '200px',
    },
  },
  codeMirror: {
    '& .CodeMirror': {
      maxHeight: '490px',
    },
  },
  actionContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 16,

    '& > button': {
      justifySelf: 'start',
    },
  },
}))
