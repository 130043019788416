import React from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import CSIDriversPicklist from '../../csi-drivers/CsiDriverPicklist'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  text: {
    marginBottom: theme.spacing(2),
  },
}))

const CsiDriverFields = ({ detectAutomatically, values, setValues }) => {
  const classes = useStyles()
  return (
    <>
      <Text className={classes.text} variant="body2">
        Platform9 can automatically detect available Kubernetes Storage Provisioners from any
        installed CSI Drivers. If no drivers are installed, a Storage Provisioner can be provided
        manually.
      </Text>
      {detectAutomatically ? (
        <>
          <PicklistField
            DropdownComponent={CSIDriversPicklist}
            id="csiDriver"
            label="CSI Driver"
            clusterId={values.clusterId}
            onChange={(csiDriver) => setValues({ csiDriver, provisioner: csiDriver })}
            value={values.csiDriver}
            required
          />
          <TextField
            id="provisioner"
            label="Choose a CSI Driver"
            mask={values.cloudProviderType === 'local' ? 'kubernetes.io/' : ''}
            value={values.provisioner}
            info=""
            disabled
            required
          />
        </>
      ) : (
        <TextField
          id="provisioner"
          label="Provisioner"
          onChange={(provisioner) => setValues({ provisioner })}
          value={values.provisioner}
          mask={values.cloudProviderType === 'local' ? 'kubernetes.io/' : ''}
          info=""
          required
        />
      )}
    </>
  )
}

export default CsiDriverFields
