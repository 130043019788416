import { makeStyles } from '@material-ui/styles'
import Alert from 'core/components/Alert'
import SubmitButton from 'core/components/buttons/SubmitButton'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import RadioFields from 'core/components/validatedForm/radio-fields'
import TextField from 'core/components/validatedForm/TextField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Text from 'core/elements/Text'
import { sessionStoreKey } from 'core/session/sessionReducers'
import downloadFile from 'core/utils/downloadFile'
import { generateKubeConfig } from 'app/plugins/infrastructure/components/clusters/kubeconfig'
import { prop } from 'ramda'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const getAuthOptions = (mfaEnabled, isSsoToken) => {
  const options = [
    {
      value: 'token',
      label: 'Token',
    },
  ]
  if (!mfaEnabled && !isSsoToken) {
    options.push({
      value: 'password',
      label: 'Password',
    })
  }
  options.push({
    value: 'certificate',
    label: 'Certificate',
  })
  return options
}

const useStyles = makeStyles((theme) => ({
  formBody: {
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 16,
    justifyItems: 'start',
  },
  authMethod: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    alignItems: 'center',
    gap: 32,
  },
  errorContainer: {
    paddingLeft: '12px',
  },
  inputFields: {
    width: '100% !important',
  },
  codeMirror: {
    '& .CodeMirror': {
      maxHeight: '500px',
      maxWidth: 740,
    },
  },
}))

const DownloadKubeConfigForm = ({
  cluster,
  onSubmit,
  autoDownload = true,
  previewKubeConfig = false,
  mfaEnabled = false,
}) => {
  const classes = useStyles()
  const [authMethod, setAuthMethod] = useState('token')
  const [errorMessage, setErrorMessage] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [kubeConfigFile, setKubeConfigFile] = useState()
  const selectSessionState = prop(sessionStoreKey)
  const session = useSelector(selectSessionState)
  const { isSsoToken } = session

  const handleSubmit = async (params) => {
    setErrorMessage(null)
    setSubmitting(true)
    const { username, password } = params
    const { error, kubeconfig } = await generateKubeConfig(
      cluster.uuid,
      authMethod,
      {
        username,
        password,
      },
      isSsoToken,
    )

    if (error) {
      setSubmitting(false)
      return setErrorMessage(error)
    }

    if (previewKubeConfig) {
      setKubeConfigFile(kubeconfig)
    }

    if (autoDownload) {
      downloadFile({
        filename: `${cluster.name}.yaml`,
        contents: kubeconfig,
      })
    }
    setSubmitting(false)
    return onSubmit(kubeconfig)
  }

  return (
    <ValidatedForm onSubmit={handleSubmit} fullWidth elevated={false}>
      <div className={classes.formBody}>
        <div className={classes.authMethod}>
          <Text variant="caption1">Authentication Method</Text>
          <RadioFields
            options={getAuthOptions(mfaEnabled, isSsoToken)}
            value={authMethod}
            onChange={(value) => setAuthMethod(value)}
          />
        </div>

        {authMethod === 'password' && (
          <>
            <TextField
              className={classes.inputFields}
              id="username"
              label="Enter your Username"
              required
            />

            <TextField
              className={classes.inputFields}
              id="password"
              label="Enter your Password"
              type="password"
              required
            />
            <br />
          </>
        )}
        {!!errorMessage && (
          <div className={classes.errorContainer}>
            <Alert variant="error" message={errorMessage} />
          </div>
        )}
        <SubmitButton
          type={authMethod === 'token' ? 'button' : 'submit'}
          onClick={handleSubmit}
          loading={submitting}
        >
          {authMethod !== 'password' ? 'Download Config' : 'Validate + Download Config'}
        </SubmitButton>
        <Alert
          variant={authMethod !== 'password' ? 'primary' : 'warning'}
          title="Note:"
          message={
            authMethod === 'token'
              ? 'Token authentication is the preferred method for downloading kubeconfig. The kubeconfig will remain valid for the next 24 hours.'
              : authMethod === 'certificate'
              ? 'Certificate authentication is a preferred method for downloading kubeconfig. The kubeconfig will remain valid as long as the certificate is valid.'
              : 'Password authentication is less secure than token authentication, but the kubeconfig will remain functional for as long as the username and password are valid.'
          }
        />
      </div>
      {!!kubeConfigFile && (
        <CodeMirror
          className={classes.codeMirror}
          id="kubeconfigYaml"
          showCopyButton
          label={cluster.name}
          copyText={kubeConfigFile}
          value={kubeConfigFile}
        />
      )}
    </ValidatedForm>
  )
}

export default DownloadKubeConfigForm
