import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { Condition, IPodDisruptionBudgetSelector } from '../model'
import Progress from 'core/components/progress/Progress'
import SummaryCard from 'k8s/components/common/entity/summary-card'
import InfoCard from 'k8s/components/common/entity/info-card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import { renderAge } from 'k8s/components/common/entity/helpers'
import Card from 'core/elements/card'
import { OwnerReferences } from '../../config-maps/details/ConfigMapsOverview'
import Grid, { GridViewColumn } from 'core/elements/grid/Grid'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'

const summaryRows: { key: keyof IPodDisruptionBudgetSelector; label: string }[] = [
  { key: 'clusterName', label: 'Cluster' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'age', label: 'Age' },
]

const conditionsColumns: GridViewColumn<Condition>[] = [
  {
    key: 'type',
    label: 'Type',
    width: 'medium',
  },
  {
    key: 'status',
    label: 'Status',
  },
  { key: 'lastTransitionTime', label: 'Last Transition Time', CellComponent: DateAndTime },
  { key: 'reason', label: 'Reason' },
  { key: 'message', label: 'Message' },
]

const PodDisruptionBudgetsOverview = ({
  podDisruptionBudget,
  loading,
  reload,
}: {
  podDisruptionBudget: IPodDisruptionBudgetSelector
  loading: boolean
  reload: (refetch: boolean) => void
}) => {
  const classes = useStyles()

  const metadataFields = [
    {
      id: 'age',
      title: 'Created',
      required: true,
      render: renderAge,
    },
    {
      id: 'ownerReferences',
      title: 'Owner References',
      render: (value) => <OwnerReferences refs={value} />,
      condition: ({ ownerReferences }) => ownerReferences?.length > 0,
    },
    {
      id: 'spec.minAvailable',
      title: 'Min Available',
      required: true,
    },
    {
      id: 'spec.maxUnavailable',
      title: 'Max Unavailable',
      required: true,
    },
    {
      id: 'status.desiredHealthy',
      title: 'Desired Healthy',
      required: true,
    },
    {
      id: 'status.currentHealthy',
      title: 'Current Healthy',
      required: true,
    },
    {
      id: 'status.disruptionsAllowed',
      title: 'Disruptions Allowed',
      required: true,
    },
    {
      id: 'status.expectedPods',
      title: 'Expected Pods',
      required: true,
    },
    {
      id: 'status.observedGeneration',
      title: 'Observed Generation',
      required: true,
    },
  ]

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, podDisruptionBudget)
  }, [podDisruptionBudget])

  return (
    <Progress loading={loading}>
      <div className={classes.container}>
        <div className={classes.column}>
          <SummaryCard<IPodDisruptionBudgetSelector>
            title={podDisruptionBudget?.name}
            subtitle="Pod Disruption Budget"
            rows={summaryRows}
            data={podDisruptionBudget}
          />
          <InfoCard
            items={metadata}
            title="Properties"
            footer={
              <div className={classes.footer}>
                <LabelsAndAnnotationsSection
                  entity={podDisruptionBudget}
                  resourceType="podDisruptionBudget"
                />
              </div>
            }
          />
        </div>
        <div className={classes.column}>
          <Card title="Status Conditions" withCustomBody>
            <Grid
              uniqueIdentifier="lastTransitionTime"
              data={podDisruptionBudget?.status?.conditions || []}
              columns={conditionsColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutosummaryRows: 'max-content',
  },
  footer: {
    margin: theme.spacing(1),
  },
  copyClipboard: {
    '& .copy': {
      color: theme.components.typography.passive,
    },
  },
}))

export default PodDisruptionBudgetsOverview
