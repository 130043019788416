import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { routes } from 'core/utils/routes'
import { deleteHorizontalPodAutoscaler } from './actions'
import usePluginRouter from 'core/hooks/usePluginRouter'

export default function DeleteHorizontalPodAutoscalerDialog({
  rows: [horizontalPodAutoscaler],
  onClose,
}) {
  const { history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtConfiguration' : 'configuration'
  const { update: deleteFn, updating: deletingHorizontalPodAutoscaler } = useUpdateAction(
    deleteHorizontalPodAutoscaler,
  )

  const handleDelete = useCallback(async () => {
    await deleteFn(horizontalPodAutoscaler)
    onClose(true)
    history.push(routes[routePath].horizontalPodAutoscalers.list.path())
  }, [horizontalPodAutoscaler, routePath])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={`Remove Horizontal Pod Autoscaler "${horizontalPodAutoscaler?.name}" ?`}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingHorizontalPodAutoscaler}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingHorizontalPodAutoscaler}>
            Delete
          </Button>
        </>
      }
    >
      <Text variant="body2">Your data will be lost since this action cannot be undone.</Text>
    </Modal>
  )
}
