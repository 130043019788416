export enum IDeployedAppDetailsPageTabs {
  Overview = 'overview',
  Pods = 'pods',
  Deployments = 'deployments',
  StatefulSets = 'statefulSets',
  ReplicaSets = 'replicaSets',
  Events = 'events',
  Logs = 'logs',
}

export interface IDeployedAppsSelector extends DeployedApp {
  repository: string
  icon: string
  home: string
  chartVersion: string
  appVersion: string
}

export interface DeployedApp {
  name: string
  chart: string
  chart_version: string
  description: string
  version: number
  namespace: string
  status: string
  clusterId?: string
}

export interface DeployedAppDetails {
  name: string
  info: Info
  chart: Chart
  config: Config
  manifest: string
  hooks: Hook[]
  version: number
  namespace: string
}

interface Chart {
  metadata: Metadata
  lock: Lock
  templates: File[]
  values: Config
  schema: null
  files: File[]
}

interface File {
  name: string
  data: string
}

interface Lock {
  generated: string
  digest: string
  dependencies: Dependency[]
}

interface Dependency {
  name: string
  version: string
  repository: string
}

interface Metadata {
  name: string
  home: string
  version: string
  description: string
  icon: string
  apiVersion: string
  appVersion: string
  deprecated: boolean
}

interface Config {
  artifactRepository: ArtifactRepository
  controller: Controller
  crdVersion: string
  createAggregateRoles: boolean
  executor: Executor
  images: Images
  init: Init
  installCRD: boolean
  minio: Minio
  server: Server
  singleNamespace: boolean
  useDefaultArtifactRepo: boolean
  useStaticCredentials: boolean
  workflow: Workflow
}

interface ArtifactRepository {
  archiveLogs: boolean
  s3: S3
}

interface S3 {
  accessKeySecret: KeySecret
  insecure: boolean
  secretKeySecret: KeySecret
}

interface KeySecret {
  key: string
}

interface Controller {
  affinity: Affinity
  containerRuntimeExecutor: string
  extraArgs: any[]
  extraEnv: any[]
  image: Image
  instanceID: InstanceID
  links: any[]
  loadBalancerSourceRanges: any[]
  logging: Logging
  metricsConfig: MetricsConfigClass
  metricsServicePort: number
  metricsServicePortName: string
  name: string
  nodeSelector: Record<string, string>
  parallelism: null
  pdb: InstanceID
  persistence: Affinity
  podAnnotations: Affinity
  podLabels: Affinity
  podSecurityContext: Affinity
  priorityClassName: string
  replicas: number
  resources: Affinity
  serviceAccount: string
  serviceAccountAnnotations: Affinity
  serviceAnnotations: Affinity
  serviceLabels: Affinity
  serviceMonitor: ServiceMonitor
  serviceType: string
  telemetryConfig: MetricsConfigClass
  telemetryServicePort: number
  telemetryServicePortName: string
  tolerations: any[]
  workflowDefaults: Affinity
  workflowNamespaces: string[]
  workflowRestrictions: Affinity
}

interface Affinity {}

interface Image {
  tag: string
}

interface InstanceID {
  enabled: boolean
}

interface Logging {
  globallevel: string
  level: string
}

interface MetricsConfigClass {
  enabled: boolean
  path: string
  port: number
}

// interface NodeSelector {
//   'kubernetes.io/os': string
// }

interface ServiceMonitor {
  additionalLabels: Affinity
  enabled: boolean
}

interface Executor {
  env: Affinity
  image: Image
  resources: Affinity
  securityContext: Affinity
}

interface Images {
  controller: string
  executor: string
  namespace: string
  pullPolicy: string
  pullSecrets: any[]
  server: string
  tag: string
}

interface Init {
  serviceAccount: string
}

interface Minio {
  defaultBucket: DefaultBucket
  install: boolean
}

interface DefaultBucket {
  enabled: boolean
  name: string
}

interface Server {
  affinity: Affinity
  baseHref: string
  clusterWorkflowTemplates: ClusterWorkflowTemplates
  createServiceAccount: boolean
  enabled: boolean
  extraArgs: any[]
  image: Image
  ingress: InstanceID
  loadBalancerIP: string
  loadBalancerSourceRanges: any[]
  name: string
  nodeSelector: Record<string, string>
  pdb: InstanceID
  podAnnotations: Affinity
  podLabels: Affinity
  podSecurityContext: Affinity
  priorityClassName: string
  replicas: number
  resources: Affinity
  secure: boolean
  serviceAccount: string
  serviceAccountAnnotations: Affinity
  serviceAnnotations: Affinity
  serviceLabels: Affinity
  servicePort: number
  serviceType: string
  sso: null
  tolerations: any[]
  volumeMounts: any[]
  volumes: any[]
}

interface ClusterWorkflowTemplates {
  enableEditing: boolean
}

interface Workflow {
  namespace: string
  rbac: Rbac
  serviceAccount: ServiceAccount
}

interface Rbac {
  create: boolean
}

interface ServiceAccount {
  annotations: Record<string, string>
  create: boolean
  name: string
}

interface Hook {
  name: string
  kind: string
  path: string
  manifest: string
  events: string[]
  last_run: LastRun
  delete_policies: string[]
}

interface LastRun {
  started_at: string
  completed_at: string
  phase: string
}

interface Info {
  first_deployed: string
  last_deployed: string
  deleted: string
  description: string
  status: string
  notes: string
}
