import { complement, isNil, propSatisfies } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { findClusterName } from 'k8s/util/helpers'
import { ICsiDriverSelector } from './model'
import { persistentVolumeSelector } from '../persistent-volume/selectors'
import { IPersistentVolumeSelector } from '../persistent-volume/model'
import { IPersistentVolumeClaimsSelector } from '../persistent-volume-claims/model'
import { pvcByPVSelector } from '../persistent-volume-claims/selectors'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'

interface VolumesByDriver {
  pvs: IPersistentVolumeSelector[]
  pvcs: IPersistentVolumeClaimsSelector[]
}

export const pvAndPvcsByCsiDriverSelector = createSharedSelector(
  persistentVolumeSelector,
  pvcByPVSelector,
  (pvs, pvcByPv) => {
    const pvsByDriverName = new Map<string, VolumesByDriver>()
    for (const pv of pvs) {
      const driverName = pv?.spec?.csi?.driver
      if (driverName) {
        if (!pvsByDriverName.has(driverName)) {
          pvsByDriverName.set(driverName, { pvs: [], pvcs: [] })
        }
        const current = pvsByDriverName.get(driverName)
        if (pvcByPv.has(pv?.metadata?.name)) {
          current.pvcs = [].concat(current.pvcs, pvcByPv.get(pv.metadata.name))
        }
        current.pvs = [].concat(current.pvs, pv)
        pvsByDriverName.set(driverName, current)
      }
    }
    return pvsByDriverName
  },
)

export const csiDriversSelector = createSharedSelector(
  getDataSelector<DataKeys.CSIDrivers>(DataKeys.CSIDrivers, ['clusterId']),
  pvAndPvcsByCsiDriverSelector,
  allClustersSelector,
  (rawCSIDrivers, pvsByDriverName, allClusters): ICsiDriverSelector[] =>
    rawCSIDrivers
      .map((csiDriver) => ({
        ...csiDriver,
        clusterName: findClusterName(allClusters, csiDriver.clusterId),
        type: csiDriver?.parameters?.type,
        persistentVolumes: pvsByDriverName.get(csiDriver?.name)?.pvs || [],
        persistentVolumeClaims: pvsByDriverName.get(csiDriver?.name)?.pvcs || [],
      }))
      .filter(propSatisfies(complement(isNil), 'clusterName')),
)
