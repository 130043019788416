import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown'
import TextField from 'core/components/validatedForm/TextField'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import { SecretTypes } from './model'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { topMiddle } from 'core/elements/menu/defaults'
import Text from 'core/elements/Text'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import { keyValueArrToObj, objToKeyValueArr } from 'utils/fp'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    display: 'grid',
    gap: 24,
  },
  configurations: {
    display: 'grid',
    gap: 16,
  },
}))

const secretTypes = [
  { label: 'Opaque', value: SecretTypes.Opaque },
  { label: 'kubernetes.io/service-account-token', value: SecretTypes.ServiceAccountToken },
  { label: 'kubernetes.io/dockercfg', value: SecretTypes.DockerCfg },
  { label: 'kubernetes.io/dockerconfigjson', value: SecretTypes.DockerConfigJson },
  { label: 'kubernetes.io/basic-auth', value: SecretTypes.BasicAuth },
  { label: 'kubernetes.io/ssh-auth', value: SecretTypes.SshAuth },
  { label: 'kubernetes.io/tls', value: SecretTypes.Tls },
  { label: 'bootstrap.kubernetes.io/token', value: SecretTypes.BootstrapToken },
]

export default function SecretParamFields({ params, updateParams }) {
  const classes = useStyles()

  return (
    <>
      <TextField
        id="name"
        label="Name"
        value={params?.name}
        onChange={(value) => updateParams({ name: value })}
        required
      />
      <DropdownField
        DropdownComponent={NamespacePicklist}
        id="namespace"
        label="Namespace"
        clusterId={params?.clusterId}
        value={params?.namespace}
        onChange={(value) => updateParams({ namespace: value })}
        selectFirst={false}
        showAll={false}
        required
      />
      <DropdownField
        DropdownComponent={Dropdown}
        id="type"
        label="Type"
        value={params?.type}
        onChange={(value) => updateParams({ type: value })}
        items={secretTypes}
      />
      <ToggleSwitchField
        id="immutable"
        label="Immutable"
        value={params?.immutable}
        onChange={(value) => updateParams({ immutable: value })}
        info="Immutable, if set to true, ensures that data stored in the Secret cannot be updated (only object metadata can be modified)"
        infoPlacement={{ align: topMiddle.align }}
      />
      <div>
        <KeyValuesField
          id="data"
          label="Add Data"
          value={objToKeyValueArr(params?.data)}
          onChange={(value) => updateParams({ data: keyValueArrToObj(value) })}
          addLabel="Add key/value"
          allowMultipleValues
        />
      </div>
      <div>
        <KeyValuesField
          id="stringData"
          label="Add String Data"
          value={objToKeyValueArr(params?.stringData)}
          onChange={(value) => updateParams({ stringData: keyValueArrToObj(value) })}
          addLabel="Add key/value"
          allowMultipleValues
        />
      </div>
    </>
  )
}
