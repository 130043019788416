import React, { useMemo, useState } from 'react'
import Text from 'core/elements/Text'
import SimpleLink from 'core/components/SimpleLink'
import SubmitButton from 'core/components/buttons/SubmitButton'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import CodeBlock from 'core/components/CodeBlock'
import CopyToClipboard from 'core/components/CopyToClipboard'
import Info from 'core/components/validatedForm/Info'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import BulletList from 'core/components/BulletList'
import useScopedPreferences from '../../../../core/session/useScopedPreferences'
import { onboardClusterTracking } from './tracking'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import useListAction from 'core/hooks/useListAction'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import Progress from 'core/components/progress/Progress'
import { UserInfo } from 'app/plugins/infrastructure/components/nodes/DownloadCliWalkthrough'
import { ovaInstallationSteps } from 'app/plugins/infrastructure/components/nodes/download-ova-walkthrough'
import { Menu, MenuItem } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  linkText: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  spaceAbove: {
    marginTop: theme.spacing(2),
  },
  downloadIcon: {
    marginLeft: theme.spacing(1),
  },
  downloadButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  userInfo: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}))

const downloadOptions = [
  {
    label: 'VirtualBox OVA',
    link: 'https://pmkft-assets.s3.us-west-1.amazonaws.com/OVA_Images/Platform9_VirtualBox.ova',
  },
  {
    label: 'VMware OVA',
    link: 'https://pmkft-assets.s3.us-west-1.amazonaws.com/OVA_Images/Platform9_vmware.ova',
  },
]

const StepTwo = <Text>{ovaInstallationSteps[1].text}</Text>
const StepThree = (
  <>
    <Text>{ovaInstallationSteps[2].text}</Text>

    <CopyToClipboard copyText={ovaInstallationSteps[2].command}>
      <CodeBlock>{<Text variant="body2">{ovaInstallationSteps[2].command}</Text>}</CodeBlock>
    </CopyToClipboard>
  </>
)
const StepFour = <Text>{ovaInstallationSteps[3].text}</Text>

const DownloadOvaWalkthrough = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const selectSessionState = prop<string, SessionState>(sessionStoreKey)
  const session = useSelector(selectSessionState)
  const { getUserPrefs } = useScopedPreferences()
  const { currentTenant, currentRegion } = getUserPrefs(session.username)

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  const tenant = useMemo(() => tenants.find((tenant) => tenant.id === currentTenant), [tenants])

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLinkClick = (label) => {
    handleMenuClose()
    onboardClusterTracking.wZOvaDownload(label)
  }

  const StepOne = useMemo(
    () => (
      <>
        <Text>Download the OVA</Text>
        <SubmitButton className={classes.downloadButton} onClick={handleDownloadClick}>
          Download
          <FontAwesomeIcon className={classes.downloadIcon} size="sm" solid>
            download
          </FontAwesomeIcon>
        </SubmitButton>
        <Menu
          id="download-options"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {downloadOptions.map((option) => (
            <SimpleLink src={option.link} key={option.label}>
              <MenuItem onClick={() => handleLinkClick(option.label)}>{option.label}</MenuItem>
            </SimpleLink>
          ))}
        </Menu>
      </>
    ),
    [anchorEl],
  )

  const downloadSteps = [StepOne, StepTwo, StepThree, StepFour]

  return (
    <Progress loading={loadingTenants} renderContentOnMount={!loadingTenants}>
      <Info title="Download and start the OVA image">
        <BulletList type="decimal" items={downloadSteps} />
        <div className={classes.userInfo}>
          <UserInfo
            classes={classes}
            username={session?.username}
            tenantName={tenant?.name}
            currentRegion={currentRegion}
          />
        </div>
      </Info>
    </Progress>
  )
}

export default DownloadOvaWalkthrough
