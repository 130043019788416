import ClusterStatusSpan from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import { IClusterStatus } from 'app/plugins/infrastructure/components/clusters/model'
import { isEmpty } from 'ramda'
import React from 'react'
import { memoize } from 'utils/misc'
import { BadgeVariant } from 'core/elements/badge/Badge'

export const getPodsMounted = memoize((pvc, allPods = []) => {
  const pods = allPods.filter(
    (pod) =>
      !!pod?.spec?.volumes?.find((vol) => vol?.persistentVolumeClaim?.claimName === pvc?.name),
  )
  if (isEmpty(pods)) return []

  // Get mount paths for all containers in all pods
  const mounts = []
  for (const pod of pods) {
    const containers = pod?.spec?.containers || []
    const volumes = pod?.spec?.volumes || []
    const volumesMap = {}
    volumes.forEach((v) => {
      volumesMap[v?.name] = v
    })
    for (const container of containers) {
      const volumeMounts = container?.volumeMounts || []
      for (const vm of volumeMounts) {
        if (volumesMap[vm?.name]?.persistentVolumeClaim?.claimName) {
          mounts.push({
            pod: pod?.name,
            container: container?.name,
            path: vm?.mountPath,
          })
        }
      }
    }
  }
  return mounts
})

export const renderPvcStatus = (phase) => {
  let status: IClusterStatus = 'ok'
  switch (phase) {
    case 'Available':
    case 'Bound':
      status = 'ok'
      break
    case 'Released':
      status = 'pause'
      break
    case 'Pending':
      status = 'pending'
      break
    case 'Failed':
      status = 'fail'
      break
  }
  return (
    <ClusterStatusSpan title={phase} status={status}>
      {phase}
    </ClusterStatusSpan>
  )
}

export const getPvcStatus = (phase) => {
  let status: BadgeVariant = 'success'
  switch (phase) {
    case 'Available':
    case 'Bound':
      status = 'success'
      break
    case 'Released':
      status = 'unknown'
      break
    case 'Pending':
      status = 'warning'
      break
    case 'Failed':
      status = 'error'
      break
  }
  return { variant: status }
}
