import React, { useCallback } from 'react'
import { INetworkPluginSelector, NetworkPluginArrayItem } from '../model'
import Text from 'core/elements/Text'
import ModalForm from 'core/elements/modal/ModalForm'
import { omit } from 'ramda'
import { updateNetworkPlugin } from '../actions'
import useUpdateAction from 'core/hooks/useUpdateAction'

const pluginPropertyMap = {
  'Host Plumber': 'hostPlumber',
  // We do not want them to remove multus
  // 'Multus CNI': 'multus',
  'Node Feature Discovery': 'nodeFeatureDiscovery',
  'Platform9 DHCP': 'dhcpController',
  Whereabouts: 'whereabouts',
  OVS: 'ovs',
  'SR-IOV': 'sriov',
}

interface Props {
  networkPlugin: INetworkPluginSelector
  selectedPlugin: NetworkPluginArrayItem
  onClose: (success?: boolean) => void
}

export default function DisablePluginDialog({ networkPlugin, selectedPlugin, onClose }: Props) {
  const { update: updateFn, updating, error, reset } = useUpdateAction(updateNetworkPlugin)

  const handleClose = () => {
    reset()
    onClose()
  }

  const submitForm = useCallback(async () => {
    const pluginProperty = pluginPropertyMap[selectedPlugin?.name]
    const body = omit([pluginProperty], networkPlugin?.spec?.plugins)

    const ops = []
    ops.push({
      op: 'replace',
      path: '/spec/plugins',
      value: body,
    })

    const { success } = await updateFn({
      ...networkPlugin,
      body: ops,
      requestType: 'patch',
      contentType: 'application/json-patch+json',
    })
    if (success) {
      handleClose()
    }
  }, [networkPlugin, handleClose])

  return (
    <ModalForm
      open
      onClose={onClose}
      panel="dialog"
      title={`Disable ${selectedPlugin?.name}`}
      submitTitle="Disable"
      onSubmit={submitForm}
      submitting={updating}
      error={error}
    >
      <Text variant="body2">This plugin will be disabled.</Text>
    </ModalForm>
  )
}
