import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { routes } from 'core/utils/routes'
import { deleteIngress } from 'k8s/components/ingresses/new-actions'
import usePluginRouter from 'core/hooks/usePluginRouter'

export default function DeleteIngressDialog({ rows: [ingress], onClose }) {
  const { history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const { update: deleteFn, updating: deletingIngress } = useUpdateAction(deleteIngress)

  const handleDelete = useCallback(async () => {
    await deleteFn(ingress)
    onClose(true)
    history.push(routes[routePath].ingresses.list.path())
  }, [ingress, routePath])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={`Remove Ingress "${ingress?.name}" ?`}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingIngress}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingIngress}>
            Delete
          </Button>
        </>
      }
    >
      <Text variant="body2">Your data will be lost since this action cannot be undone.</Text>
    </Modal>
  )
}
