import React from 'react'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'

interface PDTypePicklistProps extends Omit<DropdownProps<string>, 'items'> {
  formField?: boolean
}

const defaultOptions = [
  { label: 'pd-standard', value: 'pd-standard' },
  { label: 'pd-ssd', value: 'pd-ssd' },
]
export default function PDTypePicklist({
  name = 'pdList',
  label = 'PD List',
  formField = false,
  ...props
}: PDTypePicklistProps) {
  return (
    <AsyncDropdown
      {...props}
      name={name}
      label={label}
      formField={formField}
      items={defaultOptions}
    />
  )
}
