import { IPodSelector } from 'k8s/components/pods/model'

export enum IDaemonSetDetailsPageTabs {
  Overview = 'overview',
  Pods = 'pods',
  Events = 'events',
  Yaml = 'yaml',
  Logs = 'logs',
}

export interface IDaemonSetSelector {
  id: string
  name: string
  clusterId: string
  clusterName: string
  namespace: string
  selector: Record<string, string>
  pods: IPodSelector[]
  initContainers: InitContainer[]
  containers: Container[]
  volumes: Volume[]
  age: string
  creationTimestamp: string
  labels: Record<string, string>
  annotations: Record<string, string>
  podTemplate: Template
  nodeSelector: Record<string, string>
  numberReady: number
  numberAvailable: number
  numberMisscheduled: number
  desiredNumberScheduled: number
  currentNumberScheduled: number
  updatedNumberScheduled: number
  tolerations: Toleration[]
}

export interface DaemonSet {
  kind: string
  apiVersion: string
  metadata: ItemMetadata
  spec: ItemSpec
  status: Status
}

export interface GetDaemonSetsReponse {
  kind: string
  apiVersion: string
  metadata: GetDaemonSetsReponseMetadata
  items: DaemonSetItem[]
}

export interface DaemonSetItem {
  metadata: ItemMetadata
  spec: ItemSpec
  status: Status
}

interface ItemMetadata {
  name: string
  namespace: string
  selfLink: string
  uid: string
  resourceVersion: string
  generation: number
  creationTimestamp: string
  labels: Record<string, string>
  annotations: Record<string, string>
}
interface ItemSpec {
  selector: Selector
  template: Template
  updateStrategy: UpdateStrategy
  revisionHistoryLimit: number
}

interface Selector {
  matchLabels: Record<string, string>
}

interface Template {
  metadata: TemplateMetadata
  spec: TemplateSpec
}

interface TemplateMetadata {
  creationTimestamp: null
  labels: Record<string, string>
  annotations?: Record<string, string>
}

interface TemplateSpec {
  volumes: Volume[]
  initContainers?: InitContainer[]
  containers: Container[]
  restartPolicy: string
  terminationGracePeriodSeconds: number
  dnsPolicy: string
  nodeSelector: Record<string, string>
  serviceAccountName: string
  serviceAccount: string
  hostNetwork: boolean
  securityContext: SpecSecurityContext
  schedulerName: string
  tolerations: Toleration[]
  priorityClassName?: string
  hostPID?: boolean
}

interface Container {
  name: string
  image: string
  env?: Env[]
  resources: Resources
  volumeMounts: ContainerVolumeMount[]
  livenessProbe?: LivenessProbeClass
  readinessProbe?: LivenessProbeClass
  terminationMessagePath: string
  terminationMessagePolicy: string
  imagePullPolicy: string
  securityContext?: ContainerSecurityContext
  args?: string[]
  ports?: Port[]
}

interface Env {
  name: string
  value?: string
  valueFrom?: ValueFrom
}

interface ValueFrom {
  configMapKeyRef?: ConfigMapKeyRef
  fieldRef?: FieldRef
}

interface ConfigMapKeyRef {
  name: string
  key: string
}

interface FieldRef {
  apiVersion: string
  fieldPath: string
}

interface LivenessProbeClass {
  exec: Exec
  initialDelaySeconds?: number
  timeoutSeconds: number
  periodSeconds: number
  successThreshold: number
  failureThreshold: number
}

interface Exec {
  command: string[]
}

interface Port {
  name: string
  hostPort: number
  containerPort: number
  protocol: string
}

interface Resources {
  requests: Limits
  limits?: Limits
}

interface Limits {
  cpu: string
  memory?: string
}

interface ContainerSecurityContext {
  privileged: boolean
}

interface ContainerVolumeMount {
  name: string
  readOnly?: boolean
  mountPath: string
  mountPropagation?: string
}

interface InitContainer {
  name: string
  image: string
  command?: string[]
  env?: Env[]
  resources: {}
  volumeMounts: InitContainerVolumeMount[]
  terminationMessagePath: string
  terminationMessagePolicy: string
  imagePullPolicy: string
  securityContext: ContainerSecurityContext
}

interface InitContainerVolumeMount {
  name: string
  mountPath: string
}

interface SpecSecurityContext {
  runAsUser?: number
  runAsNonRoot?: boolean
}

interface Toleration {
  operator: string
  effect?: string
  key?: string
  value?: string
}

interface Volume {
  name: string
  hostPath: HostPath
}

interface HostPath {
  path: string
  type: HostPathType
}

enum HostPathType {
  DirectoryOrCreate = 'DirectoryOrCreate',
  Empty = '',
  FileOrCreate = 'FileOrCreate',
}

interface UpdateStrategy {
  type: string
  rollingUpdate: RollingUpdate
}

interface RollingUpdate {
  maxUnavailable: number
}

interface Status {
  currentNumberScheduled: number
  numberMisscheduled: number
  desiredNumberScheduled: number
  numberReady: number
  observedGeneration: number
  updatedNumberScheduled: number
  numberAvailable: number
}

interface GetDaemonSetsReponseMetadata {
  selfLink: string
  resourceVersion: string
}
