import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { findCluster } from 'k8s/util/helpers'
import { durationBetweenDates } from 'utils/misc'
import { IEndpointSelector } from './model'
import { isNilOrEmpty } from 'utils/fp'

export const k8sEndpointsSelector = createSharedSelector(
  getDataSelector<DataKeys.K8sEndpoints>(
    DataKeys.K8sEndpoints,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  (rawK8sEndpoints, allClusters): IEndpointSelector[] => {
    return rawK8sEndpoints.map((rawK8sEndpoint) => {
      const { clusterId, metadata } = rawK8sEndpoint
      const { name: clusterName, clusterType: clusterType } = findCluster(allClusters, clusterId)

      const endpoints = []

      if (!isNilOrEmpty(rawK8sEndpoint?.subsets)) {
        rawK8sEndpoint?.subsets?.forEach((subset) => {
          subset?.addresses.forEach((address) => {
            subset?.ports?.forEach((port) => {
              endpoints.push(`${address.ip}:${port.port}`)
            })
          })
        })
      }

      return {
        ...rawK8sEndpoint,
        endpoints,
        clusterName,
        clusterId,
        clusterType,
        age: durationBetweenDates({ labels: ['d'] })(metadata?.creationTimestamp),
        ownerReferences: metadata?.ownerReferences,
        creationTimestamp: metadata?.creationTimestamp,
        labels: metadata?.labels,
        annotations: metadata?.annotations,
      }
    })
  },
)
