import ListTable from 'core/components/listTable/ListTable'
import React, { useMemo, FC, useState, useCallback } from 'react'
import EditIcon from 'k8s/components/rbac/profiles/EditIcon'
import DeleteIcon from 'k8s/components/rbac/profiles/DeleteIcon'
import ViewIcon from 'k8s/components/rbac/profiles/ViewIcon'
import useStyles from 'k8s/components/rbac/profiles/edit/useStyles'
import { IRbacClusterRoleBinding } from 'k8s/components/rbac/model'
import useToggler from 'core/hooks/useToggler'
import Button from 'core/elements/button'
import EditClusterRoleBindingDialog from 'k8s/components/rbac/profiles/edit/EditClusterRoleBindingDialog'
import ViewClusterRoleBindingDialog from 'k8s/components/rbac/profiles/edit/ViewClusterRoleBindingDialog'
import { HeaderPrimaryActionPortal } from 'core/elements/header/portals'

const infoColumns = [{ id: 'metadata.name', label: 'Name' }]
const visibleColumns = ['metadata.name', 'apiAccess']
const columnsOrder = ['metadata.name', 'apiAccess']
const orderBy = 'metadata.name'
const orderDirection = 'asc'
const searchTargets = ['metadata.name']

interface Props {
  profileName: string
  clusterId: string
  clusterRoleBindings: IRbacClusterRoleBinding[]
  onAdd: (role: IRbacClusterRoleBinding) => void
  onUpdate: (role: IRbacClusterRoleBinding) => void
  onRemove: (role: IRbacClusterRoleBinding) => void
  readOnly?: boolean
}

const ClusterRoleBindingsList: FC<Props> = ({
  profileName,
  clusterId,
  clusterRoleBindings,
  onRemove,
  onUpdate,
  onAdd,
  readOnly = false,
}) => {
  /*****************************
   *      EDIT DIALOG
   *****************************/
  const [currentRow, setCurrentRow] = useState<IRbacClusterRoleBinding>()
  const [showingEditDialog, toggleEditDialog] = useToggler()
  const showEditDialog = useCallback((row: IRbacClusterRoleBinding) => {
    setCurrentRow(row)
    toggleEditDialog()
  }, [])
  const handleUpdate = useCallback(
    (clusterRoleBinding: IRbacClusterRoleBinding) => {
      onUpdate(clusterRoleBinding)
      toggleEditDialog()
    },
    [onUpdate],
  )

  /*****************************
   *        ADD DIALOG
   *****************************/
  const handleAdd = useCallback(() => {
    toggleAddDialog()
  }, [])
  const addClusterRoleBindingToProfile = useCallback(
    (newRole: IRbacClusterRoleBinding) => {
      toggleAddDialog()
      onAdd(newRole)
    },
    [onAdd],
  )
  const [showingAddDialog, toggleAddDialog] = useToggler()

  /*****************************
   *        VIEW DIALOG
   *****************************/
  const [showingViewDialog, toggleViewDialog] = useToggler()
  const showViewClusterRoleBindingDialog = useCallback((row: IRbacClusterRoleBinding) => {
    setCurrentRow(row)
    toggleViewDialog()
  }, [])

  /*****************************
   *    ROLE BINDINGS LIST
   *****************************/
  const classes = useStyles()

  const columns = useMemo(
    () =>
      readOnly
        ? [
            ...infoColumns,
            {
              id: 'apiAccess',
              render: (value, row) => (
                <ViewIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    showViewClusterRoleBindingDialog(row)
                  }}
                />
              ),
            },
          ]
        : [
            ...infoColumns,
            // Actions column
            {
              id: 'apiAccess',
              render: (value, row) => (
                <>
                  <EditIcon
                    onClick={(e) => {
                      e.stopPropagation()
                      showEditDialog(row)
                    }}
                  />
                  <DeleteIcon
                    onClick={(e) => {
                      e.stopPropagation()
                      onRemove(row)
                    }}
                  />
                </>
              ),
            },
          ],
    [onRemove, readOnly],
  )

  return (
    <>
      {!readOnly && (
        <HeaderPrimaryActionPortal>
          <Button className={classes.addBtn} onClick={handleAdd} icon="plus">
            Add New Cluster Role Binding
          </Button>
        </HeaderPrimaryActionPortal>
      )}
      {showingViewDialog && (
        <ViewClusterRoleBindingDialog
          onClose={toggleViewDialog}
          open={showingViewDialog}
          clusterRoleBinding={currentRow}
        />
      )}
      {showingAddDialog && (
        <EditClusterRoleBindingDialog
          clusterId={clusterId}
          profileName={profileName}
          onClose={toggleAddDialog}
          onSubmit={addClusterRoleBindingToProfile}
          open={showingAddDialog}
        />
      )}
      {currentRow && showingEditDialog && (
        <EditClusterRoleBindingDialog
          clusterId={clusterId}
          profileName={profileName}
          onClose={toggleEditDialog}
          onSubmit={handleUpdate}
          open={showingEditDialog}
          clusterRoleBinding={currentRow}
        />
      )}
      <ListTable
        compact
        className={classes.listTable}
        showPagination
        uniqueIdentifier={'metadata.name'}
        canEditColumns={false}
        searchTargets={searchTargets}
        showCheckboxes={false}
        data={clusterRoleBindings}
        columns={columns}
        visibleColumns={visibleColumns}
        columnsOrder={columnsOrder}
        orderBy={orderBy}
        orderDirection={orderDirection}
      />
    </>
  )
}

export default ClusterRoleBindingsList
