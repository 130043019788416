import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

export default makeStyles<Theme>((theme) => ({
  label: {
    backgroundColor: 'white',
    padding: '0 5px',
    margin: '0 -5px',
  },
  profileContainer: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: theme.spacing(0, -2),
  },
  rightSide: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    flexGrow: 1,
  },
  submitButton: {
    alignSelf: 'start',
    marginTop: 16,
  },
  tabs: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 1),
    background: theme.palette.grey['000'],
    padding: theme.spacing(2, 3, 8, 3),
    marginBottom: theme.spacing(2),
  },
  accordionTitle: {
    color: theme.palette.blue['700'],
    fontWeight: 'bold',
    '& >  span': {
      color: theme.palette.grey['700'],
      fontWeight: 'normal',
    },
  },
  listTable: {
    '& .MuiToolbar-root': {
      backgroundColor: 'transparent',
    },
  },
  addBtn: {
    order: -1,
  },
}))
