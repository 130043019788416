import { BadgeVariant } from 'core/elements/badge/Badge'

export const getNamespaceStatus = (phase) => {
  let status: BadgeVariant = 'unknown'

  switch (phase) {
    case 'Active':
      status = 'success'
      break

    case 'Terminating':
      status = 'error'
      break
  }
  return { variant: status, label: phase || 'N/A' }
}
