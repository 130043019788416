import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { findCluster } from 'k8s/util/helpers'
import { durationBetweenDates } from 'utils/misc'
import { ILimitRangeSelector, Limit } from './model'
import { isNilOrEmpty } from 'utils/fp'

const getLimitsObj = (limits: Limit[]) => {
  if (isNilOrEmpty(limits)) return []

  const limitsObj = limits.flatMap((limit) => {
    const { type } = limit
    const limits = new Map()

    const properties = ['default', 'min', 'max', 'defaultRequest', 'maxLimitRequestRatio']

    for (const property of properties) {
      if (limit[property]) {
        Object.entries(limit[property]).forEach(([resource, value]) => {
          const key = `${type}-${resource}`
          if (!limits.has(key)) {
            limits.set(key, {
              id: key,
              type,
              resource,
              default: undefined,
              min: undefined,
              max: undefined,
              defaultRequest: undefined,
              maxLimitRequestRatio: undefined,
            })
          }
          const obj = limits.get(key)
          obj[property] = value || obj[property]
        })
      }
    }

    return [...limits.values()]
  })
  return limitsObj
}

export const limitRangesSelector = createSharedSelector(
  getDataSelector<DataKeys.LimitRanges>(
    DataKeys.LimitRanges,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  (rawLimitRanges, allClusters): ILimitRangeSelector[] => {
    return rawLimitRanges.map((rawLimitRange) => {
      const { clusterId, metadata, spec } = rawLimitRange
      const { name: clusterName, clusterType: clusterType } = findCluster(allClusters, clusterId)

      const limitsObj = getLimitsObj(spec?.limits)
      return {
        ...rawLimitRange,
        limitsObj,
        clusterName,
        clusterId,
        clusterType,
        age: durationBetweenDates({ labels: ['d'] })(metadata?.creationTimestamp),
        ownerReferences: metadata?.ownerReferences,
        creationTimestamp: metadata?.creationTimestamp,
        labels: metadata?.labels,
        annotations: metadata?.annotations,
      }
    })
  },
)
