import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import ApiClient from 'api-client/ApiClient'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import { routes } from 'core/utils/routes'
import { isEmpty } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import { listNetworks, updateNetwork } from '../actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { networksSelector } from '../selectors'
import { clientActions } from 'core/client/clientReducers'
import {
  INetworkAttachmentDefinitionsSelector,
  INetworkAttachmentDefinitionsDetailsPageTabs,
} from '../model'
import Overview from './Overview'
import DeleteNetworkAttachmentDefinitionsDialog from '../DeleteNetworkAttachmentDefinitionsDialog'
import EditNetworkAttachmentDefinitionsModal from '../EditNetworkAttachmentDefinitionsModal'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import ActionsDropdown from 'core/elements/overview/ActionsDropdown'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'

const { qbert } = ApiClient.getInstance()

const useStyles = makeStyles<Theme>((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const dropdownActions = [
  {
    label: 'Edit',
    icon: 'pen-to-square',
    DialogComponent: EditNetworkAttachmentDefinitionsModal,
  },
  {
    label: 'Delete',
    icon: 'trash-alt',
    DialogComponent: DeleteNetworkAttachmentDefinitionsDialog,
  },
]

export default function NetworkAttachmentDefinitionsDetailsPage() {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { globalParams: params } = useGlobalParams(useParams, {})

  const { loading } = useListAction(listNetworks, { params })
  const networks = useSelectorWithParams(networksSelector, {
    clusterId,
    useGlobalParams: false,
  })

  const network = useMemo(() => networks.find((network) => network.id === id), [id, networks])
  const { update, updating } = useUpdateAction(updateNetwork)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: network?.clusterName || clusterId,
        id: network?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [network?.clusterName, network?.name, id, clusterId])

  const getNetworkYaml = useCallback(async () => {
    if (isEmpty(network)) return undefined
    return qbert.getNetwork(network.clusterId, network.namespace, network.name)
  }, [network])

  const handleNetworkUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Network Attachment Definition" breadcrumbs />
      <div className={classes.actionsBar}>
        <ActionsDropdown<INetworkAttachmentDefinitionsSelector>
          actions={dropdownActions}
          entity={network}
        />
      </div>
      <Tabs route={routes[routePath].networkDetails}>
        <Tab label="Overview" value={INetworkAttachmentDefinitionsDetailsPageTabs.Overview}>
          <Overview network={network} loading={loading} />
        </Tab>
        <Tab label="YAML" value={INetworkAttachmentDefinitionsDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Network Attachment Definitions"
            entityName={network?.name}
            getYamlFn={getNetworkYaml}
            handleUpdate={handleNetworkUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}
