import React, { useMemo } from 'react'
import { projectAs } from 'utils/fp'
import { uniqBy, prop } from 'ramda'
import { allKey } from 'app/constants'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import useListAction from 'core/hooks/useListAction'
import { listClusterRoles, listRoles } from './new-actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { rolesSelector } from './selectors'
import { clusterRolesSelector } from './selectors'

interface Props {
  clusterId?: string
  namespace?: string
  showAllRoleTypes?: boolean
}

export default function RolePicklist({
  clusterId = allKey,
  namespace = allKey,
  loading,
  onChange,
  showAllRoleTypes,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading: rolesLoading } = useListAction(listRoles, {
    params: { clusterId, namespace },
    requiredParams: ['clusterId', 'namespace'],
  })
  const roles = useSelectorWithParams(rolesSelector, {
    clusterId,
    namespace,
    useGlobalParams: false,
  })

  const { loading: clusterRolesLoading } = useListAction(listClusterRoles, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const clusterRoles = useSelectorWithParams(clusterRolesSelector, {
    clusterId,
    useGlobalParams: false,
  })

  const items = useMemo(() => {
    const _roles = projectAs(
      { label: 'pickerLabel', value: 'pickerValue' },
      uniqBy(prop('pickerValue'), roles),
    )
    const _clusterRoles = projectAs(
      { label: 'pickerLabel', value: 'pickerValue' },
      uniqBy(prop('pickerValue'), clusterRoles),
    )
    return showAllRoleTypes ? [..._roles, ..._clusterRoles] : _clusterRoles
  }, [roles, clusterRoles])

  return (
    <AsyncDropdown
      name="roleId"
      label="Role"
      selectFirst
      onChange={onChange}
      loading={loading || rolesLoading || clusterRolesLoading}
      items={items}
      {...rest}
    />
  )
}
