import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import { trackEvent } from 'utils/tracking'
import { routes } from 'core/utils/routes'
import Bugsnag from 'utils/bugsnag'
import { deleteJob, deleteCronjob } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'

const DeleteJobDialog = ({ rows: [job], onClose }) => {
  const { history } = useReactRouter()
  const { update: deleteCronjobFn, updating: deletingCronjob } = useUpdateAction(deleteCronjob)
  const { update: deleteJobFn, updating: deletingJob } = useUpdateAction(deleteJob)
  const title = `Delete Job "${job?.name}"?`
  const handleDelete = useCallback(async () => {
    const deleteFn = job?.type === 'job' ? deleteJobFn : deleteCronjobFn
    Bugsnag.leaveBreadcrumb(`Attempting to delete ${job.type}`, {
      cluster_uuid: job?.clusterId,
      name: job?.name,
      namespace: job?.namespace,
    })
    await deleteFn(job)
    trackEvent(`Delete ${job.type}`, {
      cluster_uuid: job?.clusterId,
      name: job?.name,
      namespace: job?.namespace,
    })
    onClose(true)
    history.push(routes.cronjobs.list.path())
  }, [job])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingCronjob || deletingJob}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingCronjob || deletingJob}>
            Delete
          </Button>
        </>
      }
    >
      <Text variant="body2">
        Please confirm you want to delete this {job.type}. This action cannot be undone.
      </Text>
    </Modal>
  )
}

export default DeleteJobDialog
