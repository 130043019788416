import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ListTable from 'core/components/listTable/ListTable'
import ClusterRoleBindingAnalysisDialog from './ClusterRoleBindingAnalysisDialog'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { filterByOperation, renderOp } from './helpers'
import DriftResourceSummary from './DriftResourceSummary'
import { allKey } from 'app/constants'
import OperationsPicklist from './OperationsPicklist'

const useStyles = makeStyles((theme: Theme) => ({
  inspectIcon: {
    color: theme.palette.blue[500],
    cursor: 'pointer',
  },
}))

const InspectClusterRoleBindingButton = ({ clusterRoleBinding }) => {
  const classes = useStyles({})
  const [dialogOpened, setDialogOpened] = useState(false)

  return (
    <>
      <FontAwesomeIcon className={classes.inspectIcon} onClick={() => setDialogOpened(true)}>
        search-plus
      </FontAwesomeIcon>
      {dialogOpened && (
        <ClusterRoleBindingAnalysisDialog
          clusterRoleBinding={clusterRoleBinding}
          onClose={() => setDialogOpened(false)}
        />
      )}
    </>
  )
}

const renderInspectButton = (_, clusterRoleBinding) => (
  <InspectClusterRoleBindingButton clusterRoleBinding={clusterRoleBinding} />
)

const columns = [
  { id: 'op', label: '', render: renderOp },
  { id: 'name', label: 'Name' },
  { id: 'path', label: '', render: renderInspectButton },
]

const ClusterRoleBindingAnalysis = ({ clusterRoleBindings, isImpactAnalysis, clusterId }) => {
  const [operation, setOperation] = useState(allKey)
  const mappedClusterRoleBindings = clusterRoleBindings.map((clusterRoleBinding) => ({
    ...clusterRoleBinding,
    clusterId,
  }))
  const filteredClusterRoleBindings = filterByOperation(mappedClusterRoleBindings, operation)

  return (
    <>
      <DriftResourceSummary
        resourceType="Cluster Role Bindings"
        resources={clusterRoleBindings}
        isImpactAnalysis={isImpactAnalysis}
      />
      <div>
        <ListTable
          filters={
            <OperationsPicklist
              onChange={(value) => setOperation(value)}
              value={operation}
              isImpactAnalysis={isImpactAnalysis}
            />
          }
          showCheckboxes={false}
          searchTargets={['name']}
          columns={columns}
          data={filteredClusterRoleBindings}
          rowsPerPage={10}
          uniqueIdentifier="path"
        />
      </div>
    </>
  )
}

export default ClusterRoleBindingAnalysis
