import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { AppSelector } from 'app/store'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { complement, isNil, propSatisfies } from 'ramda'
import { findClusterName } from 'k8s/util/helpers'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { durationBetweenDates } from 'utils/misc'
import { INetworkAttachmentDefinitionsSelector } from './model'

export const networksSelector: AppSelector<INetworkAttachmentDefinitionsSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.Networks>(DataKeys.Networks, ['clusterId'], ['clusterId', 'namespace']),
  clustersSelector, // Currently only bareos clusters may have luigi addons
  (networks, clusters): INetworkAttachmentDefinitionsSelector[] => {
    return networks
      .map((network) => {
        const configObj = network?.spec?.config ? JSON.parse(network?.spec?.config) : {}

        return {
          ...network,
          id: network?.metadata?.uid,
          clusterName: findClusterName(clusters, network?.clusterId),
          age: durationBetweenDates({ labels: ['d'] })(network?.created),
          type: configObj?.type,
          networkType:
            configObj?.type === 'sriov'
              ? !!configObj?.trust
                ? 'sriovDpdk'
                : 'sriov'
              : configObj?.type === 'userspace'
              ? 'ovsDpdk'
              : configObj?.type,
          ipRange:
            configObj?.ipam?.range_start && configObj?.ipam?.range_end
              ? `${configObj?.ipam?.range_start} - ${configObj?.ipam?.range_end}`
              : configObj?.ipam?.range
              ? configObj?.ipam?.range
              : undefined,
          ipamType: configObj?.ipam?.type,
        }
      })
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)
