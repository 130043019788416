import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import AlarmDetailsDialog from './AlarmDetailsDialog'
import { IAlertSelector } from './model'

interface Props {
  alarm: IAlertSelector
  display: string
}

const useStyles = makeStyles<Theme>((theme) => ({
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

const AlarmDetailsLink = ({ display, alarm }: Props) => {
  const [dialogOpened, setDialogOpened] = useState(false)
  const { link } = useStyles({})
  const handleToggleDialogOpened = (e) => {
    // this link is clicked inside the table,
    // we need to prevent bubbling the event so the table selection doesn't happen
    e.stopPropagation()
    setDialogOpened(!dialogOpened)
  }
  return (
    <>
      {dialogOpened && <AlarmDetailsDialog alarm={alarm} onClose={handleToggleDialogOpened} />}
      <span className={link} onClick={handleToggleDialogOpened}>
        {display}
      </span>
    </>
  )
}

export default AlarmDetailsLink
