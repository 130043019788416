import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { IIngressSelector, IRule } from 'k8s/components/ingresses/model'
import Progress from 'core/components/progress/Progress'
import SummaryCard from 'k8s/components/common/entity/summary-card'
import InfoCard from 'k8s/components/common/entity/info-card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import { renderAge } from 'k8s/components/common/entity/helpers'
import Card from 'core/elements/card'
import { OwnerReferences } from 'k8s/components/configuration/config-maps/details/ConfigMapsOverview'
import Grid, { GridViewColumn } from 'core/elements/grid/Grid'

const summaryRows: { key: keyof IIngressSelector; label: string }[] = [
  { key: 'clusterName', label: 'Cluster' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'age', label: 'Age' },
]

const rulesColumns: GridViewColumn<IRule>[] = [
  { key: 'host', label: 'Host' }, //  width: 'medium'
  { key: 'path', label: 'Path' },
  { key: 'pathType', label: 'Path Type' },
  { key: 'serviceName', label: 'Service' },
  { key: 'servicePort', label: 'Service Port' },
]

const IngressesOverview = ({
  ingress,
  loading,
  reload,
}: {
  ingress: IIngressSelector
  loading: boolean
  reload: (refetch: boolean) => void
}) => {
  const classes = useStyles()

  const metadataFields = [
    {
      id: 'age',
      title: 'Created',
      required: true,
      render: renderAge,
    },
    {
      id: 'ownerReferences',
      title: 'Owner References',
      render: (value) => <OwnerReferences refs={value} />,
      condition: ({ ownerReferences }) => ownerReferences?.length > 0,
    },
  ]

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, ingress)
  }, [ingress])

  return (
    <Progress loading={loading}>
      <div className={classes.container}>
        <div className={classes.column}>
          <SummaryCard<IIngressSelector>
            title={ingress?.name}
            subtitle="Ingress"
            rows={summaryRows}
            data={ingress}
          />
          <InfoCard items={metadata} title="Properties" />
        </div>
        <div className={classes.column}>
          <Card>
            <LabelsAndAnnotationsSection entity={ingress} resourceType="ingress" />
          </Card>
        </div>
        <div className={classes.columnBoth}>
          <Card title="Rules" withCustomBody>
            <Grid
              uniqueIdentifier="host"
              data={ingress?.rules || []}
              columns={rulesColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutosummaryRows: 'max-content',
  },
  columnBoth: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutosummaryRows: 'max-content',
    gridColumn: 'span 2',
  },
  footer: {
    margin: theme.spacing(1),
  },
  copyClipboard: {
    '& .copy': {
      color: theme.components.typography.passive,
    },
  },
}))

export default IngressesOverview
