import React, { useMemo } from 'react'
import { projectAs } from 'utils/fp'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { listCSIDrivers } from './new-actions'
import useListAction from 'core/hooks/useListAction'
import { csiDriversSelector } from './selectors'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'

interface Props {
  clusterId: string
}

export default function CSIDriversPicklist({
  clusterId,
  name = 'csiDriversList',
  label = 'CSI Drivers',
  formField = false,
  ...props
}: PropsWithAsyncDropdown<Props>) {
  const { loading } = useListAction(listCSIDrivers, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const csiDrivers = useSelectorWithParams(csiDriversSelector, { clusterId })
  const items = useMemo(() => projectAs({ value: 'name', label: 'name' }, csiDrivers), [csiDrivers])
  return (
    <AsyncDropdown
      {...props}
      loading={loading}
      name={name}
      label={label}
      formField={formField}
      items={items}
    />
  )
}
