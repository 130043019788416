import { withStyles } from '@material-ui/styles'
import {
  AccordionDetails as MuiAccordionDetails,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
} from '@material-ui/core'

export const Accordion = withStyles((theme) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderWidth: '1px 0',
    borderColor: theme.palette.grey['500'],
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    // '&$expanded': {
    //   margin: 'auto',
    // },
  },
  expanded: {},
}))(MuiAccordion)

export const AccordionSummary = withStyles((theme) => ({
  root: {
    flexFlow: 'row-reverse nowrap',
    backgroundColor: 'rgb(255, 255, 255)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    padding: 0,
    '&$expanded': {
      minHeight: 56,
    },
    '& .MuiAccordionSummary-expandIcon': {
      marginRight: theme.spacing(2),
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary)

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
}))(MuiAccordionDetails)
