import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import useReactRouter from 'use-react-router'
import useListAction from 'core/hooks/useListAction'
import { listResourceQuotas, updateResourceQuota } from '../actions'
import { IResourceQuotaDetailsPageTabs, IResourceQuotaSelector } from '../model'
import { resourceQuotasSelector } from '../selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import { routes } from 'core/utils/routes'
import ResourceQuotasOverview from './ResourceQuotasOverview'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import { isEmpty } from 'ramda'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import OverviewActions from 'core/elements/overview/OverviewActions'
import DeleteResourceQuotaDialog from '../DeleteResourceQuotaDialog'
import usePluginRouter from 'core/hooks/usePluginRouter'

const { qbert } = ApiClient.getInstance()

const actions = {
  rightActions: [
    {
      label: 'Delete',
      icon: 'trash-alt',
      DialogComponent: DeleteResourceQuotaDialog,
    },
  ],
}

export default function ResourceQuotasDetailsPage() {
  const dispatch = useDispatch()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtConfiguration' : 'configuration'
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { loading, reload } = useListAction(listResourceQuotas, { params: { clusterId } })
  const resourceQuotas: IResourceQuotaSelector[] = useSelector(resourceQuotasSelector)
  const resourceQuota = useMemo(
    () => resourceQuotas.find((resourceQuota) => resourceQuota.id === id),
    [id, resourceQuotas],
  )

  const { update, updating } = useUpdateAction(updateResourceQuota)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: resourceQuota?.clusterName || clusterId,
        id: resourceQuota?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [resourceQuota?.clusterName, resourceQuota?.name, id, clusterId])

  const getResourceQuotaYaml = useCallback(async () => {
    if (isEmpty(resourceQuota)) return undefined
    return qbert.getResourceQuota(
      resourceQuota.clusterId,
      resourceQuota.namespace,
      resourceQuota.name,
    )
  }, [resourceQuota])

  const handleResourceQuotaUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Resource Quotas Overview" breadcrumbs />
      <div>
        <OverviewActions actions={actions} entity={resourceQuota} />
      </div>
      <Tabs route={routes[routePath].resourceQuotas.details}>
        <Tab label="Overview" value={IResourceQuotaDetailsPageTabs.Overview}>
          <ResourceQuotasOverview resourceQuota={resourceQuota} loading={loading} reload={reload} />
        </Tab>
        <Tab label="YAML" value={IResourceQuotaDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Resource Quota"
            entityName={resourceQuota?.name}
            getYamlFn={getResourceQuotaYaml}
            handleUpdate={handleResourceQuotaUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}
