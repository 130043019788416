import React from 'react'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'

interface FsTypePicklistProps extends Omit<DropdownProps<string>, 'items'> {
  formField?: boolean
}

const defaultOptions = [
  { label: 'ext4', value: 'ext4' },
  { label: 'xfs', value: 'xfs' },
]

export default function FsTypePicklist({
  name = 'fsType',
  label = 'FS Type',
  formField = false,
  ...props
}: FsTypePicklistProps) {
  return (
    <AsyncDropdown
      {...props}
      name={name}
      label={label}
      formField={formField}
      items={defaultOptions}
    />
  )
}
