/* eslint-disable @typescript-eslint/no-empty-interface */
import { IGenericResource } from 'api-client/qbert.model'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'

export enum IIngressDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface IngressesResponse {
  kind: string
  apiVersion: string
  metadata: IngressesResponseMetadata
  items: IngressItem[]
}

export interface IngressItem {
  metadata: ItemMetadata
  spec: Spec
  status: Status
}

interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  generation: number
  creationTimestamp: string
  labels?: Record<string, string>
  ownerReferences?: OwnerReference[]
  annotations?: Record<string, string>
}

export interface OwnerReference {
  apiVersion: string
  kind: string
  name: string
  uid: string
  controller: boolean
  blockOwnerDeletion: boolean
}

interface Spec {
  defaultBackend: IngressBackend
  rules: Rule[]
}

interface IngressBackend {
  resource: any
  service: IngressServiceBackend
}

interface IngressServiceBackend {
  name: string
  port: ServiceBackendPort
}

interface ServiceBackendPort {
  name: string
  number: number
}

interface Rule {
  http: HTTP
}

interface HTTP {
  paths: Path[]
}

interface Path {
  path: string
  pathType: string
  backend: Backend
}

interface Backend {
  service: Service
}

interface Service {
  name: string
  port: Port
}

interface Port {
  number: number
}

interface Status {
  loadBalancer: LoadBalancer
}

interface LoadBalancer {
  ingress: Ingress[]
}

interface Ingress {
  ip: string
}

interface IngressesResponseMetadata {
  resourceVersion: string
}

export interface IRule {
  path: string
  host: string
  pathType: string
  serviceName: string
  servicePort: string
  backend: Backend
}

export interface IIngressSelector extends IGenericResource<IngressItem> {
  id: string
  name: string
  namespace: string
  creationTimestamp: string
  clusterName: string
  clusterId: string
  clusterType: ClusterTypes
  ownerReferences: IngressItem['metadata']['ownerReferences']
  age?: string
  labels: IngressItem['metadata']['labels']
  annotations: IngressItem['metadata']['annotations']
  rules: IRule[]
}
