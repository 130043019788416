import React, { useState } from 'react'
import FormWrapper from 'core/components/FormWrapper'
import { routes } from 'core/utils/routes'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import Wizard from 'core/components/wizard/Wizard'
import WizardStep from 'core/components/wizard/WizardStep'
import ClusterProfileSelection from './ClusterProfileSelection'
import DriftAnalysisResult from './DriftAnalysisResult'
import { CustomNext } from '../deploy/DeployRbacProfilePage'
import { trackEvent } from 'utils/tracking'
import { createRbacProfileBinding } from '../new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { RenderLabels } from 'k8s/components/pods/renderLabels'
import Tooltip from 'core/elements/tooltip'
import Button from 'core/elements/button'

const initialContext = {
  profiles: [],
  clusters: [],
  analysis: '',
}

const hideWizardButtons = (wizardContext, step: number) => {
  return step === 1
}

const wizardMetaFormattedNames = {
  profiles: 'Profile',
  clusters: 'Cluster',
  roles: 'Total Roles',
  clusterRoles: 'Total Cluster Roles',
  roleBindings: 'Total Role Bindings',
  clusterRoleBindings: 'Total Cluster Role Bindings',
}

const wizardMetaCalloutFields = Object.keys(wizardMetaFormattedNames)

const renderCustomNext = (handleNext, wizardContext, activeStep) => (
  <CustomNext handleNext={handleNext} type="Drift" />
)

const renderStep1Summary = (labels) => {
  const mappedLabels = {
    clusters: labels?.clusters?.[0]?.name,
    profiles: labels?.profiles?.[0]?.name,
  }
  return (
    <RenderLabels keyOverrides={wizardMetaFormattedNames} labels={mappedLabels} inverse split />
  )
}

const DriftAnalysisPage = () => {
  const { history } = useReactRouter()
  const [submittingStep, setSubmittingStep] = useState(false)

  const { update: updateProfileBindingAction, updating: creating, error } = useUpdateAction(
    createRbacProfileBinding,
  )

  const submitForm = async (params) => {
    const { success } = await updateProfileBindingAction({
      cluster: params.clusters[0],
      profileName: params.profiles[0].name,
    })
    if (success) history.push(routes.rbacProfiles.list.path())
  }

  const renderStep2Summary = (labels) => {
    const mappedLabels = {
      clusters: labels?.clusters?.[0]?.name,
      profiles: labels?.profiles?.[0]?.name,
      roles: labels?.profiles?.[0]?.roles?.length,
      clusterRoles: labels?.profiles?.[0]?.clusterRoles?.length,
      roleBindings: labels?.profiles?.[0]?.roleBindings?.length,
      clusterRoleBindings: labels?.profiles?.[0]?.clusterRoleBindings?.length,
    }
    return (
      <>
        <RenderLabels keyOverrides={wizardMetaFormattedNames} labels={mappedLabels} inverse split />
        <Tooltip
          message={
            labels.clusters[0]?.hasBinding ? (
              <>
                A profile must be deployed onto a cluster without an active profile. Delete the
                cluster's associated profile binding to deploy a new profile onto this cluster.
              </>
            ) : (
              ''
            )
          }
          align={{ vertical: 'bottom', horizontal: 'middle' }}
        >
          <div>
            <Button disabled={labels.clusters[0]?.hasBinding} onClick={() => submitForm(labels)}>
              Deploy Profile
            </Button>
          </div>
        </Tooltip>
      </>
    )
  }

  return (
    <FormWrapper
      title="New Drift Analysis"
      backUrl={routes.drift.list.path()}
      loading={creating || submittingStep}
    >
      <DocumentMeta title="Deploy Profile" bodyClasses={['form-view']} />
      <Wizard
        error={error}
        onComplete={submitForm}
        context={initialContext}
        showSteps={false}
        customNext={renderCustomNext}
        hideAllButtons={hideWizardButtons}
      >
        {({ wizardContext, setWizardContext, onNext, handleNext }) => (
          <>
            <WizardStep
              stepId="step1"
              label="Select a Cluster"
              onNext={() => trackEvent('Drift Analysis - Finished Drift Analysis')}
              summaryTitle="New Drift Analysis"
              summaryKeyOverrides={wizardMetaFormattedNames}
              summaryCalloutFields={wizardMetaCalloutFields}
              summaryRenderLabels={renderStep1Summary}
            >
              <ClusterProfileSelection
                wizardContext={wizardContext}
                setWizardContext={setWizardContext}
                onNext={onNext}
                handleNext={handleNext}
                setSubmitting={setSubmittingStep}
              />
            </WizardStep>
            <WizardStep
              stepId="step2"
              label="Drift Analysis"
              summaryTitle="Drift Summary"
              summaryKeyOverrides={wizardMetaFormattedNames}
              summaryCalloutFields={wizardMetaCalloutFields}
              summaryRenderLabels={renderStep2Summary}
            >
              <DriftAnalysisResult wizardContext={wizardContext} />
            </WizardStep>
          </>
        )}
      </Wizard>
    </FormWrapper>
  )
}

export default DriftAnalysisPage
