import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { AppSelector } from 'app/store'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { complement, isNil, propSatisfies } from 'ramda'
import { findClusterName } from 'k8s/util/helpers'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { durationBetweenDates } from 'utils/misc'
import { INetworkPluginSelector } from './model'

const convertPluginsToArray = (networkPlugin) => {
  const plugins = networkPlugin?.spec?.plugins
  // Hardcode namespace until they are allowed to update it
  return [
    {
      name: 'Host Plumber',
      enabled: !!plugins?.hostPlumber,
      type: 'Core',
      namespace: 'luigi-system',
    },
    {
      name: 'Multus CNI',
      enabled: !!plugins?.multus,
      type: 'Core',
      namespace: 'luigi-system',
    },
    {
      name: 'Node Feature Discovery',
      enabled: !!plugins?.nodeFeatureDiscovery,
      type: 'Core',
      namespace: 'luigi-system',
    },
    {
      name: 'Platform9 DHCP',
      enabled: !!plugins?.dhcpController,
      type: 'IPAM',
      namespace: 'luigi-system',
    },
    {
      name: 'Whereabouts',
      enabled: !!plugins?.whereabouts,
      type: 'IPAM',
      namespace: 'luigi-system',
    },
    {
      name: 'SR-IOV',
      enabled: !!plugins?.sriov,
      type: 'Network Type',
      namespace: plugins?.sriov?.namespace || 'luigi-system',
    },
    {
      name: 'OVS',
      enabled: !!plugins?.ovs,
      type: 'Network Type',
      namespace: 'luigi-system',
    },
  ]
}

export const networkPluginsSelector: AppSelector<INetworkPluginSelector[]> = createSharedSelector(
  // We do not want to filter by namespace for this resource
  getDataSelector<DataKeys.NetworkPlugins>(DataKeys.NetworkPlugins, ['clusterId'], ['clusterId']),
  clustersSelector, // Currently only bareos clusters may have luigi addons
  (networkPlugins, clusters) =>
    networkPlugins
      .map((networkPlugin) => {
        return {
          ...networkPlugin,
          clusterName: findClusterName(clusters, networkPlugin?.clusterId),
          age: durationBetweenDates({ labels: ['d'] })(networkPlugin?.created),
          arrayVersion: convertPluginsToArray(networkPlugin),
        }
      })
      .filter(propSatisfies(complement(isNil), 'clusterName')),
)
