import React, { useState, useEffect, useCallback } from 'react'
import ModalForm from 'core/elements/modal/ModalForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateNetwork } from './actions'
import { NetworkTypes } from './AddNetworkAttachmentDefinitionsModal'
import OvsParamFields from './OvsParamFields'
import OvsDpdkParamFields from './OvsDpdkParamFields'
import SriovParamFields from './SriovParamFields'
import SriovDpdkParamFields from './SriovDpdkParamFields'
import MacvlanParamFields from './MacvlanParamFields'
import IpvlanParamFields from './IpvlanParamFields'
import { dissoc } from 'ramda'

const defaultParams = {}

export default function EditNetworkAttachmentDefinitionsModal({ rows: [network], onClose }) {
  const [loaded, setLoaded] = useState(false)

  const { params, updateParams, setParams } = useParams<{
    clusterId?: string
    name?: string
    namespace?: string
    resourceName?: string
    config?: any
    networkType?: string
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(updateNetwork)

  useEffect(() => {
    // Prevent resetting of form from background reloads
    if (loaded) {
      return
    }
    if (network) {
      const configObj = network?.spec?.config ? JSON.parse(network?.spec?.config) : {}
      updateParams({
        clusterId: network?.clusterId,
        name: network?.name,
        namespace: network?.namespace,
        resourceName: network?.metadata?.annotations?.['k8s.v1.cni.cncf.io/resourceName'],
        config: configObj,
        networkType: network?.networkType,
      })
      setLoaded(true)
    }
  }, [network, loaded])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }
  const submitForm = useCallback(async () => {
    const configBody = JSON.stringify(params?.config)

    const annotationsBody = params?.resourceName
      ? {
          ...network?.metadata?.annotations,
          'k8s.v1.cni.cncf.io/resourceName': params?.resourceName,
        }
      : dissoc('k8s.v1.cni.cncf.io/resourceName', network?.metadata?.annotations)

    const ops = []
    ops.push({
      op: 'replace',
      path: '/spec/config',
      value: configBody,
    })

    ops.push({
      op: 'replace',
      path: '/metadata/annotations',
      value: annotationsBody,
    })

    const { success } = await update({
      ...network,
      body: ops,
      requestType: 'patch',
      contentType: 'application/json-patch+json',
    })
    if (success) {
      handleClose()
    }
  }, [params, handleClose])

  const classes = useStyles()

  return (
    <ModalForm
      open
      title="Edit Network Attachment Definition"
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      maxWidth={528}
      loading={!loaded}
    >
      <div className={classes.container}>
        {params?.networkType === NetworkTypes.Ovs && (
          <OvsParamFields method="edit" params={params} updateParams={updateParams} />
        )}

        {params?.networkType === NetworkTypes.OvsDpdk && (
          <OvsDpdkParamFields method="edit" params={params} updateParams={updateParams} />
        )}

        {params?.networkType === NetworkTypes.Sriov && (
          <SriovParamFields method="edit" params={params} updateParams={updateParams} />
        )}

        {params?.networkType === NetworkTypes.SriovDpdk && (
          <SriovDpdkParamFields method="edit" params={params} updateParams={updateParams} />
        )}

        {params?.networkType === NetworkTypes.Macvlan && (
          <MacvlanParamFields method="edit" params={params} updateParams={updateParams} />
        )}

        {params?.networkType === NetworkTypes.Ipvlan && (
          <IpvlanParamFields method="edit" params={params} updateParams={updateParams} />
        )}
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '24px 40px',
    display: 'grid',
    gap: 24,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
}))
